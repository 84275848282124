import { gql } from '@apollo/client';

export default gql`
  query RepeatingJob($repeatingJobId: ID!) {
    job: repeatingJob(id: $repeatingJobId) {
      id
      version
      status
      displayName
      startDate
      endDate
      isDeleted
      frequencyHash
      frequency
      cycle {
        displayName
        type
        value
        frequency
      }
      jobTemplate {
        status
        type
        items {
          name
          amount
        }
        action
        priority
        isEscalated
        dueByTime
        scheduleStartTimeAt
        checklists {
          id
        }
        notes {
          id
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          text
        }
        attachments
        reporter {
          id
          userName
          firstName
          lastName
        }
        assignee {
          id
          firstName
          lastName
        }
        department {
          id
          displayName
          departmentCode
        }
        locations {
          id
          name
          type
          number
          value: id
          title: shortDisplayName
          shortDisplayName
          longDisplayName
        }
        role {
          id
          name
        }
        metadata {
          source
          publicAttributes {
            asset {
              id
            }
          }
        }
      }
    }
  }
`;
