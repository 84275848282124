/* eslint-disable */
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import i18n from 'i18n';
import { RRule } from 'rrule';

import { formatUserDisplayName } from './user';
import { getOffset, getDuration } from './escalations';

const DAY_HOUR = 24;

const _getStatuses = (_) => ({
  queued: { id: 'queued', displayName: i18n.t('jobs:Queued') },
  pending: { id: 'pending', displayName: i18n.t('jobs:Not Started') },
  in_progress: { id: 'in_progress', displayName: i18n.t('jobs:In Progress') },
  completed: { id: 'completed', displayName: i18n.t('common:Done') },
  cancelled: { id: 'cancelled', displayName: i18n.t('jobs:Cancelled') },
  on_hold: { id: 'on_hold', displayName: i18n.t('jobs:On Hold') },
});

const _getJobStatusOptions = (_) => ({
  not_started: { id: 'not_started', displayName: i18n.t('jobs:Not Started') },
  in_progress: { id: 'in_progress', displayName: i18n.t('jobs:In Progress') },
  completed: { id: 'completed', displayName: i18n.t('common:Done') },
  cancelled: { id: 'cancelled', displayName: i18n.t('jobs:Cancelled') },
  on_hold: { id: 'on_hold', displayName: i18n.t('jobs:On Hold') },
});

const _getAdvancedStatuses = (_) => ({
  new: { id: 'new', displayName: i18n.t('jobs:Not Started') },
  archived: { id: 'archived', displayName: i18n.t('common:Done') },
});

const _getPriorities = (_) => ({
  highest: { id: 'highest', displayName: i18n.t('jobs:Highest') },
  high: { id: 'high', displayName: i18n.t('jobs:High') },
  medium: { id: 'medium', displayName: i18n.t('jobs:Medium') },
  low: { id: 'low', displayName: i18n.t('jobs:Low') },
});

const _getActions = (_) => ({
  clean: { id: 'clean', displayName: i18n.t('jobs:Clean') },
  deliver: { id: 'deliver', displayName: i18n.t('jobs:Deliver') },
  remove: { id: 'remove', displayName: i18n.t('jobs:Remove') },
  repair: { id: 'repair', displayName: i18n.t('jobs:Repair') },
  replace: { id: 'replace', displayName: i18n.t('jobs:Replace') },
  inspect: { id: 'inspect', displayName: i18n.t('jobs:Inspect') },
  install: { id: 'install', displayName: i18n.t('jobs:Install') },
  perform: { id: 'perform', displayName: i18n.t('jobs:Perform') },
  test: { id: 'test', displayName: i18n.t('jobs:Test') },
});

const _getJobTypes = (_) => ({
  internal: i18n.t('jobs:Internal Request'),
  guest: i18n.t('jobs:Guest Request'),
});

const _getAuditActions = (_) => ({
  edited: i18n.t('jobs:Updated by'),
  taken: i18n.t('jobs:Taken by'),
  assigned: i18n.t('jobs:Assigned by'),
  started: i18n.t('jobs:Started by'),
  paused: i18n.t('jobs:Paused by'),
  completed: i18n.t('jobs:Completed by'),
  cancelled: i18n.t('jobs:Cancelled by'),
  archived: i18n.t('jobs:Deleted by'),
  created: i18n.t('common:Added by'),
  added: i18n.t('common:Added by'),
  reopened: i18n.t('jobs:Reopened by'),
  assignedTo: i18n.t('jobs:Assigned to'),
  disabled: i18n.t('jobs:Could Not Assign'),
  escalated: i18n.t('jobs:Escalated to'),
});

const _getCommonTranslations = (_) => ({
  by: i18n.t('common:by'),
});

export const translatedData = {};

function updateTranslations() {
  translatedData._priorities = _getPriorities();
  translatedData.priorities = Object.values(_getPriorities());
  translatedData._actions = _getActions();
  translatedData.actions = Object.values(_getActions());
  translatedData._statuses = _getStatuses();
  translatedData._jobStatuses = _getJobStatusOptions();
  translatedData.jobStatusOptions = Object.values(_getJobStatusOptions());
  translatedData._advancedStatuses = _getAdvancedStatuses();
  translatedData.statuses = Object.values(_getStatuses());
  translatedData.jobTypes = _getJobTypes();
  translatedData._auditActions = _getAuditActions();
  translatedData.common = _getCommonTranslations();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on('languageChanged', (lng) => {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on('loaded', (lng) => {
  updateTranslations();
});

export function getType(job) {
  return translatedData.jobTypes[job.type];
}

export const { statuses } = translatedData;
export const addJobStatusOptions = translatedData._getAddJobStatuses;
export function getStatus(job) {
  const statusMap = {
    ...translatedData._statuses,
    ...translatedData._jobStatuses,
    ...translatedData._advancedStatuses,
  };
  return statusMap[job.status].displayName;
}

export const { priorities } = translatedData;
export function getPriority(job) {
  return translatedData._priorities[job.priority];
}

const priorityLevel = { highest: 0, high: 1, medium: 2, low: 3 };

export function sortByPriorityAndDoBy(jobs) {
  return orderBy(jobs, [(job) => [priorityLevel[job.priority]], 'doBy']);
}

const _actions = {
  clean: { id: 'clean', displayName: 'Clean' },
  deliver: { id: 'deliver', displayName: 'Deliver' },
  remove: { id: 'remove', displayName: 'Remove' },
  repair: { id: 'repair', displayName: 'Repair' },
  replace: { id: 'replace', displayName: 'Replace' },
  inspect: { id: 'inspect', displayName: 'Inspect' },
  install: { id: 'install', displayName: 'Install' },
  perform: { id: 'perform', displayName: 'Perform' },
  test: { id: 'test', displayName: 'Test' },
};

export const actions = Object.values(_actions);

export function getAction(action, isTranslated = false) {
  const actions = isTranslated ? translatedData._actions : _actions;
  return actions[action];
}

export function getName(job, isTranslated = false) {
  return {
    short: `#${job.id}`,
    medium: `${getPriority(job).displayName} | ${getLocations(
      job,
      isTranslated,
    )} : ${getAction(job.action) && getAction(job.action).displayName} ${
      job.item
    }`,
    long: `#${job.id} ${
      getAction(job.action, isTranslated) &&
      getAction(job.action, isTranslated).displayName
    } ${job.item}`,
    longer: `${getLocations(job, isTranslated)} : ${
      getAction(job.action, isTranslated) &&
      getAction(job.action, isTranslated).displayName
    } ${job.item}`,
    longest: `#${job.id} | ${getLocations(job, isTranslated)} : ${
      getAction(job.action, isTranslated) &&
      getAction(job.action, isTranslated).displayName
    } ${job.item}`,
    longestWithoutId: `${getLocations(job, isTranslated)} : ${
      getAction(job.action, isTranslated) &&
      getAction(job.action, isTranslated).displayName
    } ${job.item}`,
  };
}

export function getLocations(job) {
  const locations = job.locations
    ? job.locations.map((loc) => loc.shortDisplayName).join(', ')
    : job.locations &&
      JSON.parse(job.locations)
        .map((loc) => loc.shortDisplayName)
        .join(', '); // Need this tertiary operation since push notifications use "location" not "locations"
  return locations;
}

export function getDescription(job, isTranslated = false) {
  const actionId = job?.action || job.jobTemplate?.action;
  const item = job.item || job.jobTemplate.item;
  const actions = isTranslated ? translatedData._actions : _actions;
  const jobAction = actionId
    ? actions[actionId] || actions[actionId.toLowerCase()]
    : '';
  const location = getLocations(job) ? `${getLocations(job)}:` : '';

  if (!location || !item) {
    console.error('[getDescription] Some job data is missing', job);
  }

  if (jobAction) {
    return `${location} ${jobAction?.displayName} ${item}`;
  }
  return `${location} ${item}`;
}

export function getTeamMember(assignee) {
  if (!assignee) return '';

  return {
    short: `${assignee.firstName} ${assignee.lastName[0]}`,
    long: `${assignee.firstName} ${assignee.lastName}`,
  };
}

function namesAreTheSame(audit) {
  if (!audit.details) {
    return false;
  }
  return formatUserDisplayName(audit.employee) === audit.details;
}

const _auditActions = {
  edited: 'Updated by',
  taken: 'Taken by',
  assigned: 'Assigned by',
  started: 'Started by',
  paused: 'Paused by',
  completed: 'Completed by',
  cancelled: 'Cancelled by',
  archived: 'Deleted by',
  created: 'Added by',
  added: 'Added by',
  reopened: 'Reopened by',
  assignedTo: 'Assigned to',
  disabled: 'Could Not Assign',
  escalated: 'Escalated to',
};

export function getAuditAction(audit, t = false) {
  if (audit.action === 'taken') {
    return formatTakenAuditAction(audit, t);
  }
  if (audit.action === 'escalated') {
    return formatEscalationAuditAction(audit, t);
  }
  return formatDefaultAuditAction(audit, t);
}

function formatDefaultAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;
  return `${auditActions[audit.action] || auditActions.edited} ${
    audit.action === 'disabled' ? '' : formatUserDisplayName(audit.employee)
  }`;
}

function formatTakenAuditAction(audit, isTranslated) {
  const auditActions = isTranslated
    ? translatedData._auditActions
    : _auditActions;
  if (namesAreTheSame(audit)) {
    return `${auditActions.taken} ${formatUserDisplayName(audit.employee)}`;
  }
  return `${auditActions.assignedTo} ${audit.details} ${
    isTranslated ? translatedData.common.by : 'by'
  } ${formatUserDisplayName(audit.employee)}`;
}

function formatEscalationAuditAction(audit, t) {
  const auditActions = t ? translatedData._auditActions : _auditActions;
  const offSetType = audit.details;
  const timeOffset = audit.modifier1;
  const assignedType = audit.modifier2;
  const role = audit.object.split(',').join(', ');
  const filler = t('common:since job is');

  if (assignedType === null) {
    return `${auditActions.escalated} ${capitalizeEachWord(
      role,
    )} ${filler} ${getDuration(timeOffset)} ${getOffset(offSetType)}`;
  }
  return `${auditActions.escalated} ${capitalizeEachWord(
    role,
  )} ${filler} ${assignedType} ${t('common:and is')} ${getDuration(
    timeOffset,
  )} ${getOffset(offSetType)}`;
}

export const capitalizeEachWord = (sentence) =>
  sentence.replace(/\b(\w)/g, (letter) => letter.toUpperCase());

export function generateOpenJobUrl(jobId, propertyId) {
  return `${window.location.origin}/jobs?openJob=${jobId}&propertyId=${propertyId}`;
}

export function getJobAssignedPushNotificationTitle(data, t) {
  return `${t('common:You have a new job')} ${getDescription(data)}`;
}

export function getJobAssignedPushNotificationBody(data, t) {
  return t(`common:Due by {{dateTime}}`, {
    dateTime: moment.unix(data.dueBy).tz(data.timeZone).format('l [at] LTS'),
  });
}

export function getFrequency(cycle, t) {
  if (cycle.frequency === 'daily') {
    return getMultipleDailyToText(cycle, t);
  }
  return cycle.displayName.charAt(0).toUpperCase() + cycle.displayName.slice(1);
}

export const getMultipleDailyToText = (cycle, t) => {
  const cadenceValue = cycle.value;

  const cadenceRule = new RRule.fromString(cadenceValue);

  const originalOptions = cadenceRule.origOptions;

  if (originalOptions.byhour === undefined || !originalOptions.byhour.length) {
    return (
      cycle.displayName.charAt(0).toUpperCase() + cycle.displayName.slice(1)
    );
  }

  const timesPerDay = originalOptions.byhour.length;
  const lastTwoHours = originalOptions.byhour.splice(
    originalOptions.byhour.length - 2,
    2,
  );
  const count = lastTwoHours.reduce((h, a) => a - h, 0);

  return t('jobs:{{timesPerDay}} times a day, every {{count}} hours', {
    timesPerDay,
    count,
  });
};

export const getDailyTimeLabel = (cycle, t) => {
  const rule = new RRule.fromString(cycle.value);
  const originalOptions = rule.origOptions;

  if (originalOptions.byhour === undefined || !originalOptions.byhour.length) {
    return t('jobs:Due Time');
  }
  return t('jobs:First Due Time');
};

export const repeatEveryOptions = (dueByTime) => {
  if (!dueByTime) {
    return;
  }
  const startHour = dueByTime.hours();
  const repeatOptions = Array.from(
    { length: DAY_HOUR - startHour },
    (_, i) => i + 1,
  ).map((item) => ({
    id: item,
    displayName: item.toString(),
  }));

  for (let index = 0; index < repeatOptions.length; index++) {
    repeatOptions.pop();
  } // Pops options that will lead to no options being displayed when selecting the reoccurrence of the times

  return repeatOptions;
};

export const repeatTimesPerDayOptions = (dueByTime, repeatEvery, t) => {
  if (!dueByTime) {
    return [
      {
        id: null,
        displayName: t('jobs:Select...'),
      },
    ];
  }

  const startHour = dueByTime.hours();
  const repeatOptions =
    repeatEvery && // This check is to regenerate the options of the array so we don't allow an invalid option based on the repeatEvery to be displayed after a change for example.
    Array.from({ length: DAY_HOUR - startHour }, (_, i) => i + 1).map(
      (item) => ({
        id: item,
        displayName: item.toString(),
      }),
    );
  const options = [];

  for (
    let index = 1;
    index < Math.floor(repeatOptions.length / repeatEvery);
    index++
  ) {
    options.push(repeatOptions[index]);
  }
  return options;
};

export const formatUpdateRepeatingJobInput = (
  job,
  formatCycleValue,
  inputParam = {},
) => {
  const {
    isDeleted,
    frequency,
    frequencyHash,
    jobTemplate,
    cycle,
    isValidCadence,
    locations,
    asset,
    ...jobData
  } = job;
  const {
    metadata,
    notes,
    assignee,
    roles,
    department,
    location,
    isEscalated,
    reporter,
    item,
    priority,
    status,
    dueByTime,
    checklistTemplates,
    ...jobTemplateData
  } = jobTemplate;

  function filterObject(obj, key) {
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) continue;
      if (typeof obj[i] === 'object') {
        filterObject(obj[i], key);
      } else if (i === key) {
        delete obj[key];
      }
    }
    return obj;
  }

  const jobTemplateInput = {};

  if (cycle && formatCycleValue && cycle.value.includes('RRULE:')) {
    cycle.value = cycle.value.substring(6);
  }

  if (metadata) {
    jobTemplateInput.metadata = {
      publicAttributes: metadata.publicAttributes,
    };
  }

  if (assignee) {
    jobTemplateInput.assignee = {
      employeeId: assignee.id,
    };
  }

  if (department) {
    jobTemplateInput.department = [
      {
        id: department.id,
      },
    ];
  }

  if (dueByTime) {
    jobTemplateInput.dueByTime = dueByTime;
  }

  if (roles) {
    jobTemplateInput.roles = [{ id: roles.id }];
  }

  if (notes) {
    jobTemplateInput.notes = jobTemplate.notes.map((note) => ({
      id: note.id,
      note: note.text,
    }));
  } else {
    jobTemplateInput.notes = [];
  }

  if (item) {
    jobTemplateInput.item = item.displayName;
  }

  if (priority) {
    jobTemplateInput.priority = priority.id;
  }

  if (checklistTemplates) {
    jobTemplateInput.checklistTemplates = [
      { id: checklistTemplates?.id || checklistTemplates[0].id },
    ];
  }

  const JOB_STATUS = getJobStatus({t});

  // NOTE: By BE limitation, not_started is not supported by the repeatingJob services. when this status is selected we must convert it to "new"
  if (status) {
    jobTemplateInput.status =
      status.id === JOB_STATUS.not_started ? JOB_STATUS.new : status.id;
  }

  if (location) {
    jobTemplateInput.location = location.map((loc) => ({ id: loc.id }));
  }

  const input = {
    ...jobData,
    cycle,
    jobTemplate: {
      ...jobTemplateData,
      ...jobTemplateInput,
      ...inputParam,
    },
  };

  return filterObject(input, '__typename');
};
