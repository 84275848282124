/* eslint-disable */
import { Capacitor } from '@capacitor/core';
import { Browser, OpenOptions } from '@capacitor/browser';
import { Browser as BrowserIonic } from 'ionic-appauth';
import { isPlatform } from '@ionic/react';

export class OptiiCapacitorBrowser extends BrowserIonic {
  public closeWindow(): void | Promise<void> {
    if (!Browser) throw new Error('Capacitor Browser Is Undefined!');

    if (Capacitor.getPlatform() !== 'android') {
      Browser.close();
    }
  }

  public async showWindow(url: string): Promise<string | undefined> {
    const options: OpenOptions = {
      url,
      windowName: '_self',
    };

    if (!Browser) throw new Error('Capacitor Browser Is Undefined!');

    Browser.addListener('browserFinished', () => {
      this.onCloseFunction();
    });
    if (isPlatform('capacitor')) {
      return new Promise((resolve) => {
        Browser.open(options);
        Browser.addListener('browserPageLoaded', () => {
          resolve('');
        });
      });
    }
    Browser.open(options);
  }
}
