
import React, { ReactNode, useContext, useMemo } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';
import { UserNotification, SelectOption } from '../types/notifications';
import { useNotificationGroupsQuery } from '../api/notifications';
import { useRolesQuery } from '../api/roles';
import { useProjectsQuery } from '../api/projects';
import { useDepartmentsQuery } from '../api/settings';
import { DEFAULT_PAGE_SIZE } from '../constants/notifications';

type PaginationData = {
  totalCount: number | undefined;
  pageInfo?: {
    endCursor: string;
    hasNextPage: boolean;
  };
};

export type NotificationContextProps = {
  projects: {
    options: Array<SelectOption>;
    loading: boolean;
  };
  roles: {
    options: Array<SelectOption>;
    loading: boolean;
  };

  departments: {
    options: Array<SelectOption>;
    loading: boolean;
  };
  notifications: {
    dataSource: UserNotification[];
    loading: boolean;
    paginationData: PaginationData;
  };
};

export const NotificationContext =
  React.createContext<NotificationContextProps>({
    projects: {
      loading: false,
      options: [],
    },
    roles: {
      loading: false,
      options: [],
    },
    departments: {
      loading: false,
      options: [],
    },
    notifications: {
      loading: false,
      dataSource: [],
      paginationData: {
        pageInfo: {
          endCursor: '',
          hasNextPage: false,
        },
        totalCount: 0,
      },
    },
  });

export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [pageInfo, setPageInfo] = useQueryParams({
    first: NumberParam,
    after: StringParam,
  });
  const [search] = useQueryParam('search', StringParam);

  const { data: notificationGroupsData, loading: notificationGroupsLoading } =
    useNotificationGroupsQuery({
      variables: {
        sorts: 'name',
        filters: search ? `displayName@=*${search},EventGroupContext@=Job|ProjectCycle` : 'EventGroupContext@=Job|ProjectCycle',
        first: pageInfo.first || DEFAULT_PAGE_SIZE,
        after: pageInfo.after,
      },
      context: {
        _instance: 'node',
      },
      onCompleted() {
        if (!pageInfo.first) {
          setPageInfo({
            first: DEFAULT_PAGE_SIZE,
          });
        }
      },
      onError(error) {
        console.error(error);
      },
    });

  const { data: rolesData, loading: rolesLoading } = useRolesQuery({
    onError(error) {
      console.error(error);
    },
  });

  const { data: projectsData, loading: projectsLoading } = useProjectsQuery({
    onError(error) {
      console.error(error);
    },
    variables: {
      sorts: 'displayName',
      filters: 'isDeleted==false',
    },
    context: {
      _instance: 'node',
    },
  });

  const { data: departmentsData, loading: departmentsLoading } =
    useDepartmentsQuery({
      onError(error) {
        console.error(error);
      },
    });

  const departmentOptions: Array<SelectOption> = useMemo(
    () =>
      departmentsData?.page?.edges?.map(({ node }) => ({
        label: node.label,
        value: node.value,
      })) || [],
    [departmentsData],
  );

  const projectOptions: Array<SelectOption> = useMemo(
    () =>
      projectsData?.page?.edges?.map(({ node }) => ({
        label: node.label,
        value: node.value,
      })) || [],
    [projectsData],
  );

  const roleOptions: Array<SelectOption> = useMemo(
    () =>
      rolesData?.page.edges?.map(({ node }) => ({
        label: node.label,
        value: node.value,
      })) || [],
    [rolesData],
  );

  const notifications = useMemo(
    () => notificationGroupsData?.page?.edges?.map(({ node }) => node) || [],
    [notificationGroupsData],
  );

  const paginationData = useMemo(
    () => ({
      totalCount: notificationGroupsData?.page?.totalCount,
      pageInfo: notificationGroupsData?.page?.pageInfo,
    }),
    [notificationGroupsData],
  );

  const memoizedContextValues = useMemo(
    () => ({
      departments: {
        options: departmentOptions,
        loading: departmentsLoading,
      },
      projects: {
        options: projectOptions,
        loading: projectsLoading,
      },
      roles: {
        options: roleOptions,
        loading: rolesLoading,
      },
      notifications: {
        dataSource: notifications,
        loading: notificationGroupsLoading,
        paginationData,
      },
    }),
    [departmentOptions, departmentsLoading, projectOptions, projectsLoading, roleOptions, rolesLoading, notifications, notificationGroupsLoading, paginationData],
  );

  return (
    <NotificationContext.Provider value={memoizedContextValues}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used with NotificationContextProvider component');
  }

  return context;
};