import { Card, Progress, Typography } from 'antd';
import React from 'react';

export interface CardProgressProps {
  progress: 'importing' | 'complete' | 'error';
  message: string,
  children?: React.ReactNode
}

const percents = {
  importing: 30,
  complete: 100,
  error: 100,
} as const;

const status = {
  importing: 'active',
  complete: 'success',
  error: 'exception',
} as const;

const colors = {
  importing: '#00A693',
  complete: '#52C41A',
  error: 'red',
} as const;

export function CardProgress({ progress, message, children }: CardProgressProps) {
  return (
    <Card bordered={true}>
      <Typography.Paragraph>
        {message}
      </Typography.Paragraph>
      <Progress
        percent={percents[progress]}
        strokeColor={colors[progress]}
        status={status[progress]}
        showInfo={false}
        size={['100%', 16]}
      />
      {children}
    </Card>
  );
}
