import { gql } from "@apollo/client";

export default gql`
  mutation importLocations($fileURL: String!) {
    importLocations(fileURL: $fileURL) {
      id
      status
    }
  }
`;
