import React, { useState } from "react";
import SnackBar from "@optii/shared/components/molecules/SnackBar";
// hook for snack bar usage.
export function useSnack() {
  const [snack, setInnerSnack] = useState(false);
  const setSnack = ({ message, error, timeout = 3000 }) => {
    const code = Date.now();
    setInnerSnack({ message, error, code });
    setTimeout(_ => {
      setInnerSnack(false);
    }, timeout);
  };
  function Snack(_) {
  return <SnackBar show={snack}
    modifiers={snack.error && "error"}
    message={snack.message}
  />
}
  return { setSnack, Snack };
}
