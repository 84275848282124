
import React, { ReactNode, useEffect } from 'react';

const hasWindow = () => typeof window !== 'undefined';

type OfflineProps = {
    children?: ReactNode;
    onChange?: ({ isOnline }: { isOnline: boolean }) => void;
    style: Record<string,any>
};

export const Offline = ({ onChange = () => {}, children, style }: OfflineProps) => {

    const [isOnline, setIsOnline] = React.useState(hasWindow() && navigator.onLine);

    const handleConnection = (online: boolean) => () => {
        setIsOnline(online);
        onChange({ isOnline: online });
    };
    
    useEffect(() => {
        window.addEventListener('online', handleConnection(true));
        window.addEventListener('offline', handleConnection(false));

        return () => {
            window.removeEventListener('online', handleConnection(true));
            window.removeEventListener('offline', handleConnection(false));
        };

    }, []);

    if (!isOnline && children)  return children;
    
    if (!isOnline) return <>
        <div style={{
            background: '#ffa940',
            fontSize: 14,
            color: '#61240a',
            width: '100%',
            padding: 5,
            ...style,
        }}>
            'You are offline'
        </div>

    </>
    
    return null;
};
