import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {};

// for GreyFilterWithArrows
const InlineFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: calc(100% - 10rem);
  @media (max-width: ${(props) => props.theme.breakpoints.miniLgMax}) {
    max-width: calc(100% - 1rem);
    margin-top: 1rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;
export default InlineFiltersContainer;
