import Icon, { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  CheckboxOptionType,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tag,
  ThemeConfig,
  TimePicker,
  Typography,
} from 'antd';
import { Store } from 'antd/es/form/interface';
import { DefaultOptionType } from 'antd/es/select';
import { TitleProps } from 'antd/es/typography/Title';
import { Select as OptiiSelect } from '../../components/Select/Select.component';
import { Priorities } from '../../icons/jobs';
import type { ValidateMessages } from 'rc-field-form/lib/interface';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { Fragment, ReactNode, useMemo, useState } from 'react';

export interface RoomTypeFormProps {
  theme?: ThemeConfig;
  initialValues?: Store;
  loading?: boolean;
  checklists: DefaultOptionType[];
  onFinishFailed?: (data: Record<string, any>) => void;
  onFinish: (data: Record<string, any>) => void;
  displaySubmit?: boolean;
}

const format = 'HH:mm';

function CreditTimeChecklist({
  checklists,
  label,
  name,
}: {
  checklists: DefaultOptionType[];
  name: string;
  label: string;
}) {
  return (
    <Form.Item label={label} style={{ marginBottom: 0 }}>
      <style>
        {`
          .ui-library-ctc label {
            font-size: 12px !important;
            color: #595959 !important;
          }
        `}
      </style>
      <Flex
        className="ui-library-ctc"
        align="center"
        gap={8}
        style={{ width: '100%' }}
      >
        <Form.Item label="Credits" name={[name, 'credits']}>
          <InputNumber size="large" min="0" max="99.99" step="0.01" />
        </Form.Item>
        <Form.Item label=" " name={[name, 'hours']}>
          <TimePicker size="large" format={format} />
        </Form.Item>
        <Form.Item
          style={{ flex: '1' }}
          label="Checklists"
          name={[name, 'checklists']}
        >
          <Select
            maxTagCount="responsive"
            size="large"
            options={checklists}
            mode="multiple"
          />
        </Form.Item>
      </Flex>
    </Form.Item>
  );
}

export function RoomTypeForm({
  displaySubmit,
  initialValues,
  onFinish,
  onFinishFailed,
  loading,
  checklists,
  theme = {
    components: {
      Form: {
        labelFontSize: 16,
        labelColor: '#434343',
        verticalLabelPadding: 0,
      },
    },
  },
}: RoomTypeFormProps) {
  return (
    <ConfigProvider theme={theme}>
      <style>{`
      .ant-form-item .ant-form-item-label >label {
        font-weight: 500
      }
      #room-type-form .ant-tabs-tab-active {
        margin-left: 20px !important
      }
      `}</style>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          title="Form"
          id="room-type-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
          scrollToFirstError
        >
          <Card style={{ margin: 20 }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Name is a required field.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Code" name={'code'}>
              <Input />
            </Form.Item>
            <Form.Item label="Note" name={'note'}>
              <Input />
            </Form.Item>
          </Card>

          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: 'HK Credits & Checklists',
                children: (
                  <div
                    style={{
                      background: 'white',
                      marginTop: -15,
                      padding: '20px 30px',
                    }}
                  >
                    <CreditTimeChecklist
                      label="Departure"
                      name="departure"
                      checklists={checklists}
                    />

                    <CreditTimeChecklist
                      label="Departure Inspection"
                      name="departure-inspection"
                      checklists={checklists}
                    />

                    <CreditTimeChecklist
                      label="Stayover"
                      name="stayover"
                      checklists={checklists}
                    />

                    <CreditTimeChecklist
                      label="Stayover Inspection"
                      name="stayover-inspection"
                      checklists={checklists}
                    />
                    <CreditTimeChecklist
                      label="Touch Up"
                      name="touchup"
                      checklists={checklists}
                    />
                    <CreditTimeChecklist
                      label="Turndown"
                      name="turndown"
                      checklists={checklists}
                    />
                    <CreditTimeChecklist
                      label="Check"
                      name="check"
                      checklists={checklists}
                    />
                    <CreditTimeChecklist
                      label="Correction"
                      name="correction"
                      checklists={checklists}
                    />
                  </div>
                ),
              },
            ]}
          />

          {displaySubmit && (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}
        </Form>
      </Spin>
    </ConfigProvider>
  );
}

export interface LocationTypeFormProps {
  theme?: ThemeConfig;
  initialValues?: Store;
  loading?: boolean;
  onFinishFailed?: (data: Record<string, any>) => void;
  onFinish: (data: Record<string, any>) => void;
  displaySubmit?: boolean;
}

export function LocationTypeForm({
  displaySubmit,
  initialValues,
  onFinish,
  onFinishFailed,
  loading,
  theme = {
    components: {
      Form: {
        labelFontSize: 16,
        labelColor: '#434343',
        verticalLabelPadding: 0,
      },
    },
  },
}: LocationTypeFormProps) {
  return (
    <ConfigProvider theme={theme}>
      <style>{`.ant-form-item .ant-form-item-label >label {
        font-weight: 500
      }`}</style>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          title="Form"
          id="location-type-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
          scrollToFirstError
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Name is a required field.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Code" name={'code'}>
            <Input />
          </Form.Item>
          <Form.Item label="Note" name={'note'}>
            <Input />
          </Form.Item>

          {displaySubmit && (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}
        </Form>
      </Spin>
    </ConfigProvider>
  );
}

export interface LocationFormProps {
  theme?: ThemeConfig;
  initialValues?: Store;
  loading?: boolean;
  onFinishFailed?: (data: Record<string, any>) => void;
  onFinish: (data: Record<string, any>) => void;
  locations: DefaultOptionType[];
  parentLocations: DefaultOptionType[];
  locationTypes: DefaultOptionType[];
  roomTypes: DefaultOptionType[];
  tags: DefaultOptionType[];
  displaySubmit?: boolean;
}

export function LocationForm({
  displaySubmit,
  locations,
  parentLocations,
  locationTypes,
  roomTypes,
  tags,
  initialValues,
  onFinish,
  onFinishFailed,
  loading,
  theme = {
    components: {
      Form: {
        labelFontSize: 16,
        labelColor: '#434343',
        verticalLabelPadding: 0,
      },
    },
  },
}: LocationFormProps) {
  const [tagList, setTagList] = useState(tags);
  const [searchTag, setSearchTag] = useState('');

  const handleSearch = (searchValue: string) => {
    setSearchTag(searchValue);
  };

  const handleCreateOption = (value?: string) => {
    setTagList((prev) =>
      prev.concat([
        {
          label: value,
          value,
        },
      ]),
    );
  };
  return (
    <ConfigProvider theme={theme}>
      <style>{`.ant-form-item .ant-form-item-label >label {
        font-weight: 500
      }`}</style>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          autoComplete="off"
          layout="vertical"
          title="Form"
          id="location-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={initialValues}
          scrollToFirstError
        >
          <Form.Item name="isActive" valuePropName="checked">
            <Switch unCheckedChildren="Inactive" checkedChildren="Active" />
          </Form.Item>
          <Form.Item
            label="Parent Location"
            name={'parentLocation'}
            rules={[
              {
                required: true,
                message: 'Parent Location is a required field',
              },
            ]}
          >
            <Select
              options={parentLocations}
              allowClear
              placeholder="Select Parent Location"
            />
          </Form.Item>
          <Form.Item
            label="Location Type"
            name={'locationType'}
            rules={[
              { required: true, message: 'Location Type is a required field' },
            ]}
          >
            <Select
              options={locationTypes}
              labelInValue
              allowClear
              placeholder="Select Location Type"
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.locationType !== currentValues.locationType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('locationType')?.label === 'Room' ? (
                <Form.Item
                  name="roomType"
                  label="Room Type"
                  rules={[
                    {
                      required: true,
                      message: 'Room Type is a required field.',
                    },
                  ]}
                >
                  <Select options={roomTypes} allowClear />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            label="Name"
            name={'name'}
            rules={[{ required: true, message: 'Name is a required field.' }]}
          >
            <Input placeholder="Name..." autoComplete="name-only" />
          </Form.Item>

          <Form.Item label="Description" name={'description'}>
            <Input.TextArea placeholder="Description..." />
          </Form.Item>

          <Form.Item
            label="Square Unit"
            name={'squareUnit'}
            rules={[
              {
                type: 'number',
                min: 1,
                max: 100,
                message: 'Square unit must be between 10 and 100',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Square Unit..."
            />
          </Form.Item>

          <Form.Item label="Note" name={'note'}>
            <Input.TextArea placeholder="Note..." />
          </Form.Item>

          <Form.Item
            label="Sequence"
            name={'sequence'}
            rules={[
              {
                type: 'number',
                min: 1,
                max: 100,
                message: 'Sequence must be between 10 and 100',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} placeholder="Sequence..." />
          </Form.Item>

          <Form.Item label="Tags" name={'tags'}>
            <OptiiSelect
              options={tagList}
              handleOptionCreation={handleCreateOption}
              allowClear
              showSearch
              onSearch={handleSearch}
              searchValue={searchTag}
              mode="multiple"
              placeholder="Tags..."
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.associatedVirtualLocations?.length !==
              currentValues.associatedVirtualLocations?.length
            }
          >
            {({ getFieldValue }) =>
              !getFieldValue('associatedVirtualLocations')?.length ? (
                <Form.Item name={'isVirtual'} valuePropName="checked">
                  <Checkbox children="Set as virtual location" />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.isVirtual !== currentValues.isVirtual
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('isVirtual') === true ? (
                <Form.Item
                  label="Locations"
                  name={'virtualChildrenLocationIds'}
                  rules={[
                    {
                      required: true,
                      message: 'Locations is a required field.',
                    },
                  ]}
                >
                  <Select
                    options={locations}
                    allowClear
                    mode="multiple"
                    placeholder="Associated Locations..."
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          {displaySubmit && (
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          )}
        </Form>
      </Spin>
    </ConfigProvider>
  );
}

export interface GeneralSettingsFormProps {
  theme?: ThemeConfig;
  initialValues: Store;
  validateMessages?: ValidateMessages;
  loading?: boolean;
  onFinishFailed: (data: Record<string, any>) => void;
  onFinish: (data: Record<string, any>) => void;
  i18n: {
    guest: {
      label: string;
      placeholder: string;
      options: DefaultOptionType[];
    };
    internal: {
      label: string;
      placeholder: string;
      options: DefaultOptionType[];
    };
    department: {
      label: string;
      placeholder: string;
      options: DefaultOptionType[];
    };
  };
}

export function GeneralSettingsForm({
  i18n,
  initialValues,
  onFinish,
  onFinishFailed,
  loading,
  theme = {
    components: {
      Form: {
        labelFontSize: 16,
        labelColor: '#434343',
        verticalLabelPadding: 0,
      },
    },
  },
  validateMessages,
}: GeneralSettingsFormProps) {
  return (
    <ConfigProvider theme={theme}>
      <style>{`.ant-form-item .ant-form-item-label >label {
        font-weight: 500
      }`}</style>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          id="general-settings-form"
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={['settings', 'guest']}
            label={i18n.guest.label}
            rules={[
              {
                required: true,
                message: i18n.guest.placeholder,
              },
            ]}
          >
            <Select
              placeholder={i18n.guest.placeholder}
              options={i18n.guest.options}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name={['settings', 'internal']}
            label={i18n.internal.label}
            rules={[
              {
                required: true,
                message: i18n.internal.placeholder,
              },
            ]}
          >
            <Select
              placeholder={i18n.internal.placeholder}
              options={i18n.internal.options}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name={['settings', 'department']}
            label={i18n.department.label}
            rules={[
              {
                required: true,
                message: i18n.department.placeholder,
              },
            ]}
          >
            <Select
              placeholder={i18n.department.placeholder}
              options={i18n.department.options}
              size="large"
            />
          </Form.Item>
        </Form>
      </Spin>
    </ConfigProvider>
  );
}

export interface NotificationsFormProps {
  theme?: ThemeConfig;
  initialValues: Store;
  validateMessages?: ValidateMessages;
  loading?: boolean;
  onFinishFailed: (data: Record<string, any>) => void;
  onFinish: (data: Record<string, any>) => void;
  i18n: {
    name: {
      label: string;
      placeholder: string;
    };
    criteria: {
      title: string;
      paragraph: string;
    };
    notification: {
      title: string;
      paragraph: string;
    };
    criterias: {
      context: {
        label: string;
        placeholder: string;
        options: DefaultOptionType[];
      };
      project: {
        alert?: {
          label: string;
        };
        name: {
          label: string;
          placeholder: string;
          options: DefaultOptionType[];
        };
      };
      job: {
        type: {
          label: string;
          placeholder: string;
          options: CheckboxOptionType[];
          joiner: string;
          error: string;
        };
        assignee: {
          label: string;
          placeholder: string;
          options: CheckboxOptionType[];
          joiner: string;
          error: string;
        };
        priority: {
          label: string;
          placeholder: string;
          options: DefaultOptionType[];
        };
        department: {
          label: string;
          placeholder: string;
          options: DefaultOptionType[];
        };
        role: {
          label: string | null;
          placeholder: string;
          options: DefaultOptionType[];
        };
        action: {
          label: string;
          placeholder: string;
          options: DefaultOptionType[];
        };
        status: {
          label: string;
          placeholder: string;
          options: DefaultOptionType[];
        };
      };
    };
    definitions: {
      add: string;
      error: string;
      type: {
        label: string;
        placeholder: string;
        options: DefaultOptionType[];
      };
      roles: {
        label: string;
        placeholder: string;
        options: DefaultOptionType[];
      };
      timeframe: {
        label: string;
        time: {
          placeholder: string;
          options: DefaultOptionType[];
        };
        period: {
          placeholder: string;
          options: DefaultOptionType[];
        };
      };
    };
  };
}

const { Title, Paragraph } = Typography;

function ProjectFilters({ i18n }: { i18n: NotificationsFormProps['i18n'] }) {
  return (
    <Form.Item
      name={['notification', 'filters', 'project']}
      label={i18n.criterias.project.name.label}
      rules={[
        {
          required: true,
          message: i18n.criterias.project.name.placeholder,
        },
      ]}
    >
      <Select
        placeholder={i18n.criterias.project.name.placeholder}
        options={i18n.criterias.project.name.options}
        size="large"
      />
    </Form.Item>
  );
}

const tagRender = (props: CustomTagProps) => {
  const { label, value, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const IconSvg = Priorities[value as keyof typeof Priorities];

  return (
    <Tag
      {...props}
      onMouseDown={onPreventMouseDown}
      closable={false}
      bordered={false}
      onClose={onClose}
      style={{ marginRight: 3, fontSize: '16px' }}
    >
      <Space align="center">
        <span role="img">
          <Icon style={{ marginTop: '5px' }} component={IconSvg} />
        </span>
        {label}
      </Space>
    </Tag>
  );
};

function JobFilters({ i18n }: { i18n: NotificationsFormProps['i18n'] }) {
  const validateCheckboxGroup = (error: string) => (_: any, value: any) => {
    if (value && value.length >= 1) {
      return Promise.resolve();
    }

    return Promise.reject(error);
  };
  return (
    <>
      <Form.Item
        name={['notification', 'filters', 'type']}
        label={i18n.criterias.job.type.label}
        initialValue={[i18n.criterias.job.type.options[0].value]}
        rules={[
          {
            required: true,
            validator: validateCheckboxGroup(i18n.criterias.job.type.error),
          },
        ]}
      >
        <Checkbox.Group options={i18n.criterias.job.type.options} />
      </Form.Item>
      <Form.Item
        name={['notification', 'filters', 'assignee']}
        label={i18n.criterias.job.assignee.label}
        initialValue={[i18n.criterias.job.assignee.options[0].value]}
        rules={[
          {
            required: true,
            validator: validateCheckboxGroup(i18n.criterias.job.assignee.error),
          },
        ]}
      >
        <Checkbox.Group options={i18n.criterias.job.assignee.options} />
      </Form.Item>

      <Form.Item
        name={['notification', 'filters', 'priority']}
        label={i18n.criterias.job.priority.label}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          size="large"
          tagRender={tagRender}
          options={i18n.criterias.job.priority.options}
          placeholder={i18n.criterias.job.priority.placeholder}
          optionRender={(option) => {
            const IconSvg =
              Priorities[option.data.value as keyof typeof Priorities];
            return (
              <Space>
                <span role="img">
                  <Icon component={IconSvg} />
                </span>
                {option.data.label}
              </Space>
            );
          }}
        />
      </Form.Item>
      <Form.Item
        name={['notification', 'filters', 'department']}
        label={i18n.criterias.job.department.label}
        style={{ marginBottom: '10px' }}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.criterias.job.department.placeholder}
          options={i18n.criterias.job.department.options}
          size="large"
        />
      </Form.Item>
      <Form.Item name={['notification', 'filters', 'role']} initialValue={[]}>
        <Select
          mode="multiple"
          placeholder={i18n.criterias.job.role.placeholder}
          options={i18n.criterias.job.role.options}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={['notification', 'filters', 'action']}
        label={i18n.criterias.job.action.label}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.criterias.job.action.placeholder}
          options={i18n.criterias.job.action.options}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={['notification', 'filters', 'status']}
        label={i18n.criterias.job.status.label}
        initialValue={[]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.criterias.job.status.placeholder}
          options={i18n.criterias.job.status.options}
          size="large"
        />
      </Form.Item>
    </>
  );
}

function SubTitle({
  title,
  paragraph,
  level = 2,
}: {
  title: string;
  paragraph: string;
  level?: TitleProps['level'];
}) {
  return (
    <>
      <Title
        level={level}
        style={{
          fontFamily: 'Montserrat',
          fontWeight: '500',
        }}
      >
        {title}
      </Title>
      <Paragraph>{paragraph}</Paragraph>
    </>
  );
}

function Fieldset({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        borderLeft: '1px solid #00A693',
        paddingLeft: '16px',
        marginLeft: '10px',
        marginTop: '20px',
      }}
    >
      {children}
    </div>
  );
}

function Definitions({
  i18n,
  field,
}: {
  i18n: NotificationsFormProps['i18n'];
  field: FormListFieldData;
}) {
  const form = Form.useFormInstance();
  const definitions = Form.useWatch(['notification', 'definitions'], form);
  const filters = getValueFromDefinitions(definitions, field.name, 'filters');
  return (
    <Fieldset>
      <Form.Item name={[field.name, 'filters']} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name={[field.name, 'type']}
        label={i18n.definitions.type.label}
        rules={[{ required: true, message: i18n.definitions.type.placeholder }]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.definitions.type.placeholder}
          options={i18n.definitions.type.options}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={[field.name, 'timeframe']}
        label={i18n.definitions.timeframe.label}
        rules={[
          {
            required: true,
            message: i18n.definitions.timeframe.time.placeholder,
          },
        ]}
        style={{
          width: '150px',
          display: 'inline-block',
          marginRight: '16px',
        }}
      >
        <Select
          placeholder={i18n.definitions.timeframe.time.placeholder}
          options={i18n.definitions.timeframe.time.options.filter((item, i) => {
            if (!item.context)
              throw new Error(
                `Provide context assignment for i18n.definitions.timeframe.time.options ${i}`,
              );
            return item.context.indexOf(filters?.context) !== -1;
          })}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={[field.name, 'period']}
        style={{
          width: 'calc(100% - 166px)',
          display: 'inline-block',
          marginTop: '24px',
        }}
        rules={[
          {
            required: true,
            message: i18n.definitions.timeframe.period.placeholder,
          },
        ]}
      >
        <Select
          placeholder={i18n.definitions.timeframe.period.placeholder}
          options={i18n.definitions.timeframe.period.options.filter(
            (item, i) => {
              if (!item.context)
                throw new Error(
                  `Provide context assignment for i18n.definitions.timeframe.period.options ${i}`,
                );
              return item.context.indexOf(filters?.context) !== -1;
            },
          )}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={[field.name, 'roles']}
        label={i18n.definitions.roles.label}
        rules={[
          { required: true, message: i18n.definitions.roles.placeholder },
        ]}
      >
        <Select
          mode="multiple"
          placeholder={i18n.definitions.roles.placeholder}
          options={i18n.definitions.roles.options}
          size="large"
        />
      </Form.Item>
    </Fieldset>
  );
}

const findLabelById = (
  options: { value?: unknown; label: ReactNode }[],
  id: unknown,
  alternative: string,
) => {
  return (
    (options.find((item) => item.value === id)?.label as string) || alternative
  );
};

const getValueFromDefinitions = (
  definitions: DefaultOptionType[],
  fieldName: number,
  key: string,
) => {
  return definitions && definitions[fieldName]?.[key];
};

const filterOptionsByValues = (
  options: { value?: unknown; label: ReactNode }[],
  values: string | string[],
) => {
  return values
    ? options.filter((item) => values.indexOf(item.value as string) !== -1)
    : [];
};

const buildLabels = (
  items: { value?: unknown; label: ReactNode }[],
  alternative: string,
  joiner = '&',
) => {
  return items.length > 0
    ? items.map(({ label }) => `<strong>${label}</strong>`).join(` ${joiner} `)
    : alternative;
};

/*
This component builds a helper text based on arbritary rules (ex: "project" first filter, "job" first and second filter)  
*/

function TextHelper({
  onRemove,
  i18n,
  field,
}: {
  onRemove: () => void;
  i18n: NotificationsFormProps['i18n'];
  field: FormListFieldData;
}) {
  const form = Form.useFormInstance();
  const definitions = Form.useWatch(['notification', 'definitions'], form);
  const filters = getValueFromDefinitions(definitions, field.name, 'filters');

  // job criterias message internationalization and parsing

  const typeIds =
    filters?.type || i18n.criterias.job.type.options.map((item) => item.value);
  const assigneeIds =
    filters?.assignee ||
    i18n.criterias.job.assignee.options.map((item) => item.value);
  const context = filters?.context;

  const types = filterOptionsByValues(
    i18n.criterias.job.type.options,
    typeIds.length === 0
      ? i18n.criterias.job.type.options.map((item) => item.value)
      : typeIds,
  );
  const typesLabel = buildLabels(
    types as CheckboxOptionType[],
    `<strong>[${i18n.criterias.job.type.placeholder}]</strong>`,
    i18n.criterias.job.type.joiner,
  );
  const typeLabel = `${i18n.criterias.job.type.label} ${typesLabel}`;

  const assginees = filterOptionsByValues(
    i18n.criterias.job.assignee.options,
    assigneeIds.length === 0
      ? i18n.criterias.job.assignee.options.map((item) => item.value)
      : assigneeIds,
  );
  const assigneeLabel = buildLabels(
    assginees as CheckboxOptionType[],
    `<strong>[${i18n.criterias.job.assignee.placeholder}]</strong>`,
    i18n.criterias.job.assignee.joiner,
  );

  const assigneeLabels = `${i18n.criterias.job.assignee.label} ${assigneeLabel}`;

  // project criterias message internationalization and parsing

  const projectId = filters?.project;
  const projectName = useMemo(
    () =>
      findLabelById(
        i18n.criterias.project.name.options as {
          value?: unknown;
          label: ReactNode;
        }[],
        projectId,
        `<strong>[${i18n.criterias.project.name.placeholder}]</strong>`,
      ),
    [
      i18n.criterias.project.name.options,
      i18n.criterias.project.name.placeholder,
      projectId,
    ],
  );

  // text heading parsing

  const definitionTypeLabel = findLabelById(
    i18n.criterias.context.options as { value?: unknown; label: ReactNode }[],
    context,
    `<strong>[${i18n.criterias.context.placeholder}]</strong>`,
  );

  const START_SENTENCES = {
    project: `${i18n.criterias.project.name.label} <strong>${projectName}</strong>`,
    job: `${typeLabel} ${assigneeLabels}`,
  };

  const definitionType = `${i18n.criterias.context.label} <strong>${definitionTypeLabel}</strong>`;

  // text tail formating (definitions parsing)

  const notificationType = getValueFromDefinitions(
    definitions,
    field.name,
    'type',
  );

  const notifications = filterOptionsByValues(
    i18n.definitions.type.options as { value?: unknown; label: ReactNode }[],
    notificationType,
  );
  const notificationLabel = buildLabels(
    notifications as { value?: unknown; label: ReactNode }[],
    `<strong>[${i18n.definitions.type.placeholder}]</strong>`,
  );

  const notificationTimeLabel = findLabelById(
    i18n.definitions.timeframe.time.options as {
      value?: unknown;
      label: ReactNode;
    }[],
    getValueFromDefinitions(definitions, field.name, 'timeframe'),
    `[${i18n.definitions.timeframe.time.placeholder}]`,
  );
  const notificationPeriodLabel = findLabelById(
    i18n.definitions.timeframe.period.options as {
      value?: unknown;
      label: ReactNode;
    }[],
    getValueFromDefinitions(definitions, field.name, 'period'),
    `[${i18n.definitions.timeframe.period.placeholder}]`,
  );

  const notificationRoles = getValueFromDefinitions(
    definitions,
    field.name,
    'roles',
  );
  const roles = filterOptionsByValues(
    i18n.definitions.roles.options as { value?: unknown; label: ReactNode }[],
    notificationRoles,
  );
  const notificationRolesLabel = buildLabels(
    roles as { value?: unknown; label: ReactNode }[],
    `<strong>[${i18n.definitions.roles.placeholder}]</strong>`,
  );

  const criteriaContext = i18n.criterias[context as 'job' | 'project'];
  return (
    <>
      {criteriaContext && 'alert' in criteriaContext && (
        <Alert message={criteriaContext.alert?.label} type="success" />
      )}
      <Flex gap="small" align="flex-start" style={{ marginTop: '20px' }}>
        <CloseCircleFilled
          style={{
            fontSize: '16px',
            cursor: 'pointer',
            color: '#CDCDCD',
            paddingTop: '2px',
          }}
          onClick={onRemove}
        />
        <Paragraph
          style={{
            fontSize: 14,
            margin: 0,
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${definitionType} ${
                START_SENTENCES[context as keyof typeof START_SENTENCES]
              }, ${i18n.definitions.type.label.toLocaleLowerCase()} ${notificationLabel} ${
                i18n.definitions.timeframe.label
              } <strong>${notificationTimeLabel} ${notificationPeriodLabel}</strong> ${
                i18n.definitions.roles.label
              }  ${notificationRolesLabel}`,
            }}
          />
        </Paragraph>
      </Flex>
    </>
  );
}

export function NotificationsForm({
  theme = {
    components: {
      Alert: {
        colorSuccessBg: '#E1F5E6',
        colorSuccessBorder: undefined,
        colorText: '#286436',
        fontSize: 14,
      },
      Button: {
        textHoverBg: '#f1f1f1',
        fontWeight: 500,
        colorText: '#5C5C5C',
        fontSize: 16,
        paddingContentHorizontal: 26,
      },
      Form: {
        labelFontSize: 16,
        labelColor: '#434343',
        verticalLabelPadding: 0,
      },
      Select: {
        fontSizeLG: 16,
      },
      Input: {
        fontSizeLG: 16,
      },
      Typography: {
        colorText: '#434343',
        fontSizeHeading2: 18,
        fontSizeHeading3: 16,
      },
    },
  },
  i18n,
  initialValues,
  validateMessages,
  loading,
  onFinish,
  onFinishFailed,
}: NotificationsFormProps) {
  const [form] = Form.useForm();
  const filters = Form.useWatch(['notification', 'filters'], form);
  const context = filters?.context;

  const NOTIFICATION_STATES = {
    project: <ProjectFilters i18n={i18n} />,
    job: <JobFilters i18n={i18n} />,
  } as const;

  return (
    <ConfigProvider theme={theme}>
      <style>{`.ant-form-item .ant-form-item-label >label {
        font-weight: 500
      }`}</style>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          form={form}
          id="notifications-form"
          layout="vertical"
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={['notification', 'name']}
            label={i18n.name.label}
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.name.placeholder} size="large" />
          </Form.Item>
          <SubTitle
            title={i18n.criteria.title}
            paragraph={i18n.criteria.paragraph}
          />
          <Fieldset>
            <Form.Item
              name={['notification', 'filters', 'context']}
              label={i18n.criterias.context.label}
            >
              <Select
                placeholder={i18n.criterias.context.placeholder}
                options={i18n.criterias.context.options}
                size="large"
              />
            </Form.Item>

            {NOTIFICATION_STATES[context as keyof typeof NOTIFICATION_STATES]}
          </Fieldset>
          <Form.List
            name={['notification', 'definitions']}
            rules={[
              {
                validator: async (_, definitions) => {
                  if (!definitions || definitions.length < 1) {
                    return Promise.reject(new Error(i18n.definitions.error));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                <Form.Item style={{ textAlign: 'right' }}>
                  <Button
                    size="large"
                    style={{ backgroundColor: '#f1f1f1' }}
                    type="text"
                    onClick={() => {
                      form
                        .validateFields([['notification', 'filters']], {
                          recursive: true,
                        })
                        .then(() => add({ filters }))
                        .catch((error) => undefined);
                    }}
                  >
                    {i18n.definitions.add}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
                {fields.map((field, i) => {
                  return (
                    <Fragment key={field.key}>
                      <SubTitle
                        title={`${i18n.notification.title} ${field.name + 1}`}
                        paragraph={i18n.notification.paragraph}
                      />
                      <TextHelper
                        i18n={i18n}
                        field={field}
                        onRemove={() => remove(field.name)}
                      />
                      <Definitions field={field} i18n={i18n} />
                      {i + 1 < fields.length && <Divider />}
                    </Fragment>
                  );
                })}
              </>
            )}
          </Form.List>
        </Form>
      </Spin>
    </ConfigProvider>
  );
}
