import { gql } from '@apollo/client';

export const UPDATE_CLEANING_EXCEPTION_STATUS = gql`
  mutation updateCleaningExceptionStatus($cleaningExceptionInput: CleaningExceptionInput!) {
		updateCleaningExceptionStatus(cleaningExceptionInput: $cleaningExceptionInput) {
			locationId
			cleaningExceptionStatus {
				doNotDisturb
				doubleLockActive
				noServiceRequired
				sleepOutNoShow
			}
		}
	}
`;