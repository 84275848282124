/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { PropertyContext } from '@optii/shared';
import { GET_PROPERTY_BY_ID } from '@optii/shared/queries/property/getPropertyById';

const Context = React.createContext({});

const Provider = function (props) {
  const { property } = useContext(PropertyContext.Context);
  const id = property?.id;
  const { data } = useQuery(GET_PROPERTY_BY_ID, {
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
    skip: !id,
  });
  return (
    <Context.Provider
      value={{
        property: data?.property ?? props.property,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export const PropertyDetailsContext = { Provider, Context };
