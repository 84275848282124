import React, { useMemo, useState } from 'react';
import { Session } from '@optii/shared';
import usePushNotifications from 'utils/usePushNotifications';
import useTwilio from 'utils/useTwilio';
import useAnalytics from 'utils/useAnalytics';
import Routes from './routes';

const Main = function Main({ globalSnack }) {
  const [prevPage, setPrevPage] = useState(false);

  const [isMobileMenu, setMobileMenu] = useState(false);

  useAnalytics();
  usePushNotifications();

  // init twilio and manage unread messages
  const {
    twilioClient,
    unreadMsgsCount,
    setUnreadMsgsCount,
    calcUnreadMsgsCount,
  } = useTwilio();

  const memoisedContext = useMemo(
    () => ({
      prevPage,
      isMobileMenu,
      setPrevPage,
      toggleMobileMenu: setMobileMenu,
      globalSnack,
      twilioClient,
      unreadMsgsCount,
      setUnreadMsgsCount,
      calcUnreadMsgsCount,
    }),
    [
      prevPage,
      isMobileMenu,
      setPrevPage,
      setMobileMenu,
      globalSnack,
      twilioClient,
      unreadMsgsCount,
      setUnreadMsgsCount,
      calcUnreadMsgsCount,
    ],
  );

  return (
    <Session.Provider value={memoisedContext}>
      <Routes />
    </Session.Provider>
  );
};

export default Main;
