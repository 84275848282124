
import {
    Button,
    COLORS,
    Card,
    ConfigProvider,
    FONTS,
    Flex,
    FormItem,
    SPACING,
    Typography,
    FormListFieldData,
    FormInstance,
} from '@optii-solutions/ui-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    EventContext,
    Notification,
} from '../../../types/notifications';

import { FormList } from './FormList';

type SectionProps = {
    title: string;
    subtitle: string;
};

function ProjectInfo({ info }: { info: string }) {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: COLORS.secondary[1],
                    colorText: COLORS.secondary[8],
                    fontSize: FONTS.small.size,
                },
                components: {
                    Card: {
                        paddingLG: SPACING.SIZE_SM,
                        marginXS: 12,
                    },
                },
            }}
        >
            <Card
                style={{
                    marginTop: SPACING.SIZE_MD,
                }}
            >
                <Typography.Text
                    style={{
                        letterSpacing: FONTS.small.letterSpacing,
                    }}
                >
                    {info}
                </Typography.Text>
            </Card>
        </ConfigProvider>
    );
}

function Section({ title, subtitle }: SectionProps) {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Typography: {
                        colorText: COLORS.neutral[9],
                        colorTextHeading: COLORS.neutral[9],
                    },
                },
            }}
        >
            <Typography.Title
                style={{
                    letterSpacing: FONTS.h5.letterSpacing,
                    fontFamily: 'Montserrat',
                    marginTop: SPACING.NONE,
                }}
                level={5}
            >
                {title}
            </Typography.Title>
            <Typography.Text
                style={{
                    letterSpacing: FONTS.large.letterSpacing,
                }}
            >
                {subtitle}
            </Typography.Text>
        </ConfigProvider>
    );
}

export function RenderIf({ condition, children }: { condition: boolean, children: React.ReactNode }) {
    return (condition ? children : null);
}

type Props = {
    add: (defaultValue?: any, insertIndex?: number | undefined) => void;
    remove: (index: number | number[]) => void;
    actionFields: FormListFieldData[];
    eventField: FormListFieldData;
    form: FormInstance<Notification>;
    contextDidUpdate: (eventField: FormListFieldData) => (prevValues: Notification, currentValues: Notification) => boolean;
};

function Actions(props: Props) {
    const { actionFields, eventField, remove, add, form, contextDidUpdate } = props;
    const { t } = useTranslation(['rules', 'common']);

    const PROJECT_INFO_TEXT = t(
        'rules:Projects end at 11:59PM on their due date. Notifications relating to due date will be sent at 12:00 AM after project due date.',
    );

    const contextField = ['userEventActionRules', eventField.name, 'event', 'context'];


    return (
        <FormItem<Notification>
            noStyle
            shouldUpdate={contextDidUpdate(eventField)}
        >
            {({ getFieldValue }) => (
                <>
                    <RenderIf
                        condition={
                            [EventContext.ProjectCycle, EventContext.Job].includes(getFieldValue(contextField))
                        }
                    >
                        <Section
                            title={t('common:Notifications')}
                            subtitle={t('common:Define the notifications and who will receive them')}
                        />
                    </RenderIf>
                    <RenderIf
                        condition={[EventContext.TemperatureTask, EventContext.NumericalTask].includes(getFieldValue(contextField))}
                    >
                        <Section
                            title={t('common:Notifications')}
                            subtitle={t('common:When entry is outside of acceptable range.')}
                        />
                        <div>&nbsp;</div>
                    </RenderIf>

                    <RenderIf condition={getFieldValue(contextField) === EventContext.ProjectCycle}>
                        <ProjectInfo info={PROJECT_INFO_TEXT} />
                    </RenderIf>

                    {actionFields.map((actionField) => (
                        <FormList
                            eventContext={getFieldValue(contextField)}
                            actionField={actionField}
                            field={eventField}
                            remove={remove}
                            length={actionFields.length}
                            form={form}
                            key={actionField.key}
                            add={add}
                            actionFields={actionFields}                            
                        />
                    ))}
                    <RenderIf
                        condition={
                            [EventContext.ProjectCycle, EventContext.Job].includes(getFieldValue(contextField))
                        }
                    >
                        <Flex align="center" justify="end">
                            <Button type="primary" onClick={() => add()}>
                                {t('common:Add Notification')}
                            </Button>
                        </Flex>
                    </RenderIf>
                </>
            )}
        </FormItem>
    );
}

export default Actions;