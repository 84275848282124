import { COLORS, SPACING } from '@optii-solutions/ui-library';

export const SIZES = {
  large: 675,
  medium: 564,
  small: 492,
};

export const STYLES: { [key: string]: React.CSSProperties } = {
  drawerBody: {
    padding: SPACING.NONE,
  },

  collapse: {
    padding: SPACING.NONE,
  },

  collapseIcon: {
    marginRight: SPACING.SIZE_L + 5,
    marginTop: SPACING.SIZE_SM,
  },

  panel: {
    minWidth: '100%',
    padding: SPACING.NONE,
    background: COLORS.neutral[3],
    borderTop: `1px ${COLORS.neutral[5]} solid`,
  },

  header: {
    borderRadius: 0,
    minWidth: '100%',
    padding: `${SPACING.SIZE_XXS}px ${SPACING.SIZE_L}px`,
    paddingRight: `${SPACING.SIZE_XXL}px`,
  },

  itemChildren: {
    background: COLORS.neutral[1],
    padding: SPACING.SIZE_XL,
    margin: `-${SPACING.SIZE_MS}px`,
    borderTop: `1px ${COLORS.neutral[5]} solid`,
    maxHeight: 'unset',
  },

  descriptionContent: {
    paddingBottom: SPACING.SIZE_XL,
  },

  descriptions: {
    padding: SPACING.NONE,
    maxHeight: 'unset',
    overflow: 'auto',
  },
};

export const TAB_COLUMNS = {
  xxl: 2,
  xl: 2,
  lg: 2,
  md: 2,
  sm: 2,
  xs: 1,
};
