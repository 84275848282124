import moment from 'moment';
import { RRule } from 'rrule';
import { getWeekDays } from 'utils/constants/repeatingRule';
import { getDaysPositionInMonth } from './projects';

// cycle can be the following structure:

// Absolute Constants
const DAY_HOURS = 24;

const dayOfMonthPosition = [
  {
    position: 'first',
    id: 1,
  },
  {
    position: 'second',
    id: 2,
  },
  {
    position: 'third',
    id: 3,
  },
  {
    position: 'fourth',
    id: 4,
  },
  {
    position: 'last',
    id: -1,
  },
];

// helper functions
function getTime(dueByTime) {
  if (!dueByTime) throw new Error('dueByTime is missing.');

  if (!moment.isMoment(dueByTime)) throw new Error('Invalid dueByTime param.');

  return {
    startHour: Number(dueByTime.format('HH')),
    startMinutes: Number(dueByTime.format('m')),
  };
}

// GET the quarter of the month
function getQuarterOfMonth(position) {
  return dayOfMonthPosition.find((p) => p.position === position).id;
}

// GET formatted RRule based on weekday.
function getCurrentRRuleDay(startDate) {
  const currentWeekDay = startDate.day();
  // No need to pass translate, may be wise to split up keys and labels and join them on a separate export const.
  const weekdays = Object.values(getWeekDays());
  return weekdays[currentWeekDay].key;
}
// GET occurrence if yearly cadence has specific repeat cycle
function getYearlyRepeatOccurrence(repeatDate) {
  return {
    bymonth: repeatDate.getMonth() + 1,
    bymonthday: repeatDate.getDate(),
  };
}

// handlers
export const createMultipleDailyRule = (job) => {
  const cycle = job?.cycle;
  if (!cycle) {
    throw new Error('Cycle is missing');
  }

  try {
    const { startHour, startMinutes } = getTime(job.dueByTime);
    const byHour = [startHour];
    // get occurrences from startHour based on repeatEvery and
    for (const hour of byHour) {
      if (hour + cycle.repeatEvery < DAY_HOURS) {
        byHour.push(cycle.repeatEvery + hour);
      }
    }

    const repeatingRule = new RRule({
      freq: RRule.DAILY,
      interval: 1,
      byhour: cycle.timesPerDay ? byHour.splice(0, cycle.timesPerDay) : byHour,
      byminute: startMinutes,
    });

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createDailyCadenceRule = (job) => {
  const cycle = job?.cycle;
  if (!cycle) throw new Error('Cycle is missing');
  try {
    const repeatingRule = new RRule({
      freq: RRule.DAILY,
      interval: cycle.interval,
    });

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createWeeklyCadenceRule = (job) => {
  const cycle = job?.cycle;
  if (!cycle) throw new Error('Cycle is missing');

  try {
    const rule = {
      freq: RRule.WEEKLY,
      interval: cycle.interval,
    };

    if (cycle.byweekday) {
      rule.byweekday = cycle.byweekday.map((day) => RRule[day]);
    }

    const repeatingRule = new RRule(rule);

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

// confirm with team to see what occurrences of month will be regarded.
export const createMonthlyCadenceRule = (job) => {
  const cycle = job?.cycle;
  if (!cycle) throw new Error('Cycle is missing');

  try {
    const { startDate } = job;
    const dateNumber = startDate.date();
    let byMonthDay;
    let byWeekday;
    if (cycle.byWeekDay) {
      const dayPosition = getDaysPositionInMonth(startDate);
      const quarter = getQuarterOfMonth(dayPosition);
      const currentDay = getCurrentRRuleDay(startDate);
      byWeekday = currentDay.nth(quarter);
    }
    if (cycle.byMonthDay) {
      byMonthDay = cycle.byMonthDay && dateNumber;
    }

    const repeatingRule = new RRule({
      freq: RRule.MONTHLY,
      interval: cycle.interval,
      bymonthday: byMonthDay,
      byweekday: byWeekday,
    });

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const createYearlyCadenceRule = (job) => {
  const cycle = job?.cycle;
  if (!cycle) throw new Error('Cycle is missing');
  let repeatCycle;
  try {
    let dueByTime;

    if (job?.dueByTime) {
      dueByTime = getTime(job?.dueByTime);
    }

    if (cycle.repeatOn) {
      repeatCycle = getYearlyRepeatOccurrence(new Date(cycle.repeatOn));
    }

    const repeatingRule = new RRule({
      freq: RRule.YEARLY,
      interval: cycle.interval,
      ...repeatCycle,
      ...(dueByTime?.startHour && { byhour: [dueByTime.startHour] }),
      ...(dueByTime?.startMinutes && { byminute: [dueByTime.startMinutes] }),
    });

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getRepeatingRuleToText = (repeatingRule) => {
  if (!repeatingRule?.isFullyConvertibleToText()) {
    throw Error('Invalid Repeating Rule');
  }
  return repeatingRule?.toText();
};

export const decodeDailyCadenceFromText = (cycleString) => {
  if (!cycleString) {
    return {};
  }
  const rrule = RRule.fromString(cycleString);

  const { origOptions } = rrule;
  let multipleTimesPerDay = false;
  let interval;
  let timesPerDay;

  if (origOptions.byhour) {
    multipleTimesPerDay = true;
    timesPerDay = origOptions.byhour.length;

    if (origOptions.byhour.length > 1) {
      interval = origOptions.byhour[1] - origOptions.byhour[0];
    } else if (origOptions.byhour.length === 1) {
      interval = 1;
    }
  }

  return {
    multipleTimesPerDay,
    interval,
    timesPerDay,
  };
};

export const decodeWeeklyCadenceFromText = (text) => {
  const rule = RRule.fromString(text);
  const decodedRule = rule.origOptions;

  if (decodedRule.byweekday) {
    const weekDays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
    decodedRule.byweekday = decodedRule.byweekday.map(
      (day) => weekDays[day.weekday],
    );
  }

  return decodedRule;
};

export const decodeMonthlyCadenceFromText = (text) => {
  if (!text) {
    return {};
  }
  const rule = RRule.fromString(text);
  const ruleOptions = rule.origOptions;

  if (ruleOptions.byweekday?.length > 0) {
    return {
      interval: rule.origOptions.interval || 1,
      dayOfMonth: 2,
    };
  }
  if (ruleOptions.bymonthday) {
    return {
      interval: rule.origOptions.interval || 1,
      dayOfMonth: 1,
    };
  }

  return {
    interval: 1,
    dayOfMonth: null,
  };
};

export const decodeYearlyCadenceFromText = (text) => {
  const rule = RRule.fromString(text);
  const decodedRule = rule.origOptions;

  return decodedRule;
};
