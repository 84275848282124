import { useEffect } from "react";

/**
 * Hook that handles clicks outside of the passed array of ref
 */
function useOutsideClick(refs, onClick) {
  function handleClickOutside(event) {
    const instance = refs.find(
      ref => !ref.current || ref.current.contains(event.target)
    );
    if (!instance) {
      onClick();
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

export default useOutsideClick;
