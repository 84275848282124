import { useEffect, useState } from 'react';

export const useKeyDown = (targetKey: number | number[], handler: () => void) => {
  const keys = Array.isArray(targetKey) ? targetKey : [targetKey];
  const [keysDown, setKeysDown] = useState<number[]>([]);

  function downHandler({ which }: KeyboardEvent) {
    const hasKey = keys.includes(which);
    if (hasKey) {
      setKeysDown((prev) => prev.concat([which]));
    }
  }

  const upHandler = ({ which }: KeyboardEvent) => {
    const hasKey = keys.includes(which);
    if (hasKey) {
      setKeysDown((prev) => prev.filter((item) => which !== item));
    }
  };

  useEffect(() => {
    if (!keys.find((ki) => !keysDown.includes(ki))) {
      handler();
    }
  }, [keysDown]);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
};
