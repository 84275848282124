import React, { useState, useContext } from 'react';
import { useMedia } from 'react-media';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Session, DetailsContext, GA_EVENTS } from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { formatUpdateRepeatingJobInput } from '@optii/jobs/formatters/repeatingJobs';

import FieldBlock from '@optii/topcat-client/blocks/Field';
import InputGroup from '@optii/topcat-client/components/shared/form/InputGroup';
import breakpoints from '@optii/topcat-client/theme/breakpoints';

const Wrap = styled.div`
  height: ${(props) => props.height}px;
`;

export function AddTemplateNote(props) {
  const { job, updateRepeatingJob, asset, refetch, updateRepeatingJobLoading } =
    useContext(DetailsContext);
  const { t } = useTranslation(['jobs', 'common']);
  const [note, setNote] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState(null);

  const { globalSnack } = useContext(Session);

  const { jobTemplate } = job;

  const jobItemId = asset?.assetType?.jobItem?.id;

  const initialValues = {
    id: job.id,
    version: job.version,
    status: 'active',
    cycle: {
      displayName: job.cycle.displayName,
      frequency: job.cycle.frequency,
      type: job.cycle.type,
      value: job.cycle.value,
    },
    startDate: job.startDate,
    endDate: job.endDate ? job.startDate : null,
    jobTemplate: {
      item: {
        id: jobItemId ?? jobTemplate.item,
        displayName: jobTemplate.item,
      },
      attachments: jobTemplate.attachments,
      type: jobTemplate.type,
      action: jobTemplate.action,
      priority: {
        id: jobTemplate.priority,
      },
      dueByTime: jobTemplate.dueByTime,
      status: {
        id: jobTemplate.status,
      },
      department: jobTemplate.department,
      roles: jobTemplate.role && {
        ...jobTemplate.role,
        displayName: jobTemplate.role.name,
      },
      checklistTemplates: jobTemplate.checklists,
      assignee: jobTemplate.assignee,
      notes: jobTemplate.notes,
      location: jobTemplate.locations.map((prev) => ({
        ...prev,
        id: prev.id,
      })),
    },
  };

  const isMobile = useMedia({
    query: `(max-width: ${breakpoints.mdMax})`,
  });

  function handleChange(e) {
    e.preventDefault();
    setNote(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const updateRepeatingJobInput =
      formatUpdateRepeatingJobInput(initialValues);

    updateRepeatingJobInput.jobTemplate.notes.push({ note });
    try {
      await updateRepeatingJob({
        variables: {
          updateRepeatingJobInput,
        },
      });
      setNote('');

      refetch();
      GoogleAnalyticsClient.event(GA_EVENTS.addNoteJob);
    } catch (err) {
      console.error("Couldn't add note!", err);
      globalSnack({
        message: t(`jobs:There was a problem adding your note`),
        timeout: 5000,
        error: true,
      });
    }
  }
  return (
    <Wrap height={textAreaHeight} {...props}>
      <FieldBlock>
        <FieldBlock.Label htmlFor="note">{t('jobs:Add Note')}</FieldBlock.Label>
        <InputGroup
          buttonLabel={t('common:Add')}
          sendHandler={handleSubmit}
          value={note}
          onChange={handleChange}
          type="textarea"
          maxRows={5}
          disabled={!note.trim() || updateRepeatingJobLoading}
          heightChangeHandler={(height) => setTextAreaHeight(height)}
          handleKeyDown={(e) => {
            const isModifierKey = e.shiftKey;
            if (e.key === 'Enter' && !isModifierKey && note && !isMobile) {
              e.preventDefault();
            }
          }}
        />
      </FieldBlock>
    </Wrap>
  );
}
