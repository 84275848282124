import {
  Button,
  ConfigProvider,
  Flex,
  Input,
  ThemeConfig,
  Typography,
} from 'antd';
import { DrawerButton } from '../../molecules/Buttons/Buttons.component';
import { ReactNode } from 'react';

export interface TopHeaderProps {
  theme?: ThemeConfig;
  i18n: {
    placeholder: string;
    title: string;
    action?: string;
    drawer?: string;
    cancel?: string;
    submit?: string;
  };
  open?: boolean;
  formId: string;
  drawerChildren?: ReactNode;
  disableButtons: boolean;
  onSearch?: (value: string) => void;
  onSearchChange: (value: string) => void;
  onOpen?: () => void;
  onClose: () => void;
}

const { Search } = Input;

export function TopHeader({
  theme = {
    components: {
      Button: {
        paddingContentHorizontal: 18,
      },
      Typography: {
        fontSizeHeading1: 28,
        fontSizeHeading3: 24,
      },
      Input: {
        fontSizeLG: 16,
        activeBg: 'white',
        hoverBg: 'white',
      },
    },
  },
  formId = 'notifications-form',
  i18n: {
    placeholder = 'Search for actions...',
    action = 'Add action',
    title = 'Enter title',
    cancel = 'Cancel',
    submit = 'Submit',
    drawer = 'Add action',
  },
  open,
  drawerChildren,
  disableButtons,
  onSearch,
  onSearchChange,
  onOpen,
  onClose,
}: TopHeaderProps) {
  return (
    <ConfigProvider theme={theme}>
      <Flex gap="middle" justify="space-between" align="end">
        <Typography.Title level={1} style={{ fontFamily: 'Montserrat' }}>
          {title}
        </Typography.Title>
        <Flex gap="middle">
          {onSearch && (
            <Search
              placeholder={placeholder}
              onChange={(e) => onSearchChange(e.currentTarget.value)}
              onSearch={onSearch}
              size="large"
              style={{ minWidth: '264px' }}
            />
          )}
          {onOpen && (
            <Button onClick={onOpen} type="primary" size="large">
              {action}
            </Button>
          )}
        </Flex>
      </Flex>
      {drawerChildren && (
        <DrawerButton
          title={drawer}
          open={open}
          cancel={cancel}
          submit={submit}
          disableButtons={disableButtons}
          formId={formId}
          onClose={onClose}
        >
          {drawerChildren}
        </DrawerButton>
      )}
    </ConfigProvider>
  );
}
