import React from 'react';
import { Skeleton } from '@optii-solutions/ui-library';

export function LocationFormSkeleton() {
  return (
    <>
      <Skeleton.Input active />
      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
          width: 120,
        }}
      />
      <Skeleton.Input block active />

      <br />
      <br />
      <br />
      <Skeleton.Input active />
    </>
  );
}
