import { gql } from "@apollo/client";

export default gql`
  mutation updateScheduleEntry($id: ID!, $input: UpdateScheduleEntry!) {
    updateScheduleEntry(id: $id, input: $input) {
      id
      employeeID
      employmentTypeID
      departmentID
      roleID
      isAvailabe
      date
      timeStartSec
      timeStopSec
    }
  }
`;
