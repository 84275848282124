import styled from 'styled-components/macro';

export const ButtonLinkStyled = styled.button`
  color: ${(props) => props.theme.colors.teal};
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.weak}
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
`;

export const ButtonQuadStyled = styled(ButtonLinkStyled as any)`
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.weights.medium}
  text-decoration: none;
  margin-inline-end: 1rem;
  cursor: pointer;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.charcoal};
  }
`;
