import styled from 'styled-components/macro';
import { ReactComponent as Exit } from '../../../images/svg/close.svg';
import {
  ModifiersConfig,
  applyStyleModifiers,
} from 'styled-components-modifiers';
import Modal from '../Modal';

const MODIFIER_CONFIG: ModifiersConfig = {
  fullWidthMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
        max-width: 100%;
        min-width: 32rem;
        border-radius: 0;
    }   
  }`,
  mediumSize: ({ theme }) => `
    min-width: 50rem;
  `,
  wide: ({ theme }) => `
      width: 95%;
      height: 95%;
      min-width: 32rem;
      max-width: ${theme.sizes.modalWide};
  },
  `,
  noPadding: () => `
    padding-left: 0;
    padding-right: 0;
    & ${Header}, & ${Footer} {
      padding: 0 2rem;
    }
  `,
  footerShadow: ({ theme }) => `
    padding-bottom: 0;
    & ${Footer} {
      padding: 2.5rem 2rem;
      background: ${theme.colors.white};
      ${theme.shadows.up};
    }
  `,
  withHeight: () => `
    height: 16.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
};

export const Close = styled(Exit)`
  align-self: flex-start;
  margin-bottom: 0.8rem;
`;

export const Title = styled.h2`
  text-align: left;
  margin-top: 0;
  display: contents;
  ${(props) => props.theme.fonts.type.secondary}
  ${(props) => props.theme.fonts.sizes.extraLarge}
  ${(props) => props.theme.fonts.weights.weak}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConfirmationModal = styled(Modal)<{ zIndex?: string }>`
  max-height: 95%;
  max-width: 95%;
  ${applyStyleModifiers(MODIFIER_CONFIG)}
  z-index: ${({ zIndex }) => zIndex ?? '999999'};
`;
