import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

const FilterByText = styled.span`
  min-width: 9rem;
  padding-left: 1.2rem;
  ${(props) => props.theme.fonts.sizes.large};
  ${(props) => props.theme.fonts.weights.medium};
`;

function Text() {
  const { t } = useTranslation('fields');

  return <FilterByText>{t('fields:Filter by')}</FilterByText>;
}

export default Text;
