import React from 'react';
import Icons from 'blocks/Icons';
import { JOB_STATUS } from 'utils/constants';

const priorities = {
  highest: Icons.Priority.Highest,
  high: Icons.Priority.High,
  medium: Icons.Priority.Medium,
  low: Icons.Priority.Low,
  cancelled: Icons.Priority.Cancelled,
};
const onHoldPriorities = {
  highest: Icons.Priority.HighestOnHold,
  high: Icons.Priority.HighOnHold,
  medium: Icons.Priority.MediumOnHold,
  low: Icons.Priority.LowOnHold,
  cancelled: Icons.Priority.Cancelled,
};
export const titles = {
  highest: 'Highest Priority',
  high: 'High Priority',
  medium: 'Medium Priority',
  low: 'Low Priority',
  cancelled: 'Cancelled',
};
function Priority(props) {
  const { job, isFromTeamMemberView, escalationPriority } = props;

  // Cancelled status doesn't use priority
  let level;

  if (job && job.status === 'cancelled') {
    level = 'cancelled';
  } else level = job ? job.priority : escalationPriority.toLowerCase();

  let Item;
  if (job && job.status === JOB_STATUS.on_hold && isFromTeamMemberView) {
    Item = onHoldPriorities[level];
  } else {
    // Use same icon as cancelled for "all" when it comes to escalations
    level = level === 'all' ? 'cancelled' : level;
    Item = priorities[level];
  }
  if (Item)
    return (
      <Item
        modifiers={[
          [JOB_STATUS.completed, JOB_STATUS.archived].includes(
            job && job.status,
          ) && 'completed',
        ]}
        data-testid={`${titles[level]} Icon`}
        title=""
        {...props}
      />
    );

  console.error('Could not find icon for priority:', level);
  return null;
}
export default Priority;
