import { Skeleton, SPACING } from '@optii-solutions/ui-library';
import { TJobFormMode } from './JobForm.types';

type TJobFormSkeletonProps = {
  mode?: TJobFormMode;
};

const editSkeleton = (
  <div
    style={{
      maxHeight: '100%',
      overflow: 'hidden',
    }}
  >
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.NONE,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_SM,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_SM,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
      style={{
        marginBottom: SPACING.NONE,
        height: SPACING.SIZE_SM,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_SM,
        height: SPACING.SIZE_XL,
      }}
    />
  </div>
);

const addSkeleton = (
  <>
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_L,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
    />
    <Skeleton.Input block active />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_XL,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_L,
      }}
    />

    <Skeleton
      title={false}
      paragraph={{
        rows: 1,
        width: 120,
      }}
    />
    <Skeleton.Input
      block
      active
      style={{
        marginBottom: SPACING.SIZE_L,
      }}
    />
  </>
);

export function JobFormSkeleton(props: TJobFormSkeletonProps) {
  const { mode } = props;

  return mode === 'edit' ? editSkeleton : addSkeleton;
}
