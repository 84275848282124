import styled from 'styled-components/macro';
import { ReactComponent as Info } from 'images/svg/info.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

const InfoIcon = styled(Info)`
  height: 2rem;
  width: 2rem;
  ${applyStyleModifiers(CONFIG)};
`;
export default InfoIcon;
