import React from 'react';

export function Leaving() {
  return (
    <svg
      width="20"
      height="31"
      viewBox="0 0 20 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_3902_9476" fill="white">
        <path d="M16 22.5C16 23.0523 15.5523 23.5 15 23.5H5C4.44771 23.5 4 23.0523 4 22.5L4 8.5C4 7.94771 4.44772 7.5 5 7.5H15C15.5523 7.5 16 7.94771 16 8.5L16 22.5Z" />
      </mask>
      <path
        d="M15 22.5H5V24.5H15V22.5ZM5 22.5L5 8.5H3L3 22.5H5ZM5 8.5L15 8.5V6.5H5V8.5ZM15 8.5L15 22.5H17V8.5H15ZM15 8.5H15H17C17 7.39543 16.1046 6.5 15 6.5V8.5ZM5 8.5V6.5C3.89543 6.5 3 7.39543 3 8.5H5ZM5 22.5H3C3 23.6046 3.89543 24.5 5 24.5V22.5ZM15 24.5C16.1046 24.5 17 23.6046 17 22.5H15V24.5Z"
        fill="#158EB6"
        mask="url(#path-1-inside-1_3902_9476)"
      />
      <rect
        x="16"
        y="21.5"
        width="1"
        height="12"
        transform="rotate(-180 16 21.5)"
        fill="white"
      />
      <path
        d="M9.64645 15.1464C9.45118 15.3417 9.45118 15.6583 9.64645 15.8536L12.8284 19.0355C13.0237 19.2308 13.3403 19.2308 13.5355 19.0355C13.7308 18.8403 13.7308 18.5237 13.5355 18.3284L10.7071 15.5L13.5355 12.6716C13.7308 12.4763 13.7308 12.1597 13.5355 11.9645C13.3403 11.7692 13.0237 11.7692 12.8284 11.9645L9.64645 15.1464ZM10 16L17 16L17 15L10 15L10 16Z"
        fill="#FBC633"
      />
    </svg>
  );
}

export function Person() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="22" rx="2" fill="#1380A4" />
      <path
        d="M14.6601 14.5699L11.6364 13.5153V12.5594C11.6364 12.4629 11.6677 12.3755 11.7223 12.3204C12.2238 11.8119 12.6225 11.0985 12.8331 10.3403C13.1838 10.2219 13.447 9.88775 13.4991 9.46956L13.5741 8.86664C13.6344 8.38119 13.3875 7.92314 13.0003 7.73992V6.99547C13.0003 5.9771 12.3026 5.13769 11.4127 5.0403L11.0988 4.33937C10.9758 4.06576 10.6777 3.93154 10.4192 4.03471L8.58318 4.76638C7.71018 5.11395 7 6.23824 7 7.27273V7.73961C6.61245 7.92314 6.36564 8.38089 6.42618 8.86633L6.50118 9.46926C6.55327 9.88744 6.81645 10.2219 7.16718 10.34C7.37745 11.0982 7.77645 11.8116 8.27773 12.3201C8.33227 12.3758 8.36364 12.4629 8.36364 12.5594V13.5153L5.33991 14.5699C4.53836 14.849 4 15.6662 4 16.6033V17.6956C4 17.864 4.12218 18 4.27273 18H15.7273C15.8778 18 16 17.864 16 17.6956V16.6033C16 15.6662 15.4616 14.849 14.6601 14.5699Z"
        fill="#FBC633"
      />
    </svg>
  );
}
