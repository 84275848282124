import styled from 'styled-components';

export const SuggestedWrapper = styled.div<{absolutePosition?: boolean, margin?: string}>`
  margin: ${({ margin }) => margin ? margin : '0.8rem 2.8rem'};
  display: flex;
  align-items: center;
  gap: 0.8rem;
  ${({ absolutePosition }) => absolutePosition && `
    position: absolute;
    bottom: -2rem;`
  }
`;

export const SuggestedTime = styled.div`
  min-width: 71px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  color: var(--components-form-text-label, #595959);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.235px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-5, #D9D9D9);
  background: var(--Neutral-3, #F5F5F5);
  cursor: pointer;
`;
