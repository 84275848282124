// Auth related
export const AUTH_ACCEPT_REFER = 'acceptInviteReferPath';
export const AUTH_INVITE_ID = 'inviteId';
export const AUTH_SI_ERR = 'signInAuthError';
export const AUTH_ERROR_USER_ID = 'noUserId';
export const AUTH_ERROR_ACCESS = 'noAccess';
export const AUTH_ERRORS_MAP = {
  general:
    'An error occurred authenticating user, please contact your administrator',
  [AUTH_ERROR_USER_ID]: 'No valid user ID found on the auth token',
  [AUTH_ERROR_ACCESS]:
    'You are no longer an active user.  If this is an error, please contact your manager.',
};
