import React from 'react';
import styled from 'styled-components/macro';
import Priority from 'components/shared/Priority';
import { useQueryParams, StringParam } from 'use-query-params';
import {
  getTeamMember,
  getPriority,
  getTooltipPosition,
} from 'utils/formatters/jobs';
import { ReactComponent as Photo } from 'images/svg/photo.svg';
import Edit from 'components/Jobs/Shared/Edit';
import Delete from 'components/Jobs/Shared/Delete';
import Tooltip from 'components/shared/Tooltip';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProjectJobIcon } from 'images/svg/ico-project-job.svg';
import { ReactComponent as RepeatingJobIcon } from 'images/svg/ico-repeating-job.svg';

const CardContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.shadows.default}
  ${(props) => props.theme.borders.shadow}
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 1rem 1rem 0.8rem 1rem;
  position: relative;
  ${(props) =>
    props.isEscalated &&
    `border: 0.1rem solid ${props.theme.colors.roseMedium}`}
`;
const Top = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
`;
const UpperLeft = styled.div`
  display: flex;
`;
const UpperRight = styled.div`
  display: flex;
  margin-left: auto;
  align-items: baseline;
`;
const JobNumber = styled.div`
  margin-left: 0.5rem;
  color: ${(props) => props.theme.colors.charcoal05};
`;
const Description = styled.div`
  ${(props) => props.theme.fonts.sizes.largest}
  ${(props) => props.theme.fonts.weights.strong}
  ${(props) => props.theme.fonts.type.secondary}
  color: ${(props) => props.theme.colors.tealMed};
  margin-bottom: 0.7rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;
const Note = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 1rem 0 !important;
  ${(props) => props.theme.fonts.sizes.small}
`;
const Bottom = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;
const DueBy = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 1rem;
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.isLongDepartmentCode && 'flex-direction: column;'}
`;
const DueByLabel = styled.div`
  ${(props) => props.theme.fonts.weights.medium}
  margin-right: 0.4rem;
  ${(props) => props.overdue && `color: ${props.theme.colors.rose}`}
`;
const DueByTime = styled.div`
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.overdue && `color: ${props.theme.colors.rose}`}
`;
const Assignment = styled.div`
  display: flex;
  margin-left: auto;
  align-items: end;
  justify-content: end;
  width: 100%;
  padding-left: 1rem;
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) =>
    props.isLongDepartmentCode &&
    'flex-direction: column; align-items: flex-end;'}
`;
const Unassigned = styled.div``;
const Department = styled.div`
  padding-right: 0.5rem;
  border-right: 1px solid ${(props) => props.theme.colors.charcoal05};
  ${(props) =>
    props.isLongDepartmentCode &&
    `border: none;
    padding-right: 0;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}
`;
const TeamMember = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => !props.isLongDepartmentCode && 'padding-left: 0.5rem;'}
`;

const PhotoIcon = styled(Photo)`
  margin: 0 1rem;
`;

const EditBtn = styled(Edit)`
  cursor: default;
`;

const ProjectIcon = styled(ProjectJobIcon)`
  margin-left: 0.5rem;
  width: 1.5rem;
  height: auto;
`;

const RepeatingIcon = styled(RepeatingJobIcon)`
  margin-left: 1rem;
  width: 2rem;
  height: auto;
`;

// TODO: uncomment if needed for next stories
// function CardTime({ job, toPropertyTime, isLongDepartmentCode }) {
//     const { t } = useTranslation("common");
//     let time;
//     let label;
//     if (isDone(job) && job.completedAt) {
//         time = toPropertyTime(job.completedAt);
//         label = `${t("common:Done")}:`;
//     } else {
//         time = toPropertyTime(job.doBy);
//         label = `${t("common:Due By")}:`;
//     }

//     const overdue = isOverdue(job);

//     return (
//         <DueBy {...{ isLongDepartmentCode }}>
//             <DueByLabel overdue={overdue}>{label}</DueByLabel>
//             <DueByTime overdue={overdue}>{getDueTime(time)}</DueByTime>
//         </DueBy>
//     );
// }

function CardForAssetJob({ job, index }) {
  const { t } = useTranslation('jobs');
  const latestNote = (job?.notes && job?.notes[0]?.note) || ''; // BE returns already the latest note
  const teamMember = getTeamMember(job.assignee?.employee, t);
  const isLongDepartmentCode =
    job.department && job.department.departmentCode.length > 5;

  const [query, setQuery] = useQueryParams({
    edit: StringParam,
    openJob: StringParam,
  });

  return (
    <CardContainer
      data-testid="asset-job-card"
      onClick={(_) => {
        if (
          job &&
          job.onViewCallback &&
          typeof job.onViewCallback === 'function'
        ) {
          job.onViewCallback();
        }

        setQuery({
          edit: '',
          openJob: job.id,
        });
      }}
    >
      <Top>
        <Tooltip
          trigger={
            <UpperLeft>
              <Priority job={job} />
              {job?.source && <ProjectIcon data-testid="repeat-icon" />}
              {job?.cycle && <RepeatingIcon />}
              <JobNumber data-testid="job-id">#{job.id}</JobNumber>
            </UpperLeft>
          }
          content={
            <div>
              {getPriority(job)?.displayName} {t('fields:Priority')} | #{job.id}{' '}
              | {job.displayName}
            </div>
          }
          position={getTooltipPosition(job, index)}
          arrow={false}
          offsetX={-5}
        />
        <UpperRight>
          {job.attachments && job.attachments.length > 0 && (
            <PhotoIcon
              title={t('jobs:Attachments')}
              data-testid="attachmnets"
            />
          )}
          <EditBtn {...{ job, setQuery, disabled: false }} />
        </UpperRight>
      </Top>
      <Tooltip
        trigger={<Description>{job.displayName}</Description>}
        content={<div>{job.displayName}</div>}
        position={getTooltipPosition(job, index)}
        arrow={false}
        offsetY={25}
        offsetX={-5}
      />
      {latestNote && <Note data-testid="latest-note">{latestNote}</Note>}
      <Bottom>
        {/* <CardTime
                job={job}
                toPropertyTime={toPropertyTime}
                isLongDepartmentCode={isLongDepartmentCode}
              /> */}
        <div />
        <Assignment {...{ isLongDepartmentCode }}>
          {!teamMember ? (
            <Unassigned>{t('jobs:Unassigned')}</Unassigned>
          ) : (
            <>
              <Department
                title={job.departments && job.departments[0].displayName}
                isLongDepartmentCode={isLongDepartmentCode}
              >
                {job.departments && job.departments[0].departmentCode}
              </Department>
              <TeamMember
                title={teamMember.long}
                {...{ isLongDepartmentCode }}
                data-testid="assignee"
              >
                {teamMember.short}
              </TeamMember>
            </>
          )}
        </Assignment>
      </Bottom>
    </CardContainer>
  );
}

export default CardForAssetJob;
