import { ReactNode, useState } from 'react';
import { FormProps } from 'antd';

import {
  Form as AntForm,
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  TextArea,
  ThemeConfig,
  Typography,
} from '@optii-solutions/ui-library';

type Props = FormProps & {
  children: ReactNode;
  theme?: ThemeConfig;
};

export function Form(props: Props) {
  const { layout = 'vertical', size = 'middle', theme, children } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntForm {...props} size={size} layout={layout}>
        {children}
      </AntForm>
    </ConfigProvider>
  );
}

const { Text } = Typography;

type JobFormNoteEditProps = {
  name: string;
  note: string;
  onSave: (input: string) => void;
  onCancel: () => void;
  loading?: boolean;
};

export function JobFormNoteEdit({
  name,
  onSave,
  onCancel,
  note,
  loading = false,
}: JobFormNoteEditProps) {
  const [value, setValue] = useState(note);
  return (
    <Row align="middle" justify="space-between" gutter={[0, 8]}>
      <Col span={10} sm={{ span: 12 }}>
        <Text type="secondary">{name}</Text>
      </Col>
      <Col span={14} style={{ textAlign: 'right' }} sm={{ span: 12 }}>
        <Space>
          <Button
            disabled={loading}
            size="small"
            onClick={onCancel}
            type="text"
            ghost
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            size="small"
            type="primary"
            onClick={() => onSave(value)}
          >
            Save
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <TextArea
          disabled={loading}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{
            maxHeight: 105,
            minHeight: 105,
          }}
        />
      </Col>
    </Row>
  );
}
