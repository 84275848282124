export const FREQUENCY_TYPES = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
  DOES_NOT_REPEAT: "DOES_NOT_REPEAT"
};

export const PRODUCT_ACCESS = {
  configuration: "configuration",
  communication: "communication",
  house_keeping: "house_keeping",
  service_delivery: "service_delivery",
  schedule: "schedule",
  user_profile: "user_profile",
  jobs: "jobs",
  reports: "reports",
  home_dashboard: "home_dashboard",
  settings: "settings",
  engineering: "engineering",
  translation: "translation",
  smart_operations: "smart_operations",
  pms_Data: "pms_data"
};
