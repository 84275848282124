const badge = `${window.location.origin}/images/badge.png`;
const logo = `${window.location.origin}/images/logo.png`;

type Registration = any;
enum NotificationType {
  privateMessage,
  tagUser,
  jobEscalation,
  jobAssigned,
  projectCyclePushNotification,
  checklistTaskPushNotification,
}

type Message = {
  data: Data;
  fcmMessageId: string;
};
type Data = {
  twi_title?: string;
  twi_body?: string;
  twi_message_id?: string;
  priority?: string;
  jobId?: string;
  entityId?: string; // Context Id (e.g: if contextType === Job then entity is jobId else is projectCycleId )
  projectId?: string;
  title?: string;
  body?: string;
  propertyId?: string;
  PropertyId?: string;
  notificationType?: NotificationType;
  ChannelSid?: string;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

const avoidDuplicateNotification = (
  fcmMessageId: string,
  handleFunction: () => Promise<any>,
) => {
  console.log('avoidDuplicateNotification', fcmMessageId, handleFunction);
  const barrier = 4000;
  const time = getRandomInt(barrier);
  setTimeout(() => {
    const storageKey = `notification_${fcmMessageId}`;
    const current = localStorage.getItem(storageKey);
    if (!current) {
      localStorage.setItem(storageKey, storageKey);
      setTimeout(() => {
        localStorage.removeItem(storageKey);
      }, barrier);

      return handleFunction();
    }
    return () => {};
  }, time);
};

async function handleNeoEscalationNotification(
  registration: Registration,
  message: Message,
) {
  const { data, fcmMessageId } = message;
  avoidDuplicateNotification(fcmMessageId as string, () => {
    try {
      const url = `${window.location.origin}/jobs`;
      const title = data?.title;
      const body = data?.body;

      if (!body || !title) {
        throw new Error('no body or title');
      }

      const options = {
        body,
        data: {
          ...data,
          url,
        },
        badge,
        icon: logo,
        actions: [{ action: 'view', title: 'View' }],
      };
      return registration.showNotification(title, options);
    } catch (err) {
      console.error('Error!', err);
      return registration.showNotification('A job has been escalated in Optii');
    }
  });
}
async function handleChecklistTaskNotification(
  registration: Registration,
  message: Message,
) {
  const { data, fcmMessageId } = message;
  avoidDuplicateNotification(fcmMessageId as string, () => {
    try {
      const propertyId = data.PropertyId || data.propertyId;
      const url = `${window.location.origin}/jobs?openJob=${data.jobId}&propertyId=${propertyId}`;
      const title = data?.title;
      const body = data?.body;

      if (!body || !title) {
        throw new Error('no body or title');
      }

      const options = {
        body,
        data: {
          ...data,
          url,
        },
        badge,
        icon: logo,
        actions: [{ action: 'view', title: 'View' }],
      };
      return registration.showNotification(title, options);
    } catch (err) {
      console.error('Error!', err);
      return registration.showNotification('Job Task notification in Optii');
    }
  });
}

async function handleProjectCycleNotification(
  registration: Registration,
  message: Message,
) {
  const { data, fcmMessageId } = message;
  avoidDuplicateNotification(fcmMessageId as string, () => {
    try {
      const url = `${window.location.origin}/projects/pm/view/${data.projectId}?propertyId=${data.propertyId}`;
      const title = data?.title;
      const body = data?.body;

      if (!body || !title || !data.projectId || !data.propertyId) {
        throw new Error('Not enough data');
      }

      const options = {
        body,
        data: {
          ...data,
          url,
        },
        badge,
        icon: logo,
        actions: [{ action: 'view', title: 'View' }],
        tag: fcmMessageId,
      };
      return registration.showNotification(title, options);
    } catch (err) {
      console.error('Error!', err);
      return registration.showNotification(
        'A project has been escalated in Optii',
      );
    }
  });
}

export {
  handleNeoEscalationNotification,
  handleProjectCycleNotification,
  handleChecklistTaskNotification,
};
