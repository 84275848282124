import {
  ConfigProvider,
  FormInstance,
  FormItem,
  FormListFieldData,
  Select,
} from '@optii-solutions/ui-library';
import {
  EventContext,
} from '@optii/escalations/types/notifications';
import { useTranslation } from 'react-i18next';
import {
  ACTION_TYPE_OPTIONS,
} from '@optii/escalations/constants/notifications';
import { useNotificationContext } from '../../../context';

type Props = {
  eventContext: EventContext;
  actionField: FormListFieldData;
  field: FormListFieldData;
  length: number;
  remove: (index: number | number[]) => void;
  form: FormInstance;
};

export function TaskActionFormList({
  actionField,
}: Props) {
  const { t } = useTranslation(['rules']);
  const { roles } = useNotificationContext();

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
          },
        },
      }}
    >
      <FormItem
        label={t('rules:Send an')}
        name={[actionField.name, 'type']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || !value.length)
                return Promise.reject(
                  new Error(t('common:Select at least one notification type.')),
                );
              return Promise.resolve(rule);
            },
          },
        ]}
        required
      >
        <Select
          mode="multiple"
          placeholder={t('common:Notification Type')}
          options={ACTION_TYPE_OPTIONS}
        />
      </FormItem>


      <FormItem
        label={t('common:to')}
        name={[actionField.name, 'roles']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || !value.length)
                return Promise.reject(
                  new Error(t('common:Select at least one recipient role')),
                );
            },
          },
        ]}
        required
      >
        <Select
          options={roles.options}
          loading={roles.loading}
          placeholder={t('common:Roles')}
          mode="multiple"
          optionFilterProp="label"
        />
      </FormItem>
    </ConfigProvider>
  );
}
