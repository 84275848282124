import {
  FormItem,
  Select,
  type FormListFieldData,
} from '@optii-solutions/ui-library';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Operator } from '../../../types/notifications';
import { useNotificationContext } from '../../context';

type Props = {
  filterField: FormListFieldData;
};

export function ProjectCriteriaFormList({ filterField }: Props) {
  const { t } = useTranslation(['common']);
  const { projects } = useNotificationContext();
  return (
    <FormItem
      label={t('common:has project name')}
      name={[filterField.name, 'ProjectId']}
      normalize={(values) => ({
        property: 'ProjectId',
        operator: Operator.In,
        values,
      })}
      getValueProps={(valueProps) => ({
        value: valueProps?.values.filter((value: string) =>
          projects.options.map((item) => item.value).includes(value),
        ),
      })}
    >
      <Select
        placeholder={t('common:All Projects')}
        mode="multiple"
        allowClear
        loading={projects.loading}
        options={projects.options}
        optionFilterProp="label"
      />
    </FormItem>
  );
}
