import importLocations from './importLocations';
import getImportById from './getImportById';
import getLocationsList from './getLocationsList';
import getLocationById from './getLocationById';
import getLocationTypeById from './getLocationTypeById';
import getLocationTypesList from './getLocationTypesList';
import getRoomTypesList from './getRoomTypesList';
import ListLocationTypes from './ListLocationTypes';
import ListRoomType from './ListRoomType';
import createLocation from './createLocation';
import deleteLocation from './deleteLocation';
import updateLocation from './updateLocation';
import deleteLocationType from './deleteLocationType';
import updateLocationType from './updateLocationType';
import createLocationType from './createLocationType';
import getLocationTypeByName from './getLocationTypeByName';
import getLocationTypeByCode from './getLocationTypeByCode';
import createRoomType from './createRoomType';
import deleteRoomType from './deleteRoomType';
import updateRoomType from './updateRoomType';
import getRoomTypeByName from './getRoomTypeByName';
import getRoomTypeByCode from './getRoomTypeByCode';
import getRoomTypeById from './GetRoomTypeById';
import moveLocations from './moveLocations';
import getLocationPropertyTags from './getLocationPropertyTags';


// Floor Plan
export const IMPORT_LOCATIONS = importLocations;
export const GET_IMPORT_BY_ID = getImportById;
export const GET_LOCATIONS_LIST = getLocationsList;
export const GET_LOCATION_BY_ID = getLocationById;
export const GET_LOCATION_TYPES_LIST = getLocationTypesList;
export const GET_ROOM_TYPES_LIST = getRoomTypesList;
export const LIST_LOCATION_TYPES = ListLocationTypes;
export const LIST_ROOM_TYPES = ListRoomType;
export const CREATE_LOCATION = createLocation;
export const DELETE_LOCATION = deleteLocation;
export const UPDATE_LOCATION = updateLocation;
export const DELETE_LOCATION_TYPE = deleteLocationType;
export const UPDATE_LOCATION_TYPE = updateLocationType;
export const CREATE_LOCATION_TYPE = createLocationType;
export const GET_LOCATION_TYPE_BY_NAME = getLocationTypeByName;
export const GET_LOCATION_TYPE_BY_CODE = getLocationTypeByCode;
export const GET_LOCATION_TYPE_BY_ID = getLocationTypeById;
export const CREATE_ROOM_TYPE = createRoomType;
export const DELETE_ROOM_TYPE = deleteRoomType;
export const UPDATE_ROOM_TYPE = updateRoomType;
export const GET_ROOM_TYPE_BY_NAME = getRoomTypeByName;
export const GET_ROOM_TYPE_BY_CODE = getRoomTypeByCode;
export const GET_ROOM_TYPE_BY_ID = getRoomTypeById;
export const MOVE_LOCATIONS = moveLocations;
export const GET_LOCATION_PROPERTY_TAGS = getLocationPropertyTags;
