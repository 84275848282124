import { gql } from '@apollo/client';

export const GET_INITIAL_JOB_LIST = gql`
  query initialJobList($cursor: String, $first: Int, $orderBy: String, $filters: Map) {
    page: initialJobList(filters: $filters, first: $first, after: $cursor, orderBy: $orderBy) {
      edges {
        node {
          ... on InitialJobListItem {
            id
            assetId
            asset {
              id
              displayName
              assetType {
                  id
                  displayName
              }
            }
            roomId
            roomsData {
              id
              reservations {
                preferences {
                  type
                  value
                }
                statusLabel
              }
              outOfOrderDates {
                code
                startDate
                endDate
              }
              cleaningStatus
              roomType {
                code
              }
              occupancyStatus
              cleaningExceptionStatus {
                doNotDisturb
                noServiceRequired
                doubleLockActive
                sleepOutNoShow
              }
            }
            room {
              id
              reservations {
                preferences {
                  type
                  value
                }
                statusLabel
              }
              cleaningStatus
              outOfOrderDates {
                code
                startDate
                endDate
              }
              roomType {
                code
              }
              occupancyStatus
              cleaningExceptionStatus {
                doNotDisturb
                noServiceRequired
                doubleLockActive
                sleepOutNoShow
              }
            }
            items {
              name
              amount
              __typename
            }
            type
            action
            priority
            isEscalated
            status
            doBy
            parentChecklistTaskId
            checklists {
              hasUncompletedChecklistTask
              checklistTasks {
                id
                checklistId
                taskType
                attachments
                label
                orderingValue
                dataText
                dataCheckboxChecked
                dataTemperatureScale
                dataDropdownValue
                dropdownOptions
                dataNumerical
                dataTemperatureValue
                required
                notes
                createdAt
                updatedAt
                childJobsIds
                dataOnOff
                dataPassFail: dataPassError
                dataYesNo
                fulfillmentAttachments
                fulfillmentNote
                __typename
              }
              __typename
            }
            locations {
              id
              name
              shortDisplayName
              longDisplayName
              displayName
              type
              __typename
            }
            notes {
              text
              time
              __typename
            }
            assignee {
              firstName
              lastName
              id
              __typename
            }
            reporter {
              id
              __typename
            }
            department {
              displayName
              departmentCode
              __typename
            }
            attachments
            metadata {
              externalId
              source
              __typename
            }
            isRushed
            timeWindowStart
            timeWindowEnd
            creditValue
            durationMin
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
