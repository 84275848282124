import { Button, ButtonProps, ConfigProvider, ThemeConfig } from 'antd';
import { ReactNode } from 'react';
import { ICON_BUTTON_THEME } from '../../utils/themes';

type IconButtonProps = ButtonProps & {
  activeIcon?: ReactNode;
  icon?: ReactNode;
  active?: boolean;
  theme?: ThemeConfig;
};

export function IconButton({
  icon,
  activeIcon,
  active,
  children,
  theme,
  ...rest
}: IconButtonProps) {
  return (
    <ConfigProvider theme={theme}>
      <Button
        icon={icon && !active ? icon : active && activeIcon ? activeIcon : icon}
        {...rest}
        shape="circle"
        type="text"
      >
        {children}
      </Button>
    </ConfigProvider>
  );
}
