import React from 'react';

function MultiValueLabel(props) {
  const {
    children,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      data-testid="input-value"
      title={children}
    >
      {children}
    </div>
  );
}

export default MultiValueLabel;
