import { gql } from "@apollo/client";

export const JOB_INSPECTION_EVALUATE = gql`
  query inspectionEvaluate($jobID: Int!) {
    inspectionEvaluate(jobID: $jobID) {
      checklists {
        id
        tasks {
          id
        }
      }
    }
  }
`;
