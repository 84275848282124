import { gql } from "@apollo/client";

export default gql`
  mutation CreateChecklistTemplate(
    $createChecklistTemplateInput: CreateChecklistTemplateInput!
  ) {
    createChecklistTemplate(
      createChecklistTemplateInput: $createChecklistTemplateInput
    ) {
      archivedAt
      createdAt
      id
      tasks {
        createdAt
        id
        taskType
        note: notes
        label
        updatedAt
      }
      name
      description
      updatedAt
    }
  }
`;
