import React, { useContext, useState } from 'react';
import { FlexContainer, FlexItem, RequiredFlag } from 'checklists';
import { ChecklistItemContext } from 'checklists/contexts';
import { useTranslation } from 'react-i18next';
import { CHECKLIST_TASK_FIELDS } from 'checklists/constants';
import styled from 'styled-components/macro';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import Field from 'components/shared/form/Field';
import TaskNameField, { ErrorMessage } from '../TaskNameField';
import SelectType from '../SelectType';

const CustomRequiredFlag = styled(RequiredFlag)`
  position: absolute;
  margin-left: -1.4rem;
  margin-top: 1rem;
`;

const TaskField = styled(Field)`
  margin-top: ${(props) => (props.readOnly ? '0px' : '5px')};
`;

function TextType() {
  const {
    isFulfillment,
    formValues,
    isValid,
    isReadOnly,
    onFulfillmentChange,
    onFormChange,
    fulfillmentValues,
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation('checklist');
  const [value, setValue] = useState(
    fulfillmentValues[CHECKLIST_TASK_FIELDS.dataText],
  );
  const [touched, setTouched] = useState(false);

  const onChange = (event) => {
    event.persist();
    GoogleAnalyticsClient.event(GA_EVENTS.textTaskEnterData);
    setTouched(() => true);
    setValue(() => event.target.value);
  };

  const handleBlur = () => {
    if (touched) {
      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataText]: value,
      });
    }
  };

  const isRequired =
    String(formValues[CHECKLIST_TASK_FIELDS.required]) === 'true';
  const readOnly = !isFulfillment || isReadOnly;

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container" col>
      <FlexContainer>
        <FlexItem>
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer>
        {isRequired && <CustomRequiredFlag />}

        <FlexItem>
          <TaskField
            name={CHECKLIST_TASK_FIELDS.dataText}
            placeholder={t('checklist:Enter Text')}
            value={value}
            tabIndex={isFulfillment ? 0 : -1}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={handleBlur}
            data-testid="checklist-task-add-text"
          />
          {!isValid && (
            <ErrorMessage errorMessage={t('checklist:Required field')} />
          )}
        </FlexItem>
      </FlexContainer>
    </FlexContainer>
  );
}

export default TextType;
