import React from 'react';
import { ButtonPrimaryStyled } from './styles';

const ButtonPrimary: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return <ButtonPrimaryStyled {...props}>{children}</ButtonPrimaryStyled>;
};

export default ButtonPrimary;
