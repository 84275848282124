export const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
);

export function addUrlProtocol(url, protocol) {
    if(!url || !urlPattern.test(url)) return url;

    const http = 'http://';
    const https = 'https://';
    const prefix = protocol ?? http;
    let newUrl = url.trim();
    const hasProtocol = newUrl.substr(0, http.length) === http || newUrl.substr(0, https.length) === https
    
    if (hasProtocol) return newUrl;
    
    newUrl = prefix + newUrl;

    return newUrl;
}
