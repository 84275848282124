import React, {
  useEffect,
  useState,
  createRef,
  useCallback,
  memo,
} from 'react';
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';

import LoadMore from './LoadMore';
import LoadMoreWithCount from './LoadMoreWithCount';
// kick off the polyfill to support all scrollIntoViewOptions for all browsers
elementScrollIntoViewPolyfill();

function PagableList({
  data,
  pageInfo = {},
  empty = () => {
    // handle empty
  },
  list,
  fetchMore = (_) => {
    // handle fetch more
  },
  fetchMoreCallback = () => {
    // handle fetch more callback
  },
  LoadMoreComponent = LoadMore,
  withCount,
  useSecondaryButton,
  loading,
  modifiers,
  showNewButton = () => {
    // handle show new button
  }, // TODO: remove when everywhere used new load more UI
}) {
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
  const [itemIdToScrollTo, setItemIdToScrollTo] = useState();

  const listBeginRef = createRef();

  // Automatically scroll to the first item from newly loaded items
  // To use automated scroll don't forget to pass arguments to list(listBeginRef, itemIdToScrollTo) in render
  const scrollToBottom = useCallback(() => {
    if (listBeginRef.current) {
      listBeginRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }

    setShouldScrollToBottom(false);
  }, [listBeginRef]);

  useEffect(() => {
    // scroll only for columns of data list with Load More and after ckick on LoadMore btn
    if (fetchMore && shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [fetchMore, scrollToBottom, shouldScrollToBottom]);

  const Component = withCount ? LoadMoreWithCount : LoadMoreComponent;

  return (
    <>
      {data && data.length > 0 ? list(listBeginRef, itemIdToScrollTo) : empty()}
      {data && data.length > 0 && pageInfo && pageInfo.hasNextPage && (
        <Component
          {...{
            pageInfo,
            fetchMore,
            data,
            useSecondaryButton,
            loading,
            modifiers,
            setShouldScrollToBottom,
            setItemIdToScrollTo,
            showNewButton,
            callback: fetchMoreCallback,
          }}
        />
      )}
    </>
  );
}
export default memo(PagableList);
