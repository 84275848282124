import type { CardProps, ThemeConfig } from 'antd';
import { Card as AntCard, ConfigProvider } from 'antd';

import { CardGridProps, CardMetaProps } from 'antd/es/card';

type Props = CardProps & {
  theme?: ThemeConfig;
};

type GridProps = CardGridProps & {
  theme?: ThemeConfig;
};

type MetaProps = CardMetaProps & {
  theme?: ThemeConfig;
};

export function Meta(props: MetaProps) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntCard.Meta {...rest} />
    </ConfigProvider>
  );
}

export function CardGrid(props: GridProps) {
  const { theme, children, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntCard.Grid {...rest}>{children}</AntCard.Grid>
    </ConfigProvider>
  );
}

export function Card(props: Props) {
  const { theme } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntCard {...props}>{props.children}</AntCard>
    </ConfigProvider>
  );
}
