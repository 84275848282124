import { gql } from "@apollo/client";

export default gql`
  query GetScheduleMetrics(
    $date: Int!
    $departmentId: Int!
    $states: [ScheduleState!]!
  ) {
    metrics: GetScheduleMetrics(
      date: $date
      departmentId: $departmentId
      states: $states
    ) {
      CPOR
      teamNoShows
      totalScheduled
      totalHours
      occupancy
      departures
      arrivals
      days {
        date
        totalScheduled
        totalHours
      }
    }
  }
`;
