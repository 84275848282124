import { CSSProperties, PropsWithChildren } from 'react';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';

import {
  ConfigProvider,
  DescriptionsProps,
  Flex,
  Space,
  ThemeConfig,
  Typography,
} from 'antd';
import {
  Leaving,
  Person,
} from '@optii/topcat-client/images/svg/reservationDetails/reservations';
import {
  COLORS,
  Card,
  Collapse,
  DescriptionText,
  Descriptions,
  FONTS,
  RADIUS,
  SPACING,
  Tag,
} from '@optii-solutions/ui-library';
import {
  TJobDetailsReservationDetails,
  TJobDetailsReservationDetailsDataStatusType,
} from './JobDetails.types';

const RESERVATION_DETAILS_THEME: ThemeConfig = {
  components: {
    Collapse: {
      headerBg: COLORS.neutral[1],
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorder: COLORS.neutral[5],
    },
    Typography: {
      colorTextHeading: COLORS.neutral[8],
      colorText: COLORS.neutral[8],
      fontSizeHeading5: FONTS.h5.size,
    },
    Card: {
      paddingLG: SPACING.NONE,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorderSecondary: COLORS.neutral[5],
      headerHeight: 46,
      headerFontSize: FONTS.h5.size,
      headerFontSizeSM: FONTS.h5.size,
    },
    Tag: {
      defaultColor: COLORS.neutral[8],
      defaultBg: COLORS.neutral[3],
      colorBorder: COLORS.neutral[5],
      fontSizeSM: FONTS.small.size,
    },
  },
};

type ReservationHeaderProps = {
  displayName: string;
  reservationName: string;
  statusLabel?: TJobDetailsReservationDetailsDataStatusType;
  dateRange: {
    arrivalDate: string;
    departureDate: string;
  };
};

type ReservationInformationProps = {
  guestNumber: string;
  specialCodes?: string;
  statusLabel?: TJobDetailsReservationDetailsDataStatusType;
  eta?: string;
  etd?: string;
  groupId?: string;
  traces?: string;
  guestPreferences?: string;
  pmsNotes?: string;
  vipStatus?: string;
  membershipLevel?: string;
  country?: string;
};

const Text = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: FONTS.small.size,
        },
        components: {
          Typography: {
            colorText: COLORS.neutral[7],
          },
        },
      }}
    >
      <Typography.Text
        style={{ fontWeight: 300, letterSpacing: FONTS.small.letterSpacing }}
      >
        {children}
      </Typography.Text>
    </ConfigProvider>
  );
};

const DescriptionsItems = ({
  groupId,
  guestNumber,
  country,
}: {
  guestNumber: string;
  country?: string;
  specialCodes?: string;
  groupId?: string;
  traces?: string;
  guestPreferences?: string;
  pmsNotes?: string;
}): DescriptionsProps['items'] => [
  {
    key: 'groupId',
    label: <DescriptionText type="label">Group ID</DescriptionText>,
    children: <DescriptionText type="text">{groupId}</DescriptionText>,
  },
  {
    key: 'guestNumber',
    label: <DescriptionText type="label">Number of Guests</DescriptionText>,
    children: <DescriptionText type="text">{guestNumber}</DescriptionText>,
  },
  {
    key: 'country',
    label: <DescriptionText type="label">Country</DescriptionText>,
    children: <DescriptionText type="text">{country}</DescriptionText>,
  },
];

const ReservationInformation = (props: ReservationInformationProps) => {
  const {
    guestNumber,
    specialCodes,
    statusLabel,
    eta,
    etd,
    groupId,
    traces,
    guestPreferences,
    pmsNotes,
    vipStatus,
    membershipLevel,
    country,
  } = props;
  const style: { content: CSSProperties } = {
    content: {
      paddingBottom: SPACING.SIZE_MS,
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
  const containsLeftSide = eta || etd || vipStatus || membershipLevel;

  return (
    <>
      <Flex
        align="center"
        justify={containsLeftSide ? 'space-between' : 'end'}
        style={{
          marginBottom: SPACING.SIZE_MD,
        }}
      >
        <Flex align="center" justify="start">
          {eta && <Tag color="cyan">{eta}</Tag>}
          {etd && <Tag color="magenta">{etd}</Tag>}
          {vipStatus && <Tag color="orange">{vipStatus}</Tag>}
          {membershipLevel && <Tag color="geekblue">Membership Level</Tag>}
        </Flex>

        <Tag color="default">{statusLabel}</Tag>
      </Flex>
      <Descriptions
        colon={false}
        column={3}
        contentStyle={style.content}
        layout="vertical"
        items={DescriptionsItems({
          guestNumber,
          country,
          specialCodes,
          groupId,
          traces,
          guestPreferences,
          pmsNotes,
        })}
      />
    </>
  );
};

const ReservationHeader = (props: ReservationHeaderProps) => {
  const { displayName, reservationName, statusLabel, dateRange } =
    props;
  return (
    <Flex align="center" justify="space-between" wrap="wrap">
      <Flex align="center" gap={12}>
        <Icon
          component={
            statusLabel === TJobDetailsReservationDetailsDataStatusType.Arrival
              ? Leaving
              : Person
          }
        />
        <Space>
          <Typography.Text> {reservationName} </Typography.Text>

          <Text> #{displayName} </Text>
        </Space>
      </Flex>
      <Text>
        {dayjs(dateRange.arrivalDate).format('ddd ll')} -{' '}
        {dayjs(dateRange.departureDate).format('ddd ll')}
      </Text>
    </Flex>
  );
};

export function JobDetailsReservationDetails({
  data,
  t,
}: TJobDetailsReservationDetails) {
  const items = data?.map(
    (
      {
        externalReservationId,
        displayName,
        guests,
        arrivalDate,
        departureDate,
        noOfAdults,
        noOfChildren,
        estimatedArrivalTime,
        estimatedDepartureTime,
        statusLabel,
        preferences,
        notes,
        attributes,
      },
      index,
      array,
    ) => {
      const primaryGuest = guests.find((guest) => guest.type === 'Primary');
      const reservationName = primaryGuest?.firstName && primaryGuest?.lastName
        ? `${primaryGuest.title || ''} ${primaryGuest.firstName} ${
          primaryGuest.lastName
        }` 
        : displayName;
      const membershipLevel = primaryGuest?.preferences?.find(
        (preference) => preference.type === 'MembershipLevel',
      )?.value;
      const country = primaryGuest?.country;

      const pmsNotes = notes?.reduce(
        (accumulator, note, pmsNotesIndex) =>
          pmsNotesIndex === 0
            ? accumulator.concat(note.note)
            : accumulator.concat(`, ${note.note}`),
        '',
      );

      const vipStatus = primaryGuest?.preferences
        ?.filter((preference) => preference.type === 'VipCode')
        .reduce(
          (accumulator, preference, vipStatusIndex) =>
            accumulator.concat(
              vipStatusIndex === 0 ? preference.value : `, ${preference.value}`,
            ),
          '',
        );

      const specialCodes = preferences
        ?.filter((preference) => preference.type === 'Special')
        .reduce(
          (accumulator, preference, specialCodesIndex) =>
            accumulator.concat(
              specialCodesIndex === 0
                ? preference.value
                : `, ${preference.value}`,
            ),
          '',
        );

      const guestPreferences = preferences
        ?.filter(
          (preference) =>
            preference.type !== 'VipCode' && preference.type !== 'Special',
        )
        .reduce(
          (accumulator, preference, guestPreferencesIndex) =>
            accumulator.concat(
              guestPreferencesIndex === 0
                ? preference.value
                : `, ${preference.value}`,
            ),
          '',
        );

      const groupId = attributes?.GroupCode;
      const traces = attributes?.Traces;

      const eta =
        estimatedArrivalTime &&
        t('floorplan:ETA {{estimatedArrivalTime}}', {
          estimatedArrivalTime,
        });

      const etd =
        estimatedDepartureTime &&
        t('floorplan:ETD {{estimatedDepartureTime}}', {
          estimatedDepartureTime,
        });
      return {
        label: ReservationHeader({
          displayName,
          statusLabel,
          reservationName,
          dateRange: { arrivalDate, departureDate },
        }),
        key: externalReservationId,
        children: ReservationInformation({
          guestNumber: t('floorplan:{{adults}} Adults, {{children}} Children', {
            adults: noOfAdults,
            children: noOfChildren,
          }),
          specialCodes,
          eta,
          etd,
          statusLabel,
          groupId,
          traces,
          guestPreferences,
          pmsNotes,
          vipStatus,
          membershipLevel,
          country,
        }),
        style: index + 1 === array.length ? { border: RADIUS.NONE } : undefined,
      };
    },
  );

  return (
    <ConfigProvider theme={RESERVATION_DETAILS_THEME}>
      <Card>
        {items?.length === 0 ? (
          <Typography.Paragraph
            style={{
              marginTop: 12,
              color: 'lightgray',
              display: 'flex',
              minWidth: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {t('floorplan: No Reservations')}
          </Typography.Paragraph>
        ) : (
          <Collapse
            items={items}
            ghost={false}
            style={{
              border: 'none',
              marginTop: 1,
            }}
          />
        )}
      </Card>
    </ConfigProvider>
  );
}
