import styled from 'styled-components/macro';
import React from 'react';
import { applyStyleModifiers } from 'styled-components-modifiers';
import BaseButton from './BaseButton';

const CONFIG = {
  light: ({ theme }) => `
    background: ${theme.colors.tealMed};
    :active,
    :hover {
      border-color: ${theme.colors.tealMed};
    }
    :active {
      border-color: ${theme.colors.tealMed};
    }
  `,
};

const Button = styled(BaseButton)`
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.weights.medium};
  background-color: ${(props) => props.theme.colors.teal};
  :active,
  :hover {
    box-shadow: 0 1px 6px 0 rgba(51, 51, 51, 0.5);
  }
  :active {
    background-color: ${(props) => props.theme.colors.tealXDark};
  }
  ${applyStyleModifiers(CONFIG)};
`;
function PrimaryButton(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}
export default PrimaryButton;
