import { EventContext } from '../../../types/notifications';
import temperatureResponse from './temperatureResponse';
import numericalResponse from './numericalResponse';

interface Props {
  eventContext: EventContext;
  data: any;
}

export default function normalizeResponse({ eventContext, data }: Props) {
  const SUBMIT = {
    [EventContext.Job]: (responseData: Notification) => responseData,
    [EventContext.ProjectCycle]: (responseData: Notification) => responseData,
    [EventContext.TemperatureTask]: temperatureResponse,
    [EventContext.NumericalTask]: numericalResponse,
  };

  return SUBMIT[eventContext as Exclude<EventContext, 'Project'>](data);
}
