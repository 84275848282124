import styled from 'styled-components/macro';

export default styled.div`
  background-color: ${(props) => props.theme.colors.charcoal03};
  ${(props) => props.hidden && 'display: none;'}
  ${(props) =>
    props.superModal
      ? props.theme.zIndex.superModalBack
      : props.zIndex || props.theme.zIndex.modalBack}
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${(props) => (props.solid ? '1' : '0.4')};
  height: 100%;
`;
