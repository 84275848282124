import { CloseOutlined, EditFilled, EditOutlined } from '@ant-design/icons';
import { Grid, Space } from 'antd';
import { IconButton } from '../../components/Button/IconButton.component';
import React from 'react';
import { COLORS } from '../../utils/colors';

import { SPACING } from '../../..';
import { ICON_BUTTON_THEME } from '../../utils/themes';

type Props = {
  customRender?: React.ReactNode;
  onClose:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  onEdit?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  editActive?: boolean;
  responsive?: boolean;
};

const { useBreakpoint } = Grid;

export function HeaderActions({
  customRender,
  onClose,
  onEdit,
  editActive,
  responsive,
}: Props) {
  const breakpoints = useBreakpoint();

  const iconButtonTheme = {
    ...ICON_BUTTON_THEME,
  };

  if (responsive) {
    Object.assign(iconButtonTheme, {
      ...ICON_BUTTON_THEME,
      components: {
        ...ICON_BUTTON_THEME.components,
        Button: {
          ...ICON_BUTTON_THEME.components?.Button,
          fontSize: SPACING.SIZE_XL,
          fontSizeSM: SPACING.SIZE_XL,
          fontSizeMD: SPACING.SIZE_XL,
          fontSizeLG: SPACING.SIZE_XL,
          fontSizeXL: SPACING.SIZE_XL,
        },
      },
    });

    if (breakpoints.md || breakpoints.xl || breakpoints.xxl) {
      Object.assign(iconButtonTheme, {
        ...iconButtonTheme,
        components: {
          ...iconButtonTheme.components,
          Button: {
            ...iconButtonTheme.components?.Button,
            fontSize: SPACING.SIZE_MD,
            fontSizeSM: SPACING.SIZE_MD,
            fontSizeMD: SPACING.SIZE_MD,
            fontSizeLG: SPACING.SIZE_MD,
            fontSizeXL: SPACING.SIZE_MD,
          },
        },
      });
    }
  }

  return customRender ? (
    customRender
  ) : (
    <Space>
      {typeof onEdit !== 'function' ? null : (
        <IconButton
          onClick={onEdit}
          icon={<EditOutlined />}
          activeIcon={
            <EditFilled
              style={{
                color: COLORS.primary[5],
              }}
            />
          }
          active={editActive}
          theme={iconButtonTheme}
        />
      )}

      <IconButton
        onClick={onClose}
        icon={<CloseOutlined />}
        theme={iconButtonTheme}
      />
    </Space>
  );
}
