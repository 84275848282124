import { gql } from '@apollo/client';

export const GET_JOB_BY_ID = gql`
  query GetJobById($id: ID!, $startedAt: String) {
    job: GetJobById(id: $id) {
      id
      type
      items {
        amount
        name
      }
      action
      priority
      isEscalated
      status
      doBy
      scheduleStartTimeAt
      createdAt
      completedAt
      parentChecklistTaskId
      parentJobId
      checklists {
        jobId
        id
        checklistTemplateId
        description
        checklistTasks {
          id
          templateTaskId
          parentTaksId
          checklistId
          taskType
          attachments
          label
          orderingValue
          dataText
          dataCheckboxChecked
          dataTemperatureScale
          dataDropdownValue
          dropdownOptions
          dataNumerical
          dataTemperatureValue
          required
          notes
          createdAt
          updatedAt
          childJobsIds
          dataOnOff
          dataPassFail: dataPassError
          dataYesNo
          fulfillmentAttachments
          fulfillmentNote
        }
      }
      checklistTemplate {
        createdAt
        id
        tasks {
          attachments
          checklistTemplateId
          createdAt
          id
          label
          notes
          orderingValue
          parentTaksId
          required
          taskType
          updatedAt
        }
        name
        description
        updatedAt
        creator {
          firstName
          id
          lastName
        }
      }
      locations {
        name
        shortDisplayName
        id
        type
        number
        value: id
        title: name
      }
      notes {
        createdBy {
          id
          firstName
          lastName
          userName
        }
        updatedAt
        time
        text
        id
      }
      assignee {
        firstName
        lastName
        id
        userName
      }
      reporter {
        id
        firstName
        lastName
        userName
      }
      department {
        id
        displayName
        departmentCode
      }
      role {
        id
        name
        description
      }
      attachments
      metadata {
        externalId
        source
        publicAttributes
        privateAttributes
      }
      isRushed
      timeWindowStart
      timeWindowEnd
      creditValue
      durationMin
    }
    audit: GetJobAuditList(id: $id, startedAt: $startedAt) {
      id
      itemId
      employee {
        employeeId
        employeeSelf
        employeeUserName
        employeeUserFirstName
        employeeUserLastName
      }
      object
      action
      details
      modifier1
      modifier2
      createdAt
    }
  }
`;
