import React from 'react';
import { ButtonCautionStyled } from './styles';

const ButtonCaution: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return <ButtonCautionStyled {...props}>{children}</ButtonCautionStyled>;
};

export default ButtonCaution;
