import { RRule } from "rrule";

export const FREQUENCY_TYPES = {
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
  DOES_NOT_REPEAT: "DOES_NOT_REPEAT"
};

export const weekDigit = t => ({
  [RRule.SU]: t("Su"),
  [RRule.MO]: t("M"),
  [RRule.TU]: t("T"),
  [RRule.WE]: t("W"),
  [RRule.TH]: t("Th"),
  [RRule.FR]: t("F"),
  [RRule.SA]: t("Sa")
});

export const getRepeatingOptions = (t = a => a) => [
  {
    displayName: t("jobs:Does Not Repeat"),
    key: FREQUENCY_TYPES.DOES_NOT_REPEAT
  },
  { displayName: t("jobs:Repeat Daily"), key: FREQUENCY_TYPES.DAILY },
  { displayName: t("jobs:Repeat Weekly"), key: FREQUENCY_TYPES.WEEKLY },
  {
    displayName: t("jobs:Repeat Monthly"),
    key: FREQUENCY_TYPES.MONTHLY
  },
  { displayName: t("jobs:Repeat Yearly"), key: FREQUENCY_TYPES.YEARLY }
];

export const getWeekDays = (t = a => a) => ({
  Sunday: { key: RRule.SU, label: t("common:Sunday") },
  Monday: { key: RRule.MO, label: t("common:Monday") },
  Tuesday: { key: RRule.TU, label: t("common:Tuesday") },
  Wednesday: { key: RRule.WE, label: t("common:Wednesday") },
  Thursday: { key: RRule.TH, label: t("common:Thursday") },
  Friday: { key: RRule.FR, label: t("common:Friday") },
  Saturday: { key: RRule.SA, label: t("common:Saturday") }
});
