// Check out https://crontab.guru/ to make it easier to figure out cron values
export const getServiceCycles = t => [
  // At 00:00 every day.
  {
    id: 1,
    cronValue: "0 0 * * *",
    displayName: t("assets:Daily"),
    filterSortValue: "Daily"
  },
  // At 00:00 on Sunday.
  {
    id: 2,
    cronValue: "0 0 * * 0",
    displayName: t("assets:Weekly"),
    filterSortValue: "Weekly"
  },
  // at 00:00 on day-of-month 1.
  {
    id: 3,
    cronValue: "0 0 1 * *",
    displayName: t("assets:Monthly"),
    filterSortValue: "Monthly"
  },
  // At 00:00 on day-of-month 1 in January, April, July, and October.
  // {
  //   id: 4,
  //   cronValue: "0 0 1 1,4,7,10 *",
  //   displayName: t("assets:Quarterly")
  // },
  // At 00:00 on day-of-month 1 and on Sunday in January.
  {
    id: 5,
    cronValue: "0 0 1 1 0",
    displayName: t("assets:Yearly"),
    filterSortValue: "Yearly"
  }
];

export const getLifeCycle = (t, amount) => {
  const plural = amount ? Number(amount) > 1 : true;
  const cicle = [{
    id: 1,
    displayName: plural ? t("assets:Months") : t("assets:Month"),
    filterSortValue: "Month"
  }, {
    id: 2,
    displayName: plural ? t("assets:Years") : t("assets:Year"),
    filterSortValue: "Year"
  }]
  return cicle;
};


export const getLifeCycleNumber = (number, type, t) => `${number ?? 'None'} ${
  getLifeCycle(t, number).find(
    (item) =>
      item.filterSortValue?.toLowerCase() ===
      type?.toLowerCase(),
  )?.displayName ?? ''
}`;


// We need to escape commas as the library the .net service is using for filters
// uses a comma delimited list to seperate different filters
// more info on libray they use here: https://github.com/Biarity/Sieve#send-a-request
export const escapeCommas = filterValue => {
  if (Array.isArray(filterValue)) {
    return filterValue.map(f => f.replace(/,/g, "\\,"));
  }
  return filterValue.replace(/,/g, "\\,");
};