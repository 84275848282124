import React, { useState, useContext, useRef } from 'react';
import styled from 'styled-components/macro';
import FieldBlock from 'blocks/Field';
import { Session, JobDetailsContext } from '@optii/shared';
import InputGroup from 'components/shared/form/InputGroup';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import breakpoints from 'theme/breakpoints';
import { useMedia } from 'react-media';
import { useTranslation } from 'react-i18next';
import { isKeyboardOpen } from 'utils/isKeyboardOpen';

const Wrap = styled.div`
  height: ${(props) => props.height}px;
`;

function AddNote(props) {
  const {
    job,
    refetch,
    updateJob: [updateJob, { loading }],
  } = useContext(JobDetailsContext);
  const { t } = useTranslation(['jobs', 'common']);
  const [note, setNote] = useState('');
  const [textAreaHeight, setTextAreaHeight] = useState(null);

  const noteBoxRef = useRef(null);

  const { globalSnack } = useContext(Session);

  const isMobile = useMedia({
    query: `(max-width: ${breakpoints.mdMax})`,
  });

  function handleChange(e) {
    e.preventDefault();
    setNote(e.target.value);
  }

  async function handleSubmit() {
    try {
      await updateJob({
        variables: {
          id: job.id,
          input: {
            action: job.action,
            status: job.status,
            notes: [
              {
                text: note,
              },
            ],
          },
        },
      });
      setNote('');
      refetch();
      GoogleAnalyticsClient.event(GA_EVENTS.addNoteJob);
    } catch (err) {
      console.error("Couldn't add note!", err);
      globalSnack({
        message: t(`jobs:There was a problem adding your note`),
        timeout: 5000,
        error: true,
      });
    }
  }
  return (
    <Wrap height={textAreaHeight} {...props} ref={noteBoxRef}>
      <FieldBlock>
        <FieldBlock.Label htmlFor="note">{t('jobs:Add Note')}</FieldBlock.Label>
        <InputGroup
          buttonLabel={t('common:Add')}
          sendHandler={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          value={note}
          onChange={handleChange}
          type="textarea"
          maxRows={5}
          disabled={!note.trim() || loading}
          heightChangeHandler={(height) => setTextAreaHeight(height)}
          onFocus={() => {
            isKeyboardOpen(true);
            noteBoxRef.current.scrollIntoView();
          }}
          onBlur={() => {
            isKeyboardOpen(false);
          }}
          handleKeyDown={(e) => {
            const isModifierKey = e.shiftKey;
            if (e.key === 'Enter' && !isModifierKey && note && !isMobile) {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />
      </FieldBlock>
    </Wrap>
  );
}

export default AddNote;
