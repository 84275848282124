import { Client, Conversation, Message } from '@twilio/conversations';
import { Dayjs } from 'dayjs';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Channel, UnreadConversations } from '../types';

type SelectOption = {
  label: string;
  value: string;
};

type ChannelState = {
  publicChannels: Channel[];
  privateChannels: Channel[];
};

type Context = {
  employees: SelectOption[];
  client: Client | null;
  unreadConversations: {
    channels: {
      [key: string]: number;
    };
    total: number;
  } | null;
  mapPrivateConversation: (
    conversation: Conversation,
    employees?: SelectOption[],
    userId?: string,
  ) => Promise<
    | {
        key: string;
        recipientIds: string[] | undefined;
        label: string | null;
        unreadMessageCount: number;
        lastMessage: {
          content: string | null;
          timestamp: Dayjs;
        };
      }
    | undefined
  >;
  channels: ChannelState;
  setChannels: Dispatch<SetStateAction<ChannelState>>;
  getChannelById: (sid: string) => Promise<Conversation | undefined>;
  updateUnreadConversations(
    message: Message,
    setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>,
    userId?: string,
  ): Promise<void>;
  setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>;
};

export const ChatContext = createContext<Context>({
  employees: [],
  client: null,
  unreadConversations: null,
  setUnreadConversations: () => {},
  channels: {
    privateChannels: [],
    publicChannels: [],
  },
  setChannels: () => {},
  getChannelById: () => Promise.resolve(undefined),
  mapPrivateConversation: () => Promise.resolve(undefined),
  updateUnreadConversations: () => Promise.resolve(),
});
