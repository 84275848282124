import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChecklistItemContext } from '@optii/jobs/checklists/contexts';
import {
  CHECKLIST_ACTION_STATES,
  CHECKLIST_TASK_FIELDS,
} from '@optii/jobs/checklists/constants';

import SelectType from '../SelectType';
import { Error, FlexContainer, FlexItem, TaskLabel, TextInput } from '../../Checklists.elements';

const errorMessageComponent = ({ errorMessage }) => (
  <Error>{errorMessage}</Error>
);

function ReadOnlyTaskType() {
  const [focus, setFocus] = useState(false);
  const {
    formValues,
    onFormChange,
    setActionState,
    readOnly,
    actionState,
    isFulfillment,
    isValid,
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation(['common', 'checklist']);

  const canEdit = actionState === CHECKLIST_ACTION_STATES.addingItem;

  const goToView = useCallback(() => {
    if (formValues.label !== '') {
      setActionState(CHECKLIST_ACTION_STATES.viewingItem);
      setFocus(false);
    }
  }, [setActionState, setFocus, formValues]);

  const goToEdit = useCallback(() => {
    if (
      !readOnly &&
      actionState !== CHECKLIST_ACTION_STATES.fulfillment &&
      !isFulfillment
    ) {
      setActionState(CHECKLIST_ACTION_STATES.addingItem);
      setFocus(true);
    }
  }, [readOnly, actionState, isFulfillment, setActionState]);

  const onChangeTaskName = useCallback(
    (event) => {
      onFormChange({
        name: CHECKLIST_TASK_FIELDS.label,
        value: event.target.value,
      });
    },
    [onFormChange],
  );

  const taskInputValue = useMemo(
    () => formValues[CHECKLIST_TASK_FIELDS.label],
    [formValues],
  );

  const errorMessage = t('checklist:Field Name is required');

  return (
    <FlexContainer data-testid="task-type-container">
      <FlexContainer col>
        <FlexContainer>
          <FlexItem>
            {canEdit ? (
              <>
                <TextInput
                  name={CHECKLIST_TASK_FIELDS.label}
                  placeholder={t('checklist:Add Task')}
                  value={taskInputValue}
                  tabIndex={0}
                  onChange={onChangeTaskName}
                  onBlur={goToView}
                  autoFocus={focus}
                  data-testid="checklist-task-input"
                />
                {!isValid && errorMessageComponent({ errorMessage })}
              </>
            ) : (
              <TaskLabel onClick={goToEdit}>{taskInputValue}</TaskLabel>
            )}
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default ReadOnlyTaskType;
