import { gql } from "@apollo/client";
import { fragment as userFrag } from "./getUserInfo";
import { fragment as accessFrag } from "./getUserAccess";

export default gql`
  query GetCurrentUser($id: ID!) {
    access: ${accessFrag}
    user: ${userFrag}
  }
`;
