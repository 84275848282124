import React, { Fragment, memo, useEffect, useState } from 'react';
import {
  Descriptions,
  DescriptionText,
  Space,
  Tag,
  Typography,
} from '@optii-solutions/ui-library';
import { useTranslation } from 'react-i18next';
import { DescriptionsItemType } from 'antd/es/descriptions';

import { TGetLocationById, getLocationsById } from 'components/SelectLocations/api/SelectLocationsApi';
import { TAssociatedVirtualLocations, TLocation } from './types';
import { STYLES, TAB_COLUMNS } from './constants';

interface TAssociatedVirtualLocationsProps {
  associatedVirtualLocations?: TAssociatedVirtualLocations[];
  associatedLocationsData?: TAssociatedVirtualLocations[];
  virtualChildrenLocationIds?: number[];
  sortedAssociatedVirtualLocations: TAssociatedVirtualLocations[];
  onSelectVirtualLocation: (item: TAssociatedVirtualLocations) => void;
  t: (a: string) => string;
}

function getAssociatedLocations({
  associatedVirtualLocations,
  virtualChildrenLocationIds,
  sortedAssociatedVirtualLocations,
  associatedLocationsData,
  onSelectVirtualLocation,
  t,
}: TAssociatedVirtualLocationsProps) {
  return virtualChildrenLocationIds && virtualChildrenLocationIds.length ? <Space
  wrap
  style={{
    rowGap: 0,
    columnGap: 0,
  }}
>
  {virtualChildrenLocationIds.map((virtualChildrenLocationId: number, index: number, array) => {
    const associatedVirtualLocationsData = associatedVirtualLocations && associatedVirtualLocations.find((associatedVirtualLocation: TAssociatedVirtualLocations) => Number(associatedVirtualLocation.id) === virtualChildrenLocationId);
    const virtualChildrenLocationsData = associatedLocationsData && associatedLocationsData.find((_associatedLocationData: TAssociatedVirtualLocations) => Number(_associatedLocationData?.id) === virtualChildrenLocationId);
    const associatedLocation: TAssociatedVirtualLocations = (associatedVirtualLocationsData || virtualChildrenLocationsData) as TAssociatedVirtualLocations;
    const locationName = associatedLocation && (associatedLocation.shortDisplayName || associatedLocation.displayName || associatedLocation.name);

    return <Typography.Link onClick={() => {
      onSelectVirtualLocation(associatedLocation);
    }}>{locationName ?
      `${locationName}${
        array.length > 1 && index + 1 < array.length ? ',' : ''
      } ` :
      undefined}</Typography.Link>;
  }) || undefined}
  </Space> :
  <Space
    wrap
    style={{
      rowGap: 0,
      columnGap: 0,
    }}
    >
    {associatedVirtualLocations && associatedVirtualLocations.length > 0 ? (
      sortedAssociatedVirtualLocations.map(
        (
          associatedVirtualLocation: TAssociatedVirtualLocations,
          index,
          array,
        ) => (
          <Fragment key={associatedVirtualLocation.id}>
            <DescriptionText
              type="link"
              key={associatedVirtualLocation.id}
              onClick={() => {
                onSelectVirtualLocation(associatedVirtualLocation);
              }}
            >
              {`${associatedVirtualLocation.name}${
                index + 1 === array.length ? '' : ','
              }`}
            </DescriptionText>
          </Fragment>
        ),
      )
    ) : (
      <DescriptionText emptyText={t('fields:None')}>
        {undefined}
      </DescriptionText>
    )}
  </Space>;
}

interface ILocationDetailsProps {
  location: TLocation;
  onSelectVirtualLocation: (item: TAssociatedVirtualLocations) => void;
}
function LocationDetails({
  location,
  onSelectVirtualLocation,
}: ILocationDetailsProps) {
  const { t } = useTranslation(['common', 'floorplan', 'fields']);

  const {
    locationType,
    sequence,
    notes,
    roomType,
    tags,
    squareUnit,
    associatedVirtualLocations,
    virtualChildrenLocationIds,
  } = location;

  const sortedAssociatedVirtualLocations = Array.from(
    associatedVirtualLocations,
  ).sort((a, b) => a.name.localeCompare(b.name));

  const sortedTags = Array.from(tags).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const [associatedLocationsData, setAssociatedLocationsData] = useState<TAssociatedVirtualLocations[]>();

  useEffect(() => {
    async function fetchAssociatedLocationsData() {
      const data: TGetLocationById[] | undefined = await getLocationsById({
        id: virtualChildrenLocationIds,
      });

      const newData: TAssociatedVirtualLocations[] | undefined = data?.map((item: TGetLocationById) => item?.data?.GetLocationById).flat(1);

      setAssociatedLocationsData(newData);
    }

    if (virtualChildrenLocationIds?.length) {
      fetchAssociatedLocationsData();
    }
  }, [virtualChildrenLocationIds]);

  const descriptionItems = [
    {
      key: 'location-view-location-type-description',
      label: (
        <DescriptionText type="label">
          {t('floorplan:Location Type')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {locationType?.displayName}
        </DescriptionText>
      ),
    },
    location.roomType
      ? {
          key: 'location-view-room-type-description',
          label: (
            <DescriptionText type="label">
              {t('floorplan:Room Type')}
            </DescriptionText>
          ),
          children: (
            <DescriptionText type="text" emptyText={t('fields:None')}>
              {roomType?.displayName}
            </DescriptionText>
          ),
        }
      : null,
    {
      key: 'location-view-square-unit-description',
      label: (
        <DescriptionText type="label">
          {t('floorplan:Square Unit')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {squareUnit}
        </DescriptionText>
      ),
    },
    {
      key: 'location-view-sequence-description',
      label: (
        <DescriptionText type="label">
          {t('floorplan:Sequence')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {sequence}
        </DescriptionText>
      ),
    },
    {
      key: 'location-view-tags-description',
      label: (
        <DescriptionText type="label">{t('floorplan:Tags')}</DescriptionText>
      ),
      children: (
        <Space wrap>
          {sortedTags.length > 0 ? (
            sortedTags.map((tag) => (
              <DescriptionText key={tag.name} type="text">
                <Tag>{tag.name}</Tag>
              </DescriptionText>
            ))
          ) : (
            <DescriptionText emptyText={t('fields:None')}>
              {undefined}
            </DescriptionText>
          )}
        </Space>
      ),
    }, {
      key: 'location-view-associated-locations',
      label: (
        <DescriptionText type="label">{(!location.isVirtual && associatedVirtualLocations.length) ? t('floorplan:Associated Virtual Locations') : t('floorplan:Associated Locations')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="link" emptyText={(!virtualChildrenLocationIds.length && !associatedVirtualLocations.length) ? t('fields:None') : undefined}>
          {getAssociatedLocations({
            t,
            associatedVirtualLocations,
            virtualChildrenLocationIds,
            sortedAssociatedVirtualLocations,
            onSelectVirtualLocation,
            associatedLocationsData,
          })}
        </DescriptionText>
      ),
    },
    {
      key: 'location-view-notes-description',
      label: (
        <DescriptionText type="label">{t('floorplan:Notes')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {notes}
        </DescriptionText>
      ),
    },
  ];

  return (
    <Descriptions
      items={
        (descriptionItems.filter((item) => !!item) as DescriptionsItemType[]) ||
        []
      }
      colon={false}
      column={TAB_COLUMNS}
      contentStyle={STYLES.descriptionContent}
      style={STYLES.descriptions}
      layout="vertical"
    />
  );
}

export default memo(LocationDetails);
