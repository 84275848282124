import React, { useContext, useEffect } from "react";
import { GET_PROPERTY_BY_ID } from "queries";
import { useLazyQuery } from "@apollo/client";
import { PropertyContext } from '@optii/shared';

function useProductAccess(passedProp) {
  const { property } = useContext(PropertyContext.Context);

  const [getProperty, { data }] = useLazyQuery(GET_PROPERTY_BY_ID);
  const products = data && data.property && data.property.products;
  useEffect(
    _ => {
      if (property) {
        const id = passedProp || (property && property.id);
        getProperty({
          fetchPolicy: "cache-first",
          variables: {
            id
          }
        });
      }
    },
    [property, passedProp]
  );
  function canProduct(productsToCheck) {
    if (!productsToCheck || !productsToCheck.length) return true;
    return (
      products &&
      productsToCheck.reduce((acc, product) => acc || products.includes(product), false)
    );
  }

  return { canProduct };
}

export default useProductAccess;
