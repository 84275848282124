import { TFunction } from 'react-i18next';
import type {
  TJobPriorityLevels,
  TRepeatingJob,
  TRepeatingJobCycle,
  TJobStatuses,
  TJobTypes,
  TDaysOfWeek,
  TOrdinalSuffix,
  TMonths,
  TDays,
} from './JobForm.types';
import i18next from 'i18next';
import { JOB_STATUS } from 'utils/constants';

export const ORDINAL_SUFFIX: TOrdinalSuffix[] = ['st', 'nd', 'rd', 'th'];

export const MONTHS: TMonths[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS: TDays[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_OF_WEEK: TDaysOfWeek = {
  monday: {
    label: 'Mon',
    displayName: 'Monday',
    value: 'monday',
  },
  tuesday: {
    label: 'Tue',
    displayName: 'Tuesday',
    value: 'tuesday',
  },
  wednesday: {
    label: 'Wed',
    displayName: 'Wednesday',
    value: 'wednesday',
  },
  thursday: {
    label: 'Thu',
    displayName: 'Thursday',
    value: 'thursday',
  },
  friday: {
    label: 'Fri',
    displayName: 'Friday',
    value: 'friday',
  },
  saturday: {
    label: 'Sat',
    displayName: 'Saturday',
    value: 'saturday',
  },
  sunday: {
    label: 'Sun',
    displayName: 'Sunday',
    value: 'sunday',
  },
};

export const JOB_TYPES = (t: TFunction<['common', 'jobs'], undefined>) => ({
  guest: {
    label: t('jobs:Guest Request'),
    value: 'guest',
  },
  internal: {
    label: t('jobs:Internal'),
    value: 'internal',
  },
  housekeeping: {
    label: t('jobs:Housekeeping'),
    value: 'housekeeping',
  },
});

export const JOB_PRIORITY_LEVELS = (
  t: TFunction<['common', 'jobs'], undefined>,
) => ({
  highest: {
    label: t('jobs:Highest'),
    value: 'highest',
  },
  high: {
    label: t('jobs:High'),
    value: 'high',
  },
  medium: {
    label: t('jobs:Medium'),
    value: 'medium',
  },
  low: {
    label: t('jobs:Low'),
    value: 'low',
  },
});

export const JOB_STATUSES = (t: TFunction<['common', 'jobs'], undefined>) => ({
  notStarted: {
    label: t('jobs:Not Started'),
    value: 'not_started',
  },
  inProgress: {
    label: t('jobs:In Progress'),
    value: 'in_progress',
  },
  completed: {
    label: t('jobs:Done'),
    value: 'completed',
  },
  cancelled: {
    label: t('jobs:Cancelled'),
    value: 'cancelled',
  },
  onHold: {
    label: t('jobs:On Hold'),
    value: 'on_hold',
  },
});

export const JOB_REPEAT_CYCLE: TRepeatingJobCycle = {
  daily: {
    displayName: 'every day',
  },
  weekly: {
    displayName: 'every {{week}}',
  },
  monthly: {
    displayName: 'every {{month}}',
  },
  yearly: {
    displayName: 'every {{year}}',
  },
};

export const JOB_REPEAT_CYCLE_TYPE = 'rrule';

export const JOB_REPEAT_STATUS = 'active';

export const JOB_REPEAT = (t: TFunction<['common', 'jobs'], undefined>) => ({
  no: {
    label: t('jobs:Does Not Repeat'),
    value: 'no',
  },
  daily: {
    label: t('jobs:Repeat Daily'),
    value: 'daily',
  },
  weekly: {
    label: t('jobs:Repeat Weekly'),
    value: 'weekly',
  },
  monthly: {
    label: t('jobs:Repeat Monthly'),
    value: 'monthly',
  },
  yearly: {
    label: t('jobs:Repeat Yearly'),
    value: 'yearly',
  },
});

export const ACTION_TYPE_OPTIONS = (
  t: TFunction<['common', 'jobs'], undefined>,
) => [
  {
    label: t('jobs:Clean'),
    value: 'clean',
  },
  {
    label: t('jobs:Deliver'),
    value: 'deliver',
  },
  {
    label: t('jobs:Remove'),
    value: 'remove',
  },
  {
    label: t('jobs:Repair'),
    value: 'repair',
  },
  {
    label: t('jobs:Test'),
    value: 'test',
  },
  {
    label: t('jobs:Replace'),
    value: 'replace',
  },
  {
    label: t('jobs:Inspect'),
    value: 'inspect',
  },
  {
    label: t('jobs:Install'),
    value: 'install',
  },
  {
    label: t('jobs:Perform'),
    value: 'perform',
  },
];

export const HOUSEKEEPING_ACTION_TYPE_OPTIONS = (
  t: TFunction<['common', 'jobs'], undefined>,
) => [
  {
    label: t('jobs:Departure'),
    value: 'departure',
  },
  {
    label: t('jobs:Stayover'),
    value: 'stayover',
  },
  {
    label: t('jobs:Inspection'),
    value: 'inspection',
  },
  {
    label: t('jobs:Check'),
    value: 'check',
  },
  {
    label: t('jobs:Touch-up'),
    value: 'touchup',
  },
  {
    label: t('jobs:Turndown'),
    value: 'turndown',
  },
  {
    label: t('jobs:Correction'),
    value: 'correction',
  },
];

export const JOB_TEMPLATE_STATUS = 'new';

export const EMPTY_ASSIGNEE = {
  id: 0,
  displayName: '',
  userId: 0,
  userName: '',
  userFirstName: '',
  userLastName: '',
};

export const AUTO_ASSIGNEE_ID = -88;
export const AUTO_ASSIGNEE_USERNAME = 'autoassign';

export const AUTO_ASSIGNEE = (t: TFunction<['common', 'jobs'], undefined>) => ({
  id: AUTO_ASSIGNEE_ID,
  displayName: t('jobs:Auto Assign'),
  userId: AUTO_ASSIGNEE_ID,
  userName: AUTO_ASSIGNEE_USERNAME,
  firstName: t('jobs:Auto'),
  lastName: t('jobs:Assign'),
});

export const REFETCH_SUMMARY_VARIABLES = {
  first: 50,
  Sorts: 'displayName',
};

export const LOCATION_TYPES_NAMES = {
  room: 'Room',
  property: 'Property',
};

export const STATUS_ASSIGNEE_FEEDBACK_MESSAGE = {
  [JOB_STATUS.in_progress]:
    'jobs:In Progress Jobs must be assigned to a team member',
  [JOB_STATUS.on_hold]: 'jobs:On Hold Jobs must be assigned to a team member',
  [JOB_STATUS.cancelled]:
    'jobs:Cancelled Jobs must be assigned to a team member',
  [JOB_STATUS.completed]: 'jobs:Done Jobs must be assigned to a team member',
};
