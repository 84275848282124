import {
  DatePicker as AntDatePicker,
  ConfigProvider,
  ThemeConfig,
  type DatePickerProps,
} from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/bg';
import 'dayjs/locale/bs';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/es';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/et';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sq';
import 'dayjs/locale/tl-ph';
import 'dayjs/locale/tr';
import 'dayjs/locale/uk';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { getLocale } from './locales';

type Props = DatePickerProps & {
  theme?: ThemeConfig;
  locale?: string;
};

dayjs.extend(localizedFormat);

export function DatePicker(props: Props) {
  //setting default
  const { theme, locale, format = 'll', ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntDatePicker format={format} locale={getLocale(locale)} {...rest} />
    </ConfigProvider>
  );
}
