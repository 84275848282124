import React, { useContext, useCallback } from 'react';
import styled from 'styled-components/macro';
import PrimaryButton from 'components/shared/buttons/PrimaryButton';
import TertiaryButton from 'components/shared/buttons/TertiaryButton';
import QuadButton from 'components/shared/buttons/QuadButton';
import { JOB_STATUS, AUTO_ASSIGN_ID } from 'utils/constants';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import {
  Session,
  UserAccessContext,
  ViewJobDetailsContext,
} from '@optii/shared';
import { getName } from 'utils/formatters/jobs';
import Confirmation from 'components/shared/Confirmation';
import ToggleContent from 'components/shared/ToggleContent';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { useTranslation } from 'react-i18next';
import { CHECKLIST_TASK_TYPE_CONFIG } from 'checklists/constants';
import { StringParam, useQueryParam } from 'use-query-params';
import gql from 'graphql-tag';
import { setProjectCycleFakeIt } from 'utils/fakeIt/projectCycleFakeIt';

const Tertiary = styled(TertiaryButton)`
  margin-right: 1.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    min-width: 10rem;
  }
`;
const Primary = styled(PrimaryButton)`
  @media (max-width: ${(props) => props.theme.breakpoints.xxs}) {
    min-width: 10rem;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function getCancelButtonToggle({ disabled, t }) {
  return function toggle(show) {
    return (
      <QuadButton
        disabled={disabled}
        onClick={() => {
          show();
          GoogleAnalyticsClient.event(GA_EVENTS.cancelJob);
        }}
      >
        {t('jobs:Cancel Job')}
      </QuadButton>
    );
  };
}

function getCancelButtonToggleContent({ handleCancelJob, t }) {
  return function content(hide) {
    return (
      <Confirmation
        hide={hide}
        confirm={handleCancelJob}
        action={t('common:Yes, Cancel')}
        itemName={t('common:Cancel')}
      >
        <p>{t('jobs:Are you sure you want to cancel this job?')}</p>
      </Confirmation>
    );
  };
}

function FulfillButtons({ checklistsConfig }) {
  const { globalSnack } = useContext(Session);
  const { user } = useContext(UserAccessContext.Context);

  const {
    job,
    close,
    refetch,
    submitted,
    toggleSubmitted,
    loading: detailLoading,
    updateJob,
    updateLoading,
  } = useContext(ViewJobDetailsContext);
  const { t } = useTranslation(['common', 'jobs']);

  const [projectCycleId] = useQueryParam('pCycle', StringParam);
  const [projectCycleJobId] = useQueryParam('projectCycleJobId', StringParam);

  const update = useCallback(
    async (input, { success, error }) => {
      try {
        await updateJob({
          variables: {
            id: job.id,
            input: {
              ...input,
              action:
                !job.action || job.action === 'noAction'
                  ? 'noAction'
                  : job.action.toLowerCase(),
            },
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCompleted: () => {},
          update: (cache, { data: { updateJobById } }) => {
            if (projectCycleId) {
              setProjectCycleFakeIt({
                projectCycleId,
                job,
                status: input.status,
              });
            }

            let projectStatus = input.status;
            if (input.status === JOB_STATUS.completed)
              projectStatus = JOB_STATUS.done;

            if (
              input.status === JOB_STATUS.pending ||
              input.status === JOB_STATUS.new
            )
              projectStatus = JOB_STATUS.not_started;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById.status.split('_').join(' '),
              },
            });
            cache.writeFragment({
              id: `ProjectCycleJob:${projectCycleJobId}`,
              fragment: gql`
                fragment UpdatedProjectCycleJob on ProjectCycleJob {
                  status
                }
              `,
              data: {
                status: projectStatus,
              },
            });
          },
        });
        success &&
          globalSnack({
            message: success,
            timeout: 5000,
          });
        refetch();
      } catch (err) {
        console.error(error, err);
        error &&
          globalSnack({
            message: error,
            timeout: 5000,
            error: true,
          });
      }
    },
    [globalSnack, job, projectCycleId, projectCycleJobId, refetch, updateJob],
  );

  // Take the job
  async function handleTakeJob() {
    const assignee = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
    };
    await update(
      {
        assignee,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:has been assigned to you',
        )}`,
        error: t(`jobs:There was a problem taking this job`),
      },
    );
    GoogleAnalyticsClient.event(GA_EVENTS.takeJob);
  }

  // Start the job
  async function handleStartJob() {
    if (job.checklists !== null)
      GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
    await update(
      {
        status: JOB_STATUS.in_progress,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:started',
        )}`,
        error: t(`jobs:There was a problem starting this job`),
      },
    );
    GoogleAnalyticsClient.event(GA_EVENTS.startJob);
  }

  // Take and start the job
  async function handleTakeAndStart() {
    const assignee = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      userName: user.userName,
    };
    await update(
      {
        status: JOB_STATUS.in_progress,
        assignee,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:has been assigned to you and started',
        )}`,
        error: t(`jobs:There was a problem taking and starting this job`),
      },
    );
    GoogleAnalyticsClient.event(GA_EVENTS.takeAndStartJob);
  }

  // Cancel the job
  const handleCancelJob = useCallback(async () => {
    await update(
      {
        status: JOB_STATUS.cancelled,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'common:has been cancelled',
        )}`,
        error: t(`jobs:There was a problem cancelling this job`),
      },
    );
    close();
    GoogleAnalyticsClient.event(GA_EVENTS.cancelJobConfirm);
  }, [close, job, t, update]);

  // Pause the job
  async function handlePauseJob() {
    await update(
      {
        status: JOB_STATUS.on_hold,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:has been paused',
        )}`,
        error: t(`jobs:There was a problem pausing this job`),
      },
    );
    close();
    GoogleAnalyticsClient.event(GA_EVENTS.pauseJob);
  }

  // Complete the Job
  async function handleCompleteJob() {
    toggleSubmitted(true);

    const firstRequiredTaskNotChecked = checklistsConfig.requiredTasks.find(
      (requiredTask) =>
        !CHECKLIST_TASK_TYPE_CONFIG[
          requiredTask.taskType
        ]?.fulfillment?.isFulfilled(requiredTask),
    );
    if (firstRequiredTaskNotChecked) {
      setTimeout(() =>
        document
          .querySelector(`#item-${firstRequiredTaskNotChecked?.id}`)
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }),
      );
      return;
    }
    await update(
      {
        status: JOB_STATUS.completed,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:has been marked as complete',
        )}`,
        error: t(`jobs:There was a problem completing this job`),
      },
    );
    close();
    GoogleAnalyticsClient.event(GA_EVENTS.completeJob);
  }

  // Reopen the job
  function handleReopenJob() {
    update(
      {
        status: JOB_STATUS.pending,
      },
      {
        success: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:has been reopened',
        )}`,
        error: t(`jobs:There was a problem reopening this job`),
      },
    );
  }

  const disabled = updateLoading || detailLoading;

  const allRequiredTaskAreDone =
    checklistsConfig.requiredTasks?.length ===
    checklistsConfig.completedRequiredTasks?.length;

  const canComplete =
    job?.checklists?.length > 0 ? allRequiredTaskAreDone : true;

  const CancelButton = useCallback(
    () => (
      <ToggleContent
        toggle={getCancelButtonToggle({ disabled, t })}
        content={getCancelButtonToggleContent({ handleCancelJob, t })}
      />
    ),
    [disabled, handleCancelJob, t],
  );

  function getButtons() {
    switch (job?.status) {
      case JOB_STATUS.pending:
      case JOB_STATUS.new:
      case JOB_STATUS.on_hold:
        if (
          job?.assignee &&
          job?.assignee.id !== AUTO_ASSIGN_ID &&
          job?.assignee.id === user.id
        ) {
          return (
            <>
              <CancelButton />
              <Primary disabled={disabled} onClick={handleStartJob}>
                {t('jobs:Start')}
              </Primary>
            </>
          );
        }
        return (
          <>
            <CancelButton />
            <Tertiary disabled={disabled} onClick={handleTakeAndStart}>
              {t('jobs:Take and Start')}
            </Tertiary>
            {job?.assignee?.id ? (
              <Primary disabled={disabled} onClick={handleStartJob}>
                {t('jobs:Start')}
              </Primary>
            ) : (
              <Primary disabled={disabled} onClick={handleTakeJob}>
                {t('jobs:Take')}
              </Primary>
            )}
          </>
        );

      // break;
      case JOB_STATUS.in_progress:
        return (
          <>
            <CancelButton />
            <Tertiary disabled={disabled} onClick={handlePauseJob}>
              {t('jobs:Pause')}
            </Tertiary>
            <Primary
              disabled={disabled || (!canComplete && submitted)}
              onClick={handleCompleteJob}
            >
              {t('jobs:Complete')}
            </Primary>
          </>
        );
      case JOB_STATUS.completed:
      case JOB_STATUS.cancelled:
      case JOB_STATUS.archived:
        return (
          <Primary disabled={disabled} onClick={handleReopenJob}>
            {t('jobs:Reopen')}
          </Primary>
        );
      default:
        console.error("Job Status didn't match anything");
    }

    return null;
  }
  return (
    <ButtonContainer data-testid="button-container">
      {getButtons()}
    </ButtonContainer>
  );
}
export default FulfillButtons;
