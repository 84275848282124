import React from 'react';

export const ViewJobDetailsContext = React.createContext({
  job: {},
  asset: {},
  audit: [],
  checklists: [],
  submitted: false,
  isUpdateChecklistTaskLoading: false,
  isRepeatingJobTemplate: false,
  updateLoading: false,
  loading: false,
  toggleSubmitted: () => {
    // handle toggle submitted
  },
  refetch: () => {
    // handle refetch
  },
  updateJob: () => {
    // handle update job
  },
  updateChecklistTask: () => {
    // handle update checklist task
  },
  toggleIsUpdateChecklistTaskLoading: () => {
    // handle toggle is update checklist
  },
  setJob: () => {
    // handle set job
  },
  close: () => {
    // handle close
  },
});
