import React, { useContext, useMemo, useState } from 'react';
import Icons from 'blocks/Icons';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';
import { useQueryParams, StringParam } from 'use-query-params';

import { GA_EVENTS, ViewJobDetailsContext, useAccess } from '@optii/shared';
import { PERMISSIONS } from '@optii/shared/constants/permissions';

import FieldBlock from 'blocks/Field';
import DateTimeField from 'components/shared/DateTimeField';
import JobTabs from 'components/shared/Job/JobTabs';
import { CHECKLIST_TASK_TYPE_CONFIG } from 'checklists/constants';
import { ReactComponent as CalendarIcon } from 'images/svg/calendar-icon.svg';
import { ReactComponent as AvatarIcon } from 'images/svg/avatar-icon-thin.svg';
import { ReactComponent as ObjectIcon } from 'images/svg/object-icon.svg';
import { ReactComponent as ChecklistIcon } from 'images/svg/checklist-icon.svg';
import { ReactComponent as ClockCalendarIcon } from 'images/svg/clock-repeating.svg';
import { ReactComponent as RepeatingJobIcon } from 'images/svg/ico-repeating-job-gray.svg';
import { weekDigit } from 'utils/constants/repeatingRule';
import { getType } from 'utils/formatters/jobs';
import { getMultipleDailyToText } from 'utils/formatters/repeatingJobs';
import { getRepeatingRuleToText } from 'utils/formatters/repeatingRules';

import Attachments from '../Attachments';
import Field from '../form/Field';

import FulfillButtons from './FulfillButtons';
import {
  ModalContainer,
  BottomModalWrapper,
  ChecklistText,
  Description,
  MiddleModalWrapper,
  ReadyOnlyField,
  TopModalContent,
  TopModalWrapper,
  WeekDayContainer,
  Type,
  IconContainer,
} from './ViewComponents';

function repeatingJobData(job, isRepeatingJobTemplate) {
  if (job?.metadata?.source !== 'repeating-jobs-svc' && !isRepeatingJobTemplate)
    return {
      cycleValue: undefined,
    };

  if (isRepeatingJobTemplate) {
    return {
      cycleValue: job.cycle.value,
    };
  }
  return {
    cycleValue: job.metadata.privateAttributes[job.metadata.source].CycleValue,
  };
}

function ViewJobDetails({
  overdue,
  dueDate,
  assignment,
  queuedJobRequest,
  handleFileUpload,
  fulfilActions,
  description,
  dueTime,
  dueTimeLabel,
  isHousekeepingJob,
}) {
  const { job, checklists, asset, isRepeatingJobTemplate } = useContext(
    ViewJobDetailsContext,
  );

  const [query, setQuery] = useQueryParams({
    assetId: StringParam,
  });

  const { can } = useAccess();
  const canAddEditHousekeepingJobs = can(PERMISSIONS.house_keeping.add_edit);

  const [isHeaderOpen, setIsHeaderOpen] = useState(true);
  const { t } = useTranslation(['jobs', 'common', 'fields']);

  const hasChecklist = Array.isArray(checklists) && checklists.length > 0;

  const dueByLabel = isRepeatingJobTemplate
    ? t('common:Date')
    : t('common:Due By');

  const cycleData = useMemo(
    () => repeatingJobData(job, isRepeatingJobTemplate),
    [job, isRepeatingJobTemplate],
  ).cycleValue;

  const getCycleText = useMemo(() => {
    if (!cycleData) return {};
    // eslint-disable-next-line new-cap
    const repeatingRule = cycleData && new RRule.fromString(cycleData);
    const ruleString = repeatingRule && getRepeatingRuleToText(repeatingRule);

    if (repeatingRule && repeatingRule.origOptions.freq === RRule.WEEKLY) {
      const daysOfWeek =
        repeatingRule.origOptions.byweekday
          ?.sort((a, b) => a.weekday - b.weekday)
          ?.map((day) => weekDigit(t)[day]) || [];

      return {
        label: t('common:Every {{count}} week', {
          count: repeatingRule.origOptions.interval,
        }),
        weekDays: daysOfWeek.length === 7 ? [] : daysOfWeek,
      };
    }

    if (repeatingRule && repeatingRule.origOptions.freq === RRule.DAILY) {
      return {
        label: getMultipleDailyToText(
          {
            value: cycleData,
            displayName: ruleString,
          },
          t,
        ),
        weekDays: [],
      };
    }

    return ruleString
      ? {
          label: `${ruleString.charAt(0).toUpperCase()}${ruleString.slice(1)}`,
          weekDays: [],
        }
      : {
          label: undefined,
          weekDays: [],
        };
  }, [cycleData, t]);

  const completableTasks = useMemo(() => {
    if (hasChecklist) {
      return checklists[0][
        isRepeatingJobTemplate ? 'tasks' : 'checklistTasks'
      ].filter(
        (task) =>
          CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.fulfillable,
      );
    }
    return [];
  }, [checklists, hasChecklist, isRepeatingJobTemplate]);

  const requiredTasks = useMemo(() => {
    if (hasChecklist) {
      return completableTasks.filter((chklst) => chklst.required);
    }
    return [];
  }, [completableTasks, hasChecklist]);

  const completedTasks = useMemo(() => {
    if (hasChecklist) {
      return completableTasks.filter((task) =>
        CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.isFulfilled(
          task,
        ),
      );
    }
    return [];
  }, [completableTasks, hasChecklist]);

  const completedRequiredTasks = useMemo(() => {
    if (hasChecklist) {
      return completableTasks.filter(
        (task) =>
          task.required &&
          CHECKLIST_TASK_TYPE_CONFIG[task.taskType]?.fulfillment?.isFulfilled(
            task,
          ),
      );
    }
    return [];
  }, [completableTasks, hasChecklist]);

  const cycleTextLabel = getCycleText?.label;
  const weekDays = getCycleText?.weekDays || [];

  return (
    <ModalContainer data-testid="view-job">
      <TopModalWrapper>
        <TopModalContent>
          <Type data-testid="job-type">{getType(job)}</Type>
          <Description
            data-testid="job-description"
            isHeaderOpen={isHeaderOpen}
          >
            <h3>{description}</h3>
            <Icons.Arrow onClick={() => setIsHeaderOpen(!isHeaderOpen)} />
          </Description>
          {isHeaderOpen && (
            <>
              <ReadyOnlyField overdue={overdue}>
                <IconContainer>
                  <CalendarIcon />
                </IconContainer>
                {isRepeatingJobTemplate ? (
                  <Field
                    value={dueDate}
                    label={dueByLabel}
                    readOnly
                    data-testid="due-by-time"
                  />
                ) : (
                  <DateTimeField
                    value={dueDate}
                    label={dueByLabel}
                    readOnly
                    data-testid="due-by-time"
                  />
                )}
              </ReadyOnlyField>
              <ReadyOnlyField>
                <IconContainer>
                  <AvatarIcon />
                </IconContainer>
                <Field
                  value={assignment}
                  title={assignment}
                  type="text"
                  label={t('jobs:Assigned to')}
                  readOnly
                  modifiers="alignStart"
                  data-testid="job-assigned-to"
                />
              </ReadyOnlyField>

              {cycleData && (
                <>
                  <ReadyOnlyField smallMargin={!!weekDays.length}>
                    <IconContainer>
                      <RepeatingJobIcon />
                    </IconContainer>
                    <Field
                      value={cycleTextLabel}
                      title={cycleTextLabel}
                      type="text"
                      label={t('jobs:Repeat')}
                      readOnly
                      modifiers="alignStart"
                      data-testid="job-repeat"
                    />
                  </ReadyOnlyField>

                  {!!weekDays.length && (
                    <WeekDayContainer hasAdjacentFields={asset || dueTime}>
                      {weekDays.map((day) => (
                        <span>{day}</span>
                      ))}
                    </WeekDayContainer>
                  )}
                </>
              )}

              {asset && (
                <ReadyOnlyField>
                  <IconContainer>
                    <ObjectIcon />
                  </IconContainer>

                  <Field
                    value={`${asset?.assetType?.displayName} - ${asset?.location?.longDisplayName}`}
                    title={`${asset?.assetType?.displayName} - ${asset?.location?.longDisplayName}`}
                    type="text"
                    label={t('fields:Asset')}
                    readOnly
                    modifiers="alignStart"
                    data-testid="job-asset"
                    isLink
                    onClick={() => {
                      setQuery({
                        assetId: asset.id,
                      });
                    }}
                  />
                </ReadyOnlyField>
              )}

              {dueTime && (
                <ReadyOnlyField>
                  <IconContainer>
                    <ClockCalendarIcon />
                  </IconContainer>
                  <Field
                    value={dueTime}
                    title={dueTime}
                    label={dueTimeLabel}
                    readOnly
                    type="text"
                    modifiers="alignStart"
                    data-testid="due-time"
                  />
                </ReadyOnlyField>
              )}
            </>
          )}
          {hasChecklist && (
            <ReadyOnlyField hasMarginTop={!isHeaderOpen}>
              <IconContainer>
                <ChecklistIcon />
              </IconContainer>
              <FieldBlock>
                <FieldBlock.Label>
                  {t('checklist: Tasks Completed')}
                </FieldBlock.Label>
                <div>
                  <ChecklistText>
                    {t('checklist: {{totalCompleted}}/{{total}} Total', {
                      totalCompleted: completedTasks.length,
                      total: completableTasks.length,
                    })}
                  </ChecklistText>
                  {requiredTasks.length > 0 && (
                    <>
                      <span>, </span>
                      <ChecklistText isRequired>
                        {t('checklist: {{totalCompleted}}/{{total}} Required', {
                          totalCompleted: completedRequiredTasks.length,
                          total: requiredTasks.length,
                        })}
                      </ChecklistText>
                    </>
                  )}
                </div>
              </FieldBlock>
            </ReadyOnlyField>
          )}
        </TopModalContent>
      </TopModalWrapper>
      <MiddleModalWrapper>
        <JobTabs
          isRepeatingJobTemplate={isRepeatingJobTemplate}
          isHousekeepingJob={isHousekeepingJob}
          hasChecklist={hasChecklist}
        />
        <Attachments
          values={job}
          hideUploads={
            queuedJobRequest ||
            (isHousekeepingJob && !canAddEditHousekeepingJobs)
          }
          handleUpload={handleFileUpload}
          analytics={GA_EVENTS.addFileJob}
          isRepeatingJobTemplate={isRepeatingJobTemplate}
        />
      </MiddleModalWrapper>
      {fulfilActions && job?.status !== 'queued' && (
        <BottomModalWrapper>
          <FulfillButtons
            data-testid="fulfill-buttons"
            checklistsConfig={{
              requiredTasks,
              completedTasks,
              completedRequiredTasks,
            }}
          />
        </BottomModalWrapper>
      )}
    </ModalContainer>
  );
}

export default ViewJobDetails;
