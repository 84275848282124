import React from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.fonts.type.secondary};
  color: ${(props) => props.theme.colors.charcoal};
  ${(props) => props.theme.fonts.weights.light}
  ${(props) => props.theme.fonts.sizes.largest}
  padding: 0.5rem;
`;
const StyledDiv = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  ${(props) => props.theme.fonts.type.secondary};
  color: ${(props) => props.theme.colors.charcoal};
  ${(props) => props.theme.fonts.weights.light}
  ${(props) => props.theme.fonts.sizes.largest}
  padding: 0.5rem;
  ${(props) =>
    props.isActive && 'border-bottom: 3px solid #2b7c96; font-weight: 400;'}
`;
const UL = styled.ul`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  ${(props) => props.globalNav && `margin-block-end: 0;`}
  li {
    margin-right: ${(props) =>
      props.spaceBetween ? props.spaceBetween : '3rem'};
  }
  li:last-child {
    margin-right: 0;
  }
`;

function TabNav(props) {
  const { activeStyle: propsActiveStyle, sections } = props;

  const activeStyle = propsActiveStyle || {
    borderBottom: '3px solid #2b7c96',
    fontWeight: '400',
  };

  return (
    <nav>
      <UL {...props}>
        {sections.map((item) => (
          <li key={item.key || item.to}>
            {item.to ? (
              <StyledLink
                to={item.to}
                isActive={item.isActive}
                activeStyle={activeStyle}
                onClick={item.onClick}
              >
                {item.component || item.label}
              </StyledLink>
            ) : (
              <StyledDiv onClick={item.handleClick} isActive={item.isActive}>
                {item.component || item.label}
              </StyledDiv>
            )}
          </li>
        ))}
      </UL>
    </nav>
  );
}
export default TabNav;
