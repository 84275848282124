import {
  Tooltip as AntTooltip,
  ConfigProvider,
  ThemeConfig,
  TooltipProps,
} from 'antd';
import React from 'react';

type Props = TooltipProps & {
  theme?: ThemeConfig;
};

export function Tooltip(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntTooltip {...rest} />
    </ConfigProvider>
  );
}
