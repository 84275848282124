import { Message, Participant } from '@twilio/conversations';
import React, { useEffect, useState } from 'react';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {
  COLORS,
  ConfigProvider,
  FONTS,
  Typography,
} from '@optii-solutions/ui-library';
import { getMessageStatus } from '../../utils/message';
import { MessageStatus } from '../../types';

type StatusProps = {
  message: Message;
  participants: Participant[];
  loggedUserId: string;
};

function Status({ message, participants, loggedUserId }: StatusProps) {
  const [status, setStatus] = useState<{
    [MessageStatus.Delivered]?: number;
    [MessageStatus.Read]?: number;
    [MessageStatus.Failed]?: number;
    [MessageStatus.Sending]?: number;
  }>({});

  useEffect(() => {
    getMessageStatus(message, participants, loggedUserId).then((receipts) =>
      setStatus(receipts),
    );
  }, [message, participants, loggedUserId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: FONTS.xSmall.size,
          colorText: status[MessageStatus.Read]
            ? COLORS.polarGreen[6]
            : COLORS.neutral[5],
        },
      }}
    >
      <Typography.Text>
        {status[MessageStatus.Sending] ? <ClockCircleOutlined /> : null}
        {status[MessageStatus.Delivered] || status[MessageStatus.Read] ? (
          <CheckOutlined />
        ) : null}

        {status[MessageStatus.Failed] ? <CloseCircleOutlined /> : null}
      </Typography.Text>
    </ConfigProvider>
  );
}

export default Status;
