import { useContext, useCallback } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.min";

import { GET_PROPERTY_BY_ID } from "@optii/shared/queries/property/getPropertyById";
import { PropertyDetailsContext } from "@optii/shared";

export function usePropertyTime(passedProp) {
  const { property } = useContext(PropertyDetailsContext.Context);

  const { data } = useQuery(GET_PROPERTY_BY_ID, {
    fetchPolicy: "cache-first",
    variables: {
      id: passedProp
    },
    skip: !passedProp
  });

  const { regionalSettings} = property;
  const { timeZone: timezone } = regionalSettings;

  const tz = !passedProp
    ? property && property.regionalSettings.timeZone
    : data && data.property && data.property.regionalSettings.timeZone;

  // Takes a unix timestamp
  const toPropertyTime = useCallback(
    (value, timeZone = tz) => {
      // Useful if you want to debug
      // if (tz) {
      //   const format = "ll [at] LTS";
      //   const time = moment.unix(value);
      //   console.info("UTC:", time.utc().format(format));
      //   console.info("Local:", moment.unix(value).format(format));
      //   console.info(`Timezone ${tz}:`, time.tz(tz).format(format));
      // }

      if (!timeZone) {
        return moment.unix(value);
      }

      return moment.unix(value).tz(timeZone);
    },
    [tz]
  );
  function secToPropertyTime(value, base) {
    return moment()
      .unix(base)
      .add(value, "seconds")
      .tz(tz);
  }
  function generateInPropertyTime(value) {
    return moment.tz(value, tz);
  }

  return {
    timezone,
    toPropertyTime,
    secToPropertyTime,
    generateInPropertyTime
  };
}
