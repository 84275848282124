export const RushInactive = () => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75355 15.6478C4.55818 15.8424 4.24211 15.8421 4.04711 15.6471L3.35355 14.9536C3.15829 14.7583 3.15829 14.4417 3.35355 14.2464L11.7464 5.85355C12.0614 5.53857 11.8383 5 11.3929 5H10.5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 9.77614 7 9.5 7H8.5C8.22386 7 8 6.77614 8 6.5V3.5C8 3.22386 8.22386 3 8.5 3H13.825C14.0917 3 14.35 3.05 14.6 3.15C14.85 3.25 15.0667 3.39167 15.25 3.575L18.25 6.55C18.7 7 19.25 7.35 19.9 7.6C20.4069 7.79496 20.9442 7.91897 21.5119 7.97202C21.7825 7.99732 22 8.2172 22 8.48901V9.5C22 9.77614 21.7759 10.0015 21.5002 9.98727C20.6623 9.94391 19.8914 9.78982 19.1875 9.525C18.3458 9.20833 17.6 8.73333 16.95 8.1L16.3033 7.42101C16.1096 7.21759 15.7864 7.21364 15.5877 7.41228L14.1036 8.89645C13.9083 9.09171 13.9083 9.40829 14.1036 9.60355L15.5389 11.0389C15.7715 11.2715 15.72 11.6614 15.435 11.8256L9.88211 15.026C9.64328 15.1636 9.33811 15.082 9.19987 14.8435L8.70154 13.9839C8.56275 13.7445 8.64483 13.4379 8.88469 13.2998L12.1845 11.4005C12.4697 11.2364 12.5213 10.8463 12.2886 10.6136L11.4029 9.72789C11.2079 9.53289 10.8918 9.53259 10.6964 9.72722L4.75355 15.6478ZM2.5 9C2.22386 9 2 8.77614 2 8.5V7.5C2 7.22386 2.22386 7 2.5 7H6.5C6.77614 7 7 7.22386 7 7.5V8.5C7 8.77614 6.77614 9 6.5 9H2.5ZM0.5 6C0.223858 6 0 5.77614 0 5.5V4.5C0 4.22386 0.223858 4 0.5 4H4.5C4.77614 4 5 4.22386 5 4.5V5.5C5 5.77614 4.77614 6 4.5 6H0.5ZM18.475 4C17.925 4 17.45 3.80417 17.05 3.4125C16.65 3.02083 16.45 2.55 16.45 2C16.45 1.45 16.65 0.979167 17.05 0.5875C17.45 0.195833 17.925 0 18.475 0C19.025 0 19.5 0.195833 19.9 0.5875C20.3 0.979167 20.5 1.45 20.5 2C20.5 2.55 20.3 3.02083 19.9 3.4125C19.5 3.80417 19.025 4 18.475 4ZM2.5 3C2.22386 3 2 2.77614 2 2.5V1.5C2 1.22386 2.22386 1 2.5 1H6.5C6.77614 1 7 1.22386 7 1.5V2.5C7 2.77614 6.77614 3 6.5 3H2.5Z"
      fill="#BFBFBF"
    />
  </svg>
);

export const RushActive = () => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75355 15.6478C4.55818 15.8424 4.24211 15.8421 4.04711 15.6471L3.35355 14.9536C3.15829 14.7583 3.15829 14.4417 3.35355 14.2464L11.7464 5.85355C12.0614 5.53857 11.8383 5 11.3929 5H10.5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 9.77614 7 9.5 7H8.5C8.22386 7 8 6.77614 8 6.5V3.5C8 3.22386 8.22386 3 8.5 3H13.825C14.0917 3 14.35 3.05 14.6 3.15C14.85 3.25 15.0667 3.39167 15.25 3.575L18.25 6.55C18.7 7 19.25 7.35 19.9 7.6C20.4069 7.79496 20.9442 7.91897 21.5119 7.97202C21.7825 7.99732 22 8.2172 22 8.48901V9.5C22 9.77614 21.7759 10.0015 21.5002 9.98727C20.6623 9.94391 19.8914 9.78982 19.1875 9.525C18.3458 9.20833 17.6 8.73333 16.95 8.1L16.3033 7.42101C16.1096 7.21759 15.7864 7.21364 15.5877 7.41228L14.1036 8.89645C13.9083 9.09171 13.9083 9.40829 14.1036 9.60355L15.5389 11.0389C15.7715 11.2715 15.72 11.6614 15.435 11.8256L9.88211 15.026C9.64328 15.1636 9.33811 15.082 9.19987 14.8435L8.70154 13.9839C8.56275 13.7445 8.64483 13.4379 8.88469 13.2998L12.1845 11.4005C12.4697 11.2364 12.5213 10.8463 12.2886 10.6136L11.4029 9.72789C11.2079 9.53289 10.8918 9.53259 10.6964 9.72722L4.75355 15.6478ZM2.5 9C2.22386 9 2 8.77614 2 8.5V7.5C2 7.22386 2.22386 7 2.5 7H6.5C6.77614 7 7 7.22386 7 7.5V8.5C7 8.77614 6.77614 9 6.5 9H2.5ZM0.5 6C0.223858 6 0 5.77614 0 5.5V4.5C0 4.22386 0.223858 4 0.5 4H4.5C4.77614 4 5 4.22386 5 4.5V5.5C5 5.77614 4.77614 6 4.5 6H0.5ZM18.475 4C17.925 4 17.45 3.80417 17.05 3.4125C16.65 3.02083 16.45 2.55 16.45 2C16.45 1.45 16.65 0.979167 17.05 0.5875C17.45 0.195833 17.925 0 18.475 0C19.025 0 19.5 0.195833 19.9 0.5875C20.3 0.979167 20.5 1.45 20.5 2C20.5 2.55 20.3 3.02083 19.9 3.4125C19.5 3.80417 19.025 4 18.475 4ZM2.5 3C2.22386 3 2 2.77614 2 2.5V1.5C2 1.22386 2.22386 1 2.5 1H6.5C6.77614 1 7 1.22386 7 1.5V2.5C7 2.77614 6.77614 3 6.5 3H2.5Z"
      fill="#CF1322"
    />
  </svg>
);

export const Priority = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="2" fill="#F2722C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99783 11.0877C8.61148 11.0877 9.10894 11.5904 9.10894 12.2105C9.10894 12.8306 8.61148 13.3333 7.99783 13.3333C7.38418 13.3333 6.88672 12.8306 6.88672 12.2105C6.88672 11.5904 7.38418 11.0877 7.99783 11.0877ZM8.70292 2.66666C8.88887 2.66666 9.03962 2.81137 9.03962 2.98989C9.03962 2.99701 9.03937 3.00413 9.03888 3.01124L8.56789 9.84103C8.55618 10.0109 8.40925 10.1429 8.23193 10.1429H7.76517C7.58787 10.1429 7.44095 10.0109 7.4292 9.84109L6.95685 3.0113C6.94453 2.83318 7.08496 2.67919 7.27051 2.66737L8.70292 2.66666Z"
      fill="white"
    />
  </svg>
);

export const Recurring = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.00021" cy="8.00006" r="2.43478" fill="#FADB14" />
    <path
      d="M1.3913 8.00007C1.38918 5.59065 2.69965 3.37137 4.81051 2.20965C6.92137 1.04793 9.49743 1.12825 11.5318 2.4192L10.4783 3.31277C10.38 3.39588 10.3357 3.52642 10.363 3.65215C10.3904 3.77788 10.4848 3.87826 10.6087 3.91312L14.3885 4.96633C14.4188 4.97496 14.4502 4.97929 14.4817 4.9792C14.6738 4.9792 14.8296 4.82348 14.8296 4.63138C14.8295 4.61684 14.8286 4.60232 14.8268 4.5879L14.3443 0.738507C14.3285 0.611692 14.2444 0.503774 14.1253 0.457439C14.0062 0.411105 13.8713 0.433818 13.7739 0.516594L12.6372 1.48112C10.1976 -0.254308 6.99315 -0.48229 4.3324 0.890265C1.67166 2.26282 -1.66273e-05 5.00617 0 8.00007C5.725e-09 8.38427 0.311454 8.69572 0.695652 8.69572C1.07985 8.69572 1.3913 8.38427 1.3913 8.00007Z"
      fill="#158EB6"
    />
    <path
      d="M15.3042 7.30441C14.92 7.30441 14.6085 7.61587 14.6085 8.00007C14.6108 10.4095 13.3004 12.6288 11.1896 13.7906C9.07875 14.9523 6.50269 14.872 4.46835 13.5809L5.52157 12.6874C5.61979 12.6043 5.66411 12.4737 5.6368 12.348C5.60948 12.2223 5.51499 12.1219 5.39114 12.087L1.60992 11.0338C1.4978 11.0026 1.37753 11.0296 1.28945 11.1057C1.20138 11.1817 1.15717 11.2968 1.17166 11.4122L1.65548 15.2616C1.6714 15.3883 1.75544 15.4961 1.87442 15.5424C1.9934 15.5887 2.1282 15.5661 2.22557 15.4835L3.36261 14.519C5.80224 16.2544 9.00668 16.4824 11.6674 15.1099C14.3282 13.7373 15.9998 10.994 15.9998 8.00007C15.9998 7.61587 15.6884 7.30441 15.3042 7.30441Z"
      fill="#158EB6"
    />
  </svg>
);

export const ImagesIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.182 6.18197H1.81836V0.363789C1.81836 0.162958 1.98116 0.000152588 2.182 0.000152588H13.8184C14.0192 0.000152588 14.182 0.162958 14.182 0.363789V6.18197Z"
      fill="#8C8C8C"
    />
    <path
      d="M15.2727 1.45471H0.727273C0.325611 1.45471 0 1.78032 0 2.18198V13.8183C0 14.22 0.325611 14.5456 0.727273 14.5456H15.2727C15.6744 14.5456 16 14.22 16 13.8183V2.18198C16 1.78032 15.6744 1.45471 15.2727 1.45471Z"
      fill="#D9D9D9"
    />
    <path
      d="M14.5454 13.4547H1.45446C1.25363 13.4547 1.09082 13.2919 1.09082 13.091V2.90923C1.09082 2.7084 1.25363 2.54559 1.45446 2.54559H14.5454C14.7462 2.54559 14.909 2.7084 14.909 2.90923V13.091C14.909 13.2919 14.7462 13.4547 14.5454 13.4547Z"
      fill="white"
    />
    <ellipse
      cx="6.90927"
      cy="5.45471"
      rx="1.09091"
      ry="1.09091"
      fill="#BFBFBF"
    />
    <path
      d="M8.88062 11.6365L5.71153 8.47032C5.64024 8.39804 5.54168 8.35933 5.44025 8.36378C5.33918 8.36771 5.24431 8.41356 5.17844 8.49032L2.99662 11.0358C2.90392 11.1436 2.88255 11.2955 2.9419 11.4247C3.00126 11.5539 3.13045 11.6366 3.27262 11.6365H8.88062Z"
      fill="#D9D9D9"
    />
    <path
      d="M13.0217 11.0591L10.1126 7.05908C10.0454 6.96638 9.93849 6.91072 9.82403 6.90886C9.70956 6.907 9.60091 6.95915 9.53076 7.04962L5.96094 11.6365H12.7275C12.8643 11.6365 12.9895 11.5598 13.0515 11.438C13.1136 11.3161 13.1021 11.1697 13.0217 11.0591Z"
      fill="#595959"
    />
  </svg>
);

export const NotesIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="1.29559"
      width="14.5"
      height="14.5"
      rx="2.25"
      fill="white"
      stroke="#D9D9D9"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <rect
      x="4"
      y="5.21228"
      width="8"
      height="1.33333"
      rx="0.666667"
      fill="#D9D9D9"
    />
    <rect
      x="4"
      y="7.87894"
      width="8"
      height="1.33333"
      rx="0.666667"
      fill="#D9D9D9"
    />
    <rect
      x="4"
      y="10.5456"
      width="5.33333"
      height="1.33333"
      rx="0.666667"
      fill="#D9D9D9"
    />
  </svg>
);

export const Close = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3871 11.9987L20.4183 3.61749C20.5362 3.47821 20.4371 3.2666 20.2549 3.2666H18.1174C17.9916 3.2666 17.871 3.32285 17.788 3.41928L11.9889 10.3327L6.18977 3.41928C6.10941 3.32285 5.98887 3.2666 5.8603 3.2666H3.7228C3.54066 3.2666 3.44155 3.47821 3.55941 3.61749L10.5907 11.9987L3.55941 20.38C3.53301 20.411 3.51607 20.449 3.51061 20.4894C3.50514 20.5298 3.51138 20.5709 3.52859 20.6078C3.54579 20.6448 3.57323 20.676 3.60766 20.6978C3.64209 20.7196 3.68205 20.7311 3.7228 20.7309H5.8603C5.98619 20.7309 6.10673 20.6746 6.18977 20.5782L11.9889 13.6648L17.788 20.5782C17.8683 20.6746 17.9889 20.7309 18.1174 20.7309H20.2549C20.4371 20.7309 20.5362 20.5193 20.4183 20.38L13.3871 11.9987Z"
      fill="#BFBFBF"
    />
  </svg>
);

export const Filter = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7254 15.9775H9.8038C8.72089 15.9775 7.84302 16.8437 7.84302 17.9122C7.84302 18.9807 8.72089 19.8468 9.8038 19.8468H13.7254C14.8083 19.8468 15.6862 18.9807 15.6862 17.9122C15.6862 16.8437 14.8083 15.9775 13.7254 15.9775Z"
      fill="#FBC633"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6471 8.23828H5.88242C4.7995 8.23828 3.92163 9.10445 3.92163 10.1729C3.92163 11.2414 4.7995 12.1076 5.88242 12.1076H17.6471C18.73 12.1076 19.6079 11.2414 19.6079 10.1729C19.6079 9.10445 18.73 8.23828 17.6471 8.23828Z"
      fill="#44A5C5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5686 0.5H1.96078C0.877873 0.5 0 1.36617 0 2.43464C0 3.50311 0.877873 4.36928 1.96078 4.36928H21.5686C22.6515 4.36928 23.5294 3.50311 23.5294 2.43464C23.5294 1.36617 22.6515 0.5 21.5686 0.5Z"
      fill="#44A5C5"
    />
  </svg>
);

export const TableIcons = {
  edit: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.625 19.5938H3.375C2.96016 19.5938 2.625 19.9289 2.625 20.3438V21.1875C2.625 21.2906 2.70937 21.375 2.8125 21.375H21.1875C21.2906 21.375 21.375 21.2906 21.375 21.1875V20.3438C21.375 19.9289 21.0398 19.5938 20.625 19.5938ZM6.03984 17.625C6.08672 17.625 6.13359 17.6203 6.18047 17.6133L10.1227 16.9219C10.1695 16.9125 10.2141 16.8914 10.2469 16.8563L20.182 6.92109C20.2038 6.89941 20.221 6.87366 20.2328 6.8453C20.2445 6.81695 20.2506 6.78656 20.2506 6.75586C20.2506 6.72516 20.2445 6.69477 20.2328 6.66642C20.221 6.63806 20.2038 6.61231 20.182 6.59063L16.2867 2.69297C16.2422 2.64844 16.1836 2.625 16.1203 2.625C16.057 2.625 15.9984 2.64844 15.9539 2.69297L6.01875 12.6281C5.98359 12.6633 5.9625 12.7055 5.95312 12.7523L5.26172 16.6945C5.23892 16.8201 5.24707 16.9493 5.28545 17.071C5.32384 17.1927 5.39132 17.3032 5.48203 17.393C5.63672 17.543 5.83125 17.625 6.03984 17.625ZM7.61953 13.5375L16.1203 5.03906L17.8383 6.75703L9.3375 15.2555L7.25391 15.6234L7.61953 13.5375Z"
        fill="#8C8C8C"
      />
    </svg>
  ),
  delete: () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 6H17.25V4.125C17.25 3.29766 16.5773 2.625 15.75 2.625H8.25C7.42266 2.625 6.75 3.29766 6.75 4.125V6H3.75C3.33516 6 3 6.33516 3 6.75V7.5C3 7.60313 3.08437 7.6875 3.1875 7.6875H4.60312L5.18203 19.9453C5.21953 20.7445 5.88047 21.375 6.67969 21.375H17.3203C18.1219 21.375 18.7805 20.7469 18.818 19.9453L19.3969 7.6875H20.8125C20.9156 7.6875 21 7.60313 21 7.5V6.75C21 6.33516 20.6648 6 20.25 6ZM8.4375 4.3125H15.5625V6H8.4375V4.3125ZM17.1398 19.6875H6.86016L6.29297 7.6875H17.707L17.1398 19.6875Z"
        fill="#8C8C8C"
      />
    </svg>
  ),
  parent: () => (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Parent Location</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="table/-row/-parent/-default"
          transform="translate(-42.000000, -18.000000)"
        >
          <g
            id="icons/-hierarchy/parent"
            transform="translate(42.000000, 18.000000)"
          >
            <g id="hierarchy-54">
              <path
                d="M15.8571429,8.57142857 L9.42857143,8.57142857 L9.42857143,6.42857143 C9.42857143,6.19168714 9.23667857,6 9,6 C8.76332143,6 8.57142857,6.19168714 8.57142857,6.42857143 L8.57142857,8.57142857 L2.14285714,8.57142857 C1.90617857,8.57142857 1.71428571,8.76311571 1.71428571,9 L1.71428571,11.5714286 C1.71428571,11.8083129 1.90617857,12 2.14285714,12 C2.37953571,12 2.57142857,11.8083129 2.57142857,11.5714286 L2.57142857,9.42857143 L8.57142857,9.42857143 L8.57142857,11.5714286 C8.57142857,11.8083129 8.76332143,12 9,12 C9.23667857,12 9.42857143,11.8083129 9.42857143,11.5714286 L9.42857143,9.42857143 L15.4285714,9.42857143 L15.4285714,11.5714286 C15.4285714,11.8083129 15.6204643,12 15.8571429,12 C16.0938214,12 16.2857143,11.8083129 16.2857143,11.5714286 L16.2857143,9 C16.2857143,8.76311571 16.0938214,8.57142857 15.8571429,8.57142857 Z"
                id="Path"
                fill="#CDCDCD"
              ></path>
              <path
                d="M10.7142857,13.7142857 L7.28571429,13.7142857 C7.04903571,13.7142857 6.85714286,13.9059729 6.85714286,14.1428571 L6.85714286,17.5714286 C6.85714286,17.8083129 7.04903571,18 7.28571429,18 L10.7142857,18 C10.9509643,18 11.1428571,17.8083129 11.1428571,17.5714286 L11.1428571,14.1428571 C11.1428571,13.9059729 10.9509643,13.7142857 10.7142857,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <path
                d="M17.5714286,13.7142857 L14.1428571,13.7142857 C13.9061786,13.7142857 13.7142857,13.9059729 13.7142857,14.1428571 L13.7142857,17.5714286 C13.7142857,17.8083129 13.9061786,18 14.1428571,18 L17.5714286,18 C17.8081071,18 18,17.8083129 18,17.5714286 L18,14.1428571 C18,13.9059729 17.8081071,13.7142857 17.5714286,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <path
                d="M3.85714286,13.7142857 L0.428571429,13.7142857 C0.191892857,13.7142857 0,13.9059729 0,14.1428571 L0,17.5714286 C0,17.8083129 0.191892857,18 0.428571429,18 L3.85714286,18 C4.09382143,18 4.28571429,17.8083129 4.28571429,17.5714286 L4.28571429,14.1428571 C4.28571429,13.9059729 4.09382143,13.7142857 3.85714286,13.7142857 Z"
                id="Path"
                fill="#FBC633"
              ></path>
              <circle
                id="Oval"
                fill="#44A5C5"
                cx="9"
                cy="2.14285714"
                r="2.14285714"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  child: () => (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.2168V0.55013H-8.9407e-08"
        stroke="#CDCDCD"
        strokeLinejoin="round"
        strokeDasharray="1.75 1.75"
      />
      <rect
        x="0.5"
        y="5.7168"
        width="13"
        height="8.33333"
        rx="0.5"
        fill="#FCD15C"
        stroke="#FCD15C"
      />
    </svg>
  ),
  virtual: () => (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.950195"
        width="8.33333"
        height="8.33333"
        rx="0.5"
        stroke="#FCD15C"
      />
      <rect
        x="5.16675"
        y="5.61719"
        width="8.33333"
        height="8.33333"
        rx="0.5"
        stroke="#FCD15C"
      />
      <rect
        x="5.16675"
        y="5.61719"
        width="3.66667"
        height="3.66667"
        rx="0.5"
        fill="#FCD15C"
        stroke="#FCD15C"
      />
    </svg>
  ),
};

export const Priorities = {
  all: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#CDCDCD" />
      <rect width="12" height="12" transform="translate(4 4)" fill="#CDCDCD" />
      <circle cx="10" cy="10" r="5" stroke="white" strokeWidth="2" />
    </svg>
  ),
  highest: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365694)">
        <rect width="20" height="20" rx="2" fill="#CE0000" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0632 6.1673C12.9912 6.07915 12.9274 6.0001 12.8736 5.93045C12.8072 5.84378 12.6982 5.80109 12.5906 5.81959C12.483 5.83809 12.3945 5.91474 12.3608 6.01861C12.1991 6.51243 11.9531 6.97455 11.6338 7.38448C11.6526 6.21726 11.4214 4.15758 9.72988 3.04825C9.63941 2.98883 9.52361 2.98394 9.42846 3.03554C9.33331 3.08714 9.27423 3.18686 9.27468 3.29509C9.27468 4.25779 8.75896 5.28366 7.86238 6.10883C6.9949 6.90754 4.9793 9.01836 5.00016 11.3566C5.01485 12.9846 5.84384 15.2356 7.94672 16.1292C7.97816 15.1075 8.42395 14.3819 8.86004 13.6825C9.27439 13.0172 9.66611 12.3895 9.70196 11.5112C9.70654 11.3961 9.7779 11.2944 9.88451 11.2508C9.99111 11.2073 10.1133 11.23 10.1971 11.309C11.3823 12.4257 12.0173 14.0801 12.0485 16.099C13.5568 15.4557 14.7922 14.0537 14.9744 11.773C15.2098 8.81119 13.7599 7.02538 13.0632 6.1673Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365694">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  high: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365698)">
        <rect width="20" height="20" rx="2" fill="#F2722C" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 14.3421C10.8284 14.3421 11.5 15.049 11.5 15.9211C11.5 16.7931 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.7931 8.5 15.9211C8.5 15.049 9.17157 14.3421 10 14.3421ZM10.9519 2.5C11.2029 2.5 11.4064 2.70351 11.4064 2.95455C11.4064 2.96456 11.4061 2.97458 11.4054 2.98457L10.7696 12.589C10.7538 12.8278 10.5554 13.0135 10.316 13.0135H9.6859C9.44655 13.0135 9.24821 12.8279 9.23236 12.589L8.59468 2.98466C8.57805 2.73417 8.76763 2.51763 9.01811 2.501L10.9519 2.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365698">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  medium: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365702)">
        <rect width="20" height="20" rx="2" fill="#FBC633" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11.4C14.5523 11.4 15 11.8477 15 12.4V12.6C15 13.1523 14.5523 13.6 14 13.6H6C5.44772 13.6 5 13.1523 5 12.6V12.4C5 11.8477 5.44772 11.4 6 11.4H14ZM14 7C14.5523 7 15 7.44772 15 8V8.2C15 8.75228 14.5523 9.2 14 9.2H6C5.44772 9.2 5 8.75228 5 8.2V8C5 7.44772 5.44772 7 6 7H14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365702">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  low: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365706)">
        <rect width="20" height="20" rx="2" fill="#A1D2E2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9514 10.4651C13.8759 10.316 13.7227 10.2222 13.5556 10.2222H10.8889V4.44444C10.8889 4.19898 10.6899 4 10.4445 4H9.55555C9.31009 4 9.1111 4.19898 9.1111 4.44444V10.2222H6.4444C6.27729 10.2222 6.12408 10.316 6.04856 10.4651C5.97261 10.6139 5.98649 10.793 6.08502 10.9282L9.64062 15.8171C9.72439 15.9319 9.85764 16 10 16C10.1424 16 10.2756 15.9319 10.3594 15.8171L13.915 10.9282C14.0135 10.793 14.0274 10.6139 13.9514 10.4651Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365706">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
} as const;
