import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  Session,
  useAccess,
  GET_JOB_BY_ID,
  UPDATE_JOB,
  GET_ASSET_BY_ID,
  GET_LOCATION_BY_ID,
  GA_EVENTS,
  usePropertyTime,
  UserAccessContext,
  UPDATE_CHECKLIST_TASK,
  GET_ROOMS_BY_ID,
  useProductAccess,
  JobDetailsContext,
} from '@optii/shared';

import { CHECKLIST_TASK_TYPE_CONFIG } from '@optii/jobs/checklists/constants';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import { PRODUCT_ACCESS } from '@optii/shared/constants/productAccess';
import { getName } from '@optii/jobs/formatters/jobs';
import { Asset, RoomSummary } from '@optii/jobs/api/repeatingJobs';

import {
  JOB_INSPECTION_EVALUATE,
  JOB_INSPECTION_FAILED,
  JOB_INSPECTION_SUCCESS,
} from '@optii/shared/queries/job';
import type { GetJobByIdQuery, Job, Maybe } from '../../api/jobs';

import { getJobTypes } from '../JobForm/JobForm.helpers';
import { TJobCardData } from '../JobStatusCard/JobStatusCard.types';
import { transformCardData } from '../JobStatusCard/JobStatusCard.helpers';

import {
  transformCancelJobData,
  transformCompleteJobData,
  transformPauseJobData,
  transformReopenJobData,
  transformStartJobData,
  transformTakeAndStartJobData,
  transformTakeJobData,
  transformUpdateJobData,
} from './JobDetails.helpers';
import type {
  THandleAddFile,
  THandleAsset,
  THandleCancelJob,
  THandleCompleteJob,
  THandleLocation,
  THandlePauseJob,
  THandleRemoveFile,
  THandleReopenJob,
  THandleStartJob,
  THandleTakeAndStartJob,
  THandleTakeJob,
  THandleUpdateJob,
} from './JobDetails.types';


type TUseJobDetails = {
  onClose?: (
    e?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => void;
};

export function useJobDetails(props: TUseJobDetails) {
  const { onClose } = props;
  const { canProduct } = useProductAccess();

  const {
    job,
    audit,
    refetch: jobRefetch,
    loading: jobLoading,
    toggleSubmitted,
  } = useContext(JobDetailsContext);

  const { user } = useContext(UserAccessContext.Context);

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  const [showCancelJobConfirmation, setShowCancelJobConfirmation] =
    useState<boolean>(false);
  const [showPassWithFailedConfirmation, setShowPassWithFailedConfirmation] =
    useState<boolean>(false);
  const [failedTasksQty, setFailedTasksQty] =
    useState<number>(0);
  const [showFailConfirmation, setShowFailConfirmation] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('');

  const { t } = useTranslation(['common', 'jobs'], { useSuspense: false });
  const { globalSnack } = useContext(Session);
  const [jobInspectionFailed] = useMutation(JOB_INSPECTION_FAILED, {
    context: { _instance: 'node' },
  });
  const [jobInspectionSuccess] = useMutation(JOB_INSPECTION_SUCCESS, {
    context: { _instance: 'node' },
  });
  const { refetch: jobInspectionEvaluate } = useQuery(JOB_INSPECTION_EVALUATE, { context: { _instance: 'node' }, skip: true } );

  const [query, setQuery] = useQueryParams({
    assetId: StringParam,
    locationId: StringParam,
    edit: StringParam,
    openJob: StringParam,
    project: StringParam,
    projectCycle: StringParam,
    currentJobStatus: StringParam,
    openHousekeepingJob: StringParam,
    openRepeatingJob: StringParam,
    isProjectCycleJob: StringParam,
  });

  const isService =
    !!query.openJob &&
    !query.isProjectCycleJob &&
    !query.project &&
    !query.projectCycle;
  const isHousekeeping = isService && job?.type === 'housekeeping';
  const isRepeating =
    !!query.openRepeatingJob &&
    !query.openJob &&
    !query.isProjectCycleJob &&
    !query.project &&
    !query.projectCycle;
  const isProject =
    !!query.isProjectCycleJob &&
    !!query.isProjectCycleJob &&
    !!query.project &&
    !!query.projectCycle &&
    !query.openJob;

  const { timezone, generateInPropertyTime } = usePropertyTime(null);

  const { can } = useAccess();
  const canEdit = can(PERMISSIONS.jobs.all);

  const updateJobMutation = useMutation(UPDATE_JOB);
  const [updateJob, { loading: updateJobLoading }] = updateJobMutation;

  const [getLocationData, { data: locationData }] = useLazyQuery(
    GET_LOCATION_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: job.locations && job.locations[0]?.id,
      },
    },
  );

  const [getAssetData, { data: assetData }] = useLazyQuery(GET_ASSET_BY_ID, {
    fetchPolicy: 'cache-and-network', // works for Departments, Users, and brokes (if we want to delete one and alone item on the page) JobItems, Floorplan -  does not update cache on refetch (https://github.com/apollographql/apollo-client/issues/6804). Removing this policy brokes pagination. Temporary solution is to use not refetch but get api call function.
    nextFetchPolicy: 'cache-first',
    context: { _instance: 'node' },
  });

  const [
    getRoomsData,
    { data: roomsData, refetch: roomRefetch, loading: roomLoading },
  ] = useLazyQuery(GET_ROOMS_BY_ID, {
    fetchPolicy: 'cache-and-network',
    context: { _instance: 'node' },
  });

  const getCardData: () => Maybe<TJobCardData> = useCallback(
    () =>
      job &&
      transformCardData({
        roomsData: roomsData?.roomsById,
        locationData: locationData?.GetLocationById,
        job: job as Job & {
          asset: Asset;
          room: RoomSummary;
        },
        asset: assetData?.asset,
        timezone,
        t,
      }),
    [
      assetData?.asset,
      job,
      locationData?.GetLocationById,
      roomsData?.roomsById,
      t,
      timezone,
    ],
  );

  const JOB_TYPES = getJobTypes({ t });

  function handleAnalytics(editting: boolean) {
    if (editting) {
      GoogleAnalyticsClient.event(GA_EVENTS.editJobModalCancel);
    } else {
      GoogleAnalyticsClient.event(GA_EVENTS.editJobModal);
    }
  }

  const updateChecklistTask = useMutation(UPDATE_CHECKLIST_TASK, {
    context: { _instance: 'node' },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_JOB_BY_ID,
        variables: {
          id: query.openJob,
          startedAt:
            process.env.NODE_ENV === 'test'
              ? '2024-07-24 15:53:29.601000'
              : '2024-08-06 20:27:31.008847',
        },
      },
    ],
  });

  const handleEdit = useCallback(() => {
    if (!canEdit) {
      return null;
    }

    const nextQueryData = {};

    if (isService) {
      Object.assign(nextQueryData, {
        openHousekeepingJob: '',
        openRepeatingJob: '',
        openJob: job?.id,
      });
    } else if (isHousekeeping) {
      Object.assign(nextQueryData, {
        openHousekeepingJob: job?.id,
        openRepeatingJob: '',
        openJob: job?.id,
      });
    } else if (isRepeating) {
      Object.assign(nextQueryData, {
        openHousekeepingJob: '',
        openRepeatingJob: job?.id,
        openJob: '',
      });
    } else if (isProject) {
      Object.assign(nextQueryData, {
        openHousekeepingJob: '',
        openRepeatingJob: '',
        openJob: job?.id,
        isProjectCycle: '',
      });
    }

    setQuery({
      ...nextQueryData,
      edit: 'true',
    });

    return null;
  }, [
    canEdit,
    isHousekeeping,
    isProject,
    isRepeating,
    isService,
    job?.id,
    setQuery,
  ]);

  const handleUpdateJob = useCallback(
    async ({ job: currentJob, newJob }: THandleUpdateJob) => {
      const input = transformUpdateJobData({
        job: currentJob,
        newJob,
      });

      await updateJob({
        variables: { id: currentJob?.id, input },
        update: (
          cache,
          {
            data,
          }: {
            data?: {
              updateJobById: { id: string | number; status: string };
            };
          },
        ) => {
          const updateJobById = data?.updateJobById;

          cache.writeFragment({
            id: `AdhocJob:${updateJobById?.id}`,
            fragment: gql`
              fragment UpdateAdhocJob on AdhocJob {
                status
              }
            `,
            data: {
              status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
            },
          });
        },
        onCompleted: () => {
          jobRefetch();
        },
      });
    },
    [jobRefetch, updateJob],
  );

  const handleStartJob = useCallback(
    async ({ job: currentJob }: THandleStartJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const input = transformStartJobData();

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(job, true).short} ${t(
                'jobs:started',
              )}`,
              timeout: 5000,
            });

            jobRefetch();

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem starting this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, job, jobRefetch, t, updateJob],
  );

  const handleTakeJob = useCallback(
    async ({ job: currentJob }: THandleTakeJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const input = transformTakeJobData({
          user,
        });

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'jobs:has been assigned to you',
              )}`,
              timeout: 5000,
            });

            jobRefetch();

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem taking this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, jobRefetch, t, updateJob, user],
  );

  const handleTakeAndStartJob = useCallback(
    async ({ job: currentJob }: THandleTakeAndStartJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const input = transformTakeAndStartJobData({
          user,
        });

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'jobs:has been assigned to you and started',
              )}`,
              timeout: 5000,
            });

            jobRefetch();

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem taking and starting this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, jobRefetch, t, updateJob, user],
  );

  const handleCancelJob = useCallback(
    async ({ job: currentJob }: THandleCancelJob) => {
      try {
        const input = transformCancelJobData();

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            jobRefetch();

            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'common:has been cancelled',
              )}`,
              timeout: 5000,
            });

            if (typeof onClose === 'function') {
              onClose();
            }
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem cancelling this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, jobRefetch, onClose, t, updateJob],
  );

  const handlePauseJob = useCallback(
    async ({ job: currentJob }: THandlePauseJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const input = transformPauseJobData();

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'jobs:has been paused',
              )}`,
              timeout: 5000,
            });

            if (typeof onClose === 'function') {
              onClose();
            }

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem pausing this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, onClose, t, updateJob],
  );

  const handleCompleteJob = useCallback(
    async ({ job: currentJob }: THandleCompleteJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const completableTasks =
          currentJob?.checklists &&
          currentJob?.checklists[0] &&
          currentJob?.checklists[0][
            (currentJob?.jobTemplate
              ? 'tasks'
              : 'checklistTasks') as keyof (typeof currentJob.checklists)[0]
          ]?.filter(
            (task: { taskType: string }) =>
              CHECKLIST_TASK_TYPE_CONFIG[
                task.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
              ]?.fulfillment?.fulfillable,
          );

        const checklistsConfig = {
          requiredTasks: completableTasks?.filter(
            (chklst: { required: boolean }) => chklst.required,
          ),
        };

        const firstRequiredTaskNotChecked =
          checklistsConfig.requiredTasks?.find(
            (requiredTask: { taskType: string }) =>
              !CHECKLIST_TASK_TYPE_CONFIG[
                requiredTask.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
              ]?.fulfillment?.isFulfilled(requiredTask),
          );

        if (firstRequiredTaskNotChecked) {
          setTimeout(() =>
            document
              .querySelector(`#item-${firstRequiredTaskNotChecked?.id}`)
              ?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              }),
          );

          globalSnack({
            message: `${t('jobs:There was a problem completing this job')}`,
            timeout: 5000,
          });

          toggleSubmitted(true);

          return;
        }

        const input = transformCompleteJobData();

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'jobs:has been marked as complete',
              )}`,
              timeout: 5000,
            });

            jobRefetch();

            if (typeof onClose === 'function') {
              onClose();
            }

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem completing this job'),
          error: true,
          timeout: 5000,
        });

        if (typeof onClose === 'function') {
          onClose();
        }
      }
    },
    [globalSnack, jobRefetch, onClose, t, toggleSubmitted, updateJob],
  );


  const getFailedTasksQty = async (id = job?.id) => {
    const { data } = await jobInspectionEvaluate({ jobID: Number(id) });
    if (data?.inspectionEvaluate?.checklists) {
      const checkliststTasks = data?.inspectionEvaluate?.checklists?.flatMap(({ tasks }: any) => tasks) ?? [];
      return checkliststTasks.length;
    }
    return 0;
  };

  const handleCompleteFailJob = async ({ notes, skipCheck, assigneeId }: { notes: string, skipCheck: boolean, assigneeId: number  }) => {
    try {
      await jobInspectionFailed({
        variables: {
          jobID: Number(job?.id),
          notes,
          skipCheck,
          assigneeId: Number(assigneeId),
        },
      });
      globalSnack({
        message: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:marked as Failed',
        )}`,
        timeout: 5000,
      });

      jobRefetch();
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      console.log('handleCompleteFailJob error:', error);
      globalSnack({
        message: t('jobs:There was a problem marking this job as failed'),
        error: true,
        timeout: 5000,
      });
    }
    };

  const handleCompletePassJob = async (bypass: boolean) => {
    const failedTasks = bypass ? 0 : await getFailedTasksQty();

    if (failedTasks > 0) {
      setShowPassWithFailedConfirmation(true);
      setFailedTasksQty(failedTasks);
      return;
    }

    try {
      await jobInspectionSuccess({
        variables: {
          jobID: Number(job?.id),
        },
      });
      globalSnack({
        message: `${t('jobs:Job')} ${getName(job, true).short} ${t(
          'jobs:marked as Complete & Pass',
        )}`,
        timeout: 5000,
      });

      jobRefetch();
      if (typeof onClose === 'function') {
        onClose();
      }
    } catch (error) {
      console.log('jobInspectionSuccess error:', error);
      globalSnack({
        message: t('jobs:There was a problem completing and passing this job'),
        error: true,
        timeout: 5000,
      });
    }
    setFailedTasksQty(0);
  };

  const handleReopenJob = useCallback(
    async ({ job: currentJob }: THandleReopenJob) => {
      try {
        if (currentJob.checklists !== null) {
          GoogleAnalyticsClient.event(GA_EVENTS.startJobWithChecklist);
        }

        const input = transformReopenJobData();

        await updateJob({
          variables: { id: currentJob?.id, input },
          update: (
            cache,
            {
              data,
            }: {
              data?: {
                updateJobById: { id: string | number; status: string };
              };
            },
          ) => {
            const updateJobById = data?.updateJobById;

            cache.writeFragment({
              id: `AdhocJob:${updateJobById?.id}`,
              fragment: gql`
                fragment UpdateAdhocJob on AdhocJob {
                  status
                }
              `,
              data: {
                status: updateJobById?.status.split('_').join(' '), // Doing this initially since the pure status on the API side is space separated
              },
            });
          },
          onCompleted: () => {
            globalSnack({
              message: `${t('jobs:Job')} ${getName(currentJob, true).short} ${t(
                'jobs:has been reopened',
              )}`,
              timeout: 5000,
            });

            jobRefetch();

            GoogleAnalyticsClient.event(GA_EVENTS.startJob);
          },
        });
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('jobs:There was a problem starting this job'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [globalSnack, jobRefetch, t, updateJob],
  );

  const handleRemoveFile = useCallback(
    async ({ file }: THandleRemoveFile) => {
      const newJob = job?.attachments && {
        attachments: job.attachments.filter(
          (attachmentItem) => attachmentItem !== file,
        ),
      };

      if (newJob) {
        setAttachmentLoading(true);

        await handleUpdateJob({
          job: job as GetJobByIdQuery['job'],
          newJob,
        });

        setAttachmentLoading(false);
      }
    },
    [handleUpdateJob, job],
  );

  const handleAddFile = useCallback(
    async ({ files }: THandleAddFile) => {
      const newJob = {
        attachments: files,
      };

      if (newJob) {
        await handleUpdateJob({
          job: job as GetJobByIdQuery['job'],
          newJob,
        });
      }
    },
    [handleUpdateJob, job],
  );

  const handleAsset = useCallback(
    ({ asset }: THandleAsset) => {
      if (asset) {
        setQuery({
          assetId: asset,
        });
      }
    },
    [setQuery],
  );

  const handleLocation = useCallback(
    ({ location }: THandleLocation) => {
      if (query.locationId && onClose && typeof onClose === 'function') {
        setQuery({
          locationId: '',
        });

        onClose();

        setTimeout(() => {
          setQuery({
            locationId: location,
          });
        }, 500);

        return;
      }

      if (location) {
        setQuery({
          locationId: location,
        });
      }
    },
    [onClose, query.locationId, setQuery],
  );

  const getConstants = useCallback(
    () => ({
      JOB_TYPES,
    }),
    [JOB_TYPES],
  );

  useEffect(() => {
    if (
      job.locations &&
      job?.locations?.length === 1 &&
      job.locations[0]?.id &&
      job.locations[0]?.type === 'Room'
    ) {
      getLocationData();
    }
  }, [getLocationData, job?.locations]);

  useEffect(() => {
    if (query.edit && !canEdit) {
      setQuery({ edit: '' });
    }
  }, [canEdit, query, setQuery]);

  useEffect(() => {
    if (job?.metadata?.publicAttributes?.asset?.id && !isHousekeeping) {
      getAssetData({
        variables: {
          assetId: job?.metadata?.publicAttributes?.asset?.id,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      });
    }
  }, [
    getAssetData,
    isHousekeeping,
    job?.metadata?.publicAttributes?.asset?.id,
  ]);

  useEffect(() => {
    const locationIDs =
      job?.locations &&
      job?.locations
        .filter((location: { type: string }) => location.type === 'Room')
        .map((location: { id: string }) => location?.id);

    if (locationIDs?.length && canProduct([PRODUCT_ACCESS.pms_Data])) {
      getRoomsData({
        variables: {
          id: locationIDs,
        },
      });
    }
  }, [canProduct, getRoomsData, job?.locations]);

  const loading = jobLoading || updateJobLoading;

  const cardData = getCardData() as TJobCardData;

  return {
    job,
    audit,
    asset: assetData?.asset,
    roomsData: roomsData?.roomsById,
    locationData: locationData?.GetLocationById,
    cardData,
    getCardData,
    roomLoading,
    roomRefetch,
    timezone,
    loading,
    attachmentLoading,
    handleEdit,
    handleStartJob,
    handleCancelJob,
    handleTakeJob,
    handleTakeAndStartJob,
    handlePauseJob,
    handleCompleteJob,
    handleReopenJob,
    handleAnalytics,
    handleUpdateJob,
    handleAddFile,
    handleRemoveFile,
    handleAsset,
    handleLocation,
    handleCompleteFailJob,
    getFailedTasksQty,
    handleCompletePassJob,
    showFailConfirmation,
    setShowFailConfirmation,
    failedTasksQty,
    showPassWithFailedConfirmation,
    setShowPassWithFailedConfirmation,
    getAssetData,
    getLocationData,
    getConstants,
    updateJob,
    jobRefetch,
    updateChecklistTask,
    showCancelJobConfirmation,
    setShowCancelJobConfirmation,
    canEdit,
    isHousekeeping,
    isProject,
    isRepeating,
    isService,
    can,
    canProduct,
    generateInPropertyTime,
    setActiveTab,
    activeTab,
  };
}
