import React from 'react';
import styled from 'styled-components/macro';
import Field from './Field';
import SecondaryButton from '../buttons/SecondaryButton';

const Wrap = styled.div`
  position: relative;
  & > button {
    position: absolute;
    right: 0.5rem;
    bottom: 1.1rem;
    min-width: 6rem;
    height: 3rem;
    ${(props) => props.theme.fonts.sizes.small};
  }
  & textarea {
    padding-right: 7rem;
  }
  & > div:first-child {
    margin-top: 0;
  }
`;

const InputGroup = React.forwardRef((props, ref) => {
  const { buttonLabel, sendHandler, disabled, ...rest } = props;
  return (
    <Wrap>
      <Field {...rest} ref={ref} />
      <SecondaryButton disabled={disabled} onClick={sendHandler}>
        {buttonLabel}
      </SecondaryButton>
    </Wrap>
  );
});

export default InputGroup;
