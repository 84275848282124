import { Operator, Notification } from '@optii/escalations/types/notifications';

function temperatureResponse(responseData: Notification) {
    const normalizedResponse = { ...responseData };
    if (
        normalizedResponse?.userEventActionRules[0]?.event?.filters[0]?.DataTask?.values
      ) {
        const [min = '0', max = '0'] = normalizedResponse.userEventActionRules[0].event.filters[0].DataTask.values.sort((a, b) => a-b);
        normalizedResponse.userEventActionRules[0].event.filters[0].DataTemperatureMin = {
          property: 'DataTemperatureValue',
          operator: Operator.OutsideRange,
          values: [min],
        };
        normalizedResponse.userEventActionRules[0].event.filters[0].DataTemperatureMax = {
          property: 'DataTemperatureValue',
          operator: Operator.OutsideRange,
          values: [max],
        };
      }

      return normalizedResponse;
}

export default temperatureResponse;