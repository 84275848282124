import isString from 'lodash/isString';

export const sort = (a, b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
};

export const sortByDisplayName = (data) => {
  function dispSort(a, b) {
    const dispA = a.displayName.toLowerCase();
    const dispB = b.displayName.toLowerCase();

    return sort(dispA, dispB);
  }
  return data.sort(dispSort);
};

export const sortByParam = (param, data) => {
  function paramSort(a, b) {
    const paramA =
      a[param] && isString(a[param]) ? a[param].toLowerCase() : a[param];
    const paramB =
      b[param] && isString(b[param]) ? b[param].toLowerCase() : b[param];

    return sort(paramA, paramB);
  }
  return data.sort(paramSort);
};

export const sortByTwoParams = (paramA1, paramB1, paramA2, paramB2) =>
  sort(paramA1, paramB1) || sort(paramA2, paramB2);

const normalizeName = (name) => {
  if (!name) return '';
  return name.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const sortByName = (a, b) => {
  if (b.userName === 'autoassign') return 0;
  const aFullName = `${normalizeName(a.userFirstName)}${normalizeName(a.userLastName)}}`.toLowerCase();
  const bFullName = `${normalizeName(b.userFirstName)}${normalizeName(b.userLastName)}}`.toLowerCase();
  return sort(aFullName, bFullName);
};

export const sortLocation = (a, b) => {
  const aName = normalizeName(a.shortDisplayName).toLowerCase();
  const bName = normalizeName(b.shortDisplayName).toLowerCase();
  return sort(aName, bName);
}

export const sortAssetTypes = (a, b) => {
  const aName = normalizeName(a.displayName).toLowerCase();
  const bName = normalizeName(b.displayName).toLowerCase();
  return sort(aName, bName);
}