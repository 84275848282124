import { ConfigProvider, Input, ThemeConfig } from 'antd';
import type { TextAreaProps } from 'antd/es/input';

const { TextArea: TextAreaComponent } = Input;

type Props = TextAreaProps & {
  theme?: ThemeConfig;
};

export function TextArea(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <TextAreaComponent {...rest} />
    </ConfigProvider>
  );
}
