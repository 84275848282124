export type TFormatUserDisplayName = {
  employeeId?: number;
  userId: string | number;
  userFirstName?: string;
  firstName?: string;
  employeeUserFirstName?: string;
  userLastName?: string;
  lastName?: string;
  employeeUserLastName?: string;
  userName?: string;
  employeeUserName?: string;
};

// I'll just use this little space to rant about our messed up naming conventions
// shouldn't we be consistent when referencing the same type of data?
export function formatUserDisplayName(current: TFormatUserDisplayName) {
  if (!current) return '';
  if (
    current.employeeId === -1 &&
    (current.userName === '' || current.employeeUserName === '')
  )
    return 'Optii';
  const firstName =
    current.userFirstName || current.firstName || current.employeeUserFirstName;
  const lastName =
    current.userLastName || current.lastName || current.employeeUserLastName;
  const userName = current.userName || current.employeeUserName || 'Unknown';
  return firstName || lastName
    ? `${firstName || ''} ${lastName || ''}`
    : userName;
}
