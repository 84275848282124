import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import { useAccess, JobDetailsContext, useProductAccess } from '@optii/shared';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { GET_JOB_BY_ID } from '@optii/shared/queries/job/getJobById';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import { PRODUCT_ACCESS } from '@optii/shared/constants/productAccess';
import { getJobTypes } from '@optii/jobs';

import Icons from 'blocks/Icons';
import Priority from 'components/shared/Priority';
import Loading from 'components/shared/Loading';
import { Status, Top } from 'components/shared/Job/ViewComponents';
import {
  UPDATE_JOB,
  GET_ASSET_BY_ID,
  UPDATE_JOB_META_DATA,
  GET_QUEUED_JOB_BY_ID,
  UPDATE_CHECKLIST_TASK,
  GET_EMPLOYEE_LIST,
} from 'queries';
import { getName, getStatus } from 'utils/formatters/jobs';
import JOB_METADATA from 'utils/constants/jobMetadata';
import { LIST_FETCH_POLICIES } from 'utils/constants';
import { ReactComponent as ProjectJobIcon } from 'images/svg/ico-project-job.svg';
import { ReactComponent as RepeatingJobIcon } from 'images/svg/ico-repeating-job.svg';
import JobModalV2 from '../JobModalV2';
import ViewJob from './ViewJob';
import { formatUserDisplayName } from 'utils/formatters/user';
import { initGQLData } from 'utils/initGQLData';
import { sortByName } from 'utils/sort';

const Id = styled.div`
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.charcoal05};
  @media (max-width: ${(props) => props.theme.breakpoints.miniLg}) {
    font-size: 22px;
  }
`;
const EditIcon = styled(Icons.Edit)`
  margin-right: 1.7rem;
`;

const ProjectIcon = styled(ProjectJobIcon)`
  margin-left: 1rem;
  width: 2rem;
  height: auto;
`;
const RepeatingIcon = styled(RepeatingJobIcon)`
  margin-left: 1rem;
  width: 2.4rem;
  height: 2rem;
`;
const StyledPriority = styled(Priority)`
  @media (max-width: ${(props) => props.theme.breakpoints.miniLg}) {
    width: 25px;
    height: 25px;
  }
`;

// Gets the job and controls edit/view state
function JobDetails({
  close,
  subModal,
  disableBackdrop = false,
  newJob,
  isJobFormOpened,
  mode,
}) {
  const [query, setQuery] = useQueryParams({
    edit: StringParam,
    view: StringParam,
    openJob: StringParam,
    project: StringParam,
    projectCycle: StringParam,
    backToJob: NumberParam,
  });
  const history = useHistory();
  const [submitted, toggleSubmitted] = useState(false);
  const [job, setJob] = useState();
  const [isUpdateChecklistTaskLoading, toggleIsUpdateChecklistTaskLoading] =
    useState(false);
  const [asset, setAsset] = useState(null);
  const { can } = useAccess();
  const { canProduct } = useProductAccess();
  const { t } = useTranslation(['common', 'jobs']);

  const AUTO_ASSIGN_OPTION = [
    {
      id: -88, // Anything negative will work
      displayName: t('jobs:Auto Assign'),
      userId: -88,
      userName: 'autoassign',
      userFirstName: t('jobs:Auto'),
      userLastName: t('jobs:Assign'),
    },
  ];

  const [backToJob, setBackToJob] = useState(false);

  const canEdit = can(PERMISSIONS.jobs.all);
  const canAddEditHousekeepingJobs = can(PERMISSIONS.house_keeping.add_edit);
  const canProductHousekeeping = canProduct([PRODUCT_ACCESS.house_keeping]);
  const canManageRepeatingJobs = can(PERMISSIONS.settings.repeatingJobs.view);

  useEffect(
    (_) => {
      if (query.edit && !canEdit) {
        setQuery({ edit: '' });
      }
    },
    [query],
  );
  const updateJob = useMutation(UPDATE_JOB);
  const updateJobMetadata = useMutation(UPDATE_JOB_META_DATA);

  const [
    getJobById,
    { data: jobData, loading: jobLoading, refetch: jobRefetch },
  ] = useLazyQuery(GET_JOB_BY_ID, {
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    variables: {
      id: query.openJob,
      startedAt:
        process.env.NODE_ENV === 'test'
          ? '2024-07-24 15:53:29.601000'
          : '2024-08-06 20:27:31.008847',
    },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      const myAddedJobs = JSON.parse(localStorage.getItem('myAddedJobs') ?? '[]');
      const myJob = myAddedJobs.find(({id}) => id == query.openJob);
      if (myJob) {
        setJobData({job:myJob}, newJob);
      }
    }
  });

  const updateCheklistTask = useMutation(UPDATE_CHECKLIST_TASK, {
    context: { _instance: 'node' },
    awaitRefetchQueries: true,
    onCompleted: () => {
      queuedJobRequest ? queuedJobRefetch() : jobRefetch();
    },
    refetchQueries: [
      {
        query: GET_JOB_BY_ID,
        variables: {
          id: query.openJob,
          startedAt:
            process.env.NODE_ENV === 'test'
              ? '2024-07-24 15:53:29.601000'
              : '2024-08-06 20:27:31.008847',
        },
      },
    ],
  });

  const { data: employeeData } = useQuery(GET_EMPLOYEE_LIST, {
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    variables: {
      status: 'active',
    },
  });

  const employees = AUTO_ASSIGN_OPTION.concat(initGQLData(employeeData))
    .sort(sortByName)
    .map((emp) => ({
      ...emp,
      id: emp.userId,
      displayName: formatUserDisplayName(emp),
    }));

  const [
    getQueuedJobById,
    {
      data: queuedJobData,
      loading: queuedJobLoading,
      refetch: queuedJobRefetch,
    },
  ] = useLazyQuery(GET_QUEUED_JOB_BY_ID, {
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    variables: {
      projectId: query.project,
      projectCycleId: query.projectCycle,
      jobCardHash: query.openJob,
    },
    notifyOnNetworkStatusChange: true,
    context: { _instance: 'node' },
  });

  const [getAssetData, { data: assetData }] = useLazyQuery(GET_ASSET_BY_ID, {
    nextFetchPolicy: 'cache-first',
    fetchPolicy: 'cache-first',
    context: { _instance: 'node' },
  });

  const queuedJobRequest =
    (query.project && query.project !== '') ||
    (query.projectCycle && query.projectCycle !== '');

  useEffect(() => {
    queuedJobRequest ? getQueuedJobById() : getJobById();
  }, []);

  useEffect(() => {
    if (assetData) {
      setAsset(assetData.asset);
    }
  }, [assetData]);

  const JOB_TYPES = getJobTypes({ t });

  const isHousekeepingJob = job?.type === JOB_TYPES.housekeeping.value;

  const updateJobData = () => {
    if (queuedJobRequest) {
      queuedJobData && setJobData(queuedJobData, newJob);
    } else {
      jobData && setJobData(jobData, newJob);
    }
  };

  function setJobData(data, newJob) {
    setJob(data.job);
    if (newJob) {
      setJob(newJob);
    }
    if (data.job.metadata) {
      getAssetData({
        variables: {
          assetId: data.job.metadata?.publicAttributes?.asset?.id,
        },
        ...LIST_FETCH_POLICIES,
      });
    }
  }

  function auditData() {
    if (queuedJobRequest) {
      return queuedJobData && queuedJobData.audit;
    }

    return jobData && jobData.audit;
  }

  useEffect(
    (_) => {
      updateJobData();
    },
    [jobData, queuedJobData],
  );

  function handleAnalytics(editting) {
    if (editting) {
      GoogleAnalyticsClient.event(GA_EVENTS.editJobModalCancel);
    } else {
      GoogleAnalyticsClient.event(GA_EVENTS.editJobModal);
    }
  }

  const openModal =
    job &&
    job?.type !== 'housekeeping' &&
    job?.type !== 'internal' &&
    job?.type !== 'correction' &&
    job?.type !== 'guest';

  return openModal ? (
    <JobModalV2
      disableBackdrop={disableBackdrop}
      subModal={subModal}
      modalName={queuedJobRequest ? 'queued-job-modal' : 'job-modal'}
      queuedJobRequest={queuedJobRequest}
      close={close}
      isEdit={query.edit}
    >
      {((_) => {
        if (jobLoading) {
          return <Loading loading />;
        }
        return (
          <JobDetailsContext.Provider
            value={{
              job,
              setJob,
              setAsset,
              setBackToJob,
              refetch: queuedJobRequest ? queuedJobRefetch : jobRefetch,
              loading: queuedJobRequest ? queuedJobLoading : jobLoading,
              audit: auditData(),
              close,
              updateJob,
              updateJobMetadata,
              updateCheklistTask,
              submitted,
              employees,
              toggleSubmitted: (_submitted) => toggleSubmitted(_submitted),
              isUpdateChecklistTaskLoading,
              isHousekeepingJob,
              toggleIsUpdateChecklistTaskLoading: (
                _isUpdateChecklistTaskLoading,
              ) =>
                toggleIsUpdateChecklistTaskLoading(
                  _isUpdateChecklistTaskLoading,
                ),
              asset,
            }}
          >
            <Top>
              {backToJob && query.backToJob && (
                <Icons.Arrow
                  style={{ marginRight: '1rem' }}
                  modifiers={['left']}
                  onClick={() => {
                    setBackToJob(() => false);
                    history.push({
                      pathname: '/jobs',
                      search: `?openJob=${query.backToJob}`,
                    });
                  }}
                />
              )}
              <StyledPriority job={job} />

              <Id>{!queuedJobRequest && getName(job, true).short}</Id>
              <Status data-testid="job-status" className={getStatus(job).id}>
                {getStatus(job).displayName}
              </Status>
              {job?.metadata?.source === JOB_METADATA.project && (
                <ProjectIcon />
              )}
              {job?.metadata?.source === JOB_METADATA.repeating && (
                <RepeatingIcon />
              )}
              {((canEdit && !queuedJobRequest && !isHousekeepingJob) ||
                (isHousekeepingJob &&
                  canAddEditHousekeepingJobs &&
                  canProductHousekeeping)) && (
                <EditIcon
                  onClick={(_) => {
                    setQuery({ edit: true });
                    handleAnalytics(query.edit);
                  }}
                  modifiers={['right', 'medium', 'largeMobile']}
                  active={query.edit}
                />
              )}
              <Icons.Exit
                onClick={close}
                modifiers={[
                  (!canEdit ||
                    queuedJobRequest ||
                    (canEdit &&
                      isHousekeepingJob &&
                      (!canAddEditHousekeepingJobs ||
                        !canProductHousekeeping))) &&
                    'right',
                  'largeMobile',
                ]}
              />
            </Top>
            <ViewJob
              queuedJobRequest={queuedJobRequest}
              canEdit={canEdit}
              canManageRepeatingJobs={canManageRepeatingJobs}
              audit={auditData()}
            />
          </JobDetailsContext.Provider>
        );
      })()}
    </JobModalV2>
  ) : (
    <JobDetailsContext.Provider
      value={{
        job,
        setJob,
        setAsset,
        setBackToJob,
        refetch: queuedJobRequest ? queuedJobRefetch : jobRefetch,
        loading: queuedJobRequest ? queuedJobLoading : jobLoading,
        audit: auditData(),
        close,
        updateJob,
        updateJobMetadata,
        employees,
        updateCheklistTask,
        submitted,
        toggleSubmitted: (_submitted) => toggleSubmitted(_submitted),
        isUpdateChecklistTaskLoading,
        isHousekeepingJob,
        toggleIsUpdateChecklistTaskLoading: (_isUpdateChecklistTaskLoading) =>
          toggleIsUpdateChecklistTaskLoading(_isUpdateChecklistTaskLoading),
        asset,
      }}
    >
      {job && (
        <ViewJob
          mode={mode}
          disableBackdrop={disableBackdrop}
          isJobFormOpened={isJobFormOpened}
          close={close}
          queuedJobRequest={queuedJobRequest}
          canEdit={canEdit}
          canManageRepeatingJobs={canManageRepeatingJobs}
          audit={auditData()}
        />
      )}
    </JobDetailsContext.Provider>
  );
}
export default JobDetails;
