import styled from 'styled-components/macro';

const Container = styled.section`
  font-size: 1.6rem;
  cursor: default;
  .react-select__value-container & {
    padding: ${(props) => (props.hasNote ? '1rem 1.5rem 1rem 0' : '0')};
  }
  [readonly] & {
    padding: 1.32rem;
  }
  h1 {
    color: ${(props) => props.theme.colors.charcoal};
    display: flex;
    justify-content: flex-start;
    font-size: 1.6rem;
    font-weight: 100;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0.8rem;
    margin: ${(props) => (props.hasNote ? 'inherit' : '0')};
    [readonly] & {
      color: ${(props) => props.theme.colors.charcoal05};
    }
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 100;

    .react-select__value-container &,
    .react-select__option--is-selected &,
    .react-select__option--is-focused & {
      font-weight: ${(props) => (props.hasNote ? '400' : '100')};
    }
  }
  h2 {
    color: ${(props) => props.theme.colors.charcoal05};
    font-size: 1.6rem;
    font-weight: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const AssetType = styled.span`
  margin-left: 0.5rem;
`;

function LocationAssetType({ location, assetType, note, ...props }) {
  return (
    <Container {...props} hasNote={note}>
      <h1>
        <span>{location}</span>
        <AssetType>{assetType}</AssetType>
      </h1>
      {note && <h2>{note}</h2>}
    </Container>
  );
}

export function customGetOptionLabel(option) {
  return (
    <LocationAssetType
      assetType={option.assetType.displayName}
      location={option.location.longDisplayName}
      note={option.showNotes ? option.notes : null}
    />
  );
}

export function getAssetListWithNotes(assetList = []) {
  const assetTypes = {};
  let hasAssetsWithTheSameAssetType = false;
  assetList.forEach((asset, index) => {
    const assetTypeId = asset.assetType.id;
    assetTypes[assetTypeId] = assetTypes[assetTypeId] || { assetsIndex: [] };
    assetTypes[assetTypeId].assetsIndex.push(index);
    if (assetTypes[assetTypeId].assetsIndex.length > 1) {
      hasAssetsWithTheSameAssetType = true;
    }
  });
  if (!hasAssetsWithTheSameAssetType) return assetList;

  Object.keys(assetTypes).forEach((assetTypeId) => {
    assetTypes[assetTypeId].assetsIndex.length > 1 &&
      assetTypes[assetTypeId].assetsIndex.forEach((index) => {
        assetList[index].showNotes = true;
      });
  });
  return assetList;
}

export function getAssetWithNotes(asset, assetList) {
  if (!asset || !assetList) return asset;

  const [assetWithNotes = asset] = getAssetListWithNotes(assetList).filter(
    ({ id }) => id === asset.id,
  );
  return assetWithNotes;
}
