import i18next from 'i18next';
import {
  ActionType,
  EventContext,
  SelectOption,
} from '@optii/escalations/types/notifications';

export const DEFAULT_PAGE_SIZE = 10;

export const OFFSET_DURATION = [
  {
    value: '00:00:00',
    label: i18next.t('common:0 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:01:00',
    label: i18next.t('common:1 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:02:00',
    label: i18next.t('common:2 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:05:00',
    label: i18next.t('common:5 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:10:00',
    label: i18next.t('common:10 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:15:00',
    label: i18next.t('common:15 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:30:00',
    label: i18next.t('common:30 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '00:45:00',
    label: i18next.t('common:45 mins'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '01:00:00',
    label: i18next.t('common:1 hr'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '01:30:00',
    label: i18next.t('common:1.5 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '02:00:00',
    label: i18next.t('common:2 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '04:00:00',
    label: i18next.t('common:4 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '06:00:00',
    label: i18next.t('common:6 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '08:00:00',
    label: i18next.t('common:8 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '10:00:00',
    label: i18next.t('common:10 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '12:00:00',
    label: i18next.t('common:12 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '1.00:00:00',
    label: i18next.t('common:24 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '2.00:00:00',
    label: i18next.t('common:48 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '3.00:00:00',
    label: i18next.t('common:72 hrs'),
    context: [EventContext.Job, EventContext.ProjectCycle],
  },
  {
    value: '7.00:00:00',
    label: i18next.t('common:7 days'),
    context: [EventContext.ProjectCycle],
  },
  {
    value: '14.00:00:00',
    label: i18next.t('common:14 days'),
    context: [EventContext.ProjectCycle],
  },
  {
    value: '21.00:00:00',
    label: i18next.t('common:21 days'),
    context: [EventContext.ProjectCycle],
  },
  {
    value: '30.00:00:00',
    label: i18next.t('common:30 days'),
    context: [EventContext.ProjectCycle],
  },
];

export const OFFSET_TYPE = [
  {
    value: 'Before',
    label: i18next.t('common:before due date'),
    context: [EventContext.ProjectCycle],
  },
  {
    value: 'After',
    label: i18next.t('common:after due date'),
    context: [EventContext.ProjectCycle],
  },
  {
    value: 'Before',
    label: i18next.t('common:before due time'),
    context: [EventContext.Job],
  },
  {
    value: 'After',
    label: i18next.t('common:after due time'),
    context: [EventContext.Job],
  },
  {
    value: 'Status',
    label: i18next.t('common:after status change'),
    context: [EventContext.Job],
  },
  {
    value: 'Created',
    label: i18next.t('common:after creation'),
    context: [EventContext.Job],
  },
];

export const FORMATTED_ACTION_TYPE_TEXT = {
  [ActionType.SendEmail]: i18next.t('common:Email Notification'),
  [ActionType.SendNotification]: i18next.t('common:Push Notification'),
};

export const ACTION_TYPE_OPTIONS: SelectOption[] = Object.keys(ActionType).map(
  (actionType) => ({
    label: FORMATTED_ACTION_TYPE_TEXT[actionType as keyof typeof ActionType],
    value: actionType,
  }),
);
