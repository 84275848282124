import React, { useCallback, useState, memo } from 'react';
import { AutoComplete } from '@optii-solutions/ui-library';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_LOCATIONS_LIST } from 'queries';
import { TLocation, TQueryLocations } from './types';

type LocationOption = {
  label: string;
  value: string;
  shortDisplayName: string;
  displayName: string;
};

interface ILocationSearchProps {
  onSelectLocation: (location: TLocation) => void;
}
function LocationSearch({ onSelectLocation }: ILocationSearchProps) {
  const { t } = useTranslation(['common', 'floorplan', 'fields']);
  const [locations, updateLocations] = useState<TLocation[]>([]);
  const [locationSearch, updateLocationSearch] = useState<string>();

  const { loading } = useQuery<TQueryLocations>(GET_LOCATIONS_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: 'locationTypeName_ASC ,name_ASC, number_ASC',
    },
    onCompleted: (data) => {
      if (Array.isArray(data.page.edges)) {
        updateLocations(data.page.edges.map(({ node }) => node));
      }
    },
    skip: locations.length > 0,
  });

  const handleSearch = useCallback((searchedString: string) => {
    updateLocationSearch(searchedString);
  }, []);

  const handleSelect = useCallback(
    (locationId: string) => {
      const locationData = locations.find(({ id }) => locationId === id);
      if (locationData) {
        onSelectLocation(locationData);
        updateLocationSearch('');
      }
    },
    [locations, onSelectLocation],
  );

  const filterOption = useCallback((inputValue: string, option: unknown) => {
    const formattedOption = option as LocationOption;

    return (
      formattedOption!.label.toLowerCase().indexOf(inputValue.toLowerCase()) !==
        -1 ||
      formattedOption!.shortDisplayName
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) !== -1 ||
      formattedOption!.displayName
        .toLowerCase()
        .indexOf(inputValue.toLowerCase()) !== -1
    );
  }, []);

  return (
    <AutoComplete
      onSearch={handleSearch}
      onSelect={handleSelect}
      disabled={loading}
      filterOption={filterOption}
      options={locations.map((location) => ({
        label: location.longDisplayName,
        value: location.id,
        shortDisplayName: location.shortDisplayName,
        displayName: location.displayName,
      }))}
      value={locationSearch}
      placeholder={t('floorplan:Search Locations')}
    />
  );
}

export default memo(LocationSearch);
