export const JOB_STATUS = {
  deleted: "deleted",
  in_progress: "in_progress",
  new: "new",
  not_started: "not_started",
  pending: "pending",
  completed: "completed",
  archived: "archived",
  cancelled: "cancelled",
  on_hold: "on_hold",
  done: "done",
  unassigned: "unassigned",
  queued: "queued"
};
