import React, { ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import { LoadingContent, Spinner } from './styles';

interface LoadingProps {
  loading?: boolean;
  children?: ReactNode;
  className?: string;
  modifiers?: unknown;
}
const Loading: React.FC<LoadingProps> = ({
  loading,
  children,
  className,
  modifiers,
}) => {
  return (
    <>
      <Transition in={loading} timeout={500}>
        {(transState) => (
          <Spinner
            {...{ modifiers, className, transState }}
            data-testid="loading-spinner"
          >
            <div id="circularG" data-testid="loading">
              <div id="circularG_1" className="circularG" />
              <div id="circularG_2" className="circularG" />
              <div id="circularG_3" className="circularG" />
              <div id="circularG_4" className="circularG" />
              <div id="circularG_5" className="circularG" />
              <div id="circularG_6" className="circularG" />
              <div id="circularG_7" className="circularG" />
              <div id="circularG_8" className="circularG" />
            </div>
          </Spinner>
        )}
      </Transition>
      <Transition in={!loading} timeout={500}>
        {(transState) => (
          <LoadingContent {...{ transState }}>
            {!loading && children}
          </LoadingContent>
        )}
      </Transition>
    </>
  );
};

export default Loading;