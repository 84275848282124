import type { FormListFieldData } from '@optii-solutions/ui-library';
import { FormInstance } from 'antd';

import { EventContext } from '../../../../types/notifications';
import { JobAndProjectActionFormList } from './JobAndProjectActionFormList';
import { TaskActionFormList } from './TaskActionFormList';

type Props = {
  eventContext: EventContext;
  actionField: FormListFieldData;
  field: FormListFieldData;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  length: number;
  form: FormInstance;
  actionFields: FormListFieldData[];
};

export function FormList({ eventContext, actionField, field, remove, length, form }: Props) {
  const ACTION = {
    [EventContext.Job]: <JobAndProjectActionFormList eventContext={eventContext} actionField={actionField} field={field} remove={remove} length={length} form={form} />,
    [EventContext.ProjectCycle]: <JobAndProjectActionFormList eventContext={eventContext} actionField={actionField} field={field} remove={remove} length={length} form={form} />,
    [EventContext.TemperatureTask]: <TaskActionFormList eventContext={eventContext} actionField={actionField} field={field} remove={remove} length={length} form={form} />,
    [EventContext.NumericalTask]: <TaskActionFormList eventContext={eventContext} actionField={actionField} field={field} remove={remove} length={length} form={form} />,
  };

  return ACTION[eventContext as Exclude<EventContext, 'Project'>];
}
