import {
  Descriptions as AntDescriptions,
  ConfigProvider,
  DescriptionsProps,
  ThemeConfig,
  Typography,
} from 'antd';
import { ReactNode } from 'react';
import { DESCRIPTION_TEXT_THEME } from '../../utils/themes';
import { COLORS, FONTS } from '../../..';

type Props = DescriptionsProps & {
  theme?: ThemeConfig;
};

type DescriptionLabelProps = {
  children: ReactNode;
  type?: 'label' | 'text' | 'link';
  labelSize?: number;
  emptyText?: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  'data-testid'?: string;
};

export function DescriptionText(props: DescriptionLabelProps) {
  const { children, type, onClick, emptyText, labelSize } = props;

  return (
    <ConfigProvider theme={DESCRIPTION_TEXT_THEME}>
      {type === 'link' ? (
        <Typography.Link
          style={{ fontWeight: 300, fontSize: FONTS.medium.size }}
          onClick={onClick}
          data-testid={props['data-testid']}
        >
          {children}
        </Typography.Link>
      ) : (
        <Typography.Text
          data-testid={props['data-testid']}
          style={{
            fontWeight: type === 'label' ? 500 : 300,
            fontSize:
              type === 'label'
                ? labelSize || FONTS.xSmall.size
                : FONTS.medium.size,
            color: !children ? COLORS.neutral[7] : '',
          }}
        >
          {children || emptyText}
        </Typography.Text>
      )}
    </ConfigProvider>
  );
}

export function Descriptions(props: Props) {
  const { theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntDescriptions {...rest} />
    </ConfigProvider>
  );
}
