import styled from 'styled-components/macro';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import useScrollLock from 'utils/useScrollLock';
import Backdrop from 'components/shared/Backdrop';

let modalRoot = document.getElementById('super-modal-root');
// for tests, where there is no root
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'super-modal-root');
  document.body.appendChild(modalRoot);
}
const Pane = styled.div`
  ${(props) => props.hidden && 'display: none;'}
  ${(props) => props.theme.zIndex.superModal}
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48rem;
  canvas {
    width: 100%;
    height: auto;
  }
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const Modal = (props) => {
  const modalEl = useRef(null);
  const root = modalRoot;

  useScrollLock(modalEl.current);
  const localHandleClickBackdrop = (e) => {
    e.stopPropagation();
  };
  const handleClick = props.handleClickBackdrop || localHandleClickBackdrop;

  return ReactDOM.createPortal(
    <div>
      <Pane className="modal" {...props} ref={modalEl}>
        {props.children}
      </Pane>
      <Backdrop
        solid={props.solidBack}
        onClick={handleClick}
        superModal
        hidden={props.hidden}
      />
    </div>,
    root,
  );
};
export default Modal;
