import { useCallback, useEffect, useState } from 'react';
import { Client } from '@twilio/conversations';
import { OPTII_AUTHOR_ID } from '../utils/message';

export function useOnlineStatus(client: Client | null, id: string) {
  const [status, setStatus] = useState(false);

  const getUserData = useCallback(async () => {
    try {
      const userInfo = await client?.getUser(id);
      setStatus(userInfo?.isOnline || false);
    } catch (error) {
      setStatus(id === OPTII_AUTHOR_ID);
    }
  }, [client, id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return {
    status,
  };
}
