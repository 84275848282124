import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  spaceBetween: (_) => `
    justify-content: space-between;
  `,
  smallTopMargin: (_) => `
    margin-top: 1.5rem;
  `,
};
const Footer = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: flex-end;
  ${applyStyleModifiers(MODIFIER_CONFIG)};

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 2.4rem 2.4rem 2.4rem;
  }
`;

export default Footer;
