// Extract the list of results
export const initGQLData = data => (
    (data &&
      data.page &&
      data.page.edges &&
      data.page.edges.map(el => el.node)) ||
    []
  );
// Extract pagingInfo/totalCount, optional extended, mostly for passing page sizes
export const getPageInfo = (data, extend = {}) => {
  let result = {};
  // putting total count in, just to simplify matters
  if (data && data.page && data.page.totalCount) {
    result.totalCount = data.page.totalCount;
  }
  if (data && data.page && data.page.pageInfo) {
    result = { ...result, ...data.page.pageInfo };
  }
  return { ...result, ...extend };
};
// filter data by IDs
export const dataById = (data, queryIDs, type) => {
  const ids = queryIDs && queryIDs[type];
  return ids ? data && data.filter(el => ids.includes(el.id)) : [];
};