import styled from 'styled-components/macro';
import React from 'react';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  small: (_) => `
    min-width: 12rem;
  `,
  smallest: (_) => `
    min-width: 8.1rem;
    height: 3rem;
  `,
  medium: () => `
    min-width: 17.25rem;
  `,
};

const Button = styled.button`
  border-radius: 6px;
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.medium}
  min-width: 12.3rem;
  height: 4.4rem;
  cursor: pointer;
  border: none;
  :disabled {
    background-color: ${(props) => props.theme.colors.charcoal02};
    color: ${(props) => props.theme.colors.charcoal05};
    border: none;
    box-shadow: none;
    cursor: default;
    :hover {
      box-shadow: none;
    }
  }
  :active:disabled {
    background-color: ${(props) => props.theme.colors.charcoal02};
    color: ${(props) => props.theme.colors.charcoal05};
    border: none;
    box-shadow: none;
    cursor: default;
  }

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;
function TertiaryButton(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}
export default TertiaryButton;
