import {
  Button,
  COLORS,
  Col,
  ConfigProvider,
  Drawer,
  FONTS,
  Flex,
  Form,
  FormItem,
  FormList,
  HeaderActions,
  Input,
  Row,
  SPACING,
  Select,
  Typography,
  useForm,
  FormListFieldData,
} from '@optii-solutions/ui-library';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Session, Confirmation } from '@optii/shared';
import { omitDeep } from '@apollo/client/utilities';
import {
  EventContext,
  EventContextSelect,
  Notification,
  Operator,
  SelectOption,
} from '../../types/notifications';
import { Criteria } from './criteria';
import Actions, { RenderIf } from './actions';
import { STYLES } from './styles';
import {
  NotificationGroupsDocument,
  NotificationInput,
  useCreateNotificationGroupMutation,
  useNotificationGroupByIdQuery,
  useNotificationGroupsLazyQuery,
  useUpdateNotificationGroupMutation,
  useDeleteNotificationGroupMutation,
} from '../../api/notifications';
import submit, { SUBMIT_ADD_MESSAGES, SUBMIT_EDIT_MESSAGES } from './submit';
import { FormSkeleton } from './FormSkeleton';
import normalizeResponse from './response';

type SectionProps = {
  title: string;
  subtitle: string;
};

type DrawerTitleProps = {
  onClose: () => void;
  t: TFunction<'rules', undefined>;
  id?: string | null;
};

type DrawerFooterProps = DrawerTitleProps & {
  onSubmit: () => void;
  onDelete: () => void;
  loading: boolean;
  eventContext: EventContext;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  notificationId?: string | null;
  setId?: (value: string | null | undefined) => void;
  initialContext?: EventContext;
  metadata: { [key: string]: unknown };
};

function Section({ title, subtitle }: SectionProps) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.neutral[9],
            colorTextHeading: COLORS.neutral[9],
          },
        },
      }}
    >
      <Typography.Title
        style={{
          letterSpacing: FONTS.h5.letterSpacing,
          fontFamily: 'Montserrat',
          marginTop: SPACING.NONE,
        }}
        level={5}
      >
        {title}
      </Typography.Title>
      <Typography.Text
        style={{
          letterSpacing: FONTS.large.letterSpacing,
        }}
      >
        {subtitle}
      </Typography.Text>
    </ConfigProvider>
  );
}

function DrawerTitle({ onClose, t, id }: DrawerTitleProps) {
  return (
    <Row align="middle" justify="space-between" wrap={false}>
      <Typography.Title
        level={3}
        style={{
          marginTop: SPACING.NONE,
          fontFamily: 'Montserrat',
        }}
      >
        {!id ? t('rules:Add Notification') : t('rules:Edit Notification')}
      </Typography.Title>
      <HeaderActions onClose={onClose} />
    </Row>
  );
}

function DrawerFooter({
  id,
  eventContext,
  onSubmit,
  onDelete,
  onClose,
  t,
  loading,
}: DrawerFooterProps) {
  const showClose =
    [
      EventContext.Job,
      EventContext.Project,
      EventContext.ProjectCycle,
    ].includes(eventContext) || !id;
  const showDelete =
    [EventContext.NumericalTask, EventContext.TemperatureTask].includes(eventContext) && id && !showClose;

  return (
    <Row
      align="middle"
      justify="end"
      wrap={false}
      gutter={SPACING.SIZE_MS}
      style={{
        paddingRight: SPACING.SIZE_XL,
      }}
    >
      <Col>
        {showClose && (
          <Button type="text" onClick={onClose}>
            {t('common:Cancel')}
          </Button>
        )}
        {showDelete && (
          <Button type="text" style={{ color: 'red' }} onClick={onDelete}>
            {t('common:Delete')}
          </Button>
        )}
      </Col>
      <Col>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={onSubmit}
        >
          {t('common:Save')}
        </Button>
      </Col>
    </Row>
  );
}

const EVENT_CONTEXT_OPTIONS: SelectOption[] = Object.keys(
  EventContextSelect,
).map((context) => ({
  label: context === EventContext.ProjectCycle ? 'Project' : context,
  value: EventContext[context as keyof typeof EventContext],
}));

function NotificationForm({
  open,
  setOpen,
  notificationId,
  setId,
  initialContext = EventContext.Job,
  metadata,
}: Props) {
  const { t } = useTranslation(['rules', 'common']);
  const { globalSnack } = useContext(Session);
  const [form] = useForm<Notification>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { data, loading } = useNotificationGroupByIdQuery({
    variables: {
      getNotificationGroupById: notificationId || '',
    },
    skip: !notificationId,
    context: {
      _instance: 'node',
    },
  });

  function onClose() {
    setOpen(false);
    if (typeof setId === 'function') {
      setId(undefined);
    }
    form.resetFields();
  }

  const [getNameCollision] = useNotificationGroupsLazyQuery({
    context: {
      _instance: 'node',
    },

    onError(error) {
      console.error(error);
    },
  });

  const getEventContext = useCallback(
    (notificationData: Notification | NotificationInput) => {
      const eventContext = (notificationData?.userEventActionRules[0]?.event
        ?.context ?? initialContext) as EventContext;

      return eventContext;
    },
    [initialContext],
  );

  const [createNotificationGroup, { loading: createNotificationGroupLoading }] =
    useCreateNotificationGroupMutation({
      context: {
        _instance: 'node',
      },
      onError(error) {
        console.error(error);
        globalSnack({
          message: t('common:error on creating a notification'),
          error: true,
          timeout: 5000,
        });
      },
      onCompleted({ createNotificationGroup: createPayload }) {
        globalSnack({
          message: t(SUBMIT_ADD_MESSAGES[getEventContext(createPayload as NotificationInput)].success, {
            notificationName: createPayload?.displayName,
          }),
          timeout: 5000,
        });
        onClose();
      },
      refetchQueries: [NotificationGroupsDocument],
    });

  const [updateNotificationGroup, { loading: updateNotificationGroupLoading }] =
    useUpdateNotificationGroupMutation({
      context: {
        _instance: 'node',
      },
      onError(error) {
        console.error(error);
        globalSnack({
          message: t('common:error on updating a notification'),
          error: true,
          timeout: 5000,
        });
      },
      onCompleted({ updateNotificationGroup: payload }) {
        globalSnack({
          message: t(SUBMIT_EDIT_MESSAGES[getEventContext(payload as NotificationInput)].success, {
            notificationName: payload?.displayName,
          }),
          timeout: 5000,
        });
        onClose();
      },
      refetchQueries: [NotificationGroupsDocument],
    });

  const [deleteNotificationGroup, { loading: deleteNotificationLoading }] =
    useDeleteNotificationGroupMutation({
      onError(error) {
        console.error(error);
      },
      context: {
        _instance: 'node',
      },
      refetchQueries: [NotificationGroupsDocument],
    });


  useMemo(() => {
    if (data?.getNotificationGroupById) {
      const notificationData = data?.getNotificationGroupById as Notification;
      const eventContext = getEventContext(notificationData);

      const normalizedResponse = normalizeResponse({
        eventContext,
        data: notificationData,
      });
      form.setFieldsValue(normalizedResponse as unknown as Notification);
    }
  }, [data?.getNotificationGroupById, form, getEventContext]);

  async function onFinish() {
    const input = omitDeep(
      form.getFieldsValue({ strict: true }),
      '__typename',
    ) as NotificationInput;

    const eventContext = getEventContext(input);

    submit({
      eventContext,
      input,
      notificationId,
      handleUpdate: updateNotificationGroup,
      handleCreate: createNotificationGroup,
      metadata,
    });
  }

  const INITIAL_VALUES = {
    userEventActionRules: [
      {
        event: {
          context: initialContext ?? EVENT_CONTEXT_OPTIONS[0].value,
          filters: [
            {
              Type: {
                property: 'Type',
                operator: Operator.In,
                values: ['guest', 'internal'],
              },
              Assignee: {
                property: 'Assignee',
                operator: Operator.NotIn,
                values: [Operator.NotNull, Operator.Null],
              },
            },
          ],
        },
        actions: [{}],
      },
    ],
  };

  const contextDidUpdate =
    (eventField: FormListFieldData) =>
    (prevValues: Notification, currentValues: Notification) =>
      prevValues.userEventActionRules[eventField.name].event.context !==
      currentValues.userEventActionRules[eventField.name].event.context;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontWeight: 500,
          },
        },
      }}
    >
      <Drawer
        destroyOnClose
        open={open}
        width={675}
        onClose={onClose}
        title={<DrawerTitle onClose={onClose} t={t} id={notificationId} />}
        footer={
          <DrawerFooter
            loading={
              updateNotificationGroupLoading || createNotificationGroupLoading
            }
            onSubmit={() => form.submit()}
            onClose={onClose}
            onDelete={() => setIsDeleting(true)}
            t={t}
            eventContext={initialContext}
            id={notificationId}
          />
        }
      >
        {loading ? (
          <FormSkeleton />
        ) : (
          <Form
            autoComplete="off"
            title="Notification Form"
            form={form}
            onFieldsChange={() => {
              if (notificationId) {
                const actions = form.getFieldValue([
                  'userEventActionRules',
                  0,
                  'actions',
                ]);
                for (const action in actions) {
                  if (actions[action]?.legacyAssigneeFilter) {
                    actions[action].legacyAssigneeFilter = null;
                    form.setFieldValue(
                      ['userEventActionRules', 'actions'],
                      actions,
                    );
                  }
                }
              }
            }}
            initialValues={INITIAL_VALUES}
            onFinish={onFinish}
            scrollToFirstError
          >
            <RenderIf
              condition={[EventContext.Job, EventContext.ProjectCycle].includes(
                initialContext,
              )}
            >
              <FormItem<Notification>
                label="Name"
                name="displayName"
                required
                validateDebounce={250}
                rules={[
                  {
                    required: true,
                    message: t('validation:Name is a required field'),
                    whitespace: true,
                  },
                  {
                    async validator(rule, value: string) {
                      if (
                        value?.trim() &&
                        value !== data?.getNotificationGroupById?.displayName
                      ) {
                        await getNameCollision({
                          variables: {
                            filters: `displayName==${value.trim()}`,
                          },
                        }).then(({ data: payload }) => {
                          const notification = payload?.page?.edges?.find(
                            ({ node }) =>
                              node.displayName.trim() === value.trim(),
                          )?.node;

                          if (notification) {
                            return Promise.reject(
                              new Error(
                                t(
                                  'common:A notification with this name already exists.',
                                ),
                              ),
                            );
                          }
                          return Promise.resolve();
                        });
                      }
                    },
                  },
                ]}
              >
                <Input placeholder={t('fields:Notification Name')} />
              </FormItem>
            </RenderIf>
            <Flex
              vertical
              style={{
                marginBottom: SPACING.SIZE_MD,
              }}
            >
              <Section
                title={t('common:Criteria')}
                subtitle={t('common:Define what will trigger a notification')}
              />
            </Flex>

            <FormList name="userEventActionRules">
              {(eventFields) =>
                eventFields.map((eventField) => (
                  <Fragment key={eventField.key}>
                    <FormItem style={STYLES.criteriaContainer}>
                      <RenderIf
                        condition={[
                          EventContext.Job,
                          EventContext.ProjectCycle,
                        ].includes(initialContext)}
                      >
                        <FormItem
                          name={[eventField.name, 'event', 'context']}
                          label={t('fields:If a')}
                        >
                          <Select options={EVENT_CONTEXT_OPTIONS} />
                        </FormItem>
                      </RenderIf>
                      <FormList name={[eventField.name, 'event', 'filters']}>
                        {(filterFields) => (
                          <ConfigProvider
                            theme={{
                              components: {
                                Select: {
                                  colorTextPlaceholder: COLORS.neutral[8],
                                },
                              },
                            }}
                          >
                            {filterFields.map((filterField) => (
                              <FormItem<Notification>
                                noStyle
                                key={filterField.key}
                                shouldUpdate={contextDidUpdate(eventField)}
                              >
                                {({ getFieldValue }) => (
                                  <Criteria
                                    eventContext={getFieldValue([
                                      'userEventActionRules',
                                      eventField.name,
                                      'event',
                                      'context',
                                    ])}
                                    metadata={metadata}
                                    filterField={filterField}
                                  />
                                )}
                              </FormItem>
                            ))}
                          </ConfigProvider>
                        )}
                      </FormList>
                    </FormItem>
                    <FormList name={[eventField.name, 'actions']}>
                      {(actionFields, { add, remove }) => (
                        <Actions
                          actionFields={actionFields}
                          add={add}
                          remove={remove}
                          eventField={eventField}
                          form={form}
                          contextDidUpdate={contextDidUpdate}
                        />
                      )}
                    </FormList>
                  </Fragment>
                ))
              }
            </FormList>
          </Form>
        )}
      </Drawer>
      {isDeleting && (
        <Confirmation
          onHide={() => setIsDeleting(false)}
          title={t('notifications:Delete Task Notification')}
          onConfirm={async () => {
            if (notificationId) {
              await deleteNotificationGroup({
                variables: {
                  deleteNotificationGroupId: notificationId,
                },
                onCompleted() {
                  onClose();
                  globalSnack({
                    message: `${t('notifiactions:Notification')} ${t(
                      'common:was successfully deleted',
                    )}`,
                    error: false,
                    timeout: 5000,
                  });
                },
              });
            }
          }}
          loadingMessage={t('common:Deleting...')}
          isDisabled={deleteNotificationLoading}
          confirmText={t('common:Yes, Delete')}
          isSubModal
        >
          <Typography.Paragraph
            style={{
              margin: '2rem 0',
            }}
          >
            {`${t('common:Are you sure you want to')} ${t('common:Delete')} ${t(
              'notifiactions:this Notification',
            )}`}
            ?
          </Typography.Paragraph>
        </Confirmation>
      )}
    </ConfigProvider>
  );
}

export default NotificationForm;
