import { Anchor, ConfigProvider, Menu, MenuProps, ThemeConfig, Typography } from 'antd';
import { AnchorLinkItemProps } from 'antd/es/anchor/Anchor';
import { MenuItemType } from 'antd/es/menu/interface';
import { Fragment } from 'react';

export interface TopHorizontalMenuProps extends MenuProps {
  items: MenuItemType[];
  themeConfig?: ThemeConfig;
  selected: string[];
};

export function TopHorizontalMenu({
  items = [
    { label: 'General Settings', key: 'general' },
    { label: 'Notifications', key: 'notifications' },
  ],
  selected = ['notifications'],
  themeConfig = {
    components: {
      Menu: {
        colorBgTextActive: 'transparent',
        itemBg: 'transparent',
        itemColor: '#5C5C5C',
        fontSize: 16,
      },
    },
  },
  ...menuProps
}: TopHorizontalMenuProps) {
  return (
    <ConfigProvider theme={themeConfig}>
      <style>
        {`.topcat-menu >.ant-menu-item-selected {
          font-weight: 500;
        }`}
      </style>
      <Menu
        items={items}
        className="topcat-menu"
        mode="horizontal"
        selectedKeys={selected}
        style={{ fontFamily: 'Montserrat', marginLeft: '-1.5%' }}
        {...menuProps}
      />
    </ConfigProvider>
  );
}

export interface TopVerticalMenuProps {
  sections: { items: AnchorLinkItemProps[]; title: string }[];
  theme?: ThemeConfig;
  selected: string[];
}

export function TopVerticalMenu({
  sections = [
    {
      items: [
        { title: 'Users', key: 'users', href: '#users' },
        {
          title: 'Organizations',
          key: 'organizations',
          href: '#organizations',
        },
        { title: 'Departments', key: 'departments', href: '#departments' },
        { title: 'Employment Types', key: 'employment', href: '#employment' },
        { title: 'Roles & Permissions', key: 'roles', href: '#roles' },
        { title: 'Floor plan', key: 'floor-plan', href: '#floor-plan' },
        { title: 'Assets', key: 'assets', href: '#assets' },
      ],
      title: 'General',
    },
    {
      items: [
        { title: 'Checklists', key: 'checklists', href: '#checklists' },
        { title: 'Rules', key: 'rules', href: '#rules' },
        { title: 'Items', key: 'items', href: '#items' },
      ],
      title: 'Jobs',
    },
  ],
  theme = {
    components: {
      Typography: {
        fontSizeHeading2: 18,
      },
    },
  },
}: TopVerticalMenuProps) {
  return (
    <ConfigProvider theme={theme}>
      <style>
        {`.topcat-menu >.ant-menu-item-selected {
      font-weight: 500;
    }`}
      </style>
      {sections.map((section) => {
        return (
          <Fragment key={section.title}>
            <Typography.Title level={2} style={{ fontFamily: 'Montserrat' }}>
              {section.title}
            </Typography.Title>
            <Anchor
              affix={false}
              items={section.items}
              className="topcat-menu"
              style={{ margin: '10px 0 15px' }}
            />
          </Fragment>
        );
      })}
    </ConfigProvider>
  );
}
