import { gql } from "@apollo/client";

export const PREDICT_JOB_DURATION = gql`
  mutation predictJobDuration($input: JobDurationInput!) {
    predictJobDuration(jobDurationInput: $input) {
      dueTime
      jobDuration
      waitTime
    }
  }
`;
