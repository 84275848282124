import { useTranslation } from 'react-i18next';

import { GA_EVENTS } from '@optii/shared';
import { UploadList } from '@optii/shared/components/molecules/UploadList';
import type { TUploadHandleFile } from '@optii/shared/types';
import { UploadDragger } from '@optii/shared/components/molecules/UploadDragger';
import { UploadFile } from 'antd';
import { COLORS } from '@optii-solutions/ui-library';

type TJobDetailsAttachmentsProps = {
  data?: string[] | null;
  handleRemoveFile: (params: { file: string }) => Promise<void>;
  handleAddFile: (params: { files: string[] }) => Promise<void>;
  isAddEnabled?: boolean;
  isView?: boolean;
};

const debounce = (callback: (files: string[]) => void, delay: number) => {
  let timer: NodeJS.Timeout;

  return (values: string[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(values);
    }, delay);
  };
};

export function JobDetailsAttachments(props: TJobDetailsAttachmentsProps) {
  const {
    data,
    handleRemoveFile,
    handleAddFile,
    isAddEnabled = true,
    isView = true,
  } = props;

  const { t } = useTranslation(['common', 'fields', 'jobs'], {
    useSuspense: false,
  });

  async function handleUpload(files: string[]) {
    await handleAddFile({
      files,
    });
  }

  async function handleRemove(file: TUploadHandleFile) {
    if (file.uid) {
      await handleRemoveFile({
        file: file.uid,
      });
    }
  }

  const debounceHandleUpload = debounce(handleUpload, 1500);

  const fileList = data?.length ? data : [];

  return fileList.length ? (
    <UploadList
      isAddEnabled={isAddEnabled}
      isView={isView}
      placeholder={t('fields:Click or drag a file to this area to upload')}
      handleUpload={debounceHandleUpload}
      handleRemove={handleRemove}
      analytics={GA_EVENTS.addFile}
      fileList={fileList as unknown as UploadFile<string>[]}
      style={{
        backgroundColor: COLORS.neutral[1],
      }}
    />
  ) : (
    <UploadDragger
      placeholder={t('fields:Click or drag a file to this area to upload')}
      disabled={isAddEnabled}
      isView={isView}
      handleUpload={debounceHandleUpload}
      analytics={GA_EVENTS.addFile}
      style={{
        backgroundColor: COLORS.neutral[1],
      }}
    />
  );
}
