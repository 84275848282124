import styled from 'styled-components/macro';
import Field from '@optii/topcat-client/components/shared/form/Field';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  paddingLeft: ({ theme }) => `
    padding-left: 1.5rem;
    @media (max-width: ${theme.breakpoints.sm}) {
      padding-left: 0;
    }
  `,
  snap: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      margin-top: 1.5rem;
      padding-right: 0;
      width: 31rem;
    }
  `,
  paddingRight: () => `
    padding-right: 1.5rem;
  `,
  noMargin: () => `
      min-height: auto;
      height: auto;
      margin: 0;
    `,
  wide: ({ theme }) => `
    min-width: 18rem;
    @media (max-width: ${theme.breakpoints.sm}) {
      min-width: 18rem;
    }
  `,
  noMarginMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      margin-top: 0;
      padding-right: 0;      
    }

  `,
  viewOnly: () => `
    pointer-events:none;
    `,
};
const GreyFilterWithArrows = styled(Field)`
  height: 2.5rem;
  min-height: 2.5rem;
  cursor: pointer;
  margin: 0 0 1rem 0;
  max-width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 10rem;
    padding-right: 1rem;
    margin-top: 1.5rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default GreyFilterWithArrows;
