import { gql } from '@apollo/client';

export const GET_NEW_JOB_DEFAULTS = gql`
  query jobDefaults(
    $action: String
    $locationID: Int
    $roomTypeID: Int
    $item: String
    $type: String
  ) {
    defaults: jobDefaults(
      action: $action
      locationID: $locationID
      roomTypeID: $roomTypeID
      item: $item
      type: $type
    ) {
      checklistsTemplate {
        id
      }
      creditValue
      durationMin
      department {
        id: departmentId
      }
      role {
        id: roleId
      }
    }
  }
`;
