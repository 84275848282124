import styled from 'styled-components/macro';
import { ReactComponent as infoCircle } from 'images/svg/infoCircle.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

const InfoCircleIcon = styled(infoCircle)`
  height: 2rem;
  width: 2rem;
  ${applyStyleModifiers(CONFIG)};
`;
export default InfoCircleIcon;
