/* eslint-disable no-underscore-dangle */
// Used to handl unauthenticated requests, this is mostly for things like accepting an invite that have no auth
function handleUnauthenticatedRequest(request, previousContext) {
  if (previousContext && previousContext._shard) {
    return {
      'X-Optii-UserInfo': `{"shard":[${previousContext._shard}]}`,
    };
  }
  return {};
}

// previousContext can contain:
//    _skipAuth: indicates that this is unauthenticated. If this is present, then context can also include:
//    _shard: provide a shard value in an unauthenticated context
// eslint-disable-next-line consistent-return
export async function getAuthHeaders(request, previousContext, auth) {
  if (previousContext && previousContext._skipAuth) {
    return handleUnauthenticatedRequest(request, previousContext);
  }

  if (!auth) {
    throw new Error('Expected authentication info, none found.');
  }
  let token;
  try {
    token = await auth.getValidToken();
  } catch (err) {
    // do nothing if we couldn't get token, AuthService.js will sign us out
    return undefined;
  }

  const accessToken = token && token.accessToken;

  // If the user has access, get their property details and attach token and property id (shard)
  if (accessToken) {
    let propId;
    const localProperty = JSON.parse(localStorage.getItem('optiiProperty'));
    const sessionProperty = JSON.parse(sessionStorage.getItem('optiiProperty'));

    sessionProperty
      ? (propId = sessionProperty.id)
      : (propId = localProperty && localProperty.id);

    return {
      'X-Optii-UserInfo': `{"shard":[${previousContext?._shard || propId || ''}]}`,
      authorization: `Bearer ${accessToken}`,
    };
  }
}

export async function getWSAuth(auth) {
  const headers = await getAuthHeaders(null, null, auth);
  return {
    authToken: headers ? headers.authorization : "'",
    XOptiiUserInfo: headers ? headers['X-Optii-UserInfo'] : '',
  };
}
