import React from 'react';
import styled from 'styled-components/macro';

import { CHECKLIST_TASK_FIELDS } from 'checklists/constants';

import colors from 'theme/colors';
import { ReactComponent as PassIcon } from 'images/svg/pass-icon.svg';
import { ReactComponent as FailIcon } from 'images/svg/fail-icon.svg';
import { ReactComponent as NAIcon } from 'images/svg/na-pass-fail-icon.svg';
import OptionType from './OptionType';

const PassIconSelected = styled(PassIcon)`
  path {
    fill: ${(props) => props.theme.colors.white};
  }
`;
const PassIconNotSelected = styled(PassIcon)`
  opacity: 0.5;
`;

const FailIconSelected = styled(FailIcon)`
  rect {
    fill: ${(props) => props.theme.colors.white};
  }
`;
const FailIconNotSelected = styled(FailIcon)`
  opacity: 0.5;
`;

const NAIconSelected = styled(NAIcon)`
  circle {
    stroke: ${(props) => props.theme.colors.white};
  }
`;
const NAIconNotSelected = styled(NAIcon)`
  opacity: 0.5;
`;

function PassFail(props) {
  const options = [
    {
      label: <PassIcon />,
      labelConfig: {
        idle: <PassIcon />,
        selected: <PassIconSelected />,
        notSelected: <PassIconNotSelected />,
      },
      stylesConfig: {
        idle: {},
        selected: { backgroundColor: colors.polarGreen06 },
        notSelected: {},
      },
      value: 'PASSED',
    },
    {
      label: <FailIcon />,
      labelConfig: {
        idle: <FailIcon />,
        selected: <FailIconSelected />,
        notSelected: <FailIconNotSelected />,
      },
      stylesConfig: {
        idle: {},
        selected: { backgroundColor: colors.dustRed06 },
        notSelected: {},
      },
      value: 'ERROR',
    },
    {
      label: <NAIcon />,
      labelConfig: {
        idle: <NAIconNotSelected />,
        selected: <NAIconSelected />,
        notSelected: <NAIconNotSelected />,
      },
      stylesConfig: {
        idle: {},
        selected: { backgroundColor: colors.neutral08 },
        notSelected: {},
      },
      value: 'NOT_APPLICABLE',
    },
  ];

  return (
    <OptionType
      {...props}
      fieldName={CHECKLIST_TASK_FIELDS.dataPassFail}
      options={options}
    />
  );
}

export default PassFail;
