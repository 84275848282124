/* eslint-disable */
import {
  AuthorizationRequestHandler,
  StorageBackend,
  BasicQueryStringUtils,
  DefaultCrypto,
  AuthorizationServiceConfiguration,
  AuthorizationRequest,
  AuthorizationRequestResponse,
  StringMap,
  AuthorizationError,
  AuthorizationErrorJson,
  AuthorizationResponse,
  AuthorizationResponseJson,
  AuthorizationNotifier,
} from '@openid/appauth';
import { Browser } from 'ionic-appauth';

/** key for authorization request. */
const authorizationRequestKey = (handle: string) =>
  `${handle}_appauth_authorization_request`;

/** key in local storage which represents the current authorization request. */
const AUTHORIZATION_REQUEST_HANDLE_KEY =
  'appauth_current_authorization_request';
export const AUTHORIZATION_RESPONSE_KEY = 'auth_response';

// Read AuthService comments for more tasty details
export class OptiiAuthorizationRequestHandler extends AuthorizationRequestHandler {
  constructor(
    private browser: Browser,
    private storage: StorageBackend,
    utils = new BasicQueryStringUtils(),
    private generateRandom = new DefaultCrypto(),
  ) {
    super(utils, generateRandom);
  }

  protected async completeAuthorizationRequest(): Promise<AuthorizationRequestResponse> {
    const handle = await this.storage.getItem(AUTHORIZATION_REQUEST_HANDLE_KEY);

    if (!handle) {
      throw new Error('Handle Not Available');
    }
    const request: AuthorizationRequest = this.getAuthorizationRequest(
      await this.storage.getItem(authorizationRequestKey(handle)),
    );
    const queryParams = this.getQueryParams(
      await this.storage.getItem(AUTHORIZATION_RESPONSE_KEY),
    );
    this.removeItemsFromStorage(handle);

    const { state } = queryParams;
    const { error } = queryParams;

    if (state !== request.state) {
      throw new Error('State Does Not Match');
    }

    return <AuthorizationRequestResponse>{
      request,
      response: !error ? this.getAuthorizationResponse(queryParams) : undefined,
      error: error ? this.getAuthorizationError(queryParams) : undefined,
    };
  }

  setAuthorizationNotifier(
    notifier: AuthorizationNotifier,
  ): AuthorizationRequestHandler {
    this.notifier = notifier;
    return this;
  }

  private getAuthorizationRequest(
    authRequest: string | null,
  ): AuthorizationRequest {
    if (authRequest == null) {
      throw new Error('No Auth Request Available');
    }

    return new AuthorizationRequest(JSON.parse(authRequest));
  }

  private getAuthorizationError(queryParams: StringMap): AuthorizationError {
    const authorizationErrorJSON: AuthorizationErrorJson = {
      error: queryParams.error,
      error_description: queryParams.error_description,
      error_uri: undefined,
      state: queryParams.state,
    };
    return new AuthorizationError(authorizationErrorJSON);
  }

  private getAuthorizationResponse(
    queryParams: StringMap,
  ): AuthorizationResponse {
    const authorizationResponseJSON: AuthorizationResponseJson = {
      code: queryParams.code,
      state: queryParams.state,
    };
    return new AuthorizationResponse(authorizationResponseJSON);
  }

  private removeItemsFromStorage(handle: string): void {
    this.storage.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY);
    this.storage.removeItem(authorizationRequestKey(handle));
    this.storage.removeItem(AUTHORIZATION_RESPONSE_KEY);
  }

  private getQueryParams(authResponse: string | null): StringMap {
    if (authResponse != null) {
      const querySide: string = authResponse.split('#')[0];
      const parts: string[] = querySide.split('?');
      if (parts.length !== 2) throw new Error('Invalid auth response string');
      const hash = parts[1];
      return this.utils.parseQueryString(hash);
    }
    return {};
  }

  public async performAuthorizationRequest(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest,
  ): Promise<void> {
    const handle = this.generateRandom.generateRandom(10);
    this.storage.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle);
    this.storage.setItem(
      authorizationRequestKey(handle),
      JSON.stringify(await request.toJson()),
    );
    const url = this.buildRequestUrl(configuration, request);

    const returnedUrl: string | undefined = await this.browser.showWindow(
      url,
      request.redirectUri,
    );
    // callback may come from showWindow or via another method
    if (returnedUrl) {
      await this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
      this.completeAuthorizationRequestIfPossible();
    }
  }

  /** ****** The following are inspired by Okta-react, pour one out for it ******* */
  // Create an iframe to use for authorization
  public setUpIFrame(url: string): any {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    return document.body.appendChild(iframe);
  }

  // used for listening to window events
  public addListener(eventTarget: any, name: string, fn: Function) {
    if (eventTarget.addEventListener) {
      eventTarget.addEventListener(name, fn);
    } else if (eventTarget.addListener) {
      eventTarget.addListener(name, fn);
    }
  }

  // used for clearing window events
  public removeListener(eventTarget: any, name: string, fn: Function) {
    if (eventTarget.removeEventListener) {
      eventTarget.removeEventListener(name, fn);
    } else if (eventTarget.removeListener) {
      eventTarget.removeListener(name, fn);
    }
  }

  // Control logic for listening to the window for auth events from the iframe
  // 15000 ms = 15 seconds
  public addPostMessageListener(timeout = 15000, state: string) {
    let responseHandler: any;
    let timeoutId: any;
    const self = this;
    const msgReceivedOrTimeout = new Promise((resolve, reject) => {
      responseHandler = function responseHandler(e: any) {
        if (!e.data || e.data.state != state) {
          // A message not meant for us
          return;
        }
        resolve(e.data);
      };
      self.addListener(window, 'message', responseHandler);

      timeoutId = setTimeout(() => {
        reject('OAuth flow timed out');
      }, timeout);
    });

    return msgReceivedOrTimeout.finally(() => {
      // Cleanup
      clearTimeout(timeoutId);
      self.removeListener(window, 'message', responseHandler);
    });
  }

  // A handrolled solution for token renewal
  // Since okta doesn't support refresh tokens for our apps configuration (I've no idea why TBH)
  //  We embed a hidden iframe and use a standard authorization flow to obtain a new token
  //  This is exactly what okta-react does to allow a silent fetching of a new token
  public async completeRenewal(
    configuration: AuthorizationServiceConfiguration,
    request: AuthorizationRequest,
  ): Promise<any> {
    const handle = this.generateRandom.generateRandom(10);
    this.storage.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle);
    this.storage.setItem(
      authorizationRequestKey(handle),
      JSON.stringify(await request.toJson()),
    );
    // Build the URL that will be the src of iframe
    const url = this.buildRequestUrl(configuration, request);
    try {
      // Setup listeners/handlers
      const iframePromise = this.addPostMessageListener(15000, request.state);

      // Setup iframe
      const iframeEl = this.setUpIFrame(url);

      const response: any = await iframePromise.finally(() => {
        if (document.body.contains(iframeEl)) {
          // cleanup
          iframeEl.parentElement.removeChild(iframeEl);
        }
      });
      return response;
    } catch (err) {
      return { error: err };
    }
  }
}
