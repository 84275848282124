import { DatePicker as AntDatePicker, ConfigProvider, ThemeConfig } from 'antd';
import { type RangePickerProps } from 'antd/es/date-picker';

const { RangePicker: AntRangePicker } = AntDatePicker;

type Props = RangePickerProps & {
  theme?: ThemeConfig;
};

export function RangePicker(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntRangePicker {...rest} />
    </ConfigProvider>
  );
}
