import { gql } from '@apollo/client';

export default gql`
  query ListLocations(
    $filters: Map
    $first: Int
    $cursor: String
    $orderBy: String
  ) {
    page: ListLocations(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on Location {
            id
            isActive
            pId: parentId
            value: id
            parentId
            isVirtual
            virtualChildrenLocationIds
            parentLocation {
              id
              displayName
              longDisplayName
              shortDisplayName
              name
              number
            }
            childrenIds
            status
            locationType {
              id
              displayName
              code
              squareUnit
              note
            }
            roomType {
              id
              displayName
              code
              note
            }
            number
            name
            description
            displayName
            longDisplayName
            shortDisplayName
            title: displayName
            class
            squareUnit
            notes
            sequence
            tags {
              name
            }
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
