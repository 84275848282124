import React from 'react';

export function RushInactive() {
  return <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75355 15.6478C4.55818 15.8424 4.24211 15.8421 4.04711 15.6471L3.35355 14.9536C3.15829 14.7583 3.15829 14.4417 3.35355 14.2464L11.7464 5.85355C12.0614 5.53857 11.8383 5 11.3929 5H10.5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 9.77614 7 9.5 7H8.5C8.22386 7 8 6.77614 8 6.5V3.5C8 3.22386 8.22386 3 8.5 3H13.825C14.0917 3 14.35 3.05 14.6 3.15C14.85 3.25 15.0667 3.39167 15.25 3.575L18.25 6.55C18.7 7 19.25 7.35 19.9 7.6C20.4069 7.79496 20.9442 7.91897 21.5119 7.97202C21.7825 7.99732 22 8.2172 22 8.48901V9.5C22 9.77614 21.7759 10.0015 21.5002 9.98727C20.6623 9.94391 19.8914 9.78982 19.1875 9.525C18.3458 9.20833 17.6 8.73333 16.95 8.1L16.3033 7.42101C16.1096 7.21759 15.7864 7.21364 15.5877 7.41228L14.1036 8.89645C13.9083 9.09171 13.9083 9.40829 14.1036 9.60355L15.5389 11.0389C15.7715 11.2715 15.72 11.6614 15.435 11.8256L9.88211 15.026C9.64328 15.1636 9.33811 15.082 9.19987 14.8435L8.70154 13.9839C8.56275 13.7445 8.64483 13.4379 8.88469 13.2998L12.1845 11.4005C12.4697 11.2364 12.5213 10.8463 12.2886 10.6136L11.4029 9.72789C11.2079 9.53289 10.8918 9.53259 10.6964 9.72722L4.75355 15.6478ZM2.5 9C2.22386 9 2 8.77614 2 8.5V7.5C2 7.22386 2.22386 7 2.5 7H6.5C6.77614 7 7 7.22386 7 7.5V8.5C7 8.77614 6.77614 9 6.5 9H2.5ZM0.5 6C0.223858 6 0 5.77614 0 5.5V4.5C0 4.22386 0.223858 4 0.5 4H4.5C4.77614 4 5 4.22386 5 4.5V5.5C5 5.77614 4.77614 6 4.5 6H0.5ZM18.475 4C17.925 4 17.45 3.80417 17.05 3.4125C16.65 3.02083 16.45 2.55 16.45 2C16.45 1.45 16.65 0.979167 17.05 0.5875C17.45 0.195833 17.925 0 18.475 0C19.025 0 19.5 0.195833 19.9 0.5875C20.3 0.979167 20.5 1.45 20.5 2C20.5 2.55 20.3 3.02083 19.9 3.4125C19.5 3.80417 19.025 4 18.475 4ZM2.5 3C2.22386 3 2 2.77614 2 2.5V1.5C2 1.22386 2.22386 1 2.5 1H6.5C6.77614 1 7 1.22386 7 1.5V2.5C7 2.77614 6.77614 3 6.5 3H2.5Z" fill="#BFBFBF"/>
  </svg>;
}

export function RushActive() {
  return <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75355 15.6478C4.55818 15.8424 4.24211 15.8421 4.04711 15.6471L3.35355 14.9536C3.15829 14.7583 3.15829 14.4417 3.35355 14.2464L11.7464 5.85355C12.0614 5.53857 11.8383 5 11.3929 5H10.5C10.2239 5 10 5.22386 10 5.5V6.5C10 6.77614 9.77614 7 9.5 7H8.5C8.22386 7 8 6.77614 8 6.5V3.5C8 3.22386 8.22386 3 8.5 3H13.825C14.0917 3 14.35 3.05 14.6 3.15C14.85 3.25 15.0667 3.39167 15.25 3.575L18.25 6.55C18.7 7 19.25 7.35 19.9 7.6C20.4069 7.79496 20.9442 7.91897 21.5119 7.97202C21.7825 7.99732 22 8.2172 22 8.48901V9.5C22 9.77614 21.7759 10.0015 21.5002 9.98727C20.6623 9.94391 19.8914 9.78982 19.1875 9.525C18.3458 9.20833 17.6 8.73333 16.95 8.1L16.3033 7.42101C16.1096 7.21759 15.7864 7.21364 15.5877 7.41228L14.1036 8.89645C13.9083 9.09171 13.9083 9.40829 14.1036 9.60355L15.5389 11.0389C15.7715 11.2715 15.72 11.6614 15.435 11.8256L9.88211 15.026C9.64328 15.1636 9.33811 15.082 9.19987 14.8435L8.70154 13.9839C8.56275 13.7445 8.64483 13.4379 8.88469 13.2998L12.1845 11.4005C12.4697 11.2364 12.5213 10.8463 12.2886 10.6136L11.4029 9.72789C11.2079 9.53289 10.8918 9.53259 10.6964 9.72722L4.75355 15.6478ZM2.5 9C2.22386 9 2 8.77614 2 8.5V7.5C2 7.22386 2.22386 7 2.5 7H6.5C6.77614 7 7 7.22386 7 7.5V8.5C7 8.77614 6.77614 9 6.5 9H2.5ZM0.5 6C0.223858 6 0 5.77614 0 5.5V4.5C0 4.22386 0.223858 4 0.5 4H4.5C4.77614 4 5 4.22386 5 4.5V5.5C5 5.77614 4.77614 6 4.5 6H0.5ZM18.475 4C17.925 4 17.45 3.80417 17.05 3.4125C16.65 3.02083 16.45 2.55 16.45 2C16.45 1.45 16.65 0.979167 17.05 0.5875C17.45 0.195833 17.925 0 18.475 0C19.025 0 19.5 0.195833 19.9 0.5875C20.3 0.979167 20.5 1.45 20.5 2C20.5 2.55 20.3 3.02083 19.9 3.4125C19.5 3.80417 19.025 4 18.475 4ZM2.5 3C2.22386 3 2 2.77614 2 2.5V1.5C2 1.22386 2.22386 1 2.5 1H6.5C6.77614 1 7 1.22386 7 1.5V2.5C7 2.77614 6.77614 3 6.5 3H2.5Z" fill="#CF1322"/>
  </svg>;
}

export function Priority() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="2" fill="#F2722C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.99783 11.0877C8.61148 11.0877 9.10894 11.5904 9.10894 12.2105C9.10894 12.8306 8.61148 13.3333 7.99783 13.3333C7.38418 13.3333 6.88672 12.8306 6.88672 12.2105C6.88672 11.5904 7.38418 11.0877 7.99783 11.0877ZM8.70292 2.66666C8.88887 2.66666 9.03962 2.81137 9.03962 2.98989C9.03962 2.99701 9.03937 3.00413 9.03888 3.01124L8.56789 9.84103C8.55618 10.0109 8.40925 10.1429 8.23193 10.1429H7.76517C7.58787 10.1429 7.44095 10.0109 7.4292 9.84109L6.95685 3.0113C6.94453 2.83318 7.08496 2.67919 7.27051 2.66737L8.70292 2.66666Z" fill="white"/>
  </svg>;
}

export function Recurring() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.00021" cy="8.00006" r="2.43478" fill="#FADB14"/>
    <path d="M1.3913 8.00007C1.38918 5.59065 2.69965 3.37137 4.81051 2.20965C6.92137 1.04793 9.49743 1.12825 11.5318 2.4192L10.4783 3.31277C10.38 3.39588 10.3357 3.52642 10.363 3.65215C10.3904 3.77788 10.4848 3.87826 10.6087 3.91312L14.3885 4.96633C14.4188 4.97496 14.4502 4.97929 14.4817 4.9792C14.6738 4.9792 14.8296 4.82348 14.8296 4.63138C14.8295 4.61684 14.8286 4.60232 14.8268 4.5879L14.3443 0.738507C14.3285 0.611692 14.2444 0.503774 14.1253 0.457439C14.0062 0.411105 13.8713 0.433818 13.7739 0.516594L12.6372 1.48112C10.1976 -0.254308 6.99315 -0.48229 4.3324 0.890265C1.67166 2.26282 -1.66273e-05 5.00617 0 8.00007C5.725e-09 8.38427 0.311454 8.69572 0.695652 8.69572C1.07985 8.69572 1.3913 8.38427 1.3913 8.00007Z" fill="#158EB6"/>
    <path d="M15.3042 7.30441C14.92 7.30441 14.6085 7.61587 14.6085 8.00007C14.6108 10.4095 13.3004 12.6288 11.1896 13.7906C9.07875 14.9523 6.50269 14.872 4.46835 13.5809L5.52157 12.6874C5.61979 12.6043 5.66411 12.4737 5.6368 12.348C5.60948 12.2223 5.51499 12.1219 5.39114 12.087L1.60992 11.0338C1.4978 11.0026 1.37753 11.0296 1.28945 11.1057C1.20138 11.1817 1.15717 11.2968 1.17166 11.4122L1.65548 15.2616C1.6714 15.3883 1.75544 15.4961 1.87442 15.5424C1.9934 15.5887 2.1282 15.5661 2.22557 15.4835L3.36261 14.519C5.80224 16.2544 9.00668 16.4824 11.6674 15.1099C14.3282 13.7373 15.9998 10.994 15.9998 8.00007C15.9998 7.61587 15.6884 7.30441 15.3042 7.30441Z" fill="#158EB6"/>
  </svg>;
}

export function ImagesIcon() {
  return <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.182 6.18197H1.81836V0.363789C1.81836 0.162958 1.98116 0.000152588 2.182 0.000152588H13.8184C14.0192 0.000152588 14.182 0.162958 14.182 0.363789V6.18197Z" fill="#8C8C8C"/>
    <path d="M15.2727 1.45471H0.727273C0.325611 1.45471 0 1.78032 0 2.18198V13.8183C0 14.22 0.325611 14.5456 0.727273 14.5456H15.2727C15.6744 14.5456 16 14.22 16 13.8183V2.18198C16 1.78032 15.6744 1.45471 15.2727 1.45471Z" fill="#D9D9D9"/>
    <path d="M14.5454 13.4547H1.45446C1.25363 13.4547 1.09082 13.2919 1.09082 13.091V2.90923C1.09082 2.7084 1.25363 2.54559 1.45446 2.54559H14.5454C14.7462 2.54559 14.909 2.7084 14.909 2.90923V13.091C14.909 13.2919 14.7462 13.4547 14.5454 13.4547Z" fill="white"/>
    <ellipse cx="6.90927" cy="5.45471" rx="1.09091" ry="1.09091" fill="#BFBFBF"/>
    <path d="M8.88062 11.6365L5.71153 8.47032C5.64024 8.39804 5.54168 8.35933 5.44025 8.36378C5.33918 8.36771 5.24431 8.41356 5.17844 8.49032L2.99662 11.0358C2.90392 11.1436 2.88255 11.2955 2.9419 11.4247C3.00126 11.5539 3.13045 11.6366 3.27262 11.6365H8.88062Z" fill="#D9D9D9"/>
    <path d="M13.0217 11.0591L10.1126 7.05908C10.0454 6.96638 9.93849 6.91072 9.82403 6.90886C9.70956 6.907 9.60091 6.95915 9.53076 7.04962L5.96094 11.6365H12.7275C12.8643 11.6365 12.9895 11.5598 13.0515 11.438C13.1136 11.3161 13.1021 11.1697 13.0217 11.0591Z" fill="#595959"/>
  </svg>;
}

export function NotesIcon() {
  return <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="1.29559" width="14.5" height="14.5" rx="2.25" fill="white" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="square"/>
    <rect x="4" y="5.21228" width="8" height="1.33333" rx="0.666667" fill="#D9D9D9"/>
    <rect x="4" y="7.87894" width="8" height="1.33333" rx="0.666667" fill="#D9D9D9"/>
    <rect x="4" y="10.5456" width="5.33333" height="1.33333" rx="0.666667" fill="#D9D9D9"/>
</svg>;
}

export function LowPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
    <title>icons/ priority/ low</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -764.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-low" transform="translate(15.000000, 149.000000)">
                    <g id="icons/-priority/-low">
                        <g id="icons/-background/-border-radius-2" fill="#A1D2E2">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(6.000000, 4.000000)" fill="#FFFFFF">
                            <path d="M7.95143824,6.46506222 C7.87591725,6.31597333 7.72270636,6.22222222 7.55560196,6.22222222 L4.88890049,6.22222222 L4.88890049,0.444444444 C4.88890049,0.198982222 4.68991123,0 4.44445024,0 L3.55554976,0 C3.31008877,0 3.11109951,0.198982222 3.11109951,0.444444444 L3.11109951,6.22222222 L0.444398041,6.22222222 C0.277293638,6.22222222 0.12408275,6.31597333 0.0485617643,6.46506222 C-0.0273947826,6.61393333 -0.0135057124,6.79296889 0.0850200178,6.92816889 L3.64062198,11.8170578 C3.72438751,11.9318578 3.85763814,12 4,12 C4.14236186,12 4.27561249,11.9318578 4.35937802,11.8170578 L7.91497998,6.92816889 C8.01350571,6.79296889 8.02739478,6.61393333 7.95143824,6.46506222 Z" id="arrow-down/-low" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function MediumPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icons/ priority/ medium</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -729.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-medium" transform="translate(15.000000, 114.000000)">
                    <g id="icons/-priority/-medium">
                        <g id="icons/-background/-border-radius-2" fill="#FBC633">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(5.000000, 7.000000)" fill="#FFFFFF">
                            <path d="M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 L1,6 C0.44771525,6 6.76353751e-17,5.55228475 0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 L9,4 Z M9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L1,2 C0.44771525,2 6.76353751e-17,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L9,0 Z" id="equal/-medium" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function HighPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icons/ priority/ high</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -694.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-high" transform="translate(15.000000, 79.000000)">
                    <g id="icons/-priority/-high">
                        <g id="icons/-background/-border-radius-2" fill="#F2722C">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(9.000000, 4.000000)" fill="#FFFFFF">
                            <g id="exclamation/-alert">
                                <path d="M1.25,9.86842105 C1.94035594,9.86842105 2.5,10.4575201 2.5,11.1842105 C2.5,11.910901 1.94035594,12.5 1.25,12.5 C0.559644063,12.5 0,11.910901 0,11.1842105 C0,10.4575201 0.559644063,9.86842105 1.25,9.86842105 Z M1.96228401,0 C2.21332253,-1.0162618e-16 2.41682947,0.203506932 2.41682947,0.454545455 C2.41682947,0.464562482 2.41649834,0.474576773 2.41583663,0.484571921 L1.89600266,8.33671336 C1.88018947,8.57557314 1.68183268,8.76123235 1.44245004,8.76123235 L1.05914711,8.76123235 C0.819797871,8.76123235 0.621456758,8.57562329 0.605600219,8.33679987 L0.0842619426,0.484658431 C0.0676310048,0.234171402 0.257208824,0.0176295052 0.507695853,0.000998567354 L1.96228401,0 Z" id="icons/-Exclamation-Point" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function HighestPriority() {
  return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Highest Priority</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TC-837-Edit-Job-Priority-Menu-Mobile" transform="translate(-15.000000, -659.000000)">
            <g id="forms/-anatomy/-priority-menu" transform="translate(0.000000, 615.000000)">
                <g id="priority/-highest" transform="translate(15.000000, 44.000000)">
                    <g id="icons/-priority/-highest">
                        <g id="bg" fill="#CE0000">
                            <rect id="Rectangle" x="0" y="0" width="20" height="20" rx="2" />
                        </g>
                        <g id="icon" transform="translate(5.000000, 3.000000)" fill="#FFFFFF">
                            <path d="M8.06316069,2.97030816 C7.99116437,2.8876326 7.92739621,2.81350019 7.87361936,2.74818649 C7.80719843,2.66691037 7.69820043,2.6268733 7.59058749,2.6442234 C7.48297455,2.6615735 7.39449269,2.73344964 7.36082926,2.83086205 C7.19910645,3.29397256 6.95313806,3.72734111 6.63381337,4.11178207 C6.65262058,3.01715765 6.42135077,1.08558098 4.72987808,0.0452468415 C4.63941273,-0.0104797145 4.52360594,-0.0150588254 4.42845966,0.0333284704 C4.33331339,0.0817157661 4.27423224,0.17523535 4.27468503,0.27673841 C4.27468503,1.17955553 3.7589563,2.14162347 2.86238174,2.91546671 C1.99489957,3.66450729 -0.0207034881,5.64403578 0.000160751139,7.83686723 C0.0148538774,9.36360924 0.843840058,11.4745919 2.94672028,12.3126465 C2.97816357,11.3544367 3.42395302,10.6740169 3.86004501,10.0181241 C4.27439117,9.3941992 4.66610991,8.80554921 4.70196114,7.98182504 C4.70654373,7.87392437 4.77790305,7.7784758 4.8845072,7.73765568 C4.99111135,7.69683555 5.11331503,7.71816631 5.19711949,7.79222243 C6.38226705,8.83944619 7.01730397,10.3909909 7.0484534,12.2842612 C8.55684973,11.6810052 9.79224778,10.3661882 9.97444255,8.22737146 C10.2098264,5.44974822 8.75990874,3.77501695 8.06316069,2.97030816 Z" id="fire" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>;
}

export function CancelledIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9531 12L9.56083 10.6077C9.06865 10.9646 8.53413 11.2404 7.95727 11.435C7.38041 11.6297 6.77348 11.727 6.13649 11.727C5.32537 11.727 4.56311 11.5731 3.84973 11.2653C3.13633 10.9574 2.51578 10.5397 1.98806 10.0119C1.46034 9.48422 1.04257 8.86367 0.734731 8.15027C0.426896 7.43688 0.272978 6.67463 0.272978 5.86351C0.272978 5.22652 0.370313 4.61959 0.564982 4.04273C0.75964 3.46587 1.03541 2.93135 1.39228 2.43917L0 1.04687L0.659925 0.386947L11.6131 11.3401L10.9531 12ZM6.13649 10.8012C6.64213 10.8012 7.12602 10.7268 7.58814 10.5781C8.05026 10.4293 8.48469 10.216 8.89142 9.93829L2.06171 3.10858C1.78396 3.51531 1.57071 3.94974 1.42194 4.41186C1.27317 4.87398 1.19878 5.35787 1.19878 5.86351C1.19878 7.23167 1.67969 8.39666 2.64152 9.35848C3.60334 10.3203 4.76833 10.8012 6.13649 10.8012ZM11.0647 9.04452L10.3893 8.36917C10.6156 7.98617 10.7864 7.58538 10.9015 7.16679C11.0166 6.74818 11.0742 6.31376 11.0742 5.86351C11.0742 4.49536 10.5933 3.33036 9.63146 2.36854C8.66963 1.40672 7.50464 0.925805 6.13649 0.925805C5.68624 0.925805 5.25182 0.98337 4.83321 1.0985C4.41462 1.21364 4.01383 1.38437 3.63083 1.61068L2.95548 0.93531C3.42682 0.632989 3.92943 0.401524 4.46328 0.240914C4.99715 0.0803052 5.55489 0 6.13649 0C6.94761 0 7.70986 0.153918 8.42325 0.461753C9.13664 0.769589 9.7572 1.18736 10.2849 1.71508C10.8126 2.2428 11.2304 2.86336 11.5382 3.57675C11.8461 4.29014 12 5.05239 12 5.86351C12 6.44511 11.9197 7.00285 11.7591 7.53671C11.5985 8.07057 11.367 8.57317 11.0647 9.04452Z" fill="black" fillOpacity="0.45"/>
  </svg>;
}

export function InProgressIcon() {
  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7" fill="#52C41A"/>
    <circle cx="8" cy="8" r="5.5" fill="#52C41A" stroke="white"/>
  </svg>;
}

export function DoneIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50443 3.87012H7.8763C7.73969 3.87012 7.60978 3.93574 7.52942 4.04824L5.42407 6.96789L4.4705 5.64467C4.39014 5.53351 4.26157 5.46655 4.12362 5.46655H3.4955C3.40844 5.46655 3.35755 5.56565 3.40844 5.63664L5.07719 7.95092C5.11661 8.00594 5.16858 8.05078 5.22879 8.08171C5.289 8.11263 5.35571 8.12877 5.4234 8.12877C5.49109 8.12877 5.5578 8.11263 5.61801 8.08171C5.67821 8.05078 5.73018 8.00594 5.7696 7.95092L8.59014 4.04021C8.64237 3.96922 8.59148 3.87012 8.50443 3.87012Z" fill="#135200"/>
    <path d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z" fill="#135200"/>
  </svg>;
}

export function OnHoldIcon() {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821ZM4.82143 3.85714H4.17857C4.11964 3.85714 4.07143 3.90536 4.07143 3.96429V8.03571C4.07143 8.09464 4.11964 8.14286 4.17857 8.14286H4.82143C4.88036 8.14286 4.92857 8.09464 4.92857 8.03571V3.96429C4.92857 3.90536 4.88036 3.85714 4.82143 3.85714ZM7.82143 3.85714H7.17857C7.11964 3.85714 7.07143 3.90536 7.07143 3.96429V8.03571C7.07143 8.09464 7.11964 8.14286 7.17857 8.14286H7.82143C7.88036 8.14286 7.92857 8.09464 7.92857 8.03571V3.96429C7.92857 3.90536 7.88036 3.85714 7.82143 3.85714Z" fill="#614700"/>
  </svg>;
}

export function ProjectsIcon() {
  return <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Project Icon</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g id="TC-4917/5611-[System-Story]-Instantiate-&quot;Real-Assigned&quot;-Jobs-Into-Viewable-Jobs" transform="translate(-1145.000000, -50.000000)">
              <g id="anatomy/cards/-containers/-default" transform="translate(1111.000000, 39.000000)">
                  <g id="Group" transform="translate(10.000000, 10.000000)">
                      <g id="icon-project" transform="translate(25.000000, 2.000000)">
                          <ellipse id="Oval" stroke="#A1D2E2" cx="7.5" cy="7.50006792" rx="1.5" ry="1.50001358" />
                          <path d="M10.964,8.52407719 C10.985,8.18907416 11,7.84807107 11,7.50006792 C11,3.35803041 9.433,0 7.5,0 C5.567,0 4,3.35803041 4,7.50006792 C4,11.6421054 5.567,15.0001358 7.5,15.0001358 C8.46,15.0001358 9.329,14.1701283 9.962,12.8291162" id="Path" stroke="#FBC633" />
                          <path d="M6.476,4.03603655 C6.811,4.01503636 7.152,4.00003622 7.5,4.00003622 C11.642,4.00003622 15,5.56705041 15,7.50006792 C15,9.43308542 11.642,11.0000996 7.5,11.0000996 C3.358,11.0000996 0,9.43308542 0,7.50006792 C0,6.54005922 0.83,5.67105136 2.171,5.03804562" id="Path" stroke="#158EB6" />
                      </g>
                  </g>
              </g>
          </g>
      </g>
  </svg>;
}