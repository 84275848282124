import React from 'react';
import Form from 'blocks/Form';

function Checkbox({
  name,
  value,
  onChange,
  label,
  children,
  checked,
  readOnly,
  modifiers,
  isSwitcher,
  omitLabelFor,
}) {
  const renderInput = (_) => (
    <>
      <input
        name={name}
        id={name}
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={readOnly}
        data-testid={name}
        tabIndex={-1}
      />
      <span />
      {label || children}
    </>
  );
  return (
    <Form.CheckBox htmlFor={name} {...{ readOnly, modifiers, isSwitcher }}>
      {/* Sometimes, having for on a label can cause quirky behaviour */}
      {omitLabelFor ? (
        <label style={{ cursor: 'pointer' }} htmlFor={omitLabelFor}>{renderInput()}</label>
      ) : (
        <label style={{ cursor: 'pointer' }} htmlFor={name}>{renderInput()}</label>
      )}
    </Form.CheckBox>
  );
}
export default Checkbox;
