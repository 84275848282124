import { WebPlugin } from '@capacitor/core';

export class MyKeyboardPlugin extends WebPlugin {

  constructor() {
    super({
      name: 'KeyboardPlugin',
      platforms: ['web'],
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async hide(): Promise<void> {
    console.log('KeyboardPlugin: No action for the web.');
  }
}

const KeyboardPlugin = new MyKeyboardPlugin();

export { KeyboardPlugin };