import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  tight: ({ theme }) => `
    padding: 1.1rem 2rem 1.4rem 1.6rem;
  `,
  mobileHide: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      border: none;
      box-shadow: none;
    } 
  `,
};

const Card = styled.div`
  background: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.shadows.default}
  ${(props) => props.theme.borders.shadow}
  border-radius: 8px;
  margin: auto;
  margin-bottom: 1rem;
  padding: 2rem 6rem 2rem 5rem;
  position: relative;

  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Card;
