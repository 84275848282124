import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  HeaderActions,
  Row,
  SPACING,
  Skeleton,
  Typography,
  Collapse,
  CollapsePanel,
  Col,
  Descriptions,
  DescriptionText,
} from '@optii-solutions/ui-library';
import { RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useAccess } from '@optii/shared';
import { PERMISSIONS } from 'utils/constants';
import Attachments from 'components/shared/Attachments';
import { AssetsContext } from 'components/Settings/AssetsSettings/contexts';
import { AssetViewSkeleton } from './AssetView.skeleton';
import type { TAssetViewData, TAssetViewDataAttachment } from './types';
import { STYLES, TAB_COLUMNS } from './constants';
import { AssetViewJobActivity } from './AssetViewJobActivity';

interface IAssetViewProps {
  handleClose: () => void;
  handleHide: () => void;
  open: boolean;
}

function CollapseIcon({ isActive }: any) {
  return (
    <RightOutlined style={STYLES.collapseIcon} rotate={isActive ? 90 : 0} />
  );
}

export function AssetView(props: IAssetViewProps) {
  const { handleClose, handleHide, open } = props;
  const { viewAsset, setEditAsset } = useContext(AssetsContext);
  const { can } = useAccess();
  const canEditAsset = can(PERMISSIONS.settings.assets.any);


  const assetViewData: TAssetViewData = viewAsset;

  const { t } = useTranslation(['common', 'assets', 'fields', 'job']);

  const [loading, setLoading] = useState<boolean>(
    !!(assetViewData && assetViewData.assetType) || false,
  );

  useEffect(() => {
    if (assetViewData && typeof assetViewData.assetType === 'undefined') {
      setLoading(true);

      return;
    }

    setLoading(false);
  }, [assetViewData]);

  function formatDate(date: string) {
    if (!date) return null;
    const momentDate = moment(date);

    const formattedDate = momentDate.format('ll');

    return formattedDate;
  }

  const assetTypeItems = [
    {
      key: 'asset-type-asset-type-description',
      label: (
        <DescriptionText type="label">{t('common:Asset Type')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-brand-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Brand/Manufacturer')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.brand?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-model-description',
      label: (
        <DescriptionText type="label">{t('assets:Model')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.modelName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-job-item-description',
      label: (
        <DescriptionText type="label">{t('common:Job Item')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.jobItem?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-service-cycle-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Service Cycle')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.serviceCycle?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-asset-life-description',
      label: (
        <DescriptionText type="label">{t('assets:Asset Life')}</DescriptionText>
      ),
      children: (
        <DescriptionText emptyText={t('fields:None')}>
          {assetViewData?.assetType?.lifeCycle?.number}{' '}
          {assetViewData?.assetType?.lifeCycle?.lifeCyclePeriodType}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-description-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Description')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText emptyText={t('fields:None')}>
          {assetViewData?.assetType?.description}
        </DescriptionText>
      ),
      span: 2,
    },
    {
      key: 'asset-type-notes-description',
      label: (
        <DescriptionText type="label">{t('assets:Notes')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.note}
        </DescriptionText>
      ),
      span: 2,
    },
    {
      key: 'asset-type-attachments',
      label: (
        <DescriptionText type="label">
          {t('assets:Attachments')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.assetType?.documents && (
            <Attachments
              hideUploads
              values={{
                attachments:  assetViewData?.assetType?.documents.map(
                  (document: TAssetViewDataAttachment) => document.uuid,
                ),
              }}
            />
          )}
        </DescriptionText>
      ),
      span: 2,
    },
  ];

  const assetItems = [
    {
      key: 'asset-location-description',
      label: (
        <DescriptionText type="label">{t('assets:Location')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.location?.shortDisplayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-serial-number-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Serial Number')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.serialNumber}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-installation-date-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Installation Date')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {formatDate(assetViewData?.installationDate)}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-notes-description',
      label: (
        <DescriptionText type="label">{t('assets:Notes')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.notes}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-documents',
      label: (
        <DescriptionText type="label">
          {t('assets:Attachments')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {viewAsset?.documents?.length && (
            <Attachments
              hideUploads
              values={{
                attachments:  viewAsset?.documents?.map(
                  (document: TAssetViewDataAttachment) => document.uuid,
                ),
              }}
            />
          )}
        </DescriptionText>
      ),
      span: 2,
    },
  ];

  const purchaseItems = [
    {
      key: 'purchase-date-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Purchase Date')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {formatDate(assetViewData?.purchase?.date)}
        </DescriptionText>
      ),
    },
    {
      key: 'purchase-price-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Purchase Price')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.purchase?.price}
        </DescriptionText>
      ),
    },
    {
      key: 'purchase-order-number-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Purchase Order')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.purchase?.orderNumber}
        </DescriptionText>
      ),
    },
    {
      key: 'purchase-invoice-number-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Invoice Number')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.purchase?.invoiceNumber}
        </DescriptionText>
      ),
    },
  ];

  const vendorItems = [
    {
      key: 'vendor-displayName-description',
      label: (
        <DescriptionText type="label">{t('assets:Vendor')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.vendor?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'vendor-website-description',
      label: (
        <DescriptionText type="label">{t('assets:Website')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.vendor?.website && (
            <Typography.Link
              href={assetViewData?.vendor?.website}
              target="_blank"
            >
              {assetViewData?.vendor?.website}
            </Typography.Link>
          )}
        </DescriptionText>
      ),
    },
    {
      key: 'vendor-address-description',
      label: (
        <DescriptionText type="label">{t('assets:Address')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.vendor?.address}
        </DescriptionText>
      ),
    },
    {
      key: 'vendor-phone-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Phone Number')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.vendor?.phone && (
            <Typography.Link href={`tel:${assetViewData?.vendor?.phone}`}>
              {assetViewData?.vendor?.phone}
            </Typography.Link>
          )}
        </DescriptionText>
      ),
      span: 2,
    },
  ];

  const warrantyItems = [
    {
      key: 'warranty-coverage-description',
      label: (
        <DescriptionText type="label">{t('assets:Coverage')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.warranty?.number}{' '}
          {assetViewData?.warranty?.warrantyPeriodType}
        </DescriptionText>
      ),
      span: 2,
    },
    {
      key: 'warranty-description-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Description')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetViewData?.warranty?.description}
        </DescriptionText>
      ),
      span: 2,
    },
  ];

  const items = [
    {
      key: 'asset-view-panel-0',
      label: t('assets:Asset Type'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col>
            <Descriptions
              items={assetTypeItems.filter((item) => !!item) || []}
              colon={false}
              column={TAB_COLUMNS}
              contentStyle={STYLES.descriptionContent}
              style={STYLES.descriptions}
              layout="vertical"
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
    {
      key: 'asset-view-panel-1',
      label: t('assets:Asset'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col>
            <Descriptions
              items={assetItems.filter((item) => !!item) || []}
              colon={false}
              column={TAB_COLUMNS}
              contentStyle={STYLES.descriptionContent}
              style={STYLES.descriptions}
              layout="vertical"
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
    {
      key: 'asset-view-panel-2',
      label: t('assets:Purchase'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col>
            <Descriptions
              items={purchaseItems.filter((item) => !!item) || []}
              colon={false}
              column={TAB_COLUMNS}
              contentStyle={STYLES.descriptionContent}
              style={STYLES.descriptions}
              layout="vertical"
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
    {
      key: 'asset-view-panel-3',
      label: t('assets:Vendor'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col>
            <Descriptions
              items={vendorItems.filter((item) => !!item) || []}
              colon={false}
              column={TAB_COLUMNS}
              contentStyle={STYLES.descriptionContent}
              style={STYLES.descriptions}
              layout="vertical"
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
    {
      key: 'asset-view-panel-4',
      label: t('assets:Warranty'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col>
            <Descriptions
              items={warrantyItems.filter((item) => !!item) || []}
              colon={false}
              column={TAB_COLUMNS}
              contentStyle={STYLES.descriptionContent}
              style={STYLES.descriptions}
              layout="vertical"
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
    {
      key: 'asset-view-panel-5',
      label: t('job:Job Activity'),
      children: (
        <Row style={STYLES.itemChildren}>
          <Col style={{ minWidth: '100%' }}>
            <AssetViewJobActivity
              onJobEdit={() => {
                if (typeof handleHide === 'function') {
                  handleHide();
                }
              }}
              onJobView={() => {
                if (typeof handleHide === 'function') {
                  handleHide();
                }
              }}
            />
          </Col>
        </Row>
      ),
      style: STYLES.item,
    },
  ];

  const handleEditAsset = () => {
    handleClose();
    setTimeout(() => {
      setEditAsset(assetViewData);
    }, 500);
  };

  return (
    <Drawer
      destroyOnClose
      width={675}
      bodyStyle={STYLES.drawerBody}
      onClose={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      open={open}
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          <Typography.Title
            level={3}
            style={{
              marginTop: SPACING.NONE,
              fontFamily: 'Montserrat',
            }}
          >
            {loading ? (
              <Skeleton.Input block active style={{ height: '20px' }} />
            ) : (
              assetViewData &&
              `${assetViewData?.assetType?.displayName} / ${assetViewData?.location?.shortDisplayName}`
            )}
          </Typography.Title>
          <HeaderActions
            onEdit={canEditAsset ? handleEditAsset : undefined}
            onClose={() => handleClose()}
          />
        </Row>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={12}
          style={{
            paddingRight: SPACING.SIZE_XL,
          }}
        >
          <Button type="text" onClick={() => handleClose()}>
            {t('Cancel')}
          </Button>
        </Row>
      }
    >
      {loading ? (
        <AssetViewSkeleton />
      ) : (
        <Collapse
          bordered={false}
          size="small"
          defaultActiveKey={items
            .filter((_, i) => i !== 0)
            .map((item) => item.key)}
          style={STYLES.collapse}
          expandIcon={CollapseIcon}
        >
          {items.length &&
            items.map((item) => (
              <CollapsePanel
                key={item.key}
                style={STYLES.panel}
                header={
                  <Row>
                    <Col style={STYLES.header}>{item.label}</Col>
                  </Row>
                }
              >
                {item.children}
              </CollapsePanel>
            ))}
        </Collapse>
      )}
    </Drawer>
  );
}
