import {
  Collapse as AntCollapse,
  CollapsePanelProps,
  CollapseProps,
  ConfigProvider,
  ThemeConfig,
} from 'antd';

type Props = CollapseProps & {
  theme?: ThemeConfig;
};

export function Collapse(props: Props) {
  const { theme, ghost = true, expandIconPosition = 'end', ...rest } = props;
  return (
    <ConfigProvider>
      <AntCollapse
        expandIconPosition={expandIconPosition}
        ghost={ghost}
        {...rest}
      />
    </ConfigProvider>
  );
}

export function CollapsePanel(props: CollapsePanelProps) {
  return <AntCollapse.Panel {...props} />;
}
