import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import ConfirmButton from '@optii/shared/components/atoms/PrimaryButton';
import Confirmation from '@optii/shared/components/organisms/Confirmation';

const Code = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.colors.teal};
  ${(props) => props.theme.fonts.type.secondary};
  ${(props) => props.theme.fonts.sizes.gigantic};
  ${(props) => props.theme.fonts.weights.weak};
`;

function TableConfirmation(props) {
  const { disabled, title, hide, confirm, firstName, orgName, code } = props;

  const { t } = useTranslation(['common', 'home']);
  return (
    <Confirmation
      disabled={disabled}
      action={t('common:Continue')}
      title={title}
      hide={hide}
      confirm={confirm}
      button={ConfirmButton}
      cancel // it hides default cancel btn
    >
      <p>
        {t('home:Congrats')}, {firstName}, {t('home:you have been added to')}{' '}
        {orgName}.{' '}
        {t(
          'home:If you have any questions do not hesitate to reach out to us for assistance at',
        )}{' '}
        <a href="mailto:help@optiisolutions.com">help@optiisolutions.com</a>.
      </p>

      <Code>{code}</Code>
    </Confirmation>
  );
}
export default TableConfirmation;
