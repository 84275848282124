import { gql } from "@apollo/client";

export const GET_JOBS_SETTINGS = gql`
  query GetJobsSettings {
    jobsSettings: GetJobsSettings {
      guestRequestMinutes
      internalRequestMinutes
      defaultDepartmentId
    }
  }
`;
