import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Delete from './Delete';
import Edit from './Edit';
import Copy from './Copy';
import Arrow from './Arrow';
import Search from './Search';
import Exit from './Exit';
import Cross from './Cross';
import Plus from './Plus';
import Invite from './Invite';
import Low from './Priority/Low';
import Medium from './Priority/Medium';
import High from './Priority/High';
import Highest from './Priority/Highest';
import LowOnHold from './Priority/LowOnHold';
import MediumOnHold from './Priority/MediumOnHold';
import HighOnHold from './Priority/HighOnHold';
import HighestOnHold from './Priority/HighestOnHold';
import Cancelled from './Priority/Cancelled';
import Download from './Download';
import Info from './Info';
import InfoCircle from './InfoCircle';
import File from './File';
import ClockRepeating from './ClockRepeating';
import TrashCan from './TrashCan';
import TrashDelete from './TrashDelete.ts';

const MODIFIER_CONFIG = {
  right: () => `
    & :last-child {
      margin-right: 0;
    }
  `,
  alignCenter: () => `
    align-items: center;
  `,
};

const Icons = styled.div`
  display: flex;
  margin-left: auto;
  & > * {
    margin: 0 0.7rem;
    cursor: pointer;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

Icons.Delete = Delete;
Icons.Edit = Edit;
Icons.Copy = Copy;
Icons.Arrow = Arrow;
Icons.Search = Search;
Icons.Exit = Exit;
Icons.Cross = Cross;
Icons.Plus = Plus;
Icons.Invite = Invite;
Icons.File = File;
Icons.TrashCan = TrashCan;
Icons.TrashDelete = TrashDelete;
Icons.InfoCircle = InfoCircle;

Icons.Priority = {
  Low,
  Medium,
  High,
  Highest,
  Cancelled,
  LowOnHold,
  MediumOnHold,
  HighOnHold,
  HighestOnHold,
};

Icons.Low = Low;
Icons.Medium = Medium;
Icons.High = High;
Icons.Highest = Highest;
Icons.Download = Download;
Icons.Info = Info;
Icons.ClockRepeating = ClockRepeating;
export default Icons;
