import { gql } from "@apollo/client";

export default gql`
  query AssetTypes(
    $cursor: String
    $first: Int
    $Sorts: String
    $filters: String
  ) {
    page: assetTypes(
      first: $first
      Sorts: $Sorts
      filters: $filters
      after: $cursor
    ) {
      totalCount
      edges {
        node {
          ... on AssetType {
            displayName
            id
            modelName
            description
            note
            status
            isDeleted
            jobItem {
              displayName
              id
            }
            documents {
              uuid
            }
            serviceCycle {
              type
              value
              displayName
            }
            lifeCycle {
              number
              lifeCyclePeriodType
            }
            brand {
              displayName
            }
            version
            assetCount
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
