import { gql } from '@apollo/client';

export default gql`
  mutation registerDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      userId
      deviceId
    }
  }
`;
