import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Icons from 'blocks/Icons';

const MODIFIER_CONFIG = {
  desktop: () => `
    position: absolute;
    top: 1.3rem;
    left: 1rem;
  `,
  medium: (props) => `
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    @media (max-width: ${props.theme.breakpoints.sm}) {
      top: 1.2rem;
      left: 2rem;
    }
  `,
  mobile: () => `
    position: absolute;
    top: 2.5rem;
    left: 2.5rem;
  `,
};

const Icon = styled(Icons.Search)`
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Icon;
