import { gql } from "@apollo/client";

export default gql`
  query GetImportById($id: ID!) {
    import: GetImportById(id: $id) {
      id
      fileName
      type
      status
      processed
      lastProcessed
      description
      messages
      errors
      createdAt
    }
  }
`;
