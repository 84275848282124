import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import Transition from '@optii/shared/components/atoms/Transition';

import { ReactComponent as Close } from '@optii/shared/images/svg/close.svg';

const MODIFIERS = {
  error: ({ theme }) => `
    background-color: ${theme.colors.rose}
    svg g { fill: ${theme.colors.white}}
  `,
};
const Toast = styled.div`
  background-color: #4a4a4a;
  border-radius: 8px;
  display: flex;
  margin: 2rem auto auto auto;
  padding: 1.2rem;
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.strong}
  p {
    text-align: center;
    margin: 0;
    margin-right: 1rem;
  }
  svg {
    margin: 0.25rem 0.25rem 0 auto;
  }
  ${applyStyleModifiers(MODIFIERS)};
`;
export default function SnackBar({ show, message, modifiers }) {
  const el = document.getElementById('toast-root');
  const [innerShow, setInnerShow] = useState(show);
  // To get the exit animation correctly
  useEffect(() => {
    setInnerShow(show);
  }, [show, setInnerShow]);

  const transitions = {
    from: { transform: 'translate3d(0,200px,0)' },
    enter: { transform: 'translate3d(0,0,0)' },
    leave: { transform: 'translate3d(0,200px,0)' },
  };
  return ReactDOM.createPortal(
    <Transition show={innerShow} transitions={transitions}>
      {(_show) =>
        _show && (
          <Toast modifiers={modifiers} data-testid="snack-bar">
            <p>{message}</p>
            <Close onClick={() => setInnerShow(false)} />
          </Toast>
        )
      }
    </Transition>,
    el,
  );
}
