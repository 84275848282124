import { gql } from "@apollo/client";

export default gql`
  mutation updateRepeatingJob(
    $updateRepeatingJobInput: UpdateRepeatingJobInput!
  ) {
    updateRepeatingJob(updateRepeatingJobInput: $updateRepeatingJobInput) {
      id
      displayName
    }
  }
`;
