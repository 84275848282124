import {
  FormItem,
  Select,
  type FormListFieldData,
  Col,
  Row,
  Input,
} from '@optii-solutions/ui-library';
import { Operator } from '@optii/escalations/types/notifications';

import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  filterField: FormListFieldData;
  metadata: { [key: string]: any };
};

export function TemperatureTaskCriteriaFormList({
  filterField,
  metadata,
}: Props) {
  const { t } = useTranslation(['common']);
  return (
    <div style={{ width: '100%' }}>
      <Row>
        <Col sm={6}>
          <FormItem
            label={t('common: Acceptable Range')}
            name={[filterField.name, 'DataTemperatureMin']}
            normalize={(value) => ({
              property: 'DataTemperatureValue',
              operator: Operator.OutsideRange,
              values: [value],
            })}
            getValueProps={(normalizedValue) => ({
              value: normalizedValue?.values[0],
            })}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(
                      new Error(
                        t('common:Enter the minimum temperature value.'),
                      ),
                    );
                  return Promise.resolve(rule);
                },
              },
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  const DataTemperatureMax = getFieldValue([
                    'userEventActionRules',
                    0,
                    'event',
                    'filters',
                    filterField.name,
                    'DataTemperatureMax',
                  ]);

                  if (!DataTemperatureMax?.values) return Promise.resolve(rule);

                  const [max] = DataTemperatureMax.values;
                  const [min] = value.values;

                  if (max && (Number(min) > Number(max)))
                    return Promise.reject(
                      new Error(
                        t('common:Min value cannot be greater than Max value.'),
                      ),
                    );
                  return Promise.resolve(rule);
                },
              }),
            ]}
            required
          >
            <Input placeholder={t('fields:min')} type="number" />
          </FormItem>
        </Col>
        <Col
          sm={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          -
        </Col>
        <Col sm={6}>
          <FormItem
            label="&nbsp;"
            name={[filterField.name, 'DataTemperatureMax']}
            normalize={(value) => ({
              property: 'DataTemperatureValue',
              operator: Operator.OutsideRange,
              values: [value],
            })}
            getValueProps={(normalizedValue) => ({
              value: normalizedValue?.values[0],
            })}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(
                      new Error(
                        t('common:Enter the maximum temperature value.'),
                      ),
                    );
                  return Promise.resolve(rule);
                },
              },
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  const minField = [
                    'userEventActionRules',
                    0,
                    'event',
                    'filters',
                    filterField.name,
                    'DataTemperatureMin',
                  ];
                  const DataTemperatureMin = getFieldValue(minField);

                  if (!DataTemperatureMin?.values) return Promise.resolve(rule);

                  const [min] = DataTemperatureMin.values;
                  const [max] = value.values;

                  if (max && (Number(max) < Number(min)))
                    return Promise.reject(
                      new Error(
                        t('common:Max value cannot be less than Min value.'),
                      ),
                    );
                
                  return Promise.resolve(rule);
                },
              }),
            ]}
            required
          >
            <Input placeholder={t('fields:max')} type="number" />
          </FormItem>
        </Col>
        <Col sm={10} span={2}>
          <FormItem label="&nbsp;" style={{ paddingLeft: '10px' }}>
            <Select
              placeholder={t('common:Scale')}
              allowClear
              options={[
                { label: '°F', value: 'fahrenheit' },
                { label: '°C', value: 'celcius' },
              ]}
              optionFilterProp="label"
              defaultValue={
                metadata?.formValues?.dataTemperatureScale ?? 'celcius'
              }
              disabled
            />
          </FormItem>
        </Col>
      </Row>
    </div>
  );
}

export default TemperatureTaskCriteriaFormList;
