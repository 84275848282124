import { gql } from "@apollo/client";

export default gql`
  mutation deleteMultiEntries($ids: [ID!]!) {
    deleteMultiEntries(ids: $ids) {
      id
      status
    }
  }
`;
