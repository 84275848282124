import { useEffect, useContext } from "react";
import { PropertyContext, UserAccessContext } from '@optii/shared';
import ReactGA from "utils/analytics/GoogleAnalyticsClient";
import { useHistory } from "react-router-dom";

export default function useAnalytics() {
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const history = useHistory();
  useEffect(
    _ => {
      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.send({hitType: "pageview", page: location.pathname, title: location.pathname}); // Record a pageview for the given page
      });
    },
    [history]
  );
  useEffect(
    _ => {
      if (user && !property) {
        ReactGA.set({
          userId: user.id
        });
      }
      if (user && property) {
        ReactGA.set({
          userId: user.id,
          dimension1: property.id,
          dimension2: property.displayName,
          dimension3: property.orgName
        });
      }
    },
    [user, property]
  );
}
