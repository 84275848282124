import Icon from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Select,
  Space,
  ThemeConfig,
} from 'antd';
import { Filter } from '../../icons/jobs';
import { ReactNode, useState } from 'react';

export interface FiltersDropdownProps {
  i18n: {
    clearAll: string;
    dropdowns: {
      [key: string]: {
        placeholder: string;
        multiple?: boolean;
        selected?: string;
        options: { value: string; label: string }[];
        values?: string[];
        dataTestId?: string;
      };
    };
  };
  footer?: ReactNode;
  theme?: ThemeConfig;
  onChange: (output: OutputObject) => void;
}

interface OutputObject {
  [key: string]: string[] | null;
}

function generateOutput(keys: string[], values: any) {
  const obj: OutputObject = {};

  for (let i = 0; i < keys.length; i++) {
    obj[keys[i] as keyof typeof obj] = null;
    obj[keys[i]] = values[i];
  }
  return obj;
}

function FiltersDropdownSelect({
  i18n,
  clearAllLabel,
  onChange,
}: {
  i18n: FiltersDropdownProps['i18n']['dropdowns'][0];
  clearAllLabel: string;
  onChange: (values: string[] | null) => void;
}) {
  const [values, setValues] = useState(i18n.values || []);
  const [open, setOpen] = useState(false);
  return (
    <Select
      showSearch
      value={values}
      mode={i18n.multiple ? 'multiple' : undefined}
      style={{ minWidth: '175px' }}
      placeholder={i18n.placeholder}
      options={i18n.options}
      maxTagCount={0}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      maxTagPlaceholder={() => (
        <span>{`${i18n.selected} (${values.length})`}</span>
      )}
      onInputKeyDown={(e) => {
        if (e.key === 'Backspace') {
          return e.stopPropagation();
        }
      }}
      optionFilterProp="label"
      onChange={(value) => {
        setValues(value);
        onChange(value);
      }}
      className={i18n.dataTestId}
      data-testid={i18n.dataTestId}
      dropdownRender={(children) => (
        <>
          {values.length > 0 && i18n.multiple && (
            <Space
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="link"
                onClick={() => {
                  setValues([]);
                  onChange([]);
                  setOpen(false);
                }}
              >
                {clearAllLabel}
              </Button>
            </Space>
          )}

          {children}
        </>
      )}
    />
  );
}

export function FiltersDropdown({
  theme = {
    components: {
      Select: {
        colorBgContainer: '#F0F0F0',
        multipleItemBg: undefined,
        colorTextPlaceholder: '#5C5C5C',
      },
    },
  },
  i18n,
  onChange,
  footer,
}: FiltersDropdownProps) {
  const keys = Object.keys(i18n.dropdowns);
  const values = Object.values(i18n.dropdowns).map((item) => item.values);

  const filters = generateOutput(keys, values);

  return (
    <ConfigProvider theme={theme}>
      <style>
        {`
          .ui-library-filters-dropddown .ant-select-selection-placeholder, 
          .ui-library-filters-dropddown .ant-select-selection-item-content, 
          .ui-library-filters-dropddown .ant-select-selection-item {
            font-weight: 500;
            font-size: 15px;
        }
        `}
      </style>
      <Card
        className="ui-library-filters-dropddown"
        size="small"
        bordered={false}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between" gutter={[16, 8]}>
            <Col>
              <Icon component={Filter} />
            </Col>
            {keys.map((key) => (
              <Col key={key}>
                <FiltersDropdownSelect
                  i18n={i18n.dropdowns[key]}
                  clearAllLabel={i18n.clearAll}
                  onChange={(value) => {
                    if (!value) return;
                    if (!Array.isArray(value)) value = [value];
                    filters[key] = value.length > 0 ? value : null;

                    onChange(filters);
                  }}
                />
              </Col>
            ))}
          </Row>
          {footer}
        </Space>
      </Card>
    </ConfigProvider>
  );
}
