import { gql } from "@apollo/client";

export const CHECKLIST_TEMPLATES = gql`
  query ChecklistTemplates(
    $first: Int
    $cursor: String
    $orderBy: String
    $filters: String
  ) {
    page: checklistTemplates(
      first: $first
      after: $cursor
      orderBy: $orderBy
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on ChecklistTemplate {
            archivedAt
            createdAt
            id
            tasks {
              attachment
              checklistTemplateId
              createdAt
              id
              label
              taskType
              updatedAt
              required
              ordering_value
              parent_task_id
              note: notes
            }
            creator {
              firstName
              lastName
            }
            name
            description
            updatedAt
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
