import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Label from './Label';
import Input from './Input';
import FormikInput from './FormikInput';

const sharedLeftPadding = '1.32rem';
const CONTAINER_MODIFIER_CONFIG = {
  noMargin: () => `
      min-height: auto;
      height: auto;
      margin-top: 0.5rem;
    `,
  currency: ({ theme }) => `
      &:before {
        position: absolute;
        content: "$";
        left: 1.3rem;
        top: 1.2rem;
        color: ${theme.colors.charcoal05};
      }
      & input {
        padding-left: 2.2rem;
      }
    `,
  paddingLeft: () => `
      padding-left: 2rem;
      & > span:first-child { 
        left: 0;
      }
    `,
  alignStart: () => `
      margin-left: -${sharedLeftPadding}
    `,
  noTopMargin: () => `
    margin-top: 0;
  `,
};
const Field = styled.div`
  ${(props) => props.theme.fonts.type.primary};
  position: relative;
  margin: 1.6em 0 0;
  ${(props) => !props.isGreyFilterWithArrows && 'width: 100%;'}
  /* Handle many posible height/min-height conditions */
  ${(props) => {
    if (props.isMulti && !props.readOnly) {
      return `
        height: 100%;
        min-height:44px;
        `;
    }
    if (props.type === 'checkbox') {
      return `
            height: 100%;
            min-height: 100%;
        `;
    }
    if (props.type === 'textarea') {
      return `
            height: 100%;
            min-height: 44px;
        `;
    }
    if (props.type === 'treeSelect') {
      return `
            height: 100%;
            min-height: 44px;
            .ant-select-selection-overflow {
              overflow: hidden !important;
            }
        `;
    }
    if (props.wideValue) {
      return `
            height: auto;
            min-height: 44px;
        `;
    }
    if (props.topLabel) {
      return `
              min-height: 44px;
      `;
    }
    return `
            height: 44px;
            min-height: 44px;
        `;
  }}

  ${applyStyleModifiers(CONTAINER_MODIFIER_CONFIG)};
`;
Field.Label = Label;
Field.Input = Input;
Field.FormikInput = FormikInput;

export { Label, Input, FormikInput };
export default Field;
