import styled, { type StyledComponent } from 'styled-components';
import type { TagProps } from 'antd';
import { forwardRef, type CSSProperties, type FunctionComponent } from 'react';

import {
  COLORS,
  Card,
  FONTS,
  RADIUS,
  SPACING,
  Tag,
  ThemeConfig,
} from '@optii-solutions/ui-library';

import type {
  TJobCardCustomStyles,
  TJobCardStyles,
} from './JobStatusCard.types';

export const JOB_CARD_THEME: ThemeConfig = {
  components: {
    Typography: {
      colorTextHeading: COLORS.neutral[8],
      colorText: COLORS.neutral[8],
      fontSizeHeading5: FONTS.h5.size,
    },
    Card: {
      padding: SPACING.SIZE_MD,
      paddingLG: SPACING.SIZE_MD,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorderSecondary: COLORS.neutral[5],
      headerHeight: 46,
      headerFontSize: FONTS.h5.size,
      headerFontSizeSM: FONTS.h5.size,
    },
    Tag: {
      defaultColor: COLORS.neutral[8],
      defaultBg: COLORS.neutral[3],
      colorBorder: COLORS.neutral[5],
      fontSizeSM: FONTS.small.size,
    },
  },
};

export const JOB_CARD_STYLES: TJobCardStyles = {
  root: {
    minWidth: '100%',
  },
  title: {
    margin: SPACING.NONE,
    fontSize: 21,
    color: COLORS.cyan[7],
  },
  titleLink: {
    margin: SPACING.NONE,
    fontSize: 21,
    color: COLORS.cyan[7],
    cursor: 'pointer',
  },
  icons: {
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: COLORS.neutral[4],
    marginBottom: SPACING.SIZE_SM,
    paddingBottom: SPACING.SIZE_SM,
    gap: SPACING.SIZE_SM,
    display: 'flex',
    alignItems: 'space-between',
  },
  locations: {
    display: 'flex',
    gap: SPACING.SIZE_SM,
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  locationsTag: {
    padding: SPACING.SIZE_XS,
    margin: SPACING.NONE,
    fontWeight: 500,
    fontSize: FONTS.large.size,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  locationsTagDisabled: {
    padding: SPACING.SIZE_XS,
    margin: SPACING.NONE,
    fontWeight: 500,
    fontSize: FONTS.large.size,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  checklists: {
    display: 'flex',
    margin: SPACING.NONE,
    gap: SPACING.SIZE_SM,
    alignItems: 'center',
  },
  checklistsCompleted: {
    fontWeight: 500,
  },
  checklistsRequired: {
    fontWeight: 500,
    color: COLORS.red[7],
  },
  asset: {
    display: 'flex',
    margin: SPACING.NONE,
    gap: SPACING.SIZE_SM,
    alignItems: 'center',
  },
  assetLabel: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  assetLink: {
    color: COLORS.cyan[7],
    fontWeight: 500,
  },
  notes: {
    margin: SPACING.NONE,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  time: {
    display: 'flex',
    margin: SPACING.NONE,
    alignItems: 'flex-start',
    gap: SPACING.SIZE_SM,
    flexDirection: 'row',
  },
  overDueTime: {
    display: 'flex',
    margin: SPACING.NONE,
    alignItems: 'flex-start',
    gap: SPACING.SIZE_SM,
    flexDirection: 'row',
    color: COLORS.red[7],
  },
  smallTime: {
    display: 'flex',
    margin: SPACING.NONE,
    alignItems: 'center',
    gap: SPACING.SIZE_SM,
    flexDirection: 'column',
  },
  timeLabel: {
    fontWeight: 500,
    minWidth: 'unset',
  },
  smallTimeLabel: {
    fontWeight: 500,
    minWidth: '100%',
  },
  assignment: {
    margin: SPACING.NONE,
    display: 'flex',
    gap: SPACING.SIZE_SM,
  },
  assignmentDepartment: {
    fontWeight: 500,
  },
  assignmentEmployee: {
    color: COLORS.neutral[6],
  },
  roomType: {
    margin: SPACING.NONE,
  },
  creditsAndRoomType: {
    margin: SPACING.NONE,
  },
  editButton: {
    border: '0 none',
    padding: SPACING.NONE,
    paddingLeft: SPACING.SIZE_XS,
    height: '24px',
  },
  toggleButton: {
    border: '0 none',
    padding: SPACING.NONE,
    paddingLeft: SPACING.SIZE_XS,
    height: '24px',
    marginTop: '-2px',
  },
};

export function JobStatusCardCustomStyles(): TJobCardCustomStyles<
  typeof JOB_CARD_STYLES
> {
  return JOB_CARD_STYLES;
}

export const CustomCardTop: StyledComponent<'div', { color: string }> =
  styled.div`
    display: block;
    background-color: ${(props: CSSProperties) => props.color};
    height: 8px;
    min-width: calc(100% + 2px);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid ${(props: CSSProperties) => props.color};
    position: absolute;
    left: -1px;
    top: -1px;
  `;

export const StyledCustomCard: FunctionComponent<any> = forwardRef(
  (props, ref) => <Card ref={ref} {...props} />,
);

export const CustomCard = styled(StyledCustomCard)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .ant-card-body {
    padding-top: ${SPACING.SIZE_MD}px;
  }

  &.small {
    max-width: 32rem;
    min-width: 32rem;
    padding: 0px;
    position: relative;

    &.housekeeping-job {
      padding-top: ${SPACING.SIZE_XS}px;
    }

    .job-status-card-header {
      max-height: 28px;
      min-height: 28px;
    }

    .ant-card-body {
      padding: ${SPACING.SIZE_SM}px;
    }

    h4 {
      font-size: ${FONTS.large.size}px !important;
    }
  }
`;

export const CustomTag: FunctionComponent<TagProps> = styled(Tag)`
  padding: ${SPACING.SIZE_XS}px;
  margin: ${SPACING.NONE}px;
  font-weight: 500;
  font-size: ${FONTS.large.size}px;
  cursor: pointer;
  max-width: 100%;

  &.disabled {
    color: ${COLORS.neutral[3]};
    cursor: unset;
  }

  > input[type='button'] {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    cursor: inherit;
  }
`;

export const JobCardStatusContainer: FunctionComponent<
  TagProps & {
    isSmall?: boolean;
  }
> = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${(props) =>
    'isSmall' in props && props.isSmall ? 14 : SPACING.SIZE_MD}px;
  margin-top: ${(props) => ('isSmall' in props && props.isSmall ? -2 : 0)}px;
  margin-right: ${SPACING.NONE};
  gap: ${SPACING.SIZE_XS}px;
  min-height: ${(props) =>
    'isSmall' in props && props.isSmall ? SPACING.SIZE_XL : SPACING.SIZE_XXL}px;
  max-height: ${(props) =>
    'isSmall' in props && props.isSmall ? SPACING.SIZE_XL : SPACING.SIZE_XXL}px;

  > span {
    font-size: ${(props) =>
      'isSmall' in props && props.isSmall ? 14 : SPACING.SIZE_MD}px;
  }

  &.inProgress {
    background: ${COLORS.polarGreen[1]};
    border: ${COLORS.polarGreen[6]} 1px solid;

    > span {
      color: ${COLORS.polarGreen[7]};
    }
  }

  &.onHold {
    background: #ffffb8;
    border: #fff566 1px solid;

    > span {
      color: #614700;
    }
  }

  &.done {
    background: ${COLORS.polarGreen[1]};
    border: ${COLORS.polarGreen[6]} 1px solid;

    > span {
      color: ${COLORS.polarGreen[7]};
    }
  }

  &.cancelled {
    background: ${COLORS.neutral[1]};
    border: ${COLORS.neutral[6]} 1px solid;

    > span {
      color: ${COLORS.neutral[7]};
    }
  }
`;
