import React from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Col,
  ConfigProvider,
  FormItem,
  FormListFieldData,
  Icon,
  Row,
  SPACING,
  Select,
  Space,
  Tag,
} from '@optii-solutions/ui-library';
import { JOB_STATUS, PRIORITY_ICONS } from '@optii/shared';
import { useTranslation } from 'react-i18next';
import {
  Actions,
  Operator,
  Priorities,
  SelectOption,
  Statuses,
} from '../../../types/notifications';

import { useNotificationContext } from '../../context';

type Props = {
  filterField: FormListFieldData;
};

const PRIORITY_OPTIONS: SelectOption[] = Object.keys(Priorities).map(
  (priority) => ({
    label: priority.charAt(0).toUpperCase() + priority.slice(1),
    value: Priorities[priority as keyof typeof Priorities],
  }),
);

const ACTION_OPTIONS: SelectOption[] = Object.keys(Actions).map((action) => ({
  label:
    action === Actions.noAction
      ? 'No Job Action'
      : action.charAt(0).toUpperCase() + action.slice(1),
  value: Actions[action as keyof typeof Actions],
}));

const STATUS_OPTIONS: SelectOption[] = Object.keys(Statuses)
  .filter((item) => item !== Statuses.pending)
  .map((status) => ({
    label:
      status === Statuses.completed
        ? 'Done'
        : status.charAt(0).toUpperCase() + status.replace('_', ' ').slice(1),
    value:
      Statuses[status as keyof typeof Statuses] === Statuses.not_started
        ? Statuses.pending 
        : Statuses[status as keyof typeof Statuses],
  }));

function SelectTagRender(props: any) {
  const { label, value } = props;
  const ICON = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];
  return (
    <Tag
      {...props}
      bordered={false}
      style={{
        alignItems: 'center',
        display: 'flex',
        marginInlineEnd: SPACING.SIZE_XXS,
      }}
    >
      <Icon component={ICON} />
      <span>{label}</span>
    </Tag>
  );
}

function OptionRender(option: any) {
  const { value, label } = option;
  const ICON = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];
  return (
    <Space>
      <Icon component={ICON} />
      {label}
    </Space>
  );
}

export function JobCriteriaFormList({ filterField }: Props) {
  const { t } = useTranslation(['common']);
  const { roles, departments } = useNotificationContext();

  return (
    <>
      <FormItem
        label={t('common:is a')}
        name={[filterField.name, 'Type']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || value.values.length < 1) {
                return Promise.reject(
                  new Error('Select at least one request type.'),
                );
              }
              return Promise.resolve(rule);
            },
          },
        ]}
        normalize={(values) => ({
          property: 'Type',
          operator: Operator.In,
          values,
        })}
        getValueProps={(valueProps) => ({
          value: valueProps?.values,
        })}
        required
      >
        <CheckboxGroup style={{ width: '100%' }}>
          <Row>
            <Col sm={9} md={7} xs={12}>
              <Checkbox name="guest" value="guest">
                {t('common:Guest Request')}
              </Checkbox>
            </Col>
            <Col sm={9} md={7} xs={12}>
              <Checkbox name="internal" value="internal">
                {t('common:Internal Request')}
              </Checkbox>
            </Col>
          </Row>
        </CheckboxGroup>
      </FormItem>
      <FormItem
        label={t('common:and')}
        name={[filterField.name, 'Assignee']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || value.values.length < 1) {
                return Promise.reject(
                  new Error('Select at least one assignee status.'),
                );
              }
              return Promise.resolve(value);
            },
          },
        ]}
        normalize={(values) => ({
          property: 'Assignee',
          operator: Operator.NotIn, // Operator can't be empty, since Assignee is different, default the operator to any value and BFF will handle it.
          values,
        })}
        getValueProps={(valueProps) => ({
          value: valueProps?.values,
        })}
        required
      >
        <CheckboxGroup style={{ width: '100%' }}>
          <Row>
            <Col sm={9} md={7} xs={12}>
              <Checkbox name="assigned" value={Operator.NotNull}>
                {t('common:Assigned')}
              </Checkbox>
            </Col>
            <Col sm={9} md={7} xs={12}>
              <Checkbox name="unassigned" value={Operator.Null}>
                {t('common:Unassigned')}
              </Checkbox>
            </Col>
          </Row>
        </CheckboxGroup>
      </FormItem>
      <FormItem
        name={[filterField.name, 'Priority']}
        label={t('common:with Priority')}
        normalize={(values) => ({
          property: 'Priority',
          operator: Operator.In,
          values,
        })}
        getValueProps={(valueProps) => ({
          value: valueProps?.values,
        })}
      >
        <Select
          placeholder={t('common:All Priorities...')}
          options={PRIORITY_OPTIONS}
          tagRender={SelectTagRender}
          optionRender={OptionRender}
          allowClear
          mode="multiple"
        />
      </FormItem>
      <FormItem
        name={[filterField.name, 'Department']}
        label={t('common:assigned to')}
        normalize={(values) => ({
          property: 'Department',
          operator: Operator.In,
          values,
        })}
        getValueProps={(valueProps) => ({
          value: valueProps?.values.filter((value: string) =>
            departments.options
              .map((department) => department.value)
              .includes(value),
          ),
        })}
      >
        <Select
          placeholder={t('common:All Departments...')}
          options={departments.options}
          loading={departments.loading}
          allowClear
          showSearch
          mode="multiple"
          optionFilterProp="label"
        />
      </FormItem>

      <FormItem
        name={[filterField.name, 'Roles']}
        normalize={(values) => ({
          property: 'Roles',
          operator: Operator.In,
          values,
        })}
        getValueProps={(valueProps) => ({
          value: valueProps?.values.filter((value: string) =>
            roles.options.map((role) => role.value).includes(value),
          ),
        })}
      >
        <Select
          placeholder={t('common:All Roles...')}
          options={roles.options}
          loading={roles.loading}
          allowClear
          showSearch
          mode="multiple"
          optionFilterProp="label"
        />
      </FormItem>
      <FormItem
        name={[filterField.name, 'Action']}
        label={t('common:with action')}
        normalize={(values) => ({
          property: 'Action',
          operator: Operator.In,
          values,
        })}
        getValueProps={(valueProps) => ({ value: valueProps?.values })}
      >
        <Select
          placeholder={t('common:All Actions...')}
          options={ACTION_OPTIONS}
          allowClear
          mode="multiple"
        />
      </FormItem>

      <ConfigProvider
        theme={{
          components: {
            Form: {
              itemMarginBottom: SPACING.NONE,
            },
          },
        }}
      >
        <FormItem
          name={[filterField.name, 'Status']}
          label={t('common:with status')}
          normalize={(values) => ({
            property: 'Status',
            operator: Operator.In,
            values,
          })}
          getValueProps={(valueProps) => ({
            value: valueProps?.values,
          })}
        >
          <Select
            placeholder={t('common:All Statuses...')}
            options={STATUS_OPTIONS}
            allowClear
            mode="multiple"
          />
        </FormItem>
      </ConfigProvider>
    </>
  );
}
