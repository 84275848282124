import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CalendarIcon } from 'images/svg/calendar.svg';
import { ReactComponent as ClockIcon } from 'images/svg/clock.svg';
import Select from 'blocks/Select';
import useOutsideClick from 'utils/useOutsideClick';
import { applyStyleModifiers } from 'styled-components-modifiers';
import FieldBlock from 'blocks/Field';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateTimeClearIndicator from './DateTimeClearIndicator';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker.tsx';

const Error = styled.div`
  color: ${(props) => props.theme.colors.roseDark};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.weak}
`;

const Arrow = styled(Select.DropdownIndicator.Arrow)`
  margin-left: auto;
  margin-top: 0.5rem;
`;
const Clear = styled(DateTimeClearIndicator)`
  margin-right: 1rem;
  margin-top: 0.5rem;
`;

const InlineRightContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  margin-left: auto;
  position: relative;
  align-self: flex-end;
`;
const CONFIG = {
  alignStart: () => `
    margin-left: -1.32rem;
  `,
  readOnly: (props) => `
    border: none;
    cursor: auto;
  `,
  noTopMargin: () => `
    margin-top: 0;
  `,
};

const Field = styled.div`
  ${(props) => props.theme.borders.default}
  margin: 1.6em 0 0;
  padding: 1rem;
  height: 4.4rem;
  width: 100%;
  display: flex;
  border-radius: 6px;
  ${(props) => props.error && props.theme.borders.error}

  cursor: pointer;
  ${applyStyleModifiers(CONFIG)};
`;

const DisplayTime = styled.span`
  color: ${(props) => props.theme.colors.charcoal05};
  align-self: center;
  ${(props) => props.theme.fonts.weights.light};
  ${(props) =>
    props.readOnly &&
    `
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.8rem;
  `}
`;
const Calendar = styled(CalendarIcon)`
  margin-right: 1rem;
  ${(props) =>
    props.readOnly &&
    `
    margin-left:1.32rem
    margin-top:1rem
  `}
`;

const Wrapper = styled.div`
  max-width: 42rem;
  width: 100%;
  margin: 0.5rem auto;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.shadows.default};
  ${(props) => props.theme.borders.shadow};
  border-radius: 8px;
  position: absolute;
  ${(props) => props.theme.zIndex.highest};
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: calc(100vw - 6rem);
  }
`;

const Required = styled.span`
  color: #a10000;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: -3.5rem;
  top: 2.5rem;
`;

const Clock = styled(ClockIcon)`
  margin-right: 1rem;
`;

function DateCalendar(props) {
  const wrapperRef = useRef(null);
  const fieldRef = useRef(null);
  const {
    value,
    label,
    isClock,
    readOnly,
    setDate,
    before,
    createValue,
    dataTestId,
    isClearable,
    required,
    handleChange,
    error,
    modifiers,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['common']);

  // Is this a clock or a calendar?
  const Icon = isClock ? Clock : Calendar;
  const format = isClock ? 'LTS' : 'll';

  // Close if there is a click outside
  useOutsideClick([wrapperRef, fieldRef], (_) => {
    // race condition with the Field onClick handler
    setTimeout(() => setIsOpen(false), 0);
  });

  function readOnlyValue() {
    if (value === 'None') {
      return t('fields:None');
    }
    if (dayjs.isDayjs(value)) {
      return value.format(`ll [${t('common:at')}] LT`);
    }
    return value.format(`ll [${t('common:at')}] LTS`);
  }

  if (isClock) {
    return (
      <Field
        ref={fieldRef}
        error={error}
        data-testid={dataTestId}
        modifiers={modifiers}
      >
        <FieldBlock.Label modifiers="calendar">
          {label}
          {required && <Required>*</Required>}
        </FieldBlock.Label>

        <Icon title={t('common:Select Date')} />
        <TimePicker
          value={value}
          setDate={setDate}
          before={before}
          createValue={createValue}
          handleChange={handleChange}
          suffixIcon={<Arrow modifiers={['down', 'small']} />}
        />
      </Field>
    );
  }

  if (readOnly) {
    return (
      <FieldBlock
        ref={fieldRef}
        modifiers={['readOnly', 'alignStart']}
        onClick={(_) => setIsOpen((prev) => !prev)}
        data-testid={dataTestId}
      >
        <FieldBlock.Label modifiers={['calendar']}>{label}</FieldBlock.Label>
        <Icon readOnly title={t('common:Select Date')} />
        <DisplayTime data-testId="due-by-display" readOnly>
          {readOnlyValue()}
        </DisplayTime>
      </FieldBlock>
    );
  }
  return (
    <div>
      <Field
        ref={fieldRef}
        error={error}
        onClick={(_) => setIsOpen((prev) => !prev)}
        data-testid={dataTestId}
        modifiers={modifiers}
      >
        <FieldBlock.Label modifiers="calendar">
          {label}
          {required && <Required>*</Required>}
        </FieldBlock.Label>

        <Icon title={t('common:Select Date')} />

        <DisplayTime>{value && value.format(format)}</DisplayTime>
        {isClearable && value && value.format(format) ? (
          <InlineRightContainer>
            <Clear
              onClick={(evt) => {
                evt.stopPropagation();
                createValue(null);
              }}
            />
            <Arrow modifiers={['down', 'small']} />
          </InlineRightContainer>
        ) : (
          <Arrow modifiers={['down', 'small']} />
        )}
      </Field>
      {error && <Error>{error}</Error>}
      {isOpen && (
        <Wrapper ref={wrapperRef} data-testid="input-calendar">
          <DatePicker
            value={value}
            setDate={setDate}
            before={before}
            createValue={createValue}
            handleChange={handleChange}
          />
        </Wrapper>
      )}
    </div>
  );
}

export default DateCalendar;
