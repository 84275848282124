import { Meta, StoryObj } from '@storybook/react';
import {
  TopHorizontalMenu as Menu,
  TopVerticalMenu,
  TopVerticalMenu as VerticalMenu,
} from './Menus.component';

/**
 * Menus molecules for navigational purposes
 */

export default {
  title: 'UI-Library/Molecules/Menus',
  component: Menu,
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
} as Meta;

type Story = StoryObj<typeof Menu> | StoryObj<typeof VerticalMenu>;

/**
 * Molecule constructed using Ant Components Menu
 */
export const Default: Story = {
  args: {
    items: [
      { label: 'Home', key: 'home' },
      { label: 'Schedule', key: 'schedule' },
      { label: 'Jobs', key: 'jobs' },
      { label: 'Projects', key: 'projects' },
      { label: 'Chat', key: 'chat' },
      { label: 'Reports', key: 'reports' },
      { label: 'Settings', key: 'settings' },
    ],
  },
};
/**
 * This is an example of Menu being used for displaying a navigation menu inside pages
 */
export const RulesPageNavigation: Story = {
  args: {
    items: [
      { label: 'General Settings', key: 'general' },
      { label: 'Notifications', key: 'notifications' },
    ],
  },
};

/**
 * This is an example of Menu being used for displaying a navigation menu inside pages
 */
export const FloorPlanPageNavigation: Story = {
  args: {
    selected: ['locations'],
    items: [
      { label: 'Locations', key: 'locations' },
      { label: 'Location Types', key: 'location-types' },
      { label: 'Room Types', key: 'room-types' },
      { label: 'Import', key: 'import' },
    ],
  },
};

/**
 * This is an example of Menu being used for displaying a sider navigation
 */
export const RulesPageSider: Story = {
  render: (args: typeof VerticalMenu.arguments) => (
    <TopVerticalMenu {...args} />
  ),
  args: {
    sections: [
      {
        items: [
          { title: 'Users', key: 'users', href: '#users' },
          {
            title: 'Organizations',
            key: 'organizations',
            href: '#organizations',
          },
          { title: 'Departments', key: 'departments', href: '#departments' },
          { title: 'Employment Types', key: 'employment', href: '#employment' },
          { title: 'Roles & Permissions', key: 'roles', href: '#roles' },
          { title: 'Floor plan', key: 'floor-plan', href: '#floor-plan' },
          { title: 'Assets', key: 'assets', href: '#assets' },
        ],
        title: 'General',
      },
      {
        items: [
          { title: 'Checklists', key: 'checklists', href: '#checklists' },
          { title: 'Rules', key: 'rules', href: '#rules' },
          { title: 'Items', key: 'items', href: '#items' },
        ],
        title: 'Jobs',
      },
    ],
  },
};
