import translatedConstants from 'utils/constants/escalations';

import { getAction } from 'utils/formatters/jobs';

export function getDuration(duration) {
  const { durations } = translatedConstants;

  const singleDuration = durations.find((d) => d.id === duration);

  return (singleDuration && singleDuration.displayName) || 'None';
}

export function getOffset(offset) {
  const { offsetTypes } = translatedConstants;

  const singleOffsetType = offsetTypes.find((o) => o.id === offset);

  return (singleOffsetType && singleOffsetType.displayName) || 'None';
}

export function getSendToRoles(sendToRoles, roles) {
  return sendToRoles
    .map((ri) => roles.find((r) => r.id === ri).name)
    .join(', ');
}

export function getAssignType(assignType, t) {
  const { assignStatus } = translatedConstants;
  if (assignType === null || assignType.length === 2) {
    return `${assignStatus.assigned} ${t('common:or')} ${
      assignStatus.unassigned
    }`;
  }
  return assignType ? assignStatus.assigned : assignStatus.unassigned;
}

export function getEscalationLocations(locations) {
  if (typeof locations === 'string') return locations;

  return JSON.parse(locations)
    .map((loc) => loc.name)
    .join(', ');
}

export function getEscalationNotificationBody(notificationPayload, t) {
  if (notificationPayload.isAssigned === '') {
    return t(`common:{{jobName}} is {{duration}} {{beforeOrAfterDue}}.`, {
      jobName: `#${notificationPayload.jobId} ${getEscalationLocations(
        notificationPayload.location,
      )}: ${getAction(notificationPayload.action).displayName} ${
        notificationPayload.item
      }`,
      duration: getDuration(notificationPayload.escalationScheduleDuration),
      beforeOrAfterDue: getOffset(
        notificationPayload.escalationScheduleOffsetType,
      ),
    });
  }
  return t(
    `common:{{jobName}} is {{jobAssignment}} and is {{duration}} {{beforeOrAfterDue}}.`,
    {
      jobName: `#${notificationPayload.jobId} ${getEscalationLocations(
        notificationPayload.location,
      )}: ${getAction(notificationPayload.action).displayName} ${
        notificationPayload.item
      } `,
      jobAssignment: notificationPayload.jobAssignment,
      duration: getDuration(notificationPayload.escalationScheduleDuration),
      beforeOrAfterDue: getOffset(
        notificationPayload.escalationScheduleOffsetType,
      ),
    },
  );
}
