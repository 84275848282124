import styled from 'styled-components/macro';
import Icons from 'blocks/Icons';

const Close = styled(Icons.Exit)`
  position: absolute;
  top: 2.8rem;
  right: 2.5rem;
`;

export default Close;
