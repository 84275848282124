export const PRIORITY_ICONS = {
  all: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#CDCDCD" />
      <rect width="12" height="12" transform="translate(4 4)" fill="#CDCDCD" />
      <circle cx="10" cy="10" r="5" stroke="white" strokeWidth="2" />
    </svg>
  ),
  highest: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365694)">
        <rect width="20" height="20" rx="2" fill="#CE0000" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0632 6.1673C12.9912 6.07915 12.9274 6.0001 12.8736 5.93045C12.8072 5.84378 12.6982 5.80109 12.5906 5.81959C12.483 5.83809 12.3945 5.91474 12.3608 6.01861C12.1991 6.51243 11.9531 6.97455 11.6338 7.38448C11.6526 6.21726 11.4214 4.15758 9.72988 3.04825C9.63941 2.98883 9.52361 2.98394 9.42846 3.03554C9.33331 3.08714 9.27423 3.18686 9.27468 3.29509C9.27468 4.25779 8.75896 5.28366 7.86238 6.10883C6.9949 6.90754 4.9793 9.01836 5.00016 11.3566C5.01485 12.9846 5.84384 15.2356 7.94672 16.1292C7.97816 15.1075 8.42395 14.3819 8.86004 13.6825C9.27439 13.0172 9.66611 12.3895 9.70196 11.5112C9.70654 11.3961 9.7779 11.2944 9.88451 11.2508C9.99111 11.2073 10.1133 11.23 10.1971 11.309C11.3823 12.4257 12.0173 14.0801 12.0485 16.099C13.5568 15.4557 14.7922 14.0537 14.9744 11.773C15.2098 8.81119 13.7599 7.02538 13.0632 6.1673Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365694">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  high: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365698)">
        <rect width="20" height="20" rx="2" fill="#F2722C" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 14.3421C10.8284 14.3421 11.5 15.049 11.5 15.9211C11.5 16.7931 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.7931 8.5 15.9211C8.5 15.049 9.17157 14.3421 10 14.3421ZM10.9519 2.5C11.2029 2.5 11.4064 2.70351 11.4064 2.95455C11.4064 2.96456 11.4061 2.97458 11.4054 2.98457L10.7696 12.589C10.7538 12.8278 10.5554 13.0135 10.316 13.0135H9.6859C9.44655 13.0135 9.24821 12.8279 9.23236 12.589L8.59468 2.98466C8.57805 2.73417 8.76763 2.51763 9.01811 2.501L10.9519 2.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365698">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  medium: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365702)">
        <rect width="20" height="20" rx="2" fill="#FBC633" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 11.4C14.5523 11.4 15 11.8477 15 12.4V12.6C15 13.1523 14.5523 13.6 14 13.6H6C5.44772 13.6 5 13.1523 5 12.6V12.4C5 11.8477 5.44772 11.4 6 11.4H14ZM14 7C14.5523 7 15 7.44772 15 8V8.2C15 8.75228 14.5523 9.2 14 9.2H6C5.44772 9.2 5 8.75228 5 8.2V8C5 7.44772 5.44772 7 6 7H14Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365702">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  low: () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33569_365706)">
        <rect width="20" height="20" rx="2" fill="#A1D2E2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9514 10.4651C13.8759 10.316 13.7227 10.2222 13.5556 10.2222H10.8889V4.44444C10.8889 4.19898 10.6899 4 10.4445 4H9.55555C9.31009 4 9.1111 4.19898 9.1111 4.44444V10.2222H6.4444C6.27729 10.2222 6.12408 10.316 6.04856 10.4651C5.97261 10.6139 5.98649 10.793 6.08502 10.9282L9.64062 15.8171C9.72439 15.9319 9.85764 16 10 16C10.1424 16 10.2756 15.9319 10.3594 15.8171L13.915 10.9282C14.0135 10.793 14.0274 10.6139 13.9514 10.4651Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_33569_365706">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};
