import React from 'react';
import { Redirect, useHistory, Route } from 'react-router-dom';
import { useAccess } from '@optii/shared';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';

function OptiiRoute(props) {
  const { permissions } = props;
  const { can, canWithOr } = useAccess();

  const history = useHistory();

  setTimeout(() => {
    if (!Auth.Instance.session.isAuthenticated) {
      const fromUri = history.location.pathname + history.location.search;
      if (!fromUri.match(/login/)) {
        Auth.Instance.setFromUri(fromUri);
      }
      history.push('/login');
    }
    return null;
  }, 10);

  if (props.useCanOr) {
    return canWithOr(
      permissions,
      <Route {...props} />,
      <Route render={(_) => <Redirect to="/dashboard" />} />,
    );
  }

  // If they don't have the proper permissions, send them to the dashboard
  return can(
    permissions,
    <Route {...props} />,
    <Route render={(_) => <Redirect to="/dashboard" />} />,
  );
}

export default OptiiRoute;
