import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  calendar: () => `
    transform: translate(0, -21px);
  `,
  hidden: () => `
    display: none;
  `,
};
const Label = styled.label`
  ${(props) =>
    props.topLabel
      ? `
          position: unset;
          transform: unset;
          padding: unset;
          margin-left: unset;
        `
      : `
          position: absolute;
          transform: translate(0, -11px);
          padding: 0 0.5rem 0 0.5rem;
          margin-left: 0.8rem;
        `}

  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.medium}
  ${(props) => props.theme.zIndex.high}
  font-family: inherit;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Label;
