import React, {
  ReactElement,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  Flex,
  SPACING,
  ConfigProvider,
  COLORS,
  Grid,
  Image,
  Typography,
  Space,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { isFileExtensionImageOrVideo } from '../../utils/message';
import { ToolbarRender } from '../../types';
import { CustomFileItem } from './CustomFileItem';

type FileList = {
  uid: string;
  url: string;
  src: string;
  name: string;
};

type InputFilePreviewProps = {
  fileList: FileList[];
  setFileList: Dispatch<SetStateAction<FileList[]>>;
};

const { PreviewGroup } = Image;
const { useBreakpoint } = Grid;

export const toolbarRender = (
  info: ToolbarRender['info'],
  isMobile: boolean | undefined,
  fileList: FileList[],
  onDownload: (current: number) => void,
): ReactNode => {
  const {
    transform: { scale },
    actions: {
      onFlipX,
      onFlipY,
      onRotateLeft,
      onRotateRight,
      onZoomIn,
      onZoomOut,
      onReset,
    },
    current,
  } = info;

  const isCurrentFileVideo = isFileExtensionImageOrVideo(
    fileList[current].name,
  ).isVideo;
  return (
    <Space
      size={isMobile ? SPACING.NONE : SPACING.SIZE_MD}
      className="toolbar-wrapper"
      style={{
        paddingInline: isMobile ? SPACING.NONE : SPACING.SIZE_XL,
        borderRadius: 100,
        backgroundColor: 'rgba(0,0,0, .1)',
      }}
      styles={{
        item: {
          padding: SPACING.SIZE_MS,
          fontSize: 21,
        },
      }}
    >
      <DownloadOutlined onClick={() => onDownload(current)} />
      {isCurrentFileVideo ? null : (
        <>
          <SwapOutlined rotate={90} onClick={onFlipY} />
          <SwapOutlined onClick={onFlipX} />
          <RotateLeftOutlined onClick={onRotateLeft} />
          <RotateRightOutlined onClick={onRotateRight} />
          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
          <UndoOutlined onClick={onReset} />
        </>
      )}
    </Space>
  );
};

export function InputFilePreview({
  fileList,
  setFileList,
}: InputFilePreviewProps) {
  const { xs } = useBreakpoint();
  const { t } = useTranslation(['common', 'chat']);
  const [currentAttachment, setCurrentAttachment] = useState(0);
  const isMobile = xs;

  const onDownload = (current: number) => {
    const { url, name } = fileList[current];
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  function imageRender(originalNode: ReactElement, info: unknown) {
    const { current } = info as { current: number };
    if (!fileList.length && !current) return originalNode;

    const { name, url } = fileList[current];

    const { isVideo, isImage } = isFileExtensionImageOrVideo(name);

    if (isVideo) {
      return (
        <video width="75%" controls src={url}>
          <track kind="captions" />
        </video>
      );
    }

    if (isImage) return originalNode;

    return (
      <Typography.Text
        style={{
          color: COLORS.neutral[1],
        }}
      >
        {t("common:There's no preview available for this file.")}
      </Typography.Text>
    );
  }

  return (
    <Flex
      gap={SPACING.SIZE_SM}
      style={
        isMobile
          ? { marginInline: SPACING.SIZE_MD, marginTop: SPACING.SIZE_SM }
          : { marginBottom: SPACING.SIZE_DEFAULT }
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Typography: {
              colorText: COLORS.neutral[8],
            },
            Divider: {
              verticalMarginInline: SPACING.NONE,
            },
          },
        }}
      >
        <PreviewGroup
          items={fileList}
          preview={{
            imageRender,
            current: currentAttachment,
            onChange: (current: number) => {
              setCurrentAttachment(current);
            },
            toolbarRender(originalNode, info) {
              return toolbarRender(info, isMobile, fileList, onDownload);
            },
          }}
        >
          {fileList.map(({ uid, url, name }, index) => (
            <CustomFileItem
              key={uid}
              url={url}
              name={name}
              onDownload={() => onDownload(index)}
              onPreview={() => setCurrentAttachment(index)}
              onRemoveFile={() =>
                setFileList((prev) =>
                  prev.filter((prevFile) => prevFile.uid !== uid),
                )
              }
            />
          ))}
        </PreviewGroup>
      </ConfigProvider>
    </Flex>
  );
}
