import { gql } from "@apollo/client";

export default gql`
  mutation UpdateChecklistTemplate(
    $id: ID!
    $updateChecklistTemplateInput: UpdateChecklistTemplateInput!
  ) {
    updateChecklistTemplate(
      id: $id
      updateChecklistTemplateInput: $updateChecklistTemplateInput
    ) {
      archivedAt
      createdAt
      id
      tasks {
        attachment
        checklistTemplateId
        createdAt
        id
        label
        note: notes
        updatedAt
        taskType
        parent_task_id
        ordering_value
      }
      creator {
        firstName
        lastName
      }
      name
      description
      updatedAt
    }
  }
`;
