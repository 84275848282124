import { gql } from '@apollo/client';

export const CREATE_REPEATING_JOB = gql`
  mutation CreateRepeatingJob(
    $createRepeatingJobInput: CreateRepeatingJobInput!
  ) {
    createRepeatingJob(createRepeatingJobInput: $createRepeatingJobInput) {
      id
      jobTemplate {
        action
        items {
          name
          amount
        }
      }
    }
  }
`;
