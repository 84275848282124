import { gql } from "@apollo/client";

export default gql`
  query GetLocationTypeById($id: ID!) {
    locationType: GetLocationTypeById(id: $id) {
      id
      displayName
      code
      note
    }
  }
`;
