import styled from 'styled-components/macro';
import React from 'react';

type ErrorMsgProps = {
  children: JSX.Element | string;
};
const Div = styled.div`
  color: ${(props) => props.theme.colors.roseDark};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.weak}
`;
function ErrorMsg(props: ErrorMsgProps) {
  const { children } = props;
  return <Div {...props}>{children}</Div>;
}
export default ErrorMsg;
