import {
  TimePicker as AntTimePicker,
  ConfigProvider,
  ThemeConfig,
  type TimePickerProps,
} from 'antd';

type Props = TimePickerProps & {
  theme?: ThemeConfig;
};

export function TimePicker(props: Props) {
  const { theme, ...rest } = props;
  const {
    changeOnScroll = true,
    needConfirm = false,
    ...timePickerProps
  } = rest;
  return (
    <ConfigProvider theme={theme}>
      <AntTimePicker
        {...timePickerProps}
        changeOnScroll={changeOnScroll}
        needConfirm={needConfirm}
      />
    </ConfigProvider>
  );
}
