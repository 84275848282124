import { gql } from "@apollo/client";

export default gql`
  mutation editMultiEntries($input: [CreateScheduleEntry]!) {
    editMultiEntries(input: $input) {
      id
      employeeID
      employmentTypeID
      departmentID
      roleID
      isAvailabe
      date
      timeStartSec
      timeStopSec
      userDatetime
    }
  }
`;
