// enums

import { Notification as ServiceNotification } from '../api/notifications';

export enum EventContext {
  'Job' = 'Job',
  'ProjectCycle' = 'ProjectCycle',
  'TemperatureTask' = 'TemperatureTask',
  'NumericalTask' = 'NumericalTask',
  'Project' = 'Project', // FE Internal
}

export enum EventContextSelect {
  'Job' = EventContext.Job,
  'ProjectCycle' = EventContext.ProjectCycle,
}

export enum Operator {
  'In' = 'In',
  'NotIn' = 'NotIn',
  'Null' = 'Null',
  'NotNull' = 'NotNull',
  'OutsideRange' = 'OutsideRange',
}
export enum ActionType {
  'SendNotification' = 'SendNotification',
  'SendEmail' = 'SendEmail',
}

export enum ScheduleContextType {
  'Event',
  'EntityProperty',
}

export enum ScheduleOffsetType {
  'On' = 'On',
  'After' = 'After',
  'Before' = 'Before',
  'Status' = 'Status',
}

export enum JobType {
  'internal' = 'Internal',
  'guest' = 'Guest',
}

export enum Priorities {
  'low' = 'low',
  'medium' = 'medium',
  'high' = 'high',
  'highest' = 'highest',
}

export enum Actions {
  'clean' = 'clean',
  'deliver' = 'deliver',
  'remove' = 'remove',
  'repair' = 'repair',
  'replace' = 'replace',
  'inspect' = 'inspect',
  'install' = 'install',
  'perform' = 'perform',
  'test' = 'test',
  'noAction' = 'noAction',
}

export enum Statuses {
  'not_started' = 'not_started',
  'in progress' = 'in progress',
  'completed' = 'completed',
  'on hold' = 'on hold',
  'cancelled' = 'cancelled',
  'pending' = 'pending',
}

export type SelectOption = {
  label: React.ReactNode;
  value: string;
};

type FilterOption = {
  property: string;
  operator: Operator;
  values?: string[];
};

type Schedule = {
  entityPropertyName?: string | null;
  offsetDuration: string;
  offsetType?: ScheduleOffsetType;
};

type Action = {
  schedule: Schedule;
  type: ActionType[];
  roles: string[];
  legacyAssigneeFilter?: {
    eventId: string;
    values: Exclude<Operator, 'In' | 'NotIn'>;
  };
};

export type Filter = {
  [key: string]: FilterOption;
};

type Event = {
  context: EventContext;
  filters: Filter[];
};

export type EventActionRules = {
  id?: string;
  event: Event;
  actions: Action[];
};
// FE Types.
export type Notification = {
  id: string;
  displayName: string;
  userEventActionRules: EventActionRules[];
};

export type UserNotification = Pick<
  ServiceNotification,
  'id' | 'displayName' | 'userEventActionRules'
>;
