import styled from 'styled-components/macro';
import React from 'react';

const Span = styled.span`
  color: ${(props) => props.theme.colors.roseDark};
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.weights.weak}
  ${(props) =>
    props.topLabel
      ? `
          position: unset;
          left: unset;
          top: unset;
          margin-right: .25rem
        `
      : `
          position: absolute;
          left: -1.5rem;
          top: 1.4rem;
        `}
  
  ${(props) =>
    props.isInlineFormikField &&
    `left: 0;
    top: 0.4rem;`}
`;

function RequiredStar(props) {
  return <Span {...props}>*</Span>;
}
export default RequiredStar;
