import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Container from './Container';
import Filter from './Filter';
import GreyFilterWithArrows from './GreyFilterWithArrows';
import SearchBox from './SearchBox';
import Icon from './Icon';
import IconAlt from './IconAlt';
import Text from './Text';
import InlineFiltersGeneralContainer from './InlineFiltersGeneralContainer';
import InlineFiltersContainer from './InlineFiltersContainer';

const MODIFIER_CONFIG = {
  mobileHide: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `,
};

const Filters = styled.div`
  ${(props) => props.theme.elements.card};
  width: 100%;
  max-width: none;
  margin-top: 1.3rem;
  padding: 1.3rem;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

const GreenFilterWithArrows = styled(GreyFilterWithArrows)`
  .react-select__control,
  .react-select__control:hover {
    background-color: transparent;
    border: 0.1rem solid ${(props) => props.theme.colors.persianGreen};
  }
  .react-select__value-container--has-value .react-select__single-value,
  .react-select__control div {
    color: ${(props) => props.theme.colors.persianGreen};
  }
  .react-select__control--is-focused .react-select__indicators {
    background: transparent;
  }
  svg * {
    fill: ${(props) => props.theme.colors.persianGreen};
  }
`;

Filters.Container = Container;
Filters.Filter = Filter;
Filters.GreyFilterWithArrows = GreyFilterWithArrows;
Filters.GreenFilterWithArrows = GreenFilterWithArrows;
Filters.SearchBox = SearchBox;
Filters.Icon = Icon;
Filters.IconAlt = IconAlt;
Filters.Text = Text;
Filters.InlineFiltersGeneralContainer = InlineFiltersGeneralContainer;
Filters.InlineFiltersContainer = InlineFiltersContainer;
export default Filters;
