import React from "react";

export interface Values {
  action: string
  items: Items
  status?: Status
  priority: Priority
  doBy?: string
  type: string
  department: Department2
  role: Role2
  assignee: Assignee
  notes: string
  attachments: any[]
  repeatFrequency?: string
  multipleDaily?: boolean
  jobTemplate?: JobTemplate
  endDate?: string
  startDate: string
  cycle?: Cycle
  repeatEvery?: number
  asset?: Asset
  predictiveDueTime?: any
  location?: Location[]
  timezone?: string;
}

export interface Items {
  id: string
  displayName: string
  notes?: string
  assignments?: Assignment[]
  __typename?: string
}

export interface Assignment {
  action: string
  department: Department
  role?: Role
  __typename: string
}

export interface Department {
  id: string
  displayName: string
  departmentCode: string
  __typename: string
}

export interface Role {
  id: string
  name: string
  description: any
  __typename: string
}

export interface Status {
  id: string
}

export interface Priority {
  id: string
  displayName?: string
}

export interface Department2 {
  id: string
  displayName: string
  departmentCode?: string
}

export interface Role2 {
  id: string
  displayName: string
}

export interface Assignee {
  id: number
  displayName: string
  userId?: number
  userName?: string
  userFirstName?: string
  userLastName?: string
}

export interface JobTemplate {
  dueByTime: any
}

export interface Cycle {
  displayName: string
  value: string
}

export interface Asset {
  id: string
  displayName: string
  serialNumber: string
  notes: string
  status: string
  installationDate: any
  location: Location
  assetType: AssetType
  purchase: any
  vendor: any
  warranty: any
  documents: any[]
  version: number
  __typename: string
}

export interface AssetType {
  id: string
  displayName: string
  modelName: string
  jobItem: JobItem
  brand: Brand
  serviceCycle: ServiceCycle
  lifeCycle: any
  note: string
  description: string
  __typename: string
}

export interface JobItem {
  id: string
  displayName: string
  __typename: string
}

export interface Brand {
  id: string
  displayName: string
  __typename: string
}

export interface ServiceCycle {
  type: string
  value: string
  displayName: string
  __typename: string
}

export interface Location {
  id: string
  value: string
  title: string
  pId: number
  name: string
  longDisplayName: string
  shortDisplayName: string
  parentName: string
  locationTypeCode: string
  locationTypeId: string
  locationTypeName: string
  roomTypeId: any
  roomTypeName: string
  childCount: number
  __typename: string
  displayName: string
  roomType: string
  key: string
}

type TRepeatingContextValue = {
  setFieldValue: (_: any, params?: any) => void,
  errors?: {},
  onBlur?: (_: any) => void,
  onchange?: (_: any) => void,
  values?: Values,
  t?: (_: string) => void,
  touched?: (_: any) => void,
  setFieldError?: (_: any) => void,
  setFieldTouched?: (_: any) => void,
  validateForm?: (_: any) => void,
}

export const RepeatingContext = React.createContext<TRepeatingContextValue>({
  errors: {},
  onBlur: (_: any) => {},
  onchange: (_: any) => {},
  values: {} as Values,
  t: (_: any) => {},
  touched: (_: any) => {},
  setFieldValue: (_: any, params?: any) => {},
  setFieldError: (_: any) => {},
  setFieldTouched: (_: any) => {},
  validateForm: (_: any) => {},
});
