import { gql } from '@apollo/client';

export const ADD_JOB = gql`
  mutation addJob($input: JobInput!) {
    addJob(input: $input) {
      id
      action
      items {
        name
        amount
      }
      doBy
      checklistTemplate {
        id
      }
      status
      locations {
        name
        shortDisplayName
        longDisplayName
      }
    }
  }
`;
