import styled from 'styled-components/macro';
import Button from '../Button';
import {
  ModifiersConfig,
  applyStyleModifiers,
} from 'styled-components-modifiers';

const MODIFIER_CONFIG: ModifiersConfig = {
  light: ({ theme }) => `
    background: ${theme.colors.tealMed};
    :active,
    :hover {
      border-color: ${theme.colors.tealMed};
    }
    :active {
      border-color: ${theme.colors.tealMed};
    }
  `,
};

export const ButtonPrimaryStyled = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.weights.medium};
  background-color: ${(props) => props.theme.colors.teal};
  :active,
  :hover {
    box-shadow: 0 1px 6px 0 rgba(51, 51, 51, 0.5);
  }
  :active {
    background-color: ${(props) => props.theme.colors.tealXDark};
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;
