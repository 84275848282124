import { NotificationInput, UserFilterInput } from '../../../api/notifications';

export const normalizeInputFilters = (input: NotificationInput) => {
  const normalizedInput = { ...input };
  const filters =
    normalizedInput.userEventActionRules[0]?.event?.filters &&
    normalizedInput.userEventActionRules[0]?.event?.filters[0];

  for (const key in filters) {
    if (!filters[key as keyof UserFilterInput]?.values?.length) {
      (filters[key as keyof UserFilterInput] as any) = null;
    }
  }

  return normalizedInput;
};
