// We need to escape commas as the library the .net service is using for filters
// uses a comma delimited list to seperate different filters
// more info on libray they use here: https://github.com/Biarity/Sieve#send-a-request
const escapeCommas = filterValue => {
  if (Array.isArray(filterValue)) {
    return filterValue.map(f => f && String(f).replace(/,/g, "\\,"));
  }
  return filterValue && String(filterValue).replace(/,/g, "\\,");
};
export default escapeCommas;
