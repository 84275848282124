import { Meta, StoryObj } from '@storybook/react';
import {
  NotificationsForm,
  NotificationsFormProps,
} from '../../organisms/Forms/Forms.component';
import { Notifications } from '../../organisms/Forms/Forms.stories';
import { TopHeader } from './Headers.component';

/**
 * Headers are anything that have a title and some action
 */

export default {
  title: 'UI-Library/Molecules/Headers',
  component: TopHeader,
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
} as Meta;

type Story = StoryObj<typeof TopHeader>;

/**
 * Header molecule constructed using Flex, Search, Button and Drawer (opens a drawer)
 */

export const Default: Story = {
  args: {
    onSearch: (value) => console.log('Search for', value),
    onSearchChange: (value) => console.log('Search change', value),
    drawerChildren: 'Drawer content here!',
    i18n: {
      action: 'Add Action',
      drawer: 'Add Action',
      placeholder: 'Search for Actions...',
      title: 'Title',
      cancel: 'Cancel',
      submit: 'Save',
    },
  },
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
};

/**
 * Settings Job Rules Page List Header
 */

export const SettingsListing: Story = {
  args: {
    i18n: {
      action: 'Add Notification',
      drawer: 'Add Notification',
      placeholder: 'Search for notifications...',
      title: 'Rules',
      cancel: 'Cancel',
      submit: 'Save',
    },
    disableButtons: false,
    onSearch: (value) => console.log('Search for', value),
    onSearchChange: (value) => console.log('Search change', value),
    onOpen: () => console.log('open cesame!'),
    onClose: () => console.log('HODOR'),
    drawerChildren: (
      <NotificationsForm {...(Notifications.args as NotificationsFormProps)} />
    ),
  },
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
};

/**
 * Floor Plan Locations Header
 */

export const FloorPlanLocations: Story = {
  args: {
    i18n: {
      placeholder: 'Search...',
      title: 'Floor Plan',
    },
    disableButtons: false,
    onOpen: undefined,
    onSearch: (value) => console.log('Search for', value),
    onSearchChange: (value) => console.log('Search change', value),
  },
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
};
