import { gql } from "@apollo/client";

export default gql`
  query LocationActivityLog($locationId: ID!) {
    logs(locationId: $locationId) {
      aggregateId
      aggregateType
      eventType
      aggregateVersion
      action
      domain
      user {
          id
          firstName
          lastName
        }
      recordedAt
      metadata {
        changes {
          key
          new
          old
        }
        message
        roles
      }
    }
  }
`