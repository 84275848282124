import styled from 'styled-components/macro';
import React from 'react';
import { applyStyleModifiers } from 'styled-components-modifiers';
import BaseButton from './BaseButton';

const CONFIG = {
  green: ({ theme }) => `
    color: ${theme.colors.persianGreenMed};
    border: 1px solid ${theme.colors.persianGreenMed};
    :active,
    :hover {
      border-color: ${theme.colors.persianGreen};
    }
    :active {
      color: ${theme.colors.persianGreen};
      border-color: ${theme.colors.persianGreen};
    }
  `,
  teal: ({ theme }) => `
    color: ${theme.colors.moderateCyan};
    border: 1px solid ${theme.colors.moderateCyan};
    :active,
    :hover {
      border-color: ${theme.colors.teal};
    }
    :active {
      color: ${theme.colors.moderateCyan};
      border-color: ${theme.colors.teal};
      background-color: ${theme.colors.white};
      box-shadow: 0 1px 6px rgba(172, 170, 170, 0.5);
    }
  `,
};

const Button = styled(BaseButton)`
  color: ${(props) => props.theme.colors.teal};
  border: 1px solid ${(props) => props.theme.colors.teal};
  background-color: transparent;
  :active,
  :hover {
    border-color: ${(props) => props.theme.colors.tealXDark};
  }
  :active {
    color: ${(props) => props.theme.colors.tealXDark};
    border-color: ${(props) => props.theme.colors.tealXDark};
  }
  ${applyStyleModifiers(CONFIG)};
`;
function TertiaryButton(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}
export default TertiaryButton;
