import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ArrowDown } from 'images/svg/arrow-down.svg';

const Arrow = styled(ArrowDown)`
  ${(props) => props.theme.animations.hoverUp()}
  margin-left: 1rem;
`;

function DropdownIndicator(props) {
  const {
    children = <Arrow />,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      {children}
    </div>
  );
}
DropdownIndicator.Arrow = Arrow;
export default DropdownIndicator;
