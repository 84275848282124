import React from 'react';
import Icons from 'blocks/Icons';
import { useAccess } from '@optii/shared';
import { PERMISSIONS } from 'utils/constants';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import GoogleAnalyticsClient from 'utils/analytics/GoogleAnalyticsClient';
import { useTranslation } from 'react-i18next';

function Edit({ job, setQuery, disabled, edit }) {
  const { can } = useAccess();
  const canEdit = can(PERMISSIONS.jobs.all);
  const { t } = useTranslation(['common']);

  return (
    canEdit && (
      <Icons.Edit
        title={t('common:Edit')}
        /* Temporarily removing the old "Edit Job" form */
        /* modifiers={edit === '2' ? "v2" : "big"} */
        modifiers={'big'}
        onClick={(e) => {
          e.stopPropagation();
          if (disabled) {
            return;
          }

          if (job.onEditCallback && typeof job.onEditCallback === 'function') {
            job.onEditCallback();
          }

          setQuery({ edit: edit ?? '1', openJob: job.id });
          GoogleAnalyticsClient.event(GA_EVENTS.editJobCardSwimLane);
        }}
      />
    )
  );
}

export default Edit;
