import { RightOutlined } from '@ant-design/icons';
import { COLORS, Typography } from '@optii-solutions/ui-library';

import JobChecklist from '@optii/topcat-client/components/Jobs/JobDetails/JobChecklist';

import type { TJobTasksProps } from './JobTasks.types';
import { CustomCollapse, JOB_TASKS_STYLES } from './JobTasks.elements';
import { JOB_STATUSES } from '../JobForm/JobForm.constants';

export function JobTasks(props: TJobTasksProps) {
  const { job, t } = props;

  const items = job.checklists?.map((checklistItem) => ({
    label: (
      <div>
        <Typography.Paragraph style={JOB_TASKS_STYLES.checklistName}>
          {checklistItem.description || checklistItem.id}
        </Typography.Paragraph>
        <Typography.Paragraph style={JOB_TASKS_STYLES.checklistInfo}>
          <Typography.Text>
            {checklistItem.checklistTasks?.length} {t('jobs:tasks')}
          </Typography.Text>
          {', '}
          <Typography.Text style={JOB_TASKS_STYLES.checklistInfoRequired}>
            {
              checklistItem.checklistTasks?.filter(
                (checklistTaskItem) => !!checklistTaskItem?.required,
              ).length
            }{' '}
            {t('jobs:required')}
          </Typography.Text>
        </Typography.Paragraph>
      </div>
    ),
    key: `job-multiple-checklists-${checklistItem.id}`,
    children: (
      <JobChecklist
        isReadOnly={job?.status !== JOB_STATUSES.inProgress.value}
        id={checklistItem.id}
        data={checklistItem}
      />
    ),
    style: JOB_TASKS_STYLES.checklistItem,
  }));

  const collapseExpandIcon = () => (
    <RightOutlined style={{ minHeight: '100%' }} />
  );

  return job && job.checklists && job.checklists?.length > 1 ? (
    <CustomCollapse
      destroyInactivePanel
      expandIcon={collapseExpandIcon}
      items={items}
      style={{
        border: 'none',
        background: COLORS.neutral[1],
      }}
    />
  ) : (
    <JobChecklist
      isReadOnly={job?.status !== JOB_STATUSES.inProgress.value}
      id={
        job?.checklists?.length
          ? job?.checklists[0]?.id
          : job?.checklistTemplate && job.checklistTemplate[0]?.id
      }
      data={
        job?.checklists
          ? job.checklists[0]
          : job?.checklistTemplate && job.checklistTemplate[0]
      }
    />
  );
}
