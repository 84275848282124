import { gql } from '@apollo/client';

export default gql`
  query TodayJobs($locationId: Int!, $utc: Int!) {
    page: todayJobs(locationID: $locationId, UTC: $utc) {
      edges {
        node {
          ... on AdhocJob {
            id
            action
            items {
              name
              amount
              id
            }
            isRushed
            status
            source
            notes {
              id
              note
            }
            targetTime
            completedAt
            assignee {
              id
              firstName
              lastName
            }
            department {
              Id
              departmentCode
            }
            type
            attachments
            priority
          }
        }
      }
    }
  }
`;
