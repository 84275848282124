import { gql } from "@apollo/client";

export default gql`
  mutation deleteScheduleEntry($id: ID!) {
    deleteScheduleEntry(id: $id) {
      id
      status
    }
  }
`;
