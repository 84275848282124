import React from 'react';
import { Col, Row, Skeleton } from '@optii-solutions/ui-library';

export function AssetFormSkeleton() {
  return (
    <div style={{ padding: '1.6rem' }}>
      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>

        <Col span={11}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>

        <Col span={11}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>
      </Row>

      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: '20px' }}
      >
        <Col span={12}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>

        <Col span={11}>
          <Skeleton
            title={false}
            paragraph={{
              rows: 1,
            }}
          />
          <Skeleton.Input block active />
        </Col>
      </Row>

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
        }}
      />
      <Skeleton.Input
        block
        active
        size="large"
        style={{ marginBottom: '20px' }}
      />

      <Skeleton
        title={false}
        paragraph={{
          rows: 1,
        }}
      />
      <Skeleton.Input
        block
        active
        size="large"
        style={{ marginBottom: '40px' }}
      />

      <Skeleton.Input block active size="large" style={{ height: '70px' }} />
    </div>
  );
}
