import React, { useState } from "react";
import Modal from "components/shared/Modal";
import {
  GET_ROLES_LIST,
  GET_DEPARTMENTS_LIST,
  GET_JOB_ITEMS_LIST,
  GET_EMPLOYEE_LIST,
  GET_LOCATIONS_LIST,
  FIND_ALL_ASSETS
} from "queries";
import { useQuery } from "@apollo/client";
import { initGQLData as initData } from "utils/initGQLData";
import { formatUserDisplayName } from "utils/formatters/user";
import { useAccess } from '@optii/shared';
import { PERMISSIONS } from "utils/constants";
import { useTranslation } from "react-i18next";
import { sortByName } from "utils/sort";
import { JobContext } from "./contexts";
import { getAssetListWithNotes } from "../Shared/LocationAssetTypeSelect";

// Used to provide data and modal functionality to job add/view/edit

function JobModal(props) {
  const { modalName, close, children, isEdit, queuedJobRequest, subModal, disableBackdrop } = props;

  const { t } = useTranslation(["jobs"]);

  // Used to auto assign jobs
  const autoAssignOption = {
    id: -88, // Anything negative will work
    displayName: t("jobs:Auto Assign"),
    userId: -88,
    userName: "autoassign",
    userFirstName: t("jobs:Auto"),
    userLastName: t("jobs:Assign")
  };

  const { data: employeeData, loading: employeeLoading } = useQuery(
    GET_EMPLOYEE_LIST,
    {
      skip: queuedJobRequest,
      variables: {
        status: "active"
      }
    }
  );

  const [jobItemId, setJobItemId] = useState(null);

  const {
    data: assetsData,
    loading: assetsLoading,
    refetch: refetchAssets
  } = useQuery(FIND_ALL_ASSETS, {
    skip: !jobItemId || queuedJobRequest,
    context: { _instance: "node" },
    variables: {
      filters: `assetType.jobItem.id==${jobItemId}`
    }
  });

  function getAssetTypesByJobItem(jobItem) {
    if (jobItem !== jobItemId) {
      setJobItemId(jobItem);
    }
  }

  const {
    data: locationsResponseData,
    loading: locationsLoading,
    refetch: refetchLocations
  } = useQuery(GET_LOCATIONS_LIST, {
    fetchPolicy: "no-cache",
    skip: queuedJobRequest,
    variables: {
      orderBy: "name_ASC, number_ASC",
      filters: { locationStatus: "active" }
    }
  });

  const locations = initData(locationsResponseData);
  const locationsAssetType = getAssetListWithNotes(initData(assetsData));

  const loadLocationListOptions = async inputValue => {
    const { data: refetchedLocationList } = await refetchLocations({
      filters: {
        locationStatus: "active",
        locationName_LIKE: `%${inputValue}%`
      }
    });
    return initData(refetchedLocationList);
  };

  const loadLocationAssetTypeListOptions = async inputValue => {
    const term = String(inputValue).toLowerCase();
    return locationsAssetType.filter(
      item =>
        item?.location?.displayName?.toLowerCase()?.includes(term) ||
        item?.assetType?.displayName?.toLowerCase()?.includes(term)
    );
  };

  const jobItemHasAssociatedAssets =
    !assetsLoading && locationsAssetType?.length > 0;

  const { can } = useAccess();
  const canAutoAssign = can(PERMISSIONS.jobs.autoassign) && !isEdit; // show autoassign option only in Add Job form

  const shouldShowAutoAssignOption =
    (canAutoAssign && [autoAssignOption]) || [];

  const employees = shouldShowAutoAssignOption
    .concat(initData(employeeData))
    .sort(sortByName)
    .map(emp => ({
      ...emp,
      id: emp.userId,
      displayName: formatUserDisplayName(emp)
    }));

  const {
    data: departmentsData,
    loading: dptsLoading
  } = useQuery(GET_DEPARTMENTS_LIST, { skip: queuedJobRequest });
  const departments = initData(departmentsData);

  const { data: rolesData, loading: rolsLoading } = useQuery(GET_ROLES_LIST, {
    skip: queuedJobRequest
  });
  const roles = initData(rolesData).map(before => ({
    ...before,
    displayName: before.name
  }));

  const {
    data: itemsData,
    loading: itemsLoading
  } = useQuery(GET_JOB_ITEMS_LIST, { skip: queuedJobRequest });
  const jobItems = initData(itemsData);

  const loading = dptsLoading || rolsLoading || itemsLoading;

  return (
    <Modal
      disableBackdrop={disableBackdrop}
      subModal={subModal}
      modalName={modalName}
      modifiers={["right", "childrenPadding", "flex"]}
    >
      <JobContext.Provider
        value={{
          jobItems,
          employees,
          departments,
          roles,
          activeLocations: locations,
          activeLocationsAssetType: locationsAssetType,
          loadLocationListOptions,
          loadLocationAssetTypeListOptions,
          getAssetTypesByJobItem,
          jobItemHasAssociatedAssets,
          assetsLoading,
          loading,
          close
        }}
      >
        {children}
      </JobContext.Provider>
    </Modal>
  );
}

export default JobModal;
