import styled from 'styled-components/macro';
import { ReactComponent as infoCircleSVG } from '@optii/shared/images/svg/infoCircle.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

export const InfoCircle = styled(infoCircleSVG)`
  height: 2rem;
  width: 2rem;
  ${applyStyleModifiers(CONFIG)};
`;
