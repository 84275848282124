import styled from 'styled-components/macro';
import { ReactComponent as Trash } from 'images/svg/trashcan.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  right: ({ theme }) => `
    margin-left: auto
  `,
  largeMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.miniLg}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
};

const TrashCan = styled(Trash)`
  cursor: pointer;

  ${(props) => props.theme.animations.hoverUp()}

  ${applyStyleModifiers(CONFIG)};
`;
export default TrashCan;
