import styled from 'styled-components/macro';
import {
  ModifiersConfig,
  applyStyleModifiers,
} from 'styled-components-modifiers';

const MODIFIER_CONFIG: ModifiersConfig = {
  right: ({ theme }) => `
  left: auto;
  right: 0;
  transform: translate(0, -50%);
  border-radius: 0;
  height: 100%;
  overflow-y: auto;
  @media (max-width: ${theme.breakpoints.sm}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`,
  locationTypes: () => `
  p {
    margin: 0;
  }
`,
  childrenPadding: () => `
  padding: 0;
`,
  small: () => `
  max-width: 31.2rem;
  padding: 2.4rem;
`,
  flex: () => `
  display: flex;
  flex-direction: column;
`,
};

export const Pane = styled.div<{ superModal?: boolean; zIndex?: string }>`
  ${(props) => props.theme.elements.card}
  ${(props) =>
    props.superModal
      ? props.theme.zIndex.superModal
      : props.zIndex || props.theme.zIndex.modal}
  p {
    ${(props) => props.theme.fonts.weights.light}
    margin: 2rem 0;
  }
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1.3rem 2rem 3rem;
  width: 48rem;
  background-color: white;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export const Backdrop = styled.div<{
  superModal?: boolean;
  zIndex?: string;
  solid?: boolean;
}>`
  background-color: ${(props) => props.theme.colors.charcoal03};
  ${(props) => props.hidden && 'display: none;'}
  ${(props) =>
    props.superModal
      ? props.theme.zIndex.superModalBack
      : props.zIndex || props.theme.zIndex.modalBack}
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${(props) => (props.solid ? '1' : '0.4')};
  height: 100%;
`;
