import styled from 'styled-components/macro';

const sharedLeftPadding = '1.32rem';
const Input = styled.input`
  font-family: inherit;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  ${(props) => (props.topLabel ? 'min-height: 44px' : '')};
  padding-inline-start: ${sharedLeftPadding};
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.borders.default}
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.light}
  :focus {
    ${(props) => !props.error && props.theme.borders.focus}
  }

  ${(props) =>
    props.error &&
    (props.isFormik || props.blurred || props.focused) &&
    `
          border-color: ${props.theme.colors.roseDark} !important;
        `}

  ${(props) =>
    props.isInlineFormikField &&
    props.required &&
    props.edit &&
    `
    padding-inline-start: 1.2rem !important;
  `}

  ${(props) =>
    props.isLink &&
    `
    color: ${props.theme.colors.teal} !important;
    cursor: pointer !important;
  `}

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
    -webkit-text-fill-color: ${(props) => props.theme.colors.charcoal05};
  }
  :read-only {
    ${(props) => props.theme.borders.transparent};
    border: none;
    cursor: default;
  }
  /* it hides input number arrows */
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    ${(props) => props.theme.fonts.type.primary};
    ${(props) => props.theme.fonts.weights.light};
    ${(props) => props.theme.colors.charcoal04};
    font-style: italic;
  }
  
`;

export default Input;
