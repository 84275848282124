import styled from 'styled-components/macro';
import CheckBox from './Checkbox';
import RadioBtn from './RadioBtn';
import Header from './Header';
import Footer from './Footer';
// form elements
const Form = styled.form`
  ${(props) => props.theme.elements.card}
  margin-bottom: 2rem;
`;
Form.Header = Header;
Form.Footer = Footer;
Form.CheckBox = CheckBox;
Form.RadioBtn = RadioBtn;
export default Form;
