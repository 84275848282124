import { RRule } from 'rrule';
import { Moment } from 'moment';
import { getDaysPositionInMonth } from '../components/JobForm/JobForm.helpers';

type MonthlyCadencePayload = {
  startDate: Moment;
  cycle: {
    byWeekDay: boolean;
    byMonthDay: boolean;
    interval: number;
  };
};

export const getWeekDays = (t = (a: string) => a) => ({
  Sunday: { key: RRule.SU, label: t('common:Sunday') },
  Monday: { key: RRule.MO, label: t('common:Monday') },
  Tuesday: { key: RRule.TU, label: t('common:Tuesday') },
  Wednesday: { key: RRule.WE, label: t('common:Wednesday') },
  Thursday: { key: RRule.TH, label: t('common:Thursday') },
  Friday: { key: RRule.FR, label: t('common:Friday') },
  Saturday: { key: RRule.SA, label: t('common:Saturday') },
});

const dayOfMonthPosition = [
  {
    position: 'first',
    id: 1,
  },
  {
    position: 'second',
    id: 2,
  },
  {
    position: 'third',
    id: 3,
  },
  {
    position: 'fourth',
    id: 4,
  },
  {
    position: 'last',
    id: -1,
  },
];

function getQuarterOfMonth(position: string | undefined) {
  return (
    dayOfMonthPosition.find((dayPosition) => dayPosition.position === position)
      ?.id || 1
  );
}

function getCurrentRRuleDay(startDate: Moment) {
  const currentWeekDay = startDate.day();
  // No need to pass translate, may be wise to split up keys and labels and join them on a separate export const.
  const weekdays = Object.values(getWeekDays());
  return weekdays[currentWeekDay].key;
}

export const createMonthlyCadenceRule = ({
  cycle,
  startDate,
}: MonthlyCadencePayload): RRule => {
  if (!cycle) throw new Error('Cycle is not defined.');

  try {
    const dateNumber = startDate.date();
    let byMonthDay;
    let byWeekDay;
    if (cycle.byWeekDay) {
      const dayPosition = getDaysPositionInMonth(startDate);
      const quarter = getQuarterOfMonth(dayPosition);
      const currentDay = getCurrentRRuleDay(startDate);
      byWeekDay = currentDay.nth(quarter);
    }

    if (cycle.byMonthDay) {
      byMonthDay = dateNumber;
    }

    const repeatingRule = new RRule({
      freq: RRule.MONTHLY,
      interval: cycle.interval,
      bymonthday: byMonthDay,
      byweekday: byWeekDay,
    });

    return repeatingRule;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};
