import {
  type CSSProperties,
  type FunctionComponent,
  type ReactElement,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { Modal } from 'antd';
import type { UploadFile, GetProp } from 'antd';
import styled from 'styled-components';

import { GET_FILE_BY_ID } from '@optii/topcat-client/queries';
import {
  SPACING,
  Dragger,
  UploadProps,
  ConfigProvider,
} from '@optii-solutions/ui-library';
import { Session, GA_EVENTS } from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { STORE_FILE } from '@optii/shared/queries/file/storeFile';

import { type TUploadHandleFile } from '../../../types/Upload';
import { UPLOAD_DRAGGER } from './UploadDragger.styles';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function getBase64(file: FileType): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

type TUploadDragger = {
  placeholder: string;
  handleUpload: (arg: any) => void;
  handleRemove?: (arg: TUploadHandleFile) => void;
  analytics: any;
  children?: ReactElement;
  fileList?: UploadFile<any>[];
  style?: CSSProperties;
  disabled?: boolean;
  isView?: boolean;
};

function UploadDraggerComponent(props: TUploadDragger) {
  const {
    placeholder,
    handleUpload,
    analytics,
    style,
    disabled = false,
    isView = false,
  } = props;

  // Used to guarantee uniqueness of the elements so that this will work with multiple uploads mounted at once
  const [storeFile, { loading }] = useMutation(STORE_FILE);
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['fields', 'jobs']);
  const [preview, setPreview] = useState<{
    visible: boolean;
    image?: string;
    fileList?: UploadFile<any>[];
  }>();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const fileUploadListRef = useRef<any[]>(props.fileList || []);

  const [getFileListData] = useLazyQuery(GET_FILE_BY_ID);

  async function upload(file: Blob | RcFile) {
    if (file.size >= 10000000) {
      globalSnack({
        message: t('jobs:This file is too large, please try another'),
        timeout: 6000,
      });

      return;
    }

    try {
      await storeFile({
        variables: {
          file,
          name: file.name,
        },
        onCompleted({ file }) {
          if (isView) {
            fileUploadListRef.current = fileUploadListRef.current.concat(
              file.UUID,
            );
            handleUpload(fileUploadListRef.current);

            globalSnack({
              message: t('jobs:This file was uploaded sucessfully.'),
              timeout: 6000,
            });
          } else {
            setFileList((prev) =>
              prev?.concat({
                name: file.name,
                uid: file.UUID,
                url: file.URL,
              }),
            );
            handleUpload({
              name: file.name,
              uid: file.UUID,
            });
          }
        },
      });

      GoogleAnalyticsClient.event(
        analytics || GA_EVENTS.uploadFileUnknownSource,
      );
    } catch (err) {
      console.error('Error uploading attachment:', err);

      globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    }
  }

  async function handlePreview(file: UploadFile<any>) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreview({
      image: file.url || file.preview,
      visible: true,
    });
  }

  function handleRemove(file: UploadFile<any>) {
    const newFileList = fileList?.filter(
      (fileItem) => fileItem.url !== file.url,
    );

    if (!disabled) {
      fileUploadListRef.current = fileUploadListRef.current.filter(
        (item) => item !== file.uid,
      );
    }
    setFileList(newFileList);

    file.uid &&
      props.handleRemove &&
      props.handleRemove({
        name: file.name,
        uid: file.uid,
      });
  }

  const uploadProps: UploadProps = {
    name: 'attachments',
    multiple: true,
    customRequest: async (options) => {
      const { file, onSuccess } = options;

      if (typeof file !== 'string' && typeof onSuccess === 'function') {
        await upload(file);

        onSuccess(file);
      }
    },
    onChange(info) {
      const { status } = info.file;

      if (status === 'error') {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.info('Dropped files', e.dataTransfer.files);
    },
    onRemove: handleRemove,
    onPreview: handlePreview,
    listType: 'picture-card',
    showUploadList: true,
    fileList,
    style: {
      ...style,
      gap: SPACING.SIZE_MD,
      borderWidth: '1px',
      marginBottom: SPACING.SIZE_MD,
    },
  };

  useEffect(() => {
    async function fetchFileList() {
      const asyncFileList = props.fileList?.map(async (fileItem) => {
        const data = await getFileListData({
          variables: { id: fileItem },
        });

        return {
          uid: fileItem,
          url: data?.data?.file?.URL,
        };
      });
      const fetchedFileList =
        asyncFileList && (await Promise.all(asyncFileList));

      if (fetchedFileList?.length) {
        setFileList(fetchedFileList as unknown as UploadFile<any>[]);
      }
    }

    fetchFileList();
  }, []);

  const NewDragger: FunctionComponent<UploadProps> = styled(Dragger)`
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
  `;

  return (
    <ConfigProvider
      theme={{
        token: {
          size: 1,
        },
      }}
    >
      {!disabled ? (
        <NewDragger {...uploadProps}>
          <div style={UPLOAD_DRAGGER.container}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <UploadOutlined style={UPLOAD_DRAGGER.iconContainer} />
            </span>
            <span
              style={{ fontWeight: 500, display: 'flex', alignItems: 'center' }}
            >
              {placeholder}
            </span>
          </div>
        </NewDragger>
      ) : null}

      <Modal
        open={preview?.visible}
        footer={null}
        onCancel={() =>
          setPreview((...previousState) => ({
            ...previousState,
            visible: false,
          }))
        }
      >
        <img
          alt={t('common:Preview file')}
          style={{ width: '100%' }}
          src={preview?.image}
        />
      </Modal>
    </ConfigProvider>
  );
}

export const UploadDragger = memo(UploadDraggerComponent);
