import { Meta, StoryObj } from '@storybook/react';
import { Form } from 'antd';
import {
  GeneralSettingsForm,
  GeneralSettingsFormProps,
  LocationForm,
  LocationFormProps,
  LocationTypeForm,
  LocationTypeFormProps,
  NotificationsForm,
  NotificationsFormProps,
  RoomTypeForm,
  RoomTypeFormProps,
} from './Forms.component';

/**
 * Organisms running with Ant Components Form
 */

export default {
  title: 'UI-Library/Organisms/Forms',
  component: Form,
} as Meta;

/**
 * Settings Jobs Rules Notifications
 */

export const Notifications: StoryObj<typeof NotificationsForm> = {
  render: (args: NotificationsFormProps) => <NotificationsForm {...args} />,
  args: {
    onFinish: (values) => console.log(`Success: ${values}`),
    onFinishFailed: (values) => console.log('Failed:', values),
    loading: false,
    initialValues: {
      notification: {
        filters: {
          context: 'job',
          project: undefined,
        },
      },
    },
    i18n: {
      name: {
        label: 'Name',
        placeholder: 'Notification group name',
      },
      criteria: {
        title: 'Criterias',
        paragraph: 'Define what will trigger a notification.',
      },
      notification: {
        title: 'Notification',
        paragraph: 'Define the notification and who will receive it.',
      },
      criterias: {
        context: {
          label: 'If a',
          placeholder: 'Select a schema type',
          options: [
            { label: 'Job', value: 'job' },
            { label: 'Project', value: 'project' },
          ],
        },
        project: {
          alert: {
            label:
              'Projects end at 11:59PM on their due date. Notifications relating to due date will send at 12:00AM after project due date.',
          },
          name: {
            label: 'has project name',
            placeholder: 'Select project',
            options: [
              {
                value: '1',
                label: 'Monthly HVAC check',
              },
              {
                value: '2',
                label: 'Weekly Sprint Sync',
              },
              {
                value: '3',
                label: 'Daily Slack Sync',
              },
              {
                value: '4',
                label: 'Daily Standup',
              },
            ],
          },
        },
        job: {
          type: {
            joiner: 'or',
            label: 'Is a',
            placeholder: 'Select request type',
            options: [
              { value: 'guest', label: 'Guest request' },
              { value: 'internal', label: 'Internal request' },
            ],
            error: 'Select at least one request type.',
          },
          assignee: {
            label: 'and',
            placeholder: 'Select assignee status',
            options: [
              { value: 'NotNull', label: 'Assigned' },
              { value: 'Null', label: 'Unassigned' },
            ],
            joiner: 'or',
            error: 'Select at least one assignee status.',
          },
          priority: {
            label: 'with priority level',
            placeholder: 'All priorities',
            options: [
              { value: 'highest', label: 'Highest' },
              { value: 'high', label: 'High' },
              { value: 'medium', label: 'Medium' },
              { value: 'low', label: 'Low' },
            ],
          },
          department: {
            label: 'assigned to',
            placeholder: 'All departments',
            options: [
              { value: 1, label: 'Housekeeping' },
              { value: 2, label: 'Front Office' },
              { value: 3, label: 'Concierge' },
            ],
          },
          role: {
            label: null,
            placeholder: 'All roles',
            options: [
              { value: 1, label: 'Bartender' },
              { value: 2, label: 'Bellperson' },
              { value: 3, label: 'Engineer' },
            ],
          },
          action: {
            label: 'with action',
            placeholder: 'All actions',
            options: [
              { value: 1, label: 'Clean' },
              { value: 2, label: 'Deliver' },
              { value: 3, label: 'Remove' },
            ],
          },
          status: {
            label: 'with status',
            placeholder: 'All status',
            options: [
              { value: 1, label: 'Not Started' },
              { value: 2, label: 'In Progress' },
              { value: 3, label: 'Done' },
            ],
          },
        },
      },
      definitions: {
        add: 'Add Notification',
        error: 'Add at least one notification',
        type: {
          label: 'Send an',
          placeholder: 'Select notification type',
          options: [
            { value: 'email', label: 'Email Notification' },
            { value: 'push', label: 'App Notification' },
          ],
        },
        timeframe: {
          label: 'at',
          time: {
            placeholder: 'Select target time',
            options: [
              { value: '00:00:00', label: '0 min', context: ['job'] },
              { value: '00:01:00', label: '1 min', context: ['job'] },
              { value: '00:02:00', label: '2 min', context: ['job'] },
              { value: '00:03:00', label: '3 min', context: ['job'] },
              { value: '00:04:00', label: '4 min', context: ['job'] },
              {
                value: '00:05:00',
                label: '5 min',
                context: ['job', 'project'],
              },
              { value: '00:03:00', label: '7 years', context: ['project'] },
              { value: '00:04:00', label: '32 years', context: ['project'] },
            ],
          },
          period: {
            placeholder: 'Select period',
            options: [
              { value: 'before', label: 'before due at', context: ['job'] },
              { value: 'after', label: 'after due at', context: ['job'] },
              {
                value: 'status',
                label: 'after status change',
                context: ['job', 'project'],
              },
              {
                value: 'created',
                label: 'after creation',
                context: ['job', 'project'],
              },
            ],
          },
        },
        roles: {
          label: 'to',
          placeholder: 'Select roles',
          options: [
            { value: 1, label: 'Housekeeping Manager' },
            { value: 2, label: 'Concierge' },
          ],
        },
      },
    },
  },
};

/**
 * Settings Jobs Rules Notifications edit
 */

export const NotificationsEdit: StoryObj<typeof NotificationsForm> = {
  render: (args: NotificationsFormProps) => <NotificationsForm {...args} />,
  args: {
    ...Notifications.args,
    initialValues: {
      notification: {
        ...Notifications.args?.initialValues?.notification,
        name: 'Edit test',
        definitions: [
          {
            filters: {
              context: 'job',
              type: ['guest'],
              assignee: ['NotNull'],
              priority: ['highest', 'high'],
              department: [2, 1],
              role: [1, 2],
              action: [1, 2],
              status: [1, 2],
            },
            type: ['email'],
            timeframe: '00:01:00',
            period: 'after',
            roles: [1],
          },
          {
            filters: {
              context: 'project',
              project: 1,
            },
            type: ['email'],
            timeframe: '00:01:00',
            period: 'created',
            roles: [2],
          },
          {
            filters: {
              context: 'job',
              type: ['internal'],
              assignee: ['NotNull', 'Null'],
              priority: ['highest', 'high'],
              department: [1],
              role: [2],
              action: [1, 2],
              status: [1],
            },
            type: ['push'],
            timeframe: '00:04:00',
            period: 'after',
            roles: [2],
          },
          {
            filters: {
              context: 'project',
              project: 3,
            },
            type: ['email', 'push'],
            timeframe: '00:03:00',
            period: 'after',
            roles: [1],
          },
          {
            filters: {
              context: 'job',
              type: [],
              assignee: [],
              priority: ['highest', 'high', 'medium'],
              department: [1, 2],
              role: [2],
              action: [1, 2],
              status: [1],
            },
            timeframe: '00:05:00',
            period: 'created',
            type: ['email', 'push'],
            roles: [1],
          },
        ],
      },
    },
  },
};

/**
 * Settings Jobs Rules General Settings
 */

export const GeneralSettings: StoryObj<typeof GeneralSettingsForm> = {
  render: (args: GeneralSettingsFormProps) => <GeneralSettingsForm {...args} />,
  args: {
    onFinish: (values) => console.log(`Success: ${values}`),
    onFinishFailed: (values) => console.log('Failed:', values),
    loading: false,
    initialValues: {
      settings: {
        guest: '10',
        internal: '30',
        department: '20',
      },
    },
    i18n: {
      department: {
        label: 'Default Department',
        placeholder: 'Select Default Department',
        options: [
          { label: 'Administration', value: 'admin' },
          { label: 'Concierge', value: '10' },
          { label: 'Bellsperson', value: '20' },
          { label: 'Ball boy', value: '30' },
        ],
      },
      guest: {
        label: 'Guest Request Minutes',
        placeholder: 'Select Guest Request Minutes',
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '20', value: '20' },
          { label: '30', value: '30' },
        ],
      },
      internal: {
        label: 'Internal Request Minutes',
        placeholder: 'Select Internal Request Minutes',
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '20', value: '20' },
          { label: '30', value: '30' },
        ],
      },
    },
  },
};

/**
 * Floor Plan Location
 */

export const FloorPlanLocation: StoryObj<typeof LocationForm> = {
  render: (args: LocationFormProps) => <LocationForm {...args} />,
  args: {
    displaySubmit: false,
    onFinish: (values) => console.log('Success:', values),
    onFinishFailed: (values) => console.log('Failed:', values),
    loading: false,
    initialValues: {
      isActive: true,
    },
    parentLocations: [
      { value: '1', label: 'B1' },
      { value: '2', label: 'B2' },
      { value: '3', label: 'B3' },
    ],
    locations: [
      { value: '1', label: 'Room 101' },
      { value: '2', label: 'Room 201' },
      { value: '3', label: 'Level 1' },
    ],
    locationTypes: [
      { value: '1', label: 'Floor' },
      { value: '2', label: 'Meeting Room' },
      { value: '3', label: 'Room' },
    ],
    roomTypes: [
      { value: '1', label: 'Big Room' },
      { value: '2', label: 'Small Room' },
      { value: '3', label: 'Double Room' },
    ],
    tags: [
      {
        value: '1',
        label: 'Azure',
      },
      {
        value: '2',
        label: 'Crimson',
      },
    ],
  },
};

/**
 * Floor Plan Location Type
 */

export const FloorPlanLocationType: StoryObj<typeof LocationForm> = {
  render: (args: LocationTypeFormProps) => <LocationTypeForm {...args} />,
  args: {
    displaySubmit: false,
    onFinish: (values) => console.log('Success:', values),
    onFinishFailed: (values) => console.log('Failed:', values),
    loading: false,
  },
};

/**
 * Floor Plan Room Type
 */

export const FloorPlanRoomType: StoryObj<typeof RoomTypeForm> = {
  render: (args: RoomTypeFormProps) => <RoomTypeForm {...args} />,
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
  args: {
    displaySubmit: false,
    onFinish: (values) => console.log('Success:', values),
    onFinishFailed: (values) => console.log('Failed:', values),
    loading: false,
    checklists: [
      {
        label: 'Checklist 1',
        value: '1',
      },
      {
        label: 'Checklist 2',
        value: '2',
      },
      {
        label: 'Checklist 3',
        value: '3',
      },
      {
        label: 'Checklist 4',
        value: '4',
      },
      {
        label: 'Checklist 5',
        value: '5',
      },
    ],
  },
};
