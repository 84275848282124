//TODO: provide values in respect of Ant's strategy
export const FONTS = {
  h1: {
    size: 38,
    lineHeight: 46,
    letterSpacing: null,
  },
  h2: {
    size: 30,
    lineHeight: 38,
    letterSpacing: null,
  },
  h3: {
    size: 24,
    lineHeight: 3.2,
    letterSpacing: 0.264,
  },
  h4: {
    size: 20,
    lineHeight: 24,
    letterSpacing: 0.264,
  },
  h5: {
    size: 16,
    lineHeight: 2.4,
    letterSpacing: 0.192,
  },
  large: {
    size: 18,
    lineHeight: 26,
    letterSpacing: 0.216,
  },
  medium: {
    size: 16,
    lineHeight: 24,
    letterSpacing: 0.192,
  },
  small: {
    size: 14,
    lineHeight: 1.2,
    letterSpacing: 0.168,
  },
  xSmall: {
    size: 12,
    lineHeight: 20,
    letterSpacing: 0.144,
  },
};

export const SPACING = {
  NONE: 0,
  SIZE_XXS: 4,
  SIZE_XS: 4,
  SIZE_SM: 8,
  SIZE_DEFAULT: 8,
  SIZE_MS: 12,
  SIZE_MD: 16,
  SIZE_L: 20,
  SIZE_XL: 24,
  SIZE_XXL: 32,
  SIZE_XXXL: 48,
};

export const RADIUS = {
  NONE: 0,
  RADIUS_XS: 1,
  RADIUS_S: 2,
  RADIUS_DEFAULT: 4,
  RADIUS_L: 8,
  RADIUS_XL: 16,
};
