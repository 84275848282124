import { gql } from "@apollo/client";

export default gql`
  query GetInvite($code: String!) {
    user: GetInvite(code: $code) {
      id
      userFirstName
      userLastName
      status
      emailAddr
      mobileNum
      sendNow
      sendText
      userExists
      userName
      orgId
      orgName
      code
      employTypeId
      employTypeName
      roles {
        id
        deptID
        deptName
        roleID
        roleName
      }
    }
  }
`;
