import styled from 'styled-components/macro';
import { GET_JOB_LOGS } from '@optii/shared/queries/activityLog/getJobLogs';
import { GET_ROLES_LIST } from '@optii/shared/index';
import { useQuery } from '@apollo/client';
import { ReactComponent as ArrowIcon } from 'images/svg/arrow-right.svg';
import dayjs from 'dayjs';
import usePropertyTime from 'utils/usePropertyTime';
import jobLog from '@optii/topcat-client/components/shared/LocationView/ActivityLogs/JobLog';
import Loading from '@optii/topcat-client/components/shared/Loading';
import { Log, ItemChanges } from '@optii/topcat-client/components/shared/LocationView/types';
import {
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  Space,
  SPACING,
  Typography,
} from '@optii-solutions/ui-library';
import { GetJobByIdQuery } from '@optii/jobs/api/jobs';
import { getAuditAction } from '@optii/jobs/formatters/jobs';
import { TFunction, useTranslation } from 'react-i18next';
import { LegacyLog } from './JobDetails.types';

const Container = styled.div`
  background-color: white;
  width: 100%;
`;
const Body = styled.p`
  margin: 0;
`;
const NoLogBody = styled.p`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
`;
const ItemBody = styled.span`
  margin: 0;
  font-weight: 400;
  color: #595959;
`;
const Details = styled.span`
  color: #595959;
  font-weight: 400;
`;
const Bold = styled.span`
  margin: 0;
  font-weight: 500;
  color: #595959;
`;

const Date = styled.p`
  margin: 0;
  color: #595959;
  font-weight: 400;
  text-align: right;
`;
const Name = styled.p`
  margin: 0;
  color: #595959;
  font-weight: 400;
  text-align: right;
`;
const ActivityWrap = styled.div`
  margin-top: 5px;
  min-height: 50px;
  display: flex;
  border-bottom: solid 1px #d9d9d9;
`;
const NoLogsWrap = styled.div`
  margin-top: 5px;
  min-height: 236px;
`;
const DateWrap = styled.div`
  width: 35%;
  text-align: end;
`;

const BodyWrap = styled.div`
  display: flex;
  width: 65%;
`;

const TextField = styled.input`
  ${(props) => props.theme.fonts.sizes.large};
  ${(props) => props.theme.fonts.weights.weak};
  text-align: end;
  padding: 0;
  border: none;
  font-family: 'Roboto';
  color: ${COLORS.neutral[8]};
  background-color: transparent;
`;

const filterMetadata = (log: Log) => {
  if (log.eventType === 'Modified' && log.action === 'Modified') {
    return log.metadata?.changes?.filter((item) => item.old !== 'null').length;
  }
  return true;
};

function removeOldLogDuplicates(data: GetJobByIdQuery['audit']) {
  const seenModifiers = new Set();
  const seenCreatedAt = new Set();
  const seenAction = new Set();

  const filteredData = data?.filter((record) => {
    if (record) {
      const { modifier1, createdAt, action } = record;

      if (
        seenModifiers.has(modifier1) &&
        seenCreatedAt.has(createdAt) &&
        seenAction.has(action)
      ) {
        return false; // Skip the item if both modifier1 and createdAt are duplicates
      }

      seenModifiers.add(modifier1);
      seenCreatedAt.add(createdAt);
      seenAction.add(action);

      return true;
    }

    return false;
  });

  return filteredData;
}

function sortByDayjsDate(a: { recordedAt: string }, b: { recordedAt: string }) {
  if (dayjs(a.recordedAt).isBefore(dayjs(b.recordedAt))) {
    return 1;
  }
  return -1;
}

function LegacyActivityLog({
  legacyLog,
  t,
}: {
  legacyLog: LegacyLog;
  t: TFunction<['jobs', 'common']> | undefined;
}) {
  const action = getAuditAction(legacyLog, t);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            colorText: COLORS.neutral[8],
          },
          Divider: {
            verticalMarginInline: SPACING.NONE,
            marginLG: SPACING.NONE,
            colorSplit: '#d9d9d9',
          },
        },
      }}
    >
      <Flex justify="space-between">
        <Space
          direction="vertical"
          size="small"
          style={{
            marginRight: SPACING.SIZE_MD,
          }}
        >
          <Typography.Text
            style={{
              fontSize: FONTS.medium.size,
            }}
          >
            {action}
          </Typography.Text>
        </Space>

        <Space
          direction="vertical"
          size={0}
          style={{
            textAlign: 'end',
          }}
        >
          <Typography.Text
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {legacyLog.recordedAt?.format('L LT')}
          </Typography.Text>
          <Typography.Text>
            {legacyLog.employee.employeeId === -1
              ? 'Optii'
              : `${legacyLog.employee.employeeUserFirstName} ${legacyLog.employee.employeeUserLastName}`}
          </Typography.Text>
        </Space>
      </Flex>
      <Divider />
    </ConfigProvider>
  );
}

export default function ActivityLog({
  jobId,
  legacyLogs,
}: {
  jobId: string;
  legacyLogs: LegacyLog[];
}) {
  const { t } = useTranslation(['common', 'jobs']);
  const { timezone } = usePropertyTime();
  const { data, loading } = useQuery(GET_JOB_LOGS, {
    variables: { aggregateId: jobId },
    context: { _instance: 'node' },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data: rolesData, loading: rolesLoading } = useQuery(GET_ROLES_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const formattedLegacyLogs = removeOldLogDuplicates(legacyLogs).map(
    (legacy) => ({
      ...legacy,
      recordedAt: dayjs.unix(legacy?.createdAt).tz(timezone),
      legacy: true,
    }),
  );

  const logs = data?.jobLogs
    ?.concat(formattedLegacyLogs)
    .sort(sortByDayjsDate)
    .filter(filterMetadata);

  if (loading || rolesLoading) {
    return (
      <Container>
        <NoLogsWrap>
          <Loading loading />
        </NoLogsWrap>
      </Container>
    );
  }

  if (!rolesData || logs?.length === 0) {
    return (
      <Container>
        <NoLogsWrap>
          <NoLogBody>
            <Bold>No available logs for this job.</Bold>
          </NoLogBody>
        </NoLogsWrap>
      </Container>
    );
  }

  const rolesList = rolesData.page.edges;

  return (
    logs && (
      <Container>
        {logs.map((item: Log | LegacyLog) => {
          if ('legacy' in item) {
            return (
              <LegacyActivityLog
                key={item.id}
                legacyLog={item as LegacyLog}
                t={t}
              />
            );
          }
          const date = dayjs(item.recordedAt).tz(timezone).format('L LT');
          const { metadata } = item;
          const { changes } = metadata || {};
          const [status] = changes || [];
          const log = jobLog(item, timezone, undefined, rolesList);
          if (!log) {
            return null;
          }

          const {
            message,
            fullName,
            oldMetadata,
            newMetadata,
            showMeta,
            showArrow,
          } = log;

          if (status) {

            return changes?.map((change) => {
                const log2 = jobLog(item, timezone, change, rolesList);

                if (!log2) {
                  return null;
                }

                const {
                  oldMetadata: changeOldMetadata,
                  newMetadata: changeNewMetadata,
                  showMeta: changeShowMetadata,
                  showArrow: changeShowArrow,
                  itemChanges,
                } = log2;
                let { message: changeMessage } = log2;
                const itemMessage = (
                  <>
                    <ItemBody>Item: </ItemBody>
                    <Bold>Updated</Bold>
                  </>
                );
                if (itemChanges?.length === 0) return null;
                if (itemChanges && itemChanges?.length !== 0) {
                  return itemChanges.map((itemChange: ItemChanges) => {
                    const { newItems, newAmount, oldAmount } = itemChange;
                    const newName = newAmount ? newAmount.name : '';
                    const newItemName = newItems ? newItems.name : '';
                    const newItemAmount = newItems ? newItems.amount : '';
                    changeMessage = oldAmount ? itemMessage : changeMessage;
                    return (
                      <ActivityWrap
                        key={`${item.eventType}-${item.recordedAt}-${item.aggregateVersion}`}
                      >
                        <BodyWrap>
                          <Body>
                            {changeMessage}
                            <br />
                            <>
                              <Details>
                                {`${newAmount ? newName : newItemName} `}{' '}
                                {oldAmount ? `Count: ${oldAmount?.amount}` : ''}
                              </Details>
                              {oldAmount ? <ArrowIcon /> : ''}
                              <Details>
                                {oldAmount ? ` Count: ${newAmount?.amount}` : ` Count: ${newItemAmount}`}
                              </Details>
                            </>
                          </Body>
                        </BodyWrap>
                        <DateWrap>
                          <Date>{date}</Date>
                          <TextField type="button" value={fullName} />
                          {/* <Name>{fullName}</Name> */}
                        </DateWrap>
                      </ActivityWrap>
                    );
                  });
                }

                return loading ? (
                  <Loading loading />
                ) : (
                  <ActivityWrap
                    key={`${item.eventType}-${item.recordedAt}-${item.action}`}
                  >
                    <BodyWrap>
                      <Body>
                        {changeMessage}
                        <br />
                        {changeShowMetadata ? (
                          <>
                            <Details>{`${changeOldMetadata} `}</Details>
                            {changeShowArrow ? <ArrowIcon /> : ''}
                            <Details>{` ${changeNewMetadata}`}</Details>
                          </>
                        ) : (
                          ''
                        )}
                      </Body>
                    </BodyWrap>
                    <DateWrap>
                      <Date>{date}</Date>
                      <TextField type="button" value={fullName} />
                      {/* <Name>{fullName}</Name> */}
                    </DateWrap>
                  </ActivityWrap>
                );
              })
              .filter((change) => !!change);
          }

          return loading ? (
            <Loading loading />
          ) : (
            <ActivityWrap
              key={`${item.eventType}-${item.recordedAt}-${item.action}`}
            >
              <BodyWrap>
                <Body>
                  {message}
                  <br />
                  {showMeta ? (
                    <>
                      <Details>{`${oldMetadata} `}</Details>
                      {showArrow ? <ArrowIcon /> : ''}
                      <Details>{` ${newMetadata}`}</Details>
                    </>
                  ) : (
                    ''
                  )}
                </Body>
              </BodyWrap>
              <DateWrap>
                <Date>{date}</Date>
                <TextField type="button" value={fullName} />
              </DateWrap>
            </ActivityWrap>
          );
        })}
      </Container>
    )
  );
}
