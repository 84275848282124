export function removeTimeFromDate(dateTime) {
  if (!dateTime) return null;
  try {
    const date = dateTime?.toDateString ? dateTime : new Date(dateTime);
    const offset = new Date(date).getTimezoneOffset();
    const [onlyDate] = new Date(new Date(date).getTime() - offset * 60 * 1000)
      .toISOString()
      .split('T');
    return onlyDate;
  } catch (error) {
    console.log('removeTimeFromDate', error);
    return null;
  }
}
