import { gql } from "@apollo/client";

export default gql`
  query ListEmployees(
    $deptId: String
    $status: EmployeeStatus
    $employmentTypeId: String
    $roleId: String
    $cursor: String
    $first: Int
    $orderBy: String
  ) {
    page: ListEmployees(
      filters: {
        deptId: $deptId
        employmentTypeId: $employmentTypeId
        roleId: $roleId
        status: $status
      }
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          ... on EmployeeUser {
            id
            userId
            userName
            userFirstName
            userLastName
            userEmailAddr
            userMobileNum
            status
            propertyDetails {
              id
              propertyId
              propertyName
              employTypeId
              employTypeName
              roles {
                id
                deptId
                deptName
                roleId
                roleName
                roleStatus
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
