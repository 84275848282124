import { normalizeInputFilters } from './utils';
import { EventContext, NotificationInput } from '../../../api/notifications';

interface UpdateProps {
  variables: {
    id: string;
    input: NotificationInput;
  };
}

interface CreateProps {
  variables: {
    input: NotificationInput;
  };
}

interface SubmitProps {
  input: NotificationInput;
  notificationId?: string | null;
  handleUpdate: (agrs: UpdateProps) => Promise<void>;
  handleCreate: (agrs: CreateProps) => Promise<void>;
  metadata: { [key: string]: any };
}

async function temperatureTaskSubmit({ input: _input, notificationId, handleUpdate, handleCreate, metadata }: SubmitProps) {
    const input = normalizeInputFilters(_input);
    const event = input.userEventActionRules[0]?.event;
    const filters = event?.filters;

    if (event) {
        event.context = EventContext.ChecklistTask;
        event.type = ['EntityModified'];
        event.conditions = [{
            type: 'PropertyValueChanged',
            propertyName: 'DataTemperatureValue',
        }];
    }

    if (filters && filters[0]) {

        const min = filters[0].DataTemperatureMin.values[0];
        const max = filters[0]?.DataTemperatureMax.values[0];
        const scale = metadata.formValues.dataTemperatureScale;

        filters[0].TaskType = {
            property: 'TaskType',
            operator: 'In',
            values: [
                'temperature',
            ],
        };
        filters[0].DataTaskNull = {
            property: 'DataTemperatureValue',
            operator: 'NotNull',
            values: [],
        };
        filters[0].TemplateTaskId = {
            property: 'TemplateTaskId',
            operator: 'In',
            values: [metadata.taskId],
        };
        filters[0].DataTask = {
            property: 'DataTemperatureValue',
            operator: 'OutsideRange',
            values: [min, max],
        };

        const displayName = `Temperature Less than ${max}, more than ${min} - [scale:${scale}] - [taskId:${metadata.taskId}]`;

        delete filters[0].scale;
        delete filters[0].DataTemperatureMin;
        delete filters[0].DataTemperatureMax;

        if (notificationId) {
            await handleUpdate({
                variables: {
                    id: notificationId,
                    input: {
                        displayName: input.displayName ?? displayName,
                        userEventActionRules: input.userEventActionRules,
                    },
                },
            });
        } else {
            handleCreate({
                variables: {
                    input: {
                        displayName: input.displayName ?? displayName,
                        userEventActionRules: input.userEventActionRules,
                    },
                },
            });
        }
    }
}

export default temperatureTaskSubmit;
