import { gql } from "@apollo/client";

export default gql`
  mutation updateLocation($id: ID!, $input: UpdateLocation!) {
    updateLocation(id: $id, input: $input) {
      id
      isActive
      parentId
      isVirtual
      virtualChildrenLocationIds
      parentLocation {
        id
        displayName
        name
        number
      }
      associatedVirtualLocations {
        id
        name
        parentId
      }
      childrenIds
      status
      locationType {
        id
        displayName
        code
        squareUnit
        note
      }
      roomType {
        id
        displayName
        code
        note
      }
      number
      name
      description
      displayName
      longDisplayName
      shortDisplayName
      class
      squareUnit
      notes
      sequence
      tags {
        name
      }
      title: displayName
      pId: parentId
      value: id
      __typename
    }
  }
`;
