import React, { useCallback, useContext, useEffect } from "react";
import { GET_PROPERTY_BY_ID } from "../queries/property/getPropertyById";
import { useLazyQuery } from "@apollo/client";
import { PropertyContext } from '@optii/shared';

type TUseProductAccessProps = number | string;

type TCanProduct = TProductToCheck[];

type TProductToCheck = number | string;

export function useProductAccess(props?: TUseProductAccessProps) {
  const { property } = useContext(PropertyContext.Context);

  const [getProperty, { data }] = useLazyQuery(GET_PROPERTY_BY_ID);
  const products = data && data.property && data.property.products;
  useEffect(
    () => {
      if (property) {
        const id = props || (property && property.id);
        getProperty({
          fetchPolicy: "cache-first",
          variables: {
            id
          }
        });
      }
    },
    [property, props]
  );

  const canProduct = useCallback((productsToCheck: TCanProduct) => {
    if (!productsToCheck || !productsToCheck.length) return true;
    return (
      products &&
      productsToCheck.reduce((acc, product) => acc || products.includes(product), false)
    );
  }, [products]);

  return { canProduct };
}
