import Low from './Low';
import Medium from './Medium';
import High from './High';
import Highest from './Highest';
import LowOnHold from './LowOnHold';
import MediumOnHold from './MediumOnHold';
import HighOnHold from './HighOnHold';
import HighestOnHold from './HighestOnHold';
import Cancelled from './Cancelled';

export const Priority = {
  Low,
  Medium,
  High,
  Highest,
  Cancelled,
  LowOnHold,
  MediumOnHold,
  HighOnHold,
  HighestOnHold,
};
