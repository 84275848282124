import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { UserAccessContext, PropertyContext, useSnack } from '@optii/shared';

import { GET_EMPLOYEE_BY_USER_ID } from '@optii/shared/queries/employee/getEmployeeByUserId';

import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';

const Context = React.createContext({
  employee: {
    id: '',
  }
});

const SYS_OPTII_ADMIN_ID = '-1';

const Provider = function (props) {
  const { setSnack, Snack } = useSnack();
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const [employee, setEmployee] = useState(false);
  const [getEmployee, { data, error: employeeError }] = useLazyQuery(
    GET_EMPLOYEE_BY_USER_ID,
  );

  // handle failed employee errors
  useEffect(
    (_) => {
      if (user && user.id === SYS_OPTII_ADMIN_ID) {
        setEmployee({ propertyDetails: [] });
      } else if (employeeError) {
        setSnack({
          message: "An error occurred fetching user's employment",
          error: true,
          timeout: 6000,
        });
      }
    },
    [employeeError],
  );

  useEffect(
    (_) => {
      if (
        user &&
        property &&
        user.id === property.userId &&
        Auth.Instance.isTokenValid(0)
      ) {
        // When property is updated, get employment
        getEmployee({
          variables: { id: user.id },
        });
      }
    },
    [property, user],
  );
  useEffect(
    (_) => {
      if (data && data.employee) {
        setEmployee(data.employee);
      }
    },
    [data],
  );

  const contextValue = useMemo(
    () => ({
      employee,
      resetContext: (_) => {
        setEmployee(false);
      },
    }),
    [employee, setEmployee],
  );

  return (
    <Context.Provider value={contextValue}>
      {props.children}
      <Snack />
    </Context.Provider>
  );
};
export const EmploymentContext = { Provider, Context };
