import { gql } from "@apollo/client";

export const FIND_ALL_ASSETS = gql`
  query Assets($cursor: String, $first: Int, $Sorts: String, $filters: String) {
    page: assets(
      first: $first
      Sorts: $Sorts
      filters: $filters
      after: $cursor
    ) {
      totalCount
      edges {
        node {
          ... on Asset {
            id
            displayName
            serialNumber
            notes
            status
            installationDate
            location {
              id
              displayName
              shortDisplayName
              longDisplayName
              title: shortDisplayName
              value: id
            }
            assetType {
              id
              displayName
              modelName
              jobItem {
                id
                displayName
              }
              documents {
                uuid
              }
              brand {
                id
                displayName
              }
              serviceCycle {
                type
                value
                displayName
              }
              lifeCycle {
                number
                lifeCyclePeriodType
              }
              note
              description
            }
            purchase {
              id
              date
              price
              orderNumber
              invoiceNumber
            }
            vendor {
              id
              displayName
              website
              address
              phone
            }
            warranty {
              number
              warrantyPeriodType
              description
            }
            documents {
              uuid
            }
            version
            __typename
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
