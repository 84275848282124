import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ConfigProvider,
  Typography,
  COLORS,
  FONTS,
  SPACING,
  Flex,
  Card,
  ThemeConfig,
  Row,
  Col,
  Switcher,
} from '@optii-solutions/ui-library';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'styled-components';
import moment from 'moment';
import { UPDATE_CLEANING_EXCEPTION_STATUS } from 'queries';
import SnackBar from 'components/shared/SnackBar';

const LOCATION_HOUSEKEEPING_STATUS_THEME: ThemeConfig = {
  components: {
    Card: {
      borderRadius: SPACING.SIZE_SM,
      padding: SPACING.SIZE_SM,
    },
    Typography: {
      fontSize: FONTS.medium.size,
      fontWeightStrong: 400,
      colorText: COLORS.neutral[9],
    },
  },
};

type TStyles = {
  [key: string]: CSSProperties;
};

const EXCEPTION_STATUSES = [
  {
    label: 'Do Not Disturb',
    value: 'doNotDisturb',
  },
  {
    label: 'Double Lock Active',
    value: 'doubleLockActive',
  },
  {
    label: 'No Service Required',
    value: 'noServiceRequired',
  },
  {
    label: 'Sleep Out / No Show',
    value: 'sleepOutNoShow',
  },
];

const DEFAULT_EXPCEPTION_STATUS = {
  doNotDisturb: false,
  doubleLockActive: false,
  noServiceRequired: false,
  sleepOutNoShow: false,
};

const STYLES: TStyles = {
  card: {
    border: `1px solid ${COLORS.neutral[5]}`,
    minWidth: '100%',
    flex: 1,
  },
  cardBody: {
    padding: SPACING.SIZE_MD,
  },
  cardBodyLabel: {
    fontWeight: 500,
    fontSize: FONTS.xSmall.size,
    color: COLORS.neutral[10],
    lineHeight: `${FONTS.medium.lineHeight}px`,
  },
  cardBodyValueText: {
    fontStyle: 'italic',
  },
  switch: {
    marginRight: SPACING.SIZE_MS,
    marginTop: '0.4rem',
  },
  switchContainer: {
    alignItems: 'start',
    marginBottom: SPACING.SIZE_MD,
  },
};

export type OutOfOrderDate = {
  code: string;
  startDate: string;
  endDate?: string;
};

export type CleaningExceptionStatus = {
  doNotDisturb: boolean;
  doubleLockActive: boolean;
  noServiceRequired: boolean;
  sleepOutNoShow: boolean;
};

type TLocationHousekeepingStatusProps = {
  occupancyStatus: string;
  cleaningStatus: string;
  cleaningExceptionStatus: CleaningExceptionStatus;
  outOfOrderDates?: OutOfOrderDate[];
  locationId: string;
  locationName?: string;
};

export function LocationHousekeepingStatus(
  props: TLocationHousekeepingStatusProps,
) {
  const {
    occupancyStatus,
    cleaningStatus,
    outOfOrderDates = [],
    locationId,
    locationName = '',
    cleaningExceptionStatus = DEFAULT_EXPCEPTION_STATUS,
  } = props;
  const { t } = useTranslation(['common', 'floorplan']);
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState(cleaningExceptionStatus);
  const [message, setMessage] = useState('');

  const setSnack = ({
    message,
    timeout = 3000,
  }: {
    message: string;
    timeout?: number;
  }) => {
    const code = Date.now();
    setMessage(message);
    setTimeout(() => {
      setMessage('');
    }, timeout);
  };

  const occupancyCode =
    occupancyStatus === 'Out of Order' ? 'OO' : occupancyStatus[0];

  const displayCode = `${occupancyCode || ''}${cleaningStatus[0] || ''}`;
  const displayName = `${occupancyStatus} ${cleaningStatus}`;

  const [updateCleaningExceptionStatus, { loading }] = useMutation(
    UPDATE_CLEANING_EXCEPTION_STATUS,
    { errorPolicy: 'all' },
  );

  const formatDate = (unformattedDate: OutOfOrderDate) => {
    const start = moment(unformattedDate.startDate).format('MMMM D, YYYY');
    const end = `- ${moment(unformattedDate.endDate).format('MMMM D, YYYY')}`;
    return `${unformattedDate.code} | ${start} ${
      unformattedDate.endDate ? `- ${end}` : ''
    }`;
  };

  const handleChangeExceptionStatus = (field: string, value: boolean) => {
    const cleaningExceptionInput = {
      locationId,
      cleaningExceptionStatus: {
        ...status,
        [field]: value,
      },
    };
    setStatus(cleaningExceptionInput.cleaningExceptionStatus);
    updateCleaningExceptionStatus({
      variables: {
        cleaningExceptionInput,
      },
      context: { _instance: 'node' },
    })
      .then(() => {
        const exceptionName = EXCEPTION_STATUSES.find(
          (exception) => exception.value === field,
        )?.label;
        if (value) {
          setSnack({ 
            message: t(`${locationName} has been updated to ${exceptionName}`),
          });
        } else {
          setSnack({
            message: t(`${locationName} is no longer ${exceptionName}`),
          });
        }
      })
      .catch(() => {
        setStatus((current) => ({ ...current, [field]: !value }));
      });
  };

  const DatesRender = () => {
    if (expanded) {
      return (
        <>
          {outOfOrderDates?.map((date) => (
            <Typography.Text>{formatDate(date)}</Typography.Text>
          ))}
          <Typography.Link underline onClick={() => setExpanded(false)}>
            {t('floorplan:Close')}
          </Typography.Link>
        </>
      );
    }
    return (
      <>
        <Typography.Text>
          {outOfOrderDates &&
            outOfOrderDates.length > 0 &&
            formatDate(outOfOrderDates[0])}
        </Typography.Text>
        {outOfOrderDates && outOfOrderDates.length > 1 && (
          <Typography.Link underline onClick={() => setExpanded(true)}>
            {t('floorplan:View All')}
          </Typography.Link>
        )}
      </>
    );
  };

  return (
    <ConfigProvider theme={LOCATION_HOUSEKEEPING_STATUS_THEME}>
      <Card style={STYLES.card} bodyStyle={STYLES.cardBody}>
        <Row>
          <Col span={12}>
            <Typography.Text style={STYLES.cardBodyLabel}>
              {t('floorplan:Room Status')}
            </Typography.Text>
            <Flex>
              <Typography.Text>
                {displayCode ? `(${displayCode})` : ''}
              </Typography.Text>
              &nbsp;
              <Typography.Text style={STYLES.cardBodyValueText}>
                {displayName || ''}
              </Typography.Text>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical>
              <Typography.Text style={STYLES.cardBodyLabel}>
                {t('floorplan: Out of Order')}
              </Typography.Text>
              {outOfOrderDates.length > 0 ? (
                <DatesRender />
              ) : (
                <Typography.Text style={STYLES.cardBodyValueText}>
                  {t('floorplan: none')}
                </Typography.Text>
              )}
            </Flex>
          </Col>
        </Row>
        <Divider />
        <Row style={STYLES.switchRow}>
          {EXCEPTION_STATUSES.map((exception) => (
            <Col span="12" key={exception.value}>
              <Flex style={STYLES.switchContainer}>
                <Switcher
                  onChange={(value) =>
                    handleChangeExceptionStatus(exception.value, value)
                  }
                  value={
                    status[exception.value as keyof CleaningExceptionStatus]
                  }
                  size="small"
                  style={STYLES.switch}
                  loading={loading}
                />
                <Typography>{t(`floorplan:${exception.label}`)}</Typography>
              </Flex>
            </Col>
          ))}
        </Row>
      </Card>
      <SnackBar modifiers={{}} show={message} message={message} />
    </ConfigProvider>
  );
}
