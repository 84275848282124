import ButtonPrimary from '@optii/shared/components/atoms/ButtonPrimary';
import { Confirmation } from '@optii/shared';

export type TPassWithFailConfirmationProps<T> = {
    hide: () => void;
    onCompleteJob: () => void;
    qty: number,
    t: (a: string, b?: T) => string;
};

export default function PassWithFailConfirmation({
    hide,
    onCompleteJob,
    t,
    qty,
}: TPassWithFailConfirmationProps<{ displayName?: string, qty: number, tasks: string }>) {

    const TEXTS = {
        title: t('jobs:Confirmation'),
        action: t('common:Complete & Pass'),
        description: t('jobs:{{qty}} {{tasks}} in this job have failing entries or are outside of acceptable range.', {
            qty,
            tasks: qty > 1 ? t('jobs:tasks') : t('jobs:task'),
        }),
    };

    return (
        <Confirmation
            title={TEXTS.title}
            hide={() => hide()}
            action={TEXTS.action}
            confirmText={TEXTS.action}
            onConfirm={async () => {
                await onCompleteJob();
            }}
            onHide={() => hide()}
            modifiers={['fullWidthMobile']}
            isSubModal
            confirmComponent={ButtonPrimary}
        >
            <p>{TEXTS.description}</p>
        </Confirmation>
    );
}
