import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import { usePropertyTime } from '@optii/shared';

import { formatUserDisplayName } from '@optii/jobs/formatters/user';
import { getAuditAction } from '@optii/jobs/formatters/jobs';
import { Dayjs } from 'dayjs';

const Container = styled.div``;
const HistoricalAct = styled.div`
  ${props => props.theme.fonts.sizes.smallest}
  color: ${props => props.theme.colors.charcoal05};
  font-style: italic;
`;

type THistorySummaryProps = {
  job: THistorySummaryJob;
  audit: THistorySummaryLastAct[];
};

type THistorySummaryJob = {
  reporter: string;
  createdAt: Dayjs;
  lastAct?: THistorySummaryLastAct | null;
  lastActAt: Dayjs;
};

type THistorySummaryLastAct = {
  action: string;
  createdAt: string;
};

export function HistorySummary(props: THistorySummaryProps) {
  const { audit, job } = props;
  
  const { toPropertyTime } = usePropertyTime(null);
  const [state, setState] = useState<THistorySummaryJob | null>(null);
  const { t } = useTranslation(['common'], { useSuspense: false });

  useEffect(
    () => {
      if (job?.reporter) {
        const { reporter } = job;
        const createdAt = toPropertyTime(job.createdAt);

        const lastAct = audit?.length > 0 ? audit[0] : null;
        const lastActAt =
          lastAct && lastAct.createdAt && toPropertyTime(lastAct.createdAt);
        setState({ reporter, createdAt, lastAct, lastActAt });
      }
    },
    [toPropertyTime, job, audit],
  );

  if (!state) return null;

  const { reporter, createdAt, lastAct, lastActAt } = state;

  return (
    <Container {...props}>
      <HistoricalAct>
        {t('common:Added by')}{' '}
        {reporter ? formatUserDisplayName(reporter) : t('common:Unknown')}{' '}
        {t('common:on')}{' '}
        {createdAt && createdAt.format(`ll [${t('common:at')}] LTS`)}
      </HistoricalAct>

      {lastAct && lastAct.action !== 'created' && (
        <HistoricalAct>
          {getAuditAction(lastAct, t)} {t('common:on')}{' '}
          {lastActAt && lastActAt.format(`ll [${t('common:at')}] LTS`)}
        </HistoricalAct>
      )}
    </Container>
  );
}
