import React from 'react';
import { FIND_ALL_ASSET_TYPES } from 'queries';
import { useQuery } from '@apollo/client';
import { initGQLData as initData } from "utils/initGQLData";

/**
 * Hook for using FIND_ALL_ASSET_TYPES query.
 * @param {Object} props - Settings for the hook
 * @param {boolean} [props.skip=false] - Should skip or not the fetch.
 * @returns {UseAssetTypesReturn}
 */
export function useAssetTypes({
  skip = false
} = {}) {

  const { data, loading, error } = useQuery(FIND_ALL_ASSET_TYPES, {
    skip,
    context: { _instance: "node" }
  });

  const assetTypesOptions = React.useMemo(() => initData(data).map((assetType) => ({
      label: assetType.displayName,
      value: assetType.id
    })), [data]);

  const assetTypesFormated = React.useMemo(() => initData(data), [data]);

  return {
    assetTypes: data,
    assetTypesOptions,
    assetTypesFormated,
    loading,
    error,
  };
}
