import { useCallback, useEffect, useState } from 'react';
import { Client, Conversation } from '@twilio/conversations';
import dayjs from 'dayjs';
import { SelectOption } from '../components/message/types';
import { Channel } from '../types';
import { getAllConversationList } from '../utils/message';

const GENERAL_CHANNEL_FRIENDLY_NAME = 'ALL';

async function mapPrivateConversation(
  conversation: Conversation,
  employees?: SelectOption[],
  userId?: string,
) {
  const unreadMessageCount = (await conversation.getUnreadMessagesCount()) || 0;
  const recipientIds = conversation.friendlyName
    ?.split('_')
    .filter((item) => item !== userId);

  const employeeNames = recipientIds
    ?.map((recipient) => {
      const employeeName = employees?.find(
        (item) => item.value === recipient,
      )?.label;

      return employeeName;
    })
    .join(', ');

  const lastMessageIndex = conversation.lastMessage;
  const getMessage = await conversation.getMessages(
    1,
    lastMessageIndex?.index,
    'backwards',
  );

  const lastMessage = {
    content: getMessage.items[0]?.body,
    timestamp: dayjs(getMessage.items[0]?.dateUpdated),
  };

  return {
    key: conversation.sid,
    recipientIds,
    label: employeeNames || recipientIds?.join(', ') || null,
    unreadMessageCount,
    lastMessage,
  };
}

enum ChannelType {
  'Public' = 'Public',
}

type Attributes = {
  ChannelType: ChannelType;
};

type ChannelState = {
  publicChannels: Channel[];
  privateChannels: Channel[];
};

async function formatConversations(
  channelType: string,
  conversations: Conversation[],
  employees?: SelectOption[],
  userId?: string,
) {
  let result: Channel[];
  if (channelType === 'Public') {
    result = await Promise.all(
      conversations
        .filter(
          (conversation) => (conversation.attributes as Attributes).ChannelType,
        )
        .map(async (conversation) => {
          const unreadMessageCount =
            (await conversation.getUnreadMessagesCount()) || 0;
          return {
            key: conversation.sid,
            label: conversation.friendlyName,
            unreadMessageCount,
          };
        }),
    );
  } else {
    result = await Promise.all(
      conversations
        .filter(
          (conversation) =>
            !(conversation.attributes as Attributes).ChannelType &&
            conversation.friendlyName !== userId,
        )
        .map(async (conversation) =>
          mapPrivateConversation(conversation, employees, userId),
        ),
    );
  }
  const findGeneralChannel = result.find((item) =>
    item.label
      ?.toLowerCase()
      .includes(GENERAL_CHANNEL_FRIENDLY_NAME.toLowerCase()),
  );

  result.sort((a, b) => {
    if (a.label && b.label) {
      return a.label.localeCompare(b.label);
    }
    return 0;
  });

  if (findGeneralChannel) {
    result = [findGeneralChannel].concat(
      result.filter(
        (conversation) =>
          !conversation.label
            ?.toLowerCase()
            .includes(GENERAL_CHANNEL_FRIENDLY_NAME.toLowerCase()),
      ),
    );
  }

  return result;
}

export default function useChat(
  client: Client | null,
  employees: SelectOption[],
) {
  const [channels, setChannels] = useState<ChannelState>({
    privateChannels: [],
    publicChannels: [],
  });
  const getChannelById = useCallback(
    async (sid: string): Promise<Conversation | undefined> => {
      const currentChannel = await client?.getConversationBySid(sid);

      return currentChannel;
    },

    [client],
  );

  const getAllChannels = useCallback(async () => {
    const conversations = await client?.getSubscribedConversations();
    const allConversations = await getAllConversationList(
      conversations,
      conversations?.hasNextPage,
    );

    const privateChannels = await formatConversations(
      'Private',
      allConversations,
      employees,
      client?.user.identity,
    );

    const publicChannels = await formatConversations(
      'Public',
      allConversations,
      employees,
    );

    setChannels({
      privateChannels,
      publicChannels,
    });
  }, [client, employees]);

  useEffect(() => {
    if (client) {
      getAllChannels();
    }
  }, [getAllChannels, client]);

  return {
    channels,
    setChannels,
    employees,
    getChannelById,
    mapPrivateConversation,
  };
}
