import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  marginRight: ({ theme }) => `
    margin-right: 2.5rem;
  `,
};
const RadioBtn = styled.div`
  position: relative;
  margin-bottom: 1rem;
  label {
    color: ${(props) => props.theme.colors.charcoal};
    ${(props) => props.theme.fonts.sizes.large}
    ${(props) => props.theme.fonts.weights.light}
    margin-left: 1rem;
    padding-left: 1.2rem;
    display: inline-block;
  }
  input {
    padding: 0;
    margin: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked + span {
      border-color: ${(props) => props.theme.colors.persianGreenLight};
      background: ${(props) => props.theme.colors.persianGreenLight};
      &:after {
        position: absolute;
        content: '';
        top: 0.4rem;
        left: 0.4rem;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 100%;
        background: ${(props) => props.theme.colors.white};
      }
    }
  }
  span {
    position: absolute;
    ${(props) => props.hideButton && 'display: none;'}
    content: "";
    top: 0.3rem;
    left: 0;
    ${(props) => props.theme.borders.default};
    border-radius: 100%;
    box-shadow: inset 0 1px 1px 0 rgba(128, 125, 125, 0.5);
    width: 1.3rem;
    height: 1.3rem;
  }
  ${applyStyleModifiers(CONFIG)};
`;

export default RadioBtn;
