import {
  Input as AntInput,
  ConfigProvider,
  ThemeConfig,
  type InputProps,
} from 'antd';

type Props = InputProps & {
  theme?: ThemeConfig;
};

export function Input(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntInput {...rest} />
    </ConfigProvider>
  );
}
