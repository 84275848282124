import styled from 'styled-components/macro';
import React, { useState } from 'react';
import { ReactComponent as Help } from 'images/svg/help.svg';
// Want to move the help stuff into its own component
const QuestionMark = styled.div`
  position: absolute;
  top: 0.7rem;
  right: -3rem;
  display: inline-block;
  .tooltiptext {
    width: 19rem;
    background-color: #555;
    color: #fff;
    text-align: left;
    border-radius: 7px;
    padding: 0.5rem 1.4rem;
    position: absolute;
    ${(props) => props.theme.zIndex.low};
    bottom: 140%;
    right: -2rem;
    font-size: 14px;
    font-weight: 600;
    transition: opacity 0.3s;
  }
  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 80%;
    border-width: 8px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  svg {
    width: 2rem;
  }
`;
function HelpTip(props) {
  const { text } = props;

  const [tipOpen, setTipOpen] = useState(false);
  function toggleTip() {
    setTipOpen((tip) => !tip);
  }
  return (
    <QuestionMark onClick={toggleTip}>
      <Help />
      {tipOpen && <span className="tooltiptext">{text}</span>}
    </QuestionMark>
  );
}
export default HelpTip;
