import styled from 'styled-components/macro';
import { ReactComponent as Cross } from 'images/svg/cross.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  right: ({ theme }) => `
    margin-left: auto
  `,
};

const CrossIcon = styled(Cross)`
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  margin-top: 0.6rem;
  ${(props) => props.theme.animations.hoverUp()}

  ${applyStyleModifiers(CONFIG)};
`;
export default CrossIcon;
