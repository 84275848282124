import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { GET_FILE_BY_ID } from 'queries';
import { useQuery } from '@apollo/client';
import Icons from 'blocks/Icons';
import Loading from 'components/shared/Loading';
import loadImage from 'blueimp-load-image';
import ImagePreview from 'components/shared/ImagePreview';
import { useTranslation } from 'react-i18next';

const ImageContainer = styled.div`
  canvas {
    border: ${(props) =>
      props.isSelected
        ? `1px solid ${props.theme.colors.persianGreenMed} !important`
        : `1px solid ${props.theme.colors.white}`};
  }
  display: flex;
  cursor: pointer;
`;

const Image = styled.div``;
const FileDownload = styled.a`
  ${(props) =>
    props.small ? 'width: 3rem; height: 3rem;' : 'height: 12rem; width: 12rem'};
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${(props) => props.small && 'margin-bottom: 0.4rem'};
  ${(props) => !props.small && 'padding: 2.5rem 1.5rem;'}
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.weights.medium}
  background-color: ${(props) =>
    props.showFileAsIcon ? 'none' : props.theme.colors.charcoal01};
`;
const FilePlaceholder = styled.div`
  ${(props) =>
    props.small
      ? `
    width: 3rem;
    height: 3rem;
  `
      : `
    height: 12rem;
    width: 12rem;
  `}

  background-color: ${(props) => props.theme.colors.charcoal01};
`;

const DownloadIcon = styled(Icons.Download)`
  ${(props) => (props.small ? 'margin-top: 0.5rem' : 'margin-bottom: 1rem;')}
`;

const DeleteIcon = styled(Icons.Delete)`
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
`;

const FileIcon = styled(Icons.File)`
  cursor: pointer;
`;

const DownloadText = styled.div`
  ${(props) => props.small && 'display: none'};
`;
const FileName = styled.div`
  ${(props) => props.small && 'display: none'};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 11rem;
`;
const LoadingPlaceholder = styled(Loading)`
  margin-bottom: 1rem;
  transform: scale(0.5);
`;
function GalleryElement({
  fileId,
  small,
  onDelete,
  allowFileDeletion,
  showFileAsIcon,
  setSelectedAttachment,
  selectedAttachment,
  fullImage,
}) {
  const { data } = useQuery(GET_FILE_BY_ID, {
    variables: { id: fileId },
  });
  const [showPreview, setShowPreview] = useState(false);
  const { t } = useTranslation(['common']);

  const imgContainerEl = useRef(null);
  const superImgContainerEl = useRef(null);

  const file = data && data.file;
  // Tricky code to try and discover file type, worse case scenario, they just download the image without getting a thumbnail
  const matched =
    file &&
    file.name.match(/\.\w{3,4}($)/) &&
    file.name.match(/\.\w{3,4}($)/)[0];

  const isImage = [
    '.jpg',
    '.png',
    '.jpeg',
    '.gif',
    '.svg',
    '.JPG',
    '.PNG',
    '.JPEG',
    '.GIF',
    '.SVG',
  ].includes(matched);

  const load = useCallback(async () => {
    if (file) {
      imgContainerEl.current.innerHTML = '';
      const imageData = await loadImage(
        file.URL,
        small
          ? {
              crop: true,
              maxWidth: 30,
              maxHeight: 30,
            }
          : {
              orientation: true,
              maxWidth: 120,
            },
      );
      if (small) {
        imageData.image.style.border = `1px solid #Ffff`;
        imageData.image.style['border-radius'] = '0.7rem';
      }
      imgContainerEl.current?.appendChild(imageData.image);

      // Used for the overlay preview when the image is clicked
      if (superImgContainerEl.current) {
        superImgContainerEl.current.innerHTML = '';
      }
      const superData = await loadImage(file.URL, { orientation: true });
      if (superImgContainerEl.current) {
        superImgContainerEl.current.appendChild(superData.image);
      }
    }
  }, [file, small]);

  useEffect(() => {
    if (isImage) {
      load();
    }
  }, [isImage, load]);

  function downloadFile(e, _file) {
    e.preventDefault();
    fetch(_file.URL)
      .then((res) => res.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = _file.name;
        link.click();
        link.remove();
      });
  }

  function handleClick(e) {
    e.preventDefault();
    if (setSelectedAttachment) {
      if (selectedAttachment?.fileId === fileId) {
        setSelectedAttachment(null);
      } else {
        setSelectedAttachment({
          file,
          fileId,
        });
      }
    }
  }

  if (!file) {
    return (
      <FilePlaceholder small={small}>
        <LoadingPlaceholder loading />
      </FilePlaceholder>
    );
  }

  return isImage ? (
    <div style={{ position: 'relative' }} onClick={handleClick} role="link">
      {onDelete && (
        <DeleteIcon onClick={() => onDelete(fileId)} modifiers={['small']} />
      )}
      <ImageContainer
        isSelected={selectedAttachment?.fileId === fileId}
        ref={imgContainerEl}
        onClick={(e) => {
          e.preventDefault();
          if (!small && !fullImage) {
            setShowPreview(true);
          }
        }}
      />
      <ImagePreview
        hidden={!showPreview}
        superModal
        handleClickBackdrop={(e) => {
          e.stopPropagation();
          setShowPreview(false);
        }}
      >
        <Image
          ref={superImgContainerEl}
          onClick={(e) => {
            e.preventDefault();
            setShowPreview(false);
          }}
        />
      </ImagePreview>
    </div>
  ) : (
    <FileDownload
      small={small}
      onClick={(e) => downloadFile(e, file)}
      showFileAsIcon={showFileAsIcon}
    >
      {onDelete && allowFileDeletion && (
        <DeleteIcon
          onClick={(e) => {
            e.preventDefault();
            onDelete(fileId);
          }}
          modifiers={['small']}
        />
      )}
      {showFileAsIcon ? (
        <FileIcon />
      ) : (
        <>
          <DownloadIcon small={small} />
          <DownloadText small={small}>{t('common:Download')}</DownloadText>
          <FileName small={small}>{file.name}</FileName>
        </>
      )}
    </FileDownload>
  );
}

export default GalleryElement;
