import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Icons from 'blocks/Icons';

const ClearContainer = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.charcoal03};
  padding: 0 0.7rem;
  margin-right: 0.7rem;
  svg {
    margin: 0 auto;
    padding: 0;
  }
`;
function DateTimeClearIndicator(props) {
  const { t } = useTranslation(['fields']);
  const {
    children = (
      <ClearContainer>
        <Icons.Cross title={t('fields:Clear')} />
      </ClearContainer>
    ),
    onClick,
  } = props;
  return (
    <div data-testid="date-time-clear-indicator" onClick={onClick} role="link">
      {children}
    </div>
  );
}

export default DateTimeClearIndicator;
