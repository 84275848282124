import {
  Form as AntForm,
  ConfigProvider,
  FormItemProps,
  FormProps,
  ThemeConfig,
  Typography,
} from 'antd';
import { FormListProps } from 'antd/es/form';
import { ReactNode } from 'react';
import { DEFAULT_THEME_COLORS, FONTS } from '../../..';

type Props = FormProps & {
  children: ReactNode;
  theme?: ThemeConfig;
};

type LabelProps = {
  children: ReactNode;
};

function Label({ children }: LabelProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: FONTS.xSmall.size,
          colorText: DEFAULT_THEME_COLORS.token.form.textLabel,
        },
      }}
    >
      <Typography.Text
        style={{
          fontWeight: 500,
          letterSpacing: FONTS.xSmall.letterSpacing,
        }}
      >
        {children}
      </Typography.Text>
    </ConfigProvider>
  );
}

export function FormList(props: FormListProps) {
  return <AntForm.List {...props} />;
}

export function FormItem<T = any>(props: FormItemProps<T>) {
  return (
    <AntForm.Item
      {...props}
      labelAlign={props.labelAlign || 'left'}
      label={props.label ? <Label>{props.label}</Label> : undefined}
    >
      {props.children}
    </AntForm.Item>
  );
}

export function Form(props: Props) {
  const { layout = 'vertical', size = 'middle', theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntForm {...rest} size={size} layout={layout}>
        {props.children}
      </AntForm>
    </ConfigProvider>
  );
}
