import styled from 'styled-components/macro';
import { ReactComponent as Icon } from 'images/svg/document.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

const File = styled(Icon)`
  ${applyStyleModifiers(CONFIG)};
  width: 3.2rem;
  height: 3.2rem;
`;

export default File;
