import { gql } from "@apollo/client";

export const GET_JOB_LOGS = gql`
  query JobActivityLog($aggregateId: ID!) {
    jobLogs(aggregateId: $aggregateId) {
      aggregateId
      aggregateType
      eventType
      aggregateVersion
      action
      domain
      user {
          id
          firstName
          lastName
        }
      recordedAt
      metadata {
        changes {
          key
          new
          old
        }
        message
        roles
      }
    }
  }
`