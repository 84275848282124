export const getCycleTypes = t => [
  { displayName: t("projects:Days"), key: "day", id: 1 },
  { displayName: t("projects:Weeks"), key: "week", id: 2 },
  { displayName: t("projects:Months"), key: "month", id: 3 },
  { displayName: t("projects:Years"), key: "year", id: 4 }
];
export const getDaysOfWeek = t => [
  { displayName: t("common:Sunday"), key: "sunday", id: 1 },
  { displayName: t("common:Monday"), key: "monday", id: 2 },
  { displayName: t("common:Tuesday"), key: "tuesday", id: 3 },
  { displayName: t("common:Wednesday"), key: "wednesday", id: 4 },
  { displayName: t("common:Thursday"), key: "thursday", id: 5 },
  { displayName: t("common:Friday"), key: "friday", id: 6 },
  { displayName: t("common:Saturday"), key: "saturday", id: 7 }
];
export const getPositionOfMonth = t => [
  { displayName: t("projects:First"), key: "first" },
  { displayName: t("projects:Second"), key: "second" },
  { displayName: t("projects:Third"), key: "third" },
  { displayName: t("projects:Last"), key: "last" }
];

export const serviceCycleTypes = {
  cron: "cron",
  days: "days",
  weeks: "weeks",
  months: "months",
  years: "years",
  weeklyOnDay: "weeklyOnDay",
  monthlyOnDayOfWeek: "monthlyOnDayOfWeek",
  noRepeat: "noRepeat"
};
