import {
  ConfigProvider,
  ThemeConfig,
  TimePicker,
  type TimeRangePickerProps,
} from 'antd';

const { RangePicker: AntTimeRangePicker } = TimePicker;

type Props = TimeRangePickerProps & {
  theme?: ThemeConfig;
};

export function TimeRangePicker({ theme, ...props }: Props) {
  return (
    <ConfigProvider theme={theme}>
      <AntTimeRangePicker {...props} />
    </ConfigProvider>
  );
}
