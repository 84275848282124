import type { GetJobByIdQuery } from '@optii/jobs/api/jobs';

import {
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from '../JobForm/JobForm.constants';
import type {
  TJobDetailsAttachmentsData,
  TJobDetailsTabsData,
  TTransformTakeAndStartJobData,
  TTransformTakeJobData,
  TTransformUpdateJobData,
} from './JobDetails.types';

export function transformUpdateJobData({
  job,
  newJob,
}: TTransformUpdateJobData) {
  if (job) {
    const keepPreviousData = ['action', 'status'];

    const nextJob = keepPreviousData.reduce(
      (previous, current) => ({
        ...previous,
        [current]: job[current as keyof typeof job],
      }),
      {},
    );

    Object.assign(nextJob, {
      ...newJob,
    });

    return nextJob;
  }

  return null;
}

export function transformTakeJobData({ user }: TTransformTakeJobData) {
  const assignee = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
  };

  const nextJob = {
    assignee,
  };

  return nextJob;
}

export function transformTakeAndStartJobData({
  user,
}: TTransformTakeAndStartJobData) {
  const assignee = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
  };

  const nextJob = {
    status: JOB_STATUSES.inProgress.value,
    assignee,
  };

  return nextJob;
}

export function transformStartJobData() {
  const nextJob = {
    status: JOB_STATUSES.inProgress.value,
  };

  return nextJob;
}

export function transformPauseJobData() {
  const nextJob = {
    status: JOB_STATUSES.onHold.value,
  };

  return nextJob;
}

export function transformCompleteJobData() {
  const nextJob = {
    status: JOB_STATUSES.completed.value,
  };

  return nextJob;
}

export function transformReopenJobData() {
  const nextJob = {
    status: JOB_ADVANCED_STATUSES?.pending?.value,
  };

  return nextJob;
}

export function transformCancelJobData() {
  const nextJob = {
    status: JOB_STATUSES.cancelled.value,
  };

  return nextJob;
}

export function transformAttachmentsData(
  data: Omit<GetJobByIdQuery, 'audit'>,
): TJobDetailsAttachmentsData | null {
  if (data?.job?.attachments) {
    return data?.job?.attachments as TJobDetailsAttachmentsData;
  }

  return null;
}

export function transformTabsData(data: GetJobByIdQuery): TJobDetailsTabsData {
  const {
    job: { checklists },
  } = data;
  const id = data?.job?.id;
  const status = data?.job?.status;
  const action = data?.job?.action;
  const type = data?.job?.type;
  const checklistTemplate = data?.job?.checklistTemplate;
  const notes = data?.job?.notes;
  const activity = data?.audit;

  return {
    id,
    status,
    action,
    checklistTemplate,
    checklists,
    notes,
    type,
    activity,
  };
}
