import styled from 'styled-components/macro';
import { ReactComponent as CopyIcon } from 'images/svg/copy.svg';

const Copy = styled(CopyIcon)`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  :hover,
  :active {
    g {
      fill: ${(props) => props.theme.colors.charcoal05};
    }
  }
  ${(props) => props.theme.animations.hoverUp()}
`;

export default Copy;
