import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import Input from './Input';
import Icon from './Icon';
import Close from './Close';

const MODIFIER_CONFIG = {
  mobile: (_) => `
    padding: 1.4rem;
    &:before {
    left: 5.5rem;
    top: 32%;
    height: 36%;
  }
  `,
  orgTree: (_) => `
    margin-bottom: 1.1rem;
  `,
  noPadding: ({ theme }) => `
    padding: 0;
    & svg {
      left: 1.4rem;
      top: 1.2rem;
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      &:before {
        left: 4rem;
      }
    }
  `,
};
const Search = styled.div`
  position: relative;
  &:before {
    position: absolute;
    content: '';
    left: 4rem;
    top: 30%;
    height: 40%;
    width: 0.1rem;
    background: ${(props) => props.theme.colors.charcoal03};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    &:before {
      left: 5rem;
    }
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;
Search.Input = Input;
Search.Icon = Icon;
Search.Close = Close;
export default Search;
