import { SPACING, COLORS } from '@optii-solutions/ui-library';
import type { TButtonGroupStyles } from './ButtonGroup.types';

const MAX_PERCENT_WIDTH = 100;

function getPercentCalcString(
  maxElements: number,
  gapSize: number,
  partGap: number,
) {
  return `calc(${MAX_PERCENT_WIDTH / maxElements}% - ${gapSize * partGap}px)`;
}

const COLUMN_GAP = 5;
const ROW_GAP = 7;

export const ButtonGroupStyles: TButtonGroupStyles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gridAutoRows: '40px',
    columnGap: COLUMN_GAP,
    rowGap: ROW_GAP,
  },
  button: ({
    firstLineMaxElements,
    index,
    active,
  }: {
    [key: string]: string | number | boolean;
  }) => {
    const nIndex = Number(index);
    const nFirstLineMaxElements = Number(firstLineMaxElements);
    const nFirstLinePercentGap = Number(
      ((1 / nFirstLineMaxElements) * (nFirstLineMaxElements - 1)).toPrecision(
        2,
      ),
    );

    const firstLineMinWidth = getPercentCalcString(
      nFirstLineMaxElements,
      COLUMN_GAP,
      nFirstLinePercentGap,
    );
    const otherLinesMinWidth = `auto`;

    const minWidth =
      nIndex < nFirstLineMaxElements ? firstLineMinWidth : otherLinesMinWidth;

    return {
      backgroundColor: !active ? 'inherit' : COLORS.secondary[4],
      color: !active ? 'inherit' : COLORS.neutral[1],
      borderColor: !active ? COLORS.neutral[5] : COLORS.secondary[4],
      padding: SPACING.SIZE_SM,
      display: 'flex',
      flex: 1,
      minWidth,
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      fontWeight: 500,
    };
  },
};
