import React from 'react';
import { ReactComponent as FilterIcon } from 'images/svg/filter-icon.svg';
import styled from 'styled-components/macro';

const FilterIconWrapper = styled(FilterIcon)`
  min-width: 2.4rem;
  min-height: 2.1rem;
`;

function IconAlt() {
  return <FilterIconWrapper />;
}

export default IconAlt;
