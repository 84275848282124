import { gql } from "@apollo/client";

export default gql`
  mutation CreateBulkInvitation(
    $createBulkInvitationInput: CreateBulkInvitationInput
  ) {
    createBulkInvitation(
      createBulkInvitationInput: $createBulkInvitationInput
    ) {
      invitations_error {
        error
        invite {
          code
        }
      }
      invitations_success {
        code
        userFirstName
        userLastName
      }
    }
  }
`;
