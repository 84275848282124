import styled from 'styled-components/macro';

const Header = styled.h4`
  ${(props) => props.theme.fonts.sizes.largest}
  text-align: left;
  margin-bottom: 3rem;
  margin-top: 2rem;
  &:first-child {
    margin-top: 1rem;
  }
`;

export default Header;
