import React, { useState, useEffect } from 'react';

function ToggleContent({ toggle, content, shown }) {
  const [isShown, setIsShown] = useState(shown || false);
  const hide = () => setIsShown(false);

  const show = () => setIsShown(true);

  // Allow consumers to explicitly control show state
  useEffect(
    (_) => {
      setIsShown(shown);
    },
    [shown],
  );
  return (
    <>
      {toggle(show, isShown)}
      {isShown && content(hide)}
    </>
  );
}
export default ToggleContent;
