import { normalizeInputFilters } from './utils';
import { EventContext, NotificationInput } from '../../../api/notifications';

interface UpdateProps {
  variables: {
    id: string;
    input: NotificationInput;
  };
}

interface CreateProps {
  variables: {
    input: NotificationInput;
  };
}

interface SubmitProps {
  input: NotificationInput;
  notificationId?: string | null;
  handleUpdate: (agrs: UpdateProps) => Promise<any>;
  handleCreate: (agrs: CreateProps) => Promise<any>;
  metadata: { [key: string]: any };
}

async function numericalTaskSubmit({
  input: _input,
  notificationId,
  handleUpdate,
  handleCreate,
  metadata,
}: SubmitProps) {
  const input = normalizeInputFilters(_input);
  const event = input.userEventActionRules[0]?.event;
  const filters = event?.filters;

  if (event) {
    event.context = EventContext.ChecklistTask;
    event.type = ['EntityModified'];
    event.conditions = [{
        type: 'PropertyValueChanged',
        propertyName: 'DataNumerical',
    }];
  }

  if (filters && filters[0]) {
    const min = filters[0].DataNumericMin.values[0];
    const max = filters[0].DataNumericMax.values[0];

    filters[0].TaskType = {
      property: 'TaskType',
      operator: 'In',
      values: ['numerical'],
    };
    filters[0].DataTaskNull = {
      property: 'DataNumerical',
      operator: 'NotNull',
      values: [],
    };
    filters[0].TemplateTaskId = {
      property: 'TemplateTaskId',
      operator: 'In',
      values: [metadata.taskId],
    };
    filters[0].DataTask = {
      property: 'DataNumerical',
      operator: 'OutsideRange',
      values: [min, max],
    };

    const displayName = `Numeric Less than ${max}, more than ${min} - [taskId:${metadata.taskId}]`;

    delete filters[0].DataNumericMin;
    delete filters[0].DataNumericMax;

    if (notificationId) {
      await handleUpdate({
        variables: {
          id: notificationId,
          input: {
            displayName: input.displayName ?? displayName,
            userEventActionRules: input.userEventActionRules,
          },
        },
      });
    } else {
      handleCreate({
        variables: {
          input: {
            displayName: input.displayName ?? displayName,
            userEventActionRules: input.userEventActionRules,
          },
        },
      });
    }
  }
}

export default numericalTaskSubmit;
