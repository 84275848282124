import { normalizeInputFilters } from './utils';
import { NotificationInput } from '../../../api/notifications';

interface UpdateProps {
  variables: {
    id: string;
    input: NotificationInput;
  };
}

interface CreateProps {
  variables: {
    input: NotificationInput;
  };
}

interface SubmitProps {
  input: NotificationInput;
  notificationId?: string | null;
  handleUpdate: (agrs: UpdateProps) => Promise<void>;
  handleCreate: (agrs: CreateProps) => Promise<void>;
}

async function jobAndProjectSubmit({
  input: _input,
  notificationId,
  handleUpdate,
  handleCreate,
}: SubmitProps) {
  const input = normalizeInputFilters(_input);

  if (input.displayName) {
    input.displayName = input.displayName.trim();
  }

  if (notificationId) {
    await handleUpdate({
      variables: {
        id: notificationId,
        input: {
          displayName: input.displayName,
          userEventActionRules: input.userEventActionRules,
        },
      },
    });
  } else {
    handleCreate({
      variables: {
        input: {
          displayName: input.displayName,
          userEventActionRules: input.userEventActionRules,
        },
      },
    });
  }
}

export default jobAndProjectSubmit;
