import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { ReactComponent as Icon } from '@optii/shared/images/svg/priority/icon-pause-high.svg';

const CONFIG = {
  completed: ({ theme }) => `
    .fill-color {
      fill: ${theme.colors.charcoal03}
    }
  `,
};

const HighOnHold = styled(Icon)`
  ${applyStyleModifiers(CONFIG)};
`;
export default HighOnHold;
