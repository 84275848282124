import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { ReactComponent as ArrowL } from 'images/svg/carousel-arrow-left.svg';
import { ReactComponent as ArrowR } from 'images/svg/carousel-arrow-right.svg';

import Attachments from 'components/shared/Attachments';
import styled from 'styled-components';
import loadImage from 'blueimp-load-image';
import Icons from 'blocks/Icons';
import FullscreenImage from 'components/shared/FullscreenImage';
import { ChecklistItemContext } from '../contexts';

const ArrowLeft = styled(ArrowL)`
  min-width: 2.4rem;
  opacity: ${(props) => (props.hasPrevious ? '1' : '.5')};
  margin-top: 0.4rem;
  cursor: ${(props) => (props.hasPrevious ? 'pointer' : 'inherit')};
`;
const ArrowRight = styled(ArrowR)`
  min-width: 2.4rem;
  opacity: ${(props) => (props.hasNext ? '1' : '.5')};
  margin-top: 0.4rem;
  cursor: ${(props) => (props.hasNext ? 'pointer' : 'inherit')};
`;
const FullImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1.6rem;
  div {
    max-height: 40rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f5f5f5;
  }
`;

const RemoveImage = styled.button`
  position: absolute;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  right: 1.2rem;
  bottom: 1.2rem;
`;

export default function AttachmentsItem() {
  const {
    formValues,
    onFormChange,
    isReadOnly,
    isFulfillment,
    onFulfillmentChange,
  } = useContext(ChecklistItemContext);

  const [selectedAttachment, setSelectedAttachment] = useState('');

  const { attachment, fulfillmentAttachment } = formValues;

  const imageRef = useRef(null);

  const fieldName = isFulfillment ? 'fulfillmentAttachment' : 'attachment';
  const fieldValue = isFulfillment ? fulfillmentAttachment : attachment;

  const onDeleteImage = () => {
    const updatedAttachments = fieldValue.filter(
      (fileId) => selectedAttachment.fileId !== fileId,
    );
    onFormChange({
      name: fieldName,
      value: updatedAttachments,
    });
    setSelectedAttachment(null);

    if (isFulfillment) {
      onFulfillmentChange({
        ...formValues,
        fulfillmentAttachment: updatedAttachments,
      });
    }
  };

  async function load() {
    imageRef.current.innerHTML = '';
    const data = await loadImage(
      selectedAttachment.file.URL,

      {
        orientation: true,
      },
    );
    imageRef.current.appendChild(data.image);

    // Used for the overlay preview when the image is clicked
  }

  useEffect(() => {
    if (selectedAttachment) {
      load();
    }
  }, [selectedAttachment]);

  const attachmentsList = attachment?.length ? attachment : [];
  const fulfillmentAttachmentList = fulfillmentAttachment?.length
    ? fulfillmentAttachment
    : [];
  const allAttachments = [...attachmentsList, ...fulfillmentAttachmentList];

  const isAttachmentOwner = useMemo(() => {
    const attachmentList = isFulfillment ? fulfillmentAttachment : attachment;
    return attachmentList?.includes(selectedAttachment?.fileId);
  }, [
    attachment,
    fulfillmentAttachment,
    isFulfillment,
    selectedAttachment?.fileId,
  ]);

  const canRemove = !isReadOnly && isAttachmentOwner;

  return (
    <div>
      {!!allAttachments.length && (
        <Attachments
          hideUploads
          small
          allowFileDeletion
          showFileAsIcon
          allowArrows
          itemsPerPage={10}
          customArrowPrevious={ArrowLeft}
          customArrowNext={ArrowRight}
          setSelectedAttachment={setSelectedAttachment}
          selectedAttachment={selectedAttachment}
          values={{
            attachments: allAttachments,
          }}
          separator={attachmentsList.length ? attachmentsList.length : -1}
        />
      )}

      {selectedAttachment && imageRef && (
        <FullImageContainer>
          <div ref={imageRef} />
          {canRemove && (
            <RemoveImage title="Remove Image" onClick={onDeleteImage}>
              <Icons.TrashCan />
            </RemoveImage>
          )}
        </FullImageContainer>
      )}
    </div>
  );
}
