import { gql } from "@apollo/client";

export default gql`
  mutation SendBulkInvitation(
    $sendBulkInvitationInput: SendBulkInvitationInput
  ) {
    sendBulkInvitation(sendBulkInvitationInput: $sendBulkInvitationInput) {
      invitations_error {
        error
        invite {
          code
          userFirstName
          userLastName
        }
      }
      invitations_success {
        code
        userFirstName
        userLastName
      }
    }
  }
`;
