import { gql } from "@apollo/client";

export default gql`
  query GetRoomTypeById($id: ID!) {
    roomType: GetRoomTypeById(id: $id) {
      id
      displayName
      code
      note
      hkDefaultConfigs {
        id
        type
        creditValue
        durationMin
        checklistsTemplates {
          id
          checklistTemplateId
        }

      }
    }
  }
`;
