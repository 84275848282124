import { ConfigProvider, Input, ThemeConfig } from 'antd';
import { SearchProps } from 'antd/es/input';

type Props = SearchProps & {
  theme?: ThemeConfig;
};

const { Search: SearchComponent } = Input;

export function Search(props: Props) {
  const { theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <SearchComponent {...rest} />
    </ConfigProvider>
  );
}
