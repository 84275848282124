import type { SelectProps, ThemeConfig } from 'antd';
import { Select as AntSelect, ConfigProvider } from 'antd';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { SELECT_CREATE_OPTION_THEME } from '../../utils/themes';
import { Button } from '../Button/Button.component';
import styled from 'styled-components';

type SelectType = 'default' | 'filter';

type Props = SelectProps & {
  theme?: ThemeConfig;
  prefixIcon?: ReactNode;
  createOption?: (value: string) => void;
  type?: SelectType;
  handleOptionCreation?: (value?: string) => void;
  setValue?: Dispatch<SetStateAction<string | string[]>>;
  filterSelectedItemsLabel?: string;
};

const AntSelectStyled = styled(AntSelect)`

  .ant-select-clear {
    width: 24px !important;
    height: 24px !important;
    margin-right: -5px;
    padding-top: 5px;
    margin-top: -12px;
  }
`

export function Select(props: Props) {
  const {
    theme,
    type,
    handleOptionCreation,
    setValue,
    filterSelectedItemsLabel,
    ...rest
  } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntSelectStyled
        maxTagPlaceholder={
          type === 'filter' && rest.mode === 'multiple'
            ? `Selected ${filterSelectedItemsLabel} (${rest.value.length})`
            : undefined
        }
        {...rest}
        notFoundContent={
          handleOptionCreation ? (
            <ConfigProvider theme={SELECT_CREATE_OPTION_THEME}>
              <Button
                type="link"
                style={{}}
                onClick={() => handleOptionCreation(rest?.searchValue)}
              >
                Create "{rest?.searchValue}"
              </Button>
            </ConfigProvider>
          ) : undefined
        }
      />
    </ConfigProvider>
  );
}
