import { gql } from "@apollo/client";

export default gql`
  query assetType($id: ID!) {
    assetType(id: $id) {
      displayName
      description
      status
      modelName
      serviceCycle {
        type
        value
        displayName
      }
      lifeCycle {
        number
        lifeCyclePeriodType
      }
      brand {
        displayName
      }
      jobItem {
        displayName
        id
      }
      version
      documents {
        uuid
      }
      note
      description
    }
  }
`;
