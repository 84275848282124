import { gql } from "@apollo/client";

export const UPDATE_JOB_META_DATA =  gql`
  mutation updateJobMetadata(
      $id: ID!,
      $schema: String!,
      $input: MetadataEdit!,
    ) {
    updateJobMetadata(id: $id, schema: $schema, input: $input) {
      id
      status
    }
  }
`;
