import moment from 'moment';
import { getDaysOfWeek } from 'utils/constants/projects';

// We show the days of the week in order based on the property's start of day
// This function determines the options list for that dropdown based on the property
export const getDaysOfWeekInPropertyOrder = (property, t) => {
  const startOfWeek =
    property &&
    property.regionalSettings &&
    property.regionalSettings.startOfWeek;
  // find index of entry
  const weekDays = getDaysOfWeek(t);
  // support both short and long day formats
  const index = weekDays.findIndex((entry) => entry.key === startOfWeek);

  // slice the front and append it to the back
  const toAppend = weekDays.slice(0, index);
  return weekDays.slice(index).concat(toAppend);
};

const getDayPositionText = (t, dayOfWeek) => ({
  first: t(`projects:Monthly on the first {{dayOfWeek.displayName}}`, {
    dayOfWeek,
    context: dayOfWeek.key,
  }),
  second: t(`projects:Monthly on the second {{dayOfWeek.displayName}}`, {
    dayOfWeek,
    context: dayOfWeek.key,
  }),
  third: t(`projects:Monthly on the third {{dayOfWeek.displayName}}`, {
    dayOfWeek,
    context: dayOfWeek.key,
  }),
  fourth: t(`projects:Monthly on the fourth {{dayOfWeek.displayName}}`, {
    dayOfWeek,
    context: dayOfWeek.key,
  }),
  last: t(`projects:Monthly on the last {{dayOfWeek.displayName}}`, {
    dayOfWeek,
    context: dayOfWeek.key,
  }),
});
// Given a day in the month, find whether it is the first, second, third, fourth or last instance of that day of the week in that month
export const getDaysPositionInMonth = (date) => {
  // destructure original date

  const dayNumber = date.date();
  const weekDay = date.day();
  const monthDay = date.month();
  const year = date.year();

  // grab the first occurrence of the selected week day
  const firstOccurrence = moment()
    .set('month', monthDay)
    .set('year', year)
    .startOf('month')
    .day(weekDay);

  const occurrences = [];
  const occurrencePosition = ['first', 'second', 'third', 'fourth', 'last'];
  // Map and store all occurrences of the weekday on the month of the selected date.
  if (firstOccurrence.date() > 7) firstOccurrence.add(7, 'd');
  const month = firstOccurrence.month();
  while (month === firstOccurrence.month()) {
    const instantiateDate = firstOccurrence.date();
    occurrences.push(instantiateDate);
    firstOccurrence.add(7, 'd');
  }
  // Remove the "fourth" option if the length of the arrays differ.
  if (occurrencePosition.length !== occurrences.length) {
    occurrencePosition.splice(occurrencePosition.indexOf('fourth'), 1);
  }
  // Reduce and transform to a object in which the key is the occurrence (e.g first) and value being the date day.
  const formattedOccurrences = occurrences.reduce(
    (a, v, i) => ({ ...a, [occurrencePosition[i]]: v }),
    {},
  );

  // Return the key based on the selected start Date day.
  return Object.keys(formattedOccurrences).find(
    (key) => formattedOccurrences[key] === dayNumber,
  );
};

// We have two options available in the monthly cadence:
// Monthly on day 27 of the month
// Monthly on the first Friday
// This function determins that based on a moment object for the cycle start day
export const getDayOfMonthOptions = (time, t) => {
  if (!time) {
    return [];
  }

  const day = time.day() + 1;
  const date = time.date();
  const dayOfWeek = getDaysOfWeek(t).find(({ id }) => id === day);

  return [
    {
      displayName: t(`projects:Monthly on day {{date}} of the month`, {
        date,
      }),
      id: 1,
    },
    {
      displayName: getDayPositionText(t, dayOfWeek)[
        getDaysPositionInMonth(time)
      ],
      id: 2,
    },
  ];
};

// This is to build the value for the weekly service cycle
// weeklyOnDay: "every=3&day=mon,wed,fri"
// daysOfWeek: [{key: mon}]
// cycleCadence: 2
export const buildWeeklyOnDay = ({ daysOfWeek, cycleCadence }) => {
  const day = daysOfWeek.map(({ key }) => key).join(',');
  return `every=${cycleCadence}&day=${day}`;
};

// This is to build the value for the monthly service cycle
// monthlyOnDay: "every=4&on=second&day=fri"
export const buildMonthlyOnDay = ({ projectStartDate, cycleCadence }) => {
  const daysOfWeek = getDaysOfWeek((x) => x);
  // Match based on the index within the week
  const day = daysOfWeek[projectStartDate.day()];
  const position = getDaysPositionInMonth(projectStartDate);
  return `every=${cycleCadence}&on=${position}&day=${day.key}`;
};

export function buildWeeklyOnDayReadOnly(t, language, serviceCycle) {
  const weekDays = getDaysOfWeek(t);
  const params = new URLSearchParams(serviceCycle.value);
  const count = parseInt(params.get('every'), 10);
  const days = params.get('day').split(',');
  const daysTranslated = days.map(
    (d) => weekDays.find((w) => w.key === d).displayName,
  );
  const daysOfWeek = new Intl.ListFormat(language, {
    style: 'long',
    type: 'conjunction',
  }).format(daysTranslated);

  return t(`projects:{{count}} Week on {{daysOfWeek}}`, {
    count,
    daysOfWeek,
  });
}

export function buildMonthlyOnDayReadOnly(t, serviceCycle) {
  const generateSentence = (count, dayOfMonth) => ({
    first: t(`projects:{{count}} Month on first {{dayOfMonth}}`, {
      count,
      dayOfMonth,
    }),
    second: t(`projects:{{count}} Month on second {{dayOfMonth}}`, {
      count,
      dayOfMonth,
    }),
    third: t(`projects:{{count}} Month on third {{dayOfMonth}}`, {
      count,
      dayOfMonth,
    }),
    fourth: t(`projects: {{count}} Month on fourth {{dayOfMonth}}`, {
      count,
      dayOfMonth,
    }),
    last: t(`projects:{{count}} Month on last {{dayOfMonth}}`, {
      count,
      dayOfMonth,
    }),
  });

  const weekDays = getDaysOfWeek(t);
  const params = new URLSearchParams(serviceCycle.value);
  const every = parseInt(params.get('every'), 10);
  const position = params.get('on');
  const day = weekDays.find((w) => w.key === params.get('day')).displayName;

  return generateSentence(every, day)[position];
}

export function buildDailyReadOnly(t, serviceCycle) {
  const count = parseInt(serviceCycle.value, 10);
  return t(`projects:{{count}} Day`, { count });
}

export function buildWeeklyReadOnly(t, serviceCycle) {
  const count = parseInt(serviceCycle.value, 10);
  return t(`projects:{{count}} Week`, { count });
}

export function buildMonthlyReadOnly(t, serviceCycle) {
  const count = parseInt(serviceCycle.value, 10);
  return t(`projects:{{count}} Month`, { count });
}

export function buildYearlyReadOnly(t, serviceCycle) {
  const count = parseInt(serviceCycle.value, 10);
  return t(`projects:{{count}} Year`, { count });
}

export function getProjectCycleCadence(
  serviceCycleCadenceValue,
  serviceCycleType,
) {
  if (serviceCycleType !== 3) {
    return serviceCycleCadenceValue;
  }
  if (serviceCycleType === 3) {
    const params = new URLSearchParams(serviceCycleCadenceValue);
    const every = params.get('every');
    const on = params.get('on');
    const day = params.get('day');
    if (!every || !on || !day) return serviceCycleCadenceValue;

    return {
      every,
      on,
      day,
    };
  }
  return null;
}

export function getAssetsByType(assets, assetTypes) {
  let assetsByTypes = {};
  assets.forEach((asset) => {
    assetTypes.values.forEach((id) => {
      if (asset.assetType.id === id) {
        assetsByTypes = {
          ...assetsByTypes,
          [id]: assetsByTypes[id]
            ? [...assetsByTypes[id], { ...asset }]
            : [asset],
        };
      }
    });
    return asset;
  });

  return assetsByTypes;
}

export function getSelectedAssetsByAssetTypes(
  assetTypes,
  projectAssets,
  selectionLogic,
) {
  if (!selectionLogic) {
    return Object.values(assetTypes).map((item) =>
      item.filter(
        (itemValue) => !projectAssets.includes(itemValue.id.toString()),
      ),
    );
  }
  return Object.values(assetTypes).map((assetType) =>
    assetType.filter((asset) => projectAssets.includes(asset.id.toString())),
  );
}

export function getProjectJobTitle(
  selectedAssetsByType,
  locationIds,
  jobItemName,
) {
  const reducer = (prev, next) => prev + next;

  if (jobItemName) {
    return `${locationIds.length} ${jobItemName} `;
  }

  return Object.values(selectedAssetsByType)
    .map(
      (assetsArr, index) =>
        `${assetsArr?.length} ${assetsArr[0]?.assetType?.displayName}${
          index !== Object.values(selectedAssetsByType).length - 1 ? ', ' : ''
        }`,
    )
    .reduce(reducer, '');
}

export const completeFieldBgColor = (
  completeBasedOnJobs,
  completeBasedOnTime,
) => {
  const val = completeBasedOnJobs - completeBasedOnTime;
  if (val >= -10 && val <= 10) {
    return 'green';
  }
  if (val < -10) {
    return 'red';
  }
  return 'orange';
};

// expects a moment object to create a Date Object for
export const getBeforeDateValidation = (momentConstruct) =>
  new Date(
    momentConstruct.year(),
    momentConstruct.month(),
    momentConstruct.date(),
  );
