import ApolloClient from 'GraphClient';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';
import { TAssociatedVirtualLocations } from 'components/shared/LocationView/types';
import { GET_LOCATIONS_LIST, GET_LOCATION_BY_ID } from '../../../queries';
// eslint-disable-next-line import/no-cycle
import { IGetLocationsParams } from '../selectLocationsTypes';

export type TGetLocationById = {
  data: {
    GetLocationById: TAssociatedVirtualLocations[]
  }
};

export const getLocations = (params: IGetLocationsParams = {}) =>
  ApolloClient(Auth.Instance).query({
    query: GET_LOCATIONS_LIST,
    variables: { filters: { ...params } },
    // context: { _instance: "node" }
  });

export const getLocationsById = async (params: IGetLocationsParams = {}) => {
  let result: TGetLocationById[] | undefined;

  if (typeof params.id === 'string' || typeof params.id === 'number') {
    result = [await ApolloClient(Auth.Instance).query({
      query: GET_LOCATION_BY_ID,
      variables: { ...params },
    })];
  }
  
  if (typeof params.id === 'object' && params.id.length >= 1) {
    result = await Promise.all(params.id.map(async (id) => ApolloClient(Auth.Instance).query({
        query: GET_LOCATION_BY_ID,
        variables: { id },
      })));
  }

  return result;
};
