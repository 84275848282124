import ReactGA from "react-ga4";

import config from '@optii/shared/utils/config';

config.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID &&
  ReactGA.initialize(config.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
    testMode: process.env.NODE_ENV === 'test',
  });

export default ReactGA;
