import React, { useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexContainer, FlexItem, FulfillmentTextInput, RequiredFlag } from 'checklists';

import { ChecklistItemContext } from 'checklists/contexts';
import {
  CHECKLIST_TASK_FIELDS,
  CHECKLIST_TASK_TYPE_CONFIG,
  CHECKLIST_TYPES,
} from 'checklists/constants';
import styled from 'styled-components/macro';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import TaskNameField, { ErrorMessage } from '../TaskNameField';
import SelectType from '../SelectType';

const CustomRequiredFlag = styled(RequiredFlag)`
  position: absolute;
  margin-left: -14px;
  margin-top: 0.9rem;
`;

const SwitcherButton = styled.button`
  background: ${(props) => props.theme.colors.charcoal01};
  border: 0;
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.type.primary}
  ${(props) => props.theme.fonts.weights.medium}
  cursor: pointer;
  padding: 1.2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  color: ${(props) => props.theme.colors.charcoal04};

  ${({ isSelected, theme }) => {
    if (!isSelected) return ``;

    return `
      color: ${theme.colors.white};
      background: ${theme.colors.persianGreen};`;
  }}
`;

const SwitcherContainer = styled.div`
  display: flex;

  ${SwitcherButton}:nth-child(odd) {
    border-radius: 5px 0 0 5px;
  }
  ${SwitcherButton}:nth-child(even) {
    border-radius: 0 5px 5px 0;
  }
`;

function TemperatureType() {
  const task = useContext(ChecklistItemContext);
  const {
    fulfillmentValues,
    onFulfillmentChange,
    onFormChange,
    isFulfillment,
    formValues,
    isReadOnly,
  } = task;
  const { t } = useTranslation(['common', 'checklist']);
  const [value, setValue] = useState(
    fulfillmentValues[CHECKLIST_TASK_FIELDS.dataTemperatureValue],
  );
  const [touched, setTouched] = useState(false);
  const MAX_CHARS = 6;

  const isRequired =
    String(formValues[CHECKLIST_TASK_FIELDS.required]) === 'true';

  const handleFulfillmentScaleChange = (scaleValue) => {
    if (scaleValue === 'fahrenheit') GoogleAnalyticsClient.event(GA_EVENTS.temperatureTaskFahrenheitSelect);
    if (scaleValue === 'celsius') GoogleAnalyticsClient.event(GA_EVENTS.temperatureTaskCelsiusSelect);
    onFulfillmentChange({
      ...formValues,
      [CHECKLIST_TASK_FIELDS.dataTemperatureScale]: scaleValue,
    });
    onFormChange({
      name: CHECKLIST_TASK_FIELDS.dataTemperatureScale,
      value: scaleValue,
    });
  };

  const SCALES = {
    celcius: {
      label: t('checklist:°C'),
      value: 'celsius',
    },
    farenheit: {
      label: t('checklist:°F'),
      value: 'fahrenheit',
    },
  };

  const removeDegreeIcon = (degree) => {
    if (!degree) return degree;
    return String(degree).replace(/°/g, '');
  };

  const defaultScaleValue = SCALES.celcius.value;
  const scaleValue = isFulfillment
    ? fulfillmentValues[CHECKLIST_TASK_FIELDS.dataTemperatureScale]
    : formValues[CHECKLIST_TASK_FIELDS.dataTemperatureScale];

  const scale = scaleValue ?? defaultScaleValue;

  const isInvalidNumericField = useMemo(() => {
    const { hasError } = CHECKLIST_TASK_TYPE_CONFIG[
      CHECKLIST_TYPES.temperature
    ].validation(task, value);

    return hasError;
  }, [value]);

  const handleChange = (event) => {
    GoogleAnalyticsClient.event(GA_EVENTS.temperatureTaskValueChange)
    if (typeof event.target.value === 'string') {
      let validValue = event.target.value ?? '';
      const decimalChar = validValue.match(/.,/) ? 1 : 0;
      const deleting = value === removeDegreeIcon(validValue);
      if (deleting) {
        validValue = validValue.slice(0, -1);
      }
      validValue = removeDegreeIcon(validValue).slice(
        0,
        MAX_CHARS + decimalChar,
      );
      setTouched(() => true);
      setValue(() => validValue);
    }
  };

  const addDegreeIcon = (degree) => {
    if (!degree) return degree;
    let result = removeDegreeIcon(degree);
    result = `${result}°`;
    return result;
  };

  const handleBlur = () => {
    if (typeof value === 'string' && !isInvalidNumericField && touched) {
      const formattedValue = value.replace(/,/, '.');
      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataTemperatureValue]:
          removeDegreeIcon(formattedValue),
        [CHECKLIST_TASK_FIELDS.dataTemperatureScale]: scale,
      });
    }
  };

  const isDisabled = isReadOnly || !isFulfillment;

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container" col>
      <FlexContainer>
        <FlexItem>
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer>
        {isRequired && <CustomRequiredFlag />}

        <FulfillmentTextInput
          value={addDegreeIcon(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisabled}
          placeholder={t('checklist:Add Temperature')}
          tabIndex={isDisabled ? -1 : 0}
        />
        <SwitcherContainer>
          <SwitcherButton
            isSelected={scale === SCALES.celcius.value}
            onClick={() => handleFulfillmentScaleChange(SCALES.celcius.value)}
            disabled={isReadOnly || isFulfillment}
            tabIndex={isFulfillment ? 0 : -1}
          >
            {SCALES.celcius.label}
          </SwitcherButton>
          <SwitcherButton
            isSelected={scale === SCALES.farenheit.value}
            onClick={() => handleFulfillmentScaleChange(SCALES.farenheit.value)}
            disabled={isReadOnly || isFulfillment}
            tabIndex={isFulfillment ? 0 : -1}
          >
            {SCALES.farenheit.label}
          </SwitcherButton>
        </SwitcherContainer>
      </FlexContainer>
      {isInvalidNumericField && (
        <ErrorMessage errorMessage={t('checklist:Numbers only')} />
      )}
    </FlexContainer>
  );
}

export default TemperatureType;
