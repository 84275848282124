import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  useForm,
  Button,
  Col,
  Drawer,
  Form,
  FormItem,
  HeaderActions,
  Input,
  Row,
  SPACING,
  Select,
  Skeleton,
  TextArea,
  Typography,
  useWatch,
} from '@optii-solutions/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { initGQLData as initData } from 'utils/initGQLData';
import { getLifeCycle, getServiceCycles } from 'utils/formatters/assets';
import {
  FIND_ALL_BRANDS,
  GET_JOB_ITEMS_DEFS,
  CREATE_ASSET_TYPE,
  UPDATE_ASSET_TYPE,
  FIND_ALL_ASSET_TYPES,
} from 'queries';
import { AssetsContext } from 'components/Settings/AssetsSettings/contexts';
import Attachments from 'components/shared/Attachments';

import escapeCommas from 'utils/escapeCommas';
import { Session } from '@optii/shared';
import Confirmation from 'components/shared/Confirmation';
import type {
  TAssetTypeForm,
  TLifeCycleItem,
  TServiceCycleItem,
} from './types';
import { AssetTypesFormSkeleton } from './AssetTypesForm.skeleton';

type TJobItem = {
  id: string;
  displayName: string;
};

type TBrand = {
  id: string;
  displayName: string;
};

interface IAssetTypesFormProps {
  handleClose: () => void;
}

export function AssetTypesForm(props: IAssetTypesFormProps) {
  const { handleClose } = props;
  const [createBrand, setCreateBrand] = React.useState({
    create: false,
    value: '',
  });
  const [form] = useForm<TAssetTypeForm>();

  const watchDocuments = useWatch('documents', { form, preserve: true });
  const documents =
    (watchDocuments && String(watchDocuments)?.split(',')) || [];

  const { t } = useTranslation(['common', 'assets', 'fields']);
  const { editAssetType, isNewAssetType } = useContext(AssetsContext);

  const isOpen: boolean = !!(isNewAssetType || editAssetType) || false;

  const [createAssetType, { loading: createAsseTypeLoading }] =
    useMutation(CREATE_ASSET_TYPE);
  const [updateAssetType, { loading: updateAssetTypeLoading }] = useMutation(
    UPDATE_ASSET_TYPE,
    {
      errorPolicy: 'all',
    },
  );

  const [loading, setLoading] = useState<boolean>(
    updateAssetTypeLoading || true,
  );
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const { refetch: getAssetType } = useQuery(FIND_ALL_ASSET_TYPES, {
    skip: true,
    context: { _instance: 'node' },
  });

  const { data: jobItemsData } = useQuery(GET_JOB_ITEMS_DEFS, {
    context: { _instance: 'node' },
    variables: { Sorts: 'displayName' },
  });
  const categoryItems = initData(jobItemsData).map((jobItem: TJobItem) => {
    const { displayName, id } = jobItem;

    return {
      label: displayName,
      value: id,
    };
  });

  const { data: brandData } = useQuery(FIND_ALL_BRANDS, {
    context: { _instance: 'node' },
    variables: { first: 1000, sorts: 'displayName' },
  });
  const brandItems = initData(brandData).map((brandItem: TBrand) => {
    const { displayName } = brandItem;

    return {
      label: displayName,
      value: displayName,
    };
  });

  const { globalSnack } = useContext(Session);

  // get service serviceCycle items
  const serviceCycleItems = getServiceCycles(t).map(
    (serviceCycleItem: TServiceCycleItem) => {
      const { displayName, id } = serviceCycleItem;

      return {
        label: displayName,
        value: id,
      };
    },
  );

  // get Asset Life items
  const lifeCycleItems = getLifeCycle(t).map(
    (lifeCycleItem: TLifeCycleItem) => {
      const { displayName, filterSortValue } = lifeCycleItem;

      return {
        label: displayName,
        value: filterSortValue,
      };
    },
  );

  const checkForDuplicated = useCallback(
    async (
      displayName: string,
      jobItem: number | string,
      id?: number | string,
    ) => {
      try {
        const result = await getAssetType({
          filters: `displayName==${escapeCommas(
            displayName || '',
          )},jobItem.id==${jobItem}`,
        });
        const page = result && result.data && result.data.page;

        // Else make sure the count is 0
        if (page && page.totalCount === 0) {
          return false;
        }

        // For updates, need to make sure we ignore the entry if it is the current entry
        if (id && page.totalCount > 0) {
          const entityId = page.edges[0].node.id;

          if (entityId === id) return false;
        }

        return true;
      } catch (e) {
        console.error(e);
      }

      return false;
    },
    [getAssetType],
  );

  const getTransformedAssetTypeData = useCallback(
    ({ values, editAssetType }: any): any => {
      const {
        documents: valuesDocuments,
        lifeCycleNumber,
        lifeCyclePeriodType,
      } = values;

      let lifeCycle = values.lifeCycle || null;

      let serviceCycle;

      if (values.serviceCycle) {
        serviceCycle = {};
        // Get data transformation based on previous values

        if (!editAssetType) {
          Object.assign(serviceCycle, {
            value: String(values.serviceCycle.value),
            displayName: String(values.serviceCycle.label),
          });
        }

        if (editAssetType && editAssetType.id) {
          Object.assign(serviceCycle, {
            value: String(values.serviceCycle.value),
            type: 'cron',
            displayName:
              values.serviceCycle.displayName ||
              String(values.serviceCycle.label),
          });
        }
      }

      if (lifeCycleNumber && lifeCyclePeriodType) {
        lifeCycle = {
          number: Number(lifeCycleNumber),
          lifeCyclePeriodType: lifeCyclePeriodType.value,
        };

        delete lifeCycle.__typename;
      }

      const data = {
        serviceCycle,
        lifeCycle,
      };

      // Get data transformation based on new values

      if (editAssetType && editAssetType.id) {
        Object.assign(data, {
          ...data,
          documents: String(valuesDocuments || '')
            .split(',')
            .map((document) => ({
              uuid: document,
            })),
          version: Number(editAssetType?.version),
        });
      }

      if (!editAssetType) {
        Object.assign(data, {
          ...data,
          documents: String(valuesDocuments || '')
            .split(',')
            .map((document) => ({
              uuid: document,
            })),
        });
      }

      return data;
    },
    [],
  );

  const formatAssetTypeForm = useCallback(
    (values: TAssetTypeForm) => {
      const {
        serviceCycle,
        lifeCycle,
        version,
        documents: valuesDocuments,
      } = getTransformedAssetTypeData({
        values,
        editAssetType,
      });

      const formData = {
        id: editAssetType?.id,
        jobItem: {
          id: Number(values.category),
        },
        description: String(values?.description || '').trim(),
        note: String(values?.note || '').trim(),
        modelName: String(values?.modelName || '').trim(),
        displayName: String(values?.displayName || '').trim(),
        status: 'active',
        documents: valuesDocuments,
        serviceCycle,
        lifeCycle,
        version,
      };

      if (values.brand) {
        Object.assign(formData, {
          ...formData,
          brand: {
            displayName: values.brand,
          },
        });
      }

      return formData;
    },
    [editAssetType, getTransformedAssetTypeData],
  );

  const handleCreate = useCallback(
    async (values: TAssetTypeForm) => {
      const isDuplicate = await checkForDuplicated(
        values.displayName,
        Number(values.category),
      );

      if (isDuplicate) {
        globalSnack({
          message: t(
            'assets:There is already an Asset Type with this combination of Category/Job Item and Name',
          ),
          error: true,
          timeout: 5000,
        });

        return;
      }

      const createAssetTypeInput = formatAssetTypeForm(values);

      try {
        await createAssetType({
          variables: {
            createAssetTypeInput,
          },
          refetchQueries: [FIND_ALL_ASSET_TYPES, 'AssetTypes'],
          context: { _instance: 'node' },
        });

        form.resetFields();

        globalSnack({
          message: t('assets:{{name}} was successfully added', {
            name: values.displayName,
          }),
        });

        handleClose();
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t(
            'assets:Something went wrong attempting to create asset type',
          ),
          error: true,
          timeout: 5000,
        });

        handleClose();
      }
    },
    [
      createAssetType,
      globalSnack,
      checkForDuplicated,
      formatAssetTypeForm,
      form,
      t,
      handleClose,
    ],
  );

  const handleUpdate = useCallback(
    async (values: TAssetTypeForm) => {
      const isDuplicate = await checkForDuplicated(
        values.displayName,
        Number(values.category),
        editAssetType.id,
      );

      if (isDuplicate) {
        globalSnack({
          message: t(
            'assets:There is already an Asset Type with this combination of Category/Job Item and Name',
          ),
          error: true,
          timeout: 5000,
        });

        return;
      }

      const updateAssetTypeInput = formatAssetTypeForm(values);

      try {
        await updateAssetType({
          variables: {
            updateAssetTypeInput,
          },
          refetchQueries: [FIND_ALL_ASSET_TYPES, 'AssetTypes'],
          context: { _instance: 'node' },
        });

        globalSnack({
          message: t('assets:{{name}} was successfully updated', {
            name: values.displayName,
          }),
        });

        handleClose();
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t(
            'assets:Something went wrong attempting to create asset type',
          ),
          error: true,
          timeout: 5000,
        });
      }
    },
    [
      updateAssetType,
      globalSnack,
      checkForDuplicated,
      formatAssetTypeForm,
      editAssetType,
      handleClose,
      t,
    ],
  );

  useEffect(() => {
    setTimeout(() => setLoading(false), 1200);
  }, []);

  const editTitle = editAssetType?.displayName
    ? `Edit ${editAssetType.displayName}`
    : `${t('assets: Add Asset Type')}`;

  return (
    <Drawer
      destroyOnClose
      width={675}
      onClose={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      open={isOpen}
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          <Typography.Title
            level={3}
            style={{
              marginTop: SPACING.NONE,
              fontFamily: 'Montserrat',
            }}
          >
            {editTitle}
          </Typography.Title>
          <HeaderActions onClose={() => handleClose()} />
        </Row>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={12}
          style={{
            paddingRight: SPACING.SIZE_XL,
          }}
        >
          <Button type="text" onClick={() => handleClose()}>
            {t('Cancel')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={loading || updateAssetTypeLoading}
            loading={createAsseTypeLoading}
            onClick={() => form.submit()}
          >
            {editAssetType && editAssetType.id ? t('Update') : t('Save')}
          </Button>
        </Row>
      }
    >
      {loading ? (
        <AssetTypesFormSkeleton />
      ) : (
        <Form
          autoComplete="off"
          title="Form"
          form={form}
          preserve
          onFinish={
            editAssetType ? () => setShowUpdateModal(true) : handleCreate
          }
          initialValues={
            editAssetType
              ? {
                  ...editAssetType,
                  category: editAssetType.jobItem.id || null,
                  brand: editAssetType?.brand?.displayName || null,
                  serviceCycle:
                    editAssetType?.serviceCycle?.displayName !== 'undefined'
                      ? {
                          value: editAssetType?.serviceCycle?.value,
                          type: editAssetType?.serviceCycle?.type,
                          label: editAssetType?.serviceCycle?.displayName,
                          displayName: editAssetType?.serviceCycle?.displayName,
                        }
                      : null,
                  lifeCycleNumber: editAssetType?.lifeCycle?.number || null,
                  lifeCyclePeriodType: editAssetType?.lifeCycle
                    ? {
                        value: editAssetType?.lifeCycle?.lifeCyclePeriodType,
                        label: getLifeCycle(t).find(
                          (item) =>
                            item.filterSortValue.toLowerCase() ===
                            editAssetType?.lifeCycle?.lifeCyclePeriodType.toLowerCase(),
                        )?.displayName,
                        displayName: getLifeCycle(t).find(
                          (item) =>
                            item.filterSortValue.toLowerCase() ===
                            editAssetType?.lifeCycle?.lifeCyclePeriodType.toLowerCase(),
                        )?.displayName,
                      }
                    : null,
                  documents:
                    editAssetType.documents &&
                    editAssetType.documents
                      .map((document: { uuid: string }) => document.uuid)
                      .join(','),
                }
              : {}
          }
          scrollToFirstError
        >
          <Row align="middle" justify="space-between" wrap={false}>
            <Col xs={12}>
              <FormItem<TAssetTypeForm>
                label={`${t('jobItems:Job Item')}/${t('assets:Category')}`}
                name="category"
                rules={[
                  {
                    required: true,
                    message: t('assets:Category is a required field'),
                  },
                ]}
              >
                <Select
                  options={categoryItems}
                  allowClear
                  showSearch
                  placeholder={t('assets:Select')}
                  filterOption={(input, option) =>
                    String(option?.label)
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                />
              </FormItem>
            </Col>
            <Col xs={11}>
              <FormItem<TAssetTypeForm>
                label={`${t('assets:Brand')}/${t('assets:Manufacturer')}`}
                name="brand"
                rules={[
                  {
                    pattern: /[^\s*$]/g,
                    message: t('assets:Brand Name field is required'),
                  },
                ]}
              >
                {!createBrand.create && (
                  <Select
                    options={brandItems}
                    allowClear
                    placeholder={t('assets:Select')}
                    handleOptionCreation={
                      createBrand.value
                        ? (_value = '') => {
                            const value = _value.trim();
                            if (value === '') return;
                            setCreateBrand({ value, create: true });
                            form.setFieldsValue({
                              brand: value,
                            });
                          }
                        : undefined
                    }
                    showSearch
                    onSearch={(value) => {
                      setCreateBrand({ value, create: createBrand.create });
                    }}
                    filterOption={(input, option) =>
                      String(option?.label)
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                    searchValue={createBrand.value}
                  />
                )}
                {createBrand.create && (
                  <Input
                    onBlur={(e) =>
                      e.target.value === '' &&
                      setCreateBrand({ create: false, value: '' })
                    }
                    autoComplete="name-only"
                    autoFocus
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row align="middle" justify="space-between" wrap={false}>
            <Col xs={12}>
              <FormItem<TAssetTypeForm>
                label={t('assets:Name')}
                name="displayName"
                rules={[
                  {
                    required: true,
                    message: t('assets:Name is a required field.'),
                  },
                ]}
              >
                <Input
                  placeholder={t('assets:Add Asset Type')}
                  autoComplete="name-only"
                />
              </FormItem>
            </Col>
            <Col xs={11}>
              <FormItem<TAssetTypeForm>
                label={t('assets:Model')}
                name="modelName"
              >
                <Input
                  placeholder={t('assets:Add Model')}
                  autoComplete="name-only"
                />
              </FormItem>
            </Col>
          </Row>

          <Row justify="space-between" wrap={false}>
            <Col xs={12}>
              <FormItem<TAssetTypeForm>
                label={t('assets:Service Cycle')}
                name="serviceCycle"
                style={{ marginBottom: 0 }}
              >
                <Select
                  options={serviceCycleItems}
                  labelInValue
                  allowClear
                  placeholder={t('assets:Select')}
                />
              </FormItem>
            </Col>
            <Col xs={11}>
              <FormItem
                label={t('assets:Asset Life')}
                style={{ marginBottom: 0 }}
              >
                <Row justify="space-between" wrap={false}>
                  <Col xs={6}>
                    <FormItem<TAssetTypeForm>
                      name="lifeCycleNumber"
                      dependencies={['lifeCyclePeriodType']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value &&
                              !!getFieldValue('lifeCyclePeriodType')
                            ) {
                              return Promise.reject(
                                new Error(
                                  'life Cycle Number is a required field.',
                                ),
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                        {
                          pattern: /^[0-9]+$/,
                          message: t('assets:Integer numbers only'),
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder="#"
                        autoComplete="name-only"
                      />
                    </FormItem>
                  </Col>
                  <Col xs={17}>
                    <FormItem<TAssetTypeForm>
                      name="lifeCyclePeriodType"
                      dependencies={['lifeCycleNumber']}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value && !!getFieldValue('lifeCycleNumber')) {
                              return Promise.reject(
                                new Error('Period type is a required field.'),
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Select
                        options={lifeCycleItems}
                        labelInValue
                        allowClear
                        placeholder={t('assets:Select')}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>
          </Row>

          <FormItem<TAssetTypeForm>
            label={t('assets:Description')}
            name="description"
          >
            <TextArea
              placeholder={t('assets:Add a description')}
              autoComplete="name-only"
            />
          </FormItem>

          <FormItem<TAssetTypeForm> label={t('assets:Notes')} name="note">
            <TextArea
              placeholder={t('assets:Add a description')}
              autoComplete="name-only"
            />
          </FormItem>

          <Attachments
            values={{ attachments: documents }}
            handleUpload={(UUID: string) => {
              const fieldsValue = form.getFieldsValue();

              const fieldsValueDocuments =
                (fieldsValue?.documents &&
                  String(fieldsValue.documents).split(',')) ||
                [];

              if (UUID) {
                fieldsValueDocuments.push(UUID);
              }

              form.setFieldValue('documents', fieldsValueDocuments.join(','));
            }}
          />

          <FormItem name="documents">
            <Input type="hidden" />
          </FormItem>
        </Form>
      )}
      {showUpdateModal && (
        <Confirmation
          hide={() => setShowUpdateModal(false)}
          confirm={() => handleUpdate(form.getFieldsValue())}
          action={t('common:Yes, update')}
          loading={updateAssetTypeLoading}
        >
          <p>
            {`${t(
              'assets:Updating the Asset Type information will automatically update associated assets.',
            )} ${t('assets:Are you sure you want to proceed?')}`}
          </p>
        </Confirmation>
      )}
    </Drawer>
  );
}
