import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  mobile: () => `
    width: 100%;
    height: 4.3rem;
  `,
  desktop: (props) => `
    width: 44rem;
    height: 4.4rem;
    @media (max-width: ${props.theme.breakpoints.lg}) {
      width: 30rem;
    }
  `,
};

const Input = styled.input`
  border-radius: 4px;
  padding-inline-start: 5rem;
  ${(props) => props.theme.fonts.type.primary};
  ${(props) => props.theme.borders.default};
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.light}
  text-overflow:ellipsis;
  :focus {
    ${(props) => props.theme.borders.focus}
  }
  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset;
  }
  ::placeholder {
    font-family: inherit;
    color: ${(props) => props.theme.colors.charcoal04};
    ${(props) => props.theme.fonts.sizes.large};
    ${(props) => props.theme.fonts.weights.light};
    font-style: italic;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

export default Input;
