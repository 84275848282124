import React from "react";

export const JobContext = React.createContext({
  jobItems: [],
  employees: [],
  departments: [],
  roles: [],
  activeLocations: [],
  activeLocationsAssetType: [],
  loadLocationListOptions: _ => {},
  loadLocationAssetTypeListOptions: _ => {},
  getAssetTypesByJobItem: _ => {},
  jobItemHasAssociatedAssets: false,
  assetsLoading: true,
  close: _ => {},
  loading: true
});
