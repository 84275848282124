import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/shared/buttons/PrimaryButton';
import QuadButton from 'components/shared/buttons/QuadButton';
import styled from 'styled-components/macro';
import { useContext, useRef, useState } from 'react';
import Select from 'components/shared/form/Select';
import { JobDetailsContext } from '@optii/shared';
import TextareaAutosize from 'react-textarea-autosize';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.4rem;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h2`
  margin: 0 0 2.4rem 0;
  ${(props) => props.theme.fonts.sizes.largest};
  ${(props) => props.theme.fonts.weights.medium};
  ${(props) => props.theme.fonts.type.boundary};
  color: ${(props) => props.theme.colors.charcoalDefault};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const Label = styled.label`
  margin-right: 1em;
  min-width: 6.4rem;
  ${(props) => props.theme.fonts.sizes.smallest};
  ${(props) => props.theme.fonts.weights.medium};
  ${(props) => props.theme.fonts.type.primary};
  color: ${(props) => props.theme.colors.charcoal045};
`;

const Field = styled.div`
  flex-grow: 1;
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.weak};
  ${(props) => props.theme.fonts.type.primary};
  color: ${(props) => props.theme.colors.charcoal05};
`;

const Input = styled(TextareaAutosize)`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 1.2rem 1.4rem;
  width: 100%;
  height: 4rem;
  font-size: 16px;
  ${(props) => props.theme.fonts.type.primary}
`;

const WrappableField = styled.div`
  flex-grow: 1;
  padding: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.fonts.sizes.small};
  ${(props) => props.theme.fonts.weights.weak};
  ${(props) => props.theme.fonts.type.primary};
  color: ${(props) => props.theme.colors.charcoal05};
`;

function ConvertTaskToJob({
  name = 'New job',
  loading = false,
  locations = [],
  defaultAssignee,
  onSave,
  onCancel,
}) {
  if (defaultAssignee) {
    defaultAssignee.id = parseInt(defaultAssignee.id);
    defaultAssignee.displayName = `${defaultAssignee.firstName} ${defaultAssignee.lastName}`;
  }
  const { employees } = useContext(JobDetailsContext);
  const [selectedVal, setSelectedVal] = useState(defaultAssignee);
  const [note, setNote] = useState(undefined);
  const { t } = useTranslation(['jobs']);

  return (
    <>
      <Title>{t('jobs: Convert Task to a Job')}</Title>

      <Form>
        <Row>
          <Label>{t('jobs: Job')}</Label>
          <WrappableField>{name}</WrappableField>
        </Row>
        <Row>
          <Label>{t('jobs: Location')}</Label>
          <WrappableField>
            {locations.map(
              (location, i) =>
                location.name + (i < locations.length - 1 ? ', ' : ''),
            )}
          </WrappableField>
        </Row>
        <Row>
          <Label htmlFor="task_job_employees">{t('jobs: Assigned to')}</Label>

          <Field>
            <Select
              name="task_job_note"
              id="task_job_note"
              options={employees}
              onChange={(e) => setSelectedVal(e.target.value)}
              value={selectedVal}
            />
          </Field>
        </Row>
        <Row>
          <Field>
            <Input
              placeholder={t('jobs: Add a note...')}
              onChange={(event) => setNote(event.target.value)}
            />
          </Field>
        </Row>
      </Form>
      <ButtonContainer>
        <QuadButton
          disabled={loading}
          style={{ 'min-width': '12rem' }}
          onClick={onCancel}
        >
          {t('jobs: Cancel')}
        </QuadButton>
        <PrimaryButton
          disabled={loading}
          onClick={() =>
            onSave({
              label: name,
              locations: locations.map((location) => ({
                name: location.name,
                type: location.type,
                number: location.number,
                locationId: parseInt(location.id),
              })),
              assignee_employee_id: selectedVal?.id || null,
              note,
            })
          }
        >
          {t('jobs: Save')}
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
}

export default ConvertTaskToJob;
