import {
  InputNumber as AntInputNumber,
  ConfigProvider,
  InputNumberProps,
  ThemeConfig,
} from 'antd';
import React from 'react';

type Props = InputNumberProps & {
  theme?: ThemeConfig;
};

export function InputNumber(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntInputNumber {...rest} />
    </ConfigProvider>
  );
}
