import React, { Ref, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { GET_ASSET_JOBS } from 'queries';

import { initGQLData as initData, getPageInfo } from 'utils/initGQLData';

import PagableList from 'components/shared/PagableList';
import { AssetsContext } from 'components/Settings/AssetsSettings/contexts';

import BlockCard from 'blocks/Card';

import CardForAssetJob from '../Assets/CardForAssetJob';

import { TAssetViewDataJob } from './types';

interface IAssetViewJobActivityProps {
  onJobEdit: () => void;
  onJobView: () => void;
}

export function AssetViewJobActivity(props: IAssetViewJobActivityProps) {
  const { viewAsset } = useContext(AssetsContext);

  const { t } = useTranslation(['common', 'assets']);

  const { data, loading, fetchMore } = useQuery(GET_ASSET_JOBS, {
    variables: {
      filters: `assetId==${
        viewAsset && viewAsset.id
      },status!=archived,status!=in_progress`,
      first: 10,
    },
    context: { _instance: 'node' },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const assetJobs = initData(data);
  const pageInfo = getPageInfo(data);

  return assetJobs.length > 0 ? (
    <PagableList
      modifiers={null}
      data={assetJobs}
      pageInfo={pageInfo}
      fetchMore={fetchMore}
      withCount
      useSecondaryButton
      loading={loading}
      showNewButton={() => true} // TODO: remove when everywhere used new load more UI
      list={() =>
        assetJobs.map((job: TAssetViewDataJob, index: number) => {
          const newJob = {
            ...job,
            onEditCallback: () => {
              if (props.onJobEdit && typeof props.onJobEdit === 'function') {
                props.onJobEdit();
              }
            },
            onViewCallback: () => {
              if (props.onJobView && typeof props.onJobView === 'function') {
                props.onJobView();
              }
            },
          };

          return <CardForAssetJob key={job.id} {...{ job: newJob, index }} />;
        })
      }
    />
  ) : (
    <BlockCard>{t('assets:No jobs found')}</BlockCard>
  );
}
