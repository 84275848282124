export const serviceErrorsNames = {
  ERR_SERVICE: 'ERR_SERVICE',
  ERR_NOT_FOUND: 'ERR_NOT_FOUND',
  ERR_ALREADY_EXISTS: 'ERR_ALREADY_EXISTS',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
  ERR_BAD_PARAM: 'ERR_BAD_PARAM',
  ERR_NOT_ALLOWED: 'ERR_NOT_ALLOWED',
  ERR_UNAUTHORIZED: 'ERR_UNAUTHORIZED',
};

export default {
  ERR_SERVICE: 'Service error',
  ERR_NOT_FOUND: 'Not found',
  ERR_ALREADY_EXISTS: 'Already exists',
  ERR_BAD_REQUEST: 'Bad Request',
  ERR_BAD_PARAM: 'Bad Parameter',
  ERR_NOT_ALLOWED: 'Not Allowed',
};
