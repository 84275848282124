import React from 'react';
import { ButtonQuadStyled } from './styles';

const ButtonQuad: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return <ButtonQuadStyled {...props}>{children}</ButtonQuadStyled>;
};

export default ButtonQuad;
