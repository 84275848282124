import { ThemeConfig } from 'antd';
import { COLORS, DEFAULT_THEME_COLORS } from './colors';
import { FONTS, RADIUS, SPACING } from './sizes';

export const DEFAULT_THEME: ThemeConfig = {
  token: {
    fontFamily: 'Roboto',
    fontSize: FONTS.medium.size,
    colorPrimary: COLORS.primary[6],
  },
  components: {
    Button: {
      fontSize: FONTS.medium.size,
      fontSizeLG: FONTS.medium.size,
      borderRadius: RADIUS.RADIUS_DEFAULT,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      borderRadiusSM: RADIUS.RADIUS_DEFAULT,
      lineHeight: 1,
      lineWidth: 1,
      paddingContentHorizontal: SPACING.SIZE_MD,
      colorPrimary: COLORS.primary[4],
      colorPrimaryHover: COLORS.primary[5],
      colorPrimaryActive: COLORS.primary[6],
      primaryShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
    },
    Tag: {
      defaultColor: COLORS.neutral[9],
      defaultBg: COLORS.neutral[3],
      colorBorder: COLORS.neutral[5],
      fontSize: FONTS.medium.size,
      fontSizeSM: FONTS.medium.size,
    },
    Form: {
      labelColor: DEFAULT_THEME_COLORS.token.form.textLabel,
      labelRequiredMarkColor: COLORS.red[7],
      verticalLabelPadding: SPACING.NONE,
    },
    Select: {
      colorPrimary: COLORS.primary[5],
      borderRadius: RADIUS.RADIUS_DEFAULT,
      colorPrimaryHover: COLORS.primary[5],
      controlItemBgActive: COLORS.primary[1],
      borderRadiusSM: RADIUS.RADIUS_DEFAULT,
      controlHeight: SPACING.SIZE_XXL,
      fontWeightStrong: 400,
      colorText: COLORS.neutral[8],
      fontSize: FONTS.medium.size,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
    },
    Pagination: {
      borderRadius: RADIUS.RADIUS_DEFAULT,
    },
    Tabs: {
      colorPrimary: COLORS.primary[5],
      colorPrimaryHover: COLORS.primary[6],
      horizontalItemPadding: '4px 0',
      horizontalItemPaddingLG: '4px 0',
      fontSizeLG: FONTS.medium.size,
      colorText: COLORS.neutral[9],
    },
    Switch: {
      colorPrimary: COLORS.polarGreen[5],
      colorPrimaryHover: COLORS.polarGreen[4],
      fontSizeSM: FONTS.medium.size,
      handleSize: SPACING.SIZE_XL,
      trackHeight: 28,
      trackMinWidth: 50,
      innerMinMargin: 15,
      innerMaxMargin: 30,
      trackPadding: 2,
    },
    Checkbox: {
      colorPrimaryBorder: COLORS.neutral[5],
      colorBgContainerDisabled: COLORS.neutral[3],
      colorPrimaryHover: COLORS.secondary[3],
      colorPrimary: COLORS.secondary[5],
      borderRadiusSM: RADIUS.RADIUS_S,

      colorText: DEFAULT_THEME_COLORS.token.text.base,
      fontSize: FONTS.medium.size,
    },
    DatePicker: {
      colorText: COLORS.neutral[8],
    },
    Descriptions: {
      itemPaddingBottom: SPACING.NONE,
      colorText: COLORS.neutral[8],
    },
    Typography: {
      colorTextHeading: COLORS.neutral[9],
      fontWeightStrong: 500,
      colorLink: COLORS.primary[5],
      colorLinkHover: COLORS.primary[4],
      colorLinkActive: COLORS.primary[6],
      titleMarginBottom: SPACING.NONE,
    },
    Input: {
      colorPrimaryHover: COLORS.primary[5],
      colorPrimaryActive: COLORS.primary[5],
      fontSize: FONTS.medium.size,
      borderRadius: RADIUS.RADIUS_DEFAULT,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      controlHeight: SPACING.SIZE_XXL,
      colorText: COLORS.neutral[8],
      activeBorderColor: COLORS.primary[5],
    },
    InputNumber: {
      colorPrimaryHover: COLORS.primary[4],
      colorPrimaryActive: COLORS.primary[5],
      fontSize: FONTS.medium.size,
      activeBorderColor: COLORS.primary[5],
      handleHoverColor: COLORS.primary[4],
      borderRadius: RADIUS.RADIUS_DEFAULT,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
    },
    TreeSelect: {
      colorPrimary: COLORS.secondary[5],
      colorPrimaryHover: COLORS.secondary[3],
    },
    Radio: {
      buttonBg: COLORS.neutral[3],
      buttonSolidCheckedBg: COLORS.secondary[4],
      buttonColor: COLORS.neutral[8],
      colorPrimaryHover: COLORS.secondary[4],
      colorPrimary: COLORS.secondary[4],
      colorPrimaryActive: COLORS.secondary[5],

      buttonSolidCheckedColor: COLORS.neutral[1],
      buttonSolidCheckedHoverBg: COLORS.secondary[5],
      buttonSolidCheckedActiveBg: COLORS.secondary[5],
    },
    Segmented: {
      itemSelectedBg: COLORS.sunrise[6],
      itemSelectedColor: COLORS.neutral[10],
    },
  },
};

export const TREE_SELECT_THEME: ThemeConfig = {
  components: {
    TreeSelect: {
      colorPrimary: COLORS.secondary[5],
      colorPrimaryHover: COLORS.secondary[3],
    },
    Typography: {
      colorText: COLORS.neutral[7],
    },
  },
};

export const ICON_BUTTON_THEME: ThemeConfig = {
  components: {
    Button: {
      colorPrimary: COLORS.primary[5],
      colorBgContainer: undefined,
      colorText: COLORS.neutral[6],
      colorBorder: undefined,
      boxShadow: undefined,
      controlOutline: undefined,
      colorPrimaryActive: COLORS.primary[5],
    },
  },
};

export const SELECT_CREATE_OPTION_THEME: ThemeConfig = {
  components: {
    Button: {
      colorLink: COLORS.neutral[8],
      colorLinkHover: COLORS.primary[7],
      colorLinkActive: COLORS.primary[7],
      fontSize: FONTS.small.size,
    },
  },
};

export const DESCRIPTION_TEXT_THEME: ThemeConfig = {
  components: {
    Typography: {
      colorText: COLORS.neutral[8],
      colorLink: COLORS.primary[4],
      colorLinkHover: COLORS.primary[3],
      colorLinkActive: COLORS.primary[4],
    },
  },
};

export const AUTOCOMPLETE_THEME: ThemeConfig = {
  components: {
    Input: {
      borderRadius: RADIUS.RADIUS_DEFAULT,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      borderRadiusSM: RADIUS.RADIUS_DEFAULT,
      colorTextPlaceholder: COLORS.neutral[5],
      fontSize: SPACING.SIZE_MD,
    },
  },
  token: {
    borderRadius: RADIUS.RADIUS_DEFAULT,
    borderRadiusLG: RADIUS.RADIUS_DEFAULT,
    borderRadiusSM: RADIUS.RADIUS_DEFAULT,
    colorBgBase: COLORS.neutral[1],
  },
};

export const HOUSEKEEPING_STATUS_THEME: ThemeConfig = {
  components: {
    Card: {
      borderRadius: SPACING.SIZE_SM,
      padding: SPACING.SIZE_SM,
    },
    Typography: {
      fontSize: FONTS.medium.size,
      fontWeightStrong: 400,
      colorText: COLORS.neutral[9],
    },
  },
};
