import { Meta, StoryObj } from '@storybook/react';
import { PagedGrid, PagedGridDataSource } from './Tables.component';

/**
 * Table organisms built using Ant Design components
 */

export default {
  title: 'UI-Library/Organisms/Tables',
  component: PagedGrid,
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
} as Meta;

/**
 * ## Paged Grid
 * Implementation of Ant Design Table with actions and icon column
 *
 * ### Callbacks
 *  - onDelete: delete button callback, sends the item as arg
 *  - onEdit: edit button callback, sends the item as arg
 *  - selection (optional): displays the select all checkbox or not
 *
 * ### Props
 *  - columns: the items you want in the header
 *  - dataSource: Data to be displayed, must use the columns keys as reference
 */

export const dataSource: PagedGridDataSource[] = [
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        Conference Room 202
      </a>
    ),
    icon: 'child',
    parent: 'Tower 1',
    type: 'Conference Room',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        Room 330
      </a>
    ),
    icon: 'child',
    parent: 'Tower 2',
    type: 'Tower',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Child Location]
      </a>
    ),
    icon: 'child',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Child Location]
      </a>
    ),
    icon: 'child',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Virtual Location]
      </a>
    ),
    icon: 'virtual',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Virtual Location]
      </a>
    ),
    icon: 'virtual',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Root Location]
      </a>
    ),
    icon: 'parent',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
  {
    key: crypto.randomUUID(),
    name: (
      <a href="https://justjavascript.com" rel="noreferrer" target="_blank">
        [Root Location]
      </a>
    ),
    icon: 'parent',
    parent: '[Parent Location]',
    type: '[Location Type]',
    room: '[Room Type]',
  },
];

const locationTypeDataSource = [
  {
    key: crypto.randomUUID(),
    name: 'BOH',
    code: 'BOH',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: 'Basement',
    code: 'BSMT',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: 'Bathroom',
    code: 'BTH',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Location Type]',
    code: '[Code]',
    note: '[Note]',
  },
];

const roomTypeDataSource = [
  {
    key: crypto.randomUUID(),
    name: 'BOH',
    code: 'BOH',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: 'Basement',
    code: 'BSMT',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: 'Bathroom',
    code: 'BTH',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
  {
    key: crypto.randomUUID(),
    name: '[Room Type]',
    code: '[Code]',
    note: '[Note]',
  },
];

export const FloorPlanPagedGrid: StoryObj<typeof PagedGrid> = {
  args: {
    selection: true,
    loading: false,
    onDelete: (output) => console.log(output),
    onEdit: (output) => console.log(output),
    onChange: (pagination, sorter) => console.log(pagination, sorter),
    onSelectionChange: (keys) => console.log(keys),
    columns: [
      'icon',
      { title: 'Name', key: 'name', dataIndex: 'name', sorter: true },
      {
        title: 'Parent Location',
        key: 'parent',
        dataIndex: 'parent',
        sorter: true,
      },
      { title: 'Location Type', key: 'type', dataIndex: 'type', sorter: true },
      { title: 'Room Type', key: 'room', dataIndex: 'room', sorter: true },
    ],
    paginationTotal: 500,
    dataSource,
  },
};

export const FloorPlanLocationTypeGrid: StoryObj<typeof PagedGrid> = {
  args: {
    selection: false,
    loading: false,
    onDelete: (output) => console.log(output),
    onEdit: (output) => console.log(output),
    onChange: (pagination, sorter) => console.log(pagination, sorter),
    onSelectionChange: (keys) => console.log(keys),
    columns: [
      { title: 'Name', key: 'name', dataIndex: 'name', sorter: true },
      {
        title: 'Code',
        key: 'code',
        dataIndex: 'code',
        sorter: true,
      },
      { title: 'Note', key: 'note', dataIndex: 'note', sorter: true },
    ],
    paginationTotal: 500,
    dataSource: locationTypeDataSource,
  },
};

export const FloorPlanRoomTypeGrid: StoryObj<typeof PagedGrid> = {
  args: {
    selection: false,
    loading: false,
    onDelete: (output) => console.log(output),
    onEdit: (output) => console.log(output),
    onChange: (pagination, sorter) => console.log(pagination, sorter),
    onSelectionChange: (keys) => console.log(keys),
    columns: [
      { title: 'Name', key: 'name', dataIndex: 'name', sorter: true },
      {
        title: 'Code',
        key: 'code',
        dataIndex: 'code',
        sorter: true,
      },
      { title: 'Note', key: 'note', dataIndex: 'note', sorter: true },
    ],
    paginationTotal: 500,
    dataSource: roomTypeDataSource,
  },
};
