import {
  Upload as AntUpload,
  ConfigProvider,
  ThemeConfig,
  UploadProps,
} from 'antd';
import React from 'react';

type Props = UploadProps & {
  theme?: ThemeConfig;
};

const { Dragger: AntDragger } = AntUpload;

export function Dragger(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntDragger {...rest} />
    </ConfigProvider>
  );
}

export function Upload(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntUpload {...rest} />
    </ConfigProvider>
  );
}
