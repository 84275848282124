import localeBg_BG from 'antd/es/date-picker/locale/bg_BG'; // Bulgarian
import localeFr_FR from 'antd/es/date-picker/locale/fr_FR'; // French
import localeIt_IT from 'antd/es/date-picker/locale/it_IT'; // Italian
import localePl_PL from 'antd/es/date-picker/locale/pl_PL'; // Polish
import localeRo_RO from 'antd/es/date-picker/locale/ro_RO'; // Romanian
import localeRu_RU from 'antd/es/date-picker/locale/ru_RU'; // Russian
// import localePa_IN from 'antd/es/date-picker/locale/pa_IN'; // Punjabi NOT SUPPORTED
// import localeBs_BS from 'antd/es/date-picker/locale/bn_BD'; // Bosnian NOT SUPPORTED
import localeEl_GR from 'antd/es/date-picker/locale/el_GR'; // Greek
import localeJa_JP from 'antd/es/date-picker/locale/ja_JP'; // Japanese
import localeZh_CN from 'antd/es/date-picker/locale/zh_CN'; //Chinese Simplified
import localeZh_TW from 'antd/es/date-picker/locale/zh_TW'; // Chinese Traditional
// import localeHt_CR from 'antd/es/date-picker/locale/ht_CR' // Haitian Creole NOT SUPPORTED
// import localeTl_PH from 'antd/es/date-picker/locale/ta_IN'; // Tagalog (Philippines) NOT SUPPORTED
import localeDe_DE from 'antd/es/date-picker/locale/de_DE'; // German
import localeEn_GB from 'antd/es/date-picker/locale/en_GB'; // English (Great Britain)
import localeEs_ES from 'antd/es/date-picker/locale/es_ES'; // Spanish (Spanish)
import localeEt_EE from 'antd/es/date-picker/locale/et_EE'; // Estonian
import localePt_BR from 'antd/es/date-picker/locale/pt_BR'; // Portuguese Brazilian
// import localeEn_AU from 'antd/es/date-picker/locale/'; // English (Australia) NOT SUPPORTED
// import localeEn_NZ from 'antd/es/date-picker/locale/'; // English (New Zealand) NOT SUPPORTED
import localeMn_MN from 'antd/es/date-picker/locale/mn_MN'; // Mongolian
import localeTr_TR from 'antd/es/date-picker/locale/tr_TR'; // Turkish
import localeUk_UA from 'antd/es/date-picker/locale/uk_UA'; // Ukrainian
// import localeSq_AL from 'antd/es/date-picker/locale/sq_AL'; // Albanian NOT SUPPORTED

const LANGUAGE_LOCALES = {
  sq: 'Albanian', // Need custom support
  bs: 'Bosnian', // Need custom support
  bg: localeBg_BG,
  'zh-CN': localeZh_CN,
  'zh-TW': localeZh_TW,
  'en-AU': 'English Australia', //Need custom support
  'en-NZ': 'English New Zealand', // Need custom support
  'en-GB': localeEn_GB,
  et: localeEt_EE,
  fr: localeFr_FR,
  de: localeDe_DE,
  el: localeEl_GR,
  ht: 'Haitian Creole', // Need custom support,
  it: localeIt_IT,
  ja: localeJa_JP,
  mn: localeMn_MN,
  pl: localePl_PL,
  'pt-BR': localePt_BR,
  pa: 'Punjabi', // Need custom support
  ro: localeRo_RO,
  ru: localeRu_RU,
  es: localeEs_ES,
  'es-MX': 'Spanish Mexico', // Need custom support
  'tl-PH': 'Tagalog', // Need custom support
  tr: localeTr_TR,
  uk: localeUk_UA,
};

export function getLocale(languageCode?: string) {
  const LOCALE =
    LANGUAGE_LOCALES[languageCode as keyof typeof LANGUAGE_LOCALES];

  if (typeof LOCALE === 'string') {
    return undefined;
  } else return LOCALE;
}
