import React from 'react';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/shared/buttons/PrimaryButton';
import Secondary from 'components/shared/buttons/SecondaryButton';
import Loading from 'components/shared/Loading';

const CONFIG = {
  nested: ({ theme }) => `
    width: 100%;
  `,
  right: ({ theme }) => `
    justify-content: right;
  `,
  small: ({ theme }) => `
    button {
      transform: scale(0.7);
    }
    p {
      ${theme.fonts.sizes.small};
    }  
  `,
};
const Main = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  p {
    margin: 1.25rem 2rem;
  }
  ${applyStyleModifiers(CONFIG)};
`;
const LoadingMore = styled(Loading)`
  margin-top: 2rem;
  position: relative;
`;
const SecondarySmallBtn = styled(Secondary)`
  height: 3rem;
  min-width: 11.2rem;
  ${(props) => props.theme.fonts.sizes.small};
`;

function LoadMoreWithCount({
  pageInfo,
  fetchMore,
  data,
  useSecondaryButton,
  loading,
  setShouldScrollToBottom,
  setItemIdToScrollTo,
  modifiers,
  callback = () => {
    // callback
  },
  showNewButton, // TODO: remove when everywhere used new load more UI
}) {
  const { t } = useTranslation(['common']);
  const SecondaryBtn = showNewButton ? SecondarySmallBtn : Secondary; // TODO: remove and use SecondarySmallBtn when everywhere used new load more UI
  const Button = useSecondaryButton ? SecondaryBtn : PrimaryButton;

  if (loading) return <LoadingMore loading={loading} />;
  if (showNewButton)
    return (
      <Main modifiers={modifiers}>
        <Button
          data-testid="load-more"
          onClick={async (e) => {
            callback();
            try {
              if (fetchMore)
                fetchMore({
                  variables: {
                    cursor: pageInfo.endCursor,
                  },
                }).then((fetchMoreResult) => {
                  const newEdges = fetchMoreResult.data.page.edges || [];

                  if (newEdges.length > 0) {
                    setItemIdToScrollTo(newEdges[0].node.id);
                  }
                  setShouldScrollToBottom(true);
                });
            } catch (err) {
              console.error(
                `${t('common:Caught error fetching more records')}:`,
                err,
              );
            }
          }}
        >
          {t('common:Load')} {data.length} {t('common:of')}{' '}
          {pageInfo.totalCount}
        </Button>
      </Main>
    );

  return (
    <Main modifiers={modifiers}>
      <p data-testid="paging-indicator">
        {data.length} {t('common:of')} {pageInfo.totalCount}
      </p>
      <Button
        data-testid="load-more"
        onClick={async (e) => {
          callback();
          try {
            e.preventDefault();
            if (fetchMore)
              fetchMore({
                variables: {
                  cursor: pageInfo.endCursor,
                },
              }).then((fetchMoreResult) => {
                const newEdges = fetchMoreResult.data.page.edges || [];

                if (newEdges.length > 0) {
                  setItemIdToScrollTo(newEdges[0].node.id);
                }
                setShouldScrollToBottom(true);
              });
          } catch (err) {
            console.error(
              `${t('common:Caught error fetching more records')}:`,
              err,
            );
          }
        }}
      >
        {t('common:Load More')}
      </Button>
    </Main>
  );
}
export default LoadMoreWithCount;
