import { gql } from '@apollo/client';

export default gql`
  mutation CreateRepeatingJob(
    $createRepeatingJobInput: CreateRepeatingJobInput!
  ) {
    createRepeatingJob(createRepeatingJobInput: $createRepeatingJobInput) {
      id
      jobTemplate {
        action
        item
        items {
          amount
          id
          name
        }
      }
    }
  }
`;
