import { gql } from '@apollo/client';

export const GET_REPEATING_JOB_SUMMARIES = gql`
  query RepeatingJobSummaries(
    $first: Int
    $cursor: String
    $sorts: String
    $filters: String
  ) {
    page: repeatingJobSummaries(
      first: $first
      after: $cursor
      sorts: $sorts
      filters: $filters
    ) {
      totalCount
      edges {
        node {
          ... on RepeatingJobSummary {
            id
            displayName
            frequency
            startDate
            endDate
            department {
              id
              displayName
              departmentCode
            }
            location {
              id
              displayName
            }
            roles
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
