import { css, keyframes } from "styled-components/macro";

// On hover, the element pops up (or down, if scale is < 1) and then on exit, back down
// The default is the values for icons, beware changing it...
function hoverUp(duration = 0.15, scale = 1.1) {
  let durationUp; let durationDown;
  if (Array.isArray(duration)) {
    [durationUp, durationDown] = duration;
  } else {
    durationDown = duration;
    durationUp = durationDown;
  }
  const up = keyframes`
    from {
      transform: scale3d(1, 1, 1);
    }
  `;
  const down = keyframes`
    from {
      transform: scale3d(${scale}, ${scale}, ${scale});
    }
  `;
  return css`
    :hover {
      animation: ${durationUp}s ${up};
      transform: scale3d(${scale}, ${scale}, ${scale});
    }
    animation: ${durationDown}s ${down};
  `;
}

const inUpperRight = css`
  animation: 0.5s ${keyframes`
    from {
      opacity: 0;
      transform: translate3d(100%, -100%, 0) scale3d(0, 0, 0);
     
    }
    50% {
      opacity: 1;
      transform: translate3d(50%, -50%, 0) scale3d(0, 0, 0)
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  `};
`;

export default {
  hoverUp,
  inUpperRight
};
