import { ConfigProvider, Input, ThemeConfig } from 'antd';
import { PasswordProps } from 'antd/es/input';
type Props = PasswordProps & {
  theme?: ThemeConfig;
};

const { Password: PasswordComponent } = Input;

export function Password(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <PasswordComponent {...rest} />
    </ConfigProvider>
  );
}
