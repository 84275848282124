import { useEffect, useMemo } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useScrollLock = (element: Element | null) => {
  const isIosDevice = useMemo(
    () =>
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.platform &&
      (/iP(ad|hone|od)/.test(window.navigator.platform) ||
        (window.navigator.platform === 'MacIntel' &&
          window.navigator.maxTouchPoints > 1)),
    []
  );

  useEffect(() => {
    if (!isIosDevice && element) {
      disableBodyScroll(element);
      return () => {
        enableBodyScroll(element);
      };
    }
  }, [element]);
};
