import React from "react";

export const DetailsContext = React.createContext({
  attachments: [],
  asset: {},
  close: () => {},
  job: {},
  jobItems: [],
  employees: [],
  departments: [],
  roles: [],
  locations: [],
  locationsAssetType: [],
  checklists: [],
  loadLocationListOptions: _ => {},
  loadLocationAssetTypeListOptions: _ => {},
  getAssetTypesByJobItem: _ => {},
  loading: false,
  updateRepeatingJobLoading: false,
  updateRepeatingJob: async () => {},
  refetch: async () => {}
});
