import { Requestor } from '@openid/appauth';
import { XhrSettings } from 'ionic-appauth/lib/cordova';
import { CapacitorHttp } from '@capacitor/core';

// Used by ionic-appauth to make requests
export class AjaxRequestor extends Requestor {
  // eslint-disable-next-line class-methods-use-this
  public async xhr<T>(settings: XhrSettings) {
    let { url } = settings;
    if (
      settings.data &&
      settings.headers['Content-Type'] &&
      settings.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      // Strange stuff, you know you love it
      url = `${settings.url}?${settings.data}`;
    }

    // eslint-disable-next-line no-param-reassign
    if (!settings.method) settings.method = 'GET';
    return CapacitorHttp.request({
      method: settings.method,
      url,
      headers: settings.headers,
    }).then((response: any) => response.data);
  }
}
