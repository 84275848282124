import React from 'react';
import { GET_ASSET_VENDORS } from 'queries';
import { useQuery } from '@apollo/client';
import { initGQLData as initData } from "utils/initGQLData";

/**
 * Hook for using FIND_ALL_ASSET_TYPES query.
 * @param {Object} props - Settings for the hook
 * @param {boolean} [props.skip=false] - Should skip or not the fetch.
 * @returns {UseAssetVendorsReturn}
 */
export function useAssetVendors({
  skip = false
} = {}) {

  const { data, loading, error } = useQuery(GET_ASSET_VENDORS, {
    skip,
    context: { _instance: "node" }
  });


  const assetVendorsFormated = React.useMemo(() => initData(data), [data]);

  const assetVendorsOptions = React.useMemo(() => initData(data).map((vendor) => ({
      label: vendor.displayName,
      value: vendor.id
    })), [data]);


  return {
    assetVendors: data,
    assetVendorsFormated,
    assetVendorsOptions,
    loading,
    error,
  };
}
