import { gql } from '@apollo/client';

export const GET_FILE_BY_ID = gql`
  query GetFileByUUID($id: String!) {
    file: GetFileByUUID(uuid: $id) {
      id
      URL
      name
      createdAt
    }
  }
`;
