import styled from 'styled-components/macro';
import React from 'react';

// This is for links that have no href, they are buttons that are styled like links
const Button = styled.button`
  color: ${(props) => props.theme.colors.teal};
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.weak}
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
`;
function LinkButton(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}
export default LinkButton;
