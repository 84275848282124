import React from 'react';

export const ChecklistModalContext = React.createContext({
  close: () => {
    // close
  },
  readOnly: false,
  isReadOnly: false,
  isAdd: false,
  isEdit: false,
  isCopy: false,
  loading: true,
  setLoading: () => {
    // set Loading state
  },
  isFulfillment: false,
  setData: () => {
    // setData state
  },
  query: {},
  setQuery: () => {
    // set URL queries
  },
  data: [],
  modalMode: false,
});

export const ChecklistItemContext = React.createContext({
  formValues: {
    label: '',
    value: '',
    taskType: 'checkbox',
  },
  touched: {},
  actionState: '',
  setActionState: () => {
    // set action
  },
  fulfillmentValues: {},
  groupId: '',
  onFormChange: () => {
    // onForm change handler
  },
  onFulfillmentChange: () => {
    // on fulfillment change
  },
  isValid: true,
  errors: {},
  readOnly: false,
  isReadOnly: false,
  isEdit: false,
  isAdd: false,
  isFulfillment: false,
  isOpen: false,
  toggleOpen: () => {
    // open state
  },
  onToggleOpen: () => {
    // open function
  },
});
