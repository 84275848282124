import React, { CSSProperties } from 'react';
import {
  Badge,
  COLORS,
  Avatar,
  Button,
  Typography,
  FONTS,
  RADIUS,
} from '@optii-solutions/ui-library';
import { Client } from '@twilio/conversations';
import { useOnlineStatus } from '../../../hooks/useOnlineStatus';

const EMPLOYEE_NAME_STYLE: CSSProperties = {
  fontSize: FONTS.medium.size,
  letterSpacing: FONTS.medium.letterSpacing,
};

type EmployeeOptionProps = {
  label: string;
  value: string;
  client: Client | null;
  isTag?: boolean;
  onClick: () => void;
  isSelected: boolean;
};

export function EmployeeOption({
  label,
  value,
  client,
  isTag,
  onClick,
  isSelected,
}: EmployeeOptionProps) {
  const { status } = useOnlineStatus(client, value);

  return (
    <Button
      type="text"
      theme={{
        components: {
          Button: {
            borderRadius: RADIUS.NONE,
          },
        },
      }}
      style={{
        width: isTag ? '' : '100%',
        justifyContent: 'flex-start',
        backgroundColor: isSelected ? COLORS.primary[1] : undefined,
      }}
      shape="default"
      onClick={onClick}
      icon={
        <Badge dot={status} color={COLORS.polarGreen[5]} offset={[-4, 22]}>
          <Avatar
            style={{
              verticalAlign: 'center',
            }}
            size="small"
            gap={4}
          >
            {label.charAt(0).toUpperCase()}
          </Avatar>
        </Badge>
      }
    >
      <Typography.Text style={EMPLOYEE_NAME_STYLE}>{label}</Typography.Text>
    </Button>
  );
}
