import styled from 'styled-components/macro';
import { ReactComponent as Icon } from 'images/svg/clock-repeating.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

const ClockRepeating = styled(Icon)`
  ${applyStyleModifiers(CONFIG)};
  width: 1.6rem;
  height: 1.6rem;
`;
export default ClockRepeating;
