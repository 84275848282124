import { CSSProperties } from 'react';
import { SPACING } from '@optii-solutions/ui-library';

type TStyles = {
  [key: string]: CSSProperties;
};

export const UPLOAD_LIST: TStyles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: SPACING.SIZE_SM,
    padding: SPACING.NONE,
  },
  iconContainer: {
    fontSize: SPACING.SIZE_MD,
  },
};
