import { getEnvironment } from '@optii/shared/utils/config';
import { DownOutlined } from '@ant-design/icons';
import {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  SyntheticEvent,
  ReactNode,
} from 'react';
import {
  COLORS,
  DatePicker,
  FormItem,
  SPACING,
  Select,
  TextArea,
  TimePicker,
  ConfigProvider,
  Flex,
  Typography,
  Button,
  useWatch,
  FormList,
  FONTS,
  Row,
  Col,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { isPlatform } from '@ionic/react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { DefaultOptionType } from 'antd/es/cascader';
import { RuleObject } from 'antd/es/form';

import {
  useAccess,
  UserAccessContext,
  GA_EVENTS,
  initGQLData,
  type TUploadHandleFile,
} from '@optii/shared';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import { CHECKLIST_TEMPLATES } from '@optii/shared/queries/checklist/checklistTemplates';
import { use12Hours } from '@optii/shared/utils/localizeDateConfiguration';
import { UploadDragger } from '@optii/shared/components/molecules/UploadDragger';
import { Values } from '@optii/shared/contexts/RepeatingContext';
import { UploadList } from '@optii/shared/components/molecules/UploadList';
import { PredictiveDueTime } from '../PredictiveDueTime/index';

import {
  CustomStyles,
  ICONS,
  JobFormRegularFieldsDynamicSelectItem,
  STYLES,
  jobFormAssigneeRules,
  RepeatingJobFields,
  type TIcons,
  JobFormTreeSelectWrapper,
} from './JobForm.elements';
import type {
  TJobAssetTypeLocationOption,
  TJobData,
  TJobFormRegularFieldsProps,
  TPreferredLanguage,
  TJobDataLocation,
  TJobChecklistOption,
  TJobInputAssignee,
  TJobDataAsset,
  TEditJobData,
  TJobInputDepartment,
  TJobInputRole,
} from './JobForm.types';
import {
  AUTO_ASSIGNEE_ID,
  JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
  JOB_METADATA,
  JOB_REPEAT,
  JOB_STATUSES,
  STATUS_ASSIGNEE_FEEDBACK_MESSAGE,
} from './JobForm.constants';
import {
  getEditJobAssignee,
  getFormattedJobDeparment,
  getFormattedJobRole,
  getJobLocations,
  JobFormDropdownCustomHeightOnFocus,
} from './JobForm.helpers';
import { useJobForm } from './JobForm.hooks';
import {
  EditJobFormTabs,
  TEditJobFormTabsPropsJobChecklists,
  TEditJobFormTabsPropsJobNotes,
} from './JobFormTabs';

export function JobFormRegularFields(props: TJobFormRegularFieldsProps) {
  const {
    form,
    formOptions,
    showAdvancedFields,
    setShowAdvancedFields,
    values,
    mode,
    onClose,
    propertyShard,
  } = props;

  const [notes, setNotes] = useState<string>('');

  const { t } = useTranslation(['common', 'fields', 'jobs', 'projects'], {
    useSuspense: false,
  });
  const { can } = useAccess();
  const env = getEnvironment();

  const canAddChecklist = can(PERMISSIONS.settings.checklists.view);
  const canAutoCreate = can(PERMISSIONS.jobs.autocreate);
  const canRepeatingJobs = can(PERMISSIONS.settings.repeatingJobs.view);

  const { user } = useContext(UserAccessContext.Context) as TPreferredLanguage;
  const {
    // General
    jobItems,
    locationsAssetType,
    locations,
    job,
    audit,

    // Edit
    roles,
    employees,
    departments,
    handleAddNote,
    handleAddTemplateNote,
    updateLoading,
    loadingMinimalLocations,
    submitted,
    updateChecklistTask: [updateChecklistTask],
    jobRefetch,
    repeatingJobRefetch,
  } = useJobForm({
    form,
    onClose,
    propertyShard,
  });

  const watchedValues = useWatch([], form);

  const getPrioritySelectLabelRender = (label: {
    value: any;
    label: ReactNode;
  }) => (
    <Flex
      style={{
        alignItems: 'center',
        gap: SPACING.SIZE_MS,
      }}
    >
      {ICONS[label.value as keyof TIcons]()} {label.label}
    </Flex>
  );

  // watching for value changes on "actionType", "items", "location" and "asset" fields
  const itemWatchValue:
    | [{ id: string | null; amount: number; name: string }]
    | undefined = useWatch(['items'], form);
  const locationWatchValue = useWatch(['location'], form);
  const assetWatchValue = useWatch(['asset'], form);
  const { data: checklistsData } = useQuery(CHECKLIST_TEMPLATES, {
    context: { _instance: 'node', _shard: propertyShard },
    variables: {
      orderBy: 'name_ASC',
    },
    skip: !canAddChecklist,
  });

  const typedActiveLocationsAssetType: TJobAssetTypeLocationOption[] =
    locationsAssetType;

  formOptions.checklist = initGQLData(checklistsData).map(
    (checklistItem: TJobChecklistOption) => {
      const { id: value, name: label } = checklistItem;

      return {
        label,
        value: String(value),
      };
    },
  );

  const customStyles = CustomStyles();

  const isStatusInProgress = values?.status === JOB_STATUSES.inProgress.value;

  const isAssigneeDisabled = values?.assignee !== null && isStatusInProgress;

  const use12HoursValue = use12Hours();
  const repeatingJob = mode === 'editTemplate';

  const formatJobPredictiveDueTimeValues = useCallback(
    (_values: TJobData & TEditJobData) => {
      const selectedItem: {
        id: string;
        displayName: string;
      } = jobItems?.find(
        (jobItem: { id: string }) =>
          _values.items &&
          _values.items[0] &&
          _values?.items[0].id === jobItem.id,
      ) || { id: '', displayName: '' };

      const asset: TJobDataAsset[] = [_values?.asset] || [];
      const location: string[] | TJobDataLocation[] = _values?.location || [];
      const isAssetTypeLocation = !!_values?.asset;
      const { locations: formOptionsLocations } = formOptions;

      const currentAssetLocations: TJobDataAsset[] = isAssetTypeLocation
        ? asset
        : [];
      const currentLocations: TJobDataLocation[] =
        !isAssetTypeLocation && Array.isArray(location)
          ? location?.map((item) => ({ id: String(item) }) as TJobDataLocation)
          : [];

      const currentSelectedLocations = getJobLocations({
        currentAssetLocations,
        currentLocations,
        locations: formOptionsLocations,
        isAssetTypeLocation,
      });

      const department = _values?.department as TJobInputDepartment;
      const departmentHasNotBeenChanged = !!(
        _values?.department && typeof _values?.department === 'string'
      );
      const currentDeparment = departmentHasNotBeenChanged
        ? String(department)
        : undefined;
      const selectedDepartment =
        mode === 'edit'
          ? getFormattedJobDeparment({
              currentDeparment,
              departments,
              departmentHasNotBeenChanged,
              department,
            })
          : undefined;

      const role = _values?.role as TJobInputRole;
      const roleHasNotBeenChanged = !!(
        _values?.role && typeof _values?.role === 'string'
      );
      const currentRole = roleHasNotBeenChanged ? String(role) : undefined;
      const selectedRole =
        mode === 'edit'
          ? getFormattedJobRole({
              currentRole,
              roles,
              roleHasNotBeenChanged,
              role,
            })
          : undefined;

      const assignee = _values?.assignee as TJobInputAssignee;
      const assigneeHasNotBeenChanged = !!(
        _values?.assignee && typeof _values?.assignee === 'string'
      );
      const currentAssignee = assigneeHasNotBeenChanged
        ? String(assignee)
        : undefined;
      const selectedAssignee =
        mode === 'edit'
          ? getEditJobAssignee({
              currentAssignee,
              assignees: employees,
              assigneeHasNotBeenChanged,
              assignee,
            })
          : undefined;

      return {
        type: _values?.jobType,
        action: _values?.actionType,
        items: {
          id: selectedItem?.id,
          displayName: selectedItem?.displayName,
        },
        locations: currentSelectedLocations,
        priority: {
          id: _values?.priority,
        },
        department: {
          id: selectedDepartment?.id,
        },
        role: {
          id: selectedRole?.id,
          displayName: selectedRole?.name,
        },
        assignee: {
          id: Number(selectedAssignee?.id),
          displayName:
            selectedAssignee?.firstName && selectedAssignee?.lastName
              ? `${selectedAssignee?.firstName} ${selectedAssignee?.lastName}`
              : null,
        },
        notes: _values?.notes,
        attachments: _values?.attachments || [],
        startDate: '',
      };
    },
    [jobItems, formOptions, mode, departments, roles, employees],
  );

  const predictiveDueTimeValues = useMemo(
    () => formatJobPredictiveDueTimeValues(watchedValues),
    [formatJobPredictiveDueTimeValues, watchedValues],
  );

  // creating a new array of items to dont screw with references from the select
  // on this array we set as disabled any already selected items
  const filteredItems = formOptions.items?.map((item) => {
    if (itemWatchValue?.map((it) => it?.id).includes(item?.value)) {
      return {
        ...item,
        disabled: true,
      };
    }
    return item;
  });

  // here we check if the first selected item is an Asset to toggle the UX flow
  const isAssetSelected = itemWatchValue
    ? formOptions.items?.find((item) => item.value === itemWatchValue[0].id)
        ?.isAsset
    : undefined;

  // effect for removing extra items when the user selects an Asset
  useEffect(() => {
    if (isAssetSelected && itemWatchValue)
      form.setFieldValue('items', [itemWatchValue[0]]);
  }, [isAssetSelected, form, itemWatchValue]);

  // effect for toggling display of advancedFields
  useEffect(() => {
    if (!isAssetSelected) {
      setShowAdvancedFields(
        !!(Array.isArray(locationWatchValue) && locationWatchValue.length > 0),
      );
    } else {
      setShowAdvancedFields(!!(assetWatchValue && assetWatchValue[0].id));
    }
  }, [
    isAssetSelected,
    assetWatchValue,
    setShowAdvancedFields,
    locationWatchValue,
  ]);

  const IS_PROJECT_CYCLE = values?.metadata?.source === JOB_METADATA.project;

  const IS_SCHEDULE_START_TIME_ENABLED =
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test' &&
    process.env.NODE_ENV !== 'development';

  return (
    <>
      <FormList name="items">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <JobFormRegularFieldsDynamicSelectItem
                field={field}
                key={field.key}
                canAutoCreate={canAutoCreate}
                filtered={filteredItems}
                disabled={mode === 'edit' && IS_PROJECT_CYCLE}
                onFirstJobItemTypeChange={(_, option) => {
                  const prevItem = job?.items
                    ? job?.items[0]
                    : job?.jobTemplate?.items[0];
                  const prevSelected = formOptions.items?.find(
                    (item) => item.label === prevItem?.name,
                  );
                  const currentSelected = option as DefaultOptionType;
                  const isDifferentFromPrevious =
                    prevSelected?.isAsset?.id !== currentSelected?.isAsset?.id;

                  if (!currentSelected || isDifferentFromPrevious) {
                    form.setFieldValue('location', []);
                    form.setFieldValue('asset', null);
                  }
                }}
                isAssetSelected={!!isAssetSelected}
                remove={remove}
                form={form}
              />
            ))}

            {!isAssetSelected && (
              <div style={{ width: '100%', textAlign: 'right', marginTop: 16 }}>
                <Button
                  onClick={() => add({ amount: 1 })}
                  style={{
                    width: 150,
                  }}
                  disabled={mode === 'edit' && IS_PROJECT_CYCLE}
                >
                  {t('fields:Add item')}
                </Button>
              </div>
            )}
          </>
        )}
      </FormList>

      {!isAssetSelected && locations?.length && (
        <FormItem<TJobData>
          name="location"
          label={t('fields:Location')}
          rules={[
            {
              required: true,
              message: t('jobs:Location is a required field'),
              validator(rule, value) {
                if (!Array.isArray(value) || value.length === 0) {
                  return Promise.reject(
                    t('jobs:Location is a required field.'),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          validateTrigger="onBlur"
        >
          <JobFormTreeSelectWrapper
            locations={locations}
            loadingMinimalLocations={loadingMinimalLocations}
            placeholder={t('fields:Search...')}
            disabled={mode === 'edit' && IS_PROJECT_CYCLE}
            typedActiveLocationsAssetType={typedActiveLocationsAssetType}
          />
        </FormItem>
      )}

      {isAssetSelected && (
        <FormItem<TJobData>
          name="asset"
          label={t('fields:Location')}
          rules={[
            {
              required: true,
              message: t('jobs:Required'),
            },
          ]}
          validateTrigger="onBlur"
          normalize={(value: string) => {
            const { extra } = formOptions.assetTypeLocations?.find(
              (item) => item.value === value,
            ) || {
              extra: { locationId: undefined, locationTitle: undefined },
            };

            const { locationId, locationTitle } = extra;

            return [
              {
                id: value,
                value,
                locationId,
                locationTitle,
              },
            ];
          }}
        >
          <Select
            allowClear
            placeholder={t('fields:Select...')}
            size="large"
            options={formOptions.assetTypeLocations?.filter(
              (item) =>
                item.extra.jobItem ===
                formOptions.items?.find(
                  (_item: { value: string | number }) =>
                    _item.value === itemWatchValue?.[0].id,
                )?.label,
            )}
            // eslint-disable-next-line react/no-unstable-nested-components
            optionRender={(option) => {
              const displayedOption: TJobAssetTypeLocationOption =
                typedActiveLocationsAssetType.find(
                  (item) => item.id === option.value,
                ) || ({} as TJobAssetTypeLocationOption);

              return (
                <Flex
                  style={{
                    alignItems: 'center',
                    gap: SPACING.SIZE_MS,
                  }}
                >
                  {displayedOption?.location?.longDisplayName}{' '}
                  {displayedOption?.assetType?.displayName}{' '}
                  {displayedOption?.notes ? displayedOption.notes : null}
                </Flex>
              );
            }}
            onChange={(location: string) => {
              const displayedOption: TJobAssetTypeLocationOption =
                typedActiveLocationsAssetType.find(
                  (item) => item.id === location,
                ) || ({} as TJobAssetTypeLocationOption);

              form.setFieldValue('location', [displayedOption]);
            }}
            disabled={mode === 'edit' && IS_PROJECT_CYCLE}
          />
        </FormItem>
      )}

      {showAdvancedFields || mode === 'edit' ? (
        <>
          <FormItem<TJobData>
            name="status"
            label={t('fields:Status')}
            rules={[
              {
                required: true,
                message: t('jobs:Required'),
              },
            ]}
            validateTrigger="onBlur"
          >
            <Select
              allowClear
              placeholder=""
              size="large"
              options={formOptions.statuses}
            />
          </FormItem>

          <FormItem<TJobData>
            name="priority"
            label={t('fields:Priority Level')}
            rules={[
              {
                required: true,
                message: t('jobs:Required'),
              },
            ]}
            validateTrigger="onBlur"
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.jobType !== currentValues.jobType
            }
          >
            <Select
              allowClear
              placeholder=""
              size="large"
              options={formOptions.priorityLevel}
              labelRender={getPrioritySelectLabelRender}
              // eslint-disable-next-line react/no-unstable-nested-components
              optionRender={(option) => (
                <Flex
                  style={{
                    alignItems: 'center',
                    gap: SPACING.SIZE_MS,
                  }}
                >
                  {ICONS[option.data.value as keyof TIcons]()}{' '}
                  {option.data.label}
                </Flex>
              )}
            />
          </FormItem>

          {mode === 'edit' || !canRepeatingJobs ? (
            <>
              <FormItem<TEditJobData>
                name="dueDate"
                label={t('fields:Due by Date')}
              >
                <DatePicker
                  allowClear
                  locale={user.preferredLang}
                  format="MMM D, YYYY"
                  style={customStyles.DatePicker}
                />
              </FormItem>

              <Row justify="space-between">
                {IS_SCHEDULE_START_TIME_ENABLED && (
                  <Col xs={9} sm={11}>
                    <FormItem<TEditJobData>
                      name="scheduleStartTimeAt"
                      label={t('fields:Start Time')}
                    >
                      <TimePicker
                        allowClear
                        locale={user.preferredLang}
                        format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
                        onPickerValueChange={(date) =>
                          form.setFieldValue('scheduleStartTimeAt', date)
                        }
                        style={customStyles.TimePicker}
                      />
                    </FormItem>
                  </Col>
                )}

                <Col
                  xs={IS_SCHEDULE_START_TIME_ENABLED ? 14 : 24}
                  sm={IS_SCHEDULE_START_TIME_ENABLED ? 12 : 24}
                >
                  <FormItem<TEditJobData>
                    name="dueTime"
                    label={t('fields:Due by Time')}
                    style={{
                      marginBottom: SPACING.NONE,
                    }}
                  >
                    <TimePicker
                      allowClear
                      locale={user.preferredLang}
                      format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
                      onPickerValueChange={(date) =>
                        form.setFieldValue('dueTime', date)
                      }
                      style={customStyles.TimePicker}
                    />
                  </FormItem>
                  <FormItem<TEditJobData> noStyle name="predictiveDueTime" />
                  <FormItem<TEditJobData> name="timezone" noStyle />
                  <PredictiveDueTime
                    margin={`0 0 ${SPACING.SIZE_L}px`}
                    absolutePosition={false}
                    propertyShard={propertyShard}
                    data={predictiveDueTimeValues as unknown as Values}
                    handleCalcSuggestion={(value: string | number) => {
                      let dateTime;
                      const timezoneOffset = dayjs()
                        .tz(form.getFieldValue('timezone'))
                        .utcOffset();

                      if (timezoneOffset === 0) {
                        dateTime = dayjs().add(Number(value), 'minutes').utc();
                      } else {
                        dateTime = dayjs()
                          .add(Number(value), 'minutes')
                          .tz(form.getFieldValue('timezone'));
                      }

                      form.setFieldValue('predictiveDueTime', dateTime);
                    }}
                    handleSuggestionClick={(value: string | number) => {
                      let dateTime;
                      const timezoneOffset = dayjs()
                        .tz(form.getFieldValue('timezone'))
                        .utcOffset();

                      if (timezoneOffset === 0) {
                        dateTime = dayjs().add(Number(value), 'minutes').utc();
                      } else {
                        dateTime = dayjs()
                          .add(Number(value), 'minutes')
                          .tz(form.getFieldValue('timezone'));
                      }

                      form.setFieldValue('dueDate', dateTime);
                      form.setFieldValue('dueTime', dateTime);
                    }}
                    isNewForm
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      selectorBg: COLORS.secondary[4],
                      colorText: COLORS.neutral[1],
                      optionSelectedColor: COLORS.neutral[7],
                    },
                  },
                }}
              >
                <FormItem<TJobData> name="repeat">
                  <Select
                    size="large"
                    options={formOptions.repeat}
                    style={{
                      maxWidth: 180,
                      width: 'auto',
                      color: COLORS.neutral[1],
                    }}
                    dropdownStyle={{
                      minWidth: 180,
                      backgroundColor: COLORS.secondary[4],
                    }}
                    suffixIcon={
                      <DownOutlined
                        style={{
                          color: COLORS.neutral[1],
                          pointerEvents: 'none',
                        }}
                      />
                    }
                  />
                </FormItem>
              </ConfigProvider>
              <div style={STYLES.repeatContainer}>
                <FormItem<TJobData> name="timezone" noStyle />
                <RepeatingJobFields
                  user={user}
                  customStyles={customStyles}
                  t={t}
                  form={form}
                  formOptions={formOptions}
                  watchedValues={watchedValues}
                />
                <FormItem<TJobData> name="predictiveDueTime" noStyle />
                <PredictiveDueTime
                  margin={`${SPACING.SIZE_L}px 0`}
                  absolutePosition={false}
                  propertyShard={propertyShard}
                  data={predictiveDueTimeValues as unknown as Values}
                  handleCalcSuggestion={(value: number | string) => {
                    let dateTime;
                    const timezoneOffset = dayjs()
                      .tz(form.getFieldValue('timezone'))
                      .utcOffset();

                    if (timezoneOffset === 0) {
                      dateTime = dayjs().add(Number(value), 'minutes').utc();
                    } else {
                      dateTime = dayjs()
                        .add(Number(value), 'minutes')
                        .tz(form.getFieldValue('timezone'));
                    }

                    form.setFieldValue('predictiveDueTime', dateTime);
                  }}
                  handleSuggestionClick={(value: number | string) => {
                    let dateTime;
                    const timezoneOffset = dayjs()
                      .tz(form.getFieldValue('timezone'))
                      .utcOffset();

                    if (timezoneOffset === 0) {
                      dateTime = dayjs().add(Number(value), 'minutes').utc();
                    } else {
                      dateTime = dayjs()
                        .add(Number(value), 'minutes')
                        .tz(form.getFieldValue('timezone'));
                    }

                    form.setFieldValue('dueDate', dateTime);
                    form.setFieldValue('dueTime', dateTime);
                    form.setFieldValue('dailyDueTime', dateTime);
                    form.setFieldValue('weeklyDueTime', dateTime);
                    form.setFieldValue('monthlyDueTime', dateTime);
                    form.setFieldValue('yearlyDueTime', dateTime);
                  }}
                  isNewForm
                />
              </div>
            </>
          )}

          <Typography.Title
            level={4}
            style={{
              marginTop: SPACING.SIZE_XL,
              marginBottom: SPACING.SIZE_MS,
              fontFamily: 'Montserrat',
            }}
          >
            {t('jobs:Assignment')}
          </Typography.Title>

          <FormItem<TJobData>
            name="department"
            label={t('fields:Department')}
            rules={[
              {
                required: true,
                message: t('jobs:Required'),
              },
            ]}
            validateTrigger="onBlur"
            normalize={(value: string | number) => {
              const { extra } = formOptions.department?.find(
                (item) => item.value === value,
              ) || {
                extra: { departmentCode: undefined, displayName: undefined },
              };

              const { displayName, departmentCode } = extra;

              return {
                id: value,
                value,
                displayName,
                departmentCode,
              };
            }}
          >
            <Select
              allowClear
              placeholder={t('fields:Select...')}
              size="large"
              options={formOptions.department}
              optionFilterProp="label"
              showSearch
              placement={isPlatform('capacitor') ? 'topLeft' : undefined}
              listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
              onFocus={() =>
                JobFormDropdownCustomHeightOnFocus({
                  height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
                })
              }
            />
          </FormItem>

          <FormItem<TJobData>
            name="role"
            label={t('fields:Role')}
            normalize={(value) => {
              const { extra } = formOptions.role?.find(
                (item) => item.value === value,
              ) || {
                extra: { name: undefined, description: undefined },
              };

              const { name, description } = extra;

              return {
                id: value,
                value,
                name,
                description,
              };
            }}
          >
            <Select
              allowClear
              placeholder={t('fields:Select...')}
              size="large"
              options={formOptions.role}
              optionFilterProp="label"
              showSearch
              placement={isPlatform('capacitor') ? 'topLeft' : undefined}
              listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
              onFocus={() =>
                JobFormDropdownCustomHeightOnFocus({
                  height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
                })
              }
            />
          </FormItem>

          <FormItem<TJobData>
            name="assignee"
            label={t('fields:Team Member')}
            dependencies={['status', 'checklist']}
            normalize={(value: string | number) => {
              if (!value) return value;
              const { extra } = formOptions.assignee?.find(
                (item) => item?.value === value,
              ) || {
                extra: {
                  userName: undefined,
                  firstName: undefined,
                  lastName: undefined,
                },
              };

              const { userName, firstName, lastName } = extra;

              return {
                id: value,
                value,
                userName,
                firstName,
                lastName,
              };
            }}
            validateStatus={
              // eslint-disable-next-line no-nested-ternary
              watchedValues &&
              (watchedValues.status === JOB_STATUSES.inProgress.value ||
                watchedValues.status === JOB_STATUSES.cancelled.value ||
                watchedValues.status === JOB_STATUSES.completed.value) &&
              (!watchedValues.assignee ||
                String(watchedValues.assignee.id) === String(AUTO_ASSIGNEE_ID))
                ? 'error'
                : 'success'
            }
            rules={
              jobFormAssigneeRules({
                watchedValues,
                t,
              }) as RuleObject[]
            }
            help={
              // eslint-disable-next-line no-nested-ternary
              watchedValues &&
              (watchedValues.status === JOB_STATUSES.inProgress.value ||
                watchedValues.status === JOB_STATUSES.cancelled.value ||
                watchedValues.status === JOB_STATUSES.completed.value) &&
              (!watchedValues.assignee ||
                String(watchedValues.assignee.id) === String(AUTO_ASSIGNEE_ID))
                ? t(STATUS_ASSIGNEE_FEEDBACK_MESSAGE[watchedValues.status])
                : null
            }
          >
            <Select
              allowClear
              placeholder={t('fields:Select...')}
              size="large"
              options={formOptions.assignee?.filter(
                (employeeItem) => !!employeeItem,
              )}
              disabled={isAssigneeDisabled}
              optionFilterProp="label"
              showSearch
              placement={isPlatform('capacitor') ? 'topLeft' : undefined}
              listHeight={JOB_FORM_DROPDOWN_CUSTOM_HEIGHT}
              onFocus={() =>
                JobFormDropdownCustomHeightOnFocus({
                  height: JOB_FORM_DROPDOWN_CUSTOM_HEIGHT,
                })
              }
            />
          </FormItem>

          <Typography.Title
            level={4}
            style={{
              marginTop: SPACING.SIZE_XL,
              marginBottom: SPACING.SIZE_MS,
              fontFamily: 'Montserrat',
            }}
          >
            {t('jobs:Notes and Attachments')}
          </Typography.Title>

          {(mode === 'edit' || mode === 'editTemplate') && canAddChecklist ? (
            <>
              {repeatingJob ? (
                <FormItem<TJobData>
                  name="checklist"
                  label={t('fields:Checklists')}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          value &&
                          getFieldValue('status') ===
                            JOB_STATUSES.completed.value
                        ) {
                          return Promise.reject(
                            new Error('Checklist cannot be added to Done jobs'),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  dependencies={['status']}
                >
                  <Select
                    allowClear
                    placeholder={t('fields:Select...')}
                    size="large"
                    options={formOptions.checklist}
                    optionFilterProp="label"
                    showSearch
                  />
                </FormItem>
              ) : null}

              <FormItem<TEditJobData>
                noStyle
                style={{
                  marginBottom: SPACING.SIZE_XL,
                }}
              >
                <EditJobFormTabs
                  updateChecklistTask={updateChecklistTask}
                  submitted={submitted}
                  refetch={
                    mode === 'editTemplate' ? repeatingJobRefetch : jobRefetch
                  }
                  canEdit={
                    !(
                      (values?.status !== JOB_STATUSES.notStarted ||
                        values?.status !== JOB_STATUSES.onHold) &&
                      !!values?.checklists?.length
                    )
                  }
                  hideAddNote
                  job={{
                    notes: (job?.notes ||
                      job?.jobTemplate
                        ?.notes) as TEditJobFormTabsPropsJobNotes[],
                    checklists:
                      values?.checklists ||
                      (values?.checklistTemplate?.map((item: any) => ({
                        ...item,
                        checklistTasks: item.tasks,
                      })) as TEditJobFormTabsPropsJobChecklists[]),
                    status: values?.status,
                  }}
                  audit={audit}
                />
              </FormItem>

              <Flex vertical gap={SPACING.SIZE_XS}>
                <label htmlFor="notes">
                  <span
                    style={{
                      minWidth: '100%',
                      fontWeight: 500,
                      fontSize: `${FONTS.xSmall.size}px`,
                    }}
                  >
                    {t('fields:Notes')}
                  </span>
                </label>

                <Flex
                  gap={SPACING.SIZE_MD}
                  align="center"
                  style={{
                    marginBottom: SPACING.SIZE_MD,
                  }}
                >
                  <TextArea
                    id="notes"
                    rows={1}
                    size="large"
                    value={notes}
                    onChange={(event: SyntheticEvent<HTMLTextAreaElement>) => {
                      setNotes(event.currentTarget.value);
                    }}
                  />

                  <Button
                    type="default"
                    disabled={!notes.trim() || updateLoading}
                    onClick={async () => {
                      if (mode === 'edit') {
                        await handleAddNote({
                          id: job?.id,
                          setNotes,
                          notes,
                        });

                        return;
                      }

                      await handleAddTemplateNote({
                        notes,
                        setNotes,
                      });
                    }}
                  >
                    {t('jobs:Add Note')}
                  </Button>
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              {canAddChecklist && (
                <FormItem<TJobData>
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.repeat !== currentValues.repeat
                  }
                >
                  <FormItem<TJobData>
                    name="checklist"
                    label={t('fields:Add Checklist')}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            value &&
                            getFieldValue('status') ===
                              JOB_STATUSES.completed.value
                          ) {
                            return Promise.reject(
                              new Error(
                                'Checklist cannot be added to Done jobs',
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    dependencies={['status']}
                    validateTrigger="onChange"
                  >
                    <Select
                      allowClear
                      placeholder={t('fields:Select...')}
                      size="large"
                      options={formOptions.checklist}
                      optionFilterProp="label"
                      showSearch
                    />
                  </FormItem>
                </FormItem>
              )}

              <FormItem<TJobData> name="notes" label={t('fields:Notes')}>
                <TextArea rows={2} />
              </FormItem>
            </>
          )}

          {/* eslint-disable-next-line no-nested-ternary */}
          {mode === 'edit' || mode === 'editTemplate' ? (
            watchedValues && watchedValues.attachments?.length ? (
              <FormItem<TEditJobData>
                name="attachments"
                shouldUpdate={(prevValues, currentValues) =>
                  JSON.stringify(prevValues.attachments) !==
                  JSON.stringify(currentValues.attachments)
                }
                style={{
                  marginBottom: SPACING.NONE,
                }}
              >
                <UploadList
                  placeholder={t(
                    'fields:Click or drag a file to this area to upload',
                  )}
                  handleUpload={(file: TUploadHandleFile) => {
                    const previousFiles =
                      form.getFieldValue('attachments') || [];

                    form.setFieldValue('attachments', [...previousFiles, file]);
                  }}
                  handleRemove={(file: TUploadHandleFile) => {
                    const previous = form.getFieldValue('attachments') || [];
                    const next = previous.filter(
                      (previousItem: { uid: string }) =>
                        previousItem.uid !== file.uid,
                    );

                    form.setFieldValue('attachments', next);
                  }}
                  analytics={GA_EVENTS.addFile}
                  fileList={watchedValues.attachments.map(
                    (attachmentItem: TUploadHandleFile) => attachmentItem.uid,
                  )}
                />
              </FormItem>
            ) : (
              <FormItem<TEditJobData>
                name="attachments"
                style={{
                  marginBottom: SPACING.NONE,
                }}
              >
                <UploadDragger
                  placeholder={t(
                    'fields:Click or drag a file to this area to upload',
                  )}
                  handleUpload={(file: TUploadHandleFile) => {
                    const previous = form.getFieldValue('attachments') || [];

                    form.setFieldValue('attachments', [...previous, file]);
                  }}
                  analytics={GA_EVENTS.addFile}
                />
              </FormItem>
            )
          ) : (
            <FormItem<TJobData>
              name="attachments"
              style={{
                marginBottom: SPACING.NONE,
              }}
            >
              <UploadDragger
                placeholder={t(
                  'fields:Click or drag a file to this area to upload',
                )}
                handleUpload={(file: TUploadHandleFile) => {
                  const previous = form.getFieldValue('attachments') || [];

                  form.setFieldValue('attachments', [...previous, file]);
                }}
                handleRemove={(file: TUploadHandleFile) => {
                  const previous = form.getFieldValue('attachments') || [];
                  const next = previous.filter(
                    (previousItem: { name: string }) =>
                      previousItem.name !== file.name,
                  );

                  form.setFieldValue('attachments', next);
                }}
                analytics={GA_EVENTS.addFile}
              />
            </FormItem>
          )}
        </>
      ) : null}
    </>
  );
}
