import { FunctionComponent } from 'react';
import { Flex, SPACING, Skeleton } from '@optii-solutions/ui-library';
import { FlexProps } from 'antd';
import styled from 'styled-components';

export function JobDetailsSkeleton() {
  const NewFlex: FunctionComponent<FlexProps> = styled(Flex)`
    min-height: 100%;
    flex: 1;
    align-content: stretch;
    flexwrap: wrap;
    gap: ${SPACING.SIZE_DEFAULT}px;
    > .ant-skeleton-block {
      flex: 1;
      > .ant-skeleton-input {
        min-height: 100%;
      }
    }
    > .ant-skeleton-block:nth-child(2) {
      max-height: 56px;
    }
    > .ant-skeleton-block:nth-child(3) {
      max-height: 48px;
    }
  `;

  return (
    <NewFlex vertical align="stretch">
      <Skeleton.Input block active />
      <Skeleton.Input block active />

      <Skeleton.Input block active />

      <Skeleton.Input block active />
    </NewFlex>
  );
}
