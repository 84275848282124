import React from 'react';
import { PERMISSIONS, JOB_STATUS } from 'utils/constants';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import ToggleContent from 'components/shared/ToggleContent';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { useAccess } from '@optii/shared';
import Confirmation from 'components/shared/Confirmation';
import styled from 'styled-components/macro';
import Icons from 'blocks/Icons';
import { useTranslation } from 'react-i18next';

const DeleteIcon = styled(Icons.Delete)`
  margin-left: 1rem;
`;

function Delete({ job, handleDeleteJob, updateLoading }) {
  const { can } = useAccess();
  const canEdit = can(PERMISSIONS.jobs.all);
  const canDelete =
    [JOB_STATUS.pending, JOB_STATUS.new].includes(job.status) && canEdit;
  const { t } = useTranslation(['jobs', 'common']);

  return (
    canDelete && (
      <ToggleContent
        toggle={(show) => (
          <DeleteIcon
            title={t('common:Delete')}
            onClick={(e) => {
              e.stopPropagation();
              show();
              GoogleAnalyticsClient.event(GA_EVENTS.deleteJobCardSwimLane);
            }}
          />
        )}
        content={(hide) => (
          <Confirmation
            hide={hide}
            confirm={(_) => handleDeleteJob(job.id)}
            action={t('common:Yes, Delete')}
            itemName={t('common:Delete')}
            disabled={updateLoading}
          >
            <p>{t('jobs:Are you sure you want to delete this job?')}</p>
          </Confirmation>
        )}
      />
    )
  );
}

export default Delete;
