import { gql } from "@apollo/client";

export default gql`
  mutation Mutation($updateProjectCycleInput: UpdateProjectCycleInput!) {
    updateProjectCycle(updateProjectCycleInput: $updateProjectCycleInput) {
      id
      projectCycleHash
    }
  }
`;
