import { gql } from "@apollo/client";

export default gql`
  mutation createScheduleEntry($input: CreateScheduleEntry!) {
    createScheduleEntry(input: $input) {
      id
      employeeID
      employmentTypeID
      departmentID
      roleID
      isAvailabe
      date
      timeStartSec
      timeStopSec
      userDatetime
    }
  }
`;
