export const createModalRoot = (): Element => {
  const modalRoot = document.getElementById("modal-root");
  // for tests, where there is no root
  if (!modalRoot) {
    const createdModalRoot = document.createElement("div");
    createdModalRoot.setAttribute("id", "modal-root");
    return document.body.appendChild(createdModalRoot);
  }

  return modalRoot;
}

export const createSubModalRoot = (): Element => {
  const subModalRoot = document.getElementById("sub-modal");
  // for tests, where there is no root
  if (!subModalRoot) {
    const createdSubModalRoot = document.createElement("div");
    createdSubModalRoot.setAttribute("id", "sub-modal");
    return document.body.appendChild(createdSubModalRoot);
  }

  return subModalRoot;
}