import { gql } from '@apollo/client';

export default gql`
  mutation updateCleaningExceptionStatus($cleaningExceptionInput: CleaningExceptionInput!) {
		updateCleaningExceptionStatus(cleaningExceptionInput: $cleaningExceptionInput) {
			locationId
			cleaningExceptionStatus {
				doNotDisturb
				doubleLockActive
				noServiceRequired
				sleepOutNoShow
			}
		}
	}
`;