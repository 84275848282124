import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Secondary from 'components/shared/buttons/SecondaryButton';

const Button = styled(Secondary)`
  width: 100%;
`;
function LoadMore({ pageInfo, fetchMore, callback = () => ({}), afterLoad = () => ({}), btnLabel, isLoading }) {
  const { t } = useTranslation(['common']);

  const [loadingMore, setLoadingMore] = useState(false);
  return (
    <Button
      data-testid="load-more"
      disabled={loadingMore}
      onClick={async () => {
        callback();
        setLoadingMore(true);
        try {
          await fetchMore({
            variables: {
              cursor: pageInfo,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const newEdges = fetchMoreResult.page.edges || [];
              const { _pageInfo } = fetchMoreResult.page;

              return {
                page: {
                  edges: [...previousResult.page.edges, ...newEdges],
                  pageInfo: _pageInfo,
                },
              };
            },
          });
          afterLoad();
        } catch (err) {
          console.error(
            `${t('common:Caught error fetching more records')}:`,
            err,
          );
        }
        setLoadingMore(false);
      }}
    >
      {(loadingMore || isLoading) ? t('common:Loading...') : btnLabel || t('common:Load more')}
    </Button>
  );
}
export default LoadMore;
