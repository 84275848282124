import React, { useState } from 'react';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Typography,
  Card,
  COLORS,
  RADIUS,
  FONTS,
  SPACING,
  Tag,
  Flex,
  Tooltip,
} from '@optii-solutions/ui-library';
import { Divider, ThemeConfig } from 'antd';
import Icon from '@ant-design/icons';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { getAction } from 'utils/formatters/jobs';
import { capitalizeFirstLetter } from 'utils/formatters/capitalizeFirstLetter';
import { STATUS_COLORS } from 'theme/colors';
import {
  ImagesIcon,
  NotesIcon,
  Recurring,
  LowPriority,
  MediumPriority,
  HighPriority,
  HighestPriority,
  CancelledIcon,
  InProgressIcon,
  DoneIcon,
  OnHoldIcon,
  ProjectsIcon,
  RushActive,
  RushInactive,
} from 'images/svg/currentJobs/jobs';
import { TCurrentJob } from './types';

const JOB_STATUS = [
  {
    id: 'archived',
    displayName: 'Archived',
    icon: null,
    colors: STATUS_COLORS.archived,
  },
  {
    id: 'pending',
    displayName: 'Not Started',
    icon: null,
    colors: STATUS_COLORS.notStarted,
  },
  {
    id: 'in progress',
    displayName: 'In Progress',
    icon: <Icon component={InProgressIcon} />,
    colors: STATUS_COLORS.inProgress,
  },
  {
    id: 'completed',
    displayName: 'Done',
    icon: <Icon component={DoneIcon} />,
    colors: STATUS_COLORS.done,
  },
  {
    id: 'cancelled',
    displayName: 'Cancelled',
    icon: <Icon component={CancelledIcon} />,
    colors: STATUS_COLORS.cancelled,
  },
  {
    id: 'on hold',
    displayName: 'On Hold',
    icon: <Icon component={OnHoldIcon} />,
    colors: STATUS_COLORS.onHold,
  },
];

const JOB_PRIORITIES = {
  low: <Icon component={LowPriority} />,
  medium: <Icon component={MediumPriority} />,
  high: <Icon component={HighPriority} />,
  highest: <Icon component={HighestPriority} />,
};

const REPEATING_JOB_CODE = 'repeating-jobs-svc';
const PROJECT_JOB_CODE = 'project-cycle-svc';

const JOB_DETAILS_THEME: ThemeConfig = {
  components: {
    Typography: {
      colorTextHeading: COLORS.neutral[8],
      colorText: COLORS.neutral[8],
      fontSizeHeading5: FONTS.h5.size,
    },
    Card: {
      paddingLG: SPACING.NONE,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorderSecondary: COLORS.neutral[5],
      headerHeight: 60,
      headerFontSize: FONTS.h5.size,
      headerFontSizeSM: FONTS.h5.size,
    },
    Tag: {
      defaultColor: COLORS.sunset[10],
      defaultBg: COLORS.sunset[2],
      colorBorder: COLORS.neutral[4],
      fontSizeSM: FONTS.small.size,
    },
  },
};

const COLUMN_SIZES = {
  smallest: 20,
};

const STYLES = {
  cardTitle: {
    paddingLeft: SPACING.SIZE_MD,
    marginTop: 0,
    letterSpacing: FONTS.h5.letterSpacing,
  },
  buttonAddJob: {
    marginRight: SPACING.SIZE_MD,
    color: COLORS.secondary[4],
    borderColor: COLORS.secondary[4],
  },
  cardEmptyText: {
    display: 'flex',
    minWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  cardListItem: {
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    gap: SPACING.SIZE_DEFAULT,
    maxWidth: '100%',
  },
  cardListLastItem: {
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    gap: SPACING.SIZE_DEFAULT,
    maxWidth: '100%',
  },
  cardListItemIcon: {
    minWidth: COLUMN_SIZES.smallest,
    display: 'flex',
    justifyContent: 'center',
  },
  cardListItemMain: {
    borderLeft: `0.5px solid ${COLORS.neutral[5]}`,
    paddingLeft: SPACING.SIZE_DEFAULT,
    maxWidth: 'calc(100% - 40px)',
    flex: 1,
  },
  cardListDivider: {
    marginTop: SPACING.SIZE_L,
  },
  cardBody: {
    padding: SPACING.SIZE_L,
    paddingTop: SPACING.SIZE_XL,
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING.NONE,
    justifyContent: 'stretch',
  },
  cardBodyItemHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
  },
  cardBodyItemHeaderLeftCol: {
    flex: 1,
    display: 'flex',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardBodyItemHeaderRightCol: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardBodyItemHeaderTitle: {
    display: 'block',
    flex: 1,
    color: COLORS.primary[5],
    fontSize: FONTS.large.size,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  cardBodyItemInformation: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: SPACING.SIZE_DEFAULT,
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    display: 'flex',
  },
  cardBodyItemInformationRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
  cardBodyItemInformationAssignee: {
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '50%',
  },
  cardBodyItemInformationGuest: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    whiteSpace: 'nowrap',
    minWidth: '50%',
  },
  cardBodyItemInformationAssigneeDepartment: {
    fontWeight: '500',
    paddingRight: SPACING.SIZE_XS,
    maxWidth: '50%',
    whiteSpace: 'nowrap',
  },
  cardBodyItemInformationAssigneeName: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: 'auto',
    display: 'inline-block',
  },
} as const;

type CurrentJobProps = {
  jobList: Array<TCurrentJob>;
  location: {
    id: string;
  };
  timeZone?: string;
  toggleRush: (id: number, isRushed: boolean, action?: string) => void;
  updateLoading: boolean;
  onClose?: () => void;
};

type JobHeaderProps = {
  id: number;
  action?: string;
  items: { name: string; amount: number }[];
  status: string;
  onClose?: () => void;
};

type JobInformationProps = {
  id: number;
  notes?: {
    id: number;
    note: string;
  }[];
  targetTime: string;
  completedAt?: string;
  assignee?: {
    id: number;
    firstName: string;
    lastName: string;
  };
  department: {
    Id: number;
    departmentCode: string;
  }[];
  type: string;
  timeZone?: string;
};

type JobIconsProps = {
  id: number;
  isRushed: boolean;
  notes?: {
    id: number;
    note: string;
  }[];
  attachments?: [string];
  source: string;
  priority: string;
  toggleRush: (id: number, isRushed: boolean, action?: string) => void;
  action?: string;
  isLoading: boolean;
};

function JobIconsContainer(props: JobIconsProps) {
  const {
    id,
    isRushed,
    notes,
    attachments,
    priority,
    source,
    toggleRush,
    action,
    isLoading,
  } = props;
  const repeating = source === REPEATING_JOB_CODE;
  const project = source === PROJECT_JOB_CODE;
  const priorityKey = priority as keyof typeof JOB_PRIORITIES;
  const { t } = useTranslation(['common', 'jobs']);

  const [localRush, setLocalRush] = useState(isRushed);

  const handleToggle = () => {
    if (isLoading) {
      return;
    }
    setLocalRush((value) => !value);
    toggleRush(id, !localRush, action);
  };

  return (
    <Row>
      <Flex vertical align="center" gap="small">
        <Tooltip
          title={localRush ? t('jobs:Disable Rush') : t('jobs:Enable Rush')}
        >
          <Button
            size="small"
            type="text"
            onClick={handleToggle}
            icon={<Icon component={localRush ? RushActive : RushInactive} />}
          />
        </Tooltip>
        {priority && JOB_PRIORITIES[priorityKey]}
        {repeating && <Icon component={Recurring} />}
        {project && <Icon component={ProjectsIcon} />}
        {attachments && <Icon component={ImagesIcon} />}
        {notes && <Icon component={NotesIcon} />}
      </Flex>
    </Row>
  );
}

function JobHeader(props: JobHeaderProps) {
  const { id, items, action, status, onClose } = props;

  const mergedStatus = status === 'new' ? 'pending' : status;

  const index = JOB_STATUS.findIndex((s) => s.id === mergedStatus);
  const backgroundColor: string | null = JOB_STATUS[index]?.colors?.background;
  const fontColor: string | null = JOB_STATUS[index]?.colors?.font;
  const borderColor: string | null = JOB_STATUS[index]?.colors?.border;

  const { t } = useTranslation(['common', 'jobs']);
  const actionLabel = getAction(action)?.displayName;

  const [query, setQuery] = useQueryParams({
    openJob: StringParam,
    openRepeatingJob: StringParam,
  });

  const formattedItems = items
    ? items
        ?.map(({ name, amount }) =>
          amount > 1 ? `${name} (${amount})` : `${name}`,
        )
        .join(', ')
    : '';

  return (
    <Row style={STYLES.cardBodyItemHeaderRow}>
      <Col style={STYLES.cardBodyItemHeaderLeftCol}>
        <Typography.Link
          underline
          onClick={() => {
            if (query.openJob && onClose && typeof onClose === 'function') {
              setQuery({
                openJob: '',
              });

              onClose();

              setTimeout(() => {
                setQuery({
                  openJob: String(id),
                  openRepeatingJob: undefined,
                });
              }, 500);

              return;
            }

            setQuery({
              openJob: String(id),
              openRepeatingJob: undefined,
            });
          }}
          style={STYLES.cardBodyItemHeaderTitle}
        >
          {`${action ? t(`jobs:${actionLabel}`) : ''} ${formattedItems}`}
        </Typography.Link>
      </Col>
      <Col style={STYLES.cardBodyItemHeaderRightCol}>
        <Tag
          icon={JOB_STATUS[index]?.icon}
          color={backgroundColor || ''}
          style={{
            color: fontColor,
            borderColor,
            margin: SPACING.NONE,
          }}
        >
          {t(`jobs:${JOB_STATUS[index]?.displayName}`)}
        </Tag>
      </Col>
    </Row>
  );
}

function JobInformation(props: JobInformationProps) {
  const {
    id,
    notes,
    targetTime,
    completedAt,
    assignee,
    department,
    type,
    timeZone,
  } = props;
  const { t } = useTranslation(['jobs']);
  const target = new Date(targetTime).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  });
  const completed = completedAt
    ? new Date(completedAt).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      })
    : null;
  let assigneeDisplayName;
  if (assignee && assignee.firstName && assignee.lastName) {
    assigneeDisplayName = `
      ${capitalizeFirstLetter(assignee?.firstName)}
      ${assignee?.lastName[0]?.toLocaleUpperCase()}
    `;
  } else {
    assigneeDisplayName = t('jobs:Unassigned');
  }
  return (
    <Space style={STYLES.cardBodyItemInformation}>
      <Row>
        <Typography.Paragraph
          ellipsis
          style={{
            margin: SPACING.NONE,
          }}
        >
          {notes && notes[notes.length - 1].note}
        </Typography.Paragraph>
      </Row>
      <Row>
        <Typography.Text style={{ fontWeight: '500' }}>
          {completed ? t('common:Done') : t('common:Due By')}:&nbsp;
        </Typography.Text>
        <Typography.Text>{completed || target}</Typography.Text>
      </Row>
      <Row style={STYLES.cardBodyItemInformationRow}>
        <Col style={STYLES.cardBodyItemInformationAssignee}>
          <Typography.Text
            style={STYLES.cardBodyItemInformationAssigneeDepartment}
          >
            {department[0].departmentCode}
          </Typography.Text>
          <Typography.Text style={STYLES.cardBodyItemInformationAssigneeName}>
            {`| ${assigneeDisplayName}`}
          </Typography.Text>
        </Col>
        <Col style={STYLES.cardBodyItemInformationGuest}>
          <Typography.Text
            style={{ color: COLORS.neutral[7] }}
          >{`#${id} ${capitalizeFirstLetter(
            t(`jobs:${type}`),
          )}`}</Typography.Text>
        </Col>
      </Row>
    </Space>
  );
}

export function CurrentJobs(props: CurrentJobProps) {
  const { jobList, timeZone, toggleRush, updateLoading, onClose } = props;

  const { t } = useTranslation(['floorplan', 'jobs']);

  return (
    <ConfigProvider theme={JOB_DETAILS_THEME}>
      <Card
        title={
          <Typography.Title level={5} style={STYLES.cardTitle}>
            {t('jobs:Current Jobs')}
          </Typography.Title>
        }
        // extra={
        //   <Button
        //     style={STYLES.buttonAddJob}
        //     size="small"
        //     onClick={() => {
        //       setQuery({
        //         addJob: true,
        //         locationId: location?.id,
        //       });
        //     }}
        //   >
        //     {t('jobs:Add Job')}
        //   </Button>
        // }
        bodyStyle={STYLES.cardBody}
      >
        {!jobList.length ? (
          <Typography.Paragraph style={STYLES.cardEmptyText}>
            {t('floorplan: No jobs are scheduled for today')}
          </Typography.Paragraph>
        ) : (
          jobList.map((job, index, array) => {
            const key = `location-detail-current-jobs-item-${job.id}`;
            const isLastItem = index + 1 >= array.length;

            const styles = isLastItem
              ? STYLES.cardListLastItem
              : STYLES.cardListItem;

            return (
              <>
                <Row justify="space-around" style={styles} key={key}>
                  <Col span={1} style={STYLES.cardListItemIcon}>
                    <JobIconsContainer
                      {...job}
                      isLoading={updateLoading}
                      toggleRush={toggleRush}
                    />
                  </Col>
                  <Col span={23} style={STYLES.cardListItemMain}>
                    <JobHeader {...job} onClose={onClose} />
                    <JobInformation {...job} timeZone={timeZone} />
                  </Col>
                </Row>
                {!isLastItem ? (
                  <Divider style={STYLES.cardListDivider} />
                ) : null}
              </>
            );
          })
        )}
      </Card>
    </ConfigProvider>
  );
}
