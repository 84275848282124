import React, { useState } from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components/macro';
import Icons from 'blocks/Icons';
// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';

const DayPickerStyled = styled(DayPicker)`
  width: 100%;
  .DayPicker-wrapper {
    &:focus {
      outline: none;
    }
  }
  .DayPicker-Caption > div {
    text-align: center;
    margin: 0.5rem 0 0 0;
    ${(props) => props.theme.fonts.type.secondary}
    ${(props) => props.theme.fonts.sizes.largest}
  }
  .DayPicker-NavBar {
    width: 100%;
    position: absolute;
    & svg:first-child,
    & svg:last-child {
      position: absolute;
      top: 1.5rem;
    }
    & svg:first-child {
      left: 20%;
    }
    & svg:last-child {
      right: 20%;
    }
  }

  .DayPicker-Day {
    ${(props) => props.theme.fonts.sizes.small};
    ${(props) => props.theme.fonts.weights.light};
    padding: 0.2em;
  }
  .DayPicker-Day--today {
    color: ${(props) => props.theme.colors.charcoal};
    ${(props) => props.theme.fonts.weights.medium};
  }
  .DayPicker-Month {
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0 1rem;
  }

  .DayPicker-Day {
    outline: none;
    border: 0;
    border-radius: 0px;
  }
  .DayPicker-Day--selectedRangeEnd,
  .DayPicker-Day--hoverRangeEnd {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      right: 0px;
      top: -1px;
      background: ${(props) => props.theme.colors.white};
      width: 25px;
      height: 23px;
      border-radius: 0px;
      z-index: -1;
    }
  }
  .DayPicker-Day--hoverRangeStart,
  .DayPicker-Day--hoverRangeEnd {
    z-index: 1 !important;
  }
  .DayPicker-Day--hoverRangeStart:before,
  .DayPicker-Day--hoverRangeEnd:before {
    width: 12px !important;
  }
  .DayPicker-Day--selectedRange:not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected,
  .DayPicker-Day--hoverRange:not(
      .DayPicker-Day--outside
    ).DayPicker-Day--selected {
    border-radius: 0px !important;
    color: ${(props) => props.theme.colors.white} !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: ${(props) => props.theme.colors.tealMed};
  }
  .DayPicker-Day--hoverRange:hover {
    border-radius: 0 !important;
  }
  .DayPicker-Day--hoverRangeStart {
  }
`;

const Top = styled.div`
  display: flex;
`;

const DayContainer = styled.div``;

function Navbar({ onPreviousClick, onNextClick, className }) {
  return (
    <div className={className}>
      <Icons.Arrow modifiers="left" onClick={() => onPreviousClick()} />
      <Icons.Arrow modifiers="right" onClick={() => onNextClick()} />
    </div>
  );
}

function DateCalendar(props) {
  const { value, before, setDate, createValue } = props;
  const [selectedDay, setSelectedDay] = useState(
    value && new Date(value.format('L')),
  );

  return (
    <Top>
      <DayContainer>
        <DayPickerStyled
          localeUtils={MomentLocaleUtils}
          locale={moment.locale()}
          navbarElement={<Navbar />}
          selectedDays={selectedDay}
          showOutsideDays
          onDayClick={(day, { disabled }) => {
            if (disabled) return;

            setSelectedDay(day);
            // Directly modify moment object to preserve timezone
            if (setDate) {
              if (value) {
                setDate(
                  moment()
                    .year(day.getFullYear())
                    .month(day.getMonth())
                    .date(day.getDate()),
                );
              } else {
                setDate(
                  moment()
                    .year(day.getFullYear())
                    .month(day.getMonth())
                    .date(day.getDate()),
                );
              }
            } else if (value) {
              value
                .year(day.getFullYear())
                .month(day.getMonth())
                .date(day.getDate());
            } else if (createValue) {
              // Allow for nullable date selection. In this case callback creates a new moment
              createValue(
                moment()
                  .year(day.getFullYear())
                  .month(day.getMonth())
                  .date(day.getDate()),
              );
            }
          }}
          disabledDays={{ before }}
        />
      </DayContainer>
    </Top>
  );
}

export default DateCalendar;
