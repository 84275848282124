import {
  Checkbox as AntCheckbox,
  CheckboxProps,
  ConfigProvider,
  ThemeConfig,
} from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';

type Props = CheckboxProps & {
  theme?: ThemeConfig;
};

type GroupProps = CheckboxGroupProps & {
  theme?: ThemeConfig;
};

const { Group } = AntCheckbox;

export function CheckboxGroup(props: GroupProps) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider>
      <Group {...rest} />
    </ConfigProvider>
  );
}

export function Checkbox(props: Props) {
  const { theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntCheckbox {...rest} />
    </ConfigProvider>
  );
}
