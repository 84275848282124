import { type CSSProperties, type MouseEvent, useState, useMemo } from 'react';
import { Grid, Typography } from 'antd';
import { ComponentTokenMap } from 'antd/es/theme/interface';
import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { isPlatform } from '@ionic/react';
import styled from 'styled-components';
import { type DefaultOptionType } from 'antd/es/select';
import moment from 'moment';
import dayjs from 'dayjs';

import {
  COLORS,
  FONTS,
  FormItem,
  SPACING,
  Row,
  Col,
  Select,
  Button,
  InputNumber,
  DatePicker,
  TimePicker,
  Checkbox,
  Flex,
  Input,
  CheckboxGroup,
  Drawer,
  HeaderActions,
  TreeSelect,
} from '@optii/ui-library';

import { CHECKLIST_TEMPLATE, Confirmation } from '@optii/shared';
import { ButtonGroup } from '@optii/shared/components/molecules/ButtonGroup';
import { use12Hours } from '@optii/shared/utils/localizeDateConfiguration';
// @ts-ignore
import JobChecklist from '@optii/topcat-client/components/Jobs/JobDetails/JobChecklist';

import type {
  JobFormRegularFieldsDynamicSelectItemProps,
  TJobData,
  TCustomStyles,
  TStyles,
  TFormOptionsDefault,
  TRepeatingJobFields,
  TActionTypeSelector,
  TEditTemplateConfirmation,
  TJobFormAssigneeRules,
  TJobFormAssigneeRulesValidator,
  TIcons,
  TJobFormMultipleChecklist,
  THousekeepingJobData,
  TJobFormMultipleChecklistCustomView,
  TJobLocationOption,
  TJobDataLocation,
  TJobAssetTypeLocationOption,
} from './JobForm.types';
import {
  AUTO_ASSIGNEE_ID,
  JOB_REPEAT,
  JOB_STATUSES,
  LOCATION_TYPES_NAMES,
} from './JobForm.constants';
import {
  getDaysPositionInMonth,
  getRepeatingJobDayPosition,
} from './JobForm.helpers';
import { JobFormMultipleChecklistSkeleton } from './JobFormMultipleSelect.skeleton';

const { useBreakpoint } = Grid;

export const STYLES: TStyles = {
  actionTypeGroup: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: SPACING.SIZE_DEFAULT,
  },
  repeatContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: SPACING.SIZE_MD,
    paddingLeft: SPACING.SIZE_XL,
    paddingBottom: SPACING.NONE,
    borderLeft: `2px ${COLORS.secondary[4]} solid`,
  },
  timeWindowContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: SPACING.SIZE_MD,
    paddingLeft: SPACING.SIZE_XL,
    paddingBottom: SPACING.NONE,
    marginBottom: SPACING.SIZE_MD,
    borderLeft: `2px ${COLORS.secondary[4]} solid`,
  },
};

export function CustomStyles(): TCustomStyles<ComponentTokenMap> {
  const breakpoints = useBreakpoint();

  const Segmented: CSSProperties = (() => {
    if (breakpoints.xs) {
      return {
        fontSize: FONTS.xSmall.size,
        paddingInline: SPACING.SIZE_XS,
      };
    }

    return {
      fontSize: FONTS.medium.size,
      paddingInline: SPACING.SIZE_DEFAULT,
    };
  })();

  const Radio: CSSProperties = (() => {
    const commonCSSProperties: CSSProperties = {
      textAlign: 'center',
      boxSizing: 'border-box',
      flex: 1,
      whiteSpace: 'nowrap',
    };

    if (breakpoints.xs) {
      return {
        ...commonCSSProperties,
        borderRadius: SPACING.SIZE_XXS,
        fontSize: FONTS.xSmall.size,
        paddingInline: SPACING.SIZE_SM,
      };
    }

    return {
      ...commonCSSProperties,
      borderRadius: SPACING.SIZE_XS,
      fontSize: FONTS.medium.size,
      paddingInline: SPACING.SIZE_XL,
    };
  })();

  const DatePickerCSSProperties: CSSProperties = (() => {
    const commonCSSProperties: CSSProperties = {
      minWidth: '100%',
    };

    if (breakpoints.xs) {
      return {
        ...commonCSSProperties,
      };
    }

    return {
      ...commonCSSProperties,
    };
  })();

  const TimePickerCSSProperties: CSSProperties = (() => {
    const commonCSSProperties: CSSProperties = {
      minWidth: '100%',
    };

    if (breakpoints.xs) {
      return {
        ...commonCSSProperties,
      };
    }

    return {
      ...commonCSSProperties,
    };
  })();

  return {
    Segmented,
    Radio,
    DatePicker: DatePickerCSSProperties,
    TimePicker: TimePickerCSSProperties,
  };
}

export const TreeSelectContainer = styled.div`
  .ant-select-selection-overflow {
    overflow: hidden !important;
  }
`;

export const ICONS: TIcons = {
  garbage: () => (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.25 4H14.25V2.125C14.25 1.29766 13.5773 0.625 12.75 0.625H5.25C4.42266 0.625 3.75 1.29766 3.75 2.125V4H0.75C0.335156 4 0 4.33516 0 4.75V5.5C0 5.60313 0.084375 5.6875 0.1875 5.6875H1.60312L2.18203 17.9453C2.21953 18.7445 2.88047 19.375 3.67969 19.375H14.3203C15.1219 19.375 15.7805 18.7469 15.818 17.9453L16.3969 5.6875H17.8125C17.9156 5.6875 18 5.60313 18 5.5V4.75C18 4.33516 17.6648 4 17.25 4ZM5.4375 2.3125H12.5625V4H5.4375V2.3125ZM14.1398 17.6875H3.86016L3.29297 5.6875H14.707L14.1398 17.6875Z"
        fill="#BFBFBF"
      />
    </svg>
  ),
  low: function LowPriority() {
    return (
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <title>icons/ priority/ low</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="TC-837-Edit-Job-Priority-Menu-Mobile"
            transform="translate(-15.000000, -764.000000)"
          >
            <g
              id="forms/-anatomy/-priority-menu"
              transform="translate(0.000000, 615.000000)"
            >
              <g
                id="priority/-low"
                transform="translate(15.000000, 149.000000)"
              >
                <g id="icons/-priority/-low">
                  <g id="icons/-background/-border-radius-2" fill="#A1D2E2">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      rx="2"
                    />
                  </g>
                  <g
                    id="icon"
                    transform="translate(6.000000, 4.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M7.95143824,6.46506222 C7.87591725,6.31597333 7.72270636,6.22222222 7.55560196,6.22222222 L4.88890049,6.22222222 L4.88890049,0.444444444 C4.88890049,0.198982222 4.68991123,0 4.44445024,0 L3.55554976,0 C3.31008877,0 3.11109951,0.198982222 3.11109951,0.444444444 L3.11109951,6.22222222 L0.444398041,6.22222222 C0.277293638,6.22222222 0.12408275,6.31597333 0.0485617643,6.46506222 C-0.0273947826,6.61393333 -0.0135057124,6.79296889 0.0850200178,6.92816889 L3.64062198,11.8170578 C3.72438751,11.9318578 3.85763814,12 4,12 C4.14236186,12 4.27561249,11.9318578 4.35937802,11.8170578 L7.91497998,6.92816889 C8.01350571,6.79296889 8.02739478,6.61393333 7.95143824,6.46506222 Z"
                      id="arrow-down/-low"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
  medium: function MediumPriority() {
    return (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>icons/ priority/ medium</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="TC-837-Edit-Job-Priority-Menu-Mobile"
            transform="translate(-15.000000, -729.000000)"
          >
            <g
              id="forms/-anatomy/-priority-menu"
              transform="translate(0.000000, 615.000000)"
            >
              <g
                id="priority/-medium"
                transform="translate(15.000000, 114.000000)"
              >
                <g id="icons/-priority/-medium">
                  <g id="icons/-background/-border-radius-2" fill="#FBC633">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      rx="2"
                    />
                  </g>
                  <g
                    id="icon"
                    transform="translate(5.000000, 7.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 L1,6 C0.44771525,6 6.76353751e-17,5.55228475 0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 L9,4 Z M9,0 C9.55228475,-1.01453063e-16 10,0.44771525 10,1 C10,1.55228475 9.55228475,2 9,2 L1,2 C0.44771525,2 6.76353751e-17,1.55228475 0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L9,0 Z"
                      id="equal/-medium"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
  high: function HighPriority() {
    return (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>icons/ priority/ high</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="TC-837-Edit-Job-Priority-Menu-Mobile"
            transform="translate(-15.000000, -694.000000)"
          >
            <g
              id="forms/-anatomy/-priority-menu"
              transform="translate(0.000000, 615.000000)"
            >
              <g
                id="priority/-high"
                transform="translate(15.000000, 79.000000)"
              >
                <g id="icons/-priority/-high">
                  <g id="icons/-background/-border-radius-2" fill="#F2722C">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      rx="2"
                    />
                  </g>
                  <g
                    id="icon"
                    transform="translate(9.000000, 4.000000)"
                    fill="#FFFFFF"
                  >
                    <g id="exclamation/-alert">
                      <path
                        d="M1.25,9.86842105 C1.94035594,9.86842105 2.5,10.4575201 2.5,11.1842105 C2.5,11.910901 1.94035594,12.5 1.25,12.5 C0.559644063,12.5 0,11.910901 0,11.1842105 C0,10.4575201 0.559644063,9.86842105 1.25,9.86842105 Z M1.96228401,0 C2.21332253,-1.0162618e-16 2.41682947,0.203506932 2.41682947,0.454545455 C2.41682947,0.464562482 2.41649834,0.474576773 2.41583663,0.484571921 L1.89600266,8.33671336 C1.88018947,8.57557314 1.68183268,8.76123235 1.44245004,8.76123235 L1.05914711,8.76123235 C0.819797871,8.76123235 0.621456758,8.57562329 0.605600219,8.33679987 L0.0842619426,0.484658431 C0.0676310048,0.234171402 0.257208824,0.0176295052 0.507695853,0.000998567354 L1.96228401,0 Z"
                        id="icons/-Exclamation-Point"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
  highest: function HighestPriority() {
    return (
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Highest Priority</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="TC-837-Edit-Job-Priority-Menu-Mobile"
            transform="translate(-15.000000, -659.000000)"
          >
            <g
              id="forms/-anatomy/-priority-menu"
              transform="translate(0.000000, 615.000000)"
            >
              <g
                id="priority/-highest"
                transform="translate(15.000000, 44.000000)"
              >
                <g id="icons/-priority/-highest">
                  <g id="bg" fill="#CE0000">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      rx="2"
                    />
                  </g>
                  <g
                    id="icon"
                    transform="translate(5.000000, 3.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M8.06316069,2.97030816 C7.99116437,2.8876326 7.92739621,2.81350019 7.87361936,2.74818649 C7.80719843,2.66691037 7.69820043,2.6268733 7.59058749,2.6442234 C7.48297455,2.6615735 7.39449269,2.73344964 7.36082926,2.83086205 C7.19910645,3.29397256 6.95313806,3.72734111 6.63381337,4.11178207 C6.65262058,3.01715765 6.42135077,1.08558098 4.72987808,0.0452468415 C4.63941273,-0.0104797145 4.52360594,-0.0150588254 4.42845966,0.0333284704 C4.33331339,0.0817157661 4.27423224,0.17523535 4.27468503,0.27673841 C4.27468503,1.17955553 3.7589563,2.14162347 2.86238174,2.91546671 C1.99489957,3.66450729 -0.0207034881,5.64403578 0.000160751139,7.83686723 C0.0148538774,9.36360924 0.843840058,11.4745919 2.94672028,12.3126465 C2.97816357,11.3544367 3.42395302,10.6740169 3.86004501,10.0181241 C4.27439117,9.3941992 4.66610991,8.80554921 4.70196114,7.98182504 C4.70654373,7.87392437 4.77790305,7.7784758 4.8845072,7.73765568 C4.99111135,7.69683555 5.11331503,7.71816631 5.19711949,7.79222243 C6.38226705,8.83944619 7.01730397,10.3909909 7.0484534,12.2842612 C8.55684973,11.6810052 9.79224778,10.3661882 9.97444255,8.22737146 C10.2098264,5.44974822 8.75990874,3.77501695 8.06316069,2.97030816 Z"
                      id="fire"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
};

export function JobFormRegularFieldsDynamicSelectItem({
  field,
  remove,
  filtered,
  canAutoCreate,
  isAssetSelected,
  onFirstJobItemTypeChange,
  form,
  disabled,
}: JobFormRegularFieldsDynamicSelectItemProps) {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const [createItem, setCreateItem] = useState({
    create: false,
    value: '',
  });

  return (
    <FormItem
      label={
        <>
          <span style={{ color: 'red' }}>*</span>{' '}
          {t('common:Item {{ n }}', { n: field.name + 1 })}
        </>
      }
      style={{ marginBottom: 8 }}
    >
      <Row gutter={8} align="middle">
        {field.name > 0 && (
          <Col>
            <Button
              disabled={disabled}
              onClick={() => remove(field.name)}
              type="default"
              style={{ border: 0 }}
              icon={ICONS.garbage()}
            />
          </Col>
        )}
        <Col style={{ flexGrow: 1 }}>
          <FormItem name={[field.name, 'id']} style={{ marginBottom: 0 }}>
            <Select
              id={`search-${field.name}`}
              placeholder={t('fields:Search...')}
              size="large"
              options={
                (field.name === 0
                  ? filtered
                  : filtered?.filter(
                      (item) => !item.isAsset,
                    )) as DefaultOptionType[]
              }
              disabled={disabled}
              allowClear
              showSearch
              onFocus={(e) => {
                e.target.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }}
              onChange={(value, option) =>
                field.name === 0 &&
                onFirstJobItemTypeChange &&
                onFirstJobItemTypeChange(value, option)
              }
              handleOptionCreation={
                createItem.value && canAutoCreate
                  ? (itemValue?: string) => {
                      const value = itemValue?.trim();

                      if (!value) {
                        return;
                      }

                      form.setFieldValue(['items', field.name, 'id'], value);
                      setCreateItem({ value: '', create: true });
                    }
                  : undefined
              }
              onSearch={(value: string) => {
                setCreateItem({
                  ...createItem,
                  value,
                });
              }}
              filterOption={(input, option) =>
                String(option?.label)
                  ?.toLowerCase()
                  .includes(input?.toLowerCase())
              }
              searchValue={createItem.value}
              placement={isPlatform('capacitor') ? 'topLeft' : undefined}
            />
          </FormItem>
        </Col>
        {!isAssetSelected && (
          <Col>
            <FormItem
              rules={[{ required: true, message: '' }]}
              name={[field.name, 'amount']}
              style={{ marginBottom: 0 }}
            >
              <InputNumber
                min={1}
                max={255}
                onFocus={(e) => {
                  e.target.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  });
                }}
                controls
                size="large"
                disabled={disabled}
              />
            </FormItem>
          </Col>
        )}
      </Row>
    </FormItem>
  );
}

export function jobFormAssigneeRules({
  watchedValues,
  t,
}: TJobFormAssigneeRules) {
  return [
    {
      required:
        watchedValues &&
        !!(
          watchedValues.status === JOB_STATUSES.inProgress.value ||
          watchedValues.status === JOB_STATUSES.cancelled.value ||
          watchedValues.status === JOB_STATUSES.completed.value
        ),
    },
    ({ getFieldValue }: TJobFormAssigneeRulesValidator) => ({
      validator(
        ...args: {
          id: string | number;
        }[]
      ) {
        // Validation for when the user select a Status different of Not Started and has no Team Member assigned to

        const value = args[1];
        const selectedAssignee = value?.id || value;
        const selectedStatus = getFieldValue('status');

        const currentFeedbackMessage = {
          [JOB_STATUSES.inProgress.value]:
            'jobs:In Progress Jobs must be assigned to a team member',

          [JOB_STATUSES.cancelled.value]:
            'jobs:Cancelled Jobs must be assigned to a team member',
          [JOB_STATUSES.completed.value]:
            'jobs:Done Jobs must be assigned to a team member',
        };

        const hasStatusInProgressOrDone = !!(
          selectedStatus === JOB_STATUSES.inProgress.value ||
          selectedStatus === JOB_STATUSES.cancelled.value ||
          selectedStatus === JOB_STATUSES.completed.value
        );
        const emptyAssignee = !selectedAssignee;
        const stringfiedAssignee =
          typeof selectedAssignee === 'string' ||
          typeof selectedAssignee === 'number'
            ? String(selectedAssignee)
            : null;
        const autoAssignee =
          String(stringfiedAssignee) === String(AUTO_ASSIGNEE_ID);
        const hasAssigneeEmptyOrAuto = emptyAssignee || autoAssignee;

        if (hasStatusInProgressOrDone && hasAssigneeEmptyOrAuto) {
          return Promise.reject(
            new Error(t(currentFeedbackMessage[selectedStatus])),
          );
        }

        return Promise.resolve();
      },
    }),
  ];
}

export function EditTemplateConfirmation({
  hide,
  job,
  updateRepeatingJobLoading,
  onEditRepeatingJob,
  values,
  t,
}: TEditTemplateConfirmation<{ displayName?: string }>) {
  const { displayName } = job;

  const TEXTS = {
    title: t('jobs:Update {{displayName}}', {
      displayName,
    }),
    action: t('common:Yes, Update'),
    description: t('jobs:Are you sure you want to update this repeating job?'),
  };

  return (
    <Confirmation
      title={TEXTS.title}
      hide={() => hide()}
      action={TEXTS.action}
      confirmText={TEXTS.action}
      loading={updateRepeatingJobLoading}
      onConfirm={async () => {
        await onEditRepeatingJob(values);
      }}
      onHide={() => hide()}
      modifiers={['fullWidthMobile']}
      isSubModal
    >
      <p>{TEXTS.description}</p>
    </Confirmation>
  );
}

export function ActionTypeSelector({
  formOptions,
  form,
  watchedValues,
  t,
  jobTypes,
}: TActionTypeSelector) {
  const regularActionTypeRender = (
    <FormItem<TJobData>
      name="actionType"
      label={t('common:Select an Action')}
      style={{
        marginBottom: SPACING.SIZE_MD,
      }}
    >
      <ButtonGroup
        name="actionTypeGroup"
        options={formOptions.actionType as TFormOptionsDefault[]}
        firstLineMaxElements={4}
        handleButtonClick={(event: MouseEvent<HTMLButtonElement>) => {
          const currentValue = form.getFieldValue('actionType');
          const newValue = event.currentTarget.value;
          const nextValue = currentValue === newValue ? null : newValue;

          form.setFieldValue('actionType', nextValue);
        }}
        fieldName="actionType"
      />
    </FormItem>
  );

  const housekeepingActionTypeRender = (
    <FormItem<TJobData>
      name="actionType"
      label={t('common:Select an Action')}
      style={{
        marginBottom: SPACING.SIZE_MD,
      }}
      rules={[
        {
          required: true,
          message: t('jobs:Required'),
        },
      ]}
      validateTrigger="onBlur"
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.actionType !== currentValues.actionType
      }
    >
      <ButtonGroup
        name="actionTypeGroup"
        options={formOptions.housekeepingActionType as TFormOptionsDefault[]}
        firstLineMaxElements={3}
        handleButtonClick={(event: MouseEvent<HTMLButtonElement>) => {
          const nextValue = event.currentTarget.value;

          form.setFieldValue('actionType', nextValue);

          if (nextValue) {
            form.setFields([
              {
                name: 'actionType',
                touched: true,
              },
            ]);
          }
        }}
        fieldName="actionType"
      />
    </FormItem>
  );

  const actionTypeRender =
    watchedValues?.jobType === jobTypes.housekeeping.value
      ? housekeepingActionTypeRender
      : regularActionTypeRender;

  return actionTypeRender;
}

export function RepeatingJobFields({
  user,
  customStyles,
  t,
  form,
  formOptions,
  watchedValues,
}: TRepeatingJobFields<{
  day?: string | number;
  dayOfWeek?: string | number;
}>) {
  const use12HoursValue = use12Hours();
  const startTimeRules = [
    {
      validator(rule: any, value: any) {
        const endDate = form.getFieldValue('endDate');
        if (!value && endDate) {
          return Promise.reject(t('jobs:Start Time is required'));
        }
        return Promise.resolve();
      },
    },
  ];

  const IS_SCHEDULE_START_TIME_AT_ENABLED =
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test' &&
    process.env.NODE_ENV !== 'development';

  const noRepeatRender = (
    <>
      <FormItem<TJobData> name="dueDate" label={t('fields:Due by Date')}>
        <DatePicker
          allowClear
          locale={user.preferredLang}
          format="MMM D, YYYY"
          minDate={dayjs().tz(form.getFieldValue('timezone'))}
          style={customStyles.DatePicker}
        />
      </FormItem>
      <Row justify="space-between">
        {IS_SCHEDULE_START_TIME_AT_ENABLED && (
          <Col xs={9} sm={11}>
            <FormItem<TJobData>
              name="scheduleStartTimeAt"
              label={t('fields:Start Time')}
              style={{
                marginBottom: SPACING.NONE,
              }}
            >
              <TimePicker
                allowClear
                format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
                style={customStyles.TimePicker}
                onPickerValueChange={(time) =>
                  form.setFieldValue('scheduleStartTimeAt', time)
                }
              />
            </FormItem>
          </Col>
        )}
        <Col
          xs={IS_SCHEDULE_START_TIME_AT_ENABLED ? 14 : 24}
          sm={IS_SCHEDULE_START_TIME_AT_ENABLED ? 12 : 24}
        >
          <FormItem<TJobData>
            name="dueTime"
            label={t('fields:Due by Time')}
            style={{
              marginBottom: SPACING.NONE,
            }}
          >
            <TimePicker
              allowClear
              format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
              onPickerValueChange={(day) => form.setFieldValue('dueTime', day)}
            />
          </FormItem>
          <FormItem<TJobData> name="startDate" noStyle />
        </Col>
      </Row>
    </>
  );

  const dailyRepeatRender = (
    <>
      <FormItem<TJobData>
        name="startDate"
        label={t('fields:Start Date')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
      >
        <DatePicker
          allowClear
          minDate={dayjs().tz(form.getFieldValue('timezone'))}
          locale={user.preferredLang}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
          maxDate={
            form.getFieldValue('endDate')
              ? form.getFieldValue('endDate').subtract(1, 'day')
              : ''
          }
        />
      </FormItem>
      <FormItem<TJobData>
        name="multiple"
        style={{ fontWeight: 500 }}
        valuePropName="checked"
      >
        <Checkbox
          onChange={(e) => {
            form.setFieldsValue({ multiple: e.target.checked });
          }}
        >
          {t('jobs:Multiple Times per Day')}
        </Checkbox>
      </FormItem>
      <Row justify="space-between">
        <Col xs={24} sm={9} style={{ paddingBottom: '1.5rem' }}>
          <FormItem<TJobData>
            name="scheduleStartTimeAt"
            label={t('fields:Start Time')}
            style={{
              marginBottom: SPACING.NONE,
            }}
            dependencies={['endDate']}
            rules={startTimeRules}
          >
            <TimePicker
              allowClear
              format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
              locale={user.preferredLang}
              style={customStyles.TimePicker}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={14}>
          <FormItem<TJobData>
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.multiple !== currentValues.multiple ||
              prevValues.firstDueTime !== currentValues.firstDueTime ||
              prevValues.repeatHour !== currentValues.repeatHour
            }
          >
            {({ getFieldValue }) => {
              const firstDueTimeHour = Number(
                getFieldValue('firstDueTime')?.format('H'),
              );
              const repeatHour = Number(getFieldValue('repeatHour'));

              const repeatHourListLength = Math.floor(
                (24 - firstDueTimeHour) / 2,
              );
              const timesPerDayListLength = Math.floor(
                (24 - firstDueTimeHour) / repeatHour,
              );

              const repeatHourListOptions = formOptions.repeatHour?.length
                ? [...formOptions.repeatHour].slice(0, repeatHourListLength)
                : [];
              const timesPerDayListOptions = formOptions.timesPerDay?.length
                ? [...formOptions.timesPerDay].slice(0, timesPerDayListLength)
                : [];

              return getFieldValue('multiple') === true ? (
                <>
                  <FormItem<TJobData>
                    name="firstDueTime"
                    label={t('fields:First Due Time')}
                    rules={[
                      {
                        required: true,
                        message: t('jobs:This is a required field'),
                      },
                    ]}
                  >
                    <TimePicker
                      allowClear
                      locale={user.preferredLang}
                      format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
                      style={customStyles.TimePicker}
                    />
                  </FormItem>
                  <Flex
                    style={{
                      gap: SPACING.SIZE_XL,
                      alignItems: 'center',
                    }}
                  >
                    <FormItem label={t('jobs:Repeat Every')} />
                    <FormItem<TJobData>
                      name="repeatHour"
                      label={t('common:Hours')}
                      colon={false}
                      labelCol={{
                        style: { order: 2, marginLeft: SPACING.SIZE_SM },
                      }}
                      rules={[
                        {
                          required: true,
                          message: t('jobs:Required'),
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        disabled={!getFieldValue('firstDueTime')}
                        size="large"
                        options={repeatHourListOptions}
                        placeholder={t('fields:Select...')}
                      />
                    </FormItem>
                    <FormItem<TJobData>
                      name="timesPerDay"
                      label={t('fields:Times per Day')}
                      colon={false}
                      labelCol={{
                        style: { order: 2, marginLeft: SPACING.SIZE_SM },
                      }}
                      rules={[
                        {
                          required: true,
                          message: t('jobs:Required'),
                        },
                      ]}
                    >
                      <Select
                        disabled={!getFieldValue('firstDueTime')}
                        size="large"
                        allowClear
                        options={timesPerDayListOptions}
                        placeholder={t('fields:Select...')}
                      />
                    </FormItem>
                  </Flex>
                </>
              ) : (
                <FormItem<TJobData>
                  name="dailyDueTime"
                  label={t('fields:Due by Time')}
                >
                  <TimePicker
                    allowClear
                    locale={user.preferredLang}
                    format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
                    style={customStyles.TimePicker}
                  />
                </FormItem>
              );
            }}
          </FormItem>
        </Col>
      </Row>
      <FormItem<TJobData>
        name="endDate"
        label={t('fields:End Date')}
        style={{
          marginBottom: SPACING.NONE,
        }}
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
          minDate={form.getFieldValue('startDate')?.add(1, 'day')}
        />
      </FormItem>
    </>
  );

  const weeklyRepeatRender = (
    <>
      <FormItem<TJobData>
        name="startDate"
        label={t('fields:Start Date')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          minDate={dayjs().tz(form.getFieldValue('timezone'))}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
      <Flex
        justify="flex-start"
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FormItem<TJobData>
          name="repeatWeeks"
          label={t('jobs:Repeat Every')}
          rules={[
            {
              required: true,
              message: t('jobs:Required'),
            },
          ]}
          validateTrigger="onBlur"
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Input
            size="middle"
            style={{
              minWidth: 150,
              maxWidth: 150,
              marginRight: SPACING.SIZE_DEFAULT,
            }}
            minLength={1}
            maxLength={3}
            suffix={t(
              `jobs:Week${Number(watchedValues?.repeatWeeks) > 1 ? 's' : ''}`,
            )}
          />
        </FormItem>
      </Flex>
      <FormItem<TJobData>
        name="daysOfWeek"
        label={t('jobs:Every')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
      >
        <CheckboxGroup options={formOptions.daysOfWeek} />
      </FormItem>
      <Row justify="space-between">
        <Col xs={9} sm={11}>
          <FormItem<TJobData>
            name="scheduleStartTimeAt"
            label={t('fields:Start Time')}
            style={{
              marginBottom: SPACING.NONE,
            }}
            dependencies={['endDate']}
            rules={startTimeRules}
          >
            <TimePicker
              allowClear
              format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
              onPickerValueChange={(time) =>
                form.setFieldValue('scheduleStartTimeAt', time)
              }
            />
          </FormItem>
        </Col>
        <Col xs={14} sm={12}>
          <FormItem<TJobData>
            name="weeklyDueTime"
            label={t('fields:Due by Time')}
          >
            <TimePicker
              allowClear
              locale={user.preferredLang}
              format={use12Hours() ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem<TJobData>
        name="endDate"
        label={t('fields:End Date')}
        style={{
          marginBottom: SPACING.NONE,
        }}
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
    </>
  );

  const monthlyRepeatRender = (
    <>
      <FormItem<TJobData>
        name="startDate"
        label={t('fields:Start Date')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          minDate={dayjs().tz(form.getFieldValue('timezone'))}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
      <Flex gap={SPACING.SIZE_DEFAULT}>
        <FormItem<TJobData>
          label={t('jobs:Repeat Every')}
          rules={[
            {
              required: true,
              message: t('jobs:Required'),
            },
          ]}
          validateTrigger="onBlur"
        />
        <FormItem<TJobData>
          name="repeatMonths"
          label={t(
            `jobs:Month${Number(watchedValues?.repeatMonths) > 1 ? 's' : ''}`,
          )}
          colon={false}
          labelCol={{ style: { order: 2, marginLeft: SPACING.SIZE_SM } }}
          rules={[
            {
              required: true,
              message: t('jobs:Required'),
            },
          ]}
          validateTrigger="onBlur"
          style={{
            flex: 1,
          }}
        >
          <Select
            allowClear
            options={formOptions.repeatMonth}
            placeholder={t('fields:Select...')}
          />
        </FormItem>
        <FormItem<TJobData>
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.startDate !== currentValues.startDate
          }
          style={{
            flex: 1,
            minWidth: 150,
          }}
        >
          {({ getFieldValue }) => {
            const days = [
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ];
            const date = getFieldValue('startDate');
            const day = date?.format('D');
            const dayOfWeek = days[date?.day()];

            const positionText = getRepeatingJobDayPosition({ t, dayOfWeek });
            const position = (getDaysPositionInMonth(
              moment(date?.format('YYYY-MM-DD')),
            ) || 'first') as keyof typeof positionText;

            const dayOfMonthOptions = [
              {
                label: t('projects:Monthly on day {{day}} of the month', {
                  day,
                }),
                value: '1',
              },
              {
                label: positionText[position],
                value: '2',
              },
            ];

            return (
              <FormItem<TJobData>
                name="dayOfMonth"
                label={t('jobs:Day Of Month')}
                colon={false}
                labelCol={{ style: { order: 2, marginLeft: SPACING.SIZE_SM } }}
                rules={[
                  {
                    required: true,
                    message: t('jobs:Required'),
                  },
                ]}
                validateTrigger="onBlur"
                style={{
                  flex: 1,
                }}
              >
                <Select
                  allowClear
                  options={dayOfMonthOptions}
                  placeholder={t('fields:Select...')}
                />
              </FormItem>
            );
          }}
        </FormItem>
      </Flex>

      <Row justify="space-between">
        <Col xs={9} sm={11}>
          <FormItem<TJobData>
            name="scheduleStartTimeAt"
            label={t('fields:Start Time')}
            style={{
              marginBottom: SPACING.NONE,
            }}
            dependencies={['endDate']}
            rules={startTimeRules}
          >
            <TimePicker
              allowClear
              format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
              onPickerValueChange={(time) =>
                form.setFieldValue('scheduleStartTimeAt', time)
              }
            />
          </FormItem>
        </Col>
        <Col xs={14} sm={12}>
          <FormItem<TJobData>
            name="monthlyDueTime"
            label={t('fields:Due by Time')}
          >
            <TimePicker
              allowClear
              locale={user.preferredLang}
              format={use12Hours() ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem<TJobData>
        name="endDate"
        label={t('fields:End Date')}
        style={{
          marginBottom: SPACING.NONE,
        }}
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
    </>
  );

  const yearlyRepeatRender = (
    <>
      <FormItem<TJobData>
        name="startDate"
        label={t('fields:Start Date')}
        rules={[
          {
            required: true,
            message: t('jobs:Required'),
          },
        ]}
        validateTrigger="onBlur"
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          minDate={dayjs().tz(form.getFieldValue('timezone'))}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
      <Flex justify="flex-start" gap={SPACING.SIZE_DEFAULT}>
        <FormItem<TJobData>
          label={t('jobs:Repeat Every')}
          rules={[{ required: true }]}
        />
        <FormItem<TJobData>
          name="repeatYears"
          label={t(
            `jobs:Year${Number(watchedValues?.repeatYears) > 1 ? 's' : ''}`,
          )}
          colon={false}
          labelCol={{ style: { order: 2, marginLeft: SPACING.SIZE_SM } }}
          rules={[
            {
              required: true,
              message: t('jobs:Required'),
            },
          ]}
          validateTrigger="onBlur"
          style={{
            flex: 1,
            maxWidth: 150,
          }}
        >
          <Select
            allowClear
            placeholder={t('fields:Select...')}
            options={formOptions.repeatYear}
          />
        </FormItem>
      </Flex>
      <Row justify="space-between">
        <Col xs={9} sm={11}>
          <FormItem<TJobData>
            name="scheduleStartTimeAt"
            label={t('fields:Start Time')}
            style={{
              marginBottom: SPACING.NONE,
            }}
            dependencies={['endDate']}
            rules={startTimeRules}
          >
            <TimePicker
              allowClear
              format={use12HoursValue ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
              onPickerValueChange={(time) =>
                form.setFieldValue('scheduleStartTimeAt', time)
              }
            />
          </FormItem>
        </Col>
        <Col xs={14} sm={12}>
          <FormItem<TJobData>
            name="yearlyDueTime"
            label={t('fields:Due by Time')}
          >
            <TimePicker
              allowClear
              locale={user.preferredLang}
              format={use12Hours() ? 'h:mm a' : 'HH:mm'}
              style={customStyles.TimePicker}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem<TJobData>
        name="endDate"
        label={t('fields:End Date')}
        style={{
          marginBottom: SPACING.NONE,
        }}
      >
        <DatePicker
          allowClear
          locale={user.preferredLang}
          format="MMM D, YYYY"
          style={customStyles.DatePicker}
        />
      </FormItem>
    </>
  );

  const fields = {
    [JOB_REPEAT.no.value]: noRepeatRender,
    [JOB_REPEAT.daily.value]: dailyRepeatRender,
    [JOB_REPEAT.weekly.value]: weeklyRepeatRender,
    [JOB_REPEAT.monthly.value]: monthlyRepeatRender,
    [JOB_REPEAT.yearly.value]: yearlyRepeatRender,
  };

  return fields[watchedValues?.repeat as keyof typeof fields];
}

function JobFormMultipleChecklistCustomView(
  props: TJobFormMultipleChecklistCustomView,
) {
  const { options, selectedOptions, handleChecklist } = props;
  return Array.isArray(selectedOptions) ? (
    <div>
      {selectedOptions?.map((selectedOption: string) => {
        const currentOption = options?.find(
          (option) => option.value === selectedOption,
        );

        return (
          <Typography.Paragraph
            style={{
              marginBottom: SPACING.SIZE_MD,
            }}
          >
            <Typography.Link
              style={{
                fontSize: `${FONTS.medium}px`,
                color: COLORS.cyan[7],
                textDecoration: 'underline',
              }}
              onClick={() => {
                if (currentOption?.value) {
                  handleChecklist(currentOption?.value);
                }
              }}
            >
              {currentOption?.label}
            </Typography.Link>
          </Typography.Paragraph>
        );
      })}
    </div>
  ) : null;
}

export function JobFormMultipleChecklist(props: TJobFormMultipleChecklist) {
  const { t, options, selectedOptions } = props;

  const [checklist, setChecklist] = useState<string>();

  const [getChecklist, { data: checklistData, loading: checklistLoading }] =
    useLazyQuery(CHECKLIST_TEMPLATE, {
      notifyOnNetworkStatusChange: true,
      context: { _instance: 'node' },
    });

  function handleChecklist(id: string) {
    setChecklist(id);
    getChecklist({
      variables: {
        checklistTemplateId: id,
      },
    });
  }

  return (
    <>
      <FormItem<THousekeepingJobData>
        name="checklist"
        label={t('fields:Checklists')}
        style={{
          marginBottom: SPACING.SIZE_SM,
        }}
      >
        <Select
          mode="multiple"
          allowClear
          placeholder={t('fields:Select...')}
          size="large"
          options={options}
          optionFilterProp="label"
          showSearch
        />
      </FormItem>

      <JobFormMultipleChecklistCustomView
        options={options}
        selectedOptions={selectedOptions}
        handleChecklist={handleChecklist}
      />

      {checklistData && checklist ? (
        <Drawer
          open={!!checklist}
          onClose={() => {
            setChecklist('');
          }}
          destroyOnClose
          width={480}
          title={
            <Row align="middle" justify="space-between" wrap={false}>
              <Typography.Title
                level={3}
                style={{
                  marginTop: SPACING.NONE,
                  fontFamily: 'Montserrat',
                }}
              >
                {checklistData?.checklistTemplate?.name}
              </Typography.Title>
              <HeaderActions
                onClose={() => {
                  setChecklist('');
                }}
              />
            </Row>
          }
          styles={{
            body: {
              padding: SPACING.SIZE_XL,
            },
          }}
        >
          {checklistLoading ? (
            <JobFormMultipleChecklistSkeleton />
          ) : (
            <JobChecklist
              isReadOnly
              id={checklist}
              data={checklistData.checklistTemplate}
            />
          )}
        </Drawer>
      ) : null}
    </>
  );
}

type JobFormTreeSelectWrapperProps = {
  onChange?: (locations: string[]) => void;
  onBlur?: () => void;
  value?: string[];
  locations: any;
  loadingMinimalLocations: boolean;
  typedActiveLocationsAssetType: TJobAssetTypeLocationOption[];
  disabled: boolean;
  placeholder: string;
};

export function JobFormTreeSelectWrapper({
  onBlur,
  onChange,
  value,
  loadingMinimalLocations,
  locations,
  typedActiveLocationsAssetType,
  disabled,
  placeholder,
}: JobFormTreeSelectWrapperProps) {
  const selectedLocations = useMemo(() => {
    if (Array.isArray(locations) && Array.isArray(value)) {
      return locations.filter((location) => value.includes(location.id));
    }

    return [];
  }, [value, locations]);

  return (
    <TreeSelectContainer>
      <TreeSelect
        treeData={locations}
        suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
        size="large"
        notFoundContent
        multiple
        onBlur={onBlur}
        loading={loadingMinimalLocations}
        placeholder={placeholder}
        placement={isPlatform('capacitor') ? 'topLeft' : undefined}
        setValue={(items) => {
          if (typeof onChange === 'function')
            onChange((items as TJobLocationOption[]).map((item) => item.id));
        }}
        onChange={(location: string) => {
          const displayedOption: TJobAssetTypeLocationOption =
            typedActiveLocationsAssetType?.find(
              (item) => item.id === location,
            ) || ({} as TJobAssetTypeLocationOption);
          if (typeof onChange === 'function') onChange([displayedOption.id]);
        }}
        treeDefaultExpandedKeys={[
          locations?.find(
            (item: TJobDataLocation) =>
              item.locationTypeName === LOCATION_TYPES_NAMES.property,
          ).id,
        ]}
        value={selectedLocations}
        disabled={disabled}
      />
    </TreeSelectContainer>
  );
}

export { type TIcons };
