import React, { useMemo, useState } from 'react';

const Context = React.createContext({});
const Provider = function (props) {
  const [onlineUsers, setOnlineUsers] = useState({
    employeeID: null,
    loggedIn: false,
    working: false,
    online: false,
  });

  const contextValue = useMemo(
    () => ({
      onlineUsers,
      setOnlineUsers,
    }),
    [onlineUsers, setOnlineUsers],
  );

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};

export default { Provider, Context };
