import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { ReactComponent as DropdownIndicator } from 'images/svg/two-arrows-icon.svg';
import CheckList from 'checklists';
import { ChecklistItemContext } from 'checklists/contexts';
import { CHECKLIST_TASK_TYPE_CONFIG } from 'checklists/constants';

export default function SelectType() {
  const { t } = useTranslation(['common', 'checklist']);
  const { formValues, onFormChange, isReadOnly } =
    useContext(ChecklistItemContext);

  const options = useMemo(
    () =>
      Object.entries(CHECKLIST_TASK_TYPE_CONFIG).map(
        ([taskType, typeConfig]) => ({
          id: taskType,
          displayName: t(`checklist:${typeConfig.label}`),
        }),
      ),
    [t],
  );

  const currentValue = useMemo(
    () => options.find((opt) => opt.id === formValues.taskType),
    [formValues.taskType, options],
  );

  const onClick = () => {GoogleAnalyticsClient.event(GA_EVENTS.clickChecklistTaskSelector)}

  const onSelectChange = (event) =>
  {
    const taskType = event.target.value.id;
    const value = event?.target?.value?.id;
    
    switch (taskType) {
      case "checkBox":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectCheckbox)
        break;
      case "groupHeader":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectGroupHeader)
        break;
      case "readOnly":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectReadOnly)
        break;
      case "onOff":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectOnOff)
        break;
      case "temperature":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectTemperature)
        break;
      case "dropdown":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectDropdown)
        break;
      case "numerical":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectNumeric)
        break;
      case "passFail":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectPassFail)
        break;
      case "yesNo":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectYesNo)
        break;
      case "text":
        GoogleAnalyticsClient.event(GA_EVENTS.checklistTaskSelectText)
        break;
      default:
        break;
    }
    value && onFormChange({
      name: 'taskType',
      value
    });
  }

  return (
    <CheckList.Select
      type="select"
      modifiers={[
        'noMargin',
        'noMarginMobile',
        'overflow',
        isReadOnly && 'viewOnly',
      ]}
      onClick={onClick}
      isGreyFilterWithArrows
      isSearchable={false}
      isClearable={false}
      components={{ DropdownIndicator }}
      options={options}
      name="taskType"
      tabIndex={-1}
      closeMenuOnSelect
      value={currentValue}
      placeholder="selected"
      onChange={onSelectChange}
      data-testid="checklist-select"
    />
  );
}
