import React, { useContext, useMemo, useCallback, memo } from "react";
// import Select from "components/shared/form/Select";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Select } from "@optii-solutions/ui23-antd-components";

import { ChecklistItemContext } from "@optii/jobs/checklists/contexts";
import { CHECKLIST_TASK_FIELDS } from "@optii/jobs/checklists/constants";
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';

import TaskNameField from "../TaskNameField";
import OptionsList from "../OptionsList";
import SelectType from "../SelectType";
import { FlexContainer, FlexItem, RequiredFlag } from "../../Checklists.elements";

const SelectBox = styled(Select)`
  width: 100%;
  margin-top: 1rem;
  > div {
    height: 44px;
  }
`;

function stringToSelectOption (strValue) {
  return ({
    value: strValue,
    label: strValue
  });
}

function filterEmptyValues (options) {
  return options?.filter(opt => !!opt);
} 

function DropdownType(props) {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    onFormChange,
    isFulfillment,
    formValues,
    isReadOnly,
    errors
  } = useContext(ChecklistItemContext);
  const { t } = useTranslation(["common", "checklist"]);

  const isRequired =
    String(formValues[CHECKLIST_TASK_FIELDS.required]) === "true";

  const dropdownOptions = useMemo(() => {

    const currentOptions = formValues[CHECKLIST_TASK_FIELDS.dropdownOptions];
    const defaultOptions = [t("checklist:Option {{position}}", { position: 1 })];

    if (currentOptions?.length > 0) {
      return currentOptions;
    }

    return defaultOptions;

  }, [formValues[CHECKLIST_TASK_FIELDS.dropdownOptions]]);

  const formatedDropdownOptions = useMemo(
    () => dropdownOptions?.map(stringToSelectOption),
    [dropdownOptions]
  );

  const handleFulfillmentChange = useCallback(
    value => {
      if (isReadOnly) return;
      GoogleAnalyticsClient.event(GA_EVENTS.dropdownTaskSelect);

      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataDropdownValue]: value
      });
    },
    [formValues, isReadOnly, onFulfillmentChange]
  );

  const clearFulfillmentChange = useCallback(
    event => {
      if (isReadOnly) return;

      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataDropdownValue]: null
      });
    },
    [formValues, isReadOnly, onFulfillmentChange]
  );

  const handleChange = allOptions => {
    const options = filterEmptyValues(allOptions);
    onFormChange({
      name: CHECKLIST_TASK_FIELDS.dropdownOptions,
      value: options
    });
  }

  const errorMessage = errors[CHECKLIST_TASK_FIELDS.dropdownOptions] ? t(errors[CHECKLIST_TASK_FIELDS.dropdownOptions]) : false;

  return (
    <FlexContainer data-testid="checklist-task-dropdown-container" col>
      <FlexContainer>
        <FlexItem row>
          {isRequired && <RequiredFlag />}
          <TaskNameField />
        </FlexItem>
        {!isFulfillment && <SelectType />}
      </FlexContainer>
      <FlexContainer>
        {isFulfillment ? (
            <SelectBox
              onClear={clearFulfillmentChange}
              mode="multiple"
              options={formatedDropdownOptions}
              allowClear
              disabled={isReadOnly}
              placeholder={t("checklist:Select")}
              value={fulfillmentValues[CHECKLIST_TASK_FIELDS.dataDropdownValue] ?? undefined}
              onChange={handleFulfillmentChange}
            />
        ) : (
          <OptionsList
            initialOptions={dropdownOptions}
            onChange={handleChange}
            isDisabled={isReadOnly}
            error={errorMessage}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
}

export default memo(DropdownType);
