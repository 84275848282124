import React, { useForm, useWatch } from 'antd/es/form/Form';
import {
  useCallback,
  useState,
  useContext,
  useEffect,
  CSSProperties,
} from 'react';
import { Session } from '@optii/shared';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, gql } from '@apollo/client';
import { initGQLData as initData } from 'utils/initGQLData';
import {
  GET_LOCATION_PROPERTY_TAGS,
  GET_LOCATIONS_LIST,
  GET_LOCATION_BY_ID,
  GET_ROOM_TYPES_LIST,
  GET_LOCATION_TYPES_LIST,
  UPDATE_LOCATION,
  CREATE_LOCATION,
} from 'queries';
import { serviceErrorsNames } from 'utils/serviceErrors';
import {
  Button,
  COLORS,
  Checkbox,
  Drawer,
  Form,
  FormItem,
  HeaderActions,
  Input,
  InputNumber,
  RADIUS,
  Row,
  SPACING,
  Select,
  Space,
  Switcher,
  TextArea,
  Typography,
} from '@optii-solutions/ui-library';
import { LocationFormProps, TLocation, TQueryLocation } from './types';
import { LocationFormSkeleton } from './LocationFormSkeleton';

type Tag = {
  name: string;
};

type SelectField = {
  label: string;
  value: string;
};

type LocationType = {
  id: string;
  displayName: string;
  code: string;
  squareUnit?: number;
  note?: string;
};

type RoomType = {
  id: string;
  displayName: string;
  code: string;
  note?: string;
};

interface ILocationFormProps {
  onClose: () => void;
  open: boolean;
  locationId: string;
  locationName: string;
}

type LocationVariables = {
  id?: string;
  input: LocationFormProps & {
    locationTypeId: string;
    tags?: string[];
  };
};

const SHOW_CASE_NAME_STYLE: { container: CSSProperties; label: CSSProperties } =
  {
    container: {
      borderRadius: RADIUS.RADIUS_L,
      backgroundColor: COLORS.neutral[2],
      width: '100%',
      padding: SPACING.SIZE_SM,
      marginBottom: SPACING.SIZE_XL,
    },
    label: {
      fontWeight: 500,
      paddingRight: SPACING.SIZE_SM,
    },
  };

export default function LocationForm(props: ILocationFormProps) {
  const { open, onClose, locationId, locationName } = props;

  const [form] = useForm();

  const selectedParent = useWatch('parentId', form);
  const selectedType = useWatch('locationTypeId', form);
  const isVirtual = useWatch('isVirtual', form);
  const name = useWatch('name', form);

  const { t } = useTranslation(['common']);
  const { globalSnack } = useContext(Session);

  const [tagOptions, setTagsOptions] = useState<SelectField[]>([]);
  const [searchTag, setSearchTag] = useState('');

  const handleSearch = (searchValue: string) => {
    setSearchTag(searchValue);
  };

  // fetching tags
  useQuery(GET_LOCATION_PROPERTY_TAGS, {
    onCompleted: (data) => {
      const tags = data?.tags.map((tag: Tag) => ({
        label: tag.name,
        value: tag.name.toLowerCase(),
      }));
      setTagsOptions(tags);
    },
  });

  // fetching location to edit
  const { loading } = useQuery<TQueryLocation>(
    GET_LOCATION_BY_ID,

    {
      onCompleted: ({ GetLocationById }) => {
        const locationValues = {
          ...GetLocationById,
          parentId: GetLocationById?.parentLocation.id,
          virtualChildrenLocationIds:
            GetLocationById.virtualChildrenLocationIds.map((childrenLocation) =>
              childrenLocation.toString(),
            ),
          locationTypeId: {
            value: GetLocationById?.locationType?.id,
            label: GetLocationById?.locationType?.displayName,
            code: GetLocationById?.locationType.code,
          },
          roomTypeId: GetLocationById?.roomType?.id,
          tags: GetLocationById?.tags.map((tag: Tag) => tag.name),
        };
        form.setFieldsValue(locationValues);
      },
      variables: {
        id: locationId,
      },
      skip: !locationId,
    },
  );

  // fetching and formatting location and room types
  const { data: locationTypes } = useQuery(GET_LOCATION_TYPES_LIST);
  const { data: roomTypes } = useQuery(GET_ROOM_TYPES_LIST);
  const locationTypesList = locationTypes?.locationTypes.map(
    (locationType: LocationType) => ({
      label: locationType.displayName,
      value: locationType.id,
      code: locationType.code,
    }),
  );

  // fetching locations data for the dropdowns
  const { data: locations, loading: listLocationsLoading } = useQuery(
    GET_LOCATIONS_LIST,
    {
      variables: {
        orderBy: 'name_ASC, number_ASC',
      },
    },
  );

  // initialize parent locations based on locations above
  const parentLocations: SelectField[] = (initData(locations) as TLocation[])
    .filter((parentLocation) => !parentLocation.isVirtual)
    .map((parentLocation) => ({
      label: parentLocation.longDisplayName,
      value: parentLocation.id,
    }));

  // initializing virtual locations if parent, type and isVirtual
  const { data: virtualLocations, refetch: getVirtualLocationsList } = useQuery(
    GET_LOCATIONS_LIST,
    {
      skip: !(selectedParent && selectedType?.value && isVirtual),
      variables: {
        filters: {
          parentLocations: [selectedParent],
          locationTypes: [selectedType?.value],
          isVirtual: false,
        },
      },
    },
  );

  // add mutation
  const [createLocation, { loading: createLocationLoading }] =
    useMutation(CREATE_LOCATION);
  // update mutation
  const [updateLocation, { loading: updateLocationLoading }] =
    useMutation(UPDATE_LOCATION);

  // if conditions are correct load virtual locations
  useEffect(() => {
    if (selectedParent && selectedType && isVirtual) {
      getVirtualLocationsList();
    }
  }, [selectedParent, selectedType, isVirtual, getVirtualLocationsList]);

  const roomTypesList = roomTypes?.roomTypes.map((roomType: RoomType) => ({
    label: roomType.displayName,
    value: roomType.id,
  }));

  // formatting virtual locations list
  const virtualLocationsList: SelectField[] = (
    initData(virtualLocations) as TLocation[]
  )
    .filter((virtualLocation) => virtualLocation.id !== locationId)
    .map((location: TLocation) => ({
      label: location.longDisplayName,
      value: location.id,
    }));

  // Creating new tags
  const handleCreateOption = useCallback(
    (value?: string) => {
      if (value) {
        setTagsOptions(
          (prev) =>
            prev &&
            prev.concat([
              {
                label: value,
                value,
              },
            ]),
        );
        const currentTags = form.getFieldValue('tags');
        form.setFieldValue(
          'tags',
          currentTags.concat({
            value,
          }),
        );
      }
      setSearchTag('');
    },
    [form],
  );

  const getErrorMessage = useCallback(
    (error: Error, _name: string) => {
      let message = '';
      if (error.message.includes(serviceErrorsNames?.ERR_ALREADY_EXISTS)) {
        message = `${t(
          'floorplan:The location with the same name already exists',
        )}.`;
      } else {
        message = `${t('common:An error occurred')} ${
          locationId ? 'editing' : 'adding'
        } ${t('common:the')} ${_name}. ${t('common:Please try again, later')}.`;
      }
      return message;
    },
    [locationId, t],
  );

  const formatLocationData = useCallback(
    (values: LocationFormProps) =>
      ({
        id: locationId,
        input: {
          ...values,
          locationTypeId: values.locationTypeId?.value,
          tags: values.tags?.map((tag) => ({
            name: typeof tag === 'object' ? tag.value : tag,
          })),
        },
      } as LocationVariables),
    [locationId],
  );

  const onCreateLocation = useCallback(
    async (variables: LocationVariables) => {
      await createLocation({
        variables,
        onCompleted: () => {
          const createdLocationName = `${
            form.getFieldValue('locationTypeId').label
          } ${name}`;
          globalSnack({
            message: `${createdLocationName} ${t('common:successfully')} ${t(
              'common:added',
            )}`,
            timeout: 4000,
          });
          onClose();
        },
        refetchQueries: ['ListLocations'],
        onError(error) {
          console.error(error);
          globalSnack({
            message: getErrorMessage(error, name),
            error: true,
          });
        },
      });
    },
    [globalSnack, onClose, t, createLocation, form, getErrorMessage, name],
  );

  const onUpdateLocation = useCallback(
    async (variables: LocationVariables) => {
      await updateLocation({
        variables,
        onCompleted: ({ updateLocation: updatedLocation }) => {
          globalSnack({
            message: `${updatedLocation.longDisplayName} ${t(
              'common:successfully',
            )} ${t('common:updated')}`,
            timeout: 4000,
          });
          onClose();
        },
        onError(error) {
          console.error(error);
        },
        update: (cache, { data: { updateLocation: updatedLocation } }) => {
          cache.writeFragment({
            id: `Location:${updatedLocation.id}`,
            fragment: gql`
              fragment UpdateLocation on Location {
                name
                status
                displayName
                longDisplayName
                shortDisplayName
                parentLocation {
                  id
                  displayName
                  name
                  number
                }
                locationType {
                  id
                  displayName
                }
                roomType {
                  id
                  displayName
                }
                associatedVirtualLocations {
                  id
                  name
                }
                sequence
                squareUnit
                tags {
                  name
                }
              }
            `,
            data: updatedLocation,
          });
        },
      });
    },
    [globalSnack, onClose, t, updateLocation],
  );

  const handleFinish = useCallback(
    async (values: LocationFormProps) => {
      // TODO: add condition to create instead of edit
      const variables = formatLocationData(values);
      try {
        if (locationId) onUpdateLocation(variables);
        else onCreateLocation(variables);
      } catch (error) {
        globalSnack({
          message: getErrorMessage(
            error as Error,
            variables.input.name as string,
          ),
          error: true,
        });
      }
    },
    [
      globalSnack,
      formatLocationData,
      onUpdateLocation,
      onCreateLocation,
      locationId,
      getErrorMessage,
    ],
  );

  const resetVirtualChildren = () => {
    form.setFieldValue('virtualChildrenLocationIds', []);
  };

  return (
    <Drawer
      width={675}
      onClose={onClose}
      open={open}
      destroyOnClose={!locationId}
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          <Typography.Title
            level={3}
            style={{
              marginTop: SPACING.NONE,
              fontFamily: 'Montserrat',
            }}
          >
            {locationId
              ? t('floorplan:Edit {{name}}', {
                  name,
                })
              : t('floorplan:Add Location')}
          </Typography.Title>
          <HeaderActions onClose={onClose} />
        </Row>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={12}
          style={{
            paddingRight: SPACING.SIZE_XL,
          }}
        >
          <Button type="text" onClick={onClose}>
            {t('common:Cancel')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={updateLocationLoading || createLocationLoading}
            loading={updateLocationLoading || createLocationLoading}
            onClick={() => form.submit()}
          >
            {locationId ? t('common:Update') : t('common:Add')}
          </Button>
        </Row>
      }
    >
      {loading || listLocationsLoading ? (
        <LocationFormSkeleton />
      ) : (
        <Form
          scrollToFirstError
          autoComplete="off"
          title="Form"
          form={form}
          preserve={false}
          initialValues={{
            isActive: true,
            parentId: '',
            locationTypeId: '',
            roomTypeId: '',
            name: '',
            description: '',
            squareUnit: null,
            notes: '',
            sequence: null,
            tags: [],
            isVirtual: false,
            associatedVirtualLocations: [],
            childrenIds: [],
            virtualLocationChildrenIds: [],
          }}
          onFinish={handleFinish}
        >
          <FormItem<LocationFormProps> name="isActive" valuePropName="checked">
            <Switcher unCheckedChildren="Inactive" checkedChildren="Active" />
          </FormItem>
          <FormItem<LocationFormProps>
            label={t('floorplan:Parent Location')}
            name="parentId"
            rules={[
              {
                required: true,
                message: 'Parent Location is a required field',
              },
            ]}
          >
            <Select
              options={parentLocations}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                String(option?.label)
                  ?.toLowerCase()
                  .includes(input?.toLowerCase())
              }
            />
          </FormItem>
          <FormItem<LocationFormProps>
            label={t('floorplan:Location Type')}
            name="locationTypeId"
            getValueFromEvent={(value, option) => option}
            rules={[
              {
                required: true,
                message: 'Location Type is a required field',
              },
            ]}
          >
            <Select
              options={locationTypesList}
              allowClear
              onChange={resetVirtualChildren}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                String(option?.label)
                  ?.toLowerCase()
                  .includes(input?.toLowerCase())
              }
            />
          </FormItem>

          <FormItem<LocationFormProps>
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.locationTypeId !== currentValues.locationTypeId ||
              prevValues.roomTypeId !== currentValues.roomTypeId
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('locationTypeId')?.label === 'Room' ? (
                <FormItem<LocationFormProps>
                  name="roomTypeId"
                  label={t('floorplan:Room Type')}
                  rules={[
                    {
                      required: true,
                      message: 'Room Type is a required field.',
                    },
                  ]}
                >
                  <Select
                    options={roomTypesList}
                    allowClear
                    onChange={resetVirtualChildren}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      String(option?.label)
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  />
                </FormItem>
              ) : null
            }
          </FormItem>
          <FormItem<LocationFormProps>
            label={t('floorplan:Name')}
            name="name"
            rules={[
              {
                required: true,
                message: 'Name is a required field.',
              },
            ]}
          >
            <Input />
          </FormItem>

          {selectedType && name ? (
            <Space
              direction="vertical"
              size={SPACING.SIZE_DEFAULT}
              style={SHOW_CASE_NAME_STYLE.container}
            >
              {Number.isNaN(Number(name)) ? (
                <Row>
                  <Typography.Text style={SHOW_CASE_NAME_STYLE.label}>
                    {t('floorplan:Display Name')}:
                  </Typography.Text>
                  <Typography.Text>{name}</Typography.Text>
                </Row>
              ) : (
                <>
                  <Row>
                    <Typography.Text style={SHOW_CASE_NAME_STYLE.label}>
                      {t('floorplan:Short Display Name')}:
                    </Typography.Text>
                    <Typography.Text>
                      {selectedType.code} {name}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text style={SHOW_CASE_NAME_STYLE.label}>
                      {t('floorplan:Long Display Name')}:
                    </Typography.Text>

                    <Typography.Text>
                      {selectedType.label} {name}
                    </Typography.Text>
                  </Row>
                </>
              )}
            </Space>
          ) : null}

          <FormItem<LocationFormProps>
            label={t('floorplan:Description')}
            name="description"
          >
            <TextArea />
          </FormItem>
          <FormItem<LocationFormProps>
            label={t('floorplan:Square Unit')}
            name="squareUnit"
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>

          <FormItem<LocationFormProps>
            label={t('floorplan:Notes')}
            name="notes"
          >
            <TextArea />
          </FormItem>

          <FormItem<LocationFormProps>
            label={t('floorplan:Sequence')}
            name="sequence"
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItem>

          <FormItem<LocationFormProps> label={t('floorplan:Tags')} name="tags">
            <Select
              options={tagOptions}
              handleOptionCreation={handleCreateOption}
              allowClear
              showSearch
              onSearch={handleSearch}
              searchValue={searchTag}
              mode="multiple"
            />
          </FormItem>
          <FormItem<LocationFormProps>
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.associatedVirtualLocations !==
              currentValues.associatedVirtualLocations
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('associatedVirtualLocations').length === 0 &&
              getFieldValue('childrenIds').length === 0 ? (
                <FormItem<LocationFormProps>
                  name="isVirtual"
                  valuePropName="checked"
                >
                  <Checkbox>{t('floorplan:Set as virtual location')}</Checkbox>
                </FormItem>
              ) : null
            }
          </FormItem>

          <FormItem<LocationFormProps>
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.isVirtual !== currentValues.isVirtual
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('isVirtual') === true && (
                <FormItem<LocationFormProps>
                  label={t('floorplan:Locations')}
                  name="virtualChildrenLocationIds"
                  rules={[
                    {
                      required: true,
                      message: 'Locations is a required field.',
                    },
                  ]}
                  dependencies={['locationTypeId', 'parentId']}
                >
                  <Select options={virtualLocationsList} mode="multiple" />
                </FormItem>
              )
            }
          </FormItem>
        </Form>
      )}
    </Drawer>
  );
}
