import { gql } from '@apollo/client';

export const GET_JOB_DEFAULTS = gql`
  query GetJobDefault($action: JobAction, $item: String!) {
    defaults: GetJobDefault(action: $action, item: $item) {
      department {
        id
        displayName
        departmentCode
      }
      role {
        id
        name
        description
      }
    }
  }
`;
