import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Loading from 'components/shared/Loading';
import App from './App';
// import * as serviceWorker from "./serviceWorker";
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Suspense fallback={<Loading loading />}>
    <App />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
