import { gql } from '@apollo/client';

export default gql`
  query Reservations($first: Int, $after: Int) {
    page: reservations(first: $first, after: $after) {
      edges {
        cursor
        node {
          ... on Reservation {
            id
            externalReservationId
            externalGroupReservationId
            reservationStatus
            reservationType
            displayName
            arrivalDate
            departureDate
            guestNumber {
              adults
              children
            }
            specialRequestCodes
            estimatedArrivalTime
            estimatedDepartureTime
            isDeleted
            notes
            preferences {
              description
              type
              value
            }
            attributes {
              key
            }
            version
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
