import {
  AuthorizationServiceConfiguration,
  StringMap,
  BasicQueryStringUtils,
} from '@openid/appauth';
import { Browser, EndSessionHandler, EndSessionRequest } from 'ionic-appauth';

export class OptiiEndSessionHandler implements EndSessionHandler {
  constructor(
    private browser: Browser,
    private utils = new BasicQueryStringUtils(),
  ) {}

  public async performEndSessionRequest(
    configuration: AuthorizationServiceConfiguration,
    request: EndSessionRequest,
  ): Promise<string | undefined> {
    const url = this.buildRequestUrl(configuration, request);
    const waitingForBrowser = await this.browser.showWindow(
      url,
      request.postLogoutRedirectURI,
    );
    return waitingForBrowser;
  }

  private buildRequestUrl(
    configuration: AuthorizationServiceConfiguration,
    request: EndSessionRequest,
  ) {
    const requestMap: StringMap = {
      id_token_hint: request.idTokenHint,
      post_logout_redirect_uri: request.postLogoutRedirectURI,
      state: request.state,
    };

    const query = this.utils.stringify(requestMap);
    const baseUrl = configuration.endSessionEndpoint;
    const url = `${baseUrl}?${query}`;
    return url;
  }
}
