import { SPACING, Skeleton } from '@optii-solutions/ui-library';

export function JobFormMultipleChecklistSkeleton() {
  return (
    <div
      style={{
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />

      <Skeleton.Input
        block
        active
        style={{
          marginBottom: SPACING.SIZE_XL,
          height: '100px',
        }}
      />
    </div>
  );
}
