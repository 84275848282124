import i18n from 'i18n';

import type {
  TJobPriorityLevels,
  TRepeatingJob,
  TRepeatingJobCycle,
  TJobStatuses,
  TDaysOfWeek,
  TOrdinalSuffix,
  TMonths,
  TDays,
  TJobAdvancedStatuses,
} from './JobForm.types';

export const ORDINAL_SUFFIX: TOrdinalSuffix[] = ['st', 'nd', 'rd', 'th'];

export const MONTHS: TMonths[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DAYS: TDays[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_OF_WEEK: TDaysOfWeek = {
  monday: {
    label: 'Mon',
    displayName: 'Monday',
    value: 'monday',
  },
  tuesday: {
    label: 'Tue',
    displayName: 'Tuesday',
    value: 'tuesday',
  },
  wednesday: {
    label: 'Wed',
    displayName: 'Wednesday',
    value: 'wednesday',
  },
  thursday: {
    label: 'Thu',
    displayName: 'Thursday',
    value: 'thursday',
  },
  friday: {
    label: 'Fri',
    displayName: 'Friday',
    value: 'friday',
  },
  saturday: {
    label: 'Sat',
    displayName: 'Saturday',
    value: 'saturday',
  },
  sunday: {
    label: 'Sun',
    displayName: 'Sunday',
    value: 'sunday',
  },
};

export const JOB_PRIORITY_LEVELS: TJobPriorityLevels = {
  highest: {
    label: i18n.t('jobs:Highest'),
    value: 'highest',
  },
  high: {
    label: i18n.t('jobs:High'),
    value: 'high',
  },
  medium: {
    label: i18n.t('jobs:Medium'),
    value: 'medium',
  },
  low: {
    label: i18n.t('jobs:Low'),
    value: 'low',
  },
};

export const JOB_STATUSES: TJobStatuses = {
  notStarted: {
    label: i18n.t('jobs:Not Started'),
    value: 'not_started',
  },
  inProgress: {
    label: i18n.t('jobs:In Progress'),
    value: 'in_progress',
  },
  completed: {
    label: i18n.t('jobs:Done'),
    value: 'completed',
  },
  cancelled: {
    label: i18n.t('jobs:Cancelled'),
    value: 'cancelled',
  },
  onHold: {
    label: i18n.t('jobs:On Hold'),
    value: 'on_hold',
  },
};

export const JOB_REPEAT_CYCLE: TRepeatingJobCycle = {
  daily: {
    displayName: 'every day',
  },
  weekly: {
    displayName: 'every {{week}}',
  },
  monthly: {
    displayName: 'every {{month}}',
  },
  yearly: {
    displayName: 'every {{year}}',
  },
};

export const JOB_REPEAT_CYCLE_TYPE = 'rrule';

export const JOB_REPEAT_STATUS = 'active';

export const JOB_REPEAT: TRepeatingJob = {
  no: {
    label: i18n.t('jobs:Does Not Repeat'),
    value: 'no',
  },
  daily: {
    label: i18n.t('jobs:Daily'),
    value: 'daily',
  },
  weekly: {
    label: i18n.t('jobs:Weekly'),
    value: 'weekly',
  },
  monthly: {
    label: i18n.t('jobs:Monthly'),
    value: 'monthly',
  },
  yearly: {
    label: i18n.t('jobs:Yearly'),
    value: 'yearly',
  },
};

export const JOB_TEMPLATE_STATUS = 'new';

export const EMPTY_ASSIGNEE = {
  id: 0,
  displayName: '',
  userId: 0,
  userName: '',
  userFirstName: '',
  userLastName: '',
};

export const AUTO_ASSIGNEE_ID = -88;
export const AUTO_ASSIGNEE_USERNAME = 'autoassign';

export const AUTO_ASSIGNEE = {
  id: AUTO_ASSIGNEE_ID,
  displayName: i18n.t('jobs:Auto Assign'),
  userId: AUTO_ASSIGNEE_ID,
  userName: AUTO_ASSIGNEE_USERNAME,
  firstName: i18n.t('jobs:Auto'),
  lastName: i18n.t('jobs:Assign'),
};

export const REFETCH_SUMMARY_VARIABLES = {
  first: 50,
  Sorts: 'displayName',
};

export const LOCATION_TYPES_NAMES = {
  room: 'Room',
  property: 'Property',
};

export const STATUS_ASSIGNEE_FEEDBACK_MESSAGE = {
  [JOB_STATUSES.inProgress.value]:
    'jobs:In Progress Jobs must be assigned to a team member',
  [JOB_STATUSES.onHold.value]:
    'jobs:On Hold Jobs must be assigned to a team member',
  [JOB_STATUSES.cancelled.value]:
    'jobs:Cancelled Jobs must be assigned to a team member',
  [JOB_STATUSES.completed.value]:
    'jobs:Done Jobs must be assigned to a team member',
};

export const STATUSES_OPTIONS = [
  JOB_STATUSES.notStarted,
  JOB_STATUSES.inProgress,
  JOB_STATUSES.completed,
  JOB_STATUSES.cancelled,
  JOB_STATUSES.onHold,
];

export const PRIORITY_LEVEL_OPTIONS = [
  JOB_PRIORITY_LEVELS.highest,
  JOB_PRIORITY_LEVELS.high,
  JOB_PRIORITY_LEVELS.medium,
  JOB_PRIORITY_LEVELS.low,
];

export const REPEAT_OPTIONS = [
  JOB_REPEAT.no,
  JOB_REPEAT.daily,
  JOB_REPEAT.weekly,
  JOB_REPEAT.monthly,
  JOB_REPEAT.yearly,
];

export const DAYS_OF_WEEK_OPTIONS = [
  DAYS_OF_WEEK.monday,
  DAYS_OF_WEEK.tuesday,
  DAYS_OF_WEEK.wednesday,
  DAYS_OF_WEEK.thursday,
  DAYS_OF_WEEK.friday,
  DAYS_OF_WEEK.saturday,
  DAYS_OF_WEEK.sunday,
];

export const TIMES_PER_DAY_OPTIONS = [...Array(24).keys()].map(
  (item: number) => ({
    label: String(item + 1),
    value: String(item + 1),
  }),
);

export const REPEAT_HOUR_OPTIONS = [...Array(12).keys()].map(
  (item: number) => ({
    label: String(item + 1),
    value: String(item + 1),
  }),
);

export const REPEAT_MONTH_OPTIONS = [...Array(11).keys()].map(
  (item: number) => ({
    label: String(item + 1),
    value: String(item + 1),
  }),
);

export const REPEAT_YEAR_OPTIONS = [...Array(24).keys()].map(
  (item: number) => ({
    label: String(item + 1),
    value: String(item + 1),
  }),
);

export const JOB_METADATA = {
  project: 'project-cycle-svc',
  repeating: 'repeating-jobs-svc',
  adhoc: 'adhoc-job-mgr',
};

export const JOB_ADVANCED_STATUSES: TJobAdvancedStatuses = {
  new: {
    label: 'Not Started',
    value: 'new',
    sameAs: 'notStarted',
  },
  notStarted: {
    label: 'Not Started',
    value: 'not_started',
    sameAs: 'pending',
  },
  pending: {
    label: 'Not Started',
    value: 'pending',
    sameAs: 'notStarted',
  },
  archived: {
    label: 'Done',
    value: 'archived',
    sameAs: 'completed',
  },
  queued: {
    label: 'Queued',
    value: 'queued',
    sameAs: 'queued',
  },
};

export const JOB_FORM_DROPDOWN_CUSTOM_HEIGHT = 200;
