export const initGQLData = (data: any) =>
  (data &&
    data.page &&
    data.page.edges &&
    data.page.edges.map((el: any) => el.node)) ||
  [];

// Extract pagingInfo/totalCount, optional extended, mostly for passing page sizes
export const getPageInfo = (data: any, extend = {}) => {
let result: any = {};
// putting total count in, just to simplify matters
if (data && data.page && data.page.totalCount) {
  result.totalCount = data.page.totalCount;
}
if (data && data.page && data.page.pageInfo) {
  result = { ...result, ...data.page.pageInfo };
}
return { ...result, ...extend };
};

// filter data by IDs
export const dataById = (data: any, queryIDs: any, type: any) => {
const ids = queryIDs && queryIDs[type];
return ids ? data && data.filter((el: any) => ids.includes(el.id)) : [];
};
