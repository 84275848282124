import { gql } from "@apollo/client";

export default gql`
  mutation StoreFile($file: Upload!, $name: String) {
    file: storeFile(file: $file, name: $name) {
      UUID
      URL
      name
    }
  }
`;
