import styled from 'styled-components/macro';
import { ReactComponent as InviteIcon } from 'images/svg/invite.svg';

const Invite = styled(InviteIcon)`
  :hover {
    g {
      fill: ${(props) => props.theme.colors.tealXDark};
    }
  }
  cursor: pointer;
  ${(props) => props.theme.animations.hoverUp()}
`;

export default Invite;
