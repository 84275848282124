import { useTranslation } from 'react-i18next';
import {
  Flex,
  Grid,
  Row,
  Typography,
  ConfigProvider,
  Col,
  SPACING,
  Spin,
} from '@optii-solutions/ui-library';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSProperties, useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Channels } from './components/channels';
import useTwilio from './hooks/useTwilio';
import { Messages } from './components/message';
import { ChatContext } from './context/chat.context';
import { useListEmployeesQuery } from './api/data';
import useChat from './hooks/useChat';

const TITLE_STYLE: CSSProperties = {
  marginTop: SPACING.NONE,
  fontFamily: 'Montserrat',
  fontWeight: 400,
  marginBottom: SPACING.SIZE_XL,
};

export function Chat() {
  const {
    client,
    generalChannelId,
    unreadConversations,
    updateUnreadConversations,
    setUnreadConversations,
  } = useTwilio();

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const match = useRouteMatch<{ id: string }>('/chat/:id');
  const { t } = useTranslation(['chat', 'common']);
  const { data: employeesData } = useListEmployeesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const employees = useMemo(
    () =>
      employeesData?.page?.edges?.map(({ node }) => {
        const label = `${node.userFirstName} ${node.userLastName}`;
        return {
          label,
          value: String(node.userId),
          id: node.userId,
        };
      }) || [],
    [employeesData],
  );

  const { channels, getChannelById, mapPrivateConversation, setChannels } =
    useChat(client, employees);

  const contextValue = useMemo(
    () => ({
      client,
      employees,
      unreadConversations,
      channels,
      getChannelById,
      mapPrivateConversation,
      setChannels,
      updateUnreadConversations,
      setUnreadConversations,
    }),
    [
      client,
      employees,
      unreadConversations,
      channels,
      getChannelById,
      mapPrivateConversation,
      setChannels,
      updateUnreadConversations,
      setUnreadConversations,
    ],
  );

  return (
    <ChatContext.Provider value={contextValue}>
      {!client ? (
        <Flex
          align="center"
          justify="center"
          style={{
            height: '50vh',
          }}
        >
          <Spin size="large" indicator={<LoadingOutlined spin />} />
        </Flex>
      ) : (
        <ConfigProvider>
          {!breakpoints.xs ? (
            <>
              {!match?.params.id ? (
                <Redirect to={`/chat/${generalChannelId}`} />
              ) : null}

              <Row>
                <Col push={6}>
                  <Typography.Title level={3} style={TITLE_STYLE}>
                    {t('common:Chat')}
                  </Typography.Title>
                </Col>
              </Row>
            </>
          ) : null}
          <Switch>
            <Route exact component={Channels} path="/chat" />
            <Route component={Messages} path="/chat/:id" />
          </Switch>
        </ConfigProvider>
      )}
    </ChatContext.Provider>
  );
}
export default Chat;
