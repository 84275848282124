import { gql } from "@apollo/client";

export default gql`
  mutation registerDevice(
    $address: String!
    $bindingType: String!
    $tags: [String]!
  ) {
    registerDevice(address: $address, bindingType: $bindingType, tags: $tags)
  }
`;
