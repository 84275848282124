import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { createModalRoot, createSubModalRoot } from './utils';
import { Backdrop, Pane } from './styles';
import { useScrollLock } from '@optii/shared';

const modalRoot = createModalRoot() as Element;
const subModalRoot = createSubModalRoot() as Element;

interface ModalProps {
  name: string;
  focus?: boolean;
  children: ReactNode;
  isSubModal?: boolean;
  isSolidBack?: boolean;
  onBackdropClick: () => void;

  [key: string]: unknown;
}
const Modal: React.FC<ModalProps> = ({
  name,
  focus,
  children,
  isSubModal,
  isSolidBack,
  onBackdropClick,
  ...props
}) => {
  const modalElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (focus) modalElement.current?.focus();
  }, [focus]);

  useScrollLock(modalElement.current);

  return createPortal(
    <div>
      <Pane
        className="modal"
        data-testid={name || 'modal'}
        {...props}
        ref={modalElement}
        tabIndex={0}
        superModal={isSubModal}
      >
        {children}
      </Pane>
      <Backdrop
        solid={isSolidBack}
        superModal={isSubModal}
        onClick={onBackdropClick}
      />
    </div>,
    isSubModal ? subModalRoot : modalRoot
  );
};

export default Modal;
