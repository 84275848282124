import { gql } from '@apollo/client';

export default gql`
  mutation Suggestion($text: String, $jobCheck: Boolean) {
    suggestion(text: $text, jobCheck: $jobCheck) {
      item
      isJob
      text
      jobItemId
      location
      role
      action
      amount
      department
      jobType
    }
  }
`;
