import React, { useCallback, useContext } from 'react';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { getEnvironment } from '@optii/shared/utils/config';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { CHECKLIST_TASK_FIELDS } from 'checklists/constants';
import { ChecklistItemContext } from 'checklists/contexts';
import elements, { FlexContainer, FlexItem, RequiredFlag } from 'checklists';
import TaskNameField from '../TaskNameField';
import SelectType from '../SelectType';

const FLAG =  ['DEV', 'LOCAL'].includes(getEnvironment());

function CheckboxType() {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    isFulfillment,
    formValues,
    onFormChange,
    isReadOnly,
  } = useContext(ChecklistItemContext);

  const { CheckBox } = elements;

  const readOnly = FLAG ? isReadOnly : !isFulfillment || isReadOnly;

  const isRequired =
    String(formValues[CHECKLIST_TASK_FIELDS.required]) === 'true';

  const checked = isFulfillment
    ? !!fulfillmentValues[CHECKLIST_TASK_FIELDS.dataCheckboxChecked]
    : !!formValues?.defaultValue?.value;

  const onChange = useCallback(
    (event) => {
      const { checked: eventChecked } = event.target;
      event.stopPropagation();
      eventChecked
        ? GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskSelected)
        : GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskDeselected);

      if (isFulfillment) {
        onFulfillmentChange({
          ...formValues,
          [CHECKLIST_TASK_FIELDS.dataCheckboxChecked]: eventChecked,
        });
      } else {
        onFormChange({
          name: 'defaultValue',
          value: {
            value: eventChecked,
          },
        });
      }
    },
    [formValues, onFulfillmentChange, onFormChange, isFulfillment],
  );

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container">
      <FlexContainer col>
        <FlexContainer>
          <FlexItem flex="0" row>
            {isRequired && <RequiredFlag />}
            <CheckBox
              checked={checked}
              tabIndex={-1}
              onChange={onChange}
              readOnly={readOnly}
              data-testid="checklist-task-checkbox-check"
            />
          </FlexItem>
          <FlexItem>
            <TaskNameField />
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default CheckboxType;
