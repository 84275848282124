import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import {
  GA_EVENTS,
  ViewJobDetailsContext,
  JOB_STATUS,
} from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';

import TabNav from 'components/shared/nav/TabNav';
import JobCheckList from 'components/Jobs/JobDetails/JobChecklist';

import JobNotes from './JobNotes';

const ModeSelection = styled(TabNav)`
  margin: 0;
  padding: 0 1.4rem;
  background-color: ${(props) => props.theme.colors.charcoal01opct50};

  li {
    margin: 0 1rem;
  }
`;

const modes = {
  notes: 'notes',
  audits: 'audits',
  tasks: 'tasks',
};

function JobTabs({ queuedJobRequest, isHousekeepingJob }) {
  const { job, isRepeatingJobTemplate, checklists } = useContext(
    ViewJobDetailsContext,
  );

  const hasChecklist = checklists?.length > 0;

  const [mode, setMode] = useState(hasChecklist ? modes.tasks : modes.notes);

  useEffect(() => {
    setMode(hasChecklist ? modes.tasks : modes.notes);
  }, [hasChecklist]);

  const { t } = useTranslation(['jobs', 'common', 'fields']);

  function ModeSections() {
    const sections = [
      {
        handleClick: (_) => setMode(modes.notes),
        label: t('fields:Notes'),
        key: 'modes_notes',
        isActive: mode === modes.notes,
      },
    ];
    if (!queuedJobRequest && job?.status !== JOB_STATUS.queued) {
      sections.push({
        handleClick: (_) => {
          setMode(modes.audits);
          GoogleAnalyticsClient.event(GA_EVENTS.viewActivityJob);
        },
        label: t('jobs:Activity'),
        key: 'modes_audits',
        isActive: mode === modes.audits,
      });
    }
    if (hasChecklist) {
      sections.unshift({
        handleClick: (_) => {
          setMode(modes.tasks);
        },
        label: t('jobs:Tasks'),
        key: 'modes_tasks',
        isActive: mode === modes.tasks,
      });
    }
    return sections;
  }

  function isFulfillmentEnabled({ status }) {
    return [JOB_STATUS.in_progress, JOB_STATUS.on_hold].includes(status);
  }

  return (
    <>
      <ModeSelection sections={ModeSections()} />

      {mode === modes.tasks && (
        <JobCheckList
          isReadOnly={isRepeatingJobTemplate || !isFulfillmentEnabled(job)}
        />
      )}

      {[modes.audits, modes.notes].includes(mode) && (
        <JobNotes
          data-testid="job-notes"
          queuedJobRequest={queuedJobRequest}
          mode={mode}
          isHousekeepingJob={isHousekeepingJob}
        />
      )}
    </>
  );
}

export default JobTabs;
