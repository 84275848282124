import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import Field from '@optii/topcat-client/components/shared/form/Field';

const MODIFIER_CONFIG = {
  paddingLeft: ({ theme }) => `
    padding-left: 1.5rem;
    @media (max-width: ${theme.breakpoints.sm}) {
      padding-left: 0;
    }
  `,
  snap: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.lg}) {
      margin-top: 1.5rem;
      padding-right: 0;
      width: 31rem;
    }
  `,
  paddingRight: () => `
    padding-right: 1.5rem;
  `,
};
const Filter = styled(Field)`
  cursor: pointer;
  min-width: 24rem;
  margin-right: 0rem;
  margin-top: 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 10rem;
    width: 100%;
    padding-right: 1rem;
    margin-top: 1.5rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default Filter;
