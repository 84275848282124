import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useMedia } from 'react-media';
import Icons from 'blocks/Icons';
import breakpoints from 'theme/breakpoints';
import { applyStyleModifiers } from 'styled-components-modifiers';
import FileUpload from './FileUpload';
import GalleryElement from './GalleryElement';

const MODIFIER_CONFIG = {
  rightAligned: () => `
    max-width: 88.8rem;
    justify-content: right;
  `,
};
const ActionsContainer = styled.div`
  display: flex;
  margin-top: ${(props) => (props.small ? '1rem' : '3rem')};
  margin-left: ${(props) => (props.small ? '0' : '1.5rem')};
  justify-content: ${(props) => (props.small ? 'left' : 'center')};
  width: 100%;
  .BrainhubCarouselItem {
    height: ${(props) => (props.small ? 'auto' : '12rem')};
    ${(props) =>
      props.separator
        ? `
      width: auto !important;
      max-width: initial !important;
    `
        : ''};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: 1.6rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;
const CarouselContainer = styled.div`
  ${(props) =>
    props.small
      ? `
    width: calc(100% - 6rem);
    height: 3rem;
    margin: 0 .4rem;
  `
      : `
  width: 25rem;
  height: 12rem;
  `}

  ul {
    ::-webkit-scrollbar {
      display: none;
    }
    ${(props) => props.small && 'padding-left: 0.1rem !important'};

    -ms-overflow-style: none;
    scrollbar-width: none;
    li:first-child {
      margin-left: ${(props) => props.small && '0 !important'};
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    ${(props) => !props.small && 'width: 12.5rem;'};
  }
`;

const Arrow = styled(Icons.Arrow)`
  margin-top: ${(props) => (props.small === 'true' ? '1rem' : '5rem')};
  min-width: 1.5rem;
  ${(props) => props.right && `margin-left:0.5rem;`}
  ${(props) => props.left && `margin-right:0.5rem;`}
`;

const Separator = styled.div`
  width: 1px;
  background-color: ${(props) => props.theme.colors.charcoal03};
  margin: 0 1.6rem 0 0.6rem;
  height: 75%;
`;

function Attachments(props) {
  const {
    values,
    handleUpload,
    onDelete,
    small = false,
    allowArrows = true,
    analytics,
    hideUploads,
    attachmentKey = 'attachments',
    modifiers,
    allowFileDeletion,
    showFileAsIcon,
    setSelectedAttachment,
    selectedAttachment,
    separator = -1,
    isRepeatingJobTemplate,
    customArrowPrevious,
    customArrowNext,
  } = props;
  const [index, setIndex] = useState(0);
  const itemWidth = small ? 30 : 125;

  const ArrowLeft = customArrowPrevious || Arrow;
  const ArrowRight = customArrowNext || Arrow;

  const attachments = isRepeatingJobTemplate
    ? values.jobTemplate[attachmentKey]
    : values && values[attachmentKey];

  const isMobile = useMedia({
    query: `(max-width: ${breakpoints.xs})`,
  });

  const hasNext = React.useMemo(() => {
    const attachmentsLength = (attachments && attachments?.length) || 0;

    let innerHasNext = false;
    if (small && index + 1 < attachmentsLength - 9) {
      innerHasNext = true;
    } else if (!small && isMobile && index + 1 < attachmentsLength) {
      innerHasNext = true;
    } else if (!small && !isMobile && index + 1 < attachmentsLength - 1) {
      innerHasNext = true;
    }
    return innerHasNext;
  }, [small, index, attachments, isMobile]);

  const hasPrevious = index > 0;

  if (!attachments && hideUploads) {
    return null;
  }

  return (
    <ActionsContainer
      data-testid="attachmentsContainer"
      small={small}
      separator={separator}
      modifiers={modifiers}
    >
      {allowArrows && (
        <ArrowLeft
          modifiers={[
            'left',
            small ? 'small' : 'large',
            !hasPrevious && 'light',
            !hasPrevious && 'defaultCursor',
          ]}
          small={small ? 'true' : 'false'}
          onClick={(_) => hasPrevious && setIndex((prev) => prev - 1)}
          left="true"
          hasPrevious={hasPrevious}
        />
      )}
      <CarouselContainer small={small}>
        {attachments && attachments.length > 0 ? (
          <Carousel
            slidesPerPage={isMobile ? 1 : 2}
            itemWidth={itemWidth}
            value={index}
            draggable={false}
            offset={small ? 10 : 0}
          >
            {attachments.map((fileId, position) => (
              <>
                {position === separator && <Separator />}
                <GalleryElement
                  small={small}
                  onDelete={onDelete}
                  fileId={fileId}
                  key={fileId}
                  allowFileDeletion={allowFileDeletion && position >= separator}
                  showFileAsIcon={showFileAsIcon}
                  selectedAttachment={selectedAttachment}
                  setSelectedAttachment={setSelectedAttachment}
                />
              </>
            ))}
          </Carousel>
        ) : (
          ''
        )}
      </CarouselContainer>
      {!hideUploads && (
        <FileUpload
          values={values}
          handleUpload={handleUpload}
          analytics={analytics}
        />
      )}

      {allowArrows && (
        <ArrowRight
          modifiers={[
            'right',
            small ? 'small' : 'large',
            !hasNext && 'light',
            !hasNext && 'defaultCursor',
          ]}
          small={small ? 'true' : 'false'}
          onClick={(_) => hasNext && setIndex((prev) => prev + 1)}
          right="true"
          hasNext={hasNext}
        />
      )}
    </ActionsContainer>
  );
}
export default Attachments;
