import type { DrawerProps, ThemeConfig } from 'antd';
import { Drawer as AntDrawer, ConfigProvider, Typography } from 'antd';

type Props = DrawerProps & {
  theme?: ThemeConfig;
};

export function Drawer(props: Props) {
  const { closeIcon = null, closable = false, title, theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntDrawer
        {...rest}
        closeIcon={closeIcon}
        closable={closable}
        title={
          <Typography.Title
            level={3}
            style={{
              marginTop: 0,
            }}
          >
            {title}
          </Typography.Title>
        }
      >
        {props.children}
      </AntDrawer>
    </ConfigProvider>
  );
}
