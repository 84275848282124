import dayjs from 'dayjs';
import styled from 'styled-components/macro';
import { ReactComponent as ArrowIcon } from 'images/svg/arrow-right.svg';
import { Log } from '@optii/topcat-client/components/shared/LocationView/types';

const Bold = styled.span`
  margin: 0;
  font-weight: 500;
  color: #595959;
`;
const Body = styled.span`
  margin: 0;
  font-weight: 400;
  color: #595959;
`;

type ItemMetadata = {
  amount: number;
  id: number;
  jobId: number;
  name: string;
  departmentName: string;
  roleName: string;
  firstName: string;
  lastName: string;
} | undefined;

type ItemChanges = {
  newItems: ItemMetadata;
  newAmount: ItemMetadata;
  oldAmount: ItemMetadata;
}[];

type NewLocation = {
  id: number;
  name: string;
  type: string;
  jobId: number;
  number: string;
  locationId: number;
  locationSelf: string;
  longDisplayName: string;
  shortDisplayName: string;
};

type RoleNode = {
  node: Node;
};

type Node = {
  name: string;
  id: number
};

type Change = {
  key: string;
  new: ItemMetadata;
  old: ItemMetadata;
};

export default function jobLog(log: Log, timezone: string, change, rolesList: RoleNode[]) {

  const version = log.aggregateVersion;
  const id = log.aggregateId;
  const type = log.aggregateType;
  const { action, metadata, user, eventType } = log;
  let { domain } = log;
  const { firstName, lastName, id: userId } = user;
  const { changes, assignee, message, roles } = metadata;
  const { firstName: assigneeFirstName, lastName: assigneeLastName } =
    assignee || {};
  const { key: changeKey, new: changeNew, old: changeOld } = change || {};
  let fullName =
    firstName && lastName ? `${firstName} ${lastName}` : 'Unknown User';

  let keyFormatted = changeKey;
  let assigneeFullName =
    assigneeFirstName && assigneeLastName
      ? `${assigneeFirstName} ${assigneeLastName}`
      : 'Unknown User'; // ROBERT TODO: Need to Translate any system label
  let showMeta = false;
  let showArrow = true;
  let jsonObject: ItemMetadata[];
  let oldJsonObject: ItemMetadata[];
  let locationMetaName;
  let oldFormatted;
  let newFormatted;
  let roleNames = [];
  let newItems: ItemMetadata[] = [];
  let newAmount: ItemMetadata[] = [];
  let oldAmount: ItemMetadata[] = [];
  const itemChanges: ItemChanges = [];

  // version 1.0 logic

  function itemExists(oldObject: ItemMetadata | string) {
    return oldObject?.name === jsonObject && jsonObject[0] && jsonObject[0].name;
  }

  if (userId === '0' || userId === '-1') {
    fullName = 'Optii';
  }

  if (eventType === 'Notification') {
    roleNames = roles
      ?.map((role: number) =>
        rolesList.find((singleRole: RoleNode) => singleRole.node.id === role),
      )
      .map((role: RoleNode) => role.node.name);
    oldFormatted = roleNames?.join(', ');
    newFormatted = '';
    showArrow = false;
    showMeta = true;
  }

  if (change) {
    if (
      changeKey === 'Priority' ||
      changeKey === 'Type' ||
      changeKey === 'Action' ||
      changeKey === 'Note' ||
      changeKey === 'Credit' ||
      changeKey === 'DurationMin'
    ) {
      oldFormatted =
        typeof changeOld === 'string' && changeOld
          ? changeOld.replace(/['"]+/g, '')
          : 'None';
      oldFormatted =
        typeof changeOld === 'string' && changeOld
          ? oldFormatted.charAt(0).toUpperCase() + oldFormatted.slice(1)
          : 'None';
      newFormatted =
        typeof changeNew === 'string' && changeNew
          ? changeNew.replace(/['"]+/g, '')
          : 'None';
      newFormatted =
        typeof changeNew === 'string' && changeNew
          ? newFormatted.charAt(0).toUpperCase() + newFormatted.slice(1)
          : 'None';
      showMeta = true;
    }

    if (changeKey === 'Locations') {
      if (change.new === null) {
        return null;
      }
      locationMetaName = change.new?.map((newLocation: NewLocation) => 
        ` ${newLocation.longDisplayName}`);
      oldFormatted =
        (type === 'HK Job') || (change?.new?.length === 1 && change?.old?.length === 1)
          ? `Location changed to ${locationMetaName}`
          : `Job added to ${locationMetaName}`;
      newFormatted = '';
      showMeta = true;
      showArrow = false;
    }

    if (changeKey === 'Action') {
      if (oldFormatted === 'Null' || oldFormatted === null) {
        oldFormatted = 'None';
      }
      if (newFormatted === 'Null' || newFormatted === null) {
        newFormatted = 'None';
      }
    }

    if (changeKey === 'TargetTime') {
      keyFormatted = 'Due By Time';
      newFormatted = changeNew ? changeNew.replace(/['"]+/g, '') : 'None';
      newFormatted = changeNew
        ? dayjs(newFormatted).tz(timezone).format('L LT')
        : 'None';
      oldFormatted = changeOld ? changeOld.replace(/['"]+/g, '') : 'None';
      oldFormatted = changeOld
        ? dayjs(oldFormatted).tz(timezone).format('L LT')
        : 'None';
      showMeta = true;
    }

    if (changeKey === 'Items') {
      keyFormatted = 'Item';
      jsonObject = Array.isArray(changeNew) ? changeNew : [];
      oldJsonObject = Array.isArray(changeOld) ? changeOld : [];

      
      newItems = jsonObject?.filter(
        (item) => !oldJsonObject?.some((item2: ItemMetadata) => item2?.name === item?.name),
      );
      newAmount = jsonObject?.filter((item: ItemMetadata) =>
        oldJsonObject?.some((item2: ItemMetadata) => 
          (item2?.name === item?.name && item2?.amount !== item?.amount),
        ),
      );
      oldAmount = oldJsonObject?.filter((item) =>
        jsonObject?.find((item2: ItemMetadata) => 
          item2?.name === item?.name && item2?.amount !== item?.amount,
        ),
      );

      newItems.forEach((item: ItemMetadata) => itemChanges.push({ 
        newItems: item, 
        newAmount: undefined,
        oldAmount: undefined, 
        }),
      );
      newAmount.forEach((newItem: ItemMetadata, index) => {
        itemChanges.push({ 
          newItems: undefined, 
          newAmount: newItem, 
          oldAmount: oldAmount[index],
        });
      });

      if (!oldJsonObject?.find(itemExists)) {
        domain = 'Item';
        newFormatted = '';
      }
      showMeta = true;
    }

    if (changeKey === 'Departments') {
      jsonObject = changeNew;
      oldJsonObject = changeOld;
      if (changeNew === 'null' || changeNew === null) {
        newFormatted = 'None';
      } else {
        newFormatted =
          jsonObject[jsonObject.length - 1]?.departmentName || 'None';
      }
      if (changeOld === 'null' || changeOld === null) {
        oldFormatted = 'None';
      } else {
        oldFormatted = oldJsonObject[0]?.departmentName || 'None';
      }
      showMeta = true;
      showArrow = true;
    }

    if (changeKey === 'Roles') {
      jsonObject = changeNew;
      oldJsonObject = changeOld;
      if (changeNew === 'null' || changeNew === null) {
        newFormatted = 'None';
      } else {
        newFormatted = jsonObject[0]?.roleName || 'None';
      }
      if (changeOld === 'null' || changeOld === null) {
        oldFormatted = 'None';
      } else {
        oldFormatted = oldJsonObject[0]?.roleName || 'None';
      }
      showMeta = true;
      showArrow = true;
    }

    if (!changes || changeKey === 'Attachments' || changeKey === 'Credit') {
      showMeta = false;
    }

    if (changeKey === 'Assigned') {
      assigneeFullName = `${changeNew.firstName} ${changeNew.lastName}`;
      showMeta = false;
    }

    if (changeKey === 'Departments') {
      keyFormatted = 'Department';
    }

    if (changeKey === 'TimeWindowStart' || changeKey === 'TimeWindowEnd') {
      keyFormatted = changeKey === 'TimeWindowStart'
        ? 'Start Time'
        : 'End Time';


      oldFormatted = changeOld && changeOld.replace(/['"]+/g, '');
      newFormatted = changeNew && changeNew.replace(/['"]+/g, '');
      oldFormatted = dayjs(oldFormatted).tz(timezone).format('LT');
      newFormatted = dayjs(newFormatted).tz(timezone).format('LT');
      showMeta = false;
      oldFormatted = oldFormatted === 'Invalid Date' ? 'None' : oldFormatted;

      return {
        id,
        message: (
          <>
            <Body>{keyFormatted}: </Body>
            <Bold>
              {' '}
              {oldFormatted} <ArrowIcon /> {newFormatted}
            </Bold>
          </>
        ),
        showMeta,
        fullName,
      };
    }

    if (changeKey === 'DurationMin') {
      keyFormatted = 'Job Duration';
      showMeta = false;
      oldFormatted = oldFormatted === 'Null' ? 'None' : oldFormatted;

      return {
        id,
        message: (
          <>
            <Body>Job Duration: </Body>
            <Bold>
              {' '}
              {oldFormatted} <ArrowIcon /> {newFormatted}
            </Bold>
          </>
        ),
        showMeta,
        fullName,
      };
    }
  }

  const jobs = {
    // Job log formatting version 1.0
    'Job_Job_New_Added_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_New_Assigned_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Assigned to {assigneeFullName}</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Assigned_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Assigned to {assigneeFullName}</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Started_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Started</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_NotStarted_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Not Started</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Resumed_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Resumed</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Reopened_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Reopened</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Completed_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Completed</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Paused_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Paused</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Cancelled_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Cancelled</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_Job_Modified_Modified_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Updated</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      itemChanges,
      showArrow,
    },
    'Item_HK Job_Modified_Modified_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Added</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      itemChanges,
      showArrow,
    },
    'Item_Job_Modified_Modified_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Added</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      itemChanges,
      showArrow,
    },
    'Job_Job_Notification_Added_1.0': {
      id,
      message: (
        <>
          <Body>Notifications: </Body>
          <Bold>Sent {message}</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Location_Job_Modified_Updated_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Added</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Location_HK Job_Modified_Updated_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Added</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Location_Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Added</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Job_HK Job_New_Added_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Assigned_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Assigned to {assigneeFullName}</Bold>
        </>
      ),
      fullName,
    },
    'Job_HK Job_Modified_Started_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Started</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_NotStarted_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Not Started</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Resumed_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Resumed</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Reopened_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Reopened</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Completed_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Completed</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Paused_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Paused</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Cancelled_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Cancelled</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Job: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Job_HK Job_Modified_Modified_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>Updated</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Rush_HK Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>Rush: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Rush_HK Job_Modified_Removed_1.0': {
      id,
      message: (
        <>
          <Body>Rush: </Body>
          <Bold>Removed</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Credit_HK Job_Modified_Edited_1.0': {
      id,
      message: (
        <>
          <Body>{keyFormatted}: </Body>
          <Bold>
            {' '}
            {oldFormatted} <ArrowIcon /> {newFormatted}
          </Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Note_Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Note_Job_Modified_Edited_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Edited</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Note_Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Note_HK Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Note_HK Job_Modified_Edited_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Edited</Bold>
        </>
      ),
      oldMetadata: oldFormatted,
      newMetadata: newFormatted,
      showMeta,
      fullName,
      showArrow,
    },
    'Note_HK Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Note: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_Job_Modified_Edited_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Edited</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_HK Job_Modified_Added_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Added</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_HK Job_Modified_Edited_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Edited</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
    'Attachment_HK Job_Modified_Deleted_1.0': {
      id,
      message: (
        <>
          <Body>Attachment: </Body>
          <Bold>Deleted</Bold>
        </>
      ),
      showMeta,
      fullName,
    },
  };

  return jobs[`${domain}_${type}_${eventType}_${action}_${version}`];
}
