import { COLORS, SPACING } from '@optii-solutions/ui-library';

export const SIZES = {
  large: 675,
  medium: 564,
  small: 492,
};

export const STYLES: { [key: string]: React.CSSProperties } = {
  body: {
    backgroundColor: COLORS.neutral[3],
  },
  drawerHeader: {
    padding: SPACING.NONE,
  },
  drawerBody: {
    backgroundColor: COLORS.neutral[3],
    padding: SPACING.NONE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tabBar: {
    backgroundColor: COLORS.neutral[3],
  },
  content: {
    padding: `${SPACING.SIZE_L}px ${SPACING.SIZE_XL}px`,
    minWidth: '100%',
    flex: 1,
    display: 'flex',
  },
  tabs: {
    backgroundColor: COLORS.neutral[1],
  },
  footer: {
    padding: SPACING.NONE,
    borderTop: 'none',
  },
  descriptionContent: {
    paddingBottom: SPACING.SIZE_MD,
  },
  descriptions: {
    padding: `${SPACING.SIZE_DEFAULT}px ${SPACING.SIZE_XL}px`,
    maxHeight: 264,
    overflow: 'auto',
  },
  row: {
    marginTop: SPACING.SIZE_MD,
  },
  header3: {
    marginTop: SPACING.NONE,
    fontFamily: 'Montserrat',
  },
  headerActionsColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export const TAB_COLUMNS = {
  xxl: 3,
  xl: 3,
  lg: 3,
  md: 3,
  sm: 3,
  xs: 2,
};
