import Icon from '@ant-design/icons';
import {
  ConfigProvider,
  Modal,
  ModalFuncProps,
  Space,
  Table,
  TablePaginationConfig,
  TableProps,
  ThemeConfig,
} from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TableIcons } from '../../icons/jobs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

interface PagedGridColumn {
  key: string;
  title: string;
  dataIndex: string;
  sorter?: boolean;
  render?: (value: any, record: any, index: number) => ReactNode;
}

export type PagedGridDataSource = {
  [key: string]: string | ReactNode;
} & {
  icon?: 'parent' | 'child' | 'virtual';
};

export interface PagedGridProps {
  theme?: ThemeConfig;
  selection?: boolean;
  columns: (PagedGridColumn | 'icon')[];
  dataSource: PagedGridDataSource[];
  loading: boolean;
  onDelete?: (item: Record<string, any>) => void;
  deleteConfirmConfig?: (item: Record<string, any>) => ModalFuncProps;
  onEdit?: (item: Record<string, any>) => void;
  onChange: (
    pagination: TablePaginationConfig,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => void;
  onSelectionChange?: (keys: React.Key[]) => void;
  deleteKey?: string;
  actionLabel?: string;
  paginationLabel?: string;
  paginationShowLabel: string;
  paginationTotal?: number;
  paginationPerPage?: number[];
  paginationCurrent?: number;
  initialSelectedRowKeys?: React.Key[];
  clearSelectionOnDataSourceUpdate?: boolean;
}

export function PagedGrid({
  theme = {
    components: {
      Pagination: {
        itemBg: '#fff',
        borderRadius: 2,
      },
    },
  },
  selection,
  columns,
  dataSource,
  loading,
  onDelete,
  deleteConfirmConfig = () => ({}),
  onEdit,
  onChange,
  onSelectionChange,
  deleteKey = 'name',
  actionLabel = 'Action',
  paginationShowLabel = 'Show',
  paginationLabel = 'per page',
  paginationTotal,
  paginationPerPage = [50, 100, 200],
  paginationCurrent,
  initialSelectedRowKeys = [],
  clearSelectionOnDataSourceUpdate,
}: PagedGridProps) {
  const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState<
    React.Key[]
  >(initialSelectedRowKeys);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    defaultSelectedRowKeys,
  );
  const { t } = useTranslation(['common']);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    updateDefaultSelectedRowKeys(newSelectedRowKeys);
    if (onSelectionChange) {
      onSelectionChange(newSelectedRowKeys);
    }
  };

  useEffect(() => {
    if (selection && clearSelectionOnDataSourceUpdate) {
      setSelectedRowKeys([...defaultSelectedRowKeys]);
    }
  }, [selection, dataSource]);

  useEffect(() => {
    if (selection) {
      setSelectedRowKeys(initialSelectedRowKeys);
    }
  }, [selection, initialSelectedRowKeys]);

  const updateDefaultSelectedRowKeys = (newSelectedRowKeys: React.Key[]) => {
    const newDefaultSelectedRowKeys = defaultSelectedRowKeys.filter((key) =>
      newSelectedRowKeys.includes(key),
    );
    setDefaultSelectedRowKeys([
      ...initialSelectedRowKeys,
      ...newDefaultSelectedRowKeys,
    ]);
  };

  const tableColumns: TableProps['columns'] = [];

  for (const column of columns) {
    if (column === 'icon')
      tableColumns.push({
        key: 'icon',
        dataIndex: 'icon',
        render: (icon) => (
          <Icon component={TableIcons[icon as keyof typeof TableIcons]} />
        ),
      });
    else tableColumns.push(column);
  }

  if (onEdit || onDelete) {
    tableColumns.push({
      title: actionLabel,
      align: 'right',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {onEdit && record.edit && (
            <Icon
              component={TableIcons['edit']}
              onClick={() => onEdit(record)}
              data-testid="edit"
            />
          )}
          {onDelete && record.delete && (
            <Icon
              component={TableIcons['delete']}
              onClick={() => {
                confirm({
                  icon: null,
                  cancelButtonProps: {
                    type: 'text',
                    size: 'large',
                  },
                  okButtonProps: {
                    danger: true,
                    size: 'large',
                  },
                  title: t('common:Delete {{name}}', {
                    name: record[deleteKey].props
                      ? record[deleteKey].props.children
                      : record[deleteKey],
                  }),
                  content: t(
                    'common:Are you sure you want to delete {{name}}?',
                    {
                      name: record[deleteKey].props
                        ? record[deleteKey].props.children
                        : record[deleteKey],
                    },
                  ),
                  okText: t('common:Yes, delete'),
                  okType: 'primary',
                  cancelText: t('common:Cancel'),
                  centered: true,
                  onOk() {
                    onDelete(record);
                  },
                  ...deleteConfirmConfig(record),
                });
              }}
              data-testid="delete"
            />
          )}
        </Space>
      ),
    });
  }

  return (
    <ConfigProvider theme={theme}>
      <style>
        {`
          .ui-library-paged-grid {
            .ant-table-selection-extra {
              display: none;
            }
            .ant-pagination-item:not(.ant-pagination-item-active),  
            .ant-pagination-next .ant-pagination-item-link,  
            .ant-pagination-prev .ant-pagination-item-link {
              background-color: white;
              border-color: #D9D9D9;
            }
      
            .ant-pagination-mini {
              justify-content: center !important;
              .ui-library-grid-show {
                display: none;
              }
              .ant-space-item > div {
                width: 24px !important;
                height: 24px !important;
              }
            }
          }
          `}
      </style>
      <Table
        loading={loading}
        scroll={{ x: 'max-content' }}
        pagination={{
          itemRender: (page, type, element) => {
            if (type === 'next')
              return (
                <Space size="middle">
                  <div
                    style={{
                      width: 32,
                      height: 32,
                      display: 'block',
                      position: 'relative',
                      top: -1,
                    }}
                  >
                    {element}
                  </div>
                  <div className="ui-library-grid-show">
                    {paginationShowLabel}
                  </div>
                </Space>
              );
            return element;
          },
          rootClassName: 'paginationContainer',
          responsive: true,
          showSizeChanger: true,
          current: paginationCurrent,
          total: paginationTotal,
          defaultPageSize: paginationPerPage[0],
          pageSizeOptions: paginationPerPage,
          locale: {
            items_per_page: paginationLabel,
          },
        }}
        data-testid={'paginationContainer'}
        onChange={(pagination, _, sorter) => onChange(pagination, sorter)}
        className="ui-library-paged-grid"
        columns={tableColumns}
        dataSource={dataSource}
        rowSelection={
          selection
            ? {
                selectedRowKeys,
                onChange: onSelectChange,
                selections: [Table.SELECTION_ALL],
              }
            : undefined
        }
      />
    </ConfigProvider>
  );
}
