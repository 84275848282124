import React, { useState, useEffect, useMemo } from 'react';
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import { useLazyQuery } from '@apollo/client';
import { LIST_FETCH_POLICIES, ASSET_VIEWS } from 'utils/constants';
import { initGQLData as initData, getPageInfo } from 'utils/initGQLData';
import {
  FIND_ALL_ASSET_TYPES,
  FIND_ALL_ASSETS,
  FIND_ALL_BRANDS,
  GET_JOB_ITEMS_DEFS,
  FIND_ALL_MODELS,
  GET_LOCATIONS_LIST,
} from 'queries';

export const AssetsContext = React.createContext({
  loading: false,
  data: [],
  pageInfo: {},
  setPageInfo: () => {},
  refetch: () => {},
  fetchMore: () => {},
  fetch: () => {},
  assetsData: [],
  filters: [],
  setFilters: () => {},
  setEditAssetType: () => {},
  refetchSubAssets: false,
  setRefetchSubAssets: (val) => {},
  setNewAsset: () => {},
  editAssetType: {
    id: 0,
    isActive: false,
    name: '',
    description: '',
    category: {},
    brand: {
      displayName: '',
    },
    modelName: '',
    displayName: '',
    cycle: {},
    lifeCycleNumber: '',
    lifeCyclePeriodType: {
      key: 0,
      label: '',
      value: 0,
      filterSortValue: '',
    },
    note: '',
    attachments: [],
    documents: [],
    jobItem: {
      id: 0,
      displayName: '',
    },
    status: '',
    lifeCycle: {
      number: '',
      label: '',
      value: 0,
      lifeCyclePeriodType: '',
    },
    serviceCycle: {
      value: 0,
      type: '',
      displayName: '',
    },
    version: 0,
  },
  editAsset: {
    id: 0,
    name: '',
    description: '',
    installationDate: '',
    displayName: '',
    documents: [],
    version: 0,
    assetType: {
      id: '',
    },
    location: {
      id: '',
    },
    purchase: {
      date: '',
      price: '',
      orderNumber: '',
      invoiceNumber: '',
    },
    vendor: {
      website: '',
      phone: '',
      address: '',
      id: '',
      displayName: '',
    },
    warranty: {
      number: '',
      warrantyPeriodType: '',
      description: '',
    },
  },
  isNewAssetType: false,
  isNewAsset: false,
  setViewAsset: () => {},
  viewAsset: {
    id: 0,
    assetType: {
      brand: {
        displayName: '',
      },
      modelName: '',
      displayName: '',
      note: '',
      description: '',
      attachments: [],
      jobItem: {
        id: 0,
        displayName: '',
      },
      lifeCycle: {
        number: '',
        lifeCyclePeriodType: '',
      },
      serviceCycle: {
        value: 0,
        type: 0,
        displayName: '',
      },
    },
    location: {
      longDisplayName: '',
      shortDisplayName: '',
    },
    vendor: {
      id: 0,
      displayName: '',
      website: '',
      phone: '',
      address: '',
    },
    purchase: {
      id: 0,
      date: '',
      price: '',
      orderNumber: '',
      invoiceNumber: '',
    },
    warranty: {
      number: 0,
      warrantyPeriodType: '',
      description: '',
    },
    serialNumber: '',
    installationDate: '',
    notes: '',
    documents: [],
  },
  defaultAssetTpe: { id: null },
  setDefaultAssetTpe: (args) => {},
  setEditAsset: (assetData) => {},
  jobItemOptions: [],
  locationOptions: [],
  brandOptions: [],
  modelOptions: [],
  jobItemsLoading: false,
  locationsLoading: false,
  brandsLoading: false,
  modelsLoading: false,
});

export const AssetsContextProvider = function (props) {
  const [isNewAssetType, setNewAssetType] = useState(false);
  const [editAssetType, setEditAssetType] = useState(null);
  const [refetchSubAssets, setRefetchSubAssets] = useState(false);
  const [defaultAssetTpe, setDefaultAssetTpe] = useState(null);

  const [viewAsset, setViewAsset] = useState(null);
  const [openAssetView, setOpenAssetView] = useState(!!viewAsset || false);

  const [isNewAsset, setNewAsset] = useState(false);
  const [editAsset, setEditAsset] = useState(null);
  const [data, setData] = useState(null);

  const [getJobItems, { data: itemData, loading: jobItemsLoading }] =
    useLazyQuery(GET_JOB_ITEMS_DEFS, {
      context: { _instance: 'node' },
      variables: { Sorts: 'displayName' },
    });

  const [getLocations, { data: locationData, loading: locationsLoading }] =
    useLazyQuery(GET_LOCATIONS_LIST);

  const [getBrands, { data: brandData, loading: brandsLoading }] = useLazyQuery(
    FIND_ALL_BRANDS,
    {
      context: { _instance: 'node' },
      variables: { first: 1000, sorts: 'displayName' },
    },
  );

  const [getModels, { data: modelData, loading: modelsLoading }] = useLazyQuery(
    FIND_ALL_MODELS,
    {
      context: { _instance: 'node' },
    },
  );

  const [
    getAssetTypes,
    {
      data: assetTypeData,
      refetch: refetchAssetTypes,
      fetchMore: fetchMoreAssetTypes,
      loading: assetTypesLoading,
    },
  ] = useLazyQuery(FIND_ALL_ASSET_TYPES, {
    notifyOnNetworkStatusChange: true, // true - to make loading value updating
    ...LIST_FETCH_POLICIES,
    context: { _instance: 'node' },
  });

  const [
    getAssets,
    {
      data: assetData,
      refetch: refetchAssets,
      fetchMore: fetchMoreAssets,
      loading: assetsLoading,
    },
  ] = useLazyQuery(FIND_ALL_ASSETS, {
    notifyOnNetworkStatusChange: true, // true - to make loading value updating
    ...LIST_FETCH_POLICIES,
    context: { _instance: 'node' },
  });

  const [viewByFilter, setViewByFilter] = useQueryParams({
    viewBy: StringParam,
  });

  // For the use case where you navigate here and you are already here, I know, odd, withDefault broke
  useEffect(() => {
    if (viewAsset && viewAsset.id) {
      setOpenAssetView(true);
    }
  }, [viewAsset]);

  // For the use case where you navigate here and you are already here, I know, odd, withDefault broke
  useEffect(
    (_) => {
      if (!viewByFilter.viewBy) {
        setData(null);
        setViewByFilter({ viewBy: ASSET_VIEWS.assetType });
      }
    },
    [viewByFilter],
  );

  const [filters, setFilters] = useQueryParams({
    brand: withDefault(ArrayParam, []),
    model: withDefault(ArrayParam, []),
    category: withDefault(ArrayParam, []),
    location: withDefault(ArrayParam, []),
    serviceCycle: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    q: withDefault(StringParam, ''),
  });

  // Are we looking at asset types or assets
  const isAssetType = viewByFilter.viewBy === ASSET_VIEWS.assetType;

  function fetchFilters() {
    getJobItems();
    getLocations();
    getBrands();
    getModels();
  }

  useEffect((_) => {
    fetchFilters();
  }, []);

  let providerValues;

  const [assetsPageInfo, setAssetsPageInfo] = useState({});
  const [assetsTypePageInfo, setAssetsTypePageInfo] = useState({});

  useEffect(
    (_) => {
      if (isAssetType) {
        assetTypeData && setData(initData(assetTypeData));
        assetTypeData && setAssetsTypePageInfo(getPageInfo(assetTypeData));
      } else {
        assetData && setData(initData(assetData));
        assetData && setAssetsPageInfo(getPageInfo(assetData));
      }
    },
    [isAssetType, assetTypeData, assetData],
  );

  // Decide which data we need to be passing in through the provider
  if (isAssetType) {
    providerValues = {
      loading: assetTypesLoading,
      pageInfo: assetsTypePageInfo,
      setPageInfo: setAssetsTypePageInfo,
      refetch: refetchAssetTypes,
      fetchMore: fetchMoreAssetTypes,
      fetch: getAssetTypes,
    };
  } else {
    // For assets
    providerValues = {
      loading: assetsLoading,
      pageInfo: assetsPageInfo,
      setPageInfo: setAssetsPageInfo,
      refetch: refetchAssets,
      fetchMore: fetchMoreAssets,
      fetch: getAssets,
    };
  }

  const contextValue = useMemo(
    () => ({
      filters,
      setFilters,
      setEditAssetType,
      refetchSubAssets,
      setRefetchSubAssets,
      editAssetType,
      setNewAssetType,
      isNewAssetType,
      setNewAsset,
      defaultAssetTpe,
      setDefaultAssetTpe,
      isNewAsset,
      viewAsset,
      setViewAsset,
      setEditAsset,
      editAsset,
      data,
      jobItemOptions: initData(itemData),
      locationOptions: initData(locationData),
      brandOptions: initData(brandData),
      modelOptions:
        (modelData &&
          modelData.models.map((m) => ({ ...m, id: m.displayName }))) ||
        [],
      jobItemsLoading,
      locationsLoading,
      brandsLoading,
      modelsLoading,
      ...providerValues,
      loading: assetTypesLoading || assetsLoading,
      openAssetView,
      setOpenAssetView,
      viewByFilter,
      setViewByFilter,
      isAssetType,
      setData,
    }),
    [
      filters,
      setFilters,
      setEditAssetType,
      refetchSubAssets,
      setRefetchSubAssets,
      editAssetType,
      setNewAssetType,
      isNewAssetType,
      setNewAsset,
      defaultAssetTpe,
      setDefaultAssetTpe,
      isNewAsset,
      viewAsset,
      setViewAsset,
      setEditAsset,
      editAsset,
      data,
      itemData,
      locationData,
      brandData,
      modelData,
      jobItemsLoading,
      locationsLoading,
      brandsLoading,
      modelsLoading,
      providerValues,
      assetTypesLoading,
      assetsLoading,
      openAssetView,
      setOpenAssetView,
      viewByFilter,
      setViewByFilter,
      isAssetType,
      setData,
    ],
  );

  if (!viewByFilter.viewBy) {
    return null;
  }

  return (
    <AssetsContext.Provider value={contextValue}>
      {props.children}
    </AssetsContext.Provider>
  );
};
