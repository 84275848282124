import { gql } from '@apollo/client';

export default gql`
  mutation updateJobById($id: ID!, $input: JobEdit!) {
    updateJobById(id: $id, input: $input) {
      id
      action
      items {
        amount
        name
      }
      doBy
      scheduleStartTimeAt
      status
      parentChecklistTaskId
      isRushed
    }
  }
`;
