import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10002;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  img {
    @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
      max-width: 36rem;
      max-height: 50rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
      max-width: 36rem;
      max-height: 50rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
      max-width: 50rem;
      max-height: 50rem;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      max-width: 50rem;
      max-height: 50rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      max-width: 80rem;
      max-height: 100rem;
    }
    max-width: 80rem;
    max-height: 45rem;
    user-select: none;
    object-fit: contain;
  }
`;

const Portal = ({ children, domNode }) => createPortal(children, domNode);

function Modal({ children, actions }) {
  return (
    <Container onClick={(_) => actions.onOutsideClick()}>{children}</Container>
  );
}

function FullscreenImage({ children, actions }) {
  return (
    <Portal domNode={document.body}>
      <Modal actions={actions}>{children}</Modal>
    </Portal>
  );
}

export default FullscreenImage;
