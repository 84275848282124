import { EventContext } from '../../../types/notifications';
import jobAndProjectSubmit from './jobAndProjectSubmit';
import temperatureTaskSubmit from './temperatureTaskSubmit';
import numericalTaskSubmit from './numericalTaskSubmit';
import { NotificationInput } from '../../../api/notifications';

interface UpdateProps {
  variables: {
    id: string;
    input: NotificationInput;
  };
}

interface CreateProps {
  variables: {
    input: NotificationInput;
  };
}

interface Props {
  eventContext: EventContext;
  input: NotificationInput;
  notificationId?: string | null;
  handleUpdate: (agrs: UpdateProps) => Promise<any>;
  handleCreate: (agrs: CreateProps) => Promise<any>;
  metadata: { [key: string]: any };
}

export const SUBMIT_ADD_MESSAGES = {
  [EventContext.Job]: { success: 'common:{{notificationName}} was successfully added', error: 'common:error on creating a notification' },
  [EventContext.ProjectCycle]: { success: 'common:{{notificationName}} was successfully added', error: 'common:error on creating a notification' },
  [EventContext.Project]: { success: 'common:{{notificationName}} was successfully added', error: 'common:error on creating a notification' },
  [EventContext.TemperatureTask]: { success: 'common:Temperature notification was successfully added', error: 'common:error on creating a notification' },
  [EventContext.NumericalTask]: { success: 'common:Numerical notification was successfully added', error: 'common:error on creating a notification' },
};

export const SUBMIT_EDIT_MESSAGES = {
  [EventContext.Job]: { success: 'common:{{notificationName}} was successfully updated', error: 'common:error on updating a notification' },
  [EventContext.ProjectCycle]: { success: 'common:{{notificationName}} was successfully updated', error: 'common:error on updating a notification' },
  [EventContext.Project]: { success: 'common:{{notificationName}} was successfully updated', error: 'common:error on updating a notification' },
  [EventContext.TemperatureTask]: { success: 'common:Temperature notification was successfully updated', error: 'common:error on updating a notification' },
  [EventContext.NumericalTask]: { success: 'common:Numerical notification was successfully updated', error: 'common:error on updating a notification' },
};

export default function submit({ eventContext, ...props }: Props) {
  const SUBMIT = {
    [EventContext.Job]: jobAndProjectSubmit,
    [EventContext.ProjectCycle]: jobAndProjectSubmit,
    [EventContext.TemperatureTask]: temperatureTaskSubmit,
    [EventContext.NumericalTask]: numericalTaskSubmit,
  };

  return SUBMIT[eventContext as Exclude<EventContext, 'Project'>](props);
}
