import {
  CloseCircleFilled,
  DownOutlined,
  EditFilled,
  LoadingOutlined,
  UpOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  List,
  Row,
  Space,
  Spin,
  ThemeConfig,
  Typography,
} from 'antd';
import { GroupedColumn } from '../../molecules/Groups/Groups.component';
import { ReactNode } from 'react';

const { Title } = Typography;

export interface CardsProps {
  theme?: ThemeConfig;
  data: {
    id: number;
    name: ReactNode;
    role: string[];
    type: string[];
    source: string[];
  }[];
  header: { label: ReactNode; direction?: 'asc' | 'desc'; key: string }[];
  loading?: boolean;
  deletingAt: number;
  onLoadMore?: () => void;
  onDelete: (item: CardsProps['data'][0]) => void;
  onEdit: (item: CardsProps['data'][0]) => void;
  onHeaderToggle: (item: CardsProps['header'][0]) => void;
  loadMoreLabel: string;
}

interface HeaderTitleProps {
  children: ReactNode;
  direction?: string;
}

const HEADER_SIZES = [9, 5, 4, 4] as const;

function HeaderTitle({ direction, children }: HeaderTitleProps) {
  return (
    <Title
      style={{
        fontFamily: 'Montserrat',
        fontWeight: direction ? 500 : 400,
        cursor: 'pointer',
      }}
    >
      {children} {direction === 'desc' && <DownOutlined />}
      {direction === 'asc' && <UpOutlined />}
    </Title>
  );
}

export function Cards({
  theme = {
    components: {
      Card: {
        colorText: '#5C5C5C',
      },
      Typography: {
        fontSizeHeading1: 16,
        fontWeightStrong: 400,
        colorText: '#5C5C5C',
      },
      List: {
        itemPadding: '4px 0',
      },
    },
    token: {
      colorLink: '#CDCDCD',
    },
  },
  data,
  header,
  loading,
  loadMoreLabel = 'Load more',
  deletingAt,
  onDelete,
  onEdit,
  onLoadMore,
  onHeaderToggle,
}: CardsProps) {
  return (
    <ConfigProvider theme={theme}>
      <Spin
        spinning={loading}
        style={{ width: '100%' }}
        indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
      >
        <List
          itemLayout="vertical"
          split={false}
          dataSource={data}
          loadMore={
            onLoadMore && (
              <Button
                type="primary"
                onClick={() => !loading && onLoadMore()}
                style={{ margin: '10px auto', display: 'block' }}
              >
                {loadMoreLabel}
              </Button>
            )
          }
          header={
            <Row align="middle" style={{ padding: '0 12px' }}>
              {header.map((item, i) => (
                <Col
                  onClick={() => !loading && onHeaderToggle(item)}
                  key={item.key}
                  span={HEADER_SIZES[i]}
                >
                  <HeaderTitle direction={item.direction}>
                    {item.label}
                  </HeaderTitle>
                </Col>
              ))}
            </Row>
          }
          renderItem={(item) => (
            <List.Item>
              <Spin
                spinning={deletingAt === item.id}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              >
                <Card size="small" bordered={false}>
                  <Row align="middle">
                    <Col span={9}>
                      <GroupedColumn
                        width={300}
                        items={[item.name as string]}
                      />
                    </Col>
                    <Col span={5}>
                      <GroupedColumn items={item.role} />
                    </Col>
                    <Col span={4}>
                      <GroupedColumn items={item.type} />
                    </Col>
                    <Col span={4}>
                      <GroupedColumn items={item.source} />
                    </Col>
                    <Col flex="auto" style={{ textAlign: 'right' }}>
                      <Space>
                        <Button
                          onClick={() => !loading && onEdit(item)}
                          type="link"
                          shape="circle"
                        >
                          <EditFilled style={{ fontSize: '24px' }} />
                        </Button>
                        <Button
                          onClick={() => !loading && onDelete(item)}
                          type="link"
                          shape="circle"
                        >
                          <CloseCircleFilled style={{ fontSize: '24px' }} />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Spin>
            </List.Item>
          )}
        />
      </Spin>
    </ConfigProvider>
  );
}
