import i18n from "i18n";

const getStatuses = _ => [
  { displayName: i18n.t("floorplan:active"), id: "active" },
  { displayName: i18n.t("floorplan:inactive"), id: "inactive" }
];

let translatedLocationStatus = {};
export default translatedLocationStatus = {};

function updateTranslations() {
  translatedLocationStatus.locationStatuses = getStatuses();
}

// i18next seems ready -> initial fill translations
if (i18n.isInitialized) {
  updateTranslations();
}

// reset translations to new values on language change
i18n.on("languageChanged", (lng) => {
  updateTranslations();
});

// we loaded some translation file? reset needed?!?
// https://www.i18next.com/overview/api#store-events
i18n.on("loaded", (lng) => {
  updateTranslations();
});
