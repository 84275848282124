import { gql } from '@apollo/client';

export const GET_ROOM_SUMMARY = gql`
  query roomSummary($id: ID!) {
    roomSummary(id: $id) {
      id
      cleaningExceptionStatus {
        doNotDisturb
        doubleLockActive
        noServiceRequired
        sleepOutNoShow
      }
      occupancyStatus
      cleaningStatus
      outOfOrderDates {
        code
        startDate
        endDate
      }
      reservations {
        id
        arrivalDate
        attributes
        departureDate
        displayName
        estimatedArrivalTime
        estimatedDepartureTime
        externalGroupReservationId
        externalReservationId
        guestNumber {
          adults
          children
        }
        guests {
          externalGuestProfileId
          firstName
          id
          lastName
          preferences {
            description
            type
            value
          }
          title
          type
          country
        }
        isDeleted
        noOfAdults
        noOfChildren
        notes {
          createdAt
          id
          time
          note
        }
        preferences {
          description
          type
          value
        }
        reservationStatus
        specialRequestCodes
        statusLabel
      }
    }
  }
`;
