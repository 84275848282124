import type { FormListFieldData } from '@optii-solutions/ui-library';

import { JobCriteriaFormList } from './JobCriteriaFormList';
import { ProjectCriteriaFormList } from './ProjectCriteriaFormList';
import { TemperatureTaskCriteriaFormList } from './TemperatureTaskCriteriaFormList';
import { NumericalTaskCriteriaFormList } from './NumericalTaskCriteriaFormList';
import { EventContext } from '../../../types/notifications';

type Props = {
  eventContext: EventContext;
  filterField: FormListFieldData;
  metadata: { [key: string]: any };
};

export function Criteria({ eventContext, filterField, metadata }: Props) {
  const CRITERIA: { [key: string]: JSX.Element } = {
    [EventContext.Job]: <JobCriteriaFormList filterField={filterField} />,
    [EventContext.ProjectCycle]: (
      <ProjectCriteriaFormList filterField={filterField} />
    ),
    [EventContext.TemperatureTask]: (
      <TemperatureTaskCriteriaFormList
        filterField={filterField}
        metadata={metadata}
      />
    ),
    [EventContext.NumericalTask]: <NumericalTaskCriteriaFormList filterField={filterField} metadata={metadata} />,
  };

  return CRITERIA[eventContext as keyof typeof EventContext];
}
