import React, { ReactNode, useEffect, useState } from 'react';
import {
  COLORS,
  FONTS,
  SPACING,
  TimePicker as TimePickerAntd,
} from '@optii-solutions/ui-library';
import { use12Hours } from 'utils/localizeDateConfiguration';
import moment, { Moment } from 'moment';
import dayjs, { Dayjs } from 'dayjs';

interface ITimePickerProps {
  value: Moment;
  createValue: (time: Moment | null) => void;
  handleChange?: (time: Moment | null) => void;
  suffixIcon?: ReactNode;
}
function TimePicker({
  value,
  createValue,
  handleChange,
  suffixIcon,
}: ITimePickerProps) {
  const format = use12Hours() ? 'h:mm a' : 'HH:mm';
  const [time, updateTime] = useState<Dayjs | null>(
    value ? dayjs(moment(value).format(format), format) : null,
  );

  useEffect(() => {
    updateTime(value ? dayjs(moment(value).format(format), format) : null);
  }, [value, format]);

  const handleTimeChange = (newValue: Dayjs | null) => {
    updateTime(newValue);
    if (!newValue) {
      handleChange?.(null);
      return;
    }
    if (handleChange && value) {
      moment().minute(newValue.minute()).hour(newValue.hour());
      handleChange?.(moment().minute(newValue.minute()).hour(newValue.hour()));
    }
    if (value) {
      value.minute(newValue.minute()).hour(newValue.hour());
    } else {
      createValue(moment().minute(newValue.minute()).hour(newValue.hour()));
    }
  };

  return (
    <TimePickerAntd
      value={time}
      onChange={handleTimeChange}
      format={format}
      changeOnScroll
      needConfirm={false}
      use12Hours={use12Hours()}
      variant="borderless"
      style={{
        width: '100%',
        padding: SPACING.NONE,
      }}
      theme={{
        components: {
          DatePicker: {
            fontSize: FONTS.medium.size,
            colorText: COLORS.neutral[8],
            colorPrimary: COLORS.primary[5],
          },
        },
      }}
      allowClear={false}
      suffixIcon={suffixIcon}
      popupStyle={{
        zIndex: '999999',
      }}
    />
  );
}

export default TimePicker;
