import { gql } from "@apollo/client";

export default gql`

query AssetVendors($first: Int, $cursor: String, $sorts: String, $filters: String) {
  page: assetVendors(first: $first, after: $cursor, Sorts: $sorts, filters: $filters) {
    totalCount
    edges {
      node {
        ... on AssetVendor {
          id
          displayName
          website
          phone
          address
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
`;

