import { gql } from "@apollo/client";

export const fragment = `GetUserById(id: $id) {
  preferredLang
  userName
  country
  piiDisabled
  status
  id
  firstName
  lastName
  emailAddr
  city
  state
  postalCode
  mobileNum
  personalPhoto
}`;
export default gql`
  query GetUserById($id: ID!) {
    user: ${fragment}
  }
`;
