import CheckboxType from './blocks/tasksTypes/CheckboxType';
import GroupHeaderType from './blocks/tasksTypes/GroupHeaderType';
import ReadOnlyType from './blocks/tasksTypes/ReadOnlyType';
import OnOffType from './blocks/tasksTypes/OnOffType';
import TemperatureType from './blocks/tasksTypes/TemperatureType';
import PassFailType from './blocks/tasksTypes/PassFailType';
import YesNoType from './blocks/tasksTypes/YesNoType';
import NumericalType from './blocks/tasksTypes/NumericalType';
import TextType from './blocks/tasksTypes/TextType';
import DropdownType from './blocks/tasksTypes/DropdownType';

export const CHECKLIST_CONVERSIONS = {
  job: 'job',
};

export const CHECKLIST_TYPES = {
  groupHeader: 'groupHeader',
  checkBox: 'checkBox',
  readOnly: 'readOnly',
  separator: 'separator',
  numerical: 'numerical',
  yesNo: 'yesNo',
  passFail: 'passFail',
  text: 'text',
  temperature: 'temperature',
  onOff: 'onOff',
  dropdown: 'dropdown',
};

export const CHECKLIST_ACTION_STATES = {
  addingItem: 'addingItem',
  viewingItem: 'viewingItem',
  fulfillment: 'fulfillment',
  addingNote: 'addingNote',
  addingAttachment: 'addingAttachment',
};

export const CHECKLIST_TASK_FIELDS = {
  archivedAt: 'archivedAt',
  attachment: 'attachment',
  checklistId: 'checklistId',
  createdAt: 'createdAt',
  standard: 'standard',
  dataCheckboxChecked: 'dataCheckboxChecked',
  dataNumerical: 'dataNumerical',
  dataOnOff: 'dataOnOff',
  dataPassFail: 'dataPassFail',
  dataTemperatureValue: 'dataTemperatureValue',
  dataTemperatureScale: 'dataTemperatureScale',
  dataDropdownValue: 'dataDropdownValue',
  dropdownOptions: 'dropdownOptions',
  dataText: 'dataText',
  dataYesNo: 'dataYesNo',
  fulfillmentAttachment: 'fulfillmentAttachment',
  fulfillmentNote: 'fulfillmentNote',
  id: 'id',
  label: 'label',
  notes: 'notes',
  ordering_value: 'ordering_value',
  parent_task_id: 'parent_task_id',
  required: 'required',
  taskType: 'taskType',
  updatedAt: 'updatedAt',
};

function getPreviousTaskValue(previousTask) {
  if (!previousTask) return {};

  return {
    [CHECKLIST_TASK_FIELDS.taskType]:
      previousTask[CHECKLIST_TASK_FIELDS.taskType] || 'checkbox',
    [CHECKLIST_TASK_FIELDS.required]:
      previousTask[CHECKLIST_TASK_FIELDS.required] || false,
  };
}

function validation(task) {
  const { touched, formValues } = task;
  const errors = {};
  const { label } = CHECKLIST_TASK_FIELDS;
  if (touched[label] && formValues[label].length <= 0) {
    errors[label] = true;
  }

  const hasError = Object.keys(errors).length > 0;

  return {
    hasError,
    errors,
  };
}

export const CHECKLIST_TASK_TYPE_CONFIG = {
  checkBox: {
    component: CheckboxType,
    label: 'Checkbox',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataCheckboxChecked,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataCheckboxChecked]:
          task[CHECKLIST_TASK_FIELDS.dataCheckboxChecked],
      }),
      isFulfilled: (task) => !!task[CHECKLIST_TASK_FIELDS.dataCheckboxChecked],
    },
  },

  groupHeader: {
    component: GroupHeaderType,
    label: 'Group Header',
    getPreviousTaskValue,
    canBeConvertedInto: [],
    validation,
    fulfillment: {
      getValue: () => ({}),
      fulfillable: false,
      isFulfilled: () => (false),
    },
  },

  readOnly: {
    component: ReadOnlyType,
    label: 'Read-Only',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      getValue: () => ({}),
      fulfillable: false,
      isFulfilled: () => (false),
    },
  },

  onOff: {
    component: OnOffType,
    label: 'On | Off',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataOnOff,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataOnOff]:
          task[CHECKLIST_TASK_FIELDS.dataOnOff],
      }),
      isFulfilled: (task) => {
        const value = task[CHECKLIST_TASK_FIELDS.dataOnOff];
        return value === true || value === false;
      },
    },
  },

  temperature: {
    component: TemperatureType,
    label: 'Temperature',
    getPreviousTaskValue: (previousTask) => {
      const previousValues = getPreviousTaskValue(previousTask);

      return {
        ...previousValues,
        [CHECKLIST_TASK_FIELDS.dataTemperatureScale]:
          previousTask[CHECKLIST_TASK_FIELDS.dataTemperatureScale],
      };
    },
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation: (task, value) => {
      const { errors, hasError: validationError } = validation(task);
      let hasError = validationError;
      const temperatureValue =
        value ?? task.formValues[CHECKLIST_TASK_FIELDS.dataTemperatureValue];

      const isString = typeof value === 'string';
      if (!isString) {
        return { errors, hasError };
      }

      const hasNonNumericValues =
        temperatureValue?.replace(/[0-9.,°]/g, '').length > 0;
      const hasMoreThenOneDecimalChars =
        temperatureValue?.replace(/[0-9]/g, '').length > 1;
      const isOnlyADecimalChar =
        temperatureValue?.replace(/[.,]/g, '') === '' && !!temperatureValue;

      if (
        isString &&
        (hasNonNumericValues ||
          hasMoreThenOneDecimalChars ||
          isOnlyADecimalChar)
      ) {
        hasError = true;
        errors[CHECKLIST_TASK_FIELDS.dataTemperatureValue] = true;
      }

      return { hasError, errors };
    },
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataTemperatureValue,
      getValue: (task) => {
        const scale = task[CHECKLIST_TASK_FIELDS.dataTemperatureScale];
        const value = task[CHECKLIST_TASK_FIELDS.dataTemperatureValue];

        return {
          [CHECKLIST_TASK_FIELDS.dataTemperatureValue]: /[0-9]/.test(
            String(value),
          )
            ? Number(value)
            : null,
          [CHECKLIST_TASK_FIELDS.dataTemperatureScale]: scale || 'celsius',
        };
      },
      getDefaultValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataTemperatureScale]:
          task[CHECKLIST_TASK_FIELDS.dataTemperatureScale],
      }),
      isFulfilled: (task) => {
        const value = task[CHECKLIST_TASK_FIELDS.dataTemperatureValue];
        return value !== null && value !== undefined;
      },
    },
  },

  dropdown: {
    component: DropdownType,
    label: 'Dropdown',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation: (task) => {
      const { errors, hasError: validationError } = validation(task);
      let hasError = validationError;

      const { formValues } = task;
      const { dropdownOptions, label } = CHECKLIST_TASK_FIELDS;

      const emptyLabel = formValues[label] === '';
      if (emptyLabel) return { hasError, errors };

      const emptyOptionsList = formValues[dropdownOptions]?.length === 0;
      const allOptionsItemsEmpty = formValues[dropdownOptions]?.every(
        (i) => i.trim() === '',
      );
      const someOptionsItemEmpty = formValues[dropdownOptions]?.some(
        (i) => i.trim() === '',
      );

      if (emptyOptionsList || allOptionsItemsEmpty) {
        errors[dropdownOptions] = 'this list needs at least one option';
      } else if (someOptionsItemEmpty) {
        errors[dropdownOptions] = "this list can't contain empty items";
      }

      hasError = Object.keys(errors).length > 0;

      return { hasError, errors };
    },
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataDropdownValue,
      getValue: (task) => {
        const options = task[CHECKLIST_TASK_FIELDS.dropdownOptions];
        const value = task[CHECKLIST_TASK_FIELDS.dataDropdownValue];

        return {
          [CHECKLIST_TASK_FIELDS.dataDropdownValue]: value,
          [CHECKLIST_TASK_FIELDS.dropdownOptions]: options,
        };
      },
      getDefaultValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dropdownOptions]:
          task[CHECKLIST_TASK_FIELDS.dropdownOptions],
      }),
      isFulfilled: (task) => {
        const value = task[CHECKLIST_TASK_FIELDS.dataDropdownValue];
        if (value) {
          return value.length >= 1;
        }
        return false;
      },
    },
  },

  numerical: {
    component: NumericalType,
    label: 'Numeric',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataNumerical,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataNumerical]: !Number.isNaN(
          parseFloat(task[CHECKLIST_TASK_FIELDS.dataNumerical]),
        )
          ? parseFloat(task[CHECKLIST_TASK_FIELDS.dataNumerical])
          : null,
      }),
      isFulfilled: (task) =>
        !!task[CHECKLIST_TASK_FIELDS.dataNumerical] ||
        String(task[CHECKLIST_TASK_FIELDS.dataNumerical]) === '0',
    },
  },

  passFail: {
    component: PassFailType,
    label: 'Pass | Fail',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataPassFail,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataPassFail]:
          task[CHECKLIST_TASK_FIELDS.dataPassFail],
      }),
      isFulfilled: (task) => {
        const value = task[CHECKLIST_TASK_FIELDS.dataPassFail];
        return ['PASSED', 'ERROR', 'NOT_APPLICABLE'].includes(value);
      },
    },
  },

  yesNo: {
    component: YesNoType,
    label: 'Yes | No',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataYesNo,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataYesNo]:
          task[CHECKLIST_TASK_FIELDS.dataYesNo],
      }),
      isFulfilled: (task) => {
        const value = task[CHECKLIST_TASK_FIELDS.dataYesNo];
        return ['YES', 'NO', 'NOT_APPLICABLE'].includes(value);
      },
    },
  },

  text: {
    component: TextType,
    label: 'Text',
    getPreviousTaskValue,
    canBeConvertedInto: [CHECKLIST_CONVERSIONS.job],
    validation,
    fulfillment: {
      fulfillable: true,
      fulfillmentField: CHECKLIST_TASK_FIELDS.dataText,
      getValue: (task) => ({
        [CHECKLIST_TASK_FIELDS.dataText]: task[CHECKLIST_TASK_FIELDS.dataText],
      }),
      isFulfilled: (task) => !!task[CHECKLIST_TASK_FIELDS.dataText],
    },
  },
};
