import React, {
  CSSProperties,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  Grid,
  List,
  RADIUS,
  Skeleton,
  SPACING,
  Spin,
  ThemeConfig,
  Typography,
} from '@optii-solutions/ui-library';
import { LeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import JobDetails from '@optii/topcat-client/components/Jobs/JobDetails';
import { JobForm } from '@optii/jobs/index';
import {
  Conversation,
  JSONValue,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';
import dayjs, { Dayjs } from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
// import VirtualList from 'rc-virtual-list'; In case we require virtualization, this would be the alternative to the InfiniteScroll
import { Session, UserAccessContext } from '@optii/shared/index';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { MessageInput } from './MessageInput';
import { ChatContext } from '../../context/chat.context';
import { MessageItem } from './Message';
import { SelectOption } from './types';
import {
  formatBody,
  generateAttributes,
  OPTII_AUTHOR_ID,
} from '../../utils/message';
import { CHANNEL_TYPES } from '../../constants';
import {
  SuggestionMutation,
  SuggestionMutationFn,
  useSuggestionMutation,
} from '../../api/chat';

type CustomValues = {
  action: string;
  jobItemId: string;
  item: string;
  amount: string;
  location: string;
  department: string;
  role: string;
  jobType: string;
};

const LIST_STYLE: CSSProperties = {
  maxHeight: '69vh',
  minHeight: '450px',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginBottom: SPACING.SIZE_MD,
  overflowAnchor: 'none',
  flexDirection: 'column-reverse',
};

const THEME: ThemeConfig = {
  components: {
    List: {
      itemPadding: String(SPACING.NONE),
    },
    Card: {
      paddingLG: SPACING.SIZE_MD,
    },
  },
};

const CARD_TITLE_THEME: ThemeConfig = {
  token: {
    fontFamily: 'Roboto',
    colorText: COLORS.neutral[9],
    fontSize: FONTS.large.size,
  },
};

type RenderItemProps = {
  item: Message;
  employees: SelectOption[];
  index: number;
  array?: Message[];
  participants: Participant[] | undefined;
  generateJob: SuggestionMutationFn;
  jobLoading: boolean;
};

function DateSeparator({ date }: { date: Dayjs }) {
  const DATE_THEME: ThemeConfig = {
    components: {
      Divider: {
        textPaddingInline: SPACING.NONE,
        margin: SPACING.NONE,
      },
      Typography: {
        fontSize: FONTS.xSmall.size,
        colorText: COLORS.neutral[9],
      },
    },
  };
  return (
    <ConfigProvider theme={DATE_THEME}>
      <Divider
        plain
        style={{
          marginBottom: SPACING.SIZE_DEFAULT,
        }}
      >
        <Flex
          style={{
            border: `1px solid ${COLORS.neutral[5]}`,
            background: COLORS.neutral[1],
            borderRadius: RADIUS.RADIUS_XL,
            padding: `${SPACING.SIZE_XS}px ${SPACING.SIZE_MS}px`,
          }}
        >
          <Typography.Text style={{ fontWeight: 500 }}>
            {date.format('LL')}
          </Typography.Text>
        </Flex>
      </Divider>
    </ConfigProvider>
  );
}

function RenderItem({
  item,
  employees,
  index,
  array,
  participants,
  generateJob,
  jobLoading,
}: RenderItemProps) {
  const { dateCreated, author } = item;
  const timestamp = dayjs(dateCreated);
  const todayTimestamp =
    array && dayjs(array[index - 1]?.dateCreated).format('LL');

  const authorName =
    author !== OPTII_AUTHOR_ID
      ? employees.find(({ value }) => value === author)?.label
      : 'Optii';
  return authorName && author ? (
    <Fragment key={item.sid}>
      {index === 0 || timestamp.format('LL') !== todayTimestamp ? (
        <DateSeparator date={timestamp} />
      ) : null}
      <List.Item key={item.sid}>
        <MessageItem
          key={item.sid}
          message={item}
          generateJob={generateJob}
          jobLoading={jobLoading}
          authorName={authorName}
          participants={participants}
          timestamp={timestamp}
          isOptii={author === OPTII_AUTHOR_ID}
        />
      </List.Item>
    </Fragment>
  ) : null;
}

export function MessageList() {
  const { employees, getChannelById } = useContext(ChatContext);
  const { t } = useTranslation(['chat', 'common']);
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(UserAccessContext.Context);
  const [dataSource, setDataSource] = useState<Paginator<Message>>();
  const [channel, setChannel] = useState<Conversation | undefined>(undefined);
  const [participants, setParticipants] = useState<Participant[] | undefined>(
    [],
  );
  const { globalSnack } = useContext(Session);

  const [loading, setLoading] = useState(false);
  const [customValues, setCustomValues] = useState<
    SuggestionMutation['suggestion']
  >({});
  const [openJob, setOpenJob] = useState(false);

  const [viewJob, setViewJob] = useQueryParam('openJob', StringParam);

  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();

  const history = useHistory();

  const [generateJob, { loading: jobLoading }] = useSuggestionMutation({
    onCompleted({ suggestion }) {
      if (suggestion.isJob === null) {
        setCustomValues(suggestion);
        setOpenJob(true);
      }
    },
    onError(error) {
      console.error(error);
      globalSnack({
        message: t('chat:An error occurred while generating a suggestion'),
        error: true,
        timeout: 5000,
      });
    },
  });

  async function onLoadMoreData() {
    if (!dataSource?.hasPrevPage && !dataSource?.items) return [];

    const nextPage = await dataSource.prevPage();

    setDataSource((prev) => ({
      ...nextPage,
      items: [...nextPage.items, ...(prev?.items || [])],
    }));

    return [...nextPage.items, ...dataSource.items];
  }

  const getChannel = useCallback(async () => {
    setLoading(true);

    const channelData = await getChannelById(id);

    await channelData?.setAllMessagesRead();

    setParticipants(await channelData?.getParticipants());

    setChannel(channelData);

    const messages = await channelData?.getMessages(30).finally(() => {
      setLoading(false);
    });

    if (!messages) {
      console.error(
        'Something went wrong when getting messages for this channel.',
      );
    } else setDataSource(messages);
  }, [getChannelById, id]);

  const onJobCreated = useCallback(
    async (jobId?: string) => {
      if (typeof jobId === 'string' && channel) {
        const builder = channel.prepareMessage();

        const isPrivateChannel =
          (channel?.attributes as { ChannelType: string })?.ChannelType !==
          CHANNEL_TYPES.public;
        builder.setBody(formatBody(`Added Job #${jobId}`, [], t));

        builder.setAttributes(
          generateAttributes([], isPrivateChannel, channel, user) as JSONValue,
        );
        setDataSource((prev) => {
          if (prev) {
            const updatedMessages = prev?.items.map((item, index) => {
              if (item.sid === '-99') {
                return {
                  ...item,
                  sid: `-${index}`,
                } as Message;
              }
              return item;
            });

            return {
              ...prev,
              items: updatedMessages,
            };
          }

          return prev;
        });
        await builder.buildAndSend();
      }
    },
    [channel, t, user],
  );

  useEffect(() => {
    getChannel();
  }, [getChannel, id]);

  useEffect(() => {
    if (channel && user) {
      channel.on('messageAdded', async (message) => {
        if (id === message.conversation.sid) {
          if (user.id.toString() !== message.author?.toString()) {
            setDataSource((prev) => {
              if (prev) {
                return {
                  ...prev,
                  items: prev.items.concat([message]),
                };
              }
              return prev;
            });
          }

          if (message.author?.toString() === user.id.toString()) {
            // Remove optimistic  message in favor of actual message
            setDataSource((prev) => {
              if (prev) {
                const filteredMessages = prev?.items.map((item) => {
                  if (
                    message.author === item.author &&
                    message.body === item.body &&
                    (item.index === -1 || item.index === message.index)
                  )
                    return message;

                  return item;
                });
                // If no optimistic message is present, simply concat the new message to the list.
                if (
                  !filteredMessages.some(
                    (filteredMessage) =>
                      filteredMessage.index === message.index ||
                      filteredMessage.sid === message.sid,
                  )
                ) {
                  filteredMessages.push(message);
                }

                return {
                  ...prev,
                  items: filteredMessages,
                };
              }
              return prev;
            });
          }
        }
      });
    }

    return () => {
      if (channel) {
        channel.removeAllListeners('messageAdded');
      }
    };
  }, [id, channel, user]);

  useEffect(() => {
    if (participants) {
      participants.map((participant) =>
        participant.on(
          'updated',
          ({ participant: updatedParticipant, updateReasons }) => {
            const reason = updateReasons.includes('lastReadMessageIndex');
            if (reason) {
              setParticipants((prev) =>
                prev?.map((outdatedParticipant) => {
                  if (participant.identity === outdatedParticipant.identity) {
                    return updatedParticipant;
                  }
                  return outdatedParticipant;
                }),
              );
            }
          },
        ),
      );
    }
    return () => {
      participants?.map((item) => item.removeAllListeners());
    };
  }, [participants]);

  const channelName = (channel?.attributes as { ChannelType: 'Public' })
    ?.ChannelType
    ? channel?.friendlyName
    : channel?.friendlyName
        ?.split('_')
        .map(
          (recipientId) =>
            employees.find((employee) => employee.value === recipientId)?.label,
        )
        .join(', ');

  return (
    <>
      <ConfigProvider theme={THEME}>
        <Card
          style={{
            marginRight: SPACING.SIZE_MD,
            marginBottom: SPACING.SIZE_MD,
          }}
          title={
            <ConfigProvider theme={CARD_TITLE_THEME}>
              <Flex align="center" gap={SPACING.SIZE_MS}>
                {xs ? (
                  <Button
                    icon={<LeftOutlined />}
                    ghost
                    onClick={() => history.push('/chat')}
                    type="text"
                  />
                ) : null}
                <Skeleton
                  loading={loading}
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    style: {
                      fontSize: FONTS.large.size,
                      lineHeight: FONTS.large.lineHeight,
                      width: 300,
                    },
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {channelName}
                  </Typography.Text>
                </Skeleton>
              </Flex>
            </ConfigProvider>
          }
        >
          <Flex vertical style={LIST_STYLE} id="scroll">
            {loading ? (
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            ) : (
              <InfiniteScroll
                next={onLoadMoreData}
                hasMore={dataSource?.hasPrevPage || false}
                key="sid"
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                dataLength={dataSource?.items.length || 0}
                scrollableTarget="scroll"
                pullDownToRefreshThreshold={100}
                initialScrollY={700}
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
                inverse
              >
                <List
                  style={{
                    overflow: 'hidden',
                  }}
                  locale={{
                    emptyText: 'No messages yet, please add a message.',
                  }}
                  itemLayout="horizontal"
                  dataSource={dataSource?.items}
                  rowKey="sid"
                  renderItem={(item, index) =>
                    RenderItem({
                      item,
                      employees,
                      index,
                      array: dataSource?.items,
                      participants,
                      generateJob,
                      jobLoading,
                    })
                  }
                  split={false}
                  id="list"
                />
              </InfiniteScroll>
            )}
          </Flex>
          <MessageInput channel={channel} setDataSource={setDataSource} />
        </Card>
      </ConfigProvider>
      <JobForm
        open={openJob}
        mode="add"
        customValues={customValues as CustomValues}
        onClose={async (jobId) => {
          setOpenJob(false);
          if (typeof jobId === 'string') onJobCreated(jobId);
        }}
      />
      {viewJob ? (
        <JobDetails
          onClose={() => setViewJob(undefined)}
          legacyLogs={[]}
          open={!!viewJob}
        />
      ) : null}
    </>
  );
}
