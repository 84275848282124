import {
  TreeSelect as AntTreeSelect,
  ConfigProvider,
  Empty,
  ThemeConfig,
  TreeSelectProps,
  Typography,
} from 'antd';

import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { BaseOptionType } from 'antd/es/select';
import React, { SetStateAction, useCallback } from 'react';
import { TREE_SELECT_THEME } from '../../utils/themes';
import { Tag } from '../../..';

const { SHOW_PARENT } = AntTreeSelect;

export type Option = {
  title: string;
  value: string;
  id: string;
  pId: number | string | null;
  name?: string;
  longDisplayName?: string;
  shortDisplayName?: string;
  parentName?: string;
  locationTypeCode?: string;
  locationTypeId?: number | string | null;
  locationTypeName?: string;
  roomTypeId?: number | string | null;
  roomTypeName?: string;
  [key: string]: any;
};

type Props = TreeSelectProps & {
  setValue?: React.Dispatch<SetStateAction<Option[] | Option | object>>;
  value?: Option[] | Option | object;
  theme?: ThemeConfig;
  v2?: boolean;
};

type V1Props = TreeSelectProps & {
  v2?: boolean;
  setValue: React.Dispatch<SetStateAction<Option[] | Option | object>>;
  value: Option[] | Option | object;
  theme?: ThemeConfig;
};

const countAllChildren = (id: string, tree: Option[]): number => {
  const firstParentId = id;
  const findChildren = (parentId: string): number => {
    let count = 0; // Start with 1 to include the parent node itself

    for (const node of tree) {
      if (node.pId === parentId) {
        count +=
          Number(node.value + 0.1) === Number(node.pId) &&
          Number(node.pId) !== Number(firstParentId)
            ? 0
            : 1;
        count += findChildren(node.id); // Recursively count all its children
      }
    }
    return count;
  };

  // Find the node with the given id
  const rootNode = tree.find((node) => node.id === id);

  // If the node doesn't exist or has no children, return 1 (only the parent node)
  if (!rootNode || !tree.some((node) => node.pId === id)) {
    return 0;
  }

  // Recursively count all the children
  return findChildren(id);
};

const searchTreeNode = (treeNode: Option, input: string) => {
  const propertiesToSearch = [
    'title',
    'longDisplayName',
    'shortDisplayName',
    'parentName',
    'locationTypeCode',
    'locationTypeName',
    'roomTypeName',
  ];

  const lowerCaseInput = input.toLocaleLowerCase();

  for (const property of propertiesToSearch) {
    if (`${treeNode[property]}`.toLocaleLowerCase().includes(lowerCaseInput)) {
      return true;
    }
  }

  return false;
};

const { Text } = Typography;

function TreeSelectV1(props: V1Props) {
  const {
    placeholder = 'Please select',
    setValue,
    value,
    theme,
    multiple,
    treeDataSimpleMode = true,
    popupMatchSelectWidth = false,
    autoClearSearchValue = false,
    allowClear = true,
    ...rest
  } = props;

  // recursive function to get all selected items
  const getFlattedOptions = useCallback((option: any) => {
    if (option?.children?.length > 0) {
      return option.children.reduce((acc: any, curr: any) => {
        return acc.concat(getFlattedOptions(curr));
      }, []);
    }
    return option;
  }, []);

  const handleOnSelect = useCallback(
    (option: BaseOptionType) => {
      if (Array.isArray(value) && multiple) {
        const newItems = getFlattedOptions(option);
        if (Array.isArray(newItems)) {
          setValue([...value, ...newItems]);
          return;
        }
        setValue([...value, newItems]);
      } else {
        setValue(option as Option);
      }
    },
    [value, setValue, getFlattedOptions, multiple],
  );

  const handleOnDeselect = useCallback(
    (option: BaseOptionType) => {
      if (Array.isArray(value) && multiple) {
        const toRemove = getFlattedOptions(option);
        const newState = value.filter((item) => {
          if (Array.isArray(toRemove))
            return !toRemove.map((item) => item.id).includes(item.id);

          return item.id !== toRemove.id;
        });

        setValue(newState);
      } else {
        setValue({});
      }
    },
    [value, setValue, getFlattedOptions, multiple],
  );

  const tagRender = (props: CustomTagProps) => {
    const { label, value: tagValue, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const selectedChildren = countAllChildren(
      tagValue,
      rest.treeData as Option[],
    );

    const pureLabel = (rest.treeData as Option[]).find(
      (item) => item.id === tagValue,
    )?.shortDisplayName;

    return (
      <Tag
        {...props}
        onMouseDown={onPreventMouseDown}
        closable={true}
        bordered={false}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px' }}
      >
        {pureLabel} {selectedChildren > 0 && <Text>({selectedChildren})</Text>}
      </Tag>
    );
  };

  return (
    <ConfigProvider theme={theme || TREE_SELECT_THEME}>
      <AntTreeSelect
        placeholder={placeholder}
        value={value}
        treeCheckable={multiple}
        showCheckedStrategy={SHOW_PARENT}
        multiple={multiple}
        treeDataSimpleMode={treeDataSimpleMode}
        popupMatchSelectWidth={popupMatchSelectWidth}
        autoClearSearchValue={autoClearSearchValue}
        allowClear={allowClear}
        notFoundContent={
          rest.notFoundContent ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'No Locations Found'}
            />
          ) : undefined
        }
        tagRender={tagRender}
        filterTreeNode={(input, treeNode) => {
          return searchTreeNode(treeNode as Option, input);
        }}
        onClear={() => {
          if (allowClear) {
            return setValue(multiple ? [] : {});
          }
          return undefined;
        }}
        style={{ width: '100%' }}
        onSelect={(_, option) => handleOnSelect(option as BaseOptionType)}
        onDeselect={(_, option) => handleOnDeselect(option as BaseOptionType)}
        {...rest}
      />
    </ConfigProvider>
  );
}

export function TreeSelect(props: Props | V1Props) {
  const {
    theme,
    autoClearSearchValue = false,
    allowClear = true,
    v2 = false,
    ...rest
  } = props;

  const countAllChildrenV2 = (id: string, tree: Option[]): number => {
    const firstParentId = id;
    const findChildren = (parentId: string): number => {
      let count = 0; // Start with 1 to include the parent node itself

      for (const node of tree) {
        if (node.pId === parentId) {
          count +=
            `0-${node.value}` === node.pId && node.pId !== firstParentId
              ? 0
              : 1;
          count += findChildren(node.id); // Recursively count all its children
        }
      }
      return count;
    };

    // Find the node with the given id
    const rootNode = tree.find((node) => node.id === id);

    // If the node doesn't exist or has no children, return 1 (only the parent node)
    if (!rootNode || !tree.some((node) => node.pId === id)) {
      return 0;
    }

    // Recursively count all the children
    return findChildren(id);
  };

  const tagRender = (props: CustomTagProps) => {
    const { value: tagValue, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const selectedChildren = countAllChildrenV2(
      tagValue,
      rest.treeData as Option[],
    );

    const pureLabel = (rest.treeData as Option[]).find(
      (item) => item.id === tagValue,
    )?.shortDisplayName;

    return (
      <Tag
        {...props}
        onMouseDown={onPreventMouseDown}
        closable={true}
        bordered={false}
        onClose={onClose}
        style={{ marginRight: 3, fontSize: '14px' }}
      >
        {pureLabel} {selectedChildren > 0 && <Text>({selectedChildren})</Text>}
      </Tag>
    );
  };

  if (!v2) {
    return <TreeSelectV1 {...(props as V1Props)} />;
  } else
    return (
      <ConfigProvider theme={theme || TREE_SELECT_THEME}>
        <AntTreeSelect
          style={{
            width: '100%',
          }}
          autoClearSearchValue={autoClearSearchValue}
          tagRender={tagRender}
          allowClear={allowClear}
          {...rest}
        />
      </ConfigProvider>
    );
}
