import { RefObject, useEffect } from 'react';

/**
 * Hook that handles clicks outside of the passed array of ref
 */
export const useOutsideClick = (
  refs: RefObject<HTMLDivElement>[],
  onClick: () => void
) => {
  function handleClickOutside(event: any) {
    const instance = refs.find(
      (ref) => !ref.current || ref.current?.contains(event.target)
    );
    if (!instance) {
      onClick();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
