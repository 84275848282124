import React from 'react';

export const JobContext = React.createContext({
  jobItems: [],
  employees: [],
  departments: [],
  roles: [],
  assets: [],
  activeLocations: [],
  activeLocationsAssetType: [],
  loadLocationListOptions: (a: any) => {},
  loadLocationAssetTypeListOptions: (a: any) => {},
  getAssetTypesByJobItem: (a: any) => {},
  jobItemHasAssociatedAssets: false,
  assetsLoading: true,
  close: (a: any) => {},
  loading: true,
});
