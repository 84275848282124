import styled from 'styled-components/macro';
import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import MultiValueLabel from './MultiValueLabel';

const Select = styled.div``;
Select.DropdownIndicator = DropdownIndicator;
Select.ClearIndicator = ClearIndicator;
Select.MultiValueLabel = MultiValueLabel;

export default Select;
