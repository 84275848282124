import Icon from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Drawer,
  Flex,
  Modal,
  Space,
  ThemeConfig,
  Typography,
} from 'antd';
import Select, { DefaultOptionType } from 'antd/es/select';
import { Close, TableIcons } from '../../icons/jobs';
import { ReactNode, useState } from 'react';

export interface DrawerButtonProps {
  theme?: ThemeConfig;
  title: string;
  type?: 'grey' | 'regular';
  cancel: string;
  submit: string;
  formId: string;
  open?: boolean;
  loading?: boolean;
  action?: string;
  disableButtons?: boolean;
  children: ReactNode;
  onEdit?: () => void;
  onClose: () => void;
  onOpen?: () => void;
  hideFooter?: boolean;
}

export function DrawerButton({
  theme,
  title = 'Drawer Button',
  cancel = 'Cancel',
  submit = 'Submit',
  formId = 'your-form-id',
  action = 'Kal vas flam',
  type = 'regular',
  loading,
  open,
  onClose,
  onEdit,
  onOpen,
  disableButtons,
  children,
  hideFooter,
}: DrawerButtonProps) {
  return (
    <div className='bibiibib'>
      {!!onOpen && (
        <Flex justify="flex-end">
          <Button onClick={onOpen} type="primary" size="large">
            {action}
          </Button>
        </Flex>
      )}
      {type === 'grey' && (
        <style>
        {`
         .button-drawer .ant-drawer-body {
          padding: 0 !important;
          background: #f9f9f9 !important;
         }
        `}
        </style>
      )}
      <Drawer
        title={
          <Typography.Title
            style={{ fontFamily: 'Montserrat', marginTop: 0 }}
            level={3}
          >
            {title}
          </Typography.Title>
        }
        closable={false}
        placement="right"
        destroyOnClose={true}
        open={open}
        width="675px"
        extra={<>
          {onEdit && <Icon onClick={onEdit} component={TableIcons.edit}  style={{ marginRight: 10 }}/>}
          <Icon onClick={onClose} component={Close} />
        </>}
        footer={
          !hideFooter ? (
          <Flex justify="flex-end" style={{ margin: '10px 0' }} gap={8}>
            <Button
              disabled={disableButtons}
              size="large"
              onClick={onClose}
              type="text"
            >
              {cancel}
            </Button>
            <Button
              disabled={disableButtons}
              size="large"
              form={formId}
              key="submit"
              htmlType="submit"
              style={{ minWidth: '104px' }}
              type="primary"
              loading={loading}
            >
              {submit}
            </Button>
          </Flex>) : null
        }
        className='button-drawer'
      >
        {children}
      </Drawer>
    </div>
  );
}

export interface MoveLocationsButtonProps {
  count: number;
  total: number;
  locations: DefaultOptionType[];
  onSelectParent: (value: Record<string, any>, moveAll: boolean) => void;
  theme?: ThemeConfig;
}

export function MoveLocationsButton({
  count,
  total,
  locations,
  onSelectParent,
  theme = {
    components: {
      Select: {
        colorBgContainer: 'white',
        multipleItemBg: undefined,
        colorTextPlaceholder: '#ccc',
      },
    },
  },
}: MoveLocationsButtonProps) {
  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<
    Record<string, any> | undefined
  >();
  const [open, setOpen] = useState(false);
  return (
    <ConfigProvider theme={theme}>
      <Flex justify="space-between">
        <Space>
          <Typography>
            {hasSelectedAll ? total : count} records on this page have been
            selected.
          </Typography>
          {!hasSelectedAll && (
            <Button type="link" onClick={() => setHasSelectedAll(true)}>
              Select all {total} records
            </Button>
          )}
          {hasSelectedAll && (
            <Button type="link" onClick={() => setHasSelectedAll(false)}>
              Deselect all {total} records
            </Button>
          )}
        </Space>
        <Button type="primary" size="large" onClick={() => setOpen(true)}>
          Move Selected
        </Button>
      </Flex>
      <Modal
        title={`Move ${hasSelectedAll ? total : count} Selected Locations?`}
        open={open}
        okText="Update"
        okType="primary"
        cancelButtonProps={{
          type: 'text',
          size: 'large',
        }}
        okButtonProps={{
          size: 'large',
          disabled: !selectedLocation,
        }}
        onCancel={() => setOpen(false)}
        onOk={() => {
          if (selectedLocation)
            onSelectParent(selectedLocation, hasSelectedAll);
          setOpen(false);
        }}
      >
        <Select
          style={{ width: '100%', margin: '20px 0' }}
          options={locations}
          size="large"
          onChange={(value) => setSelectedLocation(value)}
          getPopupContainer={(node) => node.parentNode}
          placeholder="Select Parent Location"
        />
      </Modal>
    </ConfigProvider>
  );
}
