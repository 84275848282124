import { gql } from '@apollo/client';

export default gql`
  mutation addJob($input: JobInput!) {
    addJob(input: $input) {
      id
      action
      items {
        amount
        id
        name
      }
      doBy
      status
      locations {
        name
        shortDisplayName
        longDisplayName
      }
    }
  }
`;
