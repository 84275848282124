import { ReactComponent as DeleteIcon } from '@optii/topcat-client/images/svg/delete.svg';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  gray: ({ theme }) => `
    circle {
      fill: ${theme.colors.charcoal03};
    }
  }
  `,
  small: (_) => `
    width: 1.4rem;
    height: 1.4rem;
  `,
  medium: (_) => `
    width: 1.6rem;
    height: 1.6rem;
  `,
};

const Delete = styled(DeleteIcon)`
  width: 1.8rem;
  height: 1.8rem;
  :hover {
    path {
      fill: ${(props) => props.theme.colors.rose};
    }
  }
  cursor: pointer;
  ${(props) => props.theme.animations.hoverUp()}
  ${applyStyleModifiers(CONFIG)};
`;

export default Delete;
