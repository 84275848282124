import styled from 'styled-components';
import type { CollapseProps } from 'antd';
import type { FunctionComponent } from 'react';
import { COLORS, Collapse, FONTS, SPACING } from '@optii-solutions/ui-library';

import type { TJobTasksStyles } from './JobTasks.types';

export const CustomCollapse: FunctionComponent<CollapseProps> = styled(Collapse)`
  border-radius: ${SPACING.SIZE_MD}px;

  .ant-collapse-item {
    &:first-child {
      border-top-left-radius: ${SPACING.SIZE_XS}px;
      border-top-right-radius: ${SPACING.SIZE_XS}px;
    }

    &:last-child {
      border-bottom-left-radius: ${SPACING.SIZE_XS}px;
      border-bottom-right-radius: ${SPACING.SIZE_XS}px;
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        .ant-collapse-expand-icon {
          span {
            rotate: 90deg;
          }
        }
      }
    }

    .ant-collapse-header {
      align-items: center;

      .ant-collapse-expand-icon {
        span {
          transition: rotate .26s ease-in;
        }
      }
    }
  }
`;

export const JOB_TASKS_STYLES: TJobTasksStyles = {
  checklistName: {
    margin: SPACING.NONE,
    color: COLORS.primary[4],
    fontWeight: 500,
    fontSize: FONTS.medium.size,
  },
  checklistInfo: {
    margin: SPACING.NONE,
    color: COLORS.neutral[7],
    fontSize: FONTS.small.size,
  },
  checklistItem: {
    backgroundColor: COLORS.neutral[1],
    borderColor: COLORS.neutral[4],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderCollapse: 'collapse',
    padding: SPACING.NONE,
  },
  checklistInfoRequired: {
    color: COLORS.red[5],
  },
};
