import React from 'react';
import { useTranslation } from 'react-i18next';
import { CHECKLIST_TASK_FIELDS } from 'checklists/constants';
import OptionType from './OptionType';

function OnOff(props) {
  const { t } = useTranslation(['common', 'checklist']);

  const options = [
    {
      label: t('checklist: On'),
      value: true,
    },
    {
      label: t('checklist: Off'),
      value: false,
    },
  ];

  return (
    <OptionType
      {...props}
      fieldName={CHECKLIST_TASK_FIELDS.dataOnOff}
      options={options}
      spacedOptions
    />
  );
}

export default OnOff;
