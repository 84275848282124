import { Dayjs } from 'dayjs';
import { ReactElement, ReactNode } from 'react';

export type UnreadConversations = {
  total: number;
  channels: {
    [key: string]: number;
  };
};

export type LastMessage = {
  timestamp: Dayjs;
  content: ReactNode;
};

export type Channel = {
  label: string | null;
  key: string;
  recipientIds?: string[];
  unreadMessageCount?: number;
  timestamp?: Dayjs;
  lastMessage?: LastMessage;
};

export enum MessageStatus {
  Sending = 'Sending',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Failed = 'Failed',
  None = 'none (incoming)',
  Read = 'Read',
}

type ToolbarRenderInfo = {
  transform: { scale: number };
  actions: {
    onFlipY: () => void;
    onFlipX: () => void;
    onRotateLeft: () => void;
    onRotateRight: () => void;
    onZoomOut: () => void;
    onZoomIn: () => void;
    onReset: () => void;
  };

  current: number;
};

export type ToolbarRender = {
  originalNode: ReactElement;
  info: ToolbarRenderInfo;
};

export type FileList = {
  uid: string;
  url: string;
  src: string;
  name: string;
};

export type TaggedUser = {
  label: string;
  value: string;
  key: string;
};
