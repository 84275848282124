import capacitorSpaceModifier from "utils/capacitorSpaceModifier";
import colors from "../colors";
import shadows from "../shadows";
import zIndex from "../zIndex";
import sizes from "../sizes";

export default `
    position: fixed;
    background: ${colors.white};
    width: 100%;
    ${zIndex.highest};
    ${shadows.down};
    padding-bottom: 0.5rem;
    top: ${capacitorSpaceModifier(sizes.mobileHeaderHeight)};
`;
