import React from 'react';
import styled from 'styled-components/macro';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Wrapper = styled.div`
  .react-tel-input {
    width: 100% !important;
    .selected-flag,
    .flag-dropdown {
      background: none !important;
      border: none !important;
    }
    .flag-dropdown.open {
      width: 100%;
      z-index: 101;
    }
    .country-list {
      width: 100% !important;
    }

    #mobileNum {
      border-radius: 6px;
      font-family: 'Roboto', sans-serif !important;
      ${(props) => props.theme.borders.default};
      ${(props) =>
        !props.readOnly && `color: ${props.theme.colors.charcoal05}`};
      ${(props) => props.theme.fonts.sizes.large}
      ${(props) => props.theme.fonts.weights.light}
      height: 44px;
      width: 100%;
      padding-left: 4rem;
      box-shadow: none;

      &.error {
        border-color: ${(props) => props.theme.colors.roseDark};
        border-style: solid;
      }
      ${(props) =>
        props.error &&
        (props.isFormik || props.blurred || props.focused) &&
        `
          border-color: ${props.theme.colors.roseDark} !important;
        `}
      :focus {
        ${(props) => props.theme.borders.focus}
      }
      :-webkit-autofill {
        -webkit-box-shadow: 0 0 0 100px white inset;
        -webkit-text-fill-color: ${(props) => props.theme.colors.charcoal05};
      }
      :disabled {
        opacity: 1;
      }
    }
  }
  &.read-only {
    .react-tel-input {
      pointer-events: none;
      #mobileNum {
        border: none;
        box-shadow: none;
      }
      .arrow {
        display: none;
      }
      .selected-flag {
        padding-left: 1.5rem;
        cursor: default;
      }
    }
  }
`;

function PhoneInputComp(props) {
  const { onChange, readOnly, error, value, name } = props;

  function handleChange(formattedValue) {
    onChange({
      target: {
        name,
        value: formattedValue,
      },
    });
  }

  return (
    <Wrapper className={readOnly ? 'read-only' : ''}>
      <PhoneInput
        {...props}
        country="us"
        inputProps={{
          id: 'mobileNum',
          className: error ? 'error' : '',
          name,
        }}
        placeholder=""
        prefix="+"
        value={value}
        onChange={(_value, country, e, formattedValue) =>
          handleChange(formattedValue)
        }
        // autoFormat={false} // uncomment to remove all spaces in phone
      />
    </Wrapper>
  );
}
export default PhoneInputComp;
