import styled from 'styled-components/macro';
import { ReactComponent as Icon } from 'images/svg/priority/highest.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  completed: ({ theme }) => `
    .fill-color {
      fill: ${theme.colors.charcoal03}
    }
  `,
};

const Highest = styled(Icon)`
  ${applyStyleModifiers(CONFIG)};
`;
export default Highest;
