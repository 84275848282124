import { gql } from "@apollo/client";

export default gql`
  query GetJobAuditList($id: ID!) {
    audit: GetJobAuditList(id: $id) {
      id
      itemId
      employee
      object
      action
      details
      createdAt
    }
  }
`;
