import React, { useEffect, useContext } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { GET_ASSET_BY_ID } from 'queries';
import { AssetsContext } from 'components/Settings/AssetsSettings/contexts';
import { AssetView } from 'components/Settings/AssetsSettings/AssetView';
import { AssetTypesForm } from 'components/Settings/AssetsSettings/AssetTypes/AssetTypesForm';
import { AssetForm } from 'components/Settings/AssetsSettings/Assets/AssetForm';
import { useQuery } from '@apollo/client';

function AssetsModals() {
  const {
    isNewAssetType,
    setNewAssetType,
    openAssetView,
    setOpenAssetView,
    setViewAsset,
    isNewAsset,
    editAsset,
    setEditAsset,
    setNewAsset,
  } = useContext(AssetsContext);

  const [query, setQuery] = useQueryParams({
    assetId: StringParam,
  });

  useEffect(() => {
    if (query?.assetId && query?.assetId !== 0) {
      setOpenAssetView(true);
    } else {
      setOpenAssetView(false);
    }
  }, [query?.assetId, setOpenAssetView]);

  useQuery(GET_ASSET_BY_ID, {
    context: { _instance: 'node' },
    variables: { assetId: query?.assetId },
    skip: !query?.assetId,
    onCompleted: (data) => {
      setViewAsset(data.asset);
    },
  });

  return (
    <>
      {isNewAssetType && (
        <AssetTypesForm
          handleClose={async () => {
            setNewAssetType(false);
          }}
        />
      )}

      <AssetView
        open={openAssetView}
        handleClose={() => {
          setOpenAssetView(false);
          setViewAsset(null);
          setQuery({ assetId: undefined });
        }}
        handleHide={() => {
          setOpenAssetView(false);
        }}
      />

      {(isNewAsset || (editAsset && editAsset.id !== 0)) && (
        <AssetForm
          handleClose={async () => {
            setEditAsset(null);
            setNewAsset(false);
          }}
        />
      )}
    </>
  );
}

export default AssetsModals;
