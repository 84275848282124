import { COLORS, SPACING } from '@optii-solutions/ui-library';


import { Confirmation } from '@optii/shared';

import type {
  TCancelJobConfirmation,
  TJobDetailsCardCustomStyles,
  TJobDetailsStyles,
} from './JobDetails.types';

export const JOB_DETAILS_STYLES: TJobDetailsStyles = {
  row: {
    margin: SPACING.NONE,
    padding: SPACING.NONE,
  },
  drawerBody: {
    backgroundColor: COLORS.neutral[3],
    padding: SPACING.SIZE_XL,
    paddingBottom: SPACING.NONE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: SPACING.SIZE_MS,
  },
};

export function JobDetailsCustomStyles(): TJobDetailsCardCustomStyles<
  typeof JOB_DETAILS_STYLES
> {
  return JOB_DETAILS_STYLES;
}

export function CancelJobConfirmation({
  hide,
  onCancelJob,
  values,
  t,
}: TCancelJobConfirmation<{ displayName?: string }>) {
  const TEXTS = {
    title: t('jobs:Confirmation'),
    action: t('common:Yes, Cancel'),
    description: t('jobs:Are you sure you want to cancel this job?'),
  };

  return (
    <Confirmation
      title={TEXTS.title}
      hide={() => hide()}
      action={TEXTS.action}
      confirmText={TEXTS.action}
      onConfirm={async () => {
        await onCancelJob(values);
      }}
      onHide={() => hide()}
      modifiers={['fullWidthMobile']}
      isSubModal
    >
      <p>{TEXTS.description}</p>
    </Confirmation>
  );
}
