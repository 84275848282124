import { COLORS, SPACING } from '@optii-solutions/ui-library';
import { CSSProperties } from 'react';

type Styles = {
  criteriaContainer: CSSProperties;
  actionFlexContainer: CSSProperties;
};
export const STYLES: Styles = {
  criteriaContainer: {
    marginBottom: SPACING.SIZE_XL,
    paddingLeft: SPACING.SIZE_L,
    borderLeft: `1px solid ${COLORS.secondary[5]}`,
  },
  actionFlexContainer: {
    marginBottom: SPACING.SIZE_MS,
    marginTop: SPACING.SIZE_MD,
  },
};
