import { ReactComponent as TrashIcon } from 'images/svg/trash.svg';
import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  gray: ({ theme }: any) => `
    circle {
      fill: ${theme.colors.charcoal03};
    }
  }
  `,
  small: () => `
    width: 1.4rem;
    height: 1.4rem;
  `,
  medium: () => `
    width: 1.6rem;
    height: 1.6rem;
  `,
    big: () => `
    width: 2.2rem;
    height: 2.2rem;
  `,
};

const TrashDelete = styled(TrashIcon)`
  width: 1.8rem;
  height: 1.8rem;
  :hover {
    path {
      fill: ${(props) => props.theme.colors.rose};
    }
  }
  cursor: pointer;
  ${(props) => props.theme.animations.hoverUp()}
  ${applyStyleModifiers(CONFIG)};
`;

export default TrashDelete;
