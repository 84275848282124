import styled from 'styled-components/macro';
import { ReactComponent as EditIcon } from 'images/svg/editnew.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {
  big: (_) => `
    width: 2.2rem;
    height: 2.2rem;
  `,
  v2: (_) => `
    width: 2.2rem;
    height: 2.2rem;
    background: #f00;
  `,
  medium: (_) => `
    width: 1.8rem;
    height: 1.8rem;
  `,
  right: ({ theme }) => `
    margin-left: auto
  `,
  largeMobile: ({ theme }) => `
    @media (max-width: ${theme.breakpoints.miniLg}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
};

const Edit = styled(EditIcon)`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  :hover,
  :active {
    g {
      fill: ${(props) => props.theme.colors.tealMed};
    }
  }
  ${({ active, theme }) =>
    active &&
    `g {
      fill: ${theme.colors.tealMed};
    }`}
  ${(props) => props.theme.animations.hoverUp()}
  ${applyStyleModifiers(CONFIG)};
`;

export default Edit;
