import { gql } from "@apollo/client";

export default gql`
  mutation CreateTwilioToken($input: CreateTwilioTokenInput!) {
    createTwilioToken(createTwilioTokenInput: $input) {
      identity
      token
    }
  }
`;
