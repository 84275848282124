import styled from 'styled-components/macro';
import { ReactComponent as Icon } from 'images/svg/download.svg';
import { applyStyleModifiers } from 'styled-components-modifiers';

const CONFIG = {};

const Download = styled(Icon)`
  ${applyStyleModifiers(CONFIG)};
`;
export default Download;
