import { gql } from '@apollo/client';

export const GET_ASSET_BY_ID = gql`
  query Asset($assetId: ID!) {
    asset(id: $assetId) {
      id
      displayName
      serialNumber
      notes
      status
      installationDate
      location {
        id
        displayName
        shortDisplayName
        longDisplayName
        title: shortDisplayName
        value: id
      }
      assetType {
        id
        displayName
        modelName
        jobItem {
          id
          displayName
        }
        brand {
          id
          displayName
        }
        serviceCycle {
          type
          value
          displayName
        }
        lifeCycle {
          number
          lifeCyclePeriodType
        }
        note
        description
      }
      purchase {
        id
        date
        price
        orderNumber
        invoiceNumber
      }
      vendor {
        id
        displayName
        website
        address
        phone
      }
      warranty {
        number
        warrantyPeriodType
        description
      }
      documents {
        uuid
      }
      version
      __typename
    }
  }
`;
