import styled, { css } from 'styled-components/macro';
import React, { useCallback } from 'react';
import SelectBox from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import SelectBlock from 'blocks/Select';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { capitalizeEachWord } from 'utils/formatters/jobs';
import { useTranslation } from 'react-i18next';

const MODIFIER_CONFIG = {
  mauveColored: ({ theme, colors }) => `
    height: 3rem !important;
    line-height: 3rem;
    margin: 0 !important;
  & .react-select__control {
    height: 3rem;
    line-height: 1.3rem;
    background: ${colors ? colors.light : theme.colors.mauveMedium};
    &--is-focused .react-select__indicators {
      background: ${colors ? colors.medium : theme.colors.mauve};
    }
  }
  & .react-select__placeholder, .react-select__single-value {
    ${theme.fonts.sizes.small};
    ${theme.fonts.weights.medium};
    ${theme.fonts.type.primary};
    color: ${theme.colors.white} !important;
  }
  .react-select__menu {
    width: 100%;
    max-width: 100%;
    ${(props) => props.theme.zIndex.prettyHigh}
  }
  & .react-select__indicators {
    background: ${colors ? colors.medium : theme.colors.mauveDark};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:hover {
      background: ${colors ? colors.medium : theme.colors.mauve};
    }
  }
  .react-select__control > div {
    padding: 2px 8px;
  }
  .react-select__control div {
    display: flex;
    flex: none ;
    align-items:center;
    justify-content: center;
    white-space: nowrap;
    
  }
  `,

  persianColored: ({ theme, colors }) => `
    height: 3rem !important;
    line-height: 3rem;
    margin: 0 !important;
  & .react-select__control {
    height: 3rem;
    line-height: 1.3rem;
    background: ${colors ? colors.light : theme.colors.persianGreenLight};
    &--is-focused .react-select__indicators {
      background: ${colors ? colors.medium : theme.colors.persianGreen};
    }
  }
  & .react-select__placeholder, .react-select__single-value {
    ${theme.fonts.sizes.small};
    ${theme.fonts.weights.medium};
    ${theme.fonts.type.primary};
    color: ${theme.colors.white} !important;
  }
  .react-select__menu {
    width: 100%;
    max-width: 100%;
    ${(props) => props.theme.zIndex.prettyHigh}
  }
  & .react-select__indicators {
    background: ${colors ? colors.medium : theme.colors.persianGreenMed};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    &:hover {
      background: ${colors ? colors.medium : theme.colors.persianGreen};
    }
  }
  `,

  smallWidth: () => `
  min-width: 7.5rem;
  `,
  mediumWidth: () => `
  min-width: 9rem;
  `,
};

const stylesForSelect = css`
  ${(props) => props.theme.fonts.weights.light};
  border-radius: 8px;
  text-transform: capitalize;
  &.read-only {
    & .react-select__control {
      background: ${(props) => props.theme.colors.white};
      border: none;
      .react-select__indicators {
        display: none;
      }
    }
  }
  &.error {
    .react-select__control {
      ${(props) =>
        props.blurred && `border-color: ${props.theme.colors.roseDark}`}
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    margin-top: 0.1rem;
    ${(props) => props.theme.zIndex.prettyHigh}
  }
  .react-select__input {
    font-size: 16px;
  }
  .react-select__control {
    min-height: 32px;
    height: 100%;
    padding-right: 1rem;
    cursor: pointer;
  }
  .react-select__control > div {
    flex-wrap: nowrap;
  }
  .react-select__indicators {
    min-height: ${(props) => (props.wideValue ? '4.2rem' : 'auto')};
  }
  .react-select__control--is-focused {
    box-shadow: none;
    ${(props) => props.theme.borders.focus}
    :hover {
      ${(props) => props.theme.borders.focus}
    }
  }
  .react-select__option--is-selected {
    color: ${(props) => props.theme.colors.charcoal05};
    background: ${(props) => props.theme.colors.tealLight};
  }
  .react-select__option--is-focused {
    background: ${(props) => props.theme.colors.tealLight};
  }
  .react-select__multi-value {
    width: 48%;
    max-width: 10rem;
    margin-left: 0;
  }
`;

const StyledCreatable = styled(CreatableSelect)`
  ${(props) => props.theme.fonts.weights.light};
  border-radius: 8px;
  text-transform: capitalize;
  &.read-only {
    & .react-select__control {
      background: ${(props) => props.theme.colors.white};
      border: none;
      .react-select__indicators {
        display: none;
      }
    }
  }
  &.error {
    .react-select__control {
      ${(props) =>
        props.blurred && `border-color: ${props.theme.colors.roseDark}`}
    }
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__menu {
    margin-top: 0.1rem;
    ${(props) => props.theme.zIndex.prettyHigh}
  }
  .react-select__control {
    min-height: 32px;
    height: 100%;
    padding-right: 1rem;
    cursor: pointer;
  }
  .react-select__indicators {
    height: 100%;
  }
  .react-select__control--is-focused {
    box-shadow: none;
    ${(props) => props.theme.borders.focus}
    :hover {
      ${(props) => props.theme.borders.focus}
    }
  }
  .react-select__option--is-selected {
    color: ${(props) => props.theme.colors.charcoal05};
    background: ${(props) => props.theme.colors.tealLight};
  }
  .react-select__option--is-focused {
    background: ${(props) => props.theme.colors.tealLight};
  }
`;

const StyledSelectWithArrows = styled(SelectBox)`
  min-width: 15rem;
  height: 2.5rem !important;
  margin-right: 1rem;
  ${(props) => props.theme.fonts.weights.light};
  text-transform: capitalize;
  .react-select__placeholder {
    ${(props) => props.theme.fonts.weights.medium};
    color: ${(props) => props.theme.colors.charcoal05};
    font-size: 1.3rem;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    ${(props) => props.theme.zIndex.prettyHigh};
    ${(props) => props.theme.fonts.sizes.large};
    max-width: max-content;
  }

  .react-select__control {
    border: none;
    background-color: ${(props) => props.theme.colors.platinum};
    height: 2.5rem;
    min-height: 2.5rem;
    cursor: pointer;
    margin-right: 0;
    display: flex;
    flex-wrap: nowrap;
    ${(props) =>
      !props.additionalPlaceholder &&
      css`
        div {
          /*for typing in isSearchable input*/
          color: ${props.theme.colors.white};
          ${props.theme.fonts.weights.medium}
        }
      `}
  }
  .react-select__indicators {
    padding: 0 0.7rem;
    &:hover {
      background-color: ${(props) => props.theme.colors.charcoal03};
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }
  .react-select__control--is-focused {
    box-shadow: none !important;
    .react-select__indicators {
      background-color: ${(props) => props.theme.colors.charcoal03};
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.4rem;
    }
  }

  .react-select__multi-value__remove {
    margin-left: 0.5rem;
    &:hover {
      background: none;
      color: ${(props) => props.theme.colors.white};
    }
  }
  .react-select__option--is-selected {
    color: ${(props) => props.theme.colors.charcoal05};
    background: ${(props) => props.theme.colors.tealLight};
  }

  .react-select__multi-value {
    min-width: auto;
    max-width: 15rem;
    width: auto;
    height: 2.5rem;
    margin: 0;
    border-radius: 0;
    .react-select__multi-value__label {
      align-self: center;
      font-size: 1.3rem !important;
      ${(props) => props.theme.fonts.weights.medium};
      color: ${(props) => props.theme.colors.charcoal05} !important;
      line-height: 2.5rem;
      margin: 0;
      padding: 0 0 0 0.5rem;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.charcoal04};
      color: ${(props) => props.theme.colors.white};
      .react-select__multi-value__label {
        color: ${(props) => props.theme.colors.white} !important;
      }
    }
  }
  .react-select__value-container--is-multi {
    .react-select__input {
      color: rgb(89, 89, 89) !important;
      font-weight: 400 !important;
    }

    .react-select__placeholder {
      position: relative;
    }
  }

  .react-select__value-container--has-value {
    height: 100%;
    font-size: 1.3rem;
    flex-wrap: nowrap;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    ${(props) =>
      props.additionalPlaceholder &&
      css`
        &:before {
          position: relative;
          content: '${props.additionalPlaceholder}';
          ${props.theme.fonts.weights.medium};
          color: ${props.theme.colors.charcoal05};
          font-size: 1.3rem;
          margin: 0;
          margin-right: 0.4rem;
          border-right: 1px solid ${props.theme.colors.charcoal03};
          min-width: 10rem;
          padding-right: 0.3rem;
        }
        .react-select__single-value {
          position: relative;
          font-size: 1.3rem !important;
          bottom: 0.2rem;
          ${props.theme.fonts.weights.medium}
        }
      `}

    align-self: baseline;
    & .react-select__input {
      position: relative;
    }
    .react-select__single-value {
      ${(props) => props.theme.fonts.weights.medium};
      color: ${(props) => props.theme.colors.charcoal05};
    }
    .react-select__indicators {
      svg {
        padding-left: 0.7rem;
      }
    }
  }
  .react-select__input-container {
    padding-bottom: 0.3rem;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)}
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${stylesForSelect}
`;
const StyledSelect = styled(SelectBox)`
  ${stylesForSelect}
`;

function Select(props) {
  const {
    name,
    onChange,
    value,
    modifiers,
    styles,
    creatable,
    components: propComponents,
    readOnly,
    error,
    placeholder,
    isAsyncSelect,
    handleInputChange,
    loadOptions,
    noInputCall,
    defaultOptions,
    isMulti,
    isGreyFilterWithArrows,
    loading,
    isDisabled,
  } = props;
  const { t } = useTranslation(['fields', 'common']);

  function defaltGetOptionValue(option) {
    return option.id;
  }
  function defaultGetOptionLabel(option) {
    if (option.displayName) {
      return option.displayName;
    }
    return option.name;
  }

  const {
    options,
    getOptionValue = defaltGetOptionValue,
    getOptionLabel = defaultGetOptionLabel,
  } = props;

  function onChangeValue(eventValue, action) {
    onChange({ target: { value: eventValue, name } }, action);
  }
  function getNewOptionData(input, label) {
    return {
      id: input,
      displayName: label,
      __isNew__: true,
    };
  }

  const containerHeight = useCallback(
    (state) => {
      if (state.isMulti && value && value.length > 2) {
        return '100%';
      }
      if (modifiers && modifiers.indexOf('smallHeight') !== -1) {
        return '3.1rem';
      }
      return '100%';
    },
    [modifiers, value],
  );

  const customStyles = styles || {};
  const styleModifiers = {
    input: (base) => ({
      ...base,
      paddingBottom: '0px',
    }),
    multiValue: (styleValues, state) => ({
      ...styleValues,
      width: '9.7rem',
    }),
    multiValueRemove: (styleValues, state) => ({
      ...styleValues,
      marginLeft: 'auto',
      marginRight: '0',
    }),
    valueContainer: (styleValues, state) => ({
      ...styleValues,
      paddingTop: '0.5rem',
      paddingRight: '0',
      display: 'flex',
    }),
    container: (styleValues, state) => ({
      ...styleValues,
      height: containerHeight(state),
    }),
    ...customStyles,
  };
  const customComponents = propComponents || {};
  const components = {
    ClearIndicator: SelectBlock.ClearIndicator,
    DropdownIndicator: SelectBlock.DropdownIndicator,
    ...customComponents,
  };

  if (creatable) {
    const shouldShowCreateOption = (val) =>
      val.trim().length > 0 &&
      options.every(
        (option) =>
          capitalizeEachWord(option.displayName.toString()) !==
          capitalizeEachWord(val.trim().toString()),
      );
    return (
      <StyledCreatable
        inputId={name}
        {...props}
        components={components}
        value={value}
        createOptionPosition="first"
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getNewOptionData={getNewOptionData}
        name={name}
        isValidNewOption={(val) => shouldShowCreateOption(val)}
        onChange={onChangeValue}
        options={options}
        className={`react-select ${name} ${readOnly ? 'read-only' : ''} ${
          error ? 'error' : ''
        }`}
        classNamePrefix="react-select"
        placeholder={
          readOnly ? t('fields:None') : placeholder || t('fields:Select...')
        }
        styles={styleModifiers}
        isDisabled={readOnly}
        formatCreateLabel={(inputText) =>
          `${t('fields:Create')} "${inputText}"`
        }
      />
    );
  }
  if (isAsyncSelect) {
    return (
      <StyledAsyncSelect
        inputId={name}
        {...props}
        components={components}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        name={name}
        onChange={onChangeValue}
        handleInputChange={handleInputChange}
        loadOptions={loadOptions}
        onInputChange={(inputValue) => {
          // Since loadOptions doesn't fire on an empty inputValue, handle that case here
          if (!inputValue && loadOptions && !noInputCall) {
            loadOptions(inputValue);
          }
        }}
        defaultOptions={defaultOptions}
        className={`react-select ${name} ${readOnly ? 'read-only' : ''} ${
          error ? 'error' : ''
        }`}
        classNamePrefix="react-select"
        placeholder={
          readOnly ? t('fields:None') : placeholder || t('fields:Select...')
        }
        styles={styleModifiers}
        isDisabled={readOnly}
        isMulti={isMulti}
      />
    );
  }

  if (isGreyFilterWithArrows) {
    return (
      <StyledSelectWithArrows
        inputId={name}
        {...props}
        components={components}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        name={name}
        onChange={onChangeValue}
        options={options}
        className={`react-select ${name} ${readOnly ? 'read-only' : ''} ${
          error ? 'error' : ''
        }`}
        classNamePrefix="react-select"
        placeholder={
          readOnly ? t('fields:None') : placeholder || t('fields:Select...')
        }
        styles={styleModifiers}
        isDisabled={readOnly}
        noOptionsMessage={() =>
          `${loading ? t('common:Loading...') : t('fields:No Options')}`
        }
      />
    );
  }

  return (
    <StyledSelect
      inputId={name}
      {...props}
      components={components}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      name={name}
      onChange={onChangeValue}
      options={options}
      className={`react-select ${name} ${readOnly ? 'read-only' : ''} ${
        error ? 'error' : ''
      }`}
      classNamePrefix="react-select"
      placeholder={
        readOnly ? t('fields:None') : placeholder || t('fields:Select...')
      }
      styles={styleModifiers}
      isDisabled={readOnly || isDisabled}
      noOptionsMessage={() =>
        `${loading ? t('common:Loading...') : t('fields:No Options')}`
      }
    />
  );
}
export default Select;
