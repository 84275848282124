import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Spin, Tooltip } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import {
  PropertyContext,
  UserAccessContext,
  usePropertyTime,
  useProductAccess,
  RepeatingContext,
} from '@optii/shared';
import { PREDICT_JOB_DURATION } from '@optii/shared/queries/job';
import { InfoCircle } from '@optii/shared/components/atoms/Icons/InfoCircle';
import { Values } from '@optii/shared/contexts/RepeatingContext';

import { PRODUCT_ACCESS, FREQUENCY_TYPES } from './PredictiveDueTime.constants';
import { SuggestedTime, SuggestedWrapper } from './PredictiveDueTime.styles';

type PredictiveDueTimeProps = {
  absolutePosition?: boolean;
  propertyShard?: string;
  margin?: string;
  data: Values;
  setEditFieldValue?: (field: string, value: string | number) => void;
  handleCalcSuggestion?: (value: string | number) => void;
  handleSuggestionClick?: (value: string | number) => void;
  isNewForm?: boolean;
};

interface JobDurationInput {
  propertyId?: number;
  jobType?: string;
  jobPriority?: string;
  jobItem?: string;
  jobAction?: string;
  jobStatus?: string;
  reporterId?: number;
  reporterName?: string;
  assigneeId?: number;
  assigneeName?: string;
  roleId?: number;
  roleName?: string;
  departmentId?: number;
  departmentName?: string;
  createdAt?: string;
  attachmentCount?: number;
  noteCount?: number;
  displayJob?: string;
  displayLocations?: string;
  timeZone?: string;
  source?: string;
  locationIds?: number[];
  locations?: { id: number; name: string }[];
}

export const PredictiveDueTime: React.FC<PredictiveDueTimeProps> = ({
  absolutePosition,
  margin,
  data,
  setEditFieldValue,
  handleCalcSuggestion,
  handleSuggestionClick,
  isNewForm,
  propertyShard,
}) => {
  const [predictJobDuration, { data: predictData, loading }] = useMutation(
    PREDICT_JOB_DURATION,
    { context: { _instance: 'node', _shard: propertyShard } },
  );
  const { setFieldValue } = useContext(RepeatingContext);
  const { property }: any = useContext(PropertyContext.Context);
  const { user }: any = useContext(UserAccessContext.Context);
  const { canProduct } = useProductAccess();
  const [previousNotesLength, setPreviousNotesLength] = useState<number>(0);
  const { toPropertyTime, timezone } = usePropertyTime();
  const input: JobDurationInput = useMemo(
    () => ({
      propertyId: parseInt(property?.id),
      jobType: data?.type,
      jobPriority: data?.priority?.id,
      jobItem: data?.items?.displayName,
      locations: data?.location?.map((location) => ({
        id: parseInt(location?.id),
        name: location?.displayName,
      })),
      jobAction: data?.action,
      reporterId: parseInt(user.id),
      reporterName: `${user?.firstName} ${user?.lastName}`,
      assigneeId:
        typeof data?.assignee?.id === 'string'
          ? parseInt(data?.assignee?.id)
          : data?.assignee?.id,
      assigneeName: data?.assignee?.displayName,
      roleId: parseInt(data?.role?.id),
      roleName: data?.role?.displayName,
      departmentId: parseInt(data?.department?.id),
      departmentName: data?.department?.displayName,
      attachmentCount: data?.attachments?.length,
      noteCount: data?.notes?.length ? 1 : 0,
      displayJob: data?.items?.displayName,
      timeZone: timezone,
      source: FREQUENCY_TYPES.DOES_NOT_REPEAT
        ? 'adhoc-jobs-mgr'
        : 'repeating-jobs-svc',
    }),
    [property, data, user],
  );

  const setPredictJobDuration = useCallback(async () => {
    try {
      await predictJobDuration({
        variables: { input },

        onCompleted(response) {
          if (typeof handleCalcSuggestion === 'function') {
            handleCalcSuggestion(
              response.predictJobDuration.jobDuration +
                response.predictJobDuration.waitTime,
            );
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  }, [input, predictJobDuration, handleCalcSuggestion]);

  // Memoize the debounced function
  const debouncePredictJobDuration = useMemo(
    () => _.debounce(setPredictJobDuration, 500),
    [setPredictJobDuration, predictData],
  );
  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  // In your component
  const prevInput = usePrevious(input);

  useEffect(() => {
    const currentNotesLength = data?.notes?.length || 0;
    const isFirstInputOrCleared =
      (previousNotesLength === 0 && currentNotesLength > 0) ||
      (previousNotesLength > 0 && currentNotesLength === 0);

    // Check if 'input' object has changed
    const hasInputChanged = !_.isEqual(input, prevInput);

    if (isFirstInputOrCleared || hasInputChanged) {
      debouncePredictJobDuration();
    }

    setPreviousNotesLength(currentNotesLength);
  }, [input, debouncePredictJobDuration, data?.notes, prevInput]);

  const handleOnSuggestionClick = useCallback(() => {
    if (typeof handleSuggestionClick === 'function' && predictData) {
      const time =
        predictData.predictJobDuration.jobDuration +
        predictData.predictJobDuration.waitTime;
      handleSuggestionClick(time);
    }

    // Define a common value for due time
    const dueTime = toPropertyTime(
      moment(predictData?.predictJobDuration?.dueTime).unix(),
    );
    // Set due time field based on the prediction data
    const newDueByTime = moment(dueTime);
    setFieldValue && setFieldValue('jobTemplate.dueByTime', newDueByTime);
    setFieldValue && setFieldValue('doBy', newDueByTime);

    // Set additional fields if required
    if (setEditFieldValue) {
      setEditFieldValue && setEditFieldValue('doBy', newDueByTime);
      setEditFieldValue && setEditFieldValue('predictiveDueTime', dueTime);
    } else {
      setFieldValue && setFieldValue('predictiveDueTime', dueTime);
    }
  }, [predictData, setEditFieldValue, setFieldValue]);

  const momentSuggestedTime = toPropertyTime(
    moment(predictData?.predictJobDuration?.dueTime).unix().valueOf(),
  ).format('LTS');

  const dayjsSuggestedTime = dayjs()
    .add(
      predictData?.predictJobDuration?.jobDuration +
        predictData?.predictJobDuration?.waitTime,
      'minutes',
    )
    .tz(timezone)
    .format('hh:mm a');
  const suggestedTime = isNewForm ? dayjsSuggestedTime : momentSuggestedTime;

  return canProduct([PRODUCT_ACCESS.smart_operations]) ? (
    <SuggestedWrapper absolutePosition={absolutePosition} margin={margin}>
      <div>Suggested:</div>
      <SuggestedTime onClick={handleOnSuggestionClick}>
        {loading ? <Spin size="small" /> : suggestedTime}
      </SuggestedTime>
      <Tooltip
        title="Suggested due time based on similar previous jobs"
        placement="bottom"
      >
        {/* @ts-ignore */}
        <InfoCircle aria-label="Info about suggested due time" />
      </Tooltip>
    </SuggestedWrapper>
  ) : null;
};
