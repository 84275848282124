import React, { Fragment, useContext } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  usePropertyTime,
  EmploymentContext,
  ViewJobDetailsContext,
  useAccess,
  JOB_STATUS,
} from '@optii/shared';
import {
  JobFormNoteEdit,
} from '@optii/jobs';
import { PERMISSIONS } from '@optii/shared/constants/permissions';
import {
  JobNotesDropdown,
} from '@optii-solutions/ui23-antd-components';

import { useJobNoteEditor } from 'data/jobs';
import { formatUserDisplayName } from 'utils/formatters/user';
import { getAuditAction } from 'utils/formatters/jobs';
import AddNote from 'components/Jobs/JobDetails/AddNote';

import AddTemplateNote from './AddTemplateNote';

const Author = styled.h4`
  ${(props) => props.theme.fonts.sizes.small}
  ${(props) => props.theme.fonts.type.primary}
  ${(props) => props.theme.fonts.weights.weak}
  flex: 1;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const Time = styled.div`
  ${(props) => props.theme.fonts.sizes.small}
  margin-left: auto;
  color: ${(props) => props.theme.colors.charcoal05};
  font-style: italic;
  text-align: right;
`;
const Text = styled.p`
  ${(props) => props.theme.fonts.sizes.small}
  padding-top: 1rem;
  margin: 0 !important;
  color: ${(props) => props.theme.colors.charcoal05};
`;
const Entry = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.charcoal02};
  margin-bottom: 1.5rem;
  padding-bottom: 1.4rem;
`;

export function Note({ entry: note, toPropertyTime, canEdit }) {
  const { t } = useTranslation(['common', 'fields']);

  const { editLoading, removeLoading, update, remove, edit, setEdit } =
    useJobNoteEditor();
  if (!note.text) {
    return null;
  }
  const time = Object.prototype.hasOwnProperty.call(note, 'createdAt')
    ? toPropertyTime(moment(note.createdAt).unix())
    : toPropertyTime(note.time);

  return (
    <Entry style={{ opacity: removeLoading ? '0.3' : '1' }}>
      {edit ? (
        <JobFormNoteEdit
          note={note.text}
          loading={editLoading}
          name={formatUserDisplayName(note.createdBy)}
          onCancel={() => setEdit(false)}
          onSave={async (input) => {
            update({ note: input, id: note.id });
          }}
        />
      ) : (
        <>
          <Top>
            <Author>
              {note.createdBy
                ? formatUserDisplayName(note.createdBy)
                : t('common:Unknown')}
            </Author>
            <Time>{time && time.format('ll[,] LTS')}</Time>
            {canEdit && (
              <JobNotesDropdown
                onEdit={() => !removeLoading && setEdit(true)}
                onRemove={() =>
                  !removeLoading &&
                  remove({
                    id: note.id,
                  })
                }
              />
            )}
          </Top>
          <Text>
            {note.text.split('\n').map((item, index) => (
              // Handle new line characters
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {item}
                <br />
              </Fragment>
            ))}
          </Text>
        </>
      )}
    </Entry>
  );
}

function Audit({ entry, toPropertyTime }) {
  const time = toPropertyTime(entry.createdAt);
  const { t } = useTranslation(['common', 'fields']);
  return (
    <Entry>
      <Top>
        <Author>{getAuditAction(entry, t)}</Author>
        <Time>{time && time.format('ll[,] LTS')}</Time>
      </Top>
    </Entry>
  );
}

const Entries = styled.div``;

const Container = styled.div`
  padding-top: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
`;

function removeDuplicates(data) {
  const seenModifiers = new Set();
  const seenCreatedAt = new Set();
  const seenAction = new Set();

  const filteredData = data.filter((record) => {
    const { modifier1, createdAt, action } = record;

    if (
      seenModifiers.has(modifier1) &&
      seenCreatedAt.has(createdAt) &&
      seenAction.has(action)
    ) {
      return false; // Skip the item if both modifier1 and createdAt are duplicates
    }

    seenModifiers.add(modifier1);
    seenCreatedAt.add(createdAt);
    seenAction.add(action);
    return true; // Keep the item in the filtered data
  });

  return filteredData;
}

function JobNotes({ queuedJobRequest, mode, isHousekeepingJob }) {
  const modes = {
    notes: 'notes',
    audits: 'audits',
  };
  const { job, audit, isRepeatingJobTemplate } = useContext(
    ViewJobDetailsContext,
  );
  // For when we filter the history

  const { can } = useAccess();
  const canAddEditHousekeepingJobs = can(PERMISSIONS.house_keeping.add_edit);

  const { employee } = useContext(EmploymentContext.Context);
  const { toPropertyTime } = usePropertyTime();

  const notes = isRepeatingJobTemplate
    ? (job?.jobTemplate && job.jobTemplate.notes) || []
    : job?.notes || [];
  const audits = audit || [];

  return (
    <Container>
      <Entries data-testid="job-notes-entries">
        {mode === modes.notes &&
          notes.map((entry, index) => (
            <Note
              key={
                (entry.time || `${entry.createdAt}_audit`) +
                (entry.employee ? entry.employee.employeeId : '0') +
                entry.id
              }
              isRepeatingJobTemplate
              canEdit={employee.id === entry.createdBy.id}
              entry={entry}
              toPropertyTime={toPropertyTime}
            />
          ))}
        {mode === modes.audits &&
          removeDuplicates(audits).map((entry, index) => (
            <Audit
              key={
                (entry.time || `${entry.createdAt}_audit`) +
                (entry.employee ? entry.employee.employeeId : '0') +
                entry.id
              }
              entry={entry}
              toPropertyTime={toPropertyTime}
            />
          ))}
      </Entries>
      {mode === modes.notes &&
      !queuedJobRequest &&
      ((isHousekeepingJob && canAddEditHousekeepingJobs) || !isHousekeepingJob) &&
      job?.status !== JOB_STATUS.queued ? (
        <div>
          {isRepeatingJobTemplate ? (
            <AddTemplateNote queuedJobRequest={queuedJobRequest} />
          ) : (
            <AddNote data-testid="job-add-note" job={job} />
          )}
        </div>
      ) : null}
    </Container>
  );
}
export default JobNotes;
