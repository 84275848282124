import {
  COLORS,
  Col,
  ConfigProvider,
  FONTS,
  Flex,
  FormInstance,
  FormItem,
  FormListFieldData,
  IconButton,
  Row,
  SPACING,
  Select,
  Tooltip,
  Typography,
  useWatch,
} from '@optii-solutions/ui-library';
import {
  ActionType,
  EventContext,
  Filter,
  Operator,
  SelectOption,
} from '@optii/escalations/types/notifications';
import { DeleteOutlined } from '@ant-design/icons';
import { TFunction, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  OFFSET_DURATION,
  OFFSET_TYPE,
  ACTION_TYPE_OPTIONS,
  FORMATTED_ACTION_TYPE_TEXT,
} from '@optii/escalations/constants/notifications';
import { STYLES } from '../../styles';
import { useNotificationContext } from '../../../context';

type Props = {
  eventContext: EventContext;
  actionField: FormListFieldData;
  field: FormListFieldData;
  length: number;
  remove: (index: number | number[]) => void;
  form: FormInstance;
};

type PreviewProps = {
  filters: Filter;
  actions: {
    [x: string]: any;
  }; // TODO: fix type
  eventContext: EventContext;
  t: TFunction<('escalations' | 'rules' | 'common')[], undefined>;
  roleOptions: SelectOption[];
  projectOptions: SelectOption[];
};

const FORMATTED_ASSIGNEE_OPTIONS = {
  [Operator.NotNull]: i18next.t('common:Assigned'),
  [Operator.Null]: i18next.t('common:Unassigned'),
};

const SEPARATORS = {
  and: i18next.t('common:&'),
  or: i18next.t('common:or'),
};

const textToPreview = (
  map: (value: string) => void,
  defaultText: string,
  separator: string,
  values?: string[],
) => {
  if (!values || !values.length) return `[${defaultText}]`;

  return values.map(map).join(separator);
};

function Preview({
  filters,
  actions,
  eventContext,
  t,
  roleOptions,
  projectOptions,
}: PreviewProps) {
  const { Type, Assignee, ProjectId } = filters;
  const { type, schedule, roles, legacyAssigneeFilter } = actions;

  const typeText = textToPreview(
    (jobType: string) =>
      `${jobType.charAt(0).toUpperCase() + jobType.slice(1)} ${t(
        'common:Request',
      )}`,
    t('common:request type'),
    ` ${SEPARATORS.or} `,
    Type?.values,
  );

  const assigneeText = !legacyAssigneeFilter
    ? textToPreview(
        (value: string) => {
          const status = value as Exclude<
            Operator,
            Operator.In | Operator.NotIn | Operator.OutsideRange
          >;
          return FORMATTED_ASSIGNEE_OPTIONS[status];
        },
        t('common:assigned/unassigned'),
        ` ${SEPARATORS.or} `,
        Assignee?.values,
      )
    : FORMATTED_ASSIGNEE_OPTIONS[
        legacyAssigneeFilter.values as Exclude<
          Operator,
          Operator.In | Operator.NotIn | Operator.OutsideRange
        >
      ];

  const projectNamesText = textToPreview(
    (value) => value,
    t('common:All Projects'),
    ` ${SEPARATORS.and} `,
    projectOptions
      .filter((project) => ProjectId?.values?.includes(project?.value))
      .map((item) => item.label as string),
  );

  const notificationTypeText = textToPreview(
    (value: string) =>
      FORMATTED_ACTION_TYPE_TEXT[value as keyof typeof ActionType],
    t('common:Notification Type'),
    ` ${SEPARATORS.and} `,
    type,
  );

  const rolesText = textToPreview(
    (value) => value,
    t('common:recipient role'),
    ` ${SEPARATORS.and} `,
    roleOptions
      .filter((role) => roles?.includes(role.value))
      .map((item) => item.label as string),
  );

  const offsetDurationText =
    OFFSET_DURATION.filter((duration) =>
      duration.context.includes(
        eventContext === 'Project'
          ? EventContext.ProjectCycle
          : EventContext.Job,
      ),
    ).find((duration) => duration.value === schedule?.offsetDuration)?.label ||
    `[${t('common:time trigger')}]`;

  const offsetTypeText =
    OFFSET_TYPE.filter((offset) =>
      offset.context.includes(
        eventContext === 'Project'
          ? EventContext.ProjectCycle
          : EventContext.Job,
      ),
    ).find((offsetType) => offsetType.value === schedule?.offsetType)?.label ||
    `[${t('common:event trigger')}]`;

  const hasProjectsSelected =
    !!ProjectId?.values?.length && (eventContext as string) === 'Project';

  let CONTEXT_TEXT = null;
  if (eventContext === EventContext.Job) {
    CONTEXT_TEXT = (
      <>
        {t('common:is a')} <Typography.Text strong>{typeText}</Typography.Text>{' '}
        {t('common:and')}{' '}
        <Typography.Text strong>{assigneeText}</Typography.Text>
      </>
    );
  } else if (hasProjectsSelected) {
    CONTEXT_TEXT = (
      <>
        {t('common:has project name')}{' '}
        <Typography.Text strong> {projectNamesText}</Typography.Text>
      </>
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: FONTS.small.size,
          lineHeight: FONTS.small.lineHeight,
          colorText: COLORS.neutral[8],
        },
      }}
    >
      <Typography.Text>
        {hasProjectsSelected || eventContext === EventContext.Job ? (
          <>
            {t('rules:If a')}{' '}
            <Typography.Text strong> {eventContext} </Typography.Text>{' '}
          </>
        ) : (
          <>
            {t('common:For')}
            <Typography.Text strong>
              {' '}
              {t('common:all projects')}
            </Typography.Text>
          </>
        )}
        {CONTEXT_TEXT}, {t('rules:send')}
        <Typography.Text strong> {notificationTypeText} </Typography.Text>{' '}
        {t('common:at')}{' '}
        <Typography.Text strong>
          {offsetDurationText} {offsetTypeText}
        </Typography.Text>{' '}
        {t('common:to')} <Typography.Text strong>{rolesText}</Typography.Text>
      </Typography.Text>
    </ConfigProvider>
  );
}

export function JobAndProjectActionFormList({
  eventContext,
  field,
  actionField,
  length,
  remove,
  form,
}: Props) {
  const { t } = useTranslation(['rules']);
  const { roles, projects } = useNotificationContext();

  const actions =
    useWatch(['userEventActionRules', 0, 'actions', actionField.key], form) ||
    {};

  const filters =
    useWatch(
      ['userEventActionRules', field.key, 'event', 'filters', 0],
      form,
    ) || {};

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
          },
        },
      }}
    >
      <Flex
        align="center"
        justify="center"
        gap={SPACING.SIZE_SM}
        style={STYLES.actionFlexContainer}
      >
        <Tooltip title={t('common:Delete')}>
          <IconButton
            icon={<DeleteOutlined alt="delete notification" />}
            disabled={length === 1}
            onClick={() => remove(actionField.name)}
          />
        </Tooltip>
        <Preview
          actions={actions}
          filters={filters}
          eventContext={
            eventContext === EventContext.ProjectCycle
              ? t('common:Project')
              : t('common:Job')
          }
          t={t}
          roleOptions={roles.options}
          projectOptions={projects.options}
        />
      </Flex>
      <FormItem
        label={t('rules:Send an')}
        name={[actionField.name, 'type']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || !value.length)
                return Promise.reject(
                  new Error(t('common:Select at least one notification type.')),
                );
              return Promise.resolve(rule);
            },
          },
        ]}
        required
      >
        <Select
          mode="multiple"
          placeholder={t('common:Notification Type')}
          options={ACTION_TYPE_OPTIONS}
        />
      </FormItem>

      <Row align="bottom" gutter={SPACING.SIZE_MD}>
        <Col span={8}>
          <FormItem
            label={t('common:at')}
            required
            name={[actionField.name, 'schedule', 'offsetDuration']}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(new Error('Select a time trigger'));

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              options={OFFSET_DURATION.filter((duration) =>
                duration.context.includes(eventContext),
              )}
              placeholder={t('common:Select target time')}
            />
          </FormItem>
        </Col>
        <Col span={16}>
          <FormItem
            required
            name={[actionField.name, 'schedule', 'offsetType']}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(
                      new Error(t('common:Select an event trigger')),
                    );

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              options={OFFSET_TYPE.filter((offsetType) =>
                offsetType.context.includes(eventContext),
              )}
              placeholder={t('common:Select period')}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem
        label={t('common:to')}
        name={[actionField.name, 'roles']}
        rules={[
          {
            async validator(rule, value) {
              if (!value || !value.length)
                return Promise.reject(
                  new Error(t('common:Select at least one recipient role')),
                );

              return Promise.resolve();
            },
          },
        ]}
        required
      >
        <Select
          options={roles.options}
          loading={roles.loading}
          placeholder={t('common:Roles')}
          mode="multiple"
          optionFilterProp="label"
        />
      </FormItem>
    </ConfigProvider>
  );
}
