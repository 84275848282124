import { Fragment } from 'react';

import { FONTS, Typography } from '@optii-solutions/ui-library';
import { useAccess } from '@optii/shared';
import { PERMISSIONS } from '@optii/shared/constants/permissions';

import { getActions } from 'utils/formatters/jobs';

type TLocationMin = {
  id: string;
  name: string;
  number: number | null;
  shortDisplayName: string;
  title: string;
  type: string;
  value: string;
};

function getItems(items?: [{ name: string; amount: number }], joiner = ', ') {
  let out = '';
  if (!items) return out;
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    out += item.name;
    if (item.amount > 1) out += ` (${item.amount})`;
    if (i + 1 < items.length) out += joiner;
  }

  return out;
}

interface IViewJobDetailsHeaderProps {
  job: {
    parentChecklistTaskId?: string;
    action?: string;
    locations: TLocationMin[];
    items: [{ name: string; amount: number }] | undefined;
  };
  onLocationClick: (locationId: string) => void;
}
function ViewJobDetailsHeader({
  job,
  onLocationClick,
}: IViewJobDetailsHeaderProps) {
  const showAction = !job?.parentChecklistTaskId;
  const actions = getActions();

  const { can } = useAccess();

  return (
    <>
      {job?.locations?.map((location, inx) => (
        <Fragment key={location.id}>
          {can(PERMISSIONS.settings.floorplan.locationDetailView) ? (
            <Typography.Link
              style={{
                fontSize: FONTS.h3.size,
              }}
              onClick={() => onLocationClick(location.id)}
            >
              {location.shortDisplayName}
            </Typography.Link>
          ) : (
            <Typography.Text
              style={{
                fontSize: FONTS.h3.size,
              }}
            >
              {location.shortDisplayName}
            </Typography.Text>
          )}
          {inx < Number(job?.locations?.length) - 1 ? ', ' : ''}
        </Fragment>
      ))}
      {`: ${
        actions[job?.action?.toLowerCase()] && showAction
          ? actions[job.action?.toLowerCase()].displayName
          : ''
      } ${getItems(job?.items)}`}
    </>
  );
}

export default ViewJobDetailsHeader;
