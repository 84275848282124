import {
  FormItem,
  Select,
  type FormListFieldData,
  Col,
  Row,
  Input
} from '@optii-solutions/ui-library';
import { Operator } from '@optii/escalations/types/notifications';

import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  filterField: FormListFieldData;
  metadata: { [key: string]: any };
};

export function NumericalTaskCriteriaFormList({ filterField }: Props) {
  const { t } = useTranslation(['common']);
  return (
    <div style={{ width: '100%' }}>
      <Row>
        <Col sm={6}>
          <FormItem
            label={t('common: Acceptable Range')}
            name={[filterField.name, 'DataNumericMin']}
            normalize={(value) => ({
              property: 'DataNumerical',
              operator: Operator.OutsideRange,
              values: [value],
            })}
            getValueProps={(normalizedValue) => ({
              value: normalizedValue?.values[0],
            })}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(
                      new Error(t('common:Enter the minimum numeric value.')),
                    );
                  return Promise.resolve(rule);
                },
              },
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  const DataNumericMax = getFieldValue([
                    'userEventActionRules',
                    0,
                    'event',
                    'filters',
                    filterField.name,
                    'DataNumericMax',
                  ]);

                  if (!DataNumericMax?.values) return Promise.resolve(rule);

                  const [max] = DataNumericMax.values;
                  const [min] = value.values;

                  if (max && (Number(min) > Number(max)))
                    return Promise.reject(
                      new Error(
                        t('common:Min value cannot be greater than Max value.'),
                      ),
                    );
                  return Promise.resolve(rule);
                },
              }),
            ]}
            required
          >
            <Input placeholder={t('fields:min')} type='number' />
          </FormItem>
        </Col>
        <Col sm={1} style={{ alignItems: 'center', justifyContent: 'center',  display: 'flex' }}>
          -
        </Col>
        <Col sm={6}>
          <FormItem
            label="&nbsp;"
            name={[filterField.name, 'DataNumericMax']}
            normalize={(value) => ({
              property: 'DataNumerical',
              operator: Operator.OutsideRange,
              values: [value],
            })}
            getValueProps={(normalizedValue) => ({
              value: normalizedValue?.values[0],
            })}
            rules={[
              {
                async validator(rule, value) {
                  if (!value)
                    return Promise.reject(
                      new Error(t('common:Enter the maximum numeric value.')),
                    );
                  return Promise.resolve(rule);
                },
              },
              ({ getFieldValue }) => ({
                async validator(rule, value) {
                  const minField = [
                    'userEventActionRules',
                    0,
                    'event',
                    'filters',
                    filterField.name,
                    'DataNumericMin',
                  ];
                  const DataNumericMin = getFieldValue(minField);

                  if (!DataNumericMin?.values) return Promise.resolve(rule);

                  const [min] = DataNumericMin.values;
                  const [max] = value.values;

                  if (max && (Number(max) < Number(min)))
                    return Promise.reject(
                      new Error(
                        t('common:Max value cannot be less than Min value.'),
                      ),
                    );
                
                  return Promise.resolve(rule);
                },
              }),
            ]}
            required
          >
            <Input placeholder={t('fields:max')} type='number' />
          </FormItem>
        </Col>
      </Row>
    </div>

  );
}

export default NumericalTaskCriteriaFormList;