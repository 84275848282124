import styled from 'styled-components/macro';
import React from 'react';
import BaseButton from './BaseButton';

const Button = styled(BaseButton)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.persianGreen};

  :active,
  :hover {
    ${(props) => props.theme.shadows.button}
  }
  :active {
    background-color: ${(props) => props.theme.colors.persianGreenMed};
  }
`;
function SecondaryButton(props) {
  const { children } = props;
  return <Button {...props}>{children}</Button>;
}
export default SecondaryButton;
