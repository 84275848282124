const PREFIX = 'projectCycleFakeIt';

export function getProjectCycleFakeIt({ projectCycleId }) {
  const defaultValue = { data: {}, checksum: '' };
  try {
    const stringData = sessionStorage.getItem(`${PREFIX}:${projectCycleId}`);
    const jsonData = JSON.parse(stringData);
    return jsonData ?? defaultValue;
  } catch (error) {
    console.log('getProjectCycleFakeIt error', error);
    return defaultValue;
  }
}

function deleteJobFromOldStatuses({ jobId, data }) {
  const newData = {};
  Object.entries(data).forEach(([status, jobs]) => {
    if (jobs[jobId]) {
      // eslint-disable-next-line no-param-reassign
      delete jobs[jobId];
    }
    newData[status] = jobs;
  });
  return newData;
}

function getNormalizedStatus(status) {
  switch (status) {
    case 'completed':
      return 'done';

    case 'pending':
      return 'not_started';

    default:
      return status;
  }
}

function removeFakeItAfterDelay(id, delayInMinutes = 30) {
  const oneMinuteInMiliseconds = 60000;
  const delay = delayInMinutes * oneMinuteInMiliseconds;

  window.setTimeout(() => {
    sessionStorage.removeItem(id);
  }, delay);
}
export function setProjectCycleFakeIt({ projectCycleId, job, status }) {
  try {
    let { data = {} } = getProjectCycleFakeIt({ projectCycleId }) ?? {};
    const normalizedStatus = getNormalizedStatus(status);
    data[normalizedStatus] = data[normalizedStatus] ?? {};

    data = deleteJobFromOldStatuses({ jobId: job.id, data });
    data[normalizedStatus][job.id] = job;

    const stringData = JSON.stringify({ data, checksum: '' });
    sessionStorage.setItem(`${PREFIX}:${projectCycleId}`, stringData);

    removeFakeItAfterDelay(`${PREFIX}:${projectCycleId}`);
  } catch (error) {
    console.log('setProjectCycleFakeIt error', error);
  }
}

export function verifyProjectCycleFakeItChecksum({ projectCycleId, checksum }) {
  try {
    const { data, checksum: projectCycleChecksum } = getProjectCycleFakeIt({
      projectCycleId,
    });
    if (!projectCycleChecksum || projectCycleChecksum.length === 0) {
      const stringData = JSON.stringify({ data, checksum });
      sessionStorage.setItem(`${PREFIX}:${projectCycleId}`, stringData);
    } else if (projectCycleChecksum !== checksum) {
      sessionStorage.removeItem(`${PREFIX}:${projectCycleId}`);
    }
  } catch (error) {
    console.log('setProjectCycleFakeIt error', error);
  }
}

export function canIShowJobInStatus({ jobId, status, projectCycleId }) {
  const normalizedStatus = getNormalizedStatus(status);
  const { data } = getProjectCycleFakeIt({ projectCycleId });

  let canIShow = true;
  Object.entries(data)?.forEach(([currentStatus, jobs]) => {
    const currentStatusIncludesJobId = Object.keys(jobs)?.includes(jobId);
    if (currentStatusIncludesJobId && currentStatus !== normalizedStatus) {
      canIShow = false;
    }
  });
  return canIShow;
}

export function getFakeItJobsFromStatus({ jobs, status, projectCycleId }) {
  try {
    const normalizedStatus = getNormalizedStatus(status);
    const { data = {} } = getProjectCycleFakeIt({ projectCycleId });
    const currentJobIds = jobs.map(({ id }) => id);
    if (data[normalizedStatus]) {
      const missingJobs = Object.entries(data[normalizedStatus])
        .filter(([jobId, jobData]) => !currentJobIds.includes(jobId))
        .map(([jobId, jobData]) => jobData);
      return missingJobs;
    }
    return [];
  } catch (error) {
    console.log('getFakeItJobsFromStatus error', error);
    return [];
  }
}
