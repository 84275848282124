import styled from 'styled-components/macro';
import React, { useState } from 'react';
import { Typography } from '@optii-solutions/ui23-antd-components';
import { isUndefined } from 'lodash';
import { applyStyleModifiers } from 'styled-components-modifiers';
import TextareaAutosize from 'react-textarea-autosize';
import FieldBlock from 'blocks/Field';
import PhoneInput from './PhoneInput';
import Select from './Select';
import RequiredStar from './RequiredStar';
import Checkbox from './Checkbox';
import ErrorMsg from './ErrorMsg.tsx';
import HelpTip from './HelpTip';

const sharedLeftPadding = '1.32rem';

const ERROR_CONFIG = {
  paddingLeft: () => `
    padding-left: 3.32rem;
  `,
};

const Text = styled.span`
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.sizes.largest}
  ${(props) => props.theme.fonts.weights.weak}
`;
const Error = styled(ErrorMsg)`
  padding-inline-start: ${sharedLeftPadding};
  margin-top: 0.5rem;
  ${applyStyleModifiers(ERROR_CONFIG)};
`;
const ValidationMsg = styled.div`
  ${(props) => props.theme.fonts.sizes.small};
  font-style: italic;
  margin-top: 0.4rem;
`;

const Textarea = styled(TextareaAutosize)`
  ${(props) => props.theme.fonts.type.primary};
  min-height: 44px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  padding-inline-start: ${sharedLeftPadding};
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.borders.default}
  ${(props) => props.theme.fonts.sizes.large}
  ${(props) => props.theme.fonts.weights.light}
  resize: none;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  &::placeholder {
    ${(props) => props.theme.fonts.type.primary};
    ${(props) => props.theme.fonts.weights.light};
    ${(props) => props.theme.colors.charcoal04};
    font-style: italic;
  }
`;
const Field = React.forwardRef((props, ref) => {
  // Which type of field is this?
  const [blurred, setBlurred] = useState(props.blur || false);
  const [focused, setFocused] = useState(props.focused || false);
  const showLabel = isUndefined(props.showLabel) ? true : props.showLabel;

  function handleBlur() {
    if (props.onBlur) {
      props.onBlur();
    }
    setBlurred(true);
  }
  function handleFocus() {
    if (props.onFocus) {
      // made for PasswordCreation.js
      props.onFocus();
      setFocused(true);
    }
  }

  function renderSwitch() {
    const { handleKeyDown, heightChangeHandler, ...rest } = props;

    switch (props.type) {
      case 'tel':
        return <PhoneInput {...props} />;
      case 'select':
        if (props.readOnly) {
          if (!showLabel)
            return <Text>{props.value ? props.value.displayName : ''}</Text>;
          return (
            <FieldBlock.Input
              {...props}
              value={props.value ? props.value.displayName : ''}
              id={props.name}
            />
          );
        }
        return (
          <Select
            onBlur={() => handleBlur()}
            blurred={blurred}
            {...props}
            options={props.options || []}
            data-testid={`field-${props.name}`}
          />
        );
      case 'checkbox':
        return <Checkbox {...props} />;
      case 'textarea':
        return (
          <Textarea
            {...rest}
            onKeyDown={handleKeyDown}
            onHeightChange={heightChangeHandler}
            ref={ref}
          />
        );
      default:
        return (
          // Input field doesn't have word wrap. Span is preferred.
          props.readOnly && !showLabel ? (
            <div>
              {typeof props.onClick === 'function' ? (
                <Typography.Link
                  onClick={() => {
                    props.onClick();
                  }}
                >
                  {props.value}
                </Typography.Link>
              ) : (
                <Text>{props.value}</Text>
              )}
            </div>
          ) : (
            <FieldBlock.Input
              className="basic-input"
              id={props.name}
              data-testid={props.name}
              {...props}
              onBlur={() => handleBlur()}
              blurred={blurred}
              onFocus={() => handleFocus()}
              focused={focused}
            />
          )
        );
    }
  }

  const errMsg = props.errorMsg || props.error;

  // TODO: Email
  const hasLabel = props.label && props.type !== 'checkbox' && showLabel;
  return (
    <>
      <FieldBlock
        {...props}
        modifiers={[props.modifiers, props.isCurrency && 'currency']}
      >
        {!props.readOnly && props.required && <RequiredStar />}
        {hasLabel && (
          <FieldBlock.Label htmlFor={props.name}>
            {props.label}
          </FieldBlock.Label>
        )}
        {renderSwitch()}
        {props.help && <HelpTip text={props.help} />}
      </FieldBlock>
      {props.validationMessage && !props.readOnly && (
        <ValidationMsg>{props.validationMessage}</ValidationMsg>
      )}
      {!props.readOnly && props.error && errMsg && (blurred || focused) && (
        <Error
          {...props}
          className="error"
          data-testid={`error-msg-${props.name}`}
        >
          {errMsg}
        </Error>
      )}
    </>
  );
});
export default Field;
