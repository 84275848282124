import { gql } from "@apollo/client";

export default gql`
  query GetLocationHousekeepingStatusById($locationId: ID!) {
    status: getLocationHousekeepingStatusById(locationId: $locationId) {
      code
      createdAt
      id
      locationId
      name
      updatedAt
    }
  }
`;
