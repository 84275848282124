import React, { forwardRef, RefObject } from 'react';
import { ButtonStyled } from './styles';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> =
  forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ children, ...props }, ref) => {
      return (
        <ButtonStyled ref={ref} {...props}>
          {children}
        </ButtonStyled>
      );
    },
  );

export default Button;
