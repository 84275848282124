import React, { useCallback, useContext } from 'react';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared';
import { CHECKLIST_TASK_FIELDS } from '@optii/jobs/checklists/constants';
import { ChecklistItemContext } from '@optii/jobs/checklists/contexts';
import TaskNameField from '../TaskNameField';
import SelectType from '../SelectType';
import { CheckBox, FlexContainer, FlexItem, RequiredFlag } from '../../Checklists.elements';

function CheckboxType() {
  const {
    fulfillmentValues,
    onFulfillmentChange,
    isFulfillment,
    formValues,
    isReadOnly,
  } = useContext(ChecklistItemContext);

  const isRequired =
    String(formValues[CHECKLIST_TASK_FIELDS.required]) === 'true';

  const checked =
    fulfillmentValues[CHECKLIST_TASK_FIELDS.dataCheckboxChecked] === ''
      ? false
      : fulfillmentValues[CHECKLIST_TASK_FIELDS.dataCheckboxChecked];
  const readOnly = !isFulfillment || isReadOnly;

  const onChange = useCallback(
    (event) => {
      const { checked: eventChecked } = event.target;
      event.stopPropagation();
      eventChecked ? GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskSelected) : GoogleAnalyticsClient.event(GA_EVENTS.checkboxTaskDeselected);

      onFulfillmentChange({
        ...formValues,
        [CHECKLIST_TASK_FIELDS.dataCheckboxChecked]: eventChecked,
      });
    },
    [formValues, onFulfillmentChange],
  );

  return (
    <FlexContainer data-testid="checklist-task-checkbox-container">
      <FlexContainer col>
        <FlexContainer>
          <FlexItem flex="0" row>
            {isRequired && <RequiredFlag />}
            <CheckBox
              checked={checked}
              tabIndex={-1}
              onChange={onChange}
              readOnly={readOnly}
              data-testid="checklist-task-checkbox-check"
            />
          </FlexItem>
          <FlexItem>
            <TaskNameField />
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
      {!isFulfillment && <SelectType />}
    </FlexContainer>
  );
}

export default CheckboxType;
