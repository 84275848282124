import { gql } from "@apollo/client";

export default gql`
  mutation CopyChecklistTemplate(
    $id: ID!
    $copyChecklistTemplateInput: CopyChecklistTemplateInput!
  ) {
    copyChecklistTemplate(
      id: $id
      copyChecklistTemplateInput: $copyChecklistTemplateInput
    ) {
      archivedAt
      createdAt
      id
      tasks {
        attachment
        checklistTemplateId
        id
        label
        notes
        required
        taskType
        parent_task_id
        ordering_value
      }
      creator {
        employeeId
      }
      name
      description
      updatedAt
    }
  }
`;
