import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  useForm,
  Button,
  Col,
  Drawer,
  Form,
  FormItem,
  HeaderActions,
  Input,
  Row,
  SPACING,
  Select,
  Skeleton,
  TextArea,
  DatePicker,
  Typography,
  Collapse,
  CollapsePanel,
  DescriptionText,
  Descriptions,
  TreeSelect,
} from '@optii/ui-library';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  CREATE_ASSET,
  UPDATE_ASSET,
  CREATE_BULK_ASSET,
  GET_ASSET_TYPE_BY_ID,
  FIND_ALL_ASSETS,
  FIND_ALL_ASSET_TYPES,
} from 'queries';
import { AssetsContext } from 'components/Settings/AssetsSettings/contexts';
import Attachments from 'components/shared/Attachments';
import { removeTimeFromDate } from 'utils/removeTimeFromDate';
import { addUrlProtocol, urlPattern } from 'utils/url';

import { Session, UserAccessContext } from '@optii/shared';
import { useMinimalLocations } from '@optii/shared/hooks/useLocations';

import styled from 'styled-components';
import { useAssetVendors } from 'data/assetVendors';
import { useAssetTypes } from 'data/assetTypes';
import { getLifeCycleNumber } from 'utils/formatters/assets';
import { DownOutlined } from '@ant-design/icons';
import { LOCATION_TYPES_NAMES } from 'components/Jobs/JobForm/JobForm.constants';
import type { TAssetForm } from './types';
import { TreeSelectContainer } from './styles';
import { AssetFormSkeleton } from './AssetForm.skeleton';

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-row > th.ant-descriptions-item,
  .ant-descriptions-row > td.ant-descriptions-item {
    padding-bottom: 0px;
  }
  .ant-descriptions-item-container .ant-descriptions-item-content {
    padding: 0 0 1rem !important;
  }
  .ant-descriptions-item-container .ant-descriptions-item-label {
    padding-bottom: 0;
  }
  span {
    font-size: 1.6rem !important;
    word-break: normal;
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-radius: 0 !important;
  }
`;

const StyledPanel = styled(CollapsePanel)`
  .ant-collapse-header {
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-right: 0;
    background: #f5f5f5;
    border-radius: 0 !important;
  }
`;

const STYLES = {
  descriptionContent: {
    paddingTop: `${SPACING.SIZE_XS}px`,
    paddingBottom: `${SPACING.SIZE_XXXL}px`,
    color: '#8C8C8C',
  },
  descriptions: {
    padding: '0',
    paddingTop: `${SPACING.SIZE_XL}px`,
    paddingBottom: `${SPACING.SIZE_DEFAULT}px`,
    overflow: 'auto',
    color: '#8C8C8C',
  },
};
const TAB_COLUMNS = {
  xxl: 4,
  xl: 4,
  lg: 2,
  md: 2,
  sm: 2,
  xs: 1,
};

interface IAssetFormProps {
  handleClose: () => void;
}

type LocationOption = {
  childCount: number;
  displayName: string;
  id: string;
  key: string;
  locationTypeCode: string;
  locationTypeId: string;
  locationTypeName: string;
  longDisplayName: string;
  name: string;
  pId: number;
  parentName: string;
  roomType: string;
  roomTypeId: string;
  roomTypeName: string;
  shortDisplayName: string;
  title: string;
  value: string;
  __typename: string;
};

type TPreferredLanguage = {
  user: {
    preferredLang: any;
  };
};

export function AssetForm(props: IAssetFormProps) {
  const { handleClose } = props;

  const { t } = useTranslation(['common', 'assets', 'fields']);
  const { editAsset, isNewAsset, defaultAssetTpe, setRefetchSubAssets } =
    useContext(AssetsContext);

  const { user } = useContext(UserAccessContext.Context) as TPreferredLanguage;
  const { locations: locationsMinimal = [], loading: loadingMinimalLocations } =
    useMinimalLocations({
      withLongDisplayName: true,
    });
  const { assetVendorsOptions, assetVendorsFormated } = useAssetVendors();
  const { assetTypesOptions, loading: loadingAssetTypes } = useAssetTypes();
  const [createVendor, setCreateVendor] = React.useState({
    create: false,
    value: '',
  });
  const [documents, setDocuments] = React.useState<{ uuid: string }[]>(
    editAsset?.documents ?? [],
  );
  const [locations, setLocations] = React.useState<LocationOption[]>([]);

  const isOpen: boolean = !!(isNewAsset || editAsset) || false;

  const [form] = useForm<TAssetForm>();

  const [getAssetTypeById, { data: assetType }] = useLazyQuery(
    GET_ASSET_TYPE_BY_ID,
    {
      variables: {
        id:
          defaultAssetTpe?.id ??
          String(form.getFieldValue('assetType')) ??
          editAsset?.assetType?.id,
      },
      notifyOnNetworkStatusChange: true,
      context: { _instance: 'node' },
      fetchPolicy: 'no-cache',
    },
  );

  const [createAsset, { loading: createAssetLoading }] =
    useMutation(CREATE_ASSET);

  const [createBulkAssets, { loading: createBulkLoading }] =
    useMutation(CREATE_BULK_ASSET);

  const [updateAsset, { loading: updateAssetLoading }] = useMutation(
    UPDATE_ASSET,
    { errorPolicy: 'all' },
  );

  const submitting = useMemo(
    () => updateAssetLoading || createBulkLoading || createAssetLoading,
    [updateAssetLoading, createBulkLoading, createAssetLoading],
  );

  const [loading, setLoading] = useState<boolean>(
    submitting || loadingAssetTypes || loadingMinimalLocations,
  );

  const { globalSnack } = useContext(Session);

  const showSerialNumber = useMemo(() => {
    if (!Array.isArray(locations)) return true;

    return locations.length <= 1;
  }, [locations]);

  const getAssetName = useCallback(
    (assetTypeId: number, locationId: number) => {
      const assetTypeDisplayName =
        assetTypesOptions?.find((a) => a.value === assetTypeId)?.label ?? '';
      const locationDisplayName =
        locationsMinimal?.find((l) => l?.id === locationId)?.shortDisplayName ??
        '';

      const name = `${assetTypeDisplayName} ${locationDisplayName}`;

      return name;
    },
    [assetTypesOptions, locationsMinimal],
  );

  const formatAssetForm = useCallback(
    (values: TAssetForm) => {
      const formData = {
        id: editAsset?.id,
        assetType: {
          id: defaultAssetTpe?.id ?? values.assetType,
        },
        location: {
          id: Array.isArray(values.location)
            ? values.location[0]
            : values.location,
        },
        serialNumber: showSerialNumber ? values.serialNumber?.trim() : null,
        notes: values.notes?.trim(),
        status: values.status ?? 'active',
        installationDate: removeTimeFromDate(values.installationDate),
        documents: documents.map(({ uuid }) => ({ uuid })) ?? [],
      };

      const purchase = {
        date: removeTimeFromDate(values['purchase.date']),
        price: values['purchase.price'],
        orderNumber: values['purchase.orderNumber'],
        invoiceNumber: values['purchase.invoiceNumber'],
      };
      const hasPurchase =
        purchase.date ||
        purchase.price ||
        purchase.orderNumber ||
        purchase.invoiceNumber;
      if (hasPurchase) {
        Object.assign(formData, {
          purchase,
        });
      }

      const singleVendor = assetVendorsFormated.find(
        ({ id }) => id === values['vendor.id'],
      );

      const vendor = {
        displayName:
          values['vendor.displayName'] ??
          singleVendor?.displayName ??
          (editAsset ? editAsset.vendor?.displayName : ''),
        website: addUrlProtocol(values['vendor.website']),
        phone: values['vendor.phone'],
        address: values['vendor.address'],
        id: values['vendor.id'],
      };
      const hasVendor = vendor.displayName || vendor.id;
      if (hasVendor) {
        Object.assign(formData, {
          vendor,
        });
      }

      const warranty = {
        number: values['warranty.number']
          ? Number(values['warranty.number'])
          : null,
        warrantyPeriodType: values['warranty.warrantyPeriodType'],
        description: values['warranty.description'],
      };
      const hasWarranty =
        warranty.number || warranty.warrantyPeriodType || warranty.description;
      if (hasWarranty) {
        Object.assign(formData, {
          warranty,
        });
      }

      if (editAsset) {
        Object.assign(formData, {
          version: Number(editAsset?.version),
        });
      }

      return formData;
    },
    [
      editAsset,
      defaultAssetTpe?.id,
      showSerialNumber,
      documents,
      assetVendorsFormated,
    ],
  );

  const handleCreate = useCallback(
    async (values: TAssetForm) => {
      try {
        const formatedForm = formatAssetForm(values);
        await createAsset({
          variables: {
            createAssetInput: formatedForm,
          },
          refetchQueries: [
            FIND_ALL_ASSETS,
            'Assets',
            FIND_ALL_ASSET_TYPES,
            'AssetTypes',
          ],
          context: { _instance: 'node' },
        });

        form.resetFields();
        if (defaultAssetTpe?.id) setRefetchSubAssets(true);

        globalSnack({
          message: t('asset:{{name}} was successfully added', {
            name: getAssetName(
              formatedForm.assetType?.id,
              formatedForm.location?.id,
            ),
          }),
        });

        handleClose();
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('assets:Something went wrong attempting to create asset'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [
      createAsset,
      defaultAssetTpe?.id,
      form,
      formatAssetForm,
      globalSnack,
      handleClose,
      setRefetchSubAssets,
      t,
      getAssetName,
    ],
  );

  const handleCreateBulkAsset = async (values: TAssetForm) => {
    const createAssetInput = formatAssetForm(values);

    const bulkAssets = locations.map((loc: LocationOption) => ({
      ...createAssetInput,
      location: { id: loc.id },
    }));

    try {
      await createBulkAssets({
        variables: {
          createBulkAsset: { assets: bulkAssets },
        },
        refetchQueries: [FIND_ALL_ASSETS, 'Assets'],
        context: { _instance: 'node' },
      });

      form.resetFields();
      if (defaultAssetTpe?.id) setRefetchSubAssets(true);

      globalSnack({
        message: t('assets:Assets were successfully added'),
      });
      handleClose();
    } catch (e) {
      console.log(e);
      globalSnack({
        message: t('assets:Something went wrong attempting to create assets'),
        error: true,
        timeout: 5000,
      });
    }
  };

  const handleUpdate = useCallback(
    async (values: TAssetForm) => {
      try {
        const formatedForm = formatAssetForm(values);

        await updateAsset({
          variables: {
            updateAssetInput: formatedForm,
          },
          refetchQueries: [
            FIND_ALL_ASSETS,
            'Assets',
            FIND_ALL_ASSET_TYPES,
            'AssetTypes',
          ],
          context: { _instance: 'node' },
        });

        setRefetchSubAssets(true);

        globalSnack({
          message: t('asset:{{name}} was successfully updated', {
            name: getAssetName(
              formatedForm.assetType?.id,
              formatedForm.location?.id,
            ),
          }),
        });

        handleClose();
      } catch (e) {
        console.error(e);

        globalSnack({
          message: t('assets:Something went wrong attempting to create asset'),
          error: true,
          timeout: 5000,
        });
      }
    },
    [
      formatAssetForm,
      updateAsset,
      setRefetchSubAssets,
      globalSnack,
      t,
      getAssetName,
      handleClose,
    ],
  );

  const handleSubmit = (values: TAssetForm) => {
    if (editAsset) {
      handleUpdate(values);
    } else if (locations.length > 1) {
      handleCreateBulkAsset(values);
    } else {
      handleCreate(values);
    }
  };

  const descriptionItems = [
    {
      key: 'asset-type-description',
      label: (
        <DescriptionText type="label">{t('assets:Asset Type')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-brand-manufacturer-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Brand/Manufacturer')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.brand &&
            assetType?.assetType?.brand.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-model-description',
      label: (
        <DescriptionText type="label">{t('assets:Model')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.modelName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-job-item-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Category/ Job Item')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.jobItem?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-service-cycle-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Service Cycle')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.serviceCycle &&
            assetType?.assetType?.serviceCycle?.displayName}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-asset-life-description',
      label: (
        <DescriptionText type="label">{t('assets:Asset Life')}</DescriptionText>
      ),
      children: (
        <DescriptionText emptyText={t('fields:None')}>
          {getLifeCycleNumber(
            assetType?.assetType?.lifeCycle?.number,
            assetType?.assetType?.lifeCycle?.lifeCyclePeriodType,
            t,
          )}
        </DescriptionText>
      ),
    },
    {
      key: 'asset-type-description-description',
      label: (
        <DescriptionText type="label">
          {t('assets:Description')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText emptyText={t('fields:None')}>
          {assetType?.assetType?.description}
        </DescriptionText>
      ),
      span: 2,
    },
    {
      key: 'asset-type-notes-description',
      label: (
        <DescriptionText type="label">{t('assets:Notes')}</DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.note}
        </DescriptionText>
      ),
      span: 4,
    },
    {
      key: 'asset-type-attachments',
      label: (
        <DescriptionText type="label">
          {t('assets:Attachments')}
        </DescriptionText>
      ),
      children: (
        <DescriptionText type="text" emptyText={t('fields:None')}>
          {assetType?.assetType?.documents?.length && (
            <Attachments
              values={{
                attachments: assetType?.assetType?.documents.map(
                  (document: { uuid: string }) => document.uuid,
                ),
              }}
              hideUploads
            />
          )}
        </DescriptionText>
      ),
      span: 4,
    },
  ];

  useEffect(() => {
    setTimeout(() => setLoading(false), 1200);

    if (defaultAssetTpe?.id || editAsset?.assetType?.id) {
      getAssetTypeById();
    }
  }, [defaultAssetTpe?.id, editAsset?.assetType?.id, getAssetTypeById]);

  let editTitle = t('Add Asset');

  if (editAsset?.displayName) {
    editTitle = `Edit ${editAsset.displayName}`;
  }

  let initialValues = {};

  if (editAsset) {
    initialValues = {
      ...editAsset,
      location: editAsset.location.id,
      assetType: editAsset.assetType.id,
      installationDate: editAsset.installationDate
        ? dayjs(editAsset.installationDate)
        : null,
      'purchase.date': editAsset.purchase?.date
        ? dayjs(editAsset.purchase?.date)
        : null,
      'purchase.price': editAsset.purchase?.price,
      'purchase.orderNumber': editAsset.purchase?.orderNumber,
      'purchase.invoiceNumber': editAsset.purchase?.invoiceNumber,
      'vendor.website': editAsset.vendor?.website,
      'vendor.displayName': editAsset.vendor?.displayName,
      'vendor.phone': editAsset.vendor?.phone,
      'vendor.address': editAsset.vendor?.address,
      'vendor.id': editAsset.vendor?.id,
      'warranty.number': editAsset.warranty?.number,
      'warranty.warrantyPeriodType': editAsset.warranty?.warrantyPeriodType,
      'warranty.description': editAsset.warranty?.description,
    };
  } else if (!editAsset && defaultAssetTpe) {
    initialValues = {
      assetType: defaultAssetTpe.id,
    };
  }

  return (
    <StyledDrawer
      destroyOnClose
      width={675}
      onClose={() => {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }}
      open={isOpen}
      title={
        <Row align="middle" justify="space-between" wrap={false}>
          <Typography.Title
            level={3}
            style={{
              marginTop: SPACING.NONE,
              fontFamily: 'Montserrat',
            }}
          >
            {editTitle}
          </Typography.Title>
          <HeaderActions onClose={() => handleClose()} />
        </Row>
      }
      footer={
        <Row
          align="middle"
          justify="end"
          wrap={false}
          gutter={12}
          style={{
            paddingRight: SPACING.SIZE_XL,
          }}
        >
          <Button type="text" onClick={() => handleClose()}>
            {t('Cancel')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            disabled={loading || submitting}
            loading={submitting}
            onClick={() => form.submit()}
          >
            {editAsset ? t('Update') : t('Save')}
          </Button>
        </Row>
      }
    >
      {loading ? (
        <AssetFormSkeleton />
      ) : (
        <Form
          autoComplete="off"
          title="Form"
          form={form}
          preserve={false}
          onFinish={handleSubmit}
          initialValues={initialValues}
          scrollToFirstError
        >
          <StyledCollapse defaultActiveKey={[1, 2, 3, 4, 5]}>
            <StyledPanel key={1} header="Asset Type">
              {!defaultAssetTpe?.id && (
                <Row align="middle" justify="space-between" wrap={false}>
                  <Col xs={12}>
                    <FormItem<TAssetForm>
                      label={`${t('assets:Asset Type')}`}
                      name="assetType"
                      rules={[
                        {
                          required: true,
                          message: t('assets:Asset Type is a required field'),
                        },
                      ]}
                    >
                      <Select
                        onChange={(value: string) => {
                          if (value) {
                            getAssetTypeById();
                          }
                        }}
                        options={assetTypesOptions}
                        allowClear
                        placeholder={t('assets:Select')}
                        showSearch
                        filterOption={(input, option) =>
                          String(option?.label)
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>
              )}
              {assetType?.assetType && (
                <Row>
                  <Col>
                    <StyledDescriptions
                      items={descriptionItems.filter((item) => !!item) || []}
                      colon={false}
                      column={TAB_COLUMNS}
                      contentStyle={STYLES.descriptionContent}
                      style={STYLES.descriptions}
                      layout="vertical"
                    />
                  </Col>
                </Row>
              )}
            </StyledPanel>
            <StyledPanel key={2} header="Asset">
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={12}>
                  <TreeSelectContainer>
                    <FormItem<TAssetForm>
                      label={`${t('assets:Location')}`}
                      name="location"
                      rules={[
                        {
                          required: true,
                          message: t('assets:Location is a required field'),
                        },
                      ]}
                    >
                      <TreeSelect
                        treeData={locationsMinimal}
                        suffixIcon={
                          <DownOutlined
                            style={{
                              pointerEvents: 'none',
                            }}
                          />
                        }
                        notFoundContent
                        loading={loadingMinimalLocations}
                        placeholder={t('fields:Select')}
                        treeDefaultExpandedKeys={[
                          locationsMinimal?.find(
                            (location: { locationTypeName: string }) =>
                              location.locationTypeName ===
                              LOCATION_TYPES_NAMES.property,
                          ).id,
                        ]}
                        setValue={(
                          items: LocationOption[] | LocationOption | any,
                        ) => {
                          setLocations(items);
                        }}
                        multiple={!editAsset}
                        key="locationTree"
                        value={locations}
                      />
                    </FormItem>
                  </TreeSelectContainer>
                </Col>
                <Col xs={11}>
                  {showSerialNumber && (
                    <FormItem<TAssetForm>
                      label={t('assets:Serial Number')}
                      name="serialNumber"
                    >
                      <Input
                        placeholder="1234567890"
                        autoComplete="name-only"
                      />
                    </FormItem>
                  )}
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={12}>
                  <FormItem<TAssetForm>
                    label={t('assets:Installation Date')}
                    name="installationDate"
                  >
                    <DatePicker
                      locale={user.preferredLang}
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                <Col xs={11}>
                  <FormItem<TAssetForm> label={t('assets:Notes')} name="notes">
                    <TextArea
                      placeholder={t('assets:Add a note')}
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={14}>
                  <Attachments
                    values={{ attachments: documents.map(({ uuid }) => uuid) }}
                    handleUpload={(UUID: string) => {
                      const allDocuments = [...documents];

                      allDocuments.push({
                        uuid: UUID,
                      });

                      setDocuments(allDocuments);
                    }}
                  />
                </Col>
              </Row>
            </StyledPanel>
            <StyledPanel key={3} header="Purchase">
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={12}>
                  <FormItem<TAssetForm>
                    label={t('assets:Purchase Date')}
                    name="purchase.date"
                  >
                    <DatePicker
                      locale={user.preferredLang}
                      style={{ width: '100%' }}
                    />
                  </FormItem>
                </Col>
                <Col xs={11}>
                  <FormItem<TAssetForm>
                    label={t('assets:Purchase Price')}
                    name="purchase.price"
                    rules={[
                      {
                        pattern: /^[0-9.]+$/,
                        message: t('assets:Numbers Only'),
                      },
                    ]}
                  >
                    <Input placeholder="0.00" autoComplete="name-only" />
                  </FormItem>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={12}>
                  <FormItem<TAssetForm>
                    label={t('assets:Purchase Order')}
                    name="purchase.orderNumber"
                  >
                    <Input
                      placeholder={t('assets:Order Number')}
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
                <Col xs={11}>
                  <FormItem<TAssetForm>
                    label={t('assets:Invoice Number')}
                    name="purchase.invoiceNumber"
                  >
                    <Input
                      placeholder={t('assets:Invoice Number')}
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
              </Row>
            </StyledPanel>
            <StyledPanel key={4} header="Vendor">
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={12}>
                  {!createVendor.create && (
                    <FormItem<TAssetForm>
                      label={`${t('assets:Vendor')}`}
                      name="vendor.id"
                    >
                      <Select
                        options={assetVendorsOptions}
                        allowClear
                        handleOptionCreation={
                          createVendor.value
                            ? (_value = '') => {
                                const value = _value.trim();

                                if (value === '') return;

                                form.setFieldsValue({
                                  'vendor.address': '',
                                  'vendor.displayName': value,
                                  'vendor.phone': '',
                                  'vendor.website': '',
                                });
                                setCreateVendor({ value, create: true });
                              }
                            : undefined
                        }
                        placeholder={t('assets:Select')}
                        showSearch
                        onChange={(vendorId) => {
                          const vendor = assetVendorsFormated.find(
                            ({ id }) => id === vendorId,
                          );

                          if (!vendor) return;

                          form.setFieldsValue({
                            'vendor.id': vendor.id,
                            'vendor.address': vendor.address,
                            'vendor.displayName': vendor.displayName,
                            'vendor.phone': vendor.phone,
                            'vendor.website': vendor.website,
                          });
                        }}
                        onClear={() => {
                          form.setFieldsValue({
                            'vendor.id': '',
                            'vendor.address': '',
                            'vendor.displayName': '',
                            'vendor.phone': '',
                            'vendor.website': '',
                          });
                        }}
                        onSearch={(value) => {
                          setCreateVendor({
                            value,
                            create: createVendor.create,
                          });
                        }}
                        filterOption={(input, option) =>
                          String(option?.label)
                            ?.toLowerCase()
                            .includes(input?.toLowerCase())
                        }
                        searchValue={createVendor.value}
                      />
                    </FormItem>
                  )}
                  {createVendor.create && (
                    <FormItem<TAssetForm>
                      label={`${t('assets:Vendor')}`}
                      name="vendor.displayName"
                      rules={[
                        {
                          pattern: /[^\s*$]/g,
                          message: t('assets:Vendor Name field is required'),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t('assets:Vendor')}
                        onBlur={(e) =>
                          e.target.value === '' &&
                          setCreateVendor({ create: false, value: '' })
                        }
                        autoComplete="name-only"
                        autoFocus
                      />
                    </FormItem>
                  )}
                </Col>
                <Col xs={11}>
                  <FormItem<TAssetForm>
                    label={t('assets:Website')}
                    name="vendor.website"
                    rules={[
                      { pattern: urlPattern, message: t('assets:invalid url') },
                    ]}
                  >
                    <Input
                      placeholder={t('assets:Website')}
                      type="url"
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={11}>
                  <FormItem<TAssetForm>
                    label={t('assets:Address')}
                    name="vendor.address"
                  >
                    <TextArea
                      placeholder={'123 Drury Lane\nAustin, TX 78730'}
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem<TAssetForm>
                    label={t('assets:Phone Number')}
                    name="vendor.phone"
                  >
                    <Input
                      placeholder="(123) 456-7890"
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
              </Row>
            </StyledPanel>
            <StyledPanel key={5} header="Warranty">
              <Row align="top" gutter={5} justify="start" wrap={false}>
                <Col xs={3}>
                  <FormItem<TAssetForm>
                    label={`${t('assets:Coverage')}`}
                    name="warranty.number"
                    dependencies={[
                      'warranty.warrantyPeriodType',
                      'warranty.description',
                    ]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value &&
                            (!!getFieldValue('warranty.warrantyPeriodType') ||
                              !!getFieldValue('warranty.description'))
                          ) {
                            return Promise.reject(
                              new Error('Coverage is a required field.'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                      {
                        pattern: /^[0-9]+$/,
                        message: t('assets:Integer numbers only'),
                      },
                    ]}
                  >
                    <Input placeholder="#" autoComplete="name-only" />
                  </FormItem>
                </Col>
                <Col xs={6}>
                  <FormItem<TAssetForm>
                    name="warranty.warrantyPeriodType"
                    dependencies={['warranty.number', 'warranty.description']}
                    style={{
                      marginTop: SPACING.SIZE_XL,
                    }}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value &&
                            (!!getFieldValue('warranty.number') ||
                              !!getFieldValue('warranty.description'))
                          ) {
                            return Promise.reject(
                              new Error('Coverage Period is a required field.'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Select
                      options={[
                        { value: 'Day', label: 'Days' },
                        { value: 'Month', label: 'Months' },
                        { value: 'Year', label: 'Years' },
                      ]}
                      allowClear
                      placeholder={t('assets:Select')}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row align="middle" justify="space-between" wrap={false}>
                <Col xs={24}>
                  <FormItem<TAssetForm>
                    label={t('assets:Description')}
                    name="warranty.description"
                  >
                    <TextArea
                      placeholder={t('assets:Add a description')}
                      autoComplete="name-only"
                    />
                  </FormItem>
                </Col>
              </Row>
            </StyledPanel>
          </StyledCollapse>
        </Form>
      )}
    </StyledDrawer>
  );
}
