import React, { MouseEvent, useState } from 'react';

import { Button, COLORS, ConfigProvider } from '@optii-solutions/ui-library';

import { TButtonProps } from './ButtonGroup.types';
import { ButtonGroupStyles } from './ButtonGroup.styles';

export function ButtonGroup(props: TButtonProps) {
  const { options, firstLineMaxElements, handleButtonClick } = props;

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontSize: 14,
            defaultBg: COLORS.neutral[3],
            defaultHoverBg: COLORS.secondary[4],
            defaultHoverColor: COLORS.neutral[1],
            defaultHoverBorderColor: COLORS.secondary[4],
          },
        },
      }}
    >
      <div style={ButtonGroupStyles.container}>
        {options.map((option, index) => {
          const key = `option-button-${option.value}-${option.active}`;
          const { label, value, active } = option;

          return (
            <Button
              key={key}
              value={value}
              onClick={(e: MouseEvent<HTMLElement>) => {
                handleButtonClick(e);
              }}
              style={ButtonGroupStyles.button({
                firstLineMaxElements,
                index,
                active,
              })}
            >
              {label}
            </Button>
          );
        })}
      </div>
    </ConfigProvider>
  );
}
