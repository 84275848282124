// Users
import inviteUser from './users/inviteUser';
import getUserInfo from './users/getUserInfo';
import getUserByUsername from './users/getUserByUsername';
import getUserByEmail from './users/getUserByEmail';
import updateUserInfo from './users/updateUserInfo';
import getUserAccess from './users/getUserAccess';
import getInitialUser from './users/getInitialUser';
import getUserList from './users/getUsersList';
import getUserStats from './users/getUserStats';
import verifyUserByEmail from './users/verifyUserByEmail';
import verifyUserByEmailMulti from './users/verifyUserByEmailV2';
import verifyUserByUsername from './users/verifyUserByUsername';
import changePassword from './users/changePassword';

// Invitation
import getInvite from './invites/getInvite';
import acceptInvite from './invites/acceptInvite';
import getInviteList from './invites/getInviteList';
import updateInvite from './invites/updateInvite';
import deleteInvite from './invites/deleteInvite';
import createBulkInvitation from './invites/createBulkInvitation';
import sendBulkInvitation from './invites/sendBulkInvitation';

// Departments
import getDepartmentsList from './departments/getDepartmentsList';
import createDepartment from './departments/createDepartment';
import deleteDepartment from './departments/deleteDepartment';
import updateDepartment from './departments/updateDepartment';

// Employment Types
import getTypeList from './employmentTypes/getTypeList';
import createType from './employmentTypes/createType';
import deleteType from './employmentTypes/deleteType';
import updateType from './employmentTypes/updateType';

// Properties
import createProperty from './properties/createProperty';
import getPropertyById from './properties/getPropertyById';
import updateProperty from './properties/updateProperty';
import getPropertiesList from './properties/getPropertiesList';

// Organizations
import createOrganization from './organizations/createOrganization';
import getOrganizationById from './organizations/getOrganizationById';
import getOrganizationsList from './organizations/getOrganizationsList';
import updateOrganization from './organizations/updateOrganization';
import deleteOrganization from './organizations/deleteOrganization';

// Roles
import createRole from './roles/createRole';
import deleteRole from './roles/deleteRole';
import getRolesList from './roles/getRolesList';
import updateRole from './roles/updateRole';
import getProductsList from './roles/getProductsList';
import getPermissionsList from './roles/getPermissionsList';

// Schedule
import getSchedule from './schedule/getSchedule';
import getScheduleBySingleDate from './schedule/getScheduleBySingleDate';
import getScheduleMetrics from './schedule/getScheduleMetrics';
import getScheduleAndMetrics from './schedule/getScheduleAndMetrics';
import createSchedule from './schedule/createSchedule';
import createScheduleEntry from './schedule/createScheduleEntry';
import updateScheduleEntry from './schedule/updateScheduleEntry';
import deleteScheduleEntry from './schedule/deleteScheduleEntry';
import deleteScheduleEmployee from './schedule/deleteScheduleEmployee';
import addScheduleEmployeesList from './schedule/addScheduleEmployeesList';
import findIndividualSchedule from './schedule/findIndividualSchedule';
import publishSchedule from './schedule/publishSchedule';
import copySchedule from './schedule/copySchedule';
import entriesProcessedSubscription from './schedule/entriesProcessedSubscription';
import editMultiEntries from './schedule/editMultiEntries';
import deleteMultiEntries from './schedule/deleteMultiEntries';

// Employees
import getEmployeeByUserId from './employee/getEmployeeByUserId';
import getEmployeesByDepartment from './employee/getEmployeesByDepartment';
import updateEmployeeByUserId from './employee/updateEmployeeByUserId';
import getEmployeeList from './employee/getEmployeeList';
import getChatEmployees from './employee/getChatEmployees';
import getTeamMembersList from './employee/getTeamMembersList';
import getEmployeeState from './employee/getEmployeeState';
import setEmployeeWorkingState from './employee/setEmployeeWorkingState';

// Jobs
import addJob from './jobs/addJob';
import addJobItem from './jobs/addJobItem';
import createRepeatingJob from './jobs/createRepeatingJob';
import convertTaskToJob from './jobs/convertTaskToJob';
import getRepeatingJobSummaries from './jobs/getRepeatingJobSummaries';
import deleteRepeatingJob from './jobs/deleteRepeatingJob';
import getRepeatingJobOccurrenceSummary from './jobs/getRepeatingJobOccurrenceSummary';
import getRepeatingJobTemplateById from './jobs/getRepeatingJobTemplateById';
import deleteJobItem from './jobs/deleteJobItem';
import getJobDefaults from './jobs/getJobDefaults';
import getJobItems from './jobs/getJobItems';
import getJobItemsList from './jobs/getJobItemsList';
import getJobsList from './jobs/getJobsList';
import updateJob from './jobs/updateJob';
import updateJobItem from './jobs/updateJobItem';
import jobUpdateSub from './jobs/jobUpdateSubscription';
import jobUpdateSubGo from './jobs/jobUpdateSubscriptionGo';
import getDoneJobs from './jobs/getDoneJobs';
import getJobAudit from './jobs/getJobAudit';
import getJobItemByName from './jobs/getJobItemByName';
import getJobItemById from './jobs/getJobItemById';
import getListJobs from './jobs/getListJobs';
import getListJobsWithDuplicates from './jobs/getListJobsWithDuplicates';
import getJobsSettings from './jobs/getJobsSettings';
import setJobsSettings from './jobs/setJobsSettings';
import userLoggedInSubscription from './users/userLoggedInSubscription';
import updateMultipleJobs from './jobs/updateMultipleJobs';
import getJobItemsDefs from './jobs/getJobItemsDefs';
import updateJobMetadata from './jobs/updateJobMetadata';
import updateRepeatingJob from './jobs/updateRepeatingJob';
import updateAdhocJobNote from './jobs/updateAdhocJobNote';
import removeAdhocJobNote from './jobs/removeAdhocJobNote';
import getTodayJobs from './jobs/getTodayJobs';

// Locations
import getLocationsMenu from './locations/getLocationsMenu';
import getListLocationMinimal from './locations/getListLocationMinimal';
import getLocationHousekeepingStatus from './locations/getLocationHousekeepingStatus';

// Twilio
import createTwilioToken from './twilio/createTwilioToken';
import registerDevice from './twilio/registerDevice';

// Files
import storeFile from './files/storeFile';
import getFileById from './files/getFileById';

// Floor PLan
import importLocations from './floorPlan/importLocations';
import getImportById from './floorPlan/getImportById';
import getLocationsList from './floorPlan/getLocationsList';
import getLocationById from './floorPlan/getLocationById';
import getLocationTypesList from './floorPlan/getLocationTypesList';
import getRoomTypesList from './floorPlan/getRoomTypesList';
import createLocation from './floorPlan/createLocation';
import deleteLocation from './floorPlan/deleteLocation';
import updateLocation from './floorPlan/updateLocation';
import deleteLocationType from './floorPlan/deleteLocationType';
import updateLocationType from './floorPlan/updateLocationType';
import createLocationType from './floorPlan/createLocationType';
import getLocationTypeByName from './floorPlan/getLocationTypeByName';
import getLocationTypeByCode from './floorPlan/getLocationTypeByCode';
import createRoomType from './floorPlan/createRoomType';
import deleteRoomType from './floorPlan/deleteRoomType';
import updateRoomType from './floorPlan/updateRoomType';
import getRoomTypeByName from './floorPlan/getRoomTypeByName';
import getRoomTypeByCode from './floorPlan/getRoomTypeByCode';
import moveLocations from './floorPlan/moveLocations';
import getLocationPropertyTags from './floorPlan/getLocationPropertyTags.ts';
import getReservations from './reservations/getReservations.ts';
import getReservationById from './reservations/getReservationById.ts';
import getRoomReservations from './reservations/getRoomReservations.ts';

// Rooms
import getRoomSummary from './rooms/getRoomSummary.ts';
import updateCleaningExceptionStatus from './rooms/updateCleaningExceptionStatus.ts';

// Reports
import getReportsList from './reports/getReportsList';
import getEncryptedEmbedUrl from './reports/getEncryptedEmbedUrl';

// Escalations
import getEscalationDefinitionsList from './escalations/getEscalationDefinitionsList';
import addEscalationDefinition from './escalations/addEscalationDefinition';
import deleteEscalationDefinition from './escalations/deleteEscalationDefinition';
import escalationDefinitionNameExists from './escalations/escalationDefinitionNameExists';
import updateEscalationDefintion from './escalations/updateEscalationDefintion';

// Assets
import findAllAssetTypes from './assets/assetTypes';
import findAllBrands from './assets/brands';
import createAssetType from './assets/createAssetType';
import removeAssetType from './assets/removeAssetType';
import updateAssetType from './assets/updateAssetType';
import findAllAssets from './assets/assets';
import createAsset from './assets/createAsset';
import createBulkAsset from './assets/createBulkAsset';
import updateAsset from './assets/updateAsset';
import removeAsset from './assets/removeAsset';
import findAllModels from './assets/models';
import getAssetJobs from './assets/assetJobs';
import getAssetVendors from './assets/assetVendors';
import getAssetTypeByID from './assets/getAssetTypeById';
import getAssetByID from './assets/getAssetById';
import getAllAssetsID from './assets/getAssetsId';

// Projects
import checkForDuplicateProject from './projects/checkForDuplicateProject';
import createProject from './projects/createProject';
import getProjectsList from './projects/getProjectsList';
import GetProjectSummaries from './projects/getProjectSummaries';
import getProject from './projects/getProject';
import getProjectCycles from './projects/getProjectCycles';
import getProjectCycleJobs from './projects/getProjectCycleJobs';
import assignProjectCycleJob from './projects/assignProjectCycleJob';
import bulkAssignProjectCycleJob from './projects/bulkAssignProjectCycleJob';
import bulkAssignVirtualProjectCycleJob from './projects/bulkAssignVirtualProjectCycleJob';
import updateProject from './projects/updateProject';
import removeProject from './projects/removeProject';
import getProjectAdhocJobsTotalCount from './projects/getProjectAdhocJobsTotalCount';
import updateProjectCycle from './projects/updateProjectCycle';
import updateVirtualProjectCycle from './projects/updateVirtualProjectCycle';
import removeProjectCycle from './projects/removeProjectCycle';
import removeVirtualProjectCycle from './projects/removeVirtualProjectCycle';
import projectCycleAssignedJobsTotalCount from './projects/projectCycleAssignedJobsTotalCount';
import deleteProjectCycleJobs from './jobs/deleteProjectCycleJobs';
import undeleteProjectCycleJobs from './jobs/undeleteProjectCycleJobs';
import getQueuedJobById from './jobs/getQueuedJobById';

// Checklists
import checklistTemplate from './checklistTemplates/checklistTemplate';
import checklistTemplates from './checklistTemplates/checklistTemplates';
import createChecklistTemplate from './checklistTemplates/createChecklistTemplate';
import updateChecklistTemplate from './checklistTemplates/updateChecklistTemplate';
import deleteChecklistTemplate from './checklistTemplates/deleteChecklistTemplate';
import duplicateChecklistTemplateName from './checklistTemplates/duplicateChecklistTemplateName';
import updateChecklistTask from './checklistTemplates/updateChecklistTask';
import getParentAndTypeLocations from './projects/getParentAndTypeLocations';
import copyChecklistTemplate from './checklistTemplates/copyChecklistTemplate';
import predictJobDuration from './jobs/predictJobDuration';
import suggestion from './chat/suggestion';

// Activity Logs
import getLocationLogs from './activityLog/getLocationLogs';
import getJobLogs from './activityLog/getJobLogs';
import registerFirebaseDevice from './twilio/registerFirebaseDevice';

// ------------------ Assignments --------------------

// Invites
export const GET_INVITE = getInvite;
export const INVITE_USER = inviteUser;
export const ACCEPT_INVITE = acceptInvite;
export const GET_INVITE_LIST = getInviteList;
export const UPDATE_INVITE = updateInvite;
export const DELETE_INVITE = deleteInvite;
export const CREATE_BULK_INVITATION = createBulkInvitation;
export const SEND_BULK_INVITATION = sendBulkInvitation;

// Users
export const GET_USER = getUserInfo;
export const GET_USER_BY_USERNAME = getUserByUsername;
export const GET_USER_BY_EMAIL = getUserByEmail;
export const UPDATE_USER = updateUserInfo;
export const GET_USER_STATS = getUserStats;
export const GET_USER_LIST = getUserList;
export const GET_USER_ACCESS = getUserAccess;
export const GET_INITIAL_USER = getInitialUser;
export const VERIFY_USER_BY_EMAIL = verifyUserByEmail;
export const VERIFY_USER_BY_EMAIL_MULTI = verifyUserByEmailMulti;
export const VERIFY_USER_BY_USERNAME = verifyUserByUsername;
export const CHANGE_PASSWORD = changePassword;
export const USER_LOGGED_IN_SUB = userLoggedInSubscription;

// CHAT
export const SUGGESTION = suggestion;

// Departments
export const GET_DEPARTMENTS_LIST = getDepartmentsList;
export const CREATE_DEPARTMENT = createDepartment;
export const DELETE_DEPARTMENT = deleteDepartment;
export const UPDATE_DEPARTMENT = updateDepartment;

// Employment Types
export const GET_TYPE_LIST = getTypeList;
export const CREATE_TYPE = createType;
export const DELETE_TYPE = deleteType;
export const UPDATE_TYPE = updateType;

// Properties
export const CREATE_PROPERTY = createProperty;
export const GET_PROPERTY_BY_ID = getPropertyById;
export const UPDATE_PROPERTY = updateProperty;
export const GET_PROPERTIES_LIST = getPropertiesList;

// Orgs
export const GET_ORGANIZATION_LIST = getOrganizationsList;
export const CREATE_ORGANIZATION = createOrganization;
export const GET_ORGANIZATION_BY_ID = getOrganizationById;
export const UPDATE_ORGANIZATION = updateOrganization;
export const DELETE_ORGANIZATION = deleteOrganization;

// Roles & Permissions
export const CREATE_ROLE = createRole;
export const DELETE_ROLE = deleteRole;
export const GET_ROLES_LIST = getRolesList;
export const UPDATE_ROLE = updateRole;
export const GET_PRODUCTS_LIST = getProductsList;
export const GET_PERMISSIONS_LIST = getPermissionsList;

// Schedule
export const GET_SCHEDULE = getSchedule;
export const GET_SCHEDULE_BY_SINGLE_DATE = getScheduleBySingleDate;
export const GET_SCHEDULE_METRICS = getScheduleMetrics;
export const GET_SCHEDULE_AND_METRICS = getScheduleAndMetrics;
export const CREATE_SCHEDULE = createSchedule;
export const CREATE_SCHEDULE_ENTRY = createScheduleEntry;
export const UPDATE_SCHEDULE_ENTRY = updateScheduleEntry;
export const DELETE_SCHEDULE_ENTRY = deleteScheduleEntry;
export const DELETE_SCHEDULE_EMPLOYEE = deleteScheduleEmployee;
export const ADD_SCHEDULE_EMPLOYEES_LIST = addScheduleEmployeesList;
export const FIND_INDIVIDUAL_SCHEDULE = findIndividualSchedule;
export const PUBLISH_SCHEDULE = publishSchedule;
export const COPY_SCHEDULE = copySchedule;
export const EDIT_MULTI_ENTRIES = editMultiEntries;
export const DELETE_MULTI_ENTRIES = deleteMultiEntries;

// Employees
export const GET_EMPLOYEE_BY_USER_ID = getEmployeeByUserId;
export const GET_EMPLOYEES_BY_DEPARTMENT = getEmployeesByDepartment;
export const UPDATE_EMPLOYEE_BY_USER_ID = updateEmployeeByUserId;
export const GET_EMPLOYEE_LIST = getEmployeeList;
export const GET_CHAT_EMPLOYEES = getChatEmployees;
export const GET_TEAM_MEMBERS_LIST = getTeamMembersList;
export const GET_EMPLOYEE_STATE = getEmployeeState;
export const SET_EMPLOYEE_WORKING_STATE = setEmployeeWorkingState;

// Jobs
export const ADD_JOB = addJob;
export const ADD_JOB_ITEM = addJobItem;
export const CREATE_REPEATING_JOB = createRepeatingJob;
export const CONVERT_TASK_TO_JOB = convertTaskToJob;
export const GET_REPEATING_JOB_SUMMARIES = getRepeatingJobSummaries;
export const DELETE_REPEATING_JOB = deleteRepeatingJob;
export const GET_REPEATING_JOB_OCCURRENCE_SUMMARIES =
  getRepeatingJobOccurrenceSummary;
export const GET_REPEATING_JOB_TEMPLATE = getRepeatingJobTemplateById;
export const DELETE_JOB_ITEM = deleteJobItem;
export const GET_JOB_DEFAULTS = getJobDefaults;
export const GET_JOB_ITEMS = getJobItems;
export const GET_JOB_ITEMS_LIST = getJobItemsList;
export const GET_JOBS_LIST = getJobsList;
export const UPDATE_JOB = updateJob;
export const UPDATE_JOB_ITEM = updateJobItem;
export const JOB_UPDATED_SUB = jobUpdateSub;
export const JOB_UPDATED_SUB_GO = jobUpdateSubGo;
export const ENTRIES_UPDATED_SUB = entriesProcessedSubscription;
export const GET_DONE_JOBS = getDoneJobs;
export const GET_JOB_AUDIT = getJobAudit;
export const GET_JOB_ITEM_BY_NAME = getJobItemByName;
export const GET_JOB_ITEM_BY_ID = getJobItemById;
export const GET_LIST_JOBS = getListJobs; // for Team Member View
export const GET_LIST_JOBS_WITH_DUPLICATES = getListJobsWithDuplicates;
export const GET_JOBS_SETTINGS = getJobsSettings;
export const SET_JOBS_SETTINGS = setJobsSettings;
export const UPDATE_MULTIPLE_JOBS = updateMultipleJobs;
export const GET_JOB_ITEMS_DEFS = getJobItemsDefs;
export const UPDATE_JOB_META_DATA = updateJobMetadata;
export const UPDATE_REPEATING_JOB = updateRepeatingJob;
export const UPDATE_ADHOC_JOB_NOTE = updateAdhocJobNote;
export const REMOVE_ADHOC_JOB_NOTE = removeAdhocJobNote;
export const GET_TODAY_JOBS = getTodayJobs;
export const PREDICT_JOB_DURATION = predictJobDuration;

// Locations
export const GET_LOCATIONS = getLocationsMenu;
export const GET_LIST_LOCATION_MINIMAL_LOCATIONS = getListLocationMinimal;

// Twilio
export const CREATE_TWILIO_TOKEN = createTwilioToken;
export const REGISTER_DEVICE = registerDevice;
export const REGISTER_FIREBASE_DEVICE = registerFirebaseDevice;

// Files
export const STORE_FILE = storeFile;
export const GET_FILE_BY_ID = getFileById;

// Floor Plan
export const IMPORT_LOCATIONS = importLocations;
export const GET_IMPORT_BY_ID = getImportById;
export const GET_LOCATIONS_LIST = getLocationsList;
export const GET_LOCATION_BY_ID = getLocationById;
export const GET_LOCATION_TYPES_LIST = getLocationTypesList;
export const GET_LOCATION_HOUSEKEEPING_STATUS = getLocationHousekeepingStatus;
export const GET_ROOM_TYPES_LIST = getRoomTypesList;
export const CREATE_LOCATION = createLocation;
export const DELETE_LOCATION = deleteLocation;
export const UPDATE_LOCATION = updateLocation;
export const DELETE_LOCATION_TYPE = deleteLocationType;
export const UPDATE_LOCATION_TYPE = updateLocationType;
export const CREATE_LOCATION_TYPE = createLocationType;
export const GET_LOCATION_TYPE_BY_NAME = getLocationTypeByName;
export const GET_LOCATION_TYPE_BY_CODE = getLocationTypeByCode;
export const CREATE_ROOM_TYPE = createRoomType;
export const DELETE_ROOM_TYPE = deleteRoomType;
export const UPDATE_ROOM_TYPE = updateRoomType;
export const GET_ROOM_TYPE_BY_NAME = getRoomTypeByName;
export const GET_ROOM_TYPE_BY_CODE = getRoomTypeByCode;
export const MOVE_LOCATIONS = moveLocations;
export const GET_LOCATION_PROPERTY_TAGS = getLocationPropertyTags;

// Reservations
export const GET_RESERVATION_BY_ID = getReservationById;
export const GET_RESERVATIONS = getReservations;
export const GET_ROOM_RESERVATIONS = getRoomReservations;

// Rooms
export const GET_ROOM_SUMMARY = getRoomSummary;
export const UPDATE_CLEANING_EXCEPTION_STATUS = updateCleaningExceptionStatus;

// Reports
export const GET_REPORTS_LIST = getReportsList;
export const GET_ENCRYPTED_EMBED_URL = getEncryptedEmbedUrl;

// Escalations
export const GET_ESCALATION_DEFINITIONS_LIST = getEscalationDefinitionsList;
export const ADD_ESCALATION_DEFINITION = addEscalationDefinition;
export const DELETE_ESCALATION = deleteEscalationDefinition;
export const GET_ESCALATION_DUPE_STATUS = escalationDefinitionNameExists;
export const UPDATE_ESCALATION_DEFINITION = updateEscalationDefintion;

// Assets
export const FIND_ALL_ASSET_TYPES = findAllAssetTypes;
export const GET_ALL_ASSETS_ID = getAllAssetsID;
export const FIND_ALL_BRANDS = findAllBrands;
export const CREATE_ASSET_TYPE = createAssetType;
export const REMOVE_ASSET_TYPE = removeAssetType;
export const UPDATE_ASSET_TYPE = updateAssetType;
export const FIND_ALL_ASSETS = findAllAssets;
export const CREATE_ASSET = createAsset;
export const CREATE_BULK_ASSET = createBulkAsset;
export const UPDATE_ASSET = updateAsset;
export const REMOVE_ASSET = removeAsset;
export const FIND_ALL_MODELS = findAllModels;
export const GET_ASSET_JOBS = getAssetJobs;
export const GET_ASSET_VENDORS = getAssetVendors;
export const GET_ASSET_TYPE_BY_ID = getAssetTypeByID;
export const GET_ASSET_BY_ID = getAssetByID;

// Projects
export const CHECK_FOR_DUPLICATE_PROJECT = checkForDuplicateProject;
export const CREATE_PROJECT = createProject;
export const UPDATE_PROJECT = updateProject;
export const REMOVE_PROJECT = removeProject;
export const GET_PROJECTS_LIST = getProjectsList;
export const GET_PROJECT_SUMMARIES = GetProjectSummaries;
export const GET_PROJECT = getProject;
export const GET_PROJECT_CYCLES = getProjectCycles;
export const GET_PROJECT_CYCLE_JOBS = getProjectCycleJobs;
export const GET_PROJECT_CYCLE_ASSIGNED_JOBS_TOTAL_COUNT =
  projectCycleAssignedJobsTotalCount;
export const ASSIGN_PROJECT_CYCLE_JOB = assignProjectCycleJob;
export const BULK_ASSIGN_PROJECT_CYCLE_JOB = bulkAssignProjectCycleJob;
export const BULK_ASSIGN_VIRTUAL_PROJECT_CYCLE_JOB =
  bulkAssignVirtualProjectCycleJob;
export const GET_PROJECT_ADHOC_JOBS_TOTAL_COUNT = getProjectAdhocJobsTotalCount;
export const UPDATE_PROJECT_CYCLE = updateProjectCycle;
export const UPDATE_VIRTUAL_PROJECT_CYCLE = updateVirtualProjectCycle;
export const REMOVE_PROJECT_CYCLE = removeProjectCycle;
export const REMOVE_VIRTUAL_PROJECT_CYCLE = removeVirtualProjectCycle;
export const DELETE_PROJECT_CYCLE_JOBS = deleteProjectCycleJobs;
export const UNDELETE_PROJECT_CYCLE_JOBS = undeleteProjectCycleJobs;
export const GET_QUEUED_JOB_BY_ID = getQueuedJobById;
export const GET_PARENT_AND_TYPE_LOCATIONS = getParentAndTypeLocations;

// Checklists
export const CHECKLIST_TEMPLATE = checklistTemplate;
export const CHECKLIST_TEMPLATES = checklistTemplates;
export const CREATE_CHECKLIST_TEMPLATE = createChecklistTemplate;
export const UPDATE_CHECKLIST_TEMPLATE = updateChecklistTemplate;
export const COPY_CHECKLIST_TEMPLATE = copyChecklistTemplate;
export const DELETE_CHECKLIST_TEMPLATE = deleteChecklistTemplate;
export const CHECK_FOR_DUPLICATE_CHECKLIST_TEMPLATE =
  duplicateChecklistTemplateName;
export const UPDATE_CHECKLIST_TASK = updateChecklistTask;

// Activity Logs
export const GET_LOCATION_LOGS = getLocationLogs;
export const GET_JOB_LOGS = getJobLogs;
