import { createContext } from 'react';

export const Session = createContext({
  user: { id: '' },
  prevPage: false,
  isMobileMenu: false,
  setPrevPage: () => {},
  toggleMobileMenu: () => {},
  property: false,
  setProperty: () => {},
  globalSnack: (_: unknown) => {},
  token: {},
  twilioClient: () => {},
  unreadMsgsCount: 0,
  setUnreadMsgsCount: () => {},
  calcUnreadMsgsCount: () => {},
});
