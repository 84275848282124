import { gql } from '@apollo/client';

export default gql`
  query getLocationById($id: ID!) {
    GetLocationById(id: $id) {
      id
      isActive
      parentId
      isVirtual
      virtualChildrenLocationIds
      parentLocation {
        id
        displayName
        longDisplayName
        name
        number
      }
      associatedVirtualLocations {
        id
        name: shortDisplayName
        parentId
      }
      childrenIds
      status
      locationType {
        id
        displayName
        code
        squareUnit
        note
      }
      roomType {
        id
        displayName
        code
        note
      }
      number
      name
      description
      displayName
      longDisplayName
      shortDisplayName
      class
      squareUnit
      notes
      sequence
      tags {
        name
      }
      title: displayName
      pId: parentId
      value: id
      __typename
    }
  }
`;
