import {
  Conversation,
  JSONValue,
  Message,
  Paginator,
  Participant,
} from '@twilio/conversations';
import { GA_EVENTS, GoogleAnalyticsClient } from '@optii/shared';
import { TFunction } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { UserData } from '@optii/shared/contexts/types';
import { FileList, MessageStatus, TaggedUser } from '../types';
import { CHANNEL_TYPES, LANGUAGES } from '../constants';

export const OPTII_AUTHOR_ID = '-1';

export function filterTaggedUsers(
  input: string,
  setTaggedUsers: Dispatch<SetStateAction<TaggedUser[]>>,
) {
  setTaggedUsers((prev) =>
    prev.filter((label) => {
      const userLabelMention = `@${label}`;

      return input.includes(userLabelMention);
    }),
  );
}

export function generateAttributes(
  fileList: FileList[],
  isPrivateChannel: boolean,
  channel?: Conversation,
  user?: UserData,
) {
  const language = LANGUAGES.find(
    ({ id: languageId }) => languageId === user?.preferredLang,
  );

  const sender = {
    id: user?.id,
    userFirstName: user?.firstName,
    userLastName: user?.lastName,
    language,
  };

  const attachments = fileList.map(({ name, url }) => ({ name, url }));

  if (isPrivateChannel) {
    const receiver = channel?.uniqueName
      ?.split('_')
      .filter((recipientId) => recipientId !== user?.id)
      .map((item) => ({ id: item }));

    return {
      sender,
      channel: {
        type: CHANNEL_TYPES.private,
      },
      attachments,
      receiver,
    };
  }
  return {
    sender,
    attachments,
    channel: {
      type: CHANNEL_TYPES.public,
    },
  };
}

const VIDEO_EXTENSIONS = ['.mp4', '.avi', '.mov', '.webm', 'ogg'];
const IMAGE_EXTENSIONS = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.svg',
  '.JPG',
  '.PNG',
  '.JPEG',
  '.GIF',
  '.SVG',
];

type GetMessageStatusPromise = Promise<{
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
}>;

export async function getMessageStatus(
  message: Message,
  participants: Participant[],
  loggedUserId: string,
): GetMessageStatusPromise {
  const statuses = {
    [MessageStatus.Delivered]: 0,
    [MessageStatus.Read]: 0,
    [MessageStatus.Failed]: 0,
    [MessageStatus.Sending]: 0,
  };

  if (message.index === -1) {
    return Promise.resolve({
      ...statuses,
      [MessageStatus.Sending]: 1,
    });
  }

  participants.forEach((participant) => {
    if (participant.identity === loggedUserId || participant.type !== 'chat') {
      return;
    }

    if (
      participant.lastReadMessageIndex &&
      participant.lastReadMessageIndex >= message.index
    ) {
      statuses[MessageStatus.Read] += 1;
    } else if (participant.lastReadMessageIndex !== -1) {
      statuses[MessageStatus.Delivered] += 1;
    }
  });

  if (message.aggregatedDeliveryReceipt) {
    const receipts = await message.getDetailedDeliveryReceipts(); // paginated backend query every time

    receipts.forEach((receipt) => {
      if (receipt.status === 'read') {
        statuses[MessageStatus.Read] += 1;
      }

      if (receipt.status === 'delivered') {
        statuses[MessageStatus.Delivered] += 1;
      }

      if (receipt.status === 'failed' || receipt.status === 'undelivered') {
        statuses[MessageStatus.Failed] += 1;
      }

      if (receipt.status === 'sent' || receipt.status === 'queued') {
        statuses[MessageStatus.Sending] += 1;
      }
    });
  }

  return statuses;
}

export async function getAllConversationList(
  subscribedConversations?: Paginator<Conversation>,
  hasNextPage?: boolean,
  existingConversations: Conversation[] = [],
) {
  const allConversations = existingConversations.concat(
    subscribedConversations?.items || [],
  );

  if (!hasNextPage) {
    return allConversations;
  }

  const nextPageConversation = await subscribedConversations?.nextPage();

  return getAllConversationList(
    nextPageConversation,
    nextPageConversation?.hasNextPage,
    allConversations,
  );
}

export const isFileExtensionImageOrVideo = (name: string) => {
  const FILE_NAME_MATCH_REGEX =
    name.match(/\.\w{3,4}($)/) && name.match(/\.\w{3,4}($)/)?.[0];

  if (FILE_NAME_MATCH_REGEX) {
    const isVideo = VIDEO_EXTENSIONS.includes(FILE_NAME_MATCH_REGEX);

    const isImage = IMAGE_EXTENSIONS.includes(FILE_NAME_MATCH_REGEX);

    return {
      isVideo,
      isImage,
    };
  }

  return {
    isVideo: false,
    isImage: false,
  };
};

export const generateBase64ImageFromVideo = (url: string) =>
  new Promise<string>((resolve) => {
    const video = document.createElement('video');
    video.src = url;
    video.crossOrigin = 'anonymous';
    video.playsInline = true;
    video.onloadedmetadata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');

      video.currentTime = 0;
      video.onseeked = () => {
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL('image/png');
        resolve(base64Image);
      };
    };
  });

export const separateImagesAndTextAttachments = (attachments: FileList[]) => {
  const mediaAttachments: FileList[] = [];
  const textAttachments: FileList[] = [];

  attachments.forEach((attachment) => {
    const { isVideo, isImage } = isFileExtensionImageOrVideo(attachment.name);

    if (isVideo || isImage) mediaAttachments.push(attachment);
    else textAttachments.push(attachment);
  });

  return {
    mediaAttachments,
    textAttachments,
  };
};

export function formatBody(
  message: string,
  taggedUsers: TaggedUser[],
  t: TFunction<['chat', 'common']>,
) {
  let formattedMessage = message;

  taggedUsers.forEach(({ label, key }) => {
    const mention =
      label !== t('chat:Channel') ? `[[${key}@${label}]]` : '[[@Channel]]';

    const REGEX = new RegExp(`@${label}(?!\\S)`, 'g');

    formattedMessage = formattedMessage.replace(REGEX, mention);
  });

  return formattedMessage;
}

export const sendMessage = async (
  setValue: Dispatch<SetStateAction<string | undefined>>,
  taggedUsers: TaggedUser[],
  t: TFunction<['chat', 'common']>,
  attributes: JSONValue,
  channel?: Conversation,
  value?: string,
  setDataSource?: Dispatch<SetStateAction<Paginator<Message> | undefined>>,
) => {
  if (!channel || !value) return;

  const formattedMessageBody = formatBody(value, taggedUsers, t);

  const messageBuilder = channel.prepareMessage().setBody(formattedMessageBody);
  setValue(undefined);

  if (taggedUsers.length > 0) GoogleAnalyticsClient.event(GA_EVENTS.tagUser);

  messageBuilder.setAttributes(attributes as JSONValue);

  const author = (attributes as { sender: { id: string } }).sender.id;

  const optimisticResponse = {
    body: messageBuilder.build().text,
    author,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    index: -1,
    sid: messageBuilder.build().contentSid,
    attributes,
  };

  if (typeof setDataSource === 'function') {
    setDataSource((prev) => {
      if (prev) {
        return {
          ...prev,
          items: prev.items.concat([optimisticResponse as unknown as Message]),
        };
      }
      return prev;
    });
  }

  setTimeout(() => {
    const list = document.getElementById('scroll');

    if (list) {
      list.scrollTop = list.scrollHeight;
    }
  }, 10);

  const sentMessageIndex = await messageBuilder.buildAndSend();

  try {
    await channel.advanceLastReadMessageIndex(sentMessageIndex ?? 0);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
