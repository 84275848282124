import styled from 'styled-components/macro';
import Button from '../Button';

export const ButtonCautionStyled = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.rose};

  :active,
  :hover {
    ${(props) => props.theme.shadows.button}
  }

  :active {
    background-color: ${(props) => props.theme.colors.roseXDark};
  }
`;
