import type { ButtonProps, ThemeConfig } from 'antd';
import { Button as AntButton, ConfigProvider } from 'antd';
export type Props = ButtonProps & {
  theme?: ThemeConfig;
};

export function Button(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntButton {...rest}>{props.children}</AntButton>
    </ConfigProvider>
  );
}
