import { gql } from "@apollo/client";

export default gql`
  query ListJobsCards(
    $cursor: String
    $first: Int
    $orderBy: String
    $filters: Map
  ) {
    page: ListJobsCards(
      filters: $filters
      first: $first
      after: $cursor
      orderBy: $orderBy
    ) {
      edges {
        node {
          ... on Job {
            id
            item
            action
            priority
            isEscalated
            status
            doBy
            parentChecklistTaskId
            locations {
              id
              name
              type
              shortDisplayName
              number
            }
            notes {
              createdBy {
                id
                userName
                firstName
                lastName
              }
              text
              time
            }
            assignee {
              firstName
              lastName
              id
            }
            reporter {
              id
              userName
              firstName
              lastName
            }
            department {
              id
              displayName
              departmentCode
            }
            role {
              id
              name
              description
            }
            periods {
              jobId
              periodStart
              periodEnd
              jobStatus
              assigneeId
              assignee {
                id
                userName
                firstName
                lastName
              }
            }
            attachments
            createdAt
            startedAt
            completedAt
            metadata {
              externalId
              source
              publicAttributes
              privateAttributes
            }
          }
        }
      }
    }
  }
`;
