import React, { useCallback, useContext, useState } from 'react';
import { TextInput, Error, TaskLabel, FlexItem } from 'checklists';
import { useTranslation } from 'react-i18next';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { GA_EVENTS } from '@optii/shared/constants/gaEvents';
import { ChecklistItemContext } from 'checklists/contexts';
import {
  CHECKLIST_ACTION_STATES,
  CHECKLIST_TASK_FIELDS,
} from 'checklists/constants';

export function ErrorMessage({ errorMessage }) {
  if (errorMessage === 'Numbers only') {
    GoogleAnalyticsClient.event(GA_EVENTS.numericTaskNonNumericError);
  }
  return <Error>{errorMessage} </Error>;
}

function TaskNameField({ placeholder: propsPlaceholder, renderTitle }) {
  const [focus, setFocus] = useState(false);
  const { t } = useTranslation('checklist');
  const {
    errors,
    formValues,
    onFormChange,
    actionState,
    setActionState,
    isReadOnly,
    isFulfillment,
  } = useContext(ChecklistItemContext);

  const canEdit = actionState === CHECKLIST_ACTION_STATES.addingItem;
  const taskNameValue = formValues.label;
  const errorMessage = t('checklist:A task name is required');

  const placeholder = propsPlaceholder ?? t('Add Task');

  const goToEdit = useCallback(() => {
    if (
      !isReadOnly &&
      actionState !== CHECKLIST_ACTION_STATES.fulfillment &&
      !isFulfillment
    ) {
      setActionState(CHECKLIST_ACTION_STATES.addingItem);
      setFocus(true);
    }
  }, [actionState, setActionState, isFulfillment, isReadOnly]);

  const goToView = useCallback(() => {
    if (taskNameValue !== '') {
      setActionState(CHECKLIST_ACTION_STATES.viewingItem);
      setFocus(false);
    }
  }, [taskNameValue, setActionState]);

  const onChange = useCallback(
    (event) => {
      onFormChange({
        name: CHECKLIST_TASK_FIELDS.label,
        value: event.target.value,
      });
    },
    [onFormChange],
  );

  const RenderTitle = renderTitle;

  if (canEdit) {
    return (
      <FlexItem col>
        <TextInput
          placeholder={placeholder}
          value={taskNameValue}
          tabIndex={0}
          onChange={onChange}
          onBlur={goToView}
          autoFocus={focus}
          data-testid="checklist-task-add-name"
        />
        {errors.label && <ErrorMessage errorMessage={errorMessage} />}
      </FlexItem>
    );
  }

  if (!canEdit && !renderTitle) {
    return <TaskLabel onClick={goToEdit}>{taskNameValue}</TaskLabel>;
  }

  if (!canEdit && renderTitle) {
    return <RenderTitle goToEdit={goToEdit} />;
  }
}

export default TaskNameField;
