import { Tag as AntTag, ConfigProvider, TagProps, ThemeConfig } from 'antd';
import React from 'react';

type Props = TagProps & {
  theme?: ThemeConfig;
};

export function Tag(props: Props) {
  const { theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntTag {...rest} />
    </ConfigProvider>
  );
}
