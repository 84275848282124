import { gql } from "@apollo/client";

export const fields = `
  employeeID
  loggedIn
  working
  online`;

export default gql`
  query GetEmployeeState($userID: ID) {
    status: GetEmployeeState(userID: $userID) {
      ${fields}
    }
  }`;
