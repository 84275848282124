const gaEvent = {
  engineering: {
    category: "_engineering",
    actions: {
      forgotPassword: "Forgot Password"
    }
  },
  jobs: {
    category: "Jobs",
    actions: {
      add: "Job Page: Add Job",
      view: "Job Page: View Job",
      edit: "Job Page: Edit Job",
      delete: "Job Page: Delete Job",
      take: "Job Page: Take Job",
      start: "Job Page: Start Job",
      takeAndStart: "Job Page: Take and Start Job",
      cancel: "Job Page: Cancel Job",
      pause: "Job Page: Pause Job",
      complete: "Job Page: Complete Job",
      addNote: "Job Page: Add Note Job",
      viewActivity: "Job Page: View Activity",
      addFile: "Job Page: Add File Job",
      filter: "Job Page: Filter Job",
      sort: "Job Page: Sort Job",
      search: "Job Page: Search Job",
      undelete: "Job Page: Undelete Job"
    }
  },
  login: {
    category: "Login",
    actions: {
      login: "Login",
      troubleLoggingIn: "Trouble Logging In?",
      quickAccessCode: "Have a Quick Access Code?",
      tos: "Terms of Service"
    }
  },
  logout: {
    category: "Logout",
    actions: {
      loggedOut: "Logged Out"
    }
  },
  chat: {
    category: "Chat",
    actions: {
      sendMessage: "Chat: Send Message",
      tagUser: "Chat: Tag User",
      delete: "Chat: Delete",
      directMessage: "Chat: Add Direct Message",
      badgeCount: "Chat: Badge Count"
    }
  },
  userProfile: {
    category: "User Profile",
    actions: {
      viewPersonalInfo: "View Personal Information",
      editGeneral: "Edit User Profile: General",
      editContact: "Edit User Profile: Contact Details",
      editLanguage: "Edit User Profile: Language",
      editEmployment: "Edit User Profile: Employment",
      editPassword: "Edit User Profile: Password"
    }
  },
  help: {
    category: "Help",
    actions: {
      helpVideo: "Dashboard - Help Video",
      helpLink: "Dashboard - Learning Center Link",
      menuHelp: "User Profile - Get Help Link"
    }
  },
  misc: {
    category: "Miscellaneous",
    actions: {
      unknownFileUpload: "Unknown File Upload"
    }
  },
  escalations: {
    category: "Escalations",
    actions: {
      add: "Escalations Page: Add",
      view: "Escalations Page: View",
      edit: "Escalations Page: Edit",
      delete: "Escalations Page: Delete",
      search: "Escalations Page: Search"
    }
  },
  projects: {
    category: "Projects",
    actions: {
      addProject: "Project List Page: Add Button",
      jobActionButton: "Add Project Page: Job Action Button",
      assetJobItemToggle: "Add Project Page: Asset Type/Job Item Toggle",
      cancelSaveProject: "Add Project Page: Cancel Save Project",
      saveProject: "Save Project Add Project Page:",
      projectSavedWithNote: "Add Project Page: Project Saved With Note Added",
      projectSavedWithFile: "Add Project Page: Project Saved With File Attached",
      clickSearch: "Project List Page: Search Field",
      selectJobItem: "Add Project Page: Select Job Item in Job Detail/Add Project",
      selectLocation: "Add Project Page: Select Location in Job Detail/Add Project",
      clickUploadFile: "Add Project Page: Click Upload File",
      clickJobNote: "Click Job Note Add Project Page:",
      clickChecklist: "Add Project Page: Click Job Task Checklist",
      checklistSelect: "Add Project Page: Select Item In Job Task Checklist",
      editProject: "Project List Page: Click Edit",
      deleteProject: "Project List Page: Click Delete",
      confirmDelete: "Project Detail Page: Confirm Delete",
      cancelDelete: "Project Detail Page: Cancel Delete",
      clickProjectName: "Project List Page: Click Project Name",
      editDetailView: "Project Detail Page: Click Edit",
      editProjectCycle: "Cycle Project Detail Page: Click Edit Project",
      filterAsset:"Project Detail Page: Click Filter Asset/Project cycle",
      filterTeamMember:"Project Detail Page: Click Filter Team Member/Project Cycle",
      filterLocation:"Project Detail Page: Click Filter Location/Project cycle",
      filterStatus:"Project Detail Page: Click Filter Status/Project cycle",
      sortPercentComplete: "Project List Page: Sort By % Complete Column",
      sortName: "Project List Page: Sort By Name Column",
      sortRepeatEvery: "Project List Page: Sort By Repeat Every Column",
      sortCurrentCycle: "Project List Page: Sort By Current Cycle Column",
      sortNextCycle: "Project List Page: Sort By Next Cycle Column",
      queuedCheckbox: "Project Detail Page: Clicked Queued Checkbox in Project Cycle",
      jobCheckbox: "Project Detail Page: Clicked Job Checkbox in Project Cycle",
      assignJobProjectCycle: "Project Detail Page: Assigned A Job In Project Cycle",
    }
  },
  checklists: {
    category: "Checklists",
    actions: {
      test: "test",
      addCheckList: "Checklists Page: Add Checklist Button",
      closeChecklistModal: "Checklists Page: Closed Checklist Modal",
      checklistSave: "Checklists Page: Saved Checklist",
      checklistUpdate: "Checklists Page: Updated Checklist",
      checklistCopy: "Checklists Page: Copied Checklist",
      checklistCopyListView: "Checklists Page: Clicked Copy Checklist From List View",
      viewChecklist: "Checklists Page: Viewed Checklist From List View",
      deleteChecklist: "Checklists Page: Delete Checklist From List View",
      confirmDeleteChecklist: "Checklists Page: Confirmed Delete Checklist from Modal",
      editChecklist: "Checklists Page: Clicked Edit Checklist Button From List View",
      checklistWithDescription: "Checklists Page: Saved Checklist With Description",
      checklistAddNote: "Checklists Page: Clicked Add Note Button on Checklist Task",
      checklistClickAddAttachment: "Checklists Page: Clicked Add Attachment Button on Checklist Task",
      checklistAddAttachment: "Checklists Page: Add Attachment to Checklist Task",
      checklistRequired: "Checklists Page: Clicked Required Option on Checklist Task",
      clickChecklistTaskSelector: "Checklists Page: Clicked Task Type Selector on Checklist Task",
      checklistTaskSelectCheckbox: "Checklists Page: Select Checkbox Task Type on Checklist Task",
      checkboxTaskSelected: "Checklists Page: Checkbox Task Set To 'Selected'",
      checkboxTaskDeselected: "Checklists Page: Checkbox Task Set To 'Deselected'",
      checklistTaskSelectGroupHeader: "Checklists Page: Select Group Header Task Type on Checklist Task",
      checklistTaskSelectReadOnly: "Checklists Page: Select Read Only Task Type on Checklist Task",
      checklistTaskSelectOnOff: "Checklists Page: Select On/Off Task Type on Checklist Task",
      onOffTaskSelectOn: "Checklists Page: Select On Option on On/Off Task",
      onOffTaskSelectOff: "Checklists Page: Select Off Option on On/Off Task", 
      onOffTaskDeselect: "Checklists Page: Deselect Option on On/Off Task",
      checklistTaskSelectTemperature: "Checklists Page: Select Temperature Task Type on Checklist Task",
      temperatureTaskFahrenheitSelect: "Checklists Page: Select Fahrenheit in Temperature Task",
      temperatureTaskCelsiusSelect: "Checklists Page: Select Celsius in Temperature Task",
      temperatureTaskValueChange: "Checklists Page: Entered Temperature Value for Temperature Task",
      checklistTaskSelectDropdown: "Checklists Page: Select Dropdown Task Type on Checklist Task",
      dropdownTaskSelect: "Checklists Page: Select Option in Dropdown Task",
      checklistTaskSelectNumeric: "Checklists Page: Select Numeric Task Type on Checklist Task",
      numericTaskNonNumericError: "Checklists Page: Received Error for Non-Numeric Value in Numeric Task",
      numericTaskValueChange: "Checklists Page: Entered Value for Numeric Task",
      checklistTaskSelectPassFail: "Checklists Page: Select Pass/Fail Task Type on Checklist Task",
      passFailTaskSelectPassed: "Checklists Page: Select Passed Option on Pass/Fail Task",
      passFailTaskSelectFailed: "Checklists Page: Select Failed Option on Pass/Fail Task",
      checklistTaskSelectYesNo: "Checklists Page: Select Yes/No Task Type on Checklist Task",
      yesNoTaskSelectYes: "Checklists Page: Select Yes on Yes/No Task Type",
      yesNoTaskSelectNo: "Checklists Page: Select No on Yes/No Task Type",
      checklistTaskSelectText: "Checklists Page: Select Text Task Type on Checklist Task",
      textTaskEnterData: "Checklists Page: Enter Data in Text Task",
      startJobWithChecklist: "Checklists Page: Started Job With Attached Checklist",
      convertTaskToJob: "Checklists Page: Clicked Button Convert Task to Job",
      confirmConvertTaskToJob: "Checklists Page: Confirmed Convert Task to Job from Modal",
    }
  }
};

export const GA_EVENTS = {
  searchEscalation: {
    action: gaEvent.escalations.actions.search,
    category: gaEvent.escalations.category,
    label: "Typed in Search field"
  },
  deleteEscalationConfirm: {
    action: gaEvent.escalations.actions.delete,
    category: gaEvent.escalations.category,
    label: "Confirmed deleting escalation"
  },
  deleteIconEscalation: {
    action: gaEvent.escalations.actions.delete,
    category: gaEvent.escalations.category,
    label: "Clicked Delete icon"
  },
  editEscalationCancel: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Cancel on edit escalation form"
  },
  editEscalationUpdate: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Update on edit escalation form"
  },
  editIconEscalation: {
    action: gaEvent.escalations.actions.edit,
    category: gaEvent.escalations.category,
    label: "Clicked Edit icon"
  },
  saveEscalationCancel: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Cancel on add escalation form"
  },
  saveEscalation: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Save on add escalation form"
  },
  addEscalation: {
    action: gaEvent.escalations.actions.add,
    category: gaEvent.escalations.category,
    label: "Clicked Add Escalation button"
  },
  viewEscalations: {
    action: gaEvent.escalations.actions.view,
    category: gaEvent.escalations.category,
    label: "Clicked Escalations in Settings > Job Rules"
  },
  createJob: {
    action: gaEvent.jobs.actions.add,
    category: gaEvent.jobs.category,
    label: "Clicked Save on Job Status - Add Job"
  },
  viewJobSwimLane: {
    action: gaEvent.jobs.actions.view,
    category: gaEvent.jobs.category,
    label: "Clicked on card in Job Status"
  },
  editJobCardSwimLane: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit on card in Job Status"
  },
  editJobModal: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit icon on job modal"
  },
  editJobModalCancel: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked edit on Job Detail View to cancel edit"
  },
  editJobModalCancelButton: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked Cancel on Job Edit View"
  },
  editJobModalUpdateButton: {
    action: gaEvent.jobs.actions.edit,
    category: gaEvent.jobs.category,
    label: "Clicked Update on Job Edit View"
  },
  deleteJobCardSwimLane: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked delete on card in Job Status"
  },
  deleteQueuedJob: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked delete on card in Job Cycle"
  },
  deleteJobConfirmButton: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Confirmed deleting a job"
  },
  takeJob: {
    action: gaEvent.jobs.actions.take,
    category: gaEvent.jobs.category,
    label: "Clicked Take on Job Detail View"
  },
  startJob: {
    action: gaEvent.jobs.actions.start,
    category: gaEvent.jobs.category,
    label: "Clicked Start on Job Detail View"
  },
  takeAndStartJob: {
    action: gaEvent.jobs.actions.takeAndStart,
    category: gaEvent.jobs.category,
    label: "Clicked Take and Start on Job Detail View"
  },
  cancelJob: {
    action: gaEvent.jobs.actions.cancel,
    category: gaEvent.jobs.category,
    label: "Clicked Cancel Job on Job Detail View"
  },
  cancelJobConfirm: {
    action: gaEvent.jobs.actions.cancel,
    category: gaEvent.jobs.category,
    label: "Confirmed cancelling a job"
  },
  pauseJob: {
    action: gaEvent.jobs.actions.pause,
    category: gaEvent.jobs.category,
    label: "Clicked Pause on Job Detail View"
  },
  completeJob: {
    action: gaEvent.jobs.actions.complete,
    category: gaEvent.jobs.category,
    label: "Clicked Complete on Job Detail View"
  },
  addNoteJob: {
    action: gaEvent.jobs.actions.addNote,
    category: gaEvent.jobs.category,
    label: "Note added from Job Detail View"
  },
  viewActivityJob: {
    action: gaEvent.jobs.actions.viewActivity,
    category: gaEvent.jobs.category,
    label: "Clicked Activity on Job Detail View"
  },
  clickUploadFile: {
    action: gaEvent.jobs.actions.clickUploadFile,
    category: gaEvent.jobs.category,
    label: "Clicked Upload File on Job Detail View"
  },
  filterJobDepartment: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Department in Job Status"
  },
  filterJobRole: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Role in Job Status"
  },
  filterPastDue: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Past Due in Job Status"
  },
  filterJobTypeProject: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Project Jobs in Job Status"
  },
  filterJobTypeRepeating: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Repeating Jobs in Job Status"
  },
  filterJobTypeStandalone: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Standalone Jobs in Job Status"
  },
  filterJobSourceGuest: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Guest in Job Status"
  },
  filterJobSourceInternal: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Internal in Job Status"
  },
  filterJobSourceHousekeeping: {
    action: gaEvent.jobs.actions.filter,
    category: gaEvent.jobs.category,
    label: "Filter by Housekeeping in Job Status"
  },
  orderByDepartmentAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Department: A-Z in Job Status"
  },
  orderByDepartmentDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Department: Z-A in Job Status"
  },
  orderByTargetTimeAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Due By: Earliest in Job Status"
  },
  orderByTargetTimeDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Due By: Latest in Job Status"
  },
  orderByPriorityAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Priority: Low to Highest in Job Status"
  },
  orderByPriorityDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Priority: Highest to Low in Job Status"
  },
  orderByFirstNameAsc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Team Member: A-Z in Job Status"
  },
  orderByFirstNameDesc: {
    action: gaEvent.jobs.actions.sort,
    category: gaEvent.jobs.category,
    label: "Sort Team Member: Z-A in Job Status"
  },
  filterJobSearch: {
    action: gaEvent.jobs.actions.search,
    category: gaEvent.jobs.category,
    label: "Search Job in Job Status"
  },
  loggingIn: {
    action: gaEvent.login.actions.login,
    category: gaEvent.login.category,
    label: "Clicked Login"
  },
  loginTrouble: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Trouble Logging In?"
  },
  requestResetPassword: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Continue to Request Reset Password Email"
  },
  requestResetPasswordGoBackToLogin: {
    action: gaEvent.login.actions.troubleLoggingIn,
    category: gaEvent.login.category,
    label: "Clicked Go Back to Login"
  },
  haveQuickAccessCode: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Have a Quick Access Code"
  },
  initiateCreateProfile: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Continue to Create Profile"
  },
  termsOfService: {
    action: gaEvent.login.actions.quickAccessCode,
    category: gaEvent.login.category,
    label: "Clicked Terms of Service"
  },
  loggedOut: {
    action: gaEvent.logout.actions.loggedOut,
    category: gaEvent.logout.category,
    label: "Clicked Logout"
  },
  sendMessageAllMembers: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Chat To All Team Members"
  },
  sendMessageDepartment: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Chat To Department Channels"
  },
  sendMessageDirect: {
    action: gaEvent.chat.actions.sendMessage,
    category: gaEvent.chat.category,
    label: "Send Direct Messages"
  },
  tagUser: {
    action: gaEvent.chat.actions.tagUser,
    category: gaEvent.chat.category,
    label: "Tag Team Member"
  },
  deleteDirectMessage: {
    action: gaEvent.chat.actions.delete,
    category: gaEvent.chat.category,
    label: "Clicked delete on Direct Message"
  },
  deleteDirectMessageConfirm: {
    action: gaEvent.chat.actions.delete,
    category: gaEvent.chat.category,
    label: "Confirmed deleting a Direct message"
  },
  addDirectMessage: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Add Direct Message"
  },
  directMessageTeamMemberSearch: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Search Team Members in Chat"
  },
  directMessageTeamMemberSelect: {
    action: gaEvent.chat.actions.directMessage,
    category: gaEvent.chat.category,
    label: "Select Team Member"
  },
  badgeCount: {
    action: gaEvent.chat.actions.badgeCount,
    category: gaEvent.chat.category,
    label: "Clicked on Global Badge Count"
  },
  forgotPasswordLog: {
    action: gaEvent.engineering.actions.forgotPassword,
    category: gaEvent.engineering.category,
    label: ""
  },
  viewPersonalInfo: {
    action: gaEvent.userProfile.actions.viewPersonalInfo,
    category: gaEvent.userProfile.category,
    label: "Click on personal information"
  },
  editGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on General"
  },
  cancelGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on General"
  },
  updateGeneral: {
    action: gaEvent.userProfile.actions.editGeneral,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on General"
  },
  editContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on Contact Details"
  },
  cancelContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Contact Details"
  },
  updateContact: {
    action: gaEvent.userProfile.actions.editContact,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Contact Details"
  },
  editLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked edit on Language"
  },
  cancelLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Language"
  },
  updateLanguage: {
    action: gaEvent.userProfile.actions.editLanguage,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Language"
  },
  viewEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked view on Employment"
  },
  cancelEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Cancel on Employment"
  },
  editEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Edit on Employment"
  },
  updateEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Update on Employment"
  },
  addEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Add Another Position"
  },
  deleteEmployment: {
    action: gaEvent.userProfile.actions.editEmployment,
    category: gaEvent.userProfile.category,
    label: "Clicked Delete Position"
  },
  editPassword: {
    action: gaEvent.userProfile.actions.editPassword,
    category: gaEvent.userProfile.category,
    label: "Clicked Save Password"
  },
  dashHelpVideo: {
    action: gaEvent.help.actions.helpVideo,
    category: gaEvent.help.category,
    label: "Clicked Dashboard Help Video Link"
  },
  dashHelpLink: {
    action: gaEvent.help.actions.helpLink,
    category: gaEvent.help.category,
    label: "Clicked Dashboard Help Shortcut"
  },
  helpMenu: {
    action: gaEvent.help.actions.menuHelp,
    category: gaEvent.help.category,
    label: "Clicked Menu Get Help Link"
  },
  uploadFileUnknownSource: {
    action: gaEvent.misc.actions.unknownFileUpload,
    category: gaEvent.misc.category,
    label: "File uploaded from unknown component"
  },
  undeleteDeletedJob: {
    action: gaEvent.jobs.actions.delete,
    category: gaEvent.jobs.category,
    label: "Clicked undelete on card in Job Cycle"
  },
  addProject: {
    action: gaEvent.projects.actions.addProject,
    category: gaEvent.projects.category,
    label: "Clicked Add Project button"
  },
  jobActionButton: {
    action: gaEvent.projects.actions.jobActionButton,
    category: gaEvent.projects.category,
    label: "Clicked job action button in job details in add project"
  },
  assetJobItemToggle: {
    action: gaEvent.projects.actions.assetJobItemToggle,
    category: gaEvent.projects.category,
    label: "Clicked on asset type/job item toggle in add job details in add project"
  },
  cancelSaveProject: {
    action: gaEvent.projects.actions.cancelSaveProject,
    category: gaEvent.projects.category,
    label: "Clicked cancel button in add project"
  },
  saveProject: {
    action: gaEvent.projects.actions.saveProject,
    category: gaEvent.projects.category,
    label: "Clicked save button in Add Project"
  },
  projectSavedWithNote: {
    action: gaEvent.projects.actions.projectSavedWithNote,
    category: gaEvent.projects.category,
    label: "Project was created with a note attached"
  },
  projectSavedWithFile: {
    action: gaEvent.projects.actions.projectSavedWithFile,
    category: gaEvent.projects.category,
    label: "Project was created with a file attached"
  },
  clickSearch: {
    action: gaEvent.projects.actions.clickSearch,
    category: gaEvent.projects.category,
    label: "Clicked search field"
  },
  addFile: {
    action: gaEvent.projects.actions.addFile,
    category: gaEvent.projects.category,
    label: "Clicked upload file buttton"
  },
  clickJobNote: {
    action: gaEvent.projects.actions.clickJobNote,
    category: gaEvent.projects.category,
    label: "Clicked job note field"
  },
  clickChecklist: {
    action: gaEvent.projects.actions.clickChecklist,
    category: gaEvent.projects.category,
    label: "Clicked job task checklist field"
  },
  checklistSelect: {
    action: gaEvent.projects.actions.checklistSelect,
    category: gaEvent.projects.category,
    label: "Selected a item in job task checlist"
  },
  editProject: {
    action: gaEvent.projects.actions.editProject,
    category: gaEvent.projects.category,
    label: "Clicked edit button from project list view"
  },
  deleteProject: {
    action: gaEvent.projects.actions.deleteProject,
    category: gaEvent.projects.category,
    label: "Clicked delete button from project list view"
  },
  confirmDelete: {
    action: gaEvent.projects.actions.confirmDelete,
    category: gaEvent.projects.category,
    label: "Clicked confirm project delete button"
  },
  cancelDelete: {
    action: gaEvent.projects.actions.cancelDelete,
    category: gaEvent.projects.category,
    label: "Clicked cancel project delete button"
  },
  clickProjectName: {
    action: gaEvent.projects.actions.clickProjectName,
    category: gaEvent.projects.category,
    label: "Clicked project name in list view"
  },
  editDetailView: {
    action: gaEvent.projects.actions.editDetailView,
    category: gaEvent.projects.category,
    label: "Clicked edit botton in project detail"
  },
  editProjectCycle: {
    action: gaEvent.projects.actions.editProjectCycle,
    category: gaEvent.projects.category,
    label: "Clicked edit project cycle button"
  },
  test: {
    action: gaEvent.projects.actions.test,
    category: gaEvent.projects.category,
    label: "This is a test"
  },
  filterAsset: {
    action: gaEvent.projects.actions.filterAsset,
    category: gaEvent.projects.category,
    label: "Clicked filter by asset in project cycle"
  },
  filterTeamMember: {
    action: gaEvent.projects.actions.filterTeamMember,
    category: gaEvent.projects.category,
    label: "Clicked filter by team member in project cycle"
  },
  filterLocation: {
    action: gaEvent.projects.actions.filterLocation,
    category: gaEvent.projects.category,
    label: "Clicked filter by location in project cycle"
  },
  filterStatus: {
    action: gaEvent.projects.actions.filterStatus,
    category: gaEvent.projects.category,
    label: "Clicked filter by status in project cycle"
  },
  sortPercentComplete: {
    action: gaEvent.projects.actions.sortPercentComplete,
    category: gaEvent.projects.category,
    label: "Clicked % complete column in project list"
  },
  sortName: {
    action: gaEvent.projects.actions.sortName,
    category: gaEvent.projects.category,
    label: "Clicked name column in project list"
  },
  sortRepeatEvery: {
    action: gaEvent.projects.actions.sortRepeatEvery,
    category: gaEvent.projects.category,
    label: "Clicked repeat every column in project list"
  },
  sortCurrentCycle: {
    action: gaEvent.projects.actions.sortCurrentCycle,
    category: gaEvent.projects.category,
    label: "Clicked current cycle column in project list"
  },
  sortNextCycle: {
    action: gaEvent.projects.actions.sortNextCycle,
    category: gaEvent.projects.category,
    label: "Clicked next cycle column in project list"
  },
  queuedCheckbox: {
    action: gaEvent.projects.actions.queuedCheckbox,
    category: gaEvent.projects.category,
    label: "Clicked queued checkbox in project cycle"
  },
  jobCheckbox: {
    action: gaEvent.projects.actions.jobCheckbox,
    category: gaEvent.projects.category,
    label: "Clicked job checkbox in project cycle"
  },
  assignJobProjectCycle: {
    action: gaEvent.projects.actions.assignJobProjectCycle,
    category: gaEvent.projects.category,
    label: "Assigned a team member a job in project cycle"
  },
  selectJobItem: {
    action: gaEvent.projects.actions.selectJobItem,
    category: gaEvent.projects.category,
    label: "Selected a job item from selector list in job details/add project"
  },
  selectLocation: {
    action: gaEvent.projects.actions.selectLocation,
    category: gaEvent.projects.category,
    label: "Selected a job item from checklist in job details/add project"
  },
  addCheckList: {
    action: gaEvent.checklists.actions.addCheckList,
    category: gaEvent.checklists.category,
    label: "Clicked Add Checklist button"
  },
  closeChecklistModal: {
    action: gaEvent.checklists.actions.closeChecklistModal,
    category: gaEvent.checklists.category,
    label: "Clicked 'X' button to close checklist modal"
  },
  checklistSave: {
    action: gaEvent.checklists.actions.checklistSave,
    category: gaEvent.checklists.category,
    label: "Saved checklist"
  },
  checklistUpdate: {
    action: gaEvent.checklists.actions.checklistUpdate,
    category: gaEvent.checklists.category,
    label: "Updated checklist"
  },
  checklistCopy: {
    action: gaEvent.checklists.actions.checklistCopy,
    category: gaEvent.checklists.category,
    label: "Copied checklist"
  },
  viewChecklist: {
    action: gaEvent.checklists.actions.viewChecklist,
    category: gaEvent.checklists.category,
    label: "View checklist from list view"
  },
  editChecklist: {
    action: gaEvent.checklists.actions.editChecklist,
    category: gaEvent.checklists.category,
    label: "Edit checklist from list view"
  },
  deleteChecklist: {
    action: gaEvent.checklists.actions.deleteChecklist,
    category: gaEvent.checklists.category,
    label: "Delete checklist from list view"
  },
  confirmDeleteChecklist: {
    action: gaEvent.checklists.actions.confirmDeleteChecklist,
    category: gaEvent.checklists.category,
    label: "Confirm Delete Checklist from modal"
  },
  checklistCopyListView: {
    action: gaEvent.checklists.actions.checklistCopyListView,
    category: gaEvent.checklists.category,
    label: "Copy checklist from list view"
  },
  checklistWithDescription: {
    action: gaEvent.checklists.actions.checklistWithDescription,
    category: gaEvent.checklists.category,
    label: "Saved checklist with description"
  },
  checklistAddNote: {
    action: gaEvent.checklists.actions.checklistAddNote,
    category: gaEvent.checklists.category,
    label: "Clicked add note in Checklist task"
  },
  checklistClickAddAttachment: {
    action: gaEvent.checklists.actions.checklistClickAddAttachment,
    category: gaEvent.checklists.category,
    label: "Clicked add attachment in Checklist task"
  },
  checklistAddAttachment: {
    action: gaEvent.checklists.actions.checklistAddAttachment,
    category: gaEvent.checklists.category,
    label: "Add attachment to Checklist task"
  },
  checklistRequired: {
    action: gaEvent.checklists.actions.checklistRequired,
    category: gaEvent.checklists.category,
    label: "Clicked required option in Checklist task"
  },
  clickChecklistTaskSelector: {
    action: gaEvent.checklists.actions.clickChecklistTaskSelector,
    category: gaEvent.checklists.category,
    label: "Clicked task type selector in Checklist task"
  },
  checklistTaskSelectCheckbox: {
    action: gaEvent.checklists.actions.checklistTaskSelectCheckbox,
    category: gaEvent.checklists.category,
    label: "Select checkbox task in Checklist task"
  },
  checkboxTaskSelected: {
    action: gaEvent.checklists.actions.checkboxTaskSelected,
    category: gaEvent.checklists.category,
    label: "Checkbox task switched to 'selected'"
  },
  checkboxTaskDeselected: {
    action: gaEvent.checklists.actions.checkboxTaskDeselected,
    category: gaEvent.checklists.category,
    label: "Checkbox task switched to 'deselected'"
  },
  checklistTaskSelectGroupHeader: {
    action: gaEvent.checklists.actions.checklistTaskSelectGroupHeader,
    category: gaEvent.checklists.category,
    label: "Select group header task in Checklist task"
  },
  checklistTaskSelectReadOnly: {
    action: gaEvent.checklists.actions.checklistTaskSelectReadOnly,
    category: gaEvent.checklists.category,
    label: "Select read only task in Checklist task"
  },
  checklistTaskSelectOnOff: {
    action: gaEvent.checklists.actions.checklistTaskSelectOnOff,
    category: gaEvent.checklists.category,
    label: "Select on/off task in Checklist task"
  },
  onOffTaskSelectOn: {
    action: gaEvent.checklists.actions.onOffTaskSelectOn,
    category: gaEvent.checklists.category,
    label: "Select 'on' in On/Off task"
  },
  onOffTaskSelectOff: {
    action: gaEvent.checklists.actions.onOffTaskSelectOff,
    category: gaEvent.checklists.category,
    label: "Select 'off' in On/Off task"
  },
  onOffTaskDeselect: {
    action: gaEvent.checklists.actions.onOffTaskDeselect,
    category: gaEvent.checklists.category,
    label: "Deselect option in On/Off task"
  },
  checklistTaskSelectTemperature: {
    action: gaEvent.checklists.actions.checklistTaskSelectTemperature,
    category: gaEvent.checklists.category,
    label: "Select temperature task in Checklist task"
  },
  temperatureTaskFahrenheitSelect: {
    action: gaEvent.checklists.actions.temperatureTaskFahrenheitSelect,
    category: gaEvent.checklists.category,
    label: "Select Fahrenheit in temperature task"
  },
  temperatureTaskCelsiusSelect: {
    action: gaEvent.checklists.actions.temperatureTaskCelsiusSelect,
    category: gaEvent.checklists.category,
    label: "Select Celsius in temperature task"
  },
  temperatureTaskValueChange: {
    action: gaEvent.checklists.actions.temperatureTaskValueChange,
    category: gaEvent.checklists.category,
    label: "Enter a temperature value in temperature task"
  },
  checklistTaskSelectDropdown: {
    action: gaEvent.checklists.actions.checklistTaskSelectDropdown,
    category: gaEvent.checklists.category,
    label: "Select dropdown task in Checklist task"
  },
  dropdownTaskSelect: {
    action: gaEvent.checklists.actions.dropdownTaskSelect,
    category: gaEvent.checklists.category,
    label: "Select option in dropdown task"
  },
  checklistTaskSelectNumeric: {
    action: gaEvent.checklists.actions.checklistTaskSelectNumeric,
    category: gaEvent.checklists.category,
    label: "Select numeric task in Checklist task"
  },
  numericTaskNonNumericError: {
    action: gaEvent.checklists.actions.numericTaskNonNumericError,
    category: gaEvent.checklists.category,
    label: "Received error in numeric task for entering non-numeric value"
  },
  numericTaskValueChange: {
    action: gaEvent.checklists.actions.numericTaskValueChange,
    category: gaEvent.checklists.category,
    label: "Enter value for numeric task"
  },
  checklistTaskSelectPassFail: {
    action: gaEvent.checklists.actions.checklistTaskSelectPassFail,
    category: gaEvent.checklists.category,
    label: "Select pass/fail task in Checklist task"
  },
  passFailTaskSelectPassed: {
    action: gaEvent.checklists.actions.passFailTaskSelectPassed,
    category: gaEvent.checklists.category,
    label: "Select 'pass' in pass/fail task"
  },
  passFailTaskSelectFailed: {
    action: gaEvent.checklists.actions.passFailTaskSelectFailed,
    category: gaEvent.checklists.category,
    label: "Select 'fail' in pass/fail task"
  },
  checklistTaskSelectText: {
    action: gaEvent.checklists.actions.checklistTaskSelectText,
    category: gaEvent.checklists.category,
    label: "Select text task in Checklist task"
  },
  textTaskEnterData: {
    action: gaEvent.checklists.actions.textTaskEnterData,
    category: gaEvent.checklists.category,
    label: "Enter Data in text task"
  },
  checklistTaskSelectYesNo: {
    action: gaEvent.checklists.actions.checklistTaskSelectYesNo,
    category: gaEvent.checklists.category,
    label: "Select yes/no task in Checklist task"
  },
  yesNoTaskSelectYes: {
    action: gaEvent.checklists.actions.yesNoTaskSelectYes,
    category: gaEvent.checklists.category,
    label: "Select 'Yes' option in Yes/No task"
  },
  yesNoTaskSelectNo: {
    action: gaEvent.checklists.actions.yesNoTaskSelectNo,
    category: gaEvent.checklists.category,
    label: "Select 'No' option in Yes/No task"
  },
  startJobWithChecklist: {
    action: gaEvent.checklists.actions.startJobWithChecklist,
    category: gaEvent.checklists.category,
    label: "Started a job with a checklist attached"
  },
  convertTaskToJob: {
    action: gaEvent.checklists.actions.convertTaskToJob,
    category: gaEvent.checklists.category,
    label: "Clicked convert task to job"
  },
  confirmConvertTaskToJob: {
    action: gaEvent.checklists.actions.confirmConvertTaskToJob,
    category: gaEvent.checklists.category,
    label: "Confirmed convert task to job from modal"
  },
};
