import React from 'react';
import { animated, useTransition } from 'react-spring';

type TransitionType = {
  show: any;
  transitions: any;
  children: any;
};
/*
  Helper for simple transitions
*/
function Transition(outerProps: TransitionType) {
  const { show, transitions, children } = outerProps;

  const newTransitions = useTransition(show, null, transitions);

  return (
    <>
      {newTransitions.map(({ item, key, props }) => (
        <animated.div key={key} style={props}>
          {children(item)}
        </animated.div>
      ))}
    </>
  );
}
export default Transition;
