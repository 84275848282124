import { Meta, StoryObj } from '@storybook/react';
import { MoveLocationsButton } from '../../molecules/Buttons/Buttons.component';
import { FiltersDropdown } from './Filters.component';

/**
 * Filters Organisms running with Ant Components Select
 */

export default {
  title: 'UI-Library/Organisms/Filters',
  component: FiltersDropdown,
  parameters: {
    backgrounds: {
      default: 'topcat',
    },
  },
} as Meta;

/**
 * Generic usage
 */

export const ClearableDropdowns: StoryObj<typeof FiltersDropdown> = {
  args: {
    onChange: (output) => console.log(output),
    i18n: {
      clearAll: 'Clear All',
      dropdowns: {
        apples: {
          placeholder: 'All Apples',
          selected: 'Selected Apples',
          multiple: true,
          options: [
            { label: 'Fuji', value: '1' },
            { label: 'Gala', value: '2' },
            { label: 'McIntosh', value: 'mc' },
          ],
        },
        oranges: {
          placeholder: 'All Oranges',
          selected: 'Selected Types',
          multiple: true,
          options: [
            { label: 'Satsuma', value: '1' },
            { label: 'Clementine', value: '2' },
            { label: 'Jaffa', value: '3' },
          ],
        },
        organic: {
          placeholder: 'Organic choice',
          options: [
            {
              label: 'Pure',
              value: 'pure',
            },
            {
              label: 'Integrated',
              value: 'integrated',
            },
            {
              label: 'Hydroponic',
              value: 'hydro',
            },
          ],
        },
      },
    },
  },
};

/**
 * Settings Floor Plan Filters
 */

export const FloorPlanFilters: StoryObj<typeof FiltersDropdown> = {
  args: {
    onChange: (output) => console.log(output),
    i18n: {
      clearAll: 'Clear All',
      dropdowns: {
        parent: {
          placeholder: 'All Parent Locations',
          selected: 'Selected Parents',
          multiple: true,
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        type: {
          placeholder: 'All Location Types',
          selected: 'Selected Types',
          multiple: true,
          options: [
            { label: 'Basement', value: '1' },
            { label: 'Bathroom', value: '2' },
            { label: 'Building', value: '3' },
          ],
        },
        locations: {
          placeholder: 'All Locations',
          selected: 'Selected Locations',
          multiple: true,
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        statuses: {
          placeholder: 'All Statuses',
          selected: 'Selected Statuses',
          multiple: true,
          options: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ],
        },
        spaces: {
          placeholder: 'Physical & Virtual',
          options: [
            {
              label: 'Physical & Virtual',
              value: 'all',
            },
            {
              label: 'Physical Only',
              value: 'physical',
            },
            {
              label: 'Virtual Only',
              value: 'virtual',
            },
          ],
        },
      },
    },
  },
};

/**
 * Settings Floor Plan Filters with selected items
 */

export const FloorPlanFiltersWithSelected: StoryObj<typeof FiltersDropdown> = {
  args: {
    onChange: (output) => console.log(output),
    footer: (
      <MoveLocationsButton
        count={50}
        total={499}
        locations={[
          { label: 'B1', value: '1' },
          { label: 'B2', value: '2' },
          { label: 'B3', value: '3' },
        ]}
        onSelectParent={(location, moveAll) => console.log(location, moveAll)}
      />
    ),
    i18n: {
      clearAll: 'Clear All',
      dropdowns: {
        parent: {
          placeholder: 'All Parent Locations',
          selected: 'Selected Parents',
          multiple: true,
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        type: {
          placeholder: 'All Location Types',
          selected: 'Selected Types',
          multiple: true,
          options: [
            { label: 'Basement', value: '1' },
            { label: 'Bathroom', value: '2' },
            { label: 'Building', value: '3' },
          ],
        },
        locations: {
          placeholder: 'All Locations',
          selected: 'Selected Locations',
          multiple: true,
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        statuses: {
          placeholder: 'All Statuses',
          selected: 'Selected Statuses',
          multiple: true,
          options: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ],
        },
        spaces: {
          placeholder: 'Physical & Virtual',
          options: [
            {
              label: 'Physical & Virtual',
              value: 'all',
            },
            {
              label: 'Physical Only',
              value: 'physical',
            },
            {
              label: 'Virtual Only',
              value: 'virtual',
            },
          ],
        },
      },
    },
  },
};

/**
 * Settings Floor Plan Filters providing values
 */

export const FloorPlanFiltersInitialValues: StoryObj<typeof FiltersDropdown> = {
  args: {
    onChange: (output) => console.log(output),
    i18n: {
      clearAll: 'Clear All',
      dropdowns: {
        parent: {
          placeholder: 'All Parent Locations',
          selected: 'Selected Parents',
          multiple: true,
          values: ['1', '2'],
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        type: {
          placeholder: 'All Location Types',
          selected: 'Selected Types',
          multiple: true,
          options: [
            { label: 'Basement', value: '1' },
            { label: 'Bathroom', value: '2' },
            { label: 'Building', value: '3' },
          ],
        },
        locations: {
          placeholder: 'All Locations',
          selected: 'Selected Locations',
          multiple: true,
          values: ['3'],
          options: [
            { label: 'B1', value: '1' },
            { label: 'B2', value: '2' },
            { label: 'B3', value: '3' },
          ],
        },
        statuses: {
          placeholder: 'All Statuses',
          selected: 'Selected Statuses',
          multiple: true,
          options: [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ],
        },
        spaces: {
          placeholder: 'Physical & Virtual',
          values: ['physical'],
          options: [
            {
              label: 'Physical & Virtual',
              value: 'all',
            },
            {
              label: 'Physical Only',
              value: 'physical',
            },
            {
              label: 'Virtual Only',
              value: 'virtual',
            },
          ],
        },
      },
    },
  },
};
