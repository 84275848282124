export default {
  configuration: "configuration",
  communication: "communication",
  house_keeping: "house_keeping",
  service_delivery: "service_delivery",
  schedule: "schedule",
  user_profile: "user_profile",
  jobs: "jobs",
  reports: "reports",
  home_dashboard: "home_dashboard",
  settings: "settings",
  engineering: "engineering",
  translation: "translation",
  smart_operations: "smart_operations",
  pms_Data: "pms_data"
};
