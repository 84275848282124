import { gql } from "@apollo/client";

export default gql`
  mutation predictJobDuration($input: JobDurationInput!) {
    predictJobDuration(jobDurationInput: $input) {
      dueTime
      jobDuration
      waitTime
    }
  }
`;
