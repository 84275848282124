export default {
  lowest: 'z-index:100;',
  prettyLow: 'z-index:-1;',
  low: 'z-index:1;',
  medium: 'z-index:10;',
  high: 'z-index:100;',
  prettyHigh: 'z-index:990;',
  highest: 'z-index:999;',
  modalBack: 'z-index:991;', // ONLY USE THIS LEVEL FOR MODAL BACKGROUND!
  modal: 'z-index:1000;', // ONLY USE THIS LEVEL FOR MODAL!
  superModalBack: 'z-index:100000;', // ONLY USE THIS LEVEL FOR MODAL OVER MODAL BACKGROUND!
  superModal: 'z-index:100001;', // ONLY USE THIS LEVEL FOR MODAL OVER MODAL!
};
