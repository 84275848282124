import { gql } from "@apollo/client";

export default gql`
  mutation copySchedule($id: ID!, $dateBegin: Int64!, $dateEnd: Int64!) {
    copySchedule(id: $id, dateBegin: $dateBegin, dateEnd: $dateEnd) {
      id
      status
    }
  }
`;
