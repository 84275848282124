import { gql } from '@apollo/client';

export default gql`
  query RoomReservations($id: ID!) {
    page: roomReservations(id: $id) {
      edges {
        node {
          ... on Reservation {
            arrivalDate
            departureDate
            displayName
            estimatedArrivalTime
            estimatedDepartureTime
            id
            noOfAdults
            noOfChildren
            reservationStatus
            specialRequestCodes
            statusLabel
            guests {
              id
              firstName
              lastName
              title
              type
            }
          }
        }
      }
    }
  }
`;
