import { Message } from '@twilio/conversations';

type Sender = {
  id: string;
  language: {
    id: string;
    displayName: string;
  };

  userFirstName: string;
  userLastName: string;
};

type Translations = {
  translatedText: string;
  languageCode: string;
};

type Attributes = {
  sender: Sender;
  translations: Array<Translations>;
};

export function useMessage(message: Message, languageCode?: string) {
  const { attributes, body: originalMessage } = message;

  const { sender, translations } = attributes as Attributes;

  const senderLanguage = sender?.language?.id;

  let translationMessageAttributes;
  let translatedMessage;
  let senderMessageAttributes;

  if (translations) {
    translationMessageAttributes = translations.find(
      (translation) => translation.languageCode === languageCode,
    );
    translatedMessage = translationMessageAttributes?.translatedText;
    senderMessageAttributes = translations.find(
      (translation) => translation.languageCode === senderLanguage,
    );
  }

  const canTranslate =
    translations && senderMessageAttributes?.languageCode !== languageCode;

  if (!translatedMessage) {
    translatedMessage = originalMessage;
  }

  return {
    canTranslate,
    originalMessage,
    translatedMessage,
  };
}
