import { gql } from "@apollo/client";

export const CHECKLIST_TEMPLATE = gql`
  query ChecklistTemplate($checklistTemplateId: ID!) {
    checklistTemplate(id: $checklistTemplateId) {
      archivedAt
      createdAt
      id
      tasks {
        attachment
        checklistTemplateId
        createdAt
        id
        label
        note: notes
        updatedAt
        taskType
        required
        parent_task_id
        ordering_value
        dataTemperatureScale
        dataDropdownValue
        dropdownOptions
      }
      creator {
        employeeId
        firstName
        id
        lastName
        createdAt
      }
      name
      description
      updatedAt
    }
  }
`;
