import styled from 'styled-components/macro';
import Search from '@optii/topcat-client/blocks/Search';
import { applyStyleModifiers } from 'styled-components-modifiers';

const MODIFIER_CONFIG = {
  snap: (_) => `
    @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      margin-top: 1.5rem;
      padding-right: 0;
      width: 31rem;
    }
  `,
  positionRight: () => `
    margin-left: auto;
  `,
};
const SearchBox = styled(Search)`
  width: 25rem;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
  min-width: 14rem;
  height: 4.4rem;
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 10rem;
    width: 100%;
    padding-right: 1rem;
    margin-top: 1.5rem;
    margin-left: 0;
  }
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

export default SearchBox;
