import { gql } from '@apollo/client';

export default gql`
  query Reservation($id: ID!) {
    reservation(id: $id) {
      id
      arrivalDate
      departureDate
      estimatedArrivalTime
      estimatedDepartureTime
      noOfAdults
      noOfChildren
      reservationStatus
      version
      externalGroupReservationId
      notes
      preferences {
        description
        type
        value
      }
    }
  }
`;
