import { gql } from "@apollo/client";

export default gql`
  query GetUserByUsername($username: String!) {
    user: GetUserByUsername(username: $username) {
      preferredLang
      userName
      country
      piiDisabled
      status
      id
      firstName
      lastName
      emailAddr
      city
      state
      postalCode
      mobileNum
      personalPhoto
    }
  }
`;
