import { gql } from "@apollo/client";

export default gql`
  mutation undeleteProjectCycleJobs(
    $projectId: ID!
    $projectCycleId: ID!
    $jobCardHashes: [String!]!
  ) {
    undeleteProjectCycleJobs(
      projectId: $projectId
      projectCycleId: $projectCycleId
      jobCardHashes: $jobCardHashes
    )
  }
`;
