import styled from 'styled-components/macro';
import React from 'react';
import LinkButton from './LinkButton';

const Button = styled(LinkButton)`
  color: ${(props) => props.theme.colors.charcoal05};
  ${(props) => props.theme.fonts.weights.medium}
  text-decoration: none;
  margin-inline-end: 1rem;
  cursor: pointer;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.charcoal};
  }
`;
function QuadButton(props) {
  const { children } = props;
  return (
    <Button type="button" {...props}>
      {children}
    </Button>
  );
}
export default QuadButton;
